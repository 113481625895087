import React, { Component } from 'react';
import { Form, Input, Select, Spin } from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CitiesSelect, CategoriesSelect, LinkInput } from 'components';
import { ProjectsActions } from 'redux/actions';
import pickBy from 'loadsh/pickBy';
import identity from 'loadsh/identity';
import isEmpty from 'loadsh/isEmpty';
import {
  Container,
  PrimaryButton,
  formLayout,
  formValidateMessages,
  H1,
  H2,
  FormWrapper,
} from 'utils';

const FormItem = Form.Item;
const { Option } = Select;

class BasicInformation extends Component {
  static propTypes = {
    updateDraft: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    isUniq: PropTypes.bool.isRequired,
    checkUrl: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    projectId: PropTypes.string.isRequired,
    currentStep: PropTypes.number.isRequired,
    setCanMove: PropTypes.func.isRequired,
    showModal: PropTypes.bool.isRequired,
    selectedStep: PropTypes.number.isRequired,
    setSubmitFromModal: PropTypes.func.isRequired,
    handleCreateProject: PropTypes.func.isRequired,
  };

  onFinish = (values) => {
    const vals={...values, url:`${values.url}`.toLowerCase()}
    const {
      updateDraft,
      projectId,
      currentStep,
      setCanMove,
      showModal,
      selectedStep,
      setSubmitFromModal,
      handleCreateProject,
    } = this.props;
    if (showModal) {
      projectId ? updateDraft(projectId, vals, selectedStep - 1) : handleCreateProject(vals);
      setSubmitFromModal(false);
    } else {
      projectId ? updateDraft(projectId, vals, currentStep) : handleCreateProject(vals);
    }
    setCanMove(true);
    this.props.handleSteps(2);
  };

  handleChangeLink = (e) => {
    this.setState({ link: e.target.defaultValue });
  };

  render() {
    const {
      project,
      loading,
      // isUniq,
      setCanMove,
      projectId,
    } = this.props;
    console.log(projectId,'project--> ', project);
    return (
      <Container className="inside-form-container">
        <Spin spinning={projectId ? loading : false}>
          <H1>Grundlegendes</H1>
          <p>Nenne uns die wichtigsten Informationen zu deinem Projekt</p>
          {
            projectId ? (
              !isEmpty(project) && !loading && (
                <Form
                  {...formLayout}
                  onChange={(e) => {
                    return setCanMove(false);
                  }}
                  name="project-details"
                  onFinish={this.onFinish}
                  validateMessages={formValidateMessages}
                  initialValues={project ? pickBy(project.basicInfo, identity) : {}}
                >
                  <div>
                    <H2>Dein Projekt</H2>
                    <FormWrapper>
                      <>
                        <FormItem
                          name="title"
                          label="Name des Projekts"
                          extra="Gib deinem Projekt einen aussagekräftigen Namen."
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Input.TextArea rows={1} showCount maxLength={100} />
                          {/* <Input maxLength={100}/> */}
                        </FormItem>
                        <FormItem
                          name="subtitle"
                          label="Untertitel"
                          extra="Die Mission deines Projekts in einem Satz."
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Input.TextArea rows={1} showCount maxLength={100} />
                        </FormItem>
                        <LinkInput projectId={projectId} />
                        <FormItem
                          name="tags"
                          label="Schlüsselwörter"
                          extra="Wähle max. fünf Schlagwörter aus."
                        >
                          <Select
                            mode="tags"
                            tokenSeparators={[',']}
                            maxTagCount={5}
                            allowClear={true}
                          ></Select>
                        </FormItem>
                      </>
                    </FormWrapper>
                  </div>

                  <div>
                    <FormWrapper>
                      <>
                        <CitiesSelect />

                        <CategoriesSelect />
                        <FormItem
                          name="project_type"
                          label="Projekttyp"
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Select
                            placeholder="Wähle ein Projekttyp"
                            style={{ width: '100%' }}
                          >
                            <Option key={'partial'}>Nicht-Gewinnorientiert</Option>
                            <Option key={'all'}>Gewinnorientiert</Option>
                          </Select>
                        </FormItem>
                      </>
                    </FormWrapper>
                  </div>
                  <FormItem>
                    <PrimaryButton
                      type="primary"
                      htmltype="submit"
                      minWidth="150px"
                      id="submit-button"
                    >
                      Speichern
                    </PrimaryButton>
                  </FormItem>
                </Form>
              )
            ) : (
              <Form
                {...formLayout}
                onChange={(e) => {
                  return setCanMove(false);
                }}
                name="project-details"
                onFinish={this.onFinish}
                validateMessages={formValidateMessages}
                initialValues={""}
              >
                <div>
                  <H2>Dein Projekt</H2>
                  <FormWrapper>
                    <>
                      <FormItem
                        name="title"
                        label="Name des Projekts"
                        extra="Gib deinem Projekt einen aussagekräftigen Namen."
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input.TextArea rows={1} showCount maxLength={100} />
                        {/* <Input maxLength={100}/> */}
                      </FormItem>
                      <FormItem
                        name="subtitle"
                        label="Untertitel"
                        extra="Die Mission deines Projekts in einem Satz."
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input.TextArea rows={1} showCount maxLength={100} />
                      </FormItem>
                      <LinkInput projectId={projectId} />
                      <FormItem
                        name="tags"
                        label="Schlüsselwörter"
                        extra="Wähle max. fünf Schlagwörter aus."
                      >
                        <Select
                          mode="tags"
                          tokenSeparators={[',']}
                          maxTagCount={5}
                          allowClear={true}
                        ></Select>
                      </FormItem>
                    </>
                  </FormWrapper>
                </div>

                <div>
                  <FormWrapper>
                    <>
                      <CitiesSelect />

                      <CategoriesSelect />
                      <FormItem
                        name="project_type"
                        label="Projekttyp"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select
                          placeholder="Wähle ein Projekttyp"
                          style={{ width: '100%' }}
                        >
                          <Option key={'partial'}>Nicht-Gewinnorientiert</Option>
                          <Option key={'all'}>Gewinnorientiert</Option>
                        </Select>
                      </FormItem>
                    </>
                  </FormWrapper>
                </div>
                <FormItem>
                  <PrimaryButton
                    type="primary"
                    htmltype="submit"
                    minWidth="150px"
                    id="submit-button"
                  >
                    Speichern
                  </PrimaryButton>
                </FormItem>
              </Form>
            )
          }

        </Spin>
      </Container>
    );
  }
}
const mapStateToProps = (state) => {
  const { currentStep, project, loading, errors, isUniq } = state.projects;
  return {
    currentStep,
    project,
    loading,
    errors,
    isUniq,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleSteps: (step) => dispatch(ProjectsActions.handleSteps(step)),
  checkUrl: (url) => dispatch(ProjectsActions.checkUrl(url)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BasicInformation);
