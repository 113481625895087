import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AdminActions } from 'redux/actions';
import { Layout } from 'components';
import { Table } from 'antd';
import { Container, PrimaryButton } from 'utils';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { adminServices } from 'services';


const TableWrapper = styled.div`
  padding: 50px 0;
`;

const Filter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  @media (max-width: 320px) {
    gap:10px;
    overflow:hidden;
  }
`;

const perPage = 20;
export class AllDonations extends Component {
  static propTypes = {
    projects: PropTypes.any.isRequired,
    getAllProjects: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
  };
  componentDidMount() {
  this.getDonationList(1)
  }

  state = {
    status: null,
    page: 1,
    donationList:[]
  };
  async getDonationList(page) {
    try {
      this.setState({ loading: true, error: null });
      const res = await adminServices.getAllDonationList(page);
      this.setState({ donationList: res?.projects}); 
    } catch (error) { }
  }
  render() {
    const { projects, loading, getAllProjects } = this.props;
   const{donationList}= this.state
    const columns = [
      {
        title: 'Project name',
        dataIndex: 'title',
        key: 'title',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
      },
      { title: 'goal', dataIndex: 'goal', key: 'goal' },
      { title: 'city', dataIndex: 'city', key: 'city' },
      {
        title: 'View',
        dataIndex: 'view',
        key: 'view',
        render: (text, record) => (
          <Link to={`/admin/view-donation-detail/${record.id}`}>View {record.id}</Link>
        ),
      },
    ];
    return (
      <Layout>
        <Container>
          <TableWrapper>
            <Table
              columns={columns}
              dataSource={donationList.projects}
              loading={loading}
              pagination={{
                total: donationList.count,
                pageSize: perPage,
                hideOnSinglePage: false,
                current: this.state.page,
                onChange: (page) => {
                  this.setState({ page });
                  this.getDonationList( page);
                },
              }}
            />
          </TableWrapper>
        </Container>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  const { projects, loading } = state.admin;
  return {
    projects,
    loading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllProjects: (perPage, page, status = null) =>
    dispatch(AdminActions.getAllProjects(perPage, page, status)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AllDonations);
