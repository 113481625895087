/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import PropTypes from 'prop-types';

const PaymentIcon = ({ width, height }) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 732 684"
      height={width || '100%'}
      width={height || '100%'}
      css={css`
        max-width: ${width || '100%'};
        max-height: ${height || '100%'};
        height: ${height || '100%'};
        width: ${width || '100%'};
        display: inline-block;
      `}
    >
      <defs>
        <path
          d="M154.76 216.08C197.01 216.08 534.99 216.08 577.24 216.08C598.65 216.08 616 233.35 616 254.65C616 268.44 616 378.73 616 392.52C616 413.81 598.65 431.09 577.24 431.09C534.99 431.09 197.01 431.09 154.76 431.09C133.36 431.09 116 413.81 116 392.52C116 383.33 116 337.37 116 254.65L116 254.65C127.57 228.94 140.5 216.08 154.76 216.08Z"
          id="c8ILDGNyP"
        />
        <path
          d="M423.36 337.44C417.13 337.44 411.94 331.53 411.94 323.64C411.94 315.55 416.51 309.53 423.36 309.53C430.63 309.53 434.67 315.76 434.67 322.92C434.67 331.53 430.41 337.44 423.36 337.44ZM411.94 372.3L411.94 342.94C412.07 342.94 412.14 342.94 412.15 342.94C415.69 349.37 422.75 351.75 429.28 351.75C445.36 351.75 453.97 338.48 453.97 322.5C453.97 309.43 445.78 295.21 430.83 295.21C422.33 295.21 414.44 298.64 410.7 306.21C410.69 306.21 410.62 306.21 410.49 306.21L410.49 296.46L393.27 296.46L393.27 372.3L411.94 372.3ZM490.78 326.24C493.58 326.24 496.28 326.44 498.66 326.55C498.66 332.88 494.2 339.31 487.14 339.31C482.78 339.31 479.47 337.14 479.47 333.39C479.47 328.21 484.44 326.24 490.78 326.24ZM516.09 337.75C516.09 335.74 516.09 319.64 516.09 317.62C516.09 301.13 504.16 295.21 490.36 295.21C482.38 295.21 475.43 296.35 468.78 299.05C468.81 300.32 469.07 310.45 469.1 311.72C474.27 308.81 480.3 307.66 486.32 307.66C493.05 307.66 498.55 309.64 498.66 317.01C496.28 316.59 492.95 316.27 489.94 316.27C479.99 316.27 462.04 318.25 462.04 334.74C462.04 346.47 471.58 351.75 482.27 351.75C489.94 351.75 495.14 348.76 499.39 342C499.41 342 499.58 342 499.6 342C499.6 344.81 499.9 347.6 500.01 350.51C501.16 350.51 506.86 350.51 517.12 350.51C516.43 344.84 516.09 340.59 516.09 337.75ZM537.14 373.55C554.26 373.55 558.3 360.37 563.6 346.78C564.92 343.43 571.52 326.65 583.42 296.46L564.74 296.46L553.64 331.74L553.43 331.74L541.81 296.46L521.69 296.46C534.82 329.64 542.12 348.07 543.58 351.75C542.23 356.53 538.7 359.23 534.13 359.23C531.53 359.23 529.26 358.91 526.76 358.09C526.68 359.04 526.27 363.77 525.52 372.3C530.64 373.14 534.51 373.55 537.14 373.55Z"
          id="m1rG1Kkw8"
        />
        <path
          d="M188.1 309.53C196.51 309.53 200.03 316.27 200.03 322.29C200.03 330.59 194.74 336.19 188.1 336.19C182.5 336.19 177.52 331.43 177.52 323.03C177.52 315.66 181.15 309.53 188.1 309.53ZM201.17 296.46L201.17 306.21C201.05 306.21 200.99 306.21 200.98 306.21C197.02 299.57 190.58 295.21 182.6 295.21C165.8 295.21 158.22 307.26 158.22 323.33C158.22 339.31 167.46 350.51 182.28 350.51C189.76 350.51 195.99 347.6 200.45 341.28C200.46 341.28 200.53 341.28 200.65 341.28C200.65 343.02 200.65 343.99 200.65 344.19C200.65 354.76 194.85 359.85 184.06 359.85C176.28 359.85 171.5 358.19 165.8 355.39C165.71 356.86 164.96 368.65 164.87 370.13C169.21 371.69 176.58 373.55 185.51 373.55C207.3 373.55 218.08 366.39 218.08 344.19C218.08 341.01 218.08 325.1 218.08 296.46L201.17 296.46ZM230.97 273.95L230.97 287.64L249.65 287.64L249.65 273.95L230.97 273.95ZM249.65 350.51L249.65 296.46L230.98 296.46L230.98 350.51L249.65 350.51ZM295.32 295.21C287.22 295.21 282.56 299.57 279.34 306.42C279.33 306.42 279.26 306.42 279.13 306.42L279.13 296.46L262.12 296.46L262.12 350.51L280.79 350.51C280.79 336.82 280.79 329.22 280.79 327.7C280.79 317.11 285.67 310.78 294.38 310.78C296.57 310.78 298.63 310.78 300.71 311.4C300.77 310.36 301.08 305.17 301.64 295.83C298.87 295.42 296.77 295.21 295.32 295.21ZM321.66 323.54C321.66 315.45 325.19 308.29 333.8 308.29C342.42 308.29 345.94 315.45 345.94 323.54C345.94 331.53 342.42 338.69 333.8 338.69C325.19 338.69 321.66 331.53 321.66 323.54ZM365.24 323.54C365.24 305.59 351.64 295.21 333.8 295.21C315.95 295.21 302.37 305.59 302.37 323.54C302.37 341.39 315.95 351.75 333.8 351.75C351.64 351.75 365.24 341.39 365.24 323.54Z"
          id="b1faW816Y1"
        />
        <path
          d="M373.97 216.08L384.3 216.08L384.3 431.09L373.97 431.09L373.97 216.08Z"
          id="h14kuLcmrS"
        />
      </defs>
      <g>
        <g>
          <g>
            <use
              xlinkHref="#c8ILDGNyP"
              opacity={1}
              fill="#ffffff"
              fillOpacity={1}
            />
            <g>
              <use
                xlinkHref="#c8ILDGNyP"
                opacity={1}
                fillOpacity={0}
                stroke="#000000"
                strokeWidth={1}
                strokeOpacity={0}
              />
            </g>
          </g>
          <g>
            <use
              xlinkHref="#m1rG1Kkw8"
              opacity={1}
              fill="#22312b"
              fillOpacity={1}
            />
            <g>
              <use
                xlinkHref="#m1rG1Kkw8"
                opacity={1}
                fillOpacity={0}
                stroke="#000000"
                strokeWidth={1}
                strokeOpacity={0}
              />
            </g>
          </g>
          <g>
            <use
              xlinkHref="#b1faW816Y1"
              opacity={1}
              fill="#22312b"
              fillOpacity={1}
            />
            <g>
              <use
                xlinkHref="#b1faW816Y1"
                opacity={1}
                fillOpacity={0}
                stroke="#000000"
                strokeWidth={1}
                strokeOpacity={0}
              />
            </g>
          </g>
          <g>
            <use
              xlinkHref="#h14kuLcmrS"
              opacity={1}
              fill="#22312b"
              fillOpacity={1}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default PaymentIcon;

PaymentIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};
