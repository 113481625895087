import React from 'react';
import styled from '@emotion/styled';
import { Colors, SvgWrapper, device, Container } from 'utils';
import { BeforeBackground, AfterBackground } from 'images';
const MuhammedQuot = styled.section`
  text-align: center;
  font-family: 'Shadows Into Light', cursive;
  p {
    font-size: 25px;
    max-width: 50%;
    margin: auto;
    padding-bottom: 70px;
    @media ${device.laptop} {
      font-size: 22px;
    }
    @media ${device.allMobile} {
      padding-bottom: 20px;
      font-size: 22px;
      max-width: 80%;
    }
  }
  q {
    display: block;
    position: relative;
    max-width: 70%;
    padding: 150px 0 50px;
    font-size: 30px;
    margin: auto;
    @media ${device.laptop} {
      font-size: 22px;
    }
    @media ${device.allMobile} {
      padding: 50px 0;
      font-size: 22px;
      max-width: 80%;
    }
    &::before {
      content: '“';
      font-size: 500px;
      line-height: 1;
      color: ${Colors.secondaryColor};
      position: absolute;
      top: -200px;
      left: -150px;
      @media ${device.allMobile} {
        font-size: 50px;
        top: -20px;
        left: -10px;
      }
      @media ${device.tablet} {
        font-size: 300px;
        top: -20px;
        left: -100px;
      }
      @media ${device.minLaptop} {
        font-size: 300px;
        top: -20px;
        left: -100px;
      }
    }
    &::after {
      content: '„';
      font-size: 500px;
      line-height: 1;
      position: absolute;
      color: ${Colors.secondaryColor};
      bottom: -150px;
      right: -150px;
      @media ${device.allMobile} {
        font-size: 50px;
        bottom: -20px;
        right: -10px;
      }
      @media ${device.tablet} {
        font-size: 300px;
        bottom: -150px;
        right: -100px;
      }
      @media ${device.minLaptop} {
        font-size: 300px;
        bottom: -150px;
        right: -100px;
      }
    }
    & + p {
      position: relative;
      &:after {
        content: '';
        width: 300px;
        height: 4px;
        display: inline-block;
        background: ${Colors.secondaryColor};
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -25px;
        @media ${device.allMobile} {
          width: 100px;
          top: -25px;
        }
      }
    }
  }
  span {
    font-size: 150px;
    color: ${Colors.secondaryColor};
    font-weight: bold;
    @media ${device.allMobile} {
      font-size: 70px;
    }
  }
`;
const SectionWrapper = styled.div`
  position: relative;
  .container {
    background: #f9f9f9;
    margin-top: -8px;
  }
`;
export default function QuranFirst() {
  return (
    <SectionWrapper>
      <div className="background">
        <SvgWrapper width="100%" height="100%">
          <BeforeBackground />
        </SvgWrapper>
      </div>
      <div className="container">
        <Container>
          <MuhammedQuot>
            <q>
              […] und dass es für den Menschen nichts anderes geben wird als
              das, worum er sich (selbst) bemüht […]
            </q>
            <p>[53:39]</p>
          </MuhammedQuot>
        </Container>
      </div>

      <div className="after-background">
        <SvgWrapper width="100%" height="100%">
          <AfterBackground />
        </SvgWrapper>
      </div>
    </SectionWrapper>
  );
}
