const getMyProjects = () => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  return fetch(`my_projects`, requestOptions)
    .then((projects) => {
      return { projects: projects };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const getProjects = (perPage, page, ids) => {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify({ ids }),
    redirect: 'follow',
  };
  return fetch(`projects?per_page=${perPage}&page=${page}`, requestOptions)
    .then((projects) => {
      return { projects: projects };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const getEventProjects = (perPage, page, ids) => {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify({ ids }),
    redirect: 'follow',
  };
  return fetch(`events?per_page=${perPage}&page=${page}`, requestOptions)
    .then((projects) => {
      return { projects: projects };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const getSuccessfullEventProjects = (perPage, page, ids) => {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify({ ids }),
    redirect: 'follow',
  };
  return fetch(`successful_events?per_page=${perPage}&page=${page}`, requestOptions)
    .then((projects) => {
      return { projects: projects };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const getAllSuccessfullEvent = (cat) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  return fetch(`category/successfull_projects?name=${cat}`,requestOptions)
  .then((projects) => {
      return { projects: projects };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const getRandomProjects = (projectId) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  return fetch(`random_projects?project_id=${projectId}`, requestOptions)
    .then((projects) => {
      return { projects: projects };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const getSuccessfulProjects = (perPage, page, ids) => {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify({ ids }),
    redirect: 'follow',
  };
  return fetch(
    `projects_successful?per_page=${perPage}&page=${page}`,
    requestOptions
  )
    .then((projects) => {
      return { projects: projects };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const getHomeProjects = () => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  return fetch(`projects/home_page`, requestOptions)
    .then((res) => {
      return { projects: res };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const getMySupportedProjects = () => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  return fetch(`my_supported_projects`, requestOptions)
    .then((projects) => {
      return { projects };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const showProject = (url) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(`project/${url}`, requestOptions)
    .then((project) => {
      return { project: project };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const createDraft = (project) => {
  const raw = JSON.stringify({ project });
  const requestOptions = {
    method: 'POST',
    body: raw,
    redirect: 'follow',
  };
  return fetch(`projects/create_draft`, requestOptions)
    .then((project) => {
      return { project: project };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const createEmailRequest = (project) => {
  const raw = JSON.stringify({ project });
  const requestOptions = {
    method: 'POST',
    body: raw,
    redirect: 'follow',
  };
  return fetch(`projects/project_email_request`, requestOptions)
    .then((project) => {
      return { project: project };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const updateDraft = (projectId, project) => {
  const raw = JSON.stringify({ project });
  const requestOptions = {
    method: 'PUT',
    body: raw,
    redirect: 'follow',
  };
  return fetch(`projects/${projectId}/update_draft`, requestOptions)
    .then((project) => {
      return { project: project };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const reEditDraft = (projectId) => {
  const requestOptions = {
    method: 'PUT',
    redirect: 'follow',
  };
  return fetch(`projects/${projectId}/reedit_draft`, requestOptions)
    .then((project) => {
      return { project: project };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const uploadImages = (projectId, formData) => {
  const requestOptions = {
    method: 'PUT',
    body: formData,
    redirect: 'follow',
  };
  return fetch(`projects/${projectId}/update_draft`, requestOptions)
    .then((project) => {
      return { project: project };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const createEditReward = (projectID, reward) => {
  const raw = JSON.stringify({ ...reward });
  const requestOptions = {
    method: 'POST',
    body: raw,
    redirect: 'follow',
  };
  return fetch(`projects/${projectID}/rewards`, requestOptions)
    .then((project) => {
      return { project: project };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const uploadRewardImage = (rewardId, formData) => {
  const requestOptions = {
    method: 'PUT',
    body: formData,
    redirect: 'follow',
  };
  return fetch(`rewards/${rewardId}/upload_image`, requestOptions)
    .then((project) => {
      return { project: project };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const deleteReward = (rewardId) => {
  const requestOptions = {
    method: 'DELETE',
    redirect: 'follow',
  };
  return fetch(`rewards/${rewardId}`, requestOptions)
    .then((updates) => {
      return { updates };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const submitDraft = (projectId) => {
  const requestOptions = {
    method: 'PUT',
    redirect: 'follow',
  };
  return fetch(`projects/${projectId}/submit_draft`, requestOptions)
    .then((project) => {
      return { project: project };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const checkUrl = (url) => {
  const raw = JSON.stringify({ project: { url } });
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
    body: raw,
  };
  return fetch(`projects/check_url`, requestOptions)
    .then((result) => {
      return { isUniq: !result };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const getProjectCategories = () => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  return fetch(`categories`, requestOptions)
    .then((categories) => {
      return { categories: categories };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const getProjectAdminCategories = () => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  return fetch(`admin/categories`, requestOptions)
    .then((categories) => {
      return { adminCategories: categories };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const getProjectCreationCategories = () => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  return fetch(`categories_for_creation`, requestOptions)
    .then((categories) => {
      return { categoriesCreation: categories };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const getProjectUpdates = (projectId) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  return fetch(`updates/${projectId}`, requestOptions)
    .then((updates) => {
      return { updates };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const createProjectUpdates = (formdata) => {
  const requestOptions = {
    method: 'POST',
    redirect: 'follow',
    body: formdata,
  };
  return fetch(`updates`, requestOptions)
    .then((update) => {
      return { update };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const deleteProjectUpdates = async (updateId) => {
  const requestOptions = {
    method: 'DELETE',
    redirect: 'follow',
  };
  try {
    const updates = await fetch(`updates/${updateId}`, requestOptions);
    return { updates };
  } catch (error) {
    return await Promise.reject({ errors: error });
  }
};

const getProjectStatus = (id) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  return fetch(`projects/${id}/status`, requestOptions)
    .then((projectStatus) => {
      return { projectStatus };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const removeEdit = (id) => {
  const requestOptions = {
    method: 'PUT',
    redirect: 'follow',
  };
  return fetch(`projects/${id}/remove_edit`, requestOptions)
    .then((projectStatus) => {
      return { projectStatus };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const getProjectRewards = (id, forOwner = false) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  return fetch(
    `projects/${id}/rewards${forOwner ? '?for_order=1' : ''}`,
    requestOptions
  )
    .then((result) => result)
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const getRewardQuestions = (id) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  return fetch(`rewards/${id}/questions`, requestOptions)
    .then((result) => result)
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const getRewardOrder = (id, perPage, page) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  return fetch(
    `rewards/${id}/orders?per_page=${perPage}&page=${page}`,
    requestOptions
  )
    .then((result) => result)
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const getUserRewards = (perPage, page) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  return fetch(
    `user/rewards/orders?per_page=${perPage}&page=${page}`,
    requestOptions
  )
    .then((result) => result)
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const toggleOrderSent = (id) => {
  const requestOptions = {
    method: 'PUT',
    redirect: 'follow',
  };
  return fetch(`rewards/orders/${id}`, requestOptions)
    .then((project) => {
      return { project: project };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const projectServices = {
  getMyProjects,
  getMySupportedProjects,
  showProject,
  createDraft,
  createEmailRequest,
  updateDraft,
  uploadImages,
  uploadRewardImage,
  createEditReward,
  deleteReward,
  submitDraft,
  checkUrl,
  getProjectCategories,
  getProjectAdminCategories,
  getProjectCreationCategories,
  getProjects,
  getProjectUpdates,
  createProjectUpdates,
  deleteProjectUpdates,
  reEditDraft,
  getProjectStatus,
  getRandomProjects,
  getHomeProjects,
  removeEdit,
  getSuccessfulProjects,
  getProjectRewards,
  getRewardQuestions,
  getRewardOrder,
  toggleOrderSent,
  getUserRewards,
  getEventProjects,
  getSuccessfullEventProjects,
  getAllSuccessfullEvent
};

export default projectServices;
