/* eslint-disable prettier/prettier */
import React, { Component } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Carousel, Spin } from 'antd';
import { VisaCard } from 'components';
import { Colors, IsLoggedIn, Modal, PrimaryButton } from 'utils';
import { PaymentActions } from 'redux/actions';
import { AddCard } from 'components';
import isEmpty from 'loadsh/isEmpty';

const PaymentWrapper = styled.div`
  margin-bottom: 25px;
  h2 {
    font-size: 24px;
    font-weight: bold;
  }
  .slick-dots.slick-dots-bottom {
    bottom: -16px;
    li {
      button {
        background: #060c2b;
        &:before {
          content: none;
        }
      }
    }
  }
`;

// const PaymentButtons = styled.div`
//   margin-top: 20px;
//   text-align: center;
//   button {
//     display: inline-block;
//     width: 48%;
//     border-radius: 6px;
//     box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
//     border: solid 0.5px ${Colors.GreenColor};
//     background: none;
//     padding: 10px;
//     & + button {
//       margin-left: 20px;
//     }
//   }
// `;

const AddNewPayment = styled.div`
  margin-top: 60px;
  h3 {
    font-size: 20px;
    font-weight: 600;
    color: ${Colors.GreenColor};
    margin-bottom: 10px;
  }
  p {
    font-size: 14px;
    color: #060c2b;
  }
`;
export class CardsComponent extends Component {
  static propTypes = {
    cards: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    errors: PropTypes.array.isRequired,
    getMyCards: PropTypes.func.isRequired,
    deleteCard: PropTypes.func.isRequired,
    setCard: PropTypes.func.isRequired,
    selectedCard: PropTypes.object.isRequired,
    noPay: PropTypes.bool,
  };
  state = {
    showModal: false,
  };
  componentDidMount() {
    const { getMyCards } = this.props;
    if (IsLoggedIn()) {
      return getMyCards();
    } else {
      return;
    }
  }

  handelCloseModal = () => {
    this.setState({ showModal: false });
    document.body.style.overflowY = 'unset';
  };

  handelShowModal = () => {
    this.setState({ showModal: true });
    document.body.style.overflowY = 'hidden';
  };
  render() {
    const { showModal } = this.state;
    const { cards, loading, deleteCard, setCard, selectedCard, noPay } =
      this.props;
    const thereIsACard = !isEmpty(selectedCard);

    return (
      <>
        <PaymentWrapper className="profile-border">
          {IsLoggedIn() ? (
            <Spin spinning={loading}>
              {thereIsACard ? (
                <>
                  <VisaCard
                    key={JSON.stringify(selectedCard)}
                    id={selectedCard.Id}
                    name={' '}
                    type={selectedCard.CardType}
                    lastNumber={selectedCard.Alias.substr(
                      selectedCard.Alias.length - 4
                    )}
                    deleteCard={deleteCard}
                  />
                </>
              ) : (
                <>
                  <Carousel
                    autoplay
                    dots={true}
                    slidesToShow={cards.length > 1 ? 2 : 1}
                  >
                    {cards.map((card) => (
                      <VisaCard
                        key={JSON.stringify(card)}
                        id={card.Id}
                        name={' '}
                        type={card.CardType}
                        lastNumber={card.Alias.substr(card.Alias.length - 4)}
                        deleteCard={deleteCard}
                        setCard={noPay ? null : setCard}
                      />
                    ))}
                  </Carousel>
                </>
              )}

              <AddNewPayment>
                <h3>Add New Payment</h3>
                <p>Please enter your payment method</p>
              </AddNewPayment>
              <PrimaryButton
                minWidth="100%"
                type="button"
                onClick={() => {
                  this.handelShowModal();
                }}
              >
                Add New
              </PrimaryButton>
            </Spin>
          ) : (
            <>
              {thereIsACard ? (
                <>
                  <VisaCard
                    key={JSON.stringify(selectedCard)}
                    id={selectedCard.Id}
                    name={' '}
                    type={selectedCard.CardType}
                    lastNumber={selectedCard.Alias.substr(
                      selectedCard.Alias.length - 4
                    )}
                    deleteCard={deleteCard}
                    noActions={true}
                  />
                </>
              ) : (
                <>
                  <AddNewPayment>
                    <h3>Add Payment method</h3>
                  </AddNewPayment>
                  <PrimaryButton
                    minWidth="100%"
                    type="button"
                    onClick={() => {
                      this.handelShowModal();
                    }}
                  >
                    Add New
                  </PrimaryButton>
                </>
              )}
            </>
          )}
        </PaymentWrapper>
        <Modal showModal={showModal} handelCloseModal={this.handelCloseModal}>
          <AddCard closeModal={this.handelCloseModal} />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { selectedCard, cards, loading, errors } = state.payment;
  return {
    cards,
    loading,
    errors,
    selectedCard,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getMyCards: () => dispatch(PaymentActions.getMyCards()),
  deleteCard: (cardId) => dispatch(PaymentActions.deleteCard(cardId)),
  setCard: (cardId) => dispatch(PaymentActions.setCard(cardId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CardsComponent);
