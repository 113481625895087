function createFile(url) {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  return fetch(url, requestOptions)
    .then((response) => {
      // console.log(response.hasOwnProperty('blob'));
      if (response.hasOwnProperty('blob')) return response.blob();
      return response;
    })
    .then((res) => {
      let file;
      let metadata = {
        type: 'image/png, image/jpeg',
      };
      const fileName = url.replace(
        'https://api.commonsplace.de/rails/active_storage/blobs/',
        ''
      );
      file = new File([res], fileName, metadata);
      return {
        uid: fileName,
        url: url,
        name: fileName,
        status: 'done',
        originFileObj: file,
      };
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export default createFile;
