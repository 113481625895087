import React, { Component } from 'react';
import { Button, Form, Tooltip } from 'antd';
import {
  PrimaryButton,
  H1,
  numberWithCommas,
  device,
  Colors,
  formValidateMessages,
  Modal,
} from 'utils';
import { ChooseRewards, Danke } from '.';
import PropTypes from 'prop-types';
import CurrencyInput from 'react-currency-input-field';
import styled from '@emotion/styled';
import DropDown from './DropDown';
import { connect } from 'react-redux';
import { InfoCircleOutlined } from '@ant-design/icons';
import '../../../utils/Payment';
const FormItem = Form.Item;

const DonationWrapper = styled.div`
  .currency-input {
    margin: 0;
    input,
    button {
      padding-right: 20px !important;
    }
    &::after {
      width: 20px;
      border: none;
      background: none;
      line-height: 34px;
      @media ${device.tablet} {
        line-height: 25px;
      }
      @media ${device.allMobile} {
        line-height: 25px;
      }
    }
  }
  .donation-input-failed {
    border: ${(props) =>
      props.donationLessThenFive
        ? `1px solid ${Colors.red}`
        : `1px solid ${Colors.steel}`} !important;
    label {
      color: ${(props) =>
        props.donationLessThenFive ? `${Colors.red}` : `${Colors.black}`};
    }
  }
  .primary-button {
    @media ${device.laptop} {
      padding: 15px 14px;
    }
    @media ${device.allMobile} {
      padding: 5px 7px;
      font-size: 14px;
      border-radius: 5px;
      display: flex !important;
      flex-direction: row !important;
    }
    @media ${device.tablet} {
      padding: 5px 7px;
      font-size: 14px;
      border-radius: 5px;
    }
  }
  .feriwill-button {
    font-weight: bold;
    color: black;
    border: 1px solid black;
    border-radius: 10px;
    font-size: 24px;
    padding: 10px;
    text-align: right;
    max-width: 100px;
    background: none;
    width: 100%;
    @media ${device.allMobile} {
      font-size: 16px;
    }
  }
  .feriwill-button:hover {
    color: #95bd51;
    border-color: #95bd51;
  }
  .ant-form-item-explain.ant-form-item-explain-error {
    font-size: 14px;
    @media ${device.tablet} {
      font-size: 12px;
    }
  }
  .small-font {
    font-size: 0.9rem;
    @media ${device.allMobile} {
      display: block;
    }
  }
  .tip-btn {
    &:hover {
      background: #95bd51;
      color: #000;
    }
  }
  .text-setting {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media screen and (max-width: 425px) {
    .donation-input .ant-form-item-label {
      font-size: 14px !important;
    }
    .text-setting {
      display: flex;
      // align-items: center;
      // justify-content: center;
      // width: 100% !important;
    }
    .label-blk {
      .ant-form-item-label {
        // width: 100% !important;
        // max-width: 100% !important;
        label {
          width: 100% !important;
        }
      }
      .ant-form-item-control {
        width: 100% !important;
        max-width: 100% !important;
        // text-align: center !important;
        @media (max-width: 575px) {
          flex: 0 0 50% !important;
        }
      }
    }
    .donation-input-failedd {
      display: flex !important;
      flex-direction: column;
      width: 100%;
    }
  }
  .donation-override .ant-form-item-control {
    @media (min-width: 1201px) {
      max-width: 250px !important;
    }
    @media (max-width: 1200px) and (min-width: 1000px) {
      max-width: 220px !important;
    }
    @media (max-width: 999px) and (min-width: 950px) {
      max-width: 120px !important;
    }
    @media (max-width: 820px) and (min-width: 767px) {
      max-width: 96px !important;
    }
  }
`;
// const DonationLessThenFiveError = styled.p`
//   color: ${Colors.red};
//   margin-top: -24px;
// `;
class Donation extends Component {
  static propTypes = {
    showPaymentForm: PropTypes.bool.isRequired,
    donation: PropTypes.any.isRequired,
    freiwilliges: PropTypes.any.isRequired,
    showOption: PropTypes.bool.isRequired,
    otherOption: PropTypes.bool.isRequired,
    originalDonation: PropTypes.number.isRequired,
    setDonationState: PropTypes.func.isRequired,
    setCurrentViewState: PropTypes.func.isRequired,
    setShowOptionState: PropTypes.func.isRequired,
    setOtherOptionState: PropTypes.func.isRequired,
    setFreiwilligesState: PropTypes.func.isRequired,
    setShowPaymentFormState: PropTypes.func.isRequired,
    projectId: PropTypes.string.isRequired,
    originalRewardId: PropTypes.number.isRequired,
    setAllRewards: PropTypes.func.isRequired,
    setSelectedRewards: PropTypes.func.isRequired,
    setAddressRequired: PropTypes.func.isRequired,
    allRewards: PropTypes.any.isRequired,
    selectedRewards: PropTypes.any.isRequired,
    formRef: PropTypes.object.isRequired,
    handleCLientSecret: PropTypes.func.isRequired,
    handelShowModal: PropTypes.any.isRequired,
    isTicketable: PropTypes.bool.isRequired,
  };
  state = {
    selectedOption: null,
    donationLessThenFive: false,
    canChangeFeriwilliges: true,
    lastDonation: 0,
    selectedKey: 0,
    showGoodiesLimitModal: false,
    goodiesWithLimitReached: [],
  };

  numberToString = (value) => {
    return parseFloat(value).toFixed(2);
  };

  handleSetSelectedKey = (key) => {
    this.setState({ selectedKey: key });
    this.props.saveKey(key);
  };

  mapDonatiosToValue = (value) => {
    let mappedValue, donationValue;

    if (value && typeof value === 'string' && value.includes(',')) {
      const splitNumber = value.split(',');
      const decimalTens = splitNumber[1]
        ? parseFloat(splitNumber[1][0]) / 10
        : 0;
      const decimalHundreds =
        splitNumber[1] && splitNumber[1].length > 1
          ? parseFloat(splitNumber[1][1]) / 100
          : 0;
      const decimalNumber = decimalTens + decimalHundreds;
      donationValue = parseFloat(splitNumber[0]) + decimalNumber;
    } else {
      donationValue = value ? parseFloat(Number(value)) : 0;
    }
    switch (true) {
      case donationValue < 7:
        mappedValue = 1;
        break;
      case donationValue < 10:
        mappedValue = 1.5;
        break;
      case donationValue < 12:
        mappedValue = 2;
        break;
      case donationValue < 15:
        mappedValue = 2.5;
        break;
      case donationValue < 17:
        mappedValue = 3;
        break;
      case donationValue < 20:
        mappedValue = 3.5;
        break;
      case donationValue < 22:
        mappedValue = 4;
        break;
      case donationValue < 25:
        mappedValue = 4.5;
        break;
      case donationValue < 27:
        mappedValue = 5;
        break;
      case donationValue < 30:
        mappedValue = 5.5;
        break;
      case donationValue < 32:
        mappedValue = 6;
        break;
      case donationValue < 35:
        mappedValue = 6.5;
        break;
      case donationValue < 37:
        mappedValue = 7;
        break;
      case donationValue < 40:
        mappedValue = 7.5;
        break;
      case donationValue < 50:
        mappedValue = 8;
        break;
      case donationValue < 60:
        mappedValue = 9;
        break;
      case donationValue < 70:
        mappedValue = 10;
        break;
      case donationValue < 80:
        mappedValue = 11;
        break;
      case donationValue < 90:
        mappedValue = 12;
        break;
      case donationValue < 100:
        mappedValue = 13;
        break;
      case donationValue < 110:
        mappedValue = 14;
        break;
      case donationValue < 120:
        mappedValue = 15;
        break;
      case donationValue < 130:
        mappedValue = 16;
        break;
      case donationValue < 140:
        mappedValue = 17;
        break;
      case donationValue < 150:
        mappedValue = 18;
        break;
      case donationValue < 160:
        mappedValue = 19;
        break;
      case donationValue < 170:
        mappedValue = 20;
        break;
      case donationValue < 180:
        mappedValue = 21;
        break;
      case donationValue < 190:
        mappedValue = 22;
        break;
      case donationValue < 200:
        mappedValue = 23;
        break;
      case donationValue < 300:
        mappedValue = 24;
        break;
      case donationValue < 400:
        mappedValue = 33;
        break;
      case donationValue < 500:
        mappedValue = 40;
        break;
      case donationValue < 600:
        mappedValue = 45;
        break;
      case donationValue < 700:
        mappedValue = 50;
        break;
      case donationValue < 800:
        mappedValue = 55;
        break;

      default:
        mappedValue = 60;
        break;
    }

    return mappedValue;
  };

  handelCloseGoodiesLimitModal = () => {
    this.setState({ showGoodiesLimitModal: false });
    document.body.style.overflowY = 'unset';
  };

  componentDidMount() {
    const { donation, setFreiwilligesState } = this.props;
    setFreiwilligesState(
      this.numberToString(this.mapDonatiosToValue(donation))
    );
  }

  selectedRewardsPrice = () => {
    const { selectedRewards } = this.props;
    let totalPrice = 0;

    selectedRewards.forEach((reward) => {
      totalPrice += Number(reward.price);
    });

    return totalPrice;
  };

  // Check if the Selected Rewards were changed
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedRewards !== this.props.selectedRewards) {
      const { donation, setFreiwilligesState, selectedRewards } = this.props;
      const totalDonation = Number(donation) + this.selectedRewardsPrice();
      // Set the Tips from the Rewards and Donation
      setFreiwilligesState(
        this.numberToString(this.mapDonatiosToValue(totalDonation))
      );
      // Check if the total has passed 5
      if (totalDonation < 5) {
        this.setState({ donationLessThenFive: true });
      } else {
        this.setState({ donationLessThenFive: false });
      }
      // Revalidate the form
      this.props.formRef.current.validateFields();
      this.handleCheckRewardLimit();
    }
  }

  handleCheckRewardLimit = () => {
    const { selectedRewards } = this.props;
    const limitReachedGoodies = selectedRewards?.filter(
      (goodie) => goodie?.max_order_count === goodie?.ordered_reward_count
    );
    if (limitReachedGoodies.length > 0) {
      this.setState({ showGoodiesLimitModal: true });
      this.setState({ goodiesWithLimitReached: limitReachedGoodies });
    }
  };
  render() {
    const {
      donation,
      freiwilliges,
      showOption,
      otherOption,
      showPaymentForm,
      setDonationState,
      setCurrentViewState,
      setShowOptionState,
      setOtherOptionState,
      setFreiwilligesState,
      setShowPaymentFormState,
      handelShowModal,
      projectId,
      originalRewardId,
      isTicketable,
      selectedRewards,
    } = this.props;
    const { showGoodiesLimitModal, goodiesWithLimitReached } = this.state;
    // Total Sum
    const totalPriceSum = parseFloat(
      Number(freiwilliges) +
        Number(this.selectedRewardsPrice()) +
        Number(donation)
    ).toFixed(2);
    const donationTypeOptions = [
      {
        label: 'Einmalig',
        key: 0,
      },
      {
        label: 'Täglich',
        key: 1,
      },
      {
        label: 'Wöchentlich',
        key: 2,
      },
      {
        label: 'Monatlich',
        key: 3,
      },
    ];
    const totalRewardAmount = JSON.parse(
      localStorage.getItem('answers')
    )?.reduce((prev, curre) => {
      return prev + curre.price;
    }, 0);
    const total =
      Number(localStorage.getItem('amount') || 0) +
      Number(localStorage.getItem('tips') || 0) +
      (totalRewardAmount || 0);
    const {
      selectedOption,
      donationLessThenFive,
      canChangeFeriwilliges,
      lastDonation,
    } = this.state;

    const usedDonation = canChangeFeriwilliges
      ? Number(donation) + Number(this.selectedRewardsPrice())
      : Number(lastDonation) + Number(this.selectedRewardsPrice());

    // TODO CHANGE HOW THIS WORKS
    const changeFreiwillings = (value) => {
      this.props.saveDonation(value);
      const totalDonation = value
        ? Number(value.replaceAll('.', '').replaceAll(',', '.')) +
          Number(this.selectedRewardsPrice())
        : 0 + Number(this.selectedRewardsPrice());

      // Mapping the Value to the desired values
      const feriwilliges = this.mapDonatiosToValue(totalDonation);

      // Check the value and Change it
      if (totalDonation < 5) {
        this.setState({ donationLessThenFive: true });

        if (canChangeFeriwilliges) {
          setFreiwilligesState(this.numberToString(1));
        }
      } else {
        this.setState({ donationLessThenFive: false });

        setDonationState(
          this.numberToString(
            value ? Number(value.replaceAll('.', '').replaceAll(',', '.')) : 0
          )
        );
        if (canChangeFeriwilliges) {
          setFreiwilligesState(this.numberToString(feriwilliges));
        }
      }
    };
    // Get Payment_intend Check for paypal after redirect
    const searchParams = new URLSearchParams(document.location.search);
    const payment_id = searchParams.get('paymentId');
    const payment_intent = searchParams.get('payment_intent');

    return (
      <>
        <DonationWrapper donationLessThenFive={donationLessThenFive}>
          <Form
            validateMessages={formValidateMessages}
            ref={this.props.formRef}
            initialValues={{
              donation,
              freiwilliges,
            }}
            scrollToFirstError
          >
            <div>
              <H1 className="payment_summary">Zahlungsübersicht</H1>

              {!isTicketable && (
                <>
                  <FormItem
                    name={'donation'}
                    label={
                      this.props.selectedRewards
                        ? 'Freie Unterstützung'
                        : 'Unterstützung'
                    }
                    className="donation-input donation-input-failed"
                    labelCol={{ xl: 13, lg: 13, md: 16, sm: 14 }}
                    wrapperCol={{ xl: 11, lg: 10, md: 7, sm: 10 }}
                    rules={[
                      () => ({
                        validator() {
                          if (!donationLessThenFive) {
                            return Promise.resolve();
                          }

                          return Promise.reject(
                            new Error('Der Mindestgesamtbetrag beträgt 5 €')
                          );
                        },
                      }),
                    ]}
                  >
                    <div className="currency-input">
                      <CurrencyInput
                        allowNegativeValue={false}
                        decimalSeparator=","
                        groupSeparator="."
                        placeholder="0,00"
                        defaultValue={
                          payment_id == undefined || payment_intent == undefined
                            ? donation
                            : Number(localStorage.getItem('amount')).toFixed(2)
                        }
                        autoFocus
                        onValueChange={(value) => changeFreiwillings(value)}
                        disabled={isTicketable}
                      />
                    </div>
                  </FormItem>
                  <div className="label-blk">
                    <FormItem
                      label={
                        <div className="text-setting">
                          <div className="text">Rhythmus</div>
                          <Tooltip
                            title={
                              <span style={{ color: '#fff' }}>
                                Wiederkehrende Unterstützungen sind nur per
                                Kreditkarte möglich. Sie können jederzeit über
                                den Link in der Bestätigungsemail deaktiviert
                                werden. Ein commonsplace-Account ist
                                erforderlich, und nicht registrierte Nutzer
                                müssen sich während des Prozesses anmelden. Zum
                                Projektende endet die Unterstützung.
                              </span>
                            }
                            color="#95bd51"
                            overlayClassName="custom-tooltip"
                          >
                            <Button type="link" style={{ padding: '0px 5px' }}>
                              <InfoCircleOutlined
                                style={{ color: 'black', fontSize: '20px' }}
                              />
                            </Button>
                          </Tooltip>
                        </div>
                      }
                      className="donation-input donation-input-failed donation-input-failed"
                      labelCol={{ xl: 13, lg: 13, md: 12, sm: 12, xs: 12 }}
                      wrapperCol={{ xl: 11, lg: 10, md: 12, sm: 12, xs: 12 }}
                    >
                      <DropDown
                        setSelectedKey={this.handleSetSelectedKey}
                        options={donationTypeOptions}
                      />
                    </FormItem>
                  </div>
                </>
              )}
              {/* {donationLessThenFive && (
              <DonationLessThenFiveError>
                Der Mindestbeitrag liegt bei 5 €
              </DonationLessThenFiveError>
            )} */}

              <ChooseRewards
                isTicketable={isTicketable}
                projectId={projectId}
                originalRewardId={originalRewardId}
                donationLessThenFive={donationLessThenFive}
                setAllRewards={this.props.setAllRewards}
                setSelectedRewards={this.props.setSelectedRewards}
                setAddressRequired={this.props.setAddressRequired}
                allRewards={this.props.allRewards}
                selectedRewards={this.props.selectedRewards}
                handleCLientSecret={this.props.handleCLientSecret}
                formRef={this.props.formRef}
              />

              <div
                className={
                  showOption
                    ? 'freiwilliges-input show-options'
                    : 'freiwilliges-input'
                }
              >
                <FormItem
                  name={'freiwilliges'}
                  label={
                    <div>
                      Trinkgeld*{' '}
                      <span className="small-font">(inkl. MwSt.)</span>
                    </div>
                  }
                  className="donation-input"
                  rules={[
                    {
                      required: true,
                      message: 'Bitte gib einen Betrag an.',
                    },
                  ]}
                >
                  {showOption ? (
                    <div className="freiwilliges-option">
                      <div>
                        <PrimaryButton
                          type="button"
                          minWidth={'25px'}
                          primary={selectedOption === '50%' ? false : true}
                          className="primary-button tip-btn"
                          onClick={() => {
                            this.setState({ selectedOption: '50%' });
                            setFreiwilligesState(
                              this.numberToString(
                                this.mapDonatiosToValue(usedDonation) * 0.5
                              )
                            );
                          }}
                        >
                          {this.numberToString(
                            this.mapDonatiosToValue(usedDonation) * 0.5
                          )}{' '}
                          €
                        </PrimaryButton>
                      </div>
                      <div>
                        <PrimaryButton
                          type="button"
                          minWidth={'25px'}
                          primary={selectedOption === '25%' ? false : true}
                          className="primary-button tip-btn"
                          onClick={() => {
                            this.setState({ selectedOption: '25%' });
                            setFreiwilligesState(
                              this.numberToString(
                                this.mapDonatiosToValue(usedDonation) * 0.25
                              )
                            );
                          }}
                        >
                          {this.numberToString(
                            this.mapDonatiosToValue(usedDonation) * 0.25
                          )}{' '}
                          €
                        </PrimaryButton>
                      </div>
                      <div>
                        <PrimaryButton
                          type="button"
                          minWidth={'25px'}
                          primary={true}
                          className="primary-button"
                          onClick={() => {
                            setFreiwilligesState(
                              this.numberToString(
                                this.mapDonatiosToValue(usedDonation)
                              )
                            );
                            setShowOptionState(false);
                            setOtherOptionState(true);
                          }}
                        >
                          Ändern
                        </PrimaryButton>
                      </div>
                    </div>
                  ) : otherOption ? (
                    <div className="currency-input">
                      <CurrencyInput
                        allowNegativeValue={false}
                        decimalSeparator=","
                        groupSeparator="."
                        placeholder="0,00"
                        value={
                          payment_id == undefined || payment_intent == undefined
                            ? freiwilliges
                            : localStorage.getItem('tips')
                        }
                        defaultValue={
                          payment_id == undefined || payment_intent == undefined
                            ? freiwilliges
                            : localStorage.getItem('tips')
                        }
                        onChange={(e) => {
                          setFreiwilligesState(
                            Number(
                              e.target.value
                                .replaceAll('.', '')
                                .replaceAll(',', '.')
                            )
                          );
                        }}
                      />
                    </div>
                  ) : (
                    <div>
                      <button
                        className="feriwill-button"
                        size="large"
                        onClick={() => {
                          setShowOptionState(true);
                          this.setState({
                            canChangeFeriwilliges: false,
                            lastDonation: donation,
                          });
                        }}
                      >
                        {payment_id != undefined || payment_intent != undefined
                          ? localStorage.getItem('tips')
                          : numberWithCommas(freiwilliges)}{' '}
                        €
                      </button>
                    </div>
                  )}
                </FormItem>
              </div>
              <Danke handelShowModal={handelShowModal} />
              <div className="gesamtsumme">
                <h2>Gesamtsumme</h2>
                {payment_id != undefined || payment_intent != undefined ? (
                  <p>{numberWithCommas(total.toFixed(2) || '0,00')} €</p>
                ) : (
                  <p>{numberWithCommas(totalPriceSum || '0,00')} €</p>
                )}
              </div>
              {/* {!showPaymentForm && (
              <PrimaryButton
                className="gesamtsumme-button"
                type="button"
                minWidth={'100%'}
                onClick={() => {
                  setShowPaymentFormState(true);
                  setCurrentViewState('info');
                }}
              >
                Weiter zur Zahlung
              </PrimaryButton>
            )} */}
            </div>
          </Form>
        </DonationWrapper>
        <Modal
          showModal={showGoodiesLimitModal}
          handelCloseModal={this.handelCloseGoodiesLimitModal}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <h1 style={{ color: '#95bd51' }}>Goodies</h1>
            {goodiesWithLimitReached?.map((goodie) => {
              return <p>Goodie: {goodie?.title}</p>;
            })}
            {isTicketable ? (
              <p>Ticket Limit erreicht</p>
            ) : (
              <p> Maximales Goodies-Limit erreicht! </p>
            )}
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    donationType: state.donationType,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    saveKey: (key) => dispatch({ type: 'SAVE_KEY', payload: key }),
    saveDonation: (amount) =>
      dispatch({ type: 'SAVE_AMOUNT', payload: amount }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Donation);
