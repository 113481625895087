/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { Colors } from 'utils';

const RightArrow = ({ color, width, height }) => {
  return (
    <svg
      height={`${height || '100%'}`}
      width={`${width || '100%'}`}
      viewBox="0 0 512.171 512.171"
      enableBackground="new 0 0 512 512"
      css={css`
        fill: ${color || Colors.secondaryColor};
        max-width: ${width || '100%'};
        max-height: ${height || '100%'};
        height: ${height || '100%'};
        width: ${width || '100%'};
        display: inline-block;
      `}
      version="1.1"
      x="0"
      y="0"
      xmlSpace="preserve"
    >
      <g>
        <g>
          <g>
            <path
              d="M509.035,248.212l-213.504-212.8c-3.051-3.029-7.595-3.904-11.627-2.304c-3.989,1.664-6.571,5.547-6.571,9.856v117.333    H10.667C4.779,160.298,0,165.076,0,170.964v170.667c0,5.888,4.779,10.667,10.667,10.667h266.667v116.885    c0,4.309,2.603,8.192,6.592,9.856c1.323,0.555,2.709,0.811,4.075,0.811c2.773,0,5.504-1.088,7.552-3.115l213.504-213.419    c2.005-2.005,3.115-4.715,3.115-7.552C512.171,252.927,511.04,250.218,509.035,248.212z"
              fill={`${color || Colors.secondaryColor}`}
              data-original="#000000"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default RightArrow;

RightArrow.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};
