/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { Colors } from 'utils';

const YoutubeIcon = ({ color, width, height }) => {
  return (
    <svg
      height={`${height || '24'}px`}
      width={`${width || '24'}px`}
      viewBox="0 0 24 24"
      css={css`
        fill: ${color || Colors.secondaryColor};
        max-width: ${width || '24'}px;
        max-height: ${height || '24'}px;
        height: ${height || '24'}px;
        width: ${width || '24'}px;
      `}
    >
      <g id="surface1">
        <path
          style={{
            stroke: 'none',
            fillRull: 'nonzero',
            fill: color || Colors.secondaryColor,
            fillOpacity: '1',
          }}
          d="M 16.550781 11.878906 L 10.691406 8.21875 C 10.464844 8.074219 10.179688 8.066406 9.949219 8.195312 C 9.714844 8.328125 9.570312 8.570312 9.570312 8.835938 L 9.570312 16.164062 C 9.570312 16.429688 9.714844 16.671875 9.949219 16.804688 C 10.058594 16.863281 10.179688 16.894531 10.300781 16.894531 C 10.4375 16.894531 10.574219 16.855469 10.691406 16.78125 L 16.550781 13.121094 C 16.765625 12.988281 16.894531 12.753906 16.894531 12.5 C 16.894531 12.246094 16.765625 12.011719 16.550781 11.878906 Z M 11.035156 14.839844 L 11.035156 10.160156 L 14.78125 12.5 Z M 11.035156 14.839844 "
        />
        <path
          style={{
            stroke: 'none',
            fillRull: 'nonzero',
            fill: color || Colors.secondaryColor,
            fillOpacity: '1',
          }}
          d="M 21.335938 2.976562 L 3.664062 2.976562 C 1.644531 2.976562 0 4.621094 0 6.640625 L 0 18.359375 C 0 20.378906 1.644531 22.023438 3.664062 22.023438 L 21.335938 22.023438 C 23.355469 22.023438 25 20.378906 25 18.359375 L 25 6.640625 C 25 4.621094 23.355469 2.976562 21.335938 2.976562 Z M 23.535156 18.359375 C 23.535156 19.570312 22.550781 20.554688 21.335938 20.554688 L 3.664062 20.554688 C 2.449219 20.554688 1.464844 19.570312 1.464844 18.359375 L 1.464844 6.640625 C 1.464844 5.429688 2.449219 4.445312 3.664062 4.445312 L 21.335938 4.445312 C 22.550781 4.445312 23.535156 5.429688 23.535156 6.640625 Z M 23.535156 18.359375 "
        />
      </g>
    </svg>
  );
};

export default YoutubeIcon;

YoutubeIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};
