import React from 'react';
import { Card } from 'components';
import styled from '@emotion/styled';
import { Colors, device } from 'utils';
import PropTypes from 'prop-types';

const CardsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding: 30px 40px;
  @media ${device.allMobile} {
    width: 90%;
    margin: auto;
    justify-content: center;
    padding: 15px 0;
  }
  .card {
    width: 32%;
    display: inline-block;
    text-align: center;
    margin-bottom: 20px;

    & > article {
      max-width: 300px;
    }

    @media ${device.allMobile} {
      width: 90%;
      margin: auto;
      margin-bottom: 20px;
    }
  }
`;

const RelatedProjectsWrapper = styled.div`
  text-align: center;
  margin-top: 30px;
  h1 {
    font-size: 30px;
    color: ${Colors.GreenColor};
    border-bottom: 2px solid ${Colors.secondaryColor};
    padding: 0 60px 10px 60px;
    display: inline-block;
    margin: 0 auto 25px;
    @media ${device.allMobile} {
      text-align: center;
      font-size: 24px;
      width: 90%;
      margin: auto;
      margin-bottom: 20px;
    }
  }
  & > a {
    display: inline-block;
    text-decoration: none;
    margin: 20px auto 0;
    color: ${Colors.secondaryColor};
    &:hover {
      border-bottom: 2px solid ${Colors.secondaryColor};
    }
  }
`;

export default function RelatedProjects({
  randomProjects,
  isTicketable = false,
}) {
  return (
    <RelatedProjectsWrapper>
      {isTicketable ? (
        <h2>Crowdfunding Projekte Deiner Community</h2>
      ) : (
        <h2>Weitere Projekte</h2>
      )}
      <CardsWrapper>
        {randomProjects.length > 0 &&
          randomProjects.map((project, index) => (
            <Card
              project={project}
              key={`${JSON.stringify(project)}-${index}`}
            />
          ))}
      </CardsWrapper>
    </RelatedProjectsWrapper>
  );
}
RelatedProjects.propTypes = {
  randomProjects: PropTypes.array.isRequired,
};
