const generateProjectsArray = (projects) => {
  const projectArray = projects.map((project) => ({
    photo: project.outer_image,
    mobile_photo: project.mobile_image,
    category: project.category_name || null,
    from: project.donations_count || 0,
    name: project.title || 'Projekt Name',
    description: project.subtitle || 'Projekt Beschreibung',
    target: project.goal || 100,
    fundings: project.donations_sum || 0,
    days: project.days_left || 0,
    url: project.url ? project.url : '#!',
    id: project.id,
    status: project.status,
    is_patreon: project.is_patreon,
    is_ticketable: project.is_ticketable,
    donations_count: project.donations_count
  }));

  return projectArray;
};

export default generateProjectsArray;
