import styled from '@emotion/styled';
import React, { Component } from 'react';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
} from 'react-share';
import { Colors, device } from 'utils';
import PropTypes from 'prop-types';

const ShareProjectWrapper = styled.div`
  @media ${device.tablet} {
    min-width: 500px;
  }
  .project {
    &__header {
      margin-top: -36px;
      position: relative;
      z-index: -1;
      border-radius: 12px 12px 0 0;
      img {
        border-radius: 12px 12px 0 0;
        width: 100%;
        height: 250px;
        object-fit: cover;
      }
      div {
        width: 50%;
        background: ${Colors.white};
        box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
          0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
        border-radius: 12px;
        margin-top: -50px;
        z-index: 2;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        padding: 15px;
        text-align: center;
        @media ${device.allMobile} {
          width: 75%;
        }
        p {
          margin: 0;
        }
        .project {
          &__name {
            color: ${Colors.primaryColor};
            font-size: 22px;
            font-weight: bold;
          }
          &__quote {
            color: ${Colors.black};
          }
        }
      }
    }
    &__content {
      padding: 20px;
      text-align: center;
      p {
        margin: 0;
        margin-bottom: 10px;
        font-size: 18px;
      }
      .copy {
        font-size: 16px;
        color: ${Colors.black};
        cursor: pointer;
        &.copied {
          color: ${Colors.secondaryColor};
        }
      }
    }
  }
`;
export default class ShareProject extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    banner: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  };
  state = {
    copied: false,
  };
  copyToClipboard = (str) => {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    this.setState({ copied: true });
  };
  render() {
    const { copied } = this.state;
    const { subtitle, title, banner, url } = this.props;
    return (
      <ShareProjectWrapper>
        <div className="project__header">
          <img src={banner} alt="project-banner-img" />
          <div>
            <p className="project__name">{title}</p>
            <p className="project__quote">{subtitle}</p>
          </div>
        </div>
        <div className="project__content">
          <p>
            Teile {title} , damit das Projekt realisiert werden kann. Mit dem
            Teilen des Projekts in deiner Community, hilfst du dem Projekt
            dabei, an Bekanntheit zu gewinnen.{' '}
          </p>
          <p> Teile jetzt den Link</p>
          <p> {title} und commonsplace danken dir!</p>
          <FacebookShareButton url={url} quote={title}>
            <FacebookIcon size={32} />
          </FacebookShareButton>
          <LinkedinShareButton url={url} quote={title}>
            <LinkedinIcon size={32} />
          </LinkedinShareButton>
          <TelegramShareButton url={url} quote={title}>
            <TelegramIcon size={32} />
          </TelegramShareButton>
          <TwitterShareButton url={url} quote={title}>
            <TwitterIcon size={32} />
          </TwitterShareButton>
          <p
            className={copied ? 'copied copy' : 'copy'}
            onClick={() => {
              this.copyToClipboard(url);
            }}
          >
            {copied ? 'Link kopiert' : 'Link kopieren'}
          </p>
        </div>
      </ShareProjectWrapper>
    );
  }
}
