import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SuccessProject } from 'images';
// import { PaymentActions } from 'redux/actions';
import {
  SvgWrapper,
  Container,
  H1,
  // IsLoggedIn,
  Colors,
  // PrimaryButton,
  isMobile,
} from 'utils';
import { Header, Footer } from 'components';
// import { Link } from 'react-router-dom';
import styled from '@emotion/styled';

const ThankYouWrapper = styled.div`
  text-align: center;
  margin-bottom: 100px;
  h1 {
    font-weight: bold;
    color: ${Colors.GreenColor};
    & + p {
      font-size: 22px;
      color: ${Colors.GreenColor};
    }
  }
`;

export class SuccessPage extends Component {
  componentDidMount() {
    // const { resetDonation } = this.props;
    // resetDonation();
  }
  static propTypes = {
    // resetDonation: PropTypes.func.isRequired,
  };

  render() {
    return (
      <div>
        <Header relativeHeader={true} />
        <Container>
          <ThankYouWrapper>
            <div>
              <SvgWrapper width={isMobile() ? '80%' : '500px'} height={'auto'}>
                <picture>
                  <img src={SuccessProject} alt="success-project" />
                </picture>
              </SvgWrapper>
            </div>

            <H1>Thank You For Submit</H1>
            <p>Submit for your Project</p>
          </ThankYouWrapper>
        </Container>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  // resetDonation: () => dispatch(PaymentActions.resetDonation()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SuccessPage);
