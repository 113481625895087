import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Colors } from 'utils';

const RewardWrapper = styled.div`
  background: ${Colors.grey};
  margin-top: 10px;
  cursor: pointer;
  border: 2px solid transparent;
  &:hover {
    border: 2px solid ${Colors.secondaryColor};
  }
`;
const RewardClosedWrapper = styled.div`
  display: flex;
  padding: 10px;
  .product-info {
    width: 65%;
    padding-right: 5px;
    p {
      margin: 0;
    }
    .price {
      font-weight: bold;
      margin-bottom: 5px;
    }
    .booked {
      font-size: 14px;
    }
    .name {
      color: ${Colors.steel};
      margin-bottom: 10px;
      max-height: 21px;
      overflow: hidden;
      white-space: nowrap;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
    }
  }
  .image-wrapper {
    width: 35%;
    padding-left: 5px;
    img {
      width: 100%;
    }
  }
`;
const RewardOpenedWrapper = styled.div`
  .image-wrapper {
    width: 100%;
  }
  .product-details {
    padding: 10px;
    .product-info {
      p {
        margin: 0;
      }
      .price {
        font-weight: bold;
        margin-bottom: 5px;
      }
      .booked {
        font-size: 14px;
      }
    }
  }
  button {
    margin-top: 15px;
    border-bottom: none;
    background: ${Colors.secondaryColor};
    border: 1px solid ${Colors.secondaryColor};
    color: ${Colors.white};
    text-align: center;
    border-radius: 12px;
    cursor: pointer;
    padding: 10px 30px;
    font-size: 18px;
    &:hover {
      box-shadow: 0 8px 12px 2px rgba(0, 0, 0, 0.14),
        0 3px 15px 5px rgba(0, 0, 0, 0.12), 0 4px 15px -3px rgba(0, 0, 0, 0.2);
    }
  }
`;
export default class Reward extends Component {
  state = {
    open: false,
  };
  static propTypes = {
    prop: PropTypes.string,
  };

  render() {
    const { open } = this.state;
    return (
      <RewardWrapper
        onClick={() => {
          this.setState({ open: !this.state.open });
        }}
      >
        {open ? (
          <RewardOpenedWrapper>
            <div className="image-wrapper">
              <img
                src="https://i.picsum.photos/id/939/400/400.jpg?hmac=ErfZ7UxHw5Ban57fZ0E6Z7tURjjMauJgsJGL2x1ORPk"
                alt="product-img-wrapper"
              />
            </div>
            <div className="product-details">
              <div className="product-info">
                <p className="price">5.00 €</p>
                <p className="name">Poster “17 Jahre Harry Klein, 2611 Acts”</p>
              </div>
              <div className="dis">
                <p>
                  Dein Erinnerungs-Poster mit ALLEN Künstler*innen, die je im
                  Harry Klein oder einer unserer Veranstaltungen zu Gast waren.{' '}
                </p>
                <p>
                  Größe A1, 140gr Papier, lesbare Schriftgröße der Namen: 16pt
                </p>
              </div>
              <div className="delivery">
                <p>
                  Estimated delivery: 3-4 Wochen nach Beendigung der Kampagne
                  zzgl. Versandkosten. Du kannst dein Dankeschön auch bei uns
                  abholen. So schonst du die Umwelt, sparst gleichzeitig
                  Versandkosten und wir können uns persönlich bei dir bedanken.
                </p>
              </div>
              <button>Select</button>
              <p className="booked">21 booked</p>
            </div>
          </RewardOpenedWrapper>
        ) : (
          <RewardClosedWrapper>
            <div className="product-info">
              <p className="price">5.00 €</p>
              <p className="name">Poster “17 Jahre Harry Klein, 2611 Acts”</p>
              <p className="booked">21 booked</p>
            </div>
            <div className="image-wrapper">
              <img
                src="https://i.picsum.photos/id/939/400/400.jpg?hmac=ErfZ7UxHw5Ban57fZ0E6Z7tURjjMauJgsJGL2x1ORPk"
                alt="product-img-wrapper-close"
              />
            </div>
          </RewardClosedWrapper>
        )}
      </RewardWrapper>
    );
  }
}
