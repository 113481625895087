/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Global, css } from '@emotion/react';
import { fontFace } from './FontGenerationHelper';
import { Colors } from './';
import device from './device';
import 'react-notifications-component/dist/theme.css';
import 'antd/lib/form/style/css';
import 'antd/lib/input/style/css';
import 'antd/lib/select/style/css';
import 'antd/lib/checkbox/style/css';
import 'antd/lib/upload/style/css';
import 'antd/lib/message/style/css';
import 'antd/lib/spin/style/css';
import 'antd/lib/date-picker/style/css';
import 'antd/lib/table/style/css';
import 'antd/lib/carousel/style/css';
import 'antd/lib/modal/style/css';

const GlobalStyles = () => {
  return (
    <Global
      styles={css`
        ${fontFace('Roboto', 'Roboto-Black', 800)};
        ${fontFace('Roboto', 'Roboto-BlackItalic', 800, 'italic')};
        ${fontFace('Roboto', 'Roboto-Bold', 700)};
        ${fontFace('Roboto', 'Roboto-BoldItalic', 700, 'italic')};
        ${fontFace('Roboto', 'Roboto-Italic', 400, 'italic')};
        ${fontFace('Roboto', 'Roboto-Light', 300)};
        ${fontFace('Roboto', 'Roboto-LightItalic', 300, 'italic')};
        ${fontFace('Roboto', 'Roboto-Medium', 500)};
        ${fontFace('Roboto', 'Roboto-MediumItalic', 500, 'italic')};
        ${fontFace('Roboto', 'Roboto-Regular', 400)};
        ${fontFace('Roboto', 'Roboto-Thin', 100)};
        ${fontFace('Roboto', 'Roboto-ThinItalic', 100, 'italic')};
        ${fontFace('Helvetica', 'Helvetica', 400)};
        ${fontFace('Helvetica', 'Helvetica-Bold', 700)};
        ${fontFace('Helvetica', 'Helvetica-Light', 300)};
        html {
          box-sizing: border-box;
          img {
            max-width: 100%;
          }
        }
        *,
        ::before,
        ::after {
          box-sizing: inherit;
        }
        html,
        body {
          scroll-behavior: smooth;
          padding: 0;
          margin: 0;
          .ant-layout-content {
            padding-left: 0 !important;
            padding-right: 0 !important;
            @media ${device.allMobile} {
              padding: 0 !important;
            }
          }
        }
        body {
          background: ${Colors.white};
          font-family: 'Helvetica', 'Roboto', Arial, sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 1.6;
          color: ${Colors.GreenColor};
          h1,
          h2,
          h3 {
            font-weight: bold;
          }
        }
        button,
        a {
          cursor: pointer;
          outline: none;
          text-decoration: none;
          color: inherit;
          &:focus {
            outline: none;
            color: inherit;
          }
          &:visited {
            color: inherit;
          }
        }
        .visually-hidden {
          border: 0;
          clip: rect(0 0 0 0);
          height: 1px;
          margin: -1px;
          overflow: hidden;
          padding: 0;
          position: absolute;
          width: 1px;
        }
        svg:not(:root) {
          overflow: initial;
        }
        input[type='number']::-webkit-inner-spin-button,
        input[type='number']::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        .ant-spin-dot-item {
          background: ${Colors.secondaryColor};
        }
        .errors {
          list-style: none;
          padding: 0;
          li {
            color: #c00;
            padding: 5px;
            margin-bottom: 10px;
          }
        }
        .notification__item--success {
          background-color: ${Colors.GreenColor};
        }
        .currency-input {
          position: relative;
          display: block;
          margin-bottom: 24px;
          input:focus {
            outline: none;
          }
          &::after {
            content: ' €';
            width: 80px;
            height: 100%;
            display: block;
            position: absolute;
            right: 0;
            top: 0;
            border-radius: 0 10px 10px 0;
            padding: 0 11px;
            color: rgba(0, 0, 0, 0.85);
            font-weight: bold;
            font-size: 18px;
            text-align: center;
            background-color: #fafafa;
            border: 1px solid #d9d9d9;
            line-height: 42px;
            vertical-align: middle;
            border-color: rgba(0, 0, 0, 0.85);
            border-left-color: #d9d9d9;
          }
        }
        .notification-container--top-right {
          @media ${device.allMobile} {
            min-width: 80%;
            max-width: 90%;
            width: 90%;
            .notification {
              width: 100% !important;
            }
          }
        }
        .ant-form-item .ant-input-textarea-show-count::after {
          font-size: 15px;
        }
      `}
    />
  );
};

export default GlobalStyles;
