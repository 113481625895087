const Colors = {
  primaryColor: '#0092ae',
  secondaryColor: '#95bd51',
  // GreenColor: '#22312B',
  GreenColor: '#1e322b',
  black: '#000',
  white: '#fff',
  grey: '#e5e5e5',
  steel: '#6d8083',
  red: '#ff0000',
  transparent: 'transparent',
};
export default Colors;
