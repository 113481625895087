import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'antd';
import { numberWithCommas, PrimaryButton, isMobile } from 'utils';
// import { Colors } from 'utils';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
// import moment from 'moment';

const SingleRewardWrapper = styled.div`
  text-align: center;
  max-width: 300px;
  margin: auto;
  .finished {
    opacity: 70%;
  }
  .most-wanted {
    border: 2px solid #95bd51;
    overflow: hidden;
  }
  .popular {
    bottom: 0;
    width: 100%;
    position: absolute;
    left: 50%;
    margin-left: -50%;
    background-color: #95bd51;
    color: #fff;
    font-size: 15px;
    font-weight: 100;
    text-align: center;
    padding 10px 0;
  }
  .title-open {
    font-size: 24px;
    font-weight: 700;
    word-break: break-word;
  }
  .title-closed {
    font-size: 24px;
    font-weight: 700;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .description-open {
    font-size: 16px;
    font-weight: 500;
    color: #999;
    word-break: break-word;

  }
  .description-closed {
    font-size: 16px;
    font-weight: 500;
    color: #999;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .seperate-container {
    display: flex;
    justify-content: space-between;
  }
  .price {
    text-align: right;
    font-size: 16px;
    font-weight: 750;
  }
  .numbers {
    text-align: left;
    font-size: 12px;
    font-weight: 750;
    margin-top: 5px;
    color: #444;
  }
`;

export default class RewardCard extends Component {
  static propTypes = {
    open: PropTypes.bool,
    reward: PropTypes.object,
    projectId: PropTypes.number,
    order: PropTypes.number,
    openCards: PropTypes.func,
    isTicketable: PropTypes.bool,
  };

  render() {
    const { reward, open, projectId, order, isTicketable } = this.props;
    let isFinished = reward?.ordered_reward_count >= reward?.max_order_count;
    if (reward?.max_order_count === null || reward?.max_order_count === 0) {
      isFinished = false;
    }
    let img = '';
    if (isMobile()) {
      img = reward?.mobile_image ? reward?.mobile_image : reward.image;
    } else {
      img = reward.image ? reward.image : reward?.mobile_image;
    }
    return (
      <SingleRewardWrapper>
        <Card
          onClick={() => this.props.openCards(this.props.order - 1)}
          className={
            (isFinished ? 'finished ' : '') +
            (order === 1 && reward.order_count > 0 ? 'most-wanted' : '')
          }
          hoverable={this.props.projectStatus !== 'successful' ? true : false}
          style={{
            borderRadius: '40px',
            overflow: 'hidden',
            textAlign: 'start',
          }}
          cover={
            <img
              style={{
                borderRadius: '40px',
                border: '1px solid #f0f0f0',
              }}
              // height="250px"
              alt="reward-card"
              src={img}
            />
          }
        >
          <div className={open ? 'title-open' : 'title-closed'}>
            {' '}
            {reward.title}
          </div>
          <div className={open ? 'description-open' : 'description-closed'}>
            {' '}
            {reward.description}
          </div>
          {this.props.projectStatus !== 'successful' && (
            <>
              {open && (
                <Link
                  to={`/project/${projectId}/donation?donation=${
                    isTicketable ? 0 : '20'
                  }&rewardId=${reward.id}`}
                  disabled={isFinished}
                >
                  <PrimaryButton
                    disabled={isFinished}
                    style={{ margin: '20px auto 0px' }}
                  >
                    Buchen
                  </PrimaryButton>
                </Link>
              )}
            </>
          )}
          <div style={{ marginTop: '30px' }} className="seperate-container">
            <span className="numbers">
              {reward.ordered_reward_count ? reward.ordered_reward_count : '0'}{' '}
              {reward.max_order_count !== null && (
                <span>
                  von {reward.max_order_count ? reward.max_order_count : '0'}
                </span>
              )}{' '}
              gebucht
            </span>
            <span className="price">
              {numberWithCommas(Number(reward.price).toFixed(2))} €
            </span>
          </div>
          <div
            style={{
              marginTop: '10px',
              textAlign: 'center',
              fontWeight: '700',
            }}
          >
            {!isTicketable
              ? reward.shipping_date
                ? 'Sofort lieferbar'
                : 'Nach erfolgreicher Finanzierungsphase lieferbar'
              : null}
          </div>
          {order === 1 && reward.order_count > 0 && (
            <div className="popular" style={{ padding: '10px 0' }}>
              Beliebt
            </div>
          )}
        </Card>
      </SingleRewardWrapper>
    );
  }
}
