import React, { useEffect, useState } from 'react';
import {
  PaymentElement,
  useStripe,
  useElements,
  PaymentRequestButtonElement,
} from '@stripe/react-stripe-js';
import PayPalButton from '../components/PayPalButton';
import Cards from '../../../images/Cards.svg';
import paypal from '../../../images/paypal.svg';
import { useHistory } from 'react-router';
import '../../../utils/Payment.css';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Input, Checkbox, Spin } from 'antd';
import { CountriesSelect } from 'components';
import {
  PrimaryButton,
  IsLoggedIn,
  Modal,
  device,
  formValidateMessages,
  H1,
} from 'utils';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { store } from 'react-notifications-component';
import IdealPayIcon from 'images/svgs/IdealIcon';
import KlarnaPayIcon from 'images/svgs/KlarnaPay';
import GiroPayIcon from 'images/svgs/GiroPay';
import SofortPay from 'images/svgs/SofortPay';
import SeptaDebitIcon from 'images/svgs/SeptaDebit';

const InfoFormWrapper = styled.div`
  @media ${device.allMobile} {
    margin-top: 0;
  }
  .ant-select-selector {
    border-radius: 10px !important;
    padding: 6.5px 11px !important;
    height: auto !important;
  }
  button {
    margin-top: 40px;
    @media ${device.allMobile} {
      margin-top: 20px;
    }
  }
  .info-input {
    label {
      display: none;
    }
  }
  .payment-options {
    display: flex;
    span {
      cursor: pointer;
      & + span {
        margin-left: 25px;
      }
    }
  }
  .remove-header {
    @media ${device.allMobile} {
      margin: 0px;
      font-size: 4px;
      padding: 0px;
    }
  }
  .remove-label {
    .ant-form-item-label {
      display: none;
    }
  }
`;
const notification = {
  insert: 'top',
  container: 'top-right',
  animationIn: ['animate__animated animate__fadeIn'], // `animate.css v4` classes
  animationOut: ['animate__animated animate__fadeOut'], // `animate.css v4` classes
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
};
const PaymentMethod = ({
  project_id,
  amount,
  tips,
  selectedRewards,
  project,
}) => {
  const [paymentType, setPaymentType] = useState('paypal');
  const [message, setMessage] = useState(null);
  const [response, setResponse] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  console.log('project in payment method--->', project);
  const FormItem = Form.Item;
  // Get user if login
  const user = JSON.parse(localStorage.getItem('user'));
  const rewards = selectedRewards?.map((reward) => {
    return reward?.id;
  });

  const history = useHistory();

  //PayPal Handler
  const paypalCreateOrder = () => {};
  const paypalPaymentHandler = () => {};

  // set Payment Method
  const handlePaymentType = (e) => {
    setPaymentType(e.target.value);
  };
  const stripe = useStripe();
  const elements = useElements();

  const getUrl = window.location.href;
  const searchParams = new URLSearchParams(document.location.search);
  const account_id = searchParams.get('PayerID');
  const payment_id = searchParams.get('paymentId');
  const data = JSON.parse(localStorage.getItem('after_redirect'));

  const onFinish = async (values) => {
    localStorage.setItem('after_redirect', JSON.stringify(values));
    if (paymentType == 'card') {
      // Strip APi Call for Payment Deduction
      if (!stripe || !elements) {
        return;
      }
      setIsProcessing(true);
      const result = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/completion`,
        },
        redirect: 'if_required',
      });
      const { error, paymentIntent } = result;
      if (error) {
        setMessage(error.message);
        setIsProcessing(false);
      } else if (paymentIntent && paymentIntent.status === 'succeeded') {
        fetch('payments/direct_payment', {
          method: 'POST',
          body: JSON.stringify({
            project_id: project_id,
            amount: amount,
            tips: tips,
            reward_ids: rewards,
            payment_intent: paymentIntent?.id,
            anonymous:
              values?.anonymous == undefined ? false : values?.anonymous,
            message: values?.message == undefined ? '' : values?.message,
            ...(user !== null && { user_id: user?.id }),
            ...(user === null && {
              first_name: values.first_name,
              email: values?.email,
              last_name: values.last_name,
            }),
            ...(selectedRewards.length != 0 && {
              street: values?.street,
              building_number: values.building_number,
              zip_code: values.zip_code,
              city: values?.city,
            }),
          }),
        })
          .then((result) => {
            setIsProcessing(false);
            if (result?.success === true) {
              if(project?.is_ticketable){
                history.push('/events-success');
              }else{
              history.push('/thank-you-page');}
            } else {
              setMessage('Something wrong,please try again');
            }
          })
          .catch((err) => setIsProcessing(false));
        setResponse(paymentIntent);
        // setMessage('Payment Succeeded SuccessFully');
      } else {
        setMessage('Unexpected Error');
      }
    } else {
      setIsProcessing(true);
      fetch('create_payment', {
        method: 'POST',
        body: JSON.stringify({
          email: values?.email,
          project_id: project_id,
          amount: amount,
          tips: tips,
          reward_ids: rewards,
          return_url: getUrl,
          anonymous: values?.anonymous == undefined ? false : values?.anonymous,
          message: values?.message == undefined ? '' : values?.message,
          ...(user !== null && { user_id: user?.id }),
          ...(user === null && {
            first_name: values.first_name,
            last_name: values.last_name,
          }),
          ...(selectedRewards.length != 0 && {
            street: values?.street,
            building_number: values.building_number,
            zip_code: values.zip_code,
            city: values?.city,
          }),
        }),
      })
        .then((result) => {
          console.log('result---->', result?.reward_ids);
          localStorage.setItem('rewards', JSON.stringify(result?.reward_ids));
          const geturl = result?.response?.links?.find((item) => {
            return item?.rel == 'approval_url';
          });
          if (geturl) {
            window.open(`${geturl?.href}`, '_self');
            setIsProcessing(false);
          } else {
            setMessage('SomeThing Wrong');
            setIsProcessing(false);
          }
        })
        .catch((err) => setIsProcessing(false));
    }
  };

  // const getRewards = localStorage.getItem("rewards") != undefined && JSON.parse(localStorage.getItem("rewards"))
  // console.log("getRewards------->",getRewards)

  // Transfer Amount Api
  useEffect(() => {
    if (payment_id != undefined) {
      setIsProcessing(true);
      fetch('transfer_amount', {
        method: 'POST',
        body: JSON.stringify({
          account_id: account_id,
          payment_id: payment_id,
          email: data?.email,
          project_id: project_id,
          amount: amount,
          tips: tips,
          reward_ids:
            localStorage.getItem('rewards') != undefined &&
            JSON.parse(localStorage.getItem('rewards')),
          anonymous: data?.anonymous == undefined ? false : data?.anonymous,
          message: data?.message == undefined ? '' : data?.message,
          ...(user !== null && { user_id: user?.id }),
          ...(user === null && {
            first_name: data?.first_name,
            last_name: data?.last_name,
          }),
          ...(selectedRewards.length != 0 && {
            street: data?.street,
            building_number: data.building_number,
            zip_code: data?.zip_code,
            city: data?.city,
          }),
        }),
      })
        .then((result) => {
          setIsProcessing(false);
          if (result?.payment_response.state == 'approved') {
            if(project?.is_ticketable){
              history.push('/events-success');
            }else{
            history.push('/thank-you-page');}
          } else {
            store.addNotification({
              ...notification,
              title: 'Fehler!',
              type: 'danger',
              message: 'Something Wrong,Please try again later',
            });
            setMessage('Something Wrong,Please try again later');
          }
        })
        .catch((err) => {
          setIsProcessing(false);
          setMessage('Something Wrong,Please try again later');
          store.addNotification({
            ...notification,
            title: 'Fehler!',
            type: 'danger',
            message: 'Something Wrong,Please try again later',
          });
        });
    }
  }, []);

  const product = {
    description: 'Testing Product',
    price: 30,
  };
  return (
    <>
      <InfoFormWrapper>
        <Form
          name="donation_info"
          onFinish={onFinish}
          // ref={(b) => (this.form = b)}
          validateMessages={formValidateMessages}
          scrollToFirstError
          style={{ marginTop: '1rem' }}
        >
          <div className="payment_container">
            <h2 className="payment_label_heading">Payment Details</h2>
            <div className="secure_message">
              This is a secure and SSL encrypted payment. Your credit card
              details are safe.
            </div>
            {message && <div className="response_message">{message}</div>}
            <div>
              <div className="paypal_container">
                <div
                  className="paypal_main_div"
                  style={{ marginBottom: '1rem' }}
                >
                  <input
                    type="radio"
                    id="paypal"
                    name="paymentType"
                    defaultChecked={paymentType == 'paypal' ? true : false}
                    value="paypal"
                    onChange={handlePaymentType}
                    style={{ cursor: 'pointer' }}
                  />
                  <label htmlFor="paypal">
                    <img className="paypal_img" src={paypal} alt="paypal-card-icon" />
                  </label>
                </div>
                {paymentType == 'paypal' && (
                  <>
                    <div className="info-input" style={{ marginTop: '1rem' }}>
                      <FormItem
                        name="email"
                        label="Email"
                        rules={[
                          {
                            required: true,
                            type: 'email',
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          style={{ borderRadius: '10px' }}
                          type="email"
                          placeholder="Email"
                        />
                      </FormItem>
                    </div>

                    {user == undefined && (
                      <>
                        <div className="info-input">
                          <FormItem
                            name="first_name"
                            label="Vorname"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <Input
                              size="large"
                              placeholder="Vorname"
                              style={{ borderRadius: '10px' }}
                            />
                          </FormItem>
                          <FormItem
                            name="last_name"
                            label="Nachname"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <Input
                              size="large"
                              placeholder="Nachname"
                              style={{ borderRadius: '10px' }}
                            />
                          </FormItem>

                          {selectedRewards.length != 0 && (
                            <>
                              <FormItem
                                name="city"
                                label="Stadt"
                                rules={[
                                  {
                                    required: true,
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="Stadt"
                                  style={{ borderRadius: '10px' }}
                                />
                              </FormItem>

                              <FormItem
                                name="street"
                                label="Straße"
                                rules={[
                                  {
                                    required: true,
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="Straße"
                                  style={{ borderRadius: '10px' }}
                                />
                              </FormItem>

                              <FormItem
                                name="zip_code"
                                label="Postleitzahl"
                                rules={[
                                  {
                                    required: true,
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  type="number"
                                  style={{ borderRadius: '10px' }}
                                  placeholder="Postleitzahl"
                                />
                              </FormItem>

                              <FormItem
                                name="building_number"
                                label="Hausnummer"
                                rules={[
                                  {
                                    required: true,
                                  },
                                ]}
                              >
                                <Input
                                  type="number"
                                  size="large"
                                  style={{ borderRadius: '10px' }}
                                  placeholder="Hausnummer"
                                />
                              </FormItem>
                              {/* <CountriesSelect
                        handleCountryChange={this.handleCountryChange}
                        countryValue={countryValue}
                        required={true}
                        className="test"
                      /> */}
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>

              {/* Visa Card Section */}
              <div className="payment_bulk_wrap">
                <div
                  className="card_main_div"
                  style={{
                    marginBottom: paymentType == 'card' ? '1rem' : '0rem',
                  }}
                >
                  <input
                    id="card"
                    type="radio"
                    name="paymentType"
                    value="card"
                    defaultChecked={paymentType == 'card' ? true : false}
                    onChange={handlePaymentType}
                    style={{ cursor: 'pointer' }}
                  />
                  <label
                    htmlFor="card"
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <span className="visa-image">
                      <img
                        src={Cards}
                        alt="visa-card-icon"
                        style={{ cursor: 'pointer' }}
                      />
                    </span>
                    <span className="Klarna_span">
                      <KlarnaPayIcon height="100%" width={'1.5rem'} />
                    </span>
                    <span className="ideal_pay_span">
                      <IdealPayIcon height="100%" width={'1.5rem'} />
                    </span>
                    <span className="septa_btn_span">
                      <SeptaDebitIcon height="100%" width={'1.5rem'} />
                    </span>
                    <span className="giro_pay_span">
                      <GiroPayIcon height="100%" width={'1.5rem'} />
                    </span>
                    <span
                      className="sofort_span"
                      style={{
                        cursor: 'pointer',
                        marginLeft: '7px',
                        height: '24px',
                        marginTop: '5px',
                      }}
                    >
                      <SofortPay height="100%" width={'1.5rem'} />
                    </span>
                  </label>
                </div>
                {paymentType == 'card' && (
                  <>
                    <div className="payment_blk_in">
                      <PaymentElement id="payment-element" />
                    </div>
                    <div>
                      {user == undefined && (
                        <>
                          <div
                            className="info-input"
                            style={{ marginTop: '1rem' }}
                          >
                            <FormItem
                              name="email"
                              label="Email"
                              rules={[
                                {
                                  required: true,
                                  type: 'email',
                                },
                              ]}
                            >
                              <Input
                                size="large"
                                style={{ borderRadius: '10px' }}
                                type="email"
                                placeholder="Email"
                              />
                            </FormItem>
                            <FormItem
                              name="first_name"
                              label="Vorname"
                              rules={[
                                {
                                  required: true,
                                },
                              ]}
                            >
                              <Input
                                size="large"
                                placeholder="Vorname"
                                style={{ borderRadius: '10px' }}
                              />
                            </FormItem>
                            <FormItem
                              name="last_name"
                              label="Nachname"
                              rules={[
                                {
                                  required: true,
                                },
                              ]}
                            >
                              <Input
                                size="large"
                                placeholder="Nachname"
                                style={{ borderRadius: '10px' }}
                              />
                            </FormItem>

                            {selectedRewards.length != 0 && (
                              <>
                                <FormItem
                                  name="city"
                                  label="Stadt"
                                  rules={[
                                    {
                                      required: true,
                                    },
                                  ]}
                                >
                                  <Input
                                    size="large"
                                    placeholder="Stadt"
                                    style={{ borderRadius: '10px' }}
                                  />
                                </FormItem>

                                <FormItem
                                  name="street"
                                  label="Straße"
                                  rules={[
                                    {
                                      required: true,
                                    },
                                  ]}
                                >
                                  <Input
                                    size="large"
                                    placeholder="Straße"
                                    style={{ borderRadius: '10px' }}
                                  />
                                </FormItem>

                                <FormItem
                                  name="zip_code"
                                  label="Postleitzahl"
                                  rules={[
                                    {
                                      required: true,
                                    },
                                  ]}
                                >
                                  <Input
                                    size="large"
                                    type="number"
                                    style={{ borderRadius: '10px' }}
                                    placeholder="Postleitzahl"
                                  />
                                </FormItem>

                                <FormItem
                                  name="building_number"
                                  label="Hausnummer"
                                  rules={[
                                    {
                                      required: true,
                                    },
                                  ]}
                                >
                                  <Input
                                    type="number"
                                    size="large"
                                    style={{ borderRadius: '10px' }}
                                    placeholder="Hausnummer"
                                  />
                                </FormItem>
                                {/* <CountriesSelect
                        handleCountryChange={this.handleCountryChange}
                        countryValue={countryValue}
                        required={true}
                        className="test"
                      /> */}
                              </>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          {/* Concent forms */}
          <div style={{ marginTop: '1.5rem' }}>
            <FormItem
              name="terms"
              className="remove-label"
              valuePropName="checked"
              label="Zustimmung"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(new Error('Zustimmung erforderlich')),
                },
              ]}
            >
              <Checkbox>
                Ich akzeptiere
                <Link to="/anb" target="_blank">
                  die Nutzungsbedingungen
                </Link>{' '}
                für commonsplace und{' '}
                <Link to="/datenschutz" target="_blank">
                  Datenschutzbestimmungen
                </Link>{' '}
                und bestätige, dass ich mindestens 16 Jahre alt bin.
              </Checkbox>
            </FormItem>
            <FormItem
              name={'anonymous'}
              valuePropName="checked"
              className="check-box"
            >
              <Checkbox>Ich möchte für die Community anonym bleiben.</Checkbox>
            </FormItem>
            <div className="zahlungsmethode">
              <h2>Hinterlasse eine Botschaft</h2>
              <FormItem name={'message'} className="deine-label">
                <Input.TextArea
                  placeholder="Deine Nachricht (optional)"
                  maxLength={150}
                  showCount
                />
              </FormItem>
            </div>
          </div>

          {/* Submit Buttons */}
          {paymentType == 'card' ? (
            <FormItem>
              <PrimaryButton
                type="submit"
                minWidth={'100%'}
                className="payPalBtn"
                disabled={isProcessing}
              >
                {isProcessing
                  ? 'wird bearbeitet...'
                  : 'Jetzt zahlungspflichtig unterstützen'}
              </PrimaryButton>
            </FormItem>
          ) : (
            <PrimaryButton
              type="submit"
              minWidth={'100%'}
              className="payPalBtn"
              disabled={isProcessing}
            >
              {isProcessing ? 'wird bearbeitet...' : 'Continue with PayPal'}
            </PrimaryButton>
            // <div className="paypal_main_btn">
            //   <PayPalButton
            //     // paypalCreateOrder={paypalCreateOrder}
            //     // paypalPaymentHandler={paypalPaymentHandler}
            //     product={product}
            //   />

            // </div>
          )}
        </Form>
      </InfoFormWrapper>
    </>
  );
};

export default PaymentMethod;
