import alertConstants from './AlertsTypes';
import { store } from 'react-notifications-component';

const notification = {
  insert: 'top',
  container: 'top-right',
  animationIn: ['animate__animated animate__fadeIn'], // `animate.css v4` classes
  animationOut: ['animate__animated animate__fadeOut'], // `animate.css v4` classes
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
};

const success = (message, title = null) => {
  store.addNotification({
    ...notification,
    title: title ? title : 'Danke!',
    type: 'success',
    message: message,
  });
 return { type: alertConstants.SUCCESS, message };
};


const info = (message, title = null) => {
  store.addNotification({
    ...notification,
    title: title ? title : 'Info',
    type: 'info',
    message: message,
  });
  return { type: alertConstants.INFO, message };
};

const warning = (message, title = null) => {
  store.addNotification({
    ...notification,
    title: title ? title : 'Warning',
    type: 'warning',
    message: message,
  });

  return { type: alertConstants.WARNING, message };
};

const error = (message, title = null) => {
  store.addNotification({
    ...notification,
    title: title ? title : 'Fehler!',
    type: 'danger',
    message: message,
  });
  return { type: alertConstants.ERROR, message };
};

const clear = () => {
  return { type: alertConstants.CLEAR };
};

const alertActions = {
  success,
  info,
  warning,
  error,
  clear,
};

export default alertActions;
