import React, { Component } from 'react';
import { Form, Input, List, Spin } from 'antd';
import { Header } from 'components';
import { Container, Colors, FormWrapper, PrimaryButton, Section } from 'utils';
import { Table } from 'antd';
import styled from '@emotion/styled';
import { adminServices } from 'services';
import { connect } from 'react-redux';
import { AdminActions } from 'redux/actions';
import PropTypes from 'prop-types';
import Papa from 'papaparse';
import { CitiesSelect, CategoriesSelect } from 'components';
// import { BookedGoodiesPage } from 'scenes';

const TableWrapper = styled.div`
  padding: 50px 0;
`;
// const FormItem = Form.Item;

const Wrapper = styled.div`
  .container {
    max-width: 100%;
    padding: 0 90px;
    color: ${Colors.GreenColor};
    h1 {
      color: ${Colors.GreenColor};
      display: inline-block;
      padding: 10px;
      border-radius: 10px;
      font-size: 35px;
      margin-top: 30px;
      margin-bottom: 0px;
      font-weight: bold;
      width: 100%;
      text-align: center;
    }
  }
  label {
    font-weight: bold;
    font-size: 22px;
    display: block;
  }

  .submit-button {
    text-align: center;
    margin-top: 50px;
  }
  .ant-form-item {
    display: block;
    .ant-form-item-label {
      color: ${Colors.GreenColor};
      margin-bottom: 10px;
    }
    .ant-picker,
    .ant-input-affix-wrapper {
      width: 100%;
      padding: 10px;
      border: 1px solid ${Colors.GreenColor};
      border-radius: 10px;
    }
  }
  .ant-select-selector,
  input,
  textarea {
    border-radius: 15px !important;
    width: 100%;
    border-radius: 10px;
    line-height: 22px;
    vertical-align: middle;
  }
  .subtitle {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 30px;
  }
  .intro-text {
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 30px;
  }
  .ant-table-thead {
    th {
      font-size: 22px;
      font-weight: bold;
      text-align: center;
    }
  }
  .project-name {
    font-size: 20px;
    font-weight: bold;
  }
  .ant-table-content {
    .ant-table-thead,
    .ant-table-cell,
    .ant-table-tbody .ant-table-row {
      border: 1px solid ${Colors.GreenColor};
    }
  }
  .ant-table-cell {
    text-align: center;
    .delete {
      text-align: center;
      button {
        border: none;
        background: none;
      }
    }
  }
  .green-text {
    color: #95bd51;
  }
  .btn {
    display: flex;
    justify-content: end;
  }
`;

class BookedGoodiesPage extends Component {
  static propTypes = {
    goodies: PropTypes.any,
    getAllBookGoodies: PropTypes.func.isRequired
  };

  state = {
    dataArray: []
  };
  componentDidMount() {
    const { getAllBookGoodies } = this.props;
    getAllBookGoodies();
  }

  handleDownloadCSV = () => {
    const { goodies } = this.props;

    // Convert goodies data to CSV format
    const csvData = Papa.unparse(goodies.goodies);

    // Create a blob containing the CSV data
    const blob = new Blob([csvData], { type: 'text/csv' });

    // Create a temporary anchor element to trigger the download
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'booked_goodies.csv';
    link.click();
  };

  render() {
    const { goodies } = this.props;
    const columns = [{
      title: "project",
      dataIndex: "project_name",
      key: "project"
    },{
      title: "goodies",
      dataIndex: "reward",
      key: "reward"
    },
    {
      title: 'sub category',
      dataIndex: 'answers',
      key: 'answers',
      render: (_, { answers }) => (
        <>
        {}
          {answers?.map((answer,i,arr) => {
            if(arr.length - 1 !== i){
              return (
                <span key={answer}>
                   {answer}{" , "}
                 </span>
               );
            }else{
              return (
                <span key={answer}>
                   {answer}
                 </span>
               );
            }
            
          })} 
        </>
      ),
    },{
      title: 'price',
      dataIndex: 'price',
      key: 'price',
    }, {
      title: 'donate by',
      dataIndex: 'donate_by',
      key: 'donate_by',
    }, {
      title: 'street',
      dataIndex: 'street',
      key: 'street',
    },{
      title: 'building number',
      dataIndex: 'building_number',
      key: 'building_number',
    },{
      title: 'zip code',
      dataIndex: 'zip_code',
      key: 'zip_code',
    },{
      title: 'city',
      dataIndex: 'city',
      key: 'city',
    }, {
      title: 'country',
      dataIndex: 'country',
      key: 'country',
    }];

    return (
      <Wrapper>
        <Header relativeHeader={true} />
        <Container className="container">
          <Section>
            <h1>gebuchte Süßigkeiten</h1>
            <div className='btn'>
              <PrimaryButton onClick={() => this.handleDownloadCSV()}>
                Download CSV
              </PrimaryButton>
            </div>
            <TableWrapper>
                    <Table
                      columns={columns}
                      dataSource={goodies?.goodies}
                      // loading={loading}
                      // pagination={{
                      //   total: projects.count,
                      //   pageSize: perPage,
                      //   hideOnSinglePage: true,
                      //   current: this.state.page,
                        // onChange: (page) => {
                        //   this.setState({ page });
                        //   getAllProjects(perPage, page, this.state.status);
                        // },
                      // }}
                    />
                  </TableWrapper>
              
                <>
                  
                  {/* <tr>
                      <td>{item?.id}</td>
                      <td>{item?.reward}</td>
                      <td>{item?.donate_by}</td>

                      <td>{item?.street}</td>

                      <td>{item?.building_number}</td>
                      <td>{item?.zip_code}</td>
                      <td>{item?.city}</td>
                      <td>{item?.country}</td>

                    </tr> */}
                </>
              

          </Section>
        </Container>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => {
  const { goodies, loading } = state.admin;
  return {
    goodies,
    loading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllBookGoodies: () =>
    dispatch(AdminActions.getAllBookGoodies()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BookedGoodiesPage);

