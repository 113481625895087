import React from 'react';
import { AcceptIcon } from 'images';
import { Colors, SvgWrapper } from 'utils';
import styled from '@emotion/styled';

const SuccessfulPopWrapper = styled.div`
  background: ${Colors.secondaryColor};
  padding: 20px 30px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  border-radius: 15px;
  position: relative;
  p {
    font-weight: bold;
    font-size: 22px;
    color: ${Colors.white};
    margin: 0;
    margin-left: 15px;
  }
`;

export default function SuccessfulPop() {
  return (
    <SuccessfulPopWrapper>
      <SvgWrapper width="50px" height="50px">
        <AcceptIcon />
      </SvgWrapper>
      <p>Dein Projekt war ein Erfolg. Du hast dein Fundingziel erreicht. 🎉 </p>
    </SuccessfulPopWrapper>
  );
}
