import { alertConstants, authConstants } from 'redux/actions/types';

function alert(state = { type: null, message: null }, action) {
  switch (action.type) {
    case alertConstants.SUCCESS:
      return {
        type: 'success',
        message: action.message,
      };
    case alertConstants.INFO:
      return {
        type: 'info',
        message: action.message,
      };
    case alertConstants.WARNING:
      return {
        type: 'warning',
        message: action.message,
      };
    case alertConstants.ERROR:
      return {
        type: 'error',
        message: action.message,
      };
    case alertConstants.CLEAR:
    case authConstants.LOGOUT:
      return { type: 'clear', message: null };
    default:
      return state;
  }
}

export default alert;
