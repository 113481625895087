import { adminConstants } from 'redux/actions/types';

function admin(
  state = {
    project: {},
    projects: [],
    homeProject: [],
    user: {},
    loading: false,
    invoices: {},
    recurringDonations: {},
  },
  action
) {
  switch (action.type) {
    case adminConstants.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case adminConstants.LOGIN_SUCCESS:
      return {
        ...state,
        errors: [],
        loading: false,
        user: action.user,
      };
    case adminConstants.LOGIN_FAILURE:
      return {
        loading: false,
        errors: action.error,
      };
    case adminConstants.SET_PROJECTS_HOME_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case adminConstants.SET_PROJECTS_HOME_SUCCESS:
      return {
        ...state,
        loading: false,
        homeProject: action.projects,
      };
    case adminConstants.SET_PROJECTS_HOME_FAILURE:
      return {
        loading: false,
        errors: action.error,
      };
    case adminConstants.GET_ALL_PROJECTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case adminConstants.GET_ALL_PROJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        projects: action.projects.projects,
      };
    case adminConstants.GET_ALL_PROJECTS_FAILURE:
      return {
        loading: false,
        errors: action.error,
      };
    case adminConstants.GET_ALL_INVOICES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case adminConstants.GET_ALL_INVOICES_SUCCESS:
      return {
        ...state,
        loading: false,
        invoices: action.invoices.projects,
      };
    case adminConstants.GET_ALL_INVOICES_FAILURE:
      return {
        loading: false,
        errors: action.error,
      };
    case adminConstants.SHOW_PROJECT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case adminConstants.SHOW_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        project: action.project,
      };
    case adminConstants.SHOW_PROJECT_FAILURE:
      return {
        loading: false,
        errors: action.error,
      };
    case adminConstants.ACCEPT_PROJECT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case adminConstants.ACCEPT_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        project: action.project,
      };
    case adminConstants.ACCEPT_PROJECT_FAILURE:
      return {
        loading: false,
        errors: action.error,
      };
    case adminConstants.DECLINE_PROJECT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case adminConstants.DECLINE_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        project: action.project,
      };
    case adminConstants.DECLINE_PROJECT_FAILURE:
      return {
        loading: false,
        errors: action.error,
      };

    case adminConstants.GET_ALL_BOOK_GOODIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case adminConstants.GET_ALL_BOOK_GOODIES_SUCCESS:
      return {
        ...state,
        loading: false,
        goodies: action.goodies.goodies,
      };
    case adminConstants.GET_ALL_INVOICES_FAILURE:
      return {
        loading: false,
        errors: action.error,
      };

    case adminConstants.LOGOUT:
      return {
        ...state,
        loading: false,
        user: null,
      };

    ///// RECURRING DONATIONS
    case adminConstants.GET_ADMIN_RECURRING_DONATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case adminConstants.ADMIN_RECURRING_DONATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        recurringDonations: action.donations,
      };
    case adminConstants.ADMIN_RECURRING_DONATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        errors: { errors: action.error },
      };

    ///// RECURRING DONATIONS CANCEL
    case adminConstants.ADMIN_RECURRING_DONATIONS_CANCEL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case adminConstants.ADMIN_RECURRING_DONATIONS_CANCEL_SUCCESS:
      return {
        ...state,
        loading: false,
        recurringDonations: action.donations,
      };
    case adminConstants.ADMIN_RECURRING_DONATIONS_CANCEL_FAILURE:
      return {
        ...state,
        loading: false,
        errors: { errors: action.error },
      };

    default:
      return state;
  }
}

export default admin;
