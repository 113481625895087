/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import PropTypes from 'prop-types';

const RejectIcon = ({ red, width, height, color }) => {
  return (
    <svg
      version="1.1"
      x="0"
      y="0"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      height={`${height || '100%'}`}
      width={`${width || '100%'}`}
      css={css`
        max-width: ${width || '100%'};
        max-height: ${height || '100%'};
        height: ${height || '100%'};
        width: ${width || '100%'};
        display: inline-block;
        enable-background: new 0 0 512 512;
      `}
    >
      <path
        d="M256,0C115.3,0,0,115.3,0,256s115.3,256,256,256s256-115.3,256-256S396.7,0,256,0z"
        fill={red ? '#ff0000' : '#ffffff'}
      />
      <path
        d="M512,256c0,140.7-115.3,256-256,256V0C396.7,0,512,115.3,512,256z"
        fill={red ? '#ff0000' : '#ffffff'}
      />
      <polygon
        points="298.299,256 383.2,340.901 340.901,383.2 256,298.299 171.099,383.2 128.8,340.901   213.701,256 128.8,171.099 171.099,128.8 256,213.701 340.901,128.8 383.2,171.099 "
        fill={color ? color : red ? '#ffffff' : '#ff0000'}
      />
      <polygon
        points="298.299,256 383.2,340.901 340.901,383.2 256,298.299 256,213.701 340.901,128.8   383.2,171.099 "
        fill={color ? color : red ? '#ffffff' : '#ff0000'}
      />
    </svg>
  );
};

export default RejectIcon;

RejectIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  color: PropTypes.string,
  red: PropTypes.bool,
};
