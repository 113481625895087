/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { Colors } from 'utils';

const ArrowDown = (props) => {
  const { color, width, height } = props;
  return (
    <svg
      {...props}
      enableBackground="new 0 0 256 256"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 256 256"
      height={`${height || '100%'}`}
      width={`${width || '100%'}`}
      css={css`
        fill: ${color || Colors.secondaryColor};
        max-width: ${width || '100%'};
        max-height: ${height || '100%'};
        height: ${height || '100%'};
        width: ${width || '100%'};
        display: inline-block;
      `}
    >
      <g>
        <g>
          <polygon points="225.813,48.907 128,146.72 30.187,48.907 0,79.093 128,207.093 256,79.093 		" />
        </g>
      </g>
    </svg>
  );
};

export default ArrowDown;

ArrowDown.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};
