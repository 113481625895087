const paymentConstants = {
  GET_MY_CARDS_REQUEST: 'GET_MY_CARDS_REQUEST',
  GET_MY_CARDS_SUCCESS: 'GET_MY_CARDS_SUCCESS',
  GET_MY_CARDS_FAILURE: 'GET_MY_CARDS_FAILURE',
  PREPARE_CARD_REQUEST: 'PREPARE_CARD_REQUEST',
  PREPARE_CARD_SUCCESS: 'PREPARE_CARD_SUCCESS',
  PREPARE_CARD_FAILURE: 'PREPARE_CARD_FAILURE',
  ADD_CARD_REQUEST: 'ADD_CARD_REQUEST',
  ADD_CARD_SUCCESS: 'ADD_CARD_SUCCESS',
  ADD_CARD_FAILURE: 'ADD_CARD_FAILURE',
  DELETE_CARD_REQUEST: 'DELETE_CARD_REQUEST',
  DELETE_CARD_SUCCESS: 'DELETE_CARD_SUCCESS',
  DELETE_CARD_FAILURE: 'DELETE_CARD_FAILURE',
  MAKE_DONATION_REQUEST: 'MAKE_DONATION_REQUEST',
  MAKE_DONATION_SUCCESS: 'MAKE_DONATION_SUCCESS',
  MAKE_DONATION_FAILURE: 'MAKE_DONATION_FAILURE',
  GET_STRIPE_URL_REQUEST: 'GET_STRIPE_URL_REQUEST',
  GET_STRIPE_URL_SUCCESS: 'GET_STRIPE_URL_SUCCESS',
  GET_STRIPE_URL_FAILURE: 'GET_STRIPE_URL_FAILURE',
  GET_STRIPE_STATUS_REQUEST: 'GET_STRIPE_STATUS_REQUEST',
  GET_STRIPE_STATUS_SUCCESS: 'GET_STRIPE_STATUS_SUCCESS',
  GET_STRIPE_STATUS_FAILURE: 'GET_STRIPE_STATUS_FAILURE',
  SET_CARD: 'SET_CARD',
  REST_DONATION: 'REST_DONATION',
  SET_PAYPAL_STATUS: "SET_PAYPAL_STATUS",
  SAVE_KEY: "SAVE_KEY",
  SAVE_EMAIL: "SAVE_EMAIL",
  SAVE_AMOUNT: "SAVE_AMOUNT"
};

export default paymentConstants;
