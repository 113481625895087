import React, { useState } from 'react';
import styled from '@emotion/styled';
import {
  Colors,
  Container,
  PrimaryButton,
  GreenButton,
  device,
  isLabTop,
  isTablet,
  SvgWrapper,
  isMobile,
} from 'utils';
import { Layout, PaymentCard } from 'components';
import './components/PaymentPageStyle.css';
import PaymentDonation from './components/PaymentDonation';
import { useLocation, useParams } from 'react-router-dom';
const DonationWrapper = styled.div``;

const DonationFormWrapper = styled.div`
  display: flex;
  margin-top: 30px;
  @media ${device.allMobile} {
    flex-wrap: wrap;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .paymentCard {
    label {
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .ant-row {
      display: block;
    }
    .payment-card {
      max-width: 100%;

      .ant-form-item-control-input-content {
        @media ${device.allMobile} {
          max-width: 200px;
        }
        div {
          max-width: 100%;
        }
        input {
          font-size: 18px;
        }
        #cardNumber {
          width: 300px;
        }
      }
    }
  }

  h1 {
    font-weight: bold;
  }
  .zahlungsmethode {
    h2 {
      font-size: 22px;
      font-weight: bold;
      @media ${device.allMobile} {
        font-size: 16px;
      }
    }
    & > div {
      display: flex;
      justify-content: space-around;
      font-size: 35px;
      margin-bottom: 20px;
      @media ${device.allMobile} {
        font-size: 22px;
      }
    }
  }
  .deine-label {
    input {
      border-radius: 15px;
      padding: 15px;
    }
  }
  .gesamtsumme-button {
    @media ${device.allMobile} {
      min-width: 100%;
    }
  }
  .gesamtsumme {
    display: flex;
    justify-content: space-between;
    h2 {
      font-size: 30px;
      font-weight: bold;
      margin: 0;
      @media ${device.allMobile} {
        font-size: 20px;
      }
    }
    p {
      font-size: 30px;
      font-weight: bold;
      margin: 0;
      @media ${device.allMobile} {
        font-size: 20px;
      }
    }
    & + p {
      font-size: 22px;
      margin-bottom: 30px;
      @media ${device.allMobile} {
        font-size: 16px;
      }
    }
  }
  .danke {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: 500px;
    p {
      text-align: left;
      font-size: 22px;
      @media ${device.laptop} {
        font-size: 18px;
      }
      @media ${device.allMobile} {
        font-size: 16px;
      }
    }
  }
  & > div {
    width: 100%;
    padding: 20px 30px;
    &:first-of-type {
      padding-left: 0;
    }
    &:nth-of-type(2) {
      padding-right: 0;
    }
    @media ${device.allMobile} {
      width: 100%;
    }
    @media ${device.allMobile} {
      padding: 0;
    }
    @media ${device.tablet} {
      margin-top: 0;
    }
    .ant-checkbox-wrapper {
      a {
        color: ${Colors.secondaryColor};
        margin: 0 3px;
        display: inline-block;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .freiwilliges-input {
      position: relative;
      .anticon {
        position: absolute;
        right: 150px;
        font-size: 22px;
        top: 50%;
        transform: translateY(-50%);
        @media ${device.allMobile} {
          font-size: 16px;
          right: 10px;
        }
      }
    }
    .donation-input {
      display: flex;
      padding: 20px 20px 20px 20px;
      border: 1px solid ${Colors.steel};
      border-radius: 10px;
      justify-content: space-between;
      align-items: center;
      @media ${device.allMobile} {
        padding: 10px;
      }
      input,
      .ant-input-group-addon {
        border: 0;
        background: transparent;
        font-weight: bold;
        font-size: 22px;
        @media ${device.allMobile} {
          font-size: 16px;
        }
      }

      .ant-form-item-control {
        text-align: right;
        @media ${device.tablet} {
          max-width: 97px;
        }
        @media ${device.allMobile} {
          max-width: 55%;
        }
        input {
          font-weight: bold;
          font-size: 22px;
          padding-right: 10px;
          text-align: right;
          max-width: 100%;
          @media ${device.allMobile} {
            font-size: 16px;
            max-width: 150px;
          }
          @media ${device.tablet} {
            font-size: 16px;
            max-width: 104px;
          }
        }
        .ant-input-group-addon {
          padding: 0;
          text-align: left;
        }
      }
      .ant-form-item-label {
        width: 40%;
        display: inline-block;
        text-align: left;
        padding: 0;
        @media ${device.tablet} {
          width: 60%;
        }
        @media ${device.allMobile} {
          width: 45%;
          max-width: 45%;
        }
        label {
          font-size: 22px;
          font-weight: bold;
          @media ${device.allMobile} {
            font-size: 16px;
            line-height: 1;
          }
          &:before,
          &:after {
            content: none;
          }
        }
      }
    }
  }

  .show-options {
    .donation-input {
      .ant-form-item-label {
        width: 20%;
        @media ${device.laptop} {
          width: 31%;
          max-width: 31%;
        }
        @media ${device.allMobile} {
          width: 80px;
          max-width: 80px;
        }
        @media ${device.tablet} {
          width: 35%;
          max-width: 35%;
        }
      }
      .ant-form-item-control {
        max-width: 80%;
        @media ${device.laptop} {
          width: 69%;
          max-width: 69%;
        }
        @media ${device.tablet} {
          width: 65%;
          max-width: 65%;
        }
        @media ${device.allMobile} {
          width: calc(100% - 80px);
          max-width: calc(100% - 80px);
        }
      }
    }
    .freiwilliges-option {
      @media ${device.allMobile} {
        display: flex;
        max-width: 223px;
        margin-left: auto;

        overflow: scroll;
      }

      div {
        display: inline-block;
        button {
          margin: 0;
          @media ${device.allMobile} {
            padding: 5px 10px;
            font-size: 15px;
            border-radius: 5px;
            min-width: 56px;
          }
        }
        & + div {
          margin-left: 15px;
          @media ${device.tablet} {
            margin-left: 10px;
          }
          @media ${device.allMobile} {
            margin-left: 10px;
          }
        }
      }
    }
  }
`;

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const PaymentPage = () => {
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [showOption, setShowOption] = useState(false);
  const [otherOption, setOtherOption] = useState(false);
  const [freiwilliges, setFreiwilliges] = useState(0);
  const [currentView, setCurrentView] = useState(null);
  const [allRewards, setAllRewards] = useState([]);
  const [selectedRewards, setSelectedRewards] = useState([]);
  const [clientSecret, setClientSecret] = useState(null);

  let query = useQuery();

  const [donation, setDonation] = useState(Number(query.get('donation')) || 0);

  //Handlers

  const donationFormRef = React.createRef();

  const params = useParams();
  const id = params?.id || 0;
  console.log('donation--->', donation, id);

  const originalRewardId = Number(Number(query.get('rewardId')) || 0);

  return (
    <DonationWrapper>
      <Layout container={true}>
        <div>
          <div className="inner_div">
            <GreenButton
              primary={true}
              //   minWidth={isLabTop() ? '100px' : '150px'}
            >
              Return to FundRaiser
            </GreenButton>
            <div style={{ display: 'flex', gap: '1rem' }}>
              <div style={{ width: '8.25rem' }}>
                <img src="https://images.gofundme.com/HvXfKG7W0yMrGlwuaZY9yDH7gUw=/640x480/https://d2g8igdw686xgo.cloudfront.net/70679293_1674593140174653_r.jpeg" alt="fund-me-img" />
              </div>

              <div>
                You're supporting Half Moon Bay Farmworker Shooting & Flood
                Victims Your donation will benefit San Jose Parks Foundation
              </div>
            </div>
            <div>
              <DonationFormWrapper>
                <PaymentDonation
                  showPaymentForm={showPaymentForm}
                  showOption={showOption}
                  otherOption={otherOption}
                  donation={donation}
                  freiwilliges={freiwilliges}
                  setDonationState={setDonation}
                  setCurrentViewState={setCurrentView}
                  setShowOptionState={setShowOption}
                  setOtherOptionState={setOtherOption}
                  setFreiwilligesState={setFreiwilliges}
                  setShowPaymentFormState={setShowPaymentForm}
                  projectId={id}
                  originalRewardId={originalRewardId}
                  setAllRewards={setAllRewards}
                  setSelectedRewards={setSelectedRewards}
                  allRewards={allRewards}
                  selectedRewards={selectedRewards}
                  formRef={donationFormRef}
                  // setAddressRequired={setAddressRequired}
                  handleCLientSecret={setClientSecret}
                />
              </DonationFormWrapper>
            </div>
          </div>
        </div>
      </Layout>
    </DonationWrapper>
  );
};

export default PaymentPage;
