import React, { Component } from 'react';
import {
  Colors,
  SvgWrapper,
  isMobile,
  device,
  isLabTop,
  isTablet,
  isMinLaptop,
  is4K,
  H3,
} from 'utils';
import { ChooseProjectIcon } from 'images';
import styled from '@emotion/styled';
import SectionTitleH2 from 'utils/SectionTitleH2';

const ChooseProjectWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 150px;
  @media ${device.allMobile} {
    flex-wrap: wrap;
    margin-bottom: 25px;
  }
  .content {
    width: 60%;
    margin-left: 25px;
    text-align: left;

    @media ${device.allMobile} {
      width: 100%;
      order: 2;
      text-align: center;
      margin-left: 0;
    }
    h2 {
      margin-bottom: 10px;
      margin-top: 0;
      display: flex;
      align-items: center;
      font-weight: bold;
      @media ${device.allMobile} {
        justify-content: center;
      }
      @media ${device.laptop} {
        font-size: 25px;
      }
      &::before {
        content: '1';
        font-size: 30px;
        background: ${Colors.secondaryColor};
        width: 50px;
        text-align: center;
        height: 50px;
        line-height: 50px;
        vertical-align: middle;
        border-radius: 50%;
        display: inline-block;
        margin-right: 10px;
        color: ${Colors.white};
      }
    }
    p {
      margin: 0 0 10px;
      font-size: 22px;
      max-width: 100%;
      margin-left: 60px;
      @media ${device.laptop} {
        font-size: 18px;
      }
      @media ${device.allMobile} {
        margin: auto;
        max-width: 100%;
      }
    }
  }
  .image {
    width: 40%;
    @media ${device.allMobile} {
      width: 100%;
      order: 1;
    }
  }
`;

export default class ChooseProject extends Component {
  componentDidMount() {
    const Icon = document.getElementById('freepik_stories-admin');

    window.addEventListener('scroll', function () {
      if (isMobile()) {
        if (window.scrollY >= 4565 && window.scrollY <= 5360) {
          Icon.classList.add('animated');
        } else {
          Icon.classList.remove('animated');
        }
      } else if (isTablet()) {
        if (window.scrollY >= 3807 && window.scrollY <= 4761) {
          Icon.classList.add('animated');
        } else {
          Icon.classList.remove('animated');
        }
      } else if (isMinLaptop()) {
        if (window.scrollY >= 3804 && window.scrollY <= 4811) {
          Icon.classList.add('animated');
        } else {
          Icon.classList.remove('animated');
        }
      } else if (isLabTop()) {
        if (window.scrollY >= 4281 && window.scrollY <= 7599) {
          Icon.classList.add('animated');
        } else {
          Icon.classList.remove('animated');
        }
      } else if (is4K()) {
        if (window.scrollY >= 6200 && window.scrollY <= 7300) {
          Icon.classList.add('animated');
        } else {
          Icon.classList.remove('animated');
        }
      } else {
        if (window.scrollY >= 5891 && window.scrollY <= 6792) {
          Icon.classList.add('animated');
        } else {
          Icon.classList.remove('animated');
        }
      }
    });
  }
  render() {
    return (
      <ChooseProjectWrapper>
        <div className="image">
          <SvgWrapper className="choose-icon" width="80%" height="auto">
            <ChooseProjectIcon />
          </SvgWrapper>
        </div>
        <div className="content">
          <SectionTitleH2>Finde dein Projekt</SectionTitleH2>
          <p>
            Auf commonsplace erhältst du einen Überblick über großartige Ideen
            und Projekte deiner Community, die deine Unterstützung brauchen. Auf
            den jeweiligen Projektseiten findest du alle von dem
            Projektersteller zur Verfügung gestellten Informationen. Finde ein
            Projekt, das dich überzeugt!
          </p>
        </div>
      </ChooseProjectWrapper>
    );
  }
}
