import React, { Component } from 'react';
import { Form, Input, Spin } from 'antd';
import { UploadImage } from 'components';
import { connect } from 'react-redux';
import {
  Container,
  PrimaryButton,
  formLayout,
  formValidateMessages,
  H1,
  H2,
  FormWrapper,
} from 'utils';
import PropTypes from 'prop-types';
import pickBy from 'loadsh/pickBy';
import identity from 'loadsh/identity';
import isEmpty from 'loadsh/isEmpty';
const FormItem = Form.Item;

class Media extends Component {
  static propTypes = {
    updateDraft: PropTypes.func.isRequired,
    updateDraftAndImages: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    projectId: PropTypes.string,
    uploadImages: PropTypes.func.isRequired,
    currentStep: PropTypes.number.isRequired,
    setCanMove: PropTypes.func.isRequired,
    showModal: PropTypes.bool.isRequired,
    selectedStep: PropTypes.number.isRequired,
    setSubmitFromModal: PropTypes.func.isRequired,
  };

  state = {
    bannerImage: null,
    outerImage: null,
    images: null,
    mobileImage: null,
    mobileImagesArray: null,
    showErrors: false,
  };
  // setBannerImage = (image) => this.setState({ bannerImage: image });
  setOuterImage = (image) =>
    this.setState({ outerImage: image.length ? image : null });
  setMobileImage = (image) =>
    this.setState({ mobileImage: image.length ? image : null });
  setImages = (image) => this.setState({ images: image.length ? image : null });
  setMobileImages = (image) =>
    this.setState({ mobileImagesArray: image.length ? image : null });
  //   onFinish = (values) => {
  //     const { bannerImage, outerImage, images, mobileImage, mobileImagesArray } = this.state;
  //     const {
  //       updateDraftAndImages,
  //       projectId,
  //       currentStep,
  //       setCanMove,
  //       showModal,
  //       selectedStep,
  //       setSubmitFromModal,
  //     } = this.props;
  //     const formData = new FormData();

  //     // if(bannerImage[0].thumbUrl){
  //     //   formData.append(
  //     //     'project[banner]',
  //     //     bannerImage[0].originFileObj,
  //     //     bannerImage[0].name
  //     //   );
  //     // }

  //     if (outerImage && outerImage[0].thumbUrl) {
  //       formData.append(
  //         'project[outer_image]',
  //         outerImage[0].originFileObj,
  //         outerImage[0].name
  //       );
  //     }
  // console.log("OUTER IMAGE",mobileImage);
  //     if ( mobileImage && mobileImage[0].thumbUrl) {
  //       formData.append(
  //         'project[mobile_image]',
  //         mobileImage[0].originFileObj,
  //         mobileImage[0].name
  //       );
  //     }

  //     if (images) {
  //       const imgArr = images?.filter((img) => img.thumbUrl);

  //       imgArr.forEach((file) => {
  //         formData.append('project[images][]', file.originFileObj, file.name);
  //       });
  //     }

  //     if (mobileImagesArray) {
  //       const imgArr = mobileImagesArray
  //       ?.filter((img) => img.thumbUrl);

  //       imgArr.forEach((file) => {
  //         formData.append('project[mobile_array_images][]', file.originFileObj, file.name);
  //       });
  //     }
  //     if (showModal) {
  //       updateDraftAndImages(
  //         projectId,
  //         { video: values.video },
  //         formData,
  //         selectedStep - 1
  //       );
  //       setSubmitFromModal(false);
  //     } else {
  //       updateDraftAndImages(
  //         projectId,
  //         { video: values.video },
  //         formData,
  //         currentStep
  //       );
  //     }

  //     setCanMove(true);
  //   };

  // onfinish with separat images for desktop and mobile
  // onFinish = (values) => {
  //   const { bannerImage, outerImage, images, mobileImage, mobileImagesArray } =
  //     this.state;
  //   const {
  //     updateDraftAndImages,
  //     projectId,
  //     currentStep,
  //     setCanMove,
  //     showModal,
  //     selectedStep,
  //     setSubmitFromModal,
  //   } = this.props;
  //   const formData = new FormData();
  //   if (outerImage && outerImage[0]) {
  //     formData.append(
  //       'project[outer_image]',
  //       outerImage[0].originFileObj,
  //       outerImage[0].name
  //     );
  //   } else {
  //     // formData.append('project[outer_image]', null);
  //     // formData.delete('project[outer_image]');
  //     return this.setState({ showErrors: true });
  //   }

  //   // if (mobileImage && mobileImage[0]) {
  //   //   formData.append(
  //   //     'project[mobile_image]',
  //   //     mobileImage[0].originFileObj,
  //   //     mobileImage[0].name
  //   //   );
  //   // } else {
  //   //   // alert("mobileImage Image field should not be empty");
  //   //   return console.log('165');
  //   // }

  //   if (images?.length) {
  //     // const imgArr = images.filter((img) => img );

  //     // if (imgArr.length === 0) {
  //     //   // alert("desktop images Image field should not be empty");
  //     //   // not required
  //     //   return console.log('174');
  //     // }

  //     images.forEach((file) => {
  //       formData.append('project[images][]', file.originFileObj, file.name);
  //     });
  //   } else {
  //     return this.setState({ showErrors: true });
  //     // formData.append('project[images][]', null);
  //   }

  //   // if (mobileImagesArray) {
  //   //   const imgArr = mobileImagesArray.filter((img) => img);

  //   //   if (imgArr.length === 0) {
  //   //     // alert("mobile images Image field should not be empty");
  //   //     return;
  //   //   }

  //   //   imgArr.forEach((file) => {
  //   //     formData.append(
  //   //       'project[mobile_array_images][]',
  //   //       file.originFileObj,
  //   //       file.name
  //   //     );
  //   //   });
  //   // } else {
  //   //   // alert("mobile images Please upload the mobile images");
  //   //   return;
  //   // }

  //   if (showModal) {
  //     updateDraftAndImages(
  //       projectId,
  //       { video: values.video },
  //       formData,
  //       selectedStep - 1
  //     );
  //     setSubmitFromModal(false);
  //   } else {
  //     updateDraftAndImages(
  //       projectId,
  //       { video: values.video },
  //       formData,
  //       currentStep
  //     );
  //   }

  //   setCanMove(true);
  // };

  // onFinish from fixes_in_ui

  onFinish = (values) => {
    const { bannerImage, outerImage, images } = this.state;
    const {
      updateDraftAndImages,
      projectId,
      currentStep,
      setCanMove,
      showModal,
      selectedStep,
      setSubmitFromModal,
    } = this.props;
    const formData = new FormData();

    // if(bannerImage[0].thumbUrl){
    //   formData.append(
    //     'project[banner]',
    //     bannerImage[0].originFileObj,
    //     bannerImage[0].name
    //   );
    // }

    if (outerImage[0].thumbUrl) {
      formData.append(
        'project[outer_image]',
        outerImage[0].originFileObj,
        outerImage[0].name
      );
    }

    if (images) {
      const imgArr = images?.filter((img) => img.thumbUrl);

      imgArr.forEach((file) => {
        formData.append('project[images][]', file.originFileObj, file.name);
      });
    }

    if (showModal) {
      updateDraftAndImages(
        projectId,
        { video: values.video },
        formData,
        selectedStep - 1
      );
      setSubmitFromModal(false);
    } else {
      updateDraftAndImages(
        projectId,
        { video: values.video },
        formData,
        currentStep
      );
    }

    setCanMove(true);
  };

  render() {
    const { project, loading, projectId } = this.props;
    return (
      <Container className="inside-form-container">
        <>
          <Spin spinning={loading}>
            <H1>Bilder und Videos</H1>
            {!loading && (
              <Form
                {...formLayout}
                name="project-details"
                onFinish={this.onFinish}
                validateMessages={formValidateMessages}
                initialValues={
                  projectId
                    ? project
                      ? pickBy(project.media, identity)
                      : {}
                    : ''
                }
              >
                <div>
                  <H2>Video</H2>
                  <p>
                    Videos sind ein Blickfang! Um das Beste aus deinem Projekt
                    rauszuholen, solltest du auch Videos hinzufügen. Mit einem
                    Video kannst du dein Projekt oder deine Idee dem
                    Unterstützer zu einer Herzensangelegenheit machen. Sei
                    kreativ und verwende Illustrationen und andere visuelle
                    Elemente, um dich, dein Team und deine Kampagne
                    vorzustellen. Dadurch kannst du Authentizität, Vertrauen und
                    Mitgefühl schaffen. Visuelle Wahrnehmungen erhöhen deine
                    Chance, Unterstützer zu gewinnen. Verpasse die Gelegenheit
                    nicht!
                  </p>
                  <p>
                    Lade dein Video auf YouTube hoch und füge den Link hier ein:
                  </p>
                  <FormWrapper>
                    <FormItem
                      name="video"
                      label="Video link"
                      extra="Keine Youtube-Short Links"
                    >
                      <Input />
                    </FormItem>
                  </FormWrapper>
                </div>
                <div>
                  <H2>Bilder</H2>
                  <p>
                    Ein Bild sagt mehr als tausend Worte. Nutze die Aussagekraft
                    von Bildern und füge sie deiner Galerie hinzu.  Biete deiner
                    Community genügend Bilder an, damit sie sich eine gute
                    Vorstellung über dein Projekt machen können. Das Coverbild
                    ist hierbei das wichtigste! Es wird von den Usern als Erstes
                    gesehen, wenn sie auf dein Profil treffen. Die Bilder
                    sollten die Aufmerksamkeit der User auf deine Kampagne
                    ziehen und deine Ziele und Werte vermitteln.
                  </p>
                  <br />

                  <FormWrapper>
                    <>
                      {/* <UploadImage
                        required={true}
                        multiple={false}
                        label={'Bild für die Bezahlseite'}
                        name={'banner'}
                        setImage={this.setBannerImage}
                        fileList={
                          project.media &&
                          project.media.banner &&
                          project.media.banner.length > 0
                            ? project.media.banner
                            : null
                        }
                      /> */}

                      {/* <UploadImage
                        required={false}
                        multiple={false}
                        label={
                          <p>
                            {' '}
                            <span style={{ color: 'red' }}>*</span> Vorschaubild
                            (Mobile Ansicht)
                          </p>
                        }
                        name={'mobile_image'}
                        setImage={this.setMobileImage}
                        fileList={
                          project.media &&
                          project.media.mobile_image &&
                          project.media.mobile_image.length > 0
                            ? project.media.mobile_image
                            : null
                        }
                      /> */}
                      {/* {this.state.showErrors && !this.state.mobileImage && (
                        <div
                          class="ant-form-item-explain-error"
                          style={{ marginTop: '-25px' }}
                        >
                          Vorschaubild (Mobile Ansicht) ist erforderlich.{' '}
                        </div>
                      )} */}
                      {/* <p className="remark">
                        Empfohlene Größe: 500 x 330 pixels
                      </p> */}

                      <UploadImage
                        required={true}
                        multiple={false}
                        label={<p>Vorschaubild </p>}
                        name={'outer_image'}
                        setImage={this.setOuterImage}
                        fileList={
                          project.media &&
                          project.media.outer_image &&
                          project.media.outer_image.length > 0
                            ? project.media.outer_image
                            : null
                        }
                      />
                      <p className="remark">
                        Empfohlene Größe: 1080 x 720 pixels
                      </p>
                      <p className="remark" style={{ marginTop: '10px' }}>
                        Das Vorschaubild sollte für eine optimierte Ansicht
                        kleiner als 1MB sein
                      </p>
                      {this.state.showErrors && !this.state.outerImage && (
                        <div class="ant-form-item-explain-error">
                          Vorschaubild (Desktop Ansicht) ist erforderlich.{' '}
                        </div>
                      )}

                      {/* <UploadImage
                        required={false}
                        multiple={true}
                        label={
                          <p>
                            {' '}
                            <span style={{ color: 'red' }}>*</span>{' '}
                            Galeriebilder (Mobile Ansicht)
                          </p>
                        }
                        name={'mobile_array_images'}
                        setImage={this.setMobileImages}
                        fileList={
                          project.media &&
                          project.media.mobile_array_images &&
                          project.media.mobile_array_images.length > 0
                            ? project.media.mobile_array_images
                            : null
                        }
                      /> */}
                      {/* {this.state.showErrors && !this.state.mobileImagesArray && (
                        <div
                          class="ant-form-item-explain-error"
                          style={{ marginTop: '-25px' }}
                        >
                          Galeriebilder (Mobile Ansicht) sind erforderlich.{' '}
                        </div>
                      )} */}
                      {/* <p className="remark">
                        Empfohlene Größe: 500 x 330 pixels
                      </p> */}

                      <UploadImage
                        required={true}
                        multiple={true}
                        label={<p>Galeriebilder</p>}
                        name={'images'}
                        setImage={this.setImages}
                        fileList={
                          project.media &&
                          project.media.images &&
                          project.media.images.length > 0
                            ? project.media.images
                            : null
                        }
                      />
                      <p className="remark">
                        Empfohlene Größe: 1080 x 720 pixels
                      </p>
                      {this.state.showErrors && !this.state.images && (
                        <div class="ant-form-item-explain-error">
                          Galeriebilder (Desktop Ansicht) ist erforderlich..
                        </div>
                      )}
                    </>
                  </FormWrapper>
                </div>
                <FormItem>
                  <PrimaryButton
                    type="primary"
                    htmltype="submit"
                    minWidth="150px"
                    id="submit-button"
                  >
                    Speichern
                  </PrimaryButton>
                </FormItem>
              </Form>
            )}
          </Spin>
        </>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const { project, loading, errors, currentStep } = state.projects;
  return {
    currentStep,
    project,
    loading,
    errors,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Media);
