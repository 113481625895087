/* eslint-disable prettier/prettier */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { H2, Colors, Modal, PrimaryButton } from 'utils';
import styled from '@emotion/styled';
import { Spin, Table, Row, Col, Button, Tooltip } from 'antd';
import RewardCard from './RewardCard';
import { projectsServices } from 'services';
import {
  RollbackOutlined,
  CheckCircleFilled,
  InfoCircleOutlined,
} from '@ant-design/icons';
import Papa from 'papaparse';

const MySupportsWrapper = styled.div`
  margin-bottom: 40px;
  h2 {
    color: ${Colors.GreenColor};
    display: inline-block;
    font-size: 35px;
    margin-bottom: 50px;
  }
  .ant-table-thead {
    th {
      font-size: 22px;
      font-weight: bold;
      text-align: center;
    }
  }
  .project-name {
    font-size: 20px;
    font-weight: bold;
  }
  .ant-table-content {
    .ant-table-thead,
    .ant-table-cell,
    .ant-table-tbody .ant-table-row {
      border: 1px solid ${Colors.GreenColor};
    }
  }
  .ant-table-cell {
    text-align: center;
    .delete {
      text-align: center;
      button {
        border: none;
        background: none;
      }
    }
  }
  .green-text {
    color: #95bd51;
  }
  .btn {
    display: flex;
    justify-content: end;
  }
`;

export class RewardsTable extends Component {
  static propTypes = {
    projectId: PropTypes.string.isRequired,
  };

  state = {
    loading: false,
    loadingRewardTable: false,
    perPage: 5,
    currentPage: 1,
    rewards: [],
    viewSingleReward: false,
    rewardTableData: null,
    rewardQuestions: null,
    selectedRewardId: null,
    showModal: false,
    selectedOrderId: null,
  };

  async componentDidMount() {
    this.setState({ loading: true });

    const rewards = await projectsServices.getProjectRewards(
      this.props.projectId,
      true
    );
    this.setState({ rewards, loading: false });
  }

  handleChooseReward = async (rewardId) => {
    this.setState({ loading: true, selectedRewardId: rewardId });

    // Get the Attribute Names From the Questions
    const rewardQuestions = await projectsServices.getRewardQuestions(rewardId);

    // Get the Data for the First Page
    const rewardOrder = await projectsServices.getRewardOrder(
      rewardId,
      this.state.perPage,
      this.state.currentPage
    );

    // Get the Variable Columns of Questions
    let questionsLabels = [];
    rewardQuestions[rewardId].forEach((question) => {
      questionsLabels.push(question[0]);
    });

    // Set the State
    this.setState({
      loading: false,
      viewSingleReward: true,
      rewardTableData: rewardOrder,
      rewardQuestions: [...questionsLabels],
    });
  };

  handelCloseModal = () => {
    this.setState({ showModal: false, selectedOrderId: null });
  };
  toggleOrderSent = async () => {
    // Set the Table to load
    this.setState({ loadingRewardTable: true, showModal: false });

    // Send Request to toggle Sent
    await projectsServices.toggleOrderSent(this.state.selectedOrderId);

    // Resend the Request to get the current table values
    const rewardOrder = await projectsServices.getRewardOrder(
      this.state.selectedRewardId,
      this.state.perPage,
      this.state.currentPage
    );

    // Set the Table data and stop the loading
    this.setState({
      rewardTableData: rewardOrder,
      loadingRewardTable: false,
      showModal: false,
    });
  };

  handleDownloadCSV = () => {

    // Convert goodies data to CSV format
    const csvData = Papa.unparse(this.state.rewardTableData.orders);

    // Create a blob containing the CSV data
    const blob = new Blob([csvData], { type: 'text/csv' });

    // Create a temporary anchor element to trigger the download
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'booked_goodies_sent.csv';
    link.click();
  };

  render() {
    const { perPage } = this.state;
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text) => <p>{text}</p>,
      },
      {
        title: 'Anschrift',
        dataIndex: 'address',
        key: 'address',
        render: (text) => <p>{text}</p>,
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        render: (text) => <p>{text}</p>,
      },
    ];

    // Add columns from the Questions
    if (this.state.rewardTableData && this.state.rewardQuestions) {
      this.state.rewardQuestions.forEach((question, index) => {
        columns.push({
          title: () => <span>F{index + 1} <Tooltip
            color="#95bd51"
            title={`Frage ${index + 1}`}
          >
            <Button type="link">
              <InfoCircleOutlined
                style={{ color: 'black', fontSize: '12px' }}
              />
            </Button>
          </Tooltip></span>,
          dataIndex: question,
          key: question,
          responsive: ['md'],
          render: (text) => <p>{text}</p>,
        });
      });
    }
    columns.push({
      title: <span>Versendet <Tooltip
        color="#95bd51"
        title="Der Unterstützer wird benachrichtigt, wenn du das Goody versendet hast."
      >
        <Button type="link">
          <InfoCircleOutlined
            style={{ color: 'black', fontSize: '12px' }}
          />
        </Button>
      </Tooltip></span>,
      dataIndex: 'sent',
      key: 'sent',
      render: (text, order) => {
        if (text)
          return (
            <Button
              onClick={() => {
                this.setState({ showModal: true, selectedOrderId: order.id });
              }}
              type="text"
              size="large"
              shape="circle"
              icon={
                <CheckCircleFilled
                  className="green-text"
                  style={{ fontSize: '40px' }}
                />
              }
            />
          );
        return (
          <Button
            onClick={() =>
              this.setState({ showModal: true, selectedOrderId: order.id })
            }
            type="text"
            size="large"
            shape="circle"
            icon={
              <CheckCircleFilled style={{ color: 'grey', fontSize: '40px' }} />
            }
          />
        );
      },
    });

    return (
      <Spin spinning={this.state.loading}>
        {this.state.rewards.length !== 0 && (
          <MySupportsWrapper>
            {
              <>
                <H2>Goodies-Übersicht</H2>
                <p>
                  Es ist deine Aufgabe die Projektinitiatoren bzgl. der Goodies
                  immer auf dem Laufenden zu halten, sie rechtzeitig über
                  Verzögerungen zu informieren und ihnen Kompromisse anzubieten,
                  wenn etwas nicht wie geplant klappt.
                </p>
                <p>
                  Mit der Unterstützung schließt der Unterstützer einen Vertrag
                  mit dir über die Lieferung des Goodies und hat somit rechtlich
                  Anspruch darauf.
                </p>

                {this.state.viewSingleReward && (
                  <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                    <PrimaryButton
                      onClick={() => this.setState({ viewSingleReward: false })}
                    >
                      Zurück <RollbackOutlined />
                    </PrimaryButton>
                  </div>
                )}

                {/* Display All Rewards */}
                {this.state.viewSingleReward === false && (
                  <Row>
                    {this.state.rewards.map((reward, i) => {
                      return (
                        <Col
                          key={i}
                          xs={{ span: 24, offset: 0 }}
                          md={{ span: 24, offset: 0 }}
                          sm={{ span: 24, offset: 0 }}
                          lg={{ span: 12, offset: 0 }}
                          xl={{ span: 8, offset: 0 }}
                          style={{ padding: '10px' }}
                        >
                          <RewardCard
                            reward={reward}
                            handleChooseReward={this.handleChooseReward}
                          />
                        </Col>
                      );
                    })}
                  </Row>
                )}
                {this.state.viewSingleReward && (
                  <div>
                    <div
                      style={{
                        marginBottom: '10px',
                        fontSize: '16px',
                        fontWeight: '700',
                      }}
                    >
                      {this.state.rewardTableData &&
                        this.state.rewardQuestions &&
                        this.state.rewardQuestions.map((question, index) => {
                          return (
                            <div>
                              F{index + 1}: {question}
                              <Tooltip
                                title={`Frage ${index + 1}`}
                                color="#95bd51"
                              >
                                <Button type="link">
                                  <InfoCircleOutlined
                                    style={{ color: 'black', fontSize: '12px' }}
                                  />
                                </Button>
                              </Tooltip>
                            </div>
                          );
                        })}
                    </div>
                    <div className='btn'>
                      <PrimaryButton onClick={() => this.handleDownloadCSV()}>
                        Download CSV
                      </PrimaryButton>
                    </div>
                    <Table
                      columns={columns}
                      dataSource={this.state.rewardTableData.orders}
                      loading={
                        this.state.loading || this.state.loadingRewardTable
                      }
                      pagination={{
                        total: this.state.rewardTableData.count,
                        pageSize: perPage,
                        pageSizeOptions: [5, 10, 20, 50],
                        showTotal: (total, range) =>
                          `${range[0]}-${range[1]} von ${total} Goodies`,
                        onChange: async (page, pageSize) => {
                          this.setState({ loadingRewardTable: true });
                          const rewardOrder =
                            await projectsServices.getRewardOrder(
                              this.state.selectedRewardId,
                              pageSize,
                              page
                            );
                          this.setState({
                            perPage: pageSize,
                            currentPage: page,
                            rewardTableData: rewardOrder,
                            loadingRewardTable: false,
                          });
                        },
                      }}
                    />
                  </div>
                )}
              </>
            }
            <Modal
              showModal={this.state.showModal}
              handelCloseModal={this.handelCloseModal}
            >
              <div style={{ padding: '10px 30px' }}>
                <div style={{ fontSize: '24px' }}>
                  Bestätige, dass du das Goody schon versendet hast.
                  <p style={{ fontSize: '16px' }}>
                    Der Unterstützer erhält eine E-Mail, dass du sein Goody
                    versendet hast, wenn du bestätigst.
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    marginTop: '20px',
                  }}
                >
                  <PrimaryButton onClick={() => this.toggleOrderSent()}>
                    Bestätigen
                  </PrimaryButton>
                  <PrimaryButton
                    onClick={() => this.handelCloseModal()}
                    style={{ backgroundColor: '#F44336', color: '#FFF' }}
                  >
                    Abbrechen
                  </PrimaryButton>
                </div>
              </div>
            </Modal>
          </MySupportsWrapper>
        )}
      </Spin>
    );
  }
}

export default RewardsTable;
