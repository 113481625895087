/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import PropTypes from 'prop-types';
// import { Colors } from 'utils';

const AfterBackground = ({ color, width, height }) => {
  return (
    <svg
      id="wave-reverse"
      viewBox="0 0 5001 1024"
      css={css`
        fill: ${color || '#f9f9f9'};
        max-width: ${width || '100%'};
        max-height: ${height || '100%'};
        height: ${height || '100%'};
        width: ${width || '100%'};
        display: inline-block;
        transform: rotate(180deg);
      `}
    >
      <title></title>
      <path d="M5000.594 0v1024h-5000.594c174.876-322.47 1005.257-652.719 2436.179-775.418 828.608-49.644 1678.492 63.647 2450.834-207.767 38.101-13.388 75.66-26.885 113.581-40.815z"></path>
    </svg>
  );
};

export default AfterBackground;

AfterBackground.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  opacity: PropTypes.string,
};
