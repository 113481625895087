import React, { Component } from 'react';
import { Form, Input, List, Spin } from 'antd';
import { Header } from 'components';
import { Container, Colors, FormWrapper, PrimaryButton, Section, isMobile, Modal, H2 } from 'utils';
import { Table } from 'antd';
import styled from '@emotion/styled';
import { store } from 'react-notifications-component';
import { adminServices } from 'services';
import { connect } from 'react-redux';
import { AdminActions } from 'redux/actions';
import PropTypes from 'prop-types';
import { CitiesSelect, CategoriesSelect } from 'components';
const TableWrapper = styled.div`
  padding: 50px 0;
`;

const Wrapper = styled.div`
  .container {
    max-width: 1000px;
    color: ${Colors.GreenColor};
    h1 {
      color: ${Colors.GreenColor};
      display: inline-block;
      padding: 10px;
      border-radius: 10px;
      font-size: 35px;
      margin-top: 30px;
      margin-bottom: 0px;
      font-weight: bold;
      width: 100%;
      text-align: center;
    }
  }
  label {
    font-weight: bold;
    font-size: 22px;
    display: block;
  }

  .submit-button {
    text-align: center;
    margin-top: 50px;
  }
  .ant-form-item {
    display: block;
    .ant-form-item-label {
      color: ${Colors.GreenColor};
      margin-bottom: 10px;
    }
    .ant-picker,
    .ant-input-affix-wrapper {
      width: 100%;
      padding: 10px;
      border: 1px solid ${Colors.GreenColor};
      border-radius: 10px;
    }
  }
  .ant-select-selector,
  input,
  textarea {
    border-radius: 15px !important;
    width: 100%;
    border-radius: 10px;
    line-height: 22px;
    vertical-align: middle;
  }
  .subtitle {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 30px;
  }
  .intro-text {
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 30px;
  }
`;

class ParticipantPage extends Component {
  static propTypes = {
    goodies: PropTypes.any,
    getParticipants: PropTypes.func.isRequired
  };

  state = {
    participants: [],
  };
  componentDidMount() {
    const { getParticipants } = this.props;
    getParticipants().then((res) => {
      this.setState({participants: res?.participants})
    })
  }

  render() {
    const { participants } = this.state;
  
    const columns = [{
      title: "Vorname",
      dataIndex: "first_name",
      key: "firstName"
    }, {
      title: "Nachname",
      dataIndex: "last_name",
      key: "lastname"
    }, {
      title: "Email",
      dataIndex: "email",
      key: "email"
    }];

    return (
      <>
        <Wrapper>
          <Header relativeHeader={true} />
          <Container className="container">
            <Section>
              <h1>Teilnehmer Page</h1>
              <TableWrapper>
                <Table
                  columns={columns}
                  dataSource={participants}
                  pagination={false}
                />
              </TableWrapper>
            </Section>
          </Container>
        </Wrapper>
      </>
    );
  }
}

const mapStateToProps = () => {
};

const mapDispatchToProps = (dispatch) => ({
  getParticipants: () =>
    dispatch(adminServices.getParticipants),
});

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantPage);