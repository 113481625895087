export const isAuthenticated = () => {
  if (window.location.href.includes('testing')) {
    return !!localStorage.getItem('STAGING_USER');
  }
  return true;
};

export const loginUser = (userData) => {
  localStorage.setItem('STAGING_USER', JSON.stringify(userData));
};

export const logoutUser = () => {
  localStorage.removeItem('STAGING_USER');
};
