/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { Colors } from 'utils';

const ShowMoreIcon = (props) => {
  const { color, width, height } = props;
  return (
    <svg
      {...props}
      height={`${height || '100%'}`}
      width={`${width || '100%'}`}
      viewBox="0 0 317.41 234.57"
      css={css`
        fill: ${color || Colors.black};
        max-width: ${width || '100%'};
        max-height: ${height || '100%'};
        height: ${height || '100%'};
        width: ${width || '100%'};
        display: inline-block;
        enable-background: new 0 0 512 512;
      `}
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="319.96"
          y1="51.75"
          x2="183.62"
          y2="123.17"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0"></stop>
          <stop offset="1" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="-35.98"
          y1="169.91"
          x2="195.04"
          y2="123.38"
          xlinkHref="#linear-gradient"
        ></linearGradient>
        <linearGradient
          id="linear-gradient-3"
          x1="225.89"
          y1="83.2"
          x2="225.89"
          y2="83.2"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.01" stopColor="#fff"></stop>
          <stop offset="0.13" stopColor="#fff" stopOpacity="0.69"></stop>
          <stop offset="0.15" stopColor="#fff" stopOpacity="0.61"></stop>
          <stop offset="0.2" stopColor="#fff" stopOpacity="0.47"></stop>
          <stop offset="0.25" stopColor="#fff" stopOpacity="0.34"></stop>
          <stop offset="0.3" stopColor="#fff" stopOpacity="0.23"></stop>
          <stop offset="0.36" stopColor="#fff" stopOpacity="0.15"></stop>
          <stop offset="0.44" stopColor="#fff" stopOpacity="0.08"></stop>
          <stop offset="0.52" stopColor="#fff" stopOpacity="0.03"></stop>
          <stop offset="0.65" stopColor="#fff" stopOpacity="0.01"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="linear-gradient-4"
          x1="46.6"
          y1="87.9"
          x2="137.24"
          y2="39.18"
          xlinkHref="#linear-gradient"
        ></linearGradient>
        <linearGradient
          id="linear-gradient-5"
          x1="118.7"
          y1="41.99"
          x2="127.31"
          y2="39.2"
          xlinkHref="#linear-gradient"
        ></linearGradient>
        <linearGradient
          id="linear-gradient-6"
          x1="120.74"
          y1="41.79"
          x2="128.73"
          y2="36.64"
          xlinkHref="#linear-gradient"
        ></linearGradient>
        <linearGradient
          id="linear-gradient-7"
          x1="123.04"
          y1="39.88"
          x2="131.03"
          y2="34.72"
          xlinkHref="#linear-gradient"
        ></linearGradient>
      </defs>
      <title>25</title>
      <path
        d="M151.25,6.66a92.46,92.46,0,0,1,25,7.59c11,5.27,20.08,13.36,30.42,19.66,17.62,10.73,38.72,16.8,58.75,21,8,1.69,16.1,3.26,23.3,7.07,11,5.84,18.88,16.43,24.36,27.66,12,24.65,14.19,54.55,3.51,79.85-10.44,24.59-34.29,45.36-61.39,48C221,221,186.81,227.7,152.55,231.94c-23.79,3.52-47.68,7-71.73,7.31-10,.11-20.26-.38-29.79-3.61C33.15,229.55,17,217,10,199.08a58.33,58.33,0,0,1,1.37-45.35c4-8.69,10.22-16.36,13.13-25.48,2.63-8.24,2.34-16.94,1.94-25.47A63.36,63.36,0,0,1,29.39,79.3C34.13,63.81,41.6,49,52.15,36.68c13-15.25,30.9-26.67,50.61-30.35A120.51,120.51,0,0,1,125.85,4.7,164.91,164.91,0,0,1,151.25,6.66Z"
        transform="translate(-6.08 -4.69)"
        fill="#95BD51"
        opacity="0.18"
        style={{ isolation: 'isolate' }}
      ></path>
      <path
        d="M97.79,176a6.85,6.85,0,0,0,.55,2.92,4.89,4.89,0,0,0,2.12,2,8.43,8.43,0,0,0,6.21.61,1.25,1.25,0,0,0,.72-.43,1.1,1.1,0,0,0,.12-.62,4.59,4.59,0,0,0-1.62-2.92,10.07,10.07,0,0,1-2.24-2.56c-1-1.92-.24-7-3.86-6.22C96.77,169.41,97.71,173.83,97.79,176Z"
        transform="translate(-6.08 -4.69)"
        fill="#ffbaa8"
      ></path>
      <path
        d="M97.29,179.43s-.51,1.26.12,2,7.13,4.78,11.51,1.53-5.94-3.68-5.94-3.68Z"
        transform="translate(-6.08 -4.69)"
        fill="#95BD51"
      ></path>
      <path
        d="M97.55,176.48a.93.93,0,0,1,1.17.31A3.81,3.81,0,0,0,102.2,178c1.42-.34,2-2,2.7-2s4.5,3.22,4.71,4.6-1.72,2.4-3.86,2.53-7-1.34-7.89-2.47A4.53,4.53,0,0,1,97.55,176.48Z"
        transform="translate(-6.08 -4.69)"
        fill="#fff"
      ></path>
      <path
        d="M85.38,177.94a6.85,6.85,0,0,0,.55,2.92,4.86,4.86,0,0,0,2.13,2,8.4,8.4,0,0,0,6.2.61A1.25,1.25,0,0,0,95,183a1.1,1.1,0,0,0,.12-.62,4.59,4.59,0,0,0-1.62-2.92,10.07,10.07,0,0,1-2.24-2.56c-.95-1.92-.24-7-3.86-6.21C84.36,171.39,85.3,175.81,85.38,177.94Z"
        transform="translate(-6.08 -4.69)"
        fill="#ffbaa8"
      ></path>
      <path
        d="M84.88,181.41s-.51,1.26.12,2,7.13,4.78,11.51,1.53-5.94-3.68-5.94-3.68Z"
        transform="translate(-6.08 -4.69)"
        fill="#95BD51"
      ></path>
      <path
        d="M85.14,178.47a.92.92,0,0,1,1.17.3A3.82,3.82,0,0,0,89.79,180c1.42-.34,2-2,2.71-2s4.49,3.22,4.7,4.6-1.72,2.4-3.86,2.53-7-1.34-7.89-2.47A4.51,4.51,0,0,1,85.14,178.47Z"
        transform="translate(-6.08 -4.69)"
        fill="#fff"
      ></path>
      <path
        d="M82.8,124.52s-.68,37.17,2.07,49.57l7,.86,4.84-50.89Z"
        transform="translate(-6.08 -4.69)"
        fill="#473f47"
      ></path>
      <path
        d="M104.38,120.46s3.29,20-.7,52.25l-7,.87-4.84-50.9Z"
        transform="translate(-6.08 -4.69)"
        fill="#473f47"
      ></path>
      <path
        d="M103.74,104.78c0,.67.09,1.35.09,2,0,3.71-.86,7.53.25,11.17a4.06,4.06,0,0,1,.3,2.48,3,3,0,0,1-1.55,1.49c-3.27,1.79-7,2.34-10.74,2.86-2.77.39-6.71,1.21-9.27-.28a4.65,4.65,0,0,1-1.82-3,57,57,0,0,1-1.73-11.7c0-.52-.07-1-.1-1.54-.21-3.56-.29-7.11-.22-10.66,0-2.58.26-5.37,1.94-7.32,2.24-2.59,6.57-4.31,9.86-4.91A11.09,11.09,0,0,1,94,85.21a13.77,13.77,0,0,1,4.38,1.49c1.64.81,3.09,1.49,3.89,3.1a22.13,22.13,0,0,1,1.83,5.89,21.43,21.43,0,0,1,.1,5.77c-.1.94-.34,1.87-.43,2.81C103.75,104.44,103.75,104.61,103.74,104.78Z"
        transform="translate(-6.08 -4.69)"
        fill="#fff"
      ></path>
      <path
        d="M100.64,88l9.79,5.22,20.72-7.36,3.6,8.86s-19,9.72-22.67,9.76-13.32-2.63-13.32-2.63Z"
        transform="translate(-6.08 -4.69)"
        fill="#fff"
      ></path>
      <path
        d="M109.23,98.82c-1.11-.13-6.66-2.89-7.27-3.35.85,1.79,2,7.35,2.19,7.46a18.07,18.07,0,0,0,6.7,1.85,13.48,13.48,0,0,0,6.8-1.28,33.53,33.53,0,0,1,3.21-1.65c.66-.25,1.35-.39,2-.61a19.35,19.35,0,0,0,4.83-2.67,1.93,1.93,0,0,0,1-1.19c.1-.66-.49-1.22-1.09-1.51a7.48,7.48,0,0,0-3-.63,13.6,13.6,0,0,0-4.08.09c-.85.24-1.48,1-2.31,1.41C115.81,97.79,111.83,99.12,109.23,98.82Z"
        transform="translate(-6.08 -4.69)"
        fill="#020202"
        opacity="0.1"
      ></path>
      <path
        d="M88.61,125.79c-1.08.31-3.56-.67-4.38-.82-.5-.08-1-.33-1.43-.45-2.46-.61-2.75-7.51-3-9.37-.64-4.91-1.62-10-1.68-14.92a16.58,16.58,0,0,1,.65-5.62,6.52,6.52,0,0,1,3.69-4.1,1.86,1.86,0,0,1,1.09-.11c1.49.38,1.8,2.85,2.06,4,.45,2.1,1.47,3.06,2.51,4.74s1.11,3.78,2.1,5.51a4,4,0,0,0,1,1.26,4.54,4.54,0,0,0,2.8.59l7.06,0a2.23,2.23,0,0,1,1.61.4,2.31,2.31,0,0,1,.42,1.9l-.43,5.22a1.19,1.19,0,0,1-.17.65,1.25,1.25,0,0,1-.76.37,18.91,18.91,0,0,0-5.57,1.71,32.22,32.22,0,0,1-5.42,3,5.2,5.2,0,0,0-1.48,1,3.26,3.26,0,0,0-.71,2.23c0,.51.65,2.08.46,2.45A.71.71,0,0,1,88.61,125.79Z"
        transform="translate(-6.08 -4.69)"
        fill="#020202"
        opacity="0.1"
      ></path>
      <path
        d="M96.16,82.31a2.34,2.34,0,0,1-1.51.76,7.93,7.93,0,0,1-1.46,0l-.27,0a.33.33,0,0,0-.2,0,.29.29,0,0,0-.09.22,16.74,16.74,0,0,0,0,2.92h0c0,.18,0,.35,0,.53a1,1,0,0,1,0,.55.93.93,0,0,1-.59.38,4.31,4.31,0,0,1-1.32.23A5.82,5.82,0,0,1,88,87a26.64,26.64,0,0,0-.26-5c-.31-2.13-.87-4.26-.64-6.4A4.12,4.12,0,0,1,88,73.31a4.19,4.19,0,0,1,3.17-1.14,8.12,8.12,0,0,1,4.35,1.19c.48.33.53.75.64,1.29.17.84.3,1.69.4,2.54s.16,1.94.18,2.92A3.28,3.28,0,0,1,96.16,82.31Z"
        transform="translate(-6.08 -4.69)"
        fill="#ffbaa8"
      ></path>
      <path
        d="M94.46,73.84c-1,.27-2.29,0-3.3.44a3.23,3.23,0,0,0-1.58,3.93c-.41.15-.49-.6-.81-.9s-1,.1-1.09.62a3.23,3.23,0,0,0,.45,1.48,4.41,4.41,0,0,1,.2,2.91c-.07.23-.24.5-.46.44s-.39-.45-.53-.62a6.38,6.38,0,0,0-.73-.74c-.5-.44-1.06-.83-1.58-1.26s-.71-.84-1.31-1A11.41,11.41,0,0,0,82,78.93a2.49,2.49,0,0,1-1.2-4.29,1.73,1.73,0,0,0,.49-.52,1.58,1.58,0,0,0,.06-.72,3.82,3.82,0,0,1,1.78-3.15,6.5,6.5,0,0,1,3.58-1,2.18,2.18,0,0,0,.81-.1,2.49,2.49,0,0,0,.65-.48A4.73,4.73,0,0,1,95.37,70a3.46,3.46,0,0,1,.45,2.28A1.78,1.78,0,0,1,94.46,73.84Z"
        transform="translate(-6.08 -4.69)"
        fill="#ff681c"
      ></path>
      <path
        d="M93.19,83.1l-.27,0a.33.33,0,0,0-.2,0,.29.29,0,0,0-.09.22,16.74,16.74,0,0,0,0,2.92h0a3.91,3.91,0,0,1-2.7-4.14,16.59,16.59,0,0,0,3.29,1Z"
        transform="translate(-6.08 -4.69)"
        fill="#e0a091"
      ></path>
      <rect
        x="80.65"
        y="89.91"
        width="10.6"
        height="24.95"
        rx="5.3"
        transform="translate(-35.36 29.94) rotate(-19.53)"
        fill="#fff"
      ></rect>
      <path
        d="M234,94.26c-4.29,80.42-58.11,101.44-58.11,101.44-59.75-35.6-59.45-105.12-59.45-105.12,38.08-10.28,59.86-31.31,60.54-32h0C191.18,79.27,234,94.26,234,94.26Z"
        transform="translate(-6.08 -4.69)"
        fill="#95BD51"
      ></path>
      <path
        d="M234,93.94c-4.29,80.42-58.11,101.44-58.11,101.44-59.75-35.6-59.45-105.12-59.45-105.12C154.54,80,176.32,59,177,58.3h0C191.18,79,234,93.94,234,93.94Z"
        transform="translate(-6.08 -4.69)"
        fill="url(#linear-gradient)"
      ></path>
      <path
        d="M111,101c.5-.37,1-.78,1.42-1.18a14.67,14.67,0,0,1,3.64-2.41,7.06,7.06,0,0,1,4.27-.54c.31.06.68.25.64.57s-.22.34-.41.44A13.55,13.55,0,0,1,117.91,99c-.38.11-.82.25-1,.62a2,2,0,0,0,.91-.11,10,10,0,0,1,3.49-.25c.11,0,.24,0,.3.14s0,.3-.18.39c-1.12.89-2.8.83-3.87,1.79-.19.16-.33.5-.12.63a.42.42,0,0,0,.27,0c.46,0,.91-.19,1.37-.27a6.68,6.68,0,0,1,2.23-.06.37.37,0,0,1,.26.12c.11.17-.12.36-.3.45l-3.87,1.69a9.45,9.45,0,0,0,1.6,0,2.2,2.2,0,0,1,1.5.48.11.11,0,0,1,.06.09c0,.07,0,.11-.11.14a5.16,5.16,0,0,1-2,.6,6.21,6.21,0,0,0-1.23.22,5.69,5.69,0,0,0-.71.32,7.69,7.69,0,0,1-2.38.8,3.81,3.81,0,0,1-2.44-.4,3.73,3.73,0,0,0-.63-.32c-.8-.22-1.69.61-2.43.22a1.73,1.73,0,0,1-.58-.67c-.4-.66-1.74-2.5-.66-3.11a16.15,16.15,0,0,1,1.86-.6A8.53,8.53,0,0,0,111,101Z"
        transform="translate(-6.08 -4.69)"
        fill="#ffbaa8"
      ></path>
      <path
        d="M94.26,103.13l13.24-1.27,2.14,6.46S90.29,116.19,86.53,114s-.27-5.76-.27-5.76S86.7,103,94.26,103.13Z"
        transform="translate(-6.08 -4.69)"
        fill="#fff"
      ></path>
      <path
        d="M227.05,98.16c-3.79,70.88-51.22,89.41-51.22,89.41-52.66-31.38-52.4-92.65-52.4-92.65,33.57-9.06,52.76-27.6,53.36-28.17h0C189.29,85,227.05,98.16,227.05,98.16Z"
        transform="translate(-6.08 -4.69)"
        fill="#95BD51"
      ></path>
      <path
        d="M176.8,66.74l-1,120.83c-52.66-31.38-52.4-92.65-52.4-92.65,33.57-9.06,52.76-27.6,53.36-28.17Z"
        transform="translate(-6.08 -4.69)"
        fill="url(#linear-gradient-2)"
      ></path>
      <circle cx="169.75" cy="113.85" r="9.29" fill="#473f47"></circle>
      <polygon
        points="164.16 137.02 174.17 137.02 171.33 122.14 167.68 122.14 164.16 137.02"
        fill="#473f47"
      ></polygon>
      <polyline
        points="119.88 110.45 131.83 110.45 131.83 101.78 141.3 101.78"
        fill="none"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="0.96"
        opacity="0.18"
        style={{ isolation: 'isolate' }}
      ></polyline>
      <polyline
        points="122.22 119.74 137.78 119.74 137.78 138.66 147.52 138.66"
        fill="none"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="0.96"
        opacity="0.18"
        style={{ isolation: 'isolate' }}
      ></polyline>
      <polyline
        points="219.98 104.56 200.28 104.56 200.28 93.39 182.69 93.39"
        fill="none"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="0.96"
        opacity="0.18"
        style={{ isolation: 'isolate' }}
      ></polyline>
      <polyline
        points="216.31 123.14 197.12 123.14 197.12 142.81 183.59 142.81"
        fill="none"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="0.96"
        opacity="0.18"
        style={{ isolation: 'isolate' }}
      ></polyline>
      <polyline
        points="141.3 157.76 157.4 157.76 157.4 150.2 167.14 150.2"
        fill="none"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="0.96"
        opacity="0.18"
        style={{ isolation: 'isolate' }}
      ></polyline>
      <path
        d="M135.66,86.77a1.09,1.09,0,0,0,1.13-.23c.44-.51.09-1.28-.27-1.85.34-.26.83.07,1.09.42s.57.77,1,.72.67-.64.56-1.08a6.5,6.5,0,0,0-.65-1.22c.19-.26.61,0,.78.25s.4.63.71.56a.56.56,0,0,0,.31-.66,5.57,5.57,0,0,1-.17-.79c.53-.17,1.14.38,1.63.12a.76.76,0,0,0,.1-1.13,2,2,0,0,0-1.16-.55,5,5,0,0,0-3.2.38c-1.15.46-2.35.7-3.47,1.28a6.83,6.83,0,0,0-2.65,2,.45.45,0,0,0,.13.59.72.72,0,0,0,.46,0,3.41,3.41,0,0,1,2.32.39,10.93,10.93,0,0,0,1.18.72Z"
        transform="translate(-6.08 -4.69)"
        fill="#ffbaa8"
      ></path>
      <path
        d="M284.82,93C273.94,69.8,223.9,52.22,164,52.22c-67.84,0-123,22.54-123,50.25s55.19,50.25,123,50.25c46.24,0,86.6-10.47,107.62-25.91a51.1,51.1,0,0,0,8.28-7.48,27.9,27.9,0,0,0,6.67-12.5,21.31,21.31,0,0,0,.46-4.36A22.12,22.12,0,0,0,284.82,93Zm-5.89,26.24a50.35,50.35,0,0,1-7.1,6.44c-20.58,15.58-61.2,26.2-107.85,26.2-67.37,0-122.19-22.16-122.19-49.41S96.61,53.05,164,53.05c59.14,0,108.6,17.08,119.81,39.68a21.76,21.76,0,0,1,2.38,9.74,19,19,0,0,1-.32,3.53C285.05,110.62,282.67,115.06,278.93,119.24Z"
        transform="translate(-6.08 -4.69)"
        fill="#fff"
      ></path>
      <path
        d="M288.56,100.19a21.89,21.89,0,0,0-.36-2.41,24.48,24.48,0,0,0-1.28-4.15l-.18-.06c-.63-.2-1.28-.38-1.92-.57C273.94,69.8,223.9,52.22,164,52.22c-67.84,0-123,22.54-123,50.25s55.19,50.25,123,50.25c46.24,0,86.6-10.47,107.62-25.91a2.27,2.27,0,0,0,.57,1.66A51.3,51.3,0,0,0,282.36,119a29.49,29.49,0,0,0,5.32-9.83,22.84,22.84,0,0,0,1-6.69A21.77,21.77,0,0,0,288.56,100.19ZM164,151.88c-67.37,0-122.19-22.16-122.19-49.41S96.61,53.05,164,53.05c59.14,0,108.6,17.08,119.81,39.68a2.44,2.44,0,0,0-1.7.29,20.51,20.51,0,0,1,2.41,9.45,19.25,19.25,0,0,1-.14,2.28c-.59,4.82-3,9.54-7.2,14a45.28,45.28,0,0,1-5.08,4.65,2.41,2.41,0,0,1,0,1.31c-.09.32-.19.64-.28,1C251.25,141.26,210.63,151.88,164,151.88Z"
        transform="translate(-6.08 -4.69)"
        fill="#95BD51"
        opacity="0.18"
        style={{ isolation: 'isolate' }}
      ></path>
      <path
        d="M283.79,92.73a2.44,2.44,0,0,0-1.7.29c-4.6-8.91-15.71-17.19-32.38-23.93C226.8,59.83,196.35,54.73,164,54.73s-62.83,5.1-85.74,14.36C55.81,78.16,43.46,90,43.46,102.47s12.35,24.3,34.78,33.37c22.91,9.27,53.36,14.37,85.74,14.37s62.82-5.1,85.73-14.37c9.21-3.72,16.71-7.9,22.37-12.43a2.41,2.41,0,0,1,0,1.31c-.09.32-.19.64-.28,1-20.58,15.58-61.2,26.2-107.85,26.2-67.37,0-122.19-22.16-122.19-49.41S96.61,53.05,164,53.05C223.12,53.05,272.58,70.13,283.79,92.73Z"
        transform="translate(-6.08 -4.69)"
        fill="#95BD51"
        opacity="0.18"
        style={{ isolation: 'isolate' }}
      ></path>
      <path
        d="M286.92,93.63l-.18-.06c-.63-.2-1.28-.38-1.92-.57C273.94,69.8,223.9,52.22,164,52.22c-67.84,0-123,22.54-123,50.25s55.19,50.25,123,50.25c46.24,0,86.6-10.47,107.62-25.91a2.27,2.27,0,0,0,.57,1.66,99.46,99.46,0,0,1-20.61,11.07C228.11,149.12,197,154.39,164,154.39s-64.13-5.27-87.58-14.85c-23.94-9.78-37.12-22.95-37.12-37.07S52.46,75.18,76.4,65.41C99.85,55.82,131,50.55,164,50.55s64.13,5.27,87.58,14.86C270.42,73.11,282.6,82.91,286.92,93.63Z"
        transform="translate(-6.08 -4.69)"
        fill="#95BD51"
        opacity="0.18"
        style={{ isolation: 'isolate' }}
      ></path>
      <ellipse
        cx="152.49"
        cy="181.07"
        rx="96.23"
        ry="15.86"
        fill="#95BD51"
        opacity="0.35"
        style={{ isolation: 'isolate' }}
      ></ellipse>
      <path
        d="M225.89,83.2"
        transform="translate(-6.08 -4.69)"
        fill="url(#linear-gradient-3)"
      ></path>
      <polygon
        points="131.32 62.56 107.75 62.56 107.75 33.46 128.07 33.46 131.32 37.61 131.32 62.56"
        fill="#fff"
      ></polygon>
      <polygon
        points="129.23 64.28 105.66 64.28 105.66 35.18 125.98 35.18 129.23 39.33 129.23 64.28"
        fill="#fff"
      ></polygon>
      <polygon
        points="129.03 64.48 105.46 64.48 105.46 35.38 125.78 35.38 129.03 39.53 129.03 64.48"
        fill="url(#linear-gradient-4)"
      ></polygon>
      <polygon
        points="126.96 66.89 103.39 66.89 103.39 37.78 123.71 37.78 126.96 41.93 126.96 66.89"
        fill="#fff"
      ></polygon>
      <polygon
        points="123.71 37.78 123.71 41.89 126.96 41.89 123.71 37.78"
        fill="url(#linear-gradient-5)"
      ></polygon>
      <polygon
        points="125.78 35.38 125.78 39.61 129.03 39.61 125.78 35.38"
        fill="url(#linear-gradient-6)"
      ></polygon>
      <polygon
        points="128.07 33.46 128.07 37.69 131.32 37.69 128.07 33.46"
        fill="url(#linear-gradient-7)"
      ></polygon>
      <g id="Q83r0t">
        <path
          d="M286.93,128.52a2.35,2.35,0,0,1-1.48-1.15c-.24-.41-.46-.84-.7-1.25a2.17,2.17,0,0,0-2.27-1.17,2.22,2.22,0,0,0-2,1.64c-.15.49-.27,1-.44,1.47a2.42,2.42,0,0,1-3,1.59l-2.75-.81a2.44,2.44,0,0,1-1.7-3.06c.13-.5.3-1,.42-1.48a2.16,2.16,0,0,0-.81-2.37,2.11,2.11,0,0,0-2.45-.24c-.43.21-.84.46-1.26.68a2.49,2.49,0,0,1-3.41-1c-.48-.87-1-1.73-1.43-2.6a2.41,2.41,0,0,1,1-3.39c.4-.23.82-.43,1.21-.66a2.15,2.15,0,0,0,1.16-2.18,2.17,2.17,0,0,0-1.55-2c-.48-.16-1-.28-1.47-.44a2.44,2.44,0,0,1-1.67-3.2c.25-.87.5-1.74.76-2.61a2.4,2.4,0,0,1,2.87-1.73c.52.1,1,.27,1.53.41a2.29,2.29,0,0,0,2.68-3.38c-.22-.39-.44-.78-.64-1.17a2.38,2.38,0,0,1,1-3.25c.93-.53,1.87-1,2.83-1.52a2.49,2.49,0,0,1,3.26,1c.25.43.47.87.72,1.3a2.13,2.13,0,0,0,2.2,1.11,2.16,2.16,0,0,0,1.95-1.58c.15-.45.27-.92.41-1.37a2.48,2.48,0,0,1,3.23-1.72l2.56.75a2.47,2.47,0,0,1,1.77,3.12c-.12.44-.26.89-.39,1.33a2.19,2.19,0,0,0,.75,2.48,2.22,2.22,0,0,0,2.59.21c.39-.21.78-.44,1.17-.64a2.49,2.49,0,0,1,3.38,1c.5.89,1,1.79,1.48,2.69a2.41,2.41,0,0,1-1,3.32c-.43.24-.88.45-1.31.7a2.09,2.09,0,0,0-1.12,2,2.13,2.13,0,0,0,1.37,2c.5.2,1,.33,1.56.49a2.25,2.25,0,0,1,1.64,2.91c-.26,1-.55,2-.87,3a2.31,2.31,0,0,1-3.05,1.59c-.46-.13-.91-.28-1.38-.39a2.14,2.14,0,0,0-2.31.84,2.08,2.08,0,0,0-.21,2.41c.23.44.49.87.72,1.3a2.42,2.42,0,0,1-1,3.33c-.89.5-1.79,1-2.69,1.47A2.39,2.39,0,0,1,286.93,128.52Zm-13.51-19.76a7.92,7.92,0,1,0,9.84-5.35A7.9,7.9,0,0,0,273.42,108.76Z"
          transform="translate(-6.08 -4.69)"
          fill="#95BD51"
        ></path>
      </g>
      <g id="Q83r0t-2" data-name="Q83r0t" opacity="0.27">
        <path
          d="M286.93,128.52a2.35,2.35,0,0,1-1.48-1.15c-.24-.41-.46-.84-.7-1.25a2.17,2.17,0,0,0-2.27-1.17,2.22,2.22,0,0,0-2,1.64c-.15.49-.27,1-.44,1.47a2.42,2.42,0,0,1-3,1.59l-2.75-.81a2.44,2.44,0,0,1-1.7-3.06c.13-.5.3-1,.42-1.48a2.16,2.16,0,0,0-.81-2.37,2.11,2.11,0,0,0-2.45-.24c-.43.21-.84.46-1.26.68a2.49,2.49,0,0,1-3.41-1c-.48-.87-1-1.73-1.43-2.6a2.41,2.41,0,0,1,1-3.39c.4-.23.82-.43,1.21-.66a2.15,2.15,0,0,0,1.16-2.18,2.17,2.17,0,0,0-1.55-2c-.48-.16-1-.28-1.47-.44a2.44,2.44,0,0,1-1.67-3.2c.25-.87.5-1.74.76-2.61a2.4,2.4,0,0,1,2.87-1.73c.52.1,1,.27,1.53.41a2.29,2.29,0,0,0,2.68-3.38c-.22-.39-.44-.78-.64-1.17a2.38,2.38,0,0,1,1-3.25c.93-.53,1.87-1,2.83-1.52a2.49,2.49,0,0,1,3.26,1c.25.43.47.87.72,1.3a2.13,2.13,0,0,0,2.2,1.11,2.16,2.16,0,0,0,1.95-1.58c.15-.45.27-.92.41-1.37a2.48,2.48,0,0,1,3.23-1.72l2.56.75a2.47,2.47,0,0,1,1.77,3.12c-.12.44-.26.89-.39,1.33a2.19,2.19,0,0,0,.75,2.48,2.22,2.22,0,0,0,2.59.21c.39-.21.78-.44,1.17-.64a2.49,2.49,0,0,1,3.38,1c.5.89,1,1.79,1.48,2.69a2.41,2.41,0,0,1-1,3.32c-.43.24-.88.45-1.31.7a2.09,2.09,0,0,0-1.12,2,2.13,2.13,0,0,0,1.37,2c.5.2,1,.33,1.56.49a2.25,2.25,0,0,1,1.64,2.91c-.26,1-.55,2-.87,3a2.31,2.31,0,0,1-3.05,1.59c-.46-.13-.91-.28-1.38-.39a2.14,2.14,0,0,0-2.31.84,2.08,2.08,0,0,0-.21,2.41c.23.44.49.87.72,1.3a2.42,2.42,0,0,1-1,3.33c-.89.5-1.79,1-2.69,1.47A2.39,2.39,0,0,1,286.93,128.52Zm-13.51-19.76a7.92,7.92,0,1,0,9.84-5.35A7.9,7.9,0,0,0,273.42,108.76Z"
          transform="translate(-6.08 -4.69)"
          fill="#020202"
        ></path>
      </g>
      <g id="Q83r0t-3" data-name="Q83r0t">
        <path
          d="M297,112.29c-.51-.16-1.05-.28-1.55-.49a2.13,2.13,0,0,1-1.37-2,2.1,2.1,0,0,1,1.13-2c.42-.24.87-.46,1.3-.7a2.41,2.41,0,0,0,1-3.32c-.48-.9-1-1.8-1.48-2.68a2.49,2.49,0,0,0-3.38-1l-1.17.64a2.22,2.22,0,0,1-2.59-.2c-.11-.1-.21-.2-.31-.31a2.17,2.17,0,0,1-.44-2.18c0-.07.05-.16.08-.23.1-.37.21-.73.3-1.1a2.44,2.44,0,0,0-1.58-3.05l-.18-.06c-.63-.2-1.28-.38-1.92-.57l-.65-.19-.38-.08a2.44,2.44,0,0,0-1.7.29A2.55,2.55,0,0,0,281,94.53c-.15.46-.27.92-.41,1.38a2.18,2.18,0,0,1-2,1.57,2.14,2.14,0,0,1-2.2-1.1c-.26-.43-.47-.87-.72-1.31a2.47,2.47,0,0,0-3.25-1c-1,.47-1.91,1-2.84,1.51a2.38,2.38,0,0,0-1,3.25c.2.4.43.78.64,1.17a2.23,2.23,0,0,1-.15,2.64,2.25,2.25,0,0,1-2.53.75c-.51-.15-1-.32-1.53-.42a2.4,2.4,0,0,0-2.87,1.73c-.25.87-.51,1.74-.76,2.62a2.43,2.43,0,0,0,1.68,3.19c.48.17,1,.27,1.46.44a2.19,2.19,0,0,1,1.55,2,2.13,2.13,0,0,1-1.15,2.18c-.4.23-.82.44-1.22.66a2.41,2.41,0,0,0-1,3.39c.47.87.95,1.74,1.43,2.6a2.51,2.51,0,0,0,3.41,1c.42-.23.83-.47,1.26-.69a2.13,2.13,0,0,1,2.45.24,2.29,2.29,0,0,1,.79,1.07,2.41,2.41,0,0,1,0,1.31c-.09.32-.19.64-.28,1l-.15.51a2.63,2.63,0,0,0,.49,2.28,2.4,2.4,0,0,0,1.21.79l2.75.8a2.43,2.43,0,0,0,3-1.59c.17-.48.29-1,.44-1.47a2.24,2.24,0,0,1,2-1.64,2.18,2.18,0,0,1,2.26,1.17c.24.41.46.84.69,1.26a2.52,2.52,0,0,0,3.36,1c.9-.49,1.8-1,2.69-1.47a2.41,2.41,0,0,0,1-3.32c-.24-.44-.5-.86-.72-1.3a2.06,2.06,0,0,1,.2-2.41,2.16,2.16,0,0,1,2.31-.85c.47.11.92.27,1.38.4a2.3,2.3,0,0,0,3-1.59c.33-1,.62-2,.88-3A2.26,2.26,0,0,0,297,112.29Zm-9.3,1.34a8,8,0,0,1-7.82,5.7,6,6,0,0,1-.95-.09,8.18,8.18,0,0,1-1.07-.23,5.83,5.83,0,0,1-.7-.25,7.91,7.91,0,0,1,5.18-14.93,7.73,7.73,0,0,1,2,.92,7,7,0,0,1,1.49,1.25,6.84,6.84,0,0,1,.7.83,8.11,8.11,0,0,1,1.13,2.33A7.81,7.81,0,0,1,287.7,113.63Z"
          transform="translate(-6.08 -4.69)"
          fill="#95BD51"
        ></path>
      </g>
    </svg>
  );
};

export default ShowMoreIcon;

ShowMoreIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};
