import React from 'react';
import { NotFoundIcon } from 'images';
import { SvgWrapper } from 'utils';
import styled from '@emotion/styled';
const EmptyWrapper = styled.div`
  text-align: center;
  margin: 50px auto;
  p {
    font-size: 25px;
    font-weight: bold;
    margin: 50px auto;
  }
`;
export default function Empty() {
  return (
    <EmptyWrapper>
      <SvgWrapper width="100px" height="100px">
        <NotFoundIcon />
      </SvgWrapper>
      <p>Noch keine Projekte unterstützt…</p>
    </EmptyWrapper>
  );
}
