import React, { Component } from 'react';
import { Form, Input, List, Spin } from 'antd';
import { Header } from 'components';
import { Container, Colors, FormWrapper, PrimaryButton, Section, isMobile, Modal, H2 } from 'utils';
import { Table } from 'antd';
import styled from '@emotion/styled';
import { store } from 'react-notifications-component';
import { adminServices } from 'services';
import { connect } from 'react-redux';
import { AdminActions } from 'redux/actions';
import PropTypes from 'prop-types';
import { CitiesSelect, CategoriesSelect } from 'components';
const TableWrapper = styled.div`
  padding: 50px 0;
`;
const notification = {
  insert: 'top',
  container: 'top-right',
  animationIn: ['animate__animated animate__fadeIn'], // `animate.css v4` classes
  animationOut: ['animate__animated animate__fadeOut'], // `animate.css v4` classes
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
};

const Wrapper = styled.div`
  .container {
    max-width: 1000px;
    color: ${Colors.GreenColor};
    h1 {
      color: ${Colors.GreenColor};
      display: inline-block;
      padding: 10px;
      border-radius: 10px;
      font-size: 35px;
      margin-top: 30px;
      margin-bottom: 0px;
      font-weight: bold;
      width: 100%;
      text-align: center;
    }
  }
  label {
    font-weight: bold;
    font-size: 22px;
    display: block;
  }

  .submit-button {
    text-align: center;
    margin-top: 50px;
  }
  .ant-form-item {
    display: block;
    .ant-form-item-label {
      color: ${Colors.GreenColor};
      margin-bottom: 10px;
    }
    .ant-picker,
    .ant-input-affix-wrapper {
      width: 100%;
      padding: 10px;
      border: 1px solid ${Colors.GreenColor};
      border-radius: 10px;
    }
  }
  .ant-select-selector,
  input,
  textarea {
    border-radius: 15px !important;
    width: 100%;
    border-radius: 10px;
    line-height: 22px;
    vertical-align: middle;
  }
  .subtitle {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 30px;
  }
  .intro-text {
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 30px;
  }
`;

class AdminRecurringPayments extends Component {
  static propTypes = {
    goodies: PropTypes.any,
    getAdminRecurringPayments: PropTypes.func.isRequired
  };

  state = {
    dataArray: [],
    showModal: false,
    selectedDonationId: '',
  };
  componentDidMount() {
    const { getAdminRecurringPayments } = this.props;
    getAdminRecurringPayments();
  }

  handelCloseModal = () => {
    this.setState({ showModal: false });
    document.body.style.overflowY = 'unset';
  };
  handelShowModal = () => {
    this.setState({ showModal: true });
    document.body.style.overflowY = 'hidden';
  };

  handelCloseLoginModal = () => {
    this.setState({ showLoginModal: false });
    document.body.style.overflowY = 'unset';
  };


  handleSelectedDonation = (id) => {
    this.setState({ selectedDonationId: id });
  }
  handleRequestCancelRecurringPayment = () => {
    const { adminCancelRecurringPayment, getAdminRecurringPayments } = this.props;
    if (this.state.selectedDonationId) {
      adminCancelRecurringPayment(this.state.selectedDonationId);
      getAdminRecurringPayments();
    }
  }

  render() {
    const { showModal } = this.state;
    const { loading, recurringDonations } = this.props;
    const columns = [{
      title: "Vorname",
      dataIndex: "first_name",
      key: "firstName"
    }, {
      title: "Nachname",
      dataIndex: "last_name",
      key: "lastname"
    }, {
      title: "Email",
      dataIndex: "email",
      key: "email"
    },
    {
      title: 'Projekttitel',
      dataIndex: 'title',
      key: 'title',
    }, {
      title: 'Spendenart',
      dataIndex: 'donation_type',
      key: 'donationtype',
      width: '100px',
    }, {
      title: 'Spendenbetrag',
      dataIndex: 'amount',
      key: 'amount',
      width: '100px',
    }, {
      title: 'Aktion',
      dataIndex: 'id',
      key: 'action',
      width: '200px',
      render: (id) => (
        <>
          <PrimaryButton
            minWidth={isMobile() ? '20%' : '100px'}
            onClick={() => { this.handleSelectedDonation(id); this.handelShowModal(); }}
            primary={true}
          >
            stornieren
          </PrimaryButton>
        </>
      ),
    }];

    return (
      <>
        <Wrapper>
          <Header relativeHeader={true} />
          <Container className="container">
            <Section>
              <h1>Admin Ihre wiederkehrenden Projekte</h1>
              <TableWrapper>
                <Table
                  columns={columns}
                  dataSource={recurringDonations?.donations}
                  pagination={false}
                  loading={loading}
                />
              </TableWrapper>
            </Section>
          </Container>
        </Wrapper>
        <Modal showModal={showModal} handelCloseModal={this.handelCloseModal}>
          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px', alignItems: 'center' }}>
            <H2>
              bist du sicher ?
            </H2>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', alignItems: 'center' }}>
            <PrimaryButton
              minWidth={isMobile() ? '20%' : '100px'}
              onClick={() => { this.handelCloseModal(); this.handleRequestCancelRecurringPayment() }}
              primary={true}
            >
              Ja
            </PrimaryButton>
            <PrimaryButton
              minWidth={isMobile() ? '20%' : '100px'}
              onClick={() => { this.handelCloseModal(); }}
              primary={true}
            >
              Nein
            </PrimaryButton>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { recurringDonations, loading } = state.admin;
  return {
    recurringDonations, loading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAdminRecurringPayments: () =>
    dispatch(AdminActions.getAdminRecurringPayments()),
  adminCancelRecurringPayment: (id) =>
    dispatch(AdminActions.AdmincancelRecurringPayment(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminRecurringPayments);