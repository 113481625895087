import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { H2, PrimaryButton } from 'utils';
import { Form, Input, Spin } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { authActions } from 'redux/actions';

const FormItem = Form.Item;
class ForgetPassword extends Component {
  static propTypes = {
    handleCurrentFormState: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    errors: PropTypes.array,
    resetPassword: PropTypes.func.isRequired,
  };
  onFinish = (values) => {
    const { resetPassword } = this.props;
    resetPassword(values.email);
  };

  onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  render() {
    const { handleCurrentFormState, loading } = this.props;
    return (
      <Spin spinning={loading}>
        <H2>Reset Passwort</H2>
        <Form
          name="reset-password"
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
        >
          <FormItem
            name="email"
            label="E-Mail-Adresse"
            rules={[
              {
                required: true,
                message: 'Bitte gib deine E-Mail-Adresse ein.',
              },
            ]}
            hasFeedback
          >
            <Input type="email" />
          </FormItem>

          <FormItem>
            <PrimaryButton htmltype="submit">Reset Password</PrimaryButton>
          </FormItem>
        </Form>
        <div>
          <p>
            Bereits registriert?{' '}
            <Link to="#!" onClick={() => handleCurrentFormState('login')}>
              Jetzt anmelden!
            </Link>
          </p>
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, loading, errors } = state.authentication;
  return {
    user,
    loading,
    errors,
  };
};

const mapDispatchToProps = (dispatch) => ({
  resetPassword: (user) => dispatch(authActions.resetPassword(user)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword);
