import { paymentConstants } from 'redux/actions/types';
import find from 'loadsh/find';
const initialState = {
  card: {},
  selectedCard: {},
  cards: [],
  errors: [],
  loading: false,
  donationSuccess: false,
  stripeStatus: '',
  url: '',
  donationType: 0,
  amount: 0,
  initiatorEmail:''
};

function payment(state = initialState, action) {
  switch (action.type) {
    case paymentConstants.GET_MY_CARDS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case paymentConstants.GET_MY_CARDS_SUCCESS:
      return {
        ...state,
        loading: false,
        cards: action.cards,
        selectedCard: {},
      };
    case paymentConstants.GET_MY_CARDS_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.error,
      };
    case paymentConstants.PREPARE_CARD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case paymentConstants.PREPARE_CARD_SUCCESS:
      return {
        ...state,
        loading: false,
        card: action.card,
      };
    case paymentConstants.PREPARE_CARD_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.error,
      };
    case paymentConstants.ADD_CARD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case paymentConstants.ADD_CARD_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedCard: action.card,
        cards: [...state.cards, action.card],
      };
    case paymentConstants.ADD_CARD_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.error,
      };
    case paymentConstants.MAKE_DONATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case paymentConstants.MAKE_DONATION_SUCCESS:
      return {
        ...state,
        loading: false,
        donation: action.donation,
        donationSuccess: true,
      };
    case paymentConstants.MAKE_DONATION_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.error,
      };
    case paymentConstants.DELETE_CARD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case paymentConstants.DELETE_CARD_SUCCESS:
      return {
        ...state,
        loading: false,
        cards: state.cards.filter((card) => card.id !== action.cardId),
        selectedCard: {},
      };
    case paymentConstants.DELETE_CARD_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.error,
      };
    case paymentConstants.GET_STRIPE_URL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case paymentConstants.GET_STRIPE_URL_SUCCESS:
      return {
        ...state,
        loading: false,
        url: action.url,
      };
    case paymentConstants.GET_STRIPE_URL_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.error,
      };
    case paymentConstants.GET_STRIPE_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case paymentConstants.GET_STRIPE_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        stripeStatus: action.status,
      };
    case paymentConstants.GET_STRIPE_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.error,
      };
    case paymentConstants.SET_CARD:
      return {
        ...state,
        selectedCard: find(state.cards, function (c) {
          return c.Id === action.cardId;
        }),
      };
    case paymentConstants.REST_DONATION:
      return {
        ...state,
        donationSuccess: false,
      };
    case paymentConstants.SAVE_KEY:
      return {
        ...state,
        donationType: action.payload,
      };
      case paymentConstants.SAVE_AMOUNT:
      return {
        ...state,
        amount: action.payload,
      };

      case paymentConstants.SAVE_EMAIL:
        return {
          ...state,
          initiatorEmail: action.payload,
        };
    default:
      return state;
  }
}

export default payment;
