export const formLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};
export const formValidateMessages = {
  required: '${label} ist erforderlich. ',
  types: {
    email: '${label} ist keine gültige E-Mail!',
    number: '${label} is not a valid number!',
  },
};
