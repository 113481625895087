import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Colors, device } from 'utils';

const TabsHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 5px solid ${Colors.secondaryColor};
  @media ${device.allMobile} {
    overflow-x: scroll;
  }

  & > div {
    width: 17%;
    text-align: center;
    box-shadow: 0px 0px 8px 7px rgba(0, 0, 0, 0.14);
    border-radius: 10px 10px 0 0;
    background: ${Colors.white};
    border-bottom: none;
    cursor: pointer;
    @media ${device.allMobile} {
      min-width: 150px;
      margin-top: 20px;
      margin-left: 15px;
    }
    &.selected {
      background: ${Colors.secondaryColor};
      border: 1px solid ${Colors.secondaryColor};
      box-shadow: none;
      border-bottom: none;
      p {
        color: ${Colors.white};
      }
    }
    p {
      color: ${Colors.primaryColor};
      letter-spacing: 2px;
      text-transform: uppercase;
      margin: 10px auto;
    }
  }
`;

function TabsHeader(props) {
  const { handleTabToggle, selected } = props;
  return (
    <TabsHeaderWrapper>
      <div
        className={selected === 'project' ? 'selected' : ''}
        onClick={() => {
          handleTabToggle('project');
        }}
      >
        <p>project</p>
      </div>
      <div
        className={selected === 'timeLine' ? 'selected' : ''}
        onClick={() => {
          handleTabToggle('timeLine');
        }}
      >
        <p>Time Line</p>
      </div>
      <div
        className={selected === 'Supprters' ? 'selected' : ''}
        onClick={() => {
          handleTabToggle('Supprters');
        }}
      >
        <p>Supprters</p>
      </div>
      <div
        className={selected === 'Sponsored' ? 'selected' : ''}
        onClick={() => {
          handleTabToggle('Sponsored');
        }}
      >
        <p>Sponsored</p>
      </div>
      <div
        className={selected === 'Tab5' ? 'selected' : ''}
        onClick={() => {
          handleTabToggle('Tab5');
        }}
      >
        <p>Tab 5</p>
      </div>
    </TabsHeaderWrapper>
  );
}

TabsHeader.propTypes = {
  handleTabToggle: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired,
};

export default TabsHeader;
