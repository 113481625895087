import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Colors } from 'utils';

const FormWrapper = styled.div`
  background: ${Colors.transparent};
  margin-bottom: 20px;
  padding: 20px 0;
  a {
    color: ${Colors.secondaryColor};
    &:hover {
      color: ${Colors.primaryColor};
    }
  }
`;
export default function FormContainer(props) {
  return <FormWrapper {...props}>{props.children}</FormWrapper>;
}

FormContainer.propTypes = {
  children: PropTypes.object.isRequired,
};
