import React, { Component } from 'react';
import '../MuhammadSaw/saw.css';
import { Form, Input } from 'antd';
import PropTypes from 'prop-types';
const FormItem = Form.Item;

const checkURL = (url, id) => {
  return fetch(`projects/check_url`, {
    method: 'POST',
    redirect: 'follow',
    body: JSON.stringify({ project: { url, project_id: id } }),
  }).then((result) => {
    if (!result.url) {
      return Promise.resolve();
    } else {
      return Promise.reject('Dieser Link ist leider schon vergeben.');
    }
  });
};
export default class LinkInput extends Component {
  static propTypes = {
    projectId: PropTypes.string.isRequired,
  };
  state = {
    link: '',
  };
  handleChangeLink = (e) => {
    this.setState({ link: e.target.defaultValue });
  };

  render() {
    const { link } = this.state;
    const { projectId } = this.props;
    return (
      <div>
        <FormItem
          name="url"
          label="commonsplace-link"
          hasFeedback
          extra={
            <>
              <p className="project-link remark">
                Wähle bedacht! Nutze keine Sonderzeichen.
              </p>
            </>
          }
          rules={[
            {
              required: true,
              message: 'Projektlink fehlt!',
            },
            {
              min: 4,
              message: 'Bitte gebe mindestens 4 Zeichen ein.',
            },
            () => ({
              validator: (_, value) => {
                if (/^[a-zA-Z0-9]+$/.test(value)) {
                  return checkURL(value, projectId);
                }
                return Promise.reject('Sonderzeichen sind nicht erlaubt.');
              },
            }),
          ]}
        >
          <Input
          maxLength={16}
            className="ant-input-group .ant-input"
            addonBefore={
              process.env.NODE_ENV === 'production'
                ? 'commonsplace.de/project/'
                : 'localhost:3001/project/'
            }
            onKeyUp={(e) => this.handleChangeLink(e)}
          />
        </FormItem>
      </div>
    );
  }
}
