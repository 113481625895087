import React, { Component } from 'react';
import { Form, Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import uniqBy from 'lodash/uniqBy';
import PropTypes from 'prop-types';
const FormItem = Form.Item;
const Option = Select.Option;

export default class CountriesSelect extends Component {
  constructor(props) {
    super(props);
    this.lastFetchId = 0;
    this.fetchCountries = debounce(this.fetchCountries, 800);
  }
  state = {
    link: '',
    data: [],
    fetching: false,
    value: [],
  };

  static propTypes = {
    required: PropTypes.bool,
  };
  fetchCountries = (value) => {
    this.lastFetchId += 1;
    const fetchId = this.lastFetchId;
    this.setState({ data: [], fetching: true });
    fetch(
      `https://wft-geo-db.p.rapidapi.com/v1/geo/countries?languageCode=de&limit=10&namePrefix=${value}`,
      {
        method: 'GET',
        headers: {
          'x-rapidapi-key':
            '4febbc1e2fmsh385c68cc8d777bdp191a31jsn35df7f7e0d4f',
          'x-rapidapi-host': 'wft-geo-db.p.rapidapi.com',
        },
      }
    ).then((countries) => {
      if (fetchId !== this.lastFetchId) {
        // for fetch callback order
        return;
      }
      const data = countries.data.map((country) => ({
        text: `${country.name}`,
        value: country.name,
      }));

      this.setState({ data: uniqBy(data, 'value'), fetching: false });
    });
  };

  handleChange = (value) => {
    this.setState({
      value,
      data: [],
      fetching: false,
    });
  };

  render() {
    const { fetching, data, value } = this.state;
    const { required } = this.props;
    return (
      <FormItem
        name="country"
        label="Land"
        rules={[
          {
            required: required ? true : false,
            message: 'Bitte gib dein Land ein.',
          },
        ]}
      >
        <Select
          showSearch
          value={value}
          placeholder="Wähle ein Land"
          notFoundContent={
            fetching ? <Spin size="small" /> : <p>Es gibt kein Ergebnis</p>
          }
          filterOption={false}
          onSearch={this.fetchCountries}
          onKeyUp={this.handleChange}
          autoComplete="off"
          style={{ width: '100%' }}
        >
          {data.map((d) => (
            <Option key={d.value} value={d.value}>
              {d.text}
            </Option>
          ))}
        </Select>
      </FormItem>
    );
  }
}
