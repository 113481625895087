/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { Colors } from 'utils';

const TargetIcon = (props) => {
  const { color, width, height } = props;
  return (
    <svg
      {...props}
      height={`${height || '512'}px`}
      width={`${width || '512'}px`}
      viewBox="0 0 512 512"
      css={css`
        fill: ${color || Colors.GreenColor};
        max-width: ${width || '100%'};
        max-height: ${height || '100%'};
        height: ${height || '100%'};
        width: ${width || '100%'};
        display: inline-block;
        enable-background: new 0 0 512 512;
      `}
    >
      <g>
        <g xmlns="http://www.w3.org/2000/svg" id="XMLID_1017_">
          <g id="XMLID_1278_">
            <path
              id="XMLID_1279_"
              d="m256 74.946c7.262 0 13.169 4.947 13.169 11.027 0 5.522 4.478 10 10 10s10-4.478 10-10c0-13.849-9.752-25.606-23.169-29.583v-1.39c0-5.522-4.478-10-10-10s-10 4.478-10 10v1.39c-13.417 3.978-23.169 15.734-23.169 29.583 0 17.108 14.88 31.026 33.169 31.026 7.262 0 13.169 4.946 13.169 11.026s-5.907 11.027-13.169 11.027-13.169-4.947-13.169-11.027c0-5.522-4.478-10-10-10s-10 4.478-10 10c0 13.849 9.752 25.606 23.169 29.583v1.392c0 5.522 4.478 10 10 10s10-4.478 10-10v-1.39c13.417-3.978 23.169-15.734 23.169-29.583 0-17.109-14.88-31.027-33.169-31.027-7.262 0-13.169-4.946-13.169-11.026s5.907-11.028 13.169-11.028z"
              fill={color || Colors.GreenColor}
              data-original="#000000"
            />
            <path
              id="XMLID_1303_"
              d="m448 274.23h-45.703c-59.203 0-107.368 48.165-107.368 107.368v41.561l-28.929 28.929v-238.559c54.328-5.06 97-50.901 97-106.529 0-11.519-1.822-22.856-5.417-33.698-1.738-5.244-7.402-8.081-12.639-6.346-5.243 1.738-8.083 7.396-6.346 12.639 2.922 8.808 4.402 18.029 4.402 27.405 0 47.972-39.028 87-87 87s-87-39.028-87-87 39.028-87 87-87c8.83 0 17.535 1.315 25.874 3.91 5.271 1.64 10.878-1.305 12.52-6.578 1.641-5.273-1.305-10.879-6.578-12.52-10.265-3.193-20.969-4.812-31.816-4.812-59 0-107 48-107 107 0 55.628 42.672 101.469 97 106.529v152.331l-28.929-28.928v-41.56c0-59.203-48.165-107.368-107.368-107.368h-45.703c-5.522 0-10 4.478-10 10v45.703c0 59.203 48.165 107.368 107.368 107.368h41.561l43.071 43.07v97.855h-100.161c-5.522 0-10 4.478-10 10s4.478 10 10 10h220.322c5.522 0 10-4.478 10-10s-4.478-10-10-10h-100.161v-11.627l43.072-43.071h41.56c59.203 0 107.368-48.165 107.368-107.368v-45.704c0-5.522-4.478-10-10-10zm-286.632 56.844c-48.175 0-87.368-39.193-87.368-87.368v-35.703h35.703c48.175 0 87.368 39.193 87.368 87.368v21.56l-44.161-44.161c-3.906-3.904-10.236-3.904-14.143 0-3.905 3.905-3.905 10.237 0 14.143l44.162 44.161zm276.632-1.14c0 48.175-39.193 87.368-87.368 87.368h-21.56l44.161-44.16c3.905-3.905 3.905-10.237 0-14.143-3.905-3.903-10.235-3.904-14.143 0l-44.161 44.161v-21.561c0-48.175 39.193-87.368 87.368-87.368h35.703z"
              fill={color || Colors.GreenColor}
              data-original="#000000"
            />
            <path
              id="XMLID_1306_"
              d="m324.38 48.2c2.63 0 5.21-1.07 7.07-2.931 1.859-1.859 2.93-4.439 2.93-7.069 0-2.631-1.07-5.21-2.93-7.07s-4.44-2.93-7.07-2.93-5.21 1.069-7.07 2.93c-1.859 1.86-2.93 4.439-2.93 7.07 0 2.63 1.07 5.21 2.93 7.069 1.86 1.861 4.44 2.931 7.07 2.931z"
              fill={color || Colors.GreenColor}
              data-original="#000000"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default TargetIcon;

TargetIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};
