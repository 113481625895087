import React from 'react';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';

// import { MasterCard, MasterCard2X, visa, visa2X } from 'images';
import { Colors } from 'utils';

const CardWrapper = styled.div`
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  border: solid 1px #e4e9f2;
  border-radius: 18px;
  padding: 15px 20px;
  width: 300px;
  margin-top: 20px;
  button {
    margin-left: auto;
    border: none;
    background: none;
    font-size: 15px;
    font-weight: 600;
    color: #cc0f0f;
    display: block;
    line-height: 1;
  }
  .card-actions {
    display: flex;
    justify-content: space-between;
    button:first-of-type {
      margin: 0;
      color: ${Colors.secondaryColor};
    }
  }
`;
const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;
// const CardName = styled.div`
//   margin-bottom: 20px;
//   p {
//     text-transform: uppercase;
//     margin-bottom: 0;
//     &:first-of-type {
//       font-size: 11px;
//       font-weight: 500;
//       color: #9298ac;
//       margin-bottom: 0;
//     }
//     &:nth-of-type(2) {
//       font-size: 16px;
//       font-weight: 500;
//       color: #060c2b;
//     }
//   }
// `;
// const CardType = styled.div``;

const CardNumber = styled.div`
  margin-bottom: 15px;
  span {
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 16px;
    color: #060c2b;
    & + span {
      margin-left: 15px;
    }
  }
`;
const CardDatails = styled.div`
  display: flex;

  p {
    text-transform: uppercase;
    margin-bottom: 0;
    &:first-of-type {
      font-family: AvenirNext, Arial, sans-serif;
      font-size: 11px;
      font-weight: 500;
      color: #9298ac;
      margin-bottom: 0;
    }
    &:nth-of-type(2) {
      font-family: AvenirNext, Arial, sans-serif;
      font-size: 16px;
      font-weight: 500;
      color: #060c2b;
    }
  }
  div + div {
    margin-left: 97px;
  }
`;

export default function Card(props) {
  return (
    <CardWrapper>
      <CardHeader>
        {/* <CardName>
          <p>HOLDER NAME</p>
          <p>{props.name}</p>
        </CardName> */}
        {/* <CardType>
          {props.type === 'visa' ? (
            <picture>
              <source srcSet={`${visa2X} 2x`} />
              <img src={visa} alt="Visa Card " />
            </picture>
          ) : (
            <picture>
              <source srcSet={`${MasterCard2X} 2x`} />
              <img src={MasterCard} alt="Master Card " />
            </picture>
          )}
        </CardType> */}
      </CardHeader>
      <CardNumber>
        <span>&#42; &#42; &#42; &#42;</span>
        <span>&#42; &#42; &#42; &#42;</span>
        <span>&#42; &#42; &#42; &#42;</span>
        <span>{props.lastNumber}</span>
      </CardNumber>
      <CardDatails>
        <div>
          <p>EXPRIRES DATE</p>
          <p>&#42; &#42;/&#42; &#42;</p>
        </div>
        <div>
          <p>CVC</p>
          <p>&#42; &#42; &#42;</p>
        </div>
      </CardDatails>
      {!props.noActions && (
        <>
          {props.setCard ? (
            <>
              <div className="card-actions">
                <button
                  onClick={() => {
                    props.setCard(props.id);
                  }}
                >
                  Pay with this card
                </button>
                <button
                  onClick={() => {
                    props.deleteCard(props.id);
                  }}
                >
                  REMOVE
                </button>
              </div>{' '}
            </>
          ) : (
            <button
              onClick={() => {
                props.deleteCard(props.id);
              }}
            >
              REMOVE
            </button>
          )}
        </>
      )}
    </CardWrapper>
  );
}

Card.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  lastNumber: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  deleteCard: PropTypes.func.isRequired,
  setCard: PropTypes.func,
  noActions: PropTypes.bool,
};
