import React from 'react';
import { BaseLayout } from 'components';
import { Link } from 'react-router-dom';
import { ShortBackground } from 'images';
import { Container, H1, SvgWrapper, isMobile, device } from 'utils';
import styled from '@emotion/styled';
import {
  SecurityIcon,
  SecurityDateIcon,
  ConnectedIcon,
  Sank,
  Datenschutz,
  Manuelle,
  Dokumentation,
} from 'images';
import { Helmet } from 'react-helmet';

const ImagesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 50px;
  margin: 50px auto;
  @media ${device.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }
  @media ${device.allMobile} {
    grid-template-columns: repeat(1, 1fr);
  }
  img {
    height: 250px;
    width: auto;
  }
  h2 {
    font-size: 18px;
    margin-bottom: 24px;
  }
  p {
    font-size: 16px !important;
  }
`;

const FeesPageWrapper = styled.div`
  .intro {
    text-align: center;
    margin-bottom: 100px;
    margin-top: 0;
    @media ${device.allMobile} {
      padding: 0 15px;
      margin-bottom: 30px;
      margin-top: 30px;
    }
    .icon {
      position: relative;
      left: -55px;
      margin-bottom: 50px;
    }
    h1 {
      @media ${device.allMobile} {
        font-size: 24px;
      }
    }
    p {
      font-size: 22px;
      margin: auto;
      @media ${device.allMobile} {
        font-size: 16px;
      }
      span {
        font-weight: bold;
      }
    }
    .margin-less {
      @media ${device.allMobile} {
        margin-top: 0;
      }
    }
    &__content {
      display: flex;
      align-items: center;
      margin: 50px auto;
      @media ${device.allMobile} {
        display: flex;
        margin-bottom: 50px;
        margin-top: 50px;
        flex-wrap: wrap;
      }

      &--icon {
        width: 40%;

        @media ${device.allMobile} {
          width: 100%;
          order: 1;
        }
      }
      &--content {
        width: 60%;
        text-align: left;
        @media ${device.allMobile} {
          width: 100%;
          text-align: center;
          order: 2;
        }
        &.payment {
          h2 {
            font-size: 22px;
          }
          p {
            text-align: left;
          }
          ul {
            padding: 0;
            list-style: none;
            li {
              font-size: 22px;
            }
          }
        }
      }
    }
  }
`;
export default function TransparencyAndTrustPage() {
  return (
    <div>
      <Helmet>
        <meta
          name="description"
          content="Vertrauen und Sicherheit bei commonsplace. Maßnahmen und Best Practices zum Schutz der Daten und zur Gewährleistung sicherer Transaktionen."
        />
      </Helmet>
      <BaseLayout
        bannerComponent={
          <div style={{ textAlign: 'center' }}>
            <H1>Vertrauen und Sicherheit</H1>
          </div>
        }
        container={false}
        maxHeightProps="500px"
        maxHeightMobileProps="250px"
        img={ShortBackground}
      >
        <Container>
          <FeesPageWrapper>
            <div className="intro">
              <h2>Das Vertrauen der Community</h2>
              <div style={{ margin: '50px 0' }}>
                <p>
                  commonsplace ist eine Community-Plattform und ein Ort für
                  vielfältige und kreative Ideen, engagierte Projektersteller
                  und großzügige Unterstützer. Transparenz, Sicherheit und
                  Vertrauen machen das Miteinander und die Harmonie auf
                  commonsplace aus. Wir setzen alles darauf, euch eine sichere
                  und vertrauensvolle Plattform zu bieten!
                </p>
              </div>
              <h2 style={{ marginTop: '50px' }}>Unsere Sicherheitsebenen</h2>
              <div className="intro__content">
                <div className="intro__content--content">
                  <p>
                    Unsere Plattform nutzt beste Technologien, um jede Zahlung
                    verschlüsselt und sicher zu gewährleisten. Die Identität
                    eines jeden Projektinitiators wird von unserem
                    Zahlungsdienstpartner Stripe verifiziert. Außerdem weist
                    Stripe jedem Projektinitiator ein eigenes Client Money-Konto
                    zu. Auf dem Money-Konto wird das gesammelte Geld bis zur
                    Ausschüttung sicher, treuhänderisch und vertraglich
                    verwaltet. commonsplace verfügt zu keiner Zeit über das
                    Geld. Sollte eine Insolvenz seitens commonsplace auftreten,
                    überweist Stripe das Geld an die Unterstützer bzw. an den
                    Projektinitiator zurück. Projektinitiator, bei denen
                    Anzeichen von Missbrauch erkannt werden, werden aus dem
                    System unmittelbar entfernt. Wir behalten uns das Recht vor,
                    Projekte abzulehnen, die gegen
                    <Link to="/guidelines"> unsere Richtlinien</Link> verstoßen.
                  </p>
                </div>
                <div className="intro__content--icon">
                  <SvgWrapper width="90%" height="auto">
                    <SecurityIcon />
                  </SvgWrapper>
                </div>
              </div>
              <h2 style={{ marginTop: '50px' }}>
                Maßnahmen für Transparenz und Vertrauen
              </h2>
              <ImagesGrid>
                <div>
                  <img src={Sank} alt="sank" width="100%" height="auto" />
                  <h2>Sanktionsüberprüfungen</h2>
                  <p>
                    Jeder Projektinitiator auf commonsplace wird von unserem
                    Zahlungsdienstleister gegen verschiedene Sanktionslisten
                    geprüft, die globale Sanktionen, regulatorische und
                    Anti-Geldwäsche-Durchsetzungsliste umfassen. Wir stellen
                    auch sicher, dass Begünstigte überprüft werden, wenn
                    Projektinitiator Gelder im Namen einer anderen Person
                    sammeln.
                  </p>
                </div>

                <div>
                  <img
                    src={Dokumentation}
                    alt="sank"
                    width="100%"
                    height="auto"
                  />
                  <h2>Dokumentationsüberprüfung</h2>
                  <p>
                    Wir überprüfen unterstützende Dokumente, um den Projektzweck
                    zu verifizieren. Sollten wir während unserer Überprüfungen
                    auf verdächtiges Verhalten aufmerksam werden, behalten wir
                    die Gelder bei Bedarf ein und stellen die Legitimität des
                    Projekts vor der Freigabe der Mittel sicher.
                  </p>
                </div>

                <div>
                  <img src={Manuelle} alt="sank" width="100%" height="auto" />
                  <h2>Manuelle Vorprüfung vor dem Start</h2>
                  <p>
                    Jedes Projekt wird von unserem Team manuell überprüft, um
                    sicherzustellen, dass sie unseren{' '}
                    <Link
                      style={{ textDecoration: 'underline' }}
                      to="/guidelines"
                    >
                      Website-Richtlinien
                    </Link>{' '}
                    entspricht. Wir erlauben keine Projekte, die Hass oder
                    Gewalt gegen andere Parteien schüren.
                  </p>
                </div>

                <div>
                  <img
                    src={Datenschutz}
                    alt="sank"
                    width="100%"
                    height="auto"
                  />
                  <h2>Datenschutz</h2>
                  <p>
                    Wir sind verpflichtet, die Privatsphäre aller Daten durch
                    unsere internen Kontrollen zu schützen. Wo erforderlich,
                    stellen wir sicher, dass alle Drittanbieter über
                    Schutzmaßnahmen verfügen, um die Daten in Übereinstimmung
                    mit den geltenden Datenschutzgesetzen zu schützen.
                  </p>
                </div>
              </ImagesGrid>
              {/* <div className="intro" style={{ margin: 0 }}>
                <h2>Sanktionsüberprüfungen</h2>
                <div className="intro__content" style={{ margin: 0 }}>
                  <div className="intro__content--icon">
                    <img src={Sank} alt="sank" width="50%" height="auto" />
                  </div>
                  <div className="intro__content--content">
                    <p>
                      Jeder Projektinitiator auf commonsplace wird von unserem
                      Zahlungsdienstleister gegen verschiedene Sanktionslisten
                      geprüft, die globale Sanktionen, regulatorische und
                      Anti-Geldwäsche-Durchsetzungsliste umfassen. Wir stellen
                      auch sicher, dass Begünstigte überprüft werden, wenn
                      Projektinitiator Gelder im Namen einer anderen Person
                      sammeln.
                    </p>
                  </div>
                </div>
              </div>

              <div className="intro" style={{ margin: 0 }}>
                <h2>Dokumentationsüberprüfung </h2>
                <div className="intro__content" style={{ margin: 0 }}>
                  <div className="intro__content--icon">
                    <img
                      src={Dokumentation}
                      alt="sank"
                      width="50%"
                      height="auto"
                    />
                  </div>
                  <div className="intro__content--content">
                    <p>
                      Wir überprüfen unterstützende Dokumente, um den
                      Projektzweck zu verifizieren, was vor der Auszahlung
                      abgeschlossen wird. Sollten wir während unserer
                      Überprüfungen auf verdächtiges Verhalten aufmerksam
                      werden, behalten wir die Gelder bei Bedarf ein und stellen
                      die Legitimität des Projekts vor der Freigabe der Mittel
                      sicher.
                    </p>
                  </div>
                </div>
              </div>

              <div className="intro" style={{ margin: 0 }}>
                <h2>Manuelle Vorprüfung vor dem Start</h2>
                <div className="intro__content" style={{ marginBottom: 50 }}>
                  <div className="intro__content--icon">
                    <img src={Manuelle} alt="sank" width="50%" height="auto" />
                  </div>
                  <div className="intro__content--content">
                    <p>
                      Jedes Projekt wird von unserem Team manuell überprüft, um
                      sicherzustellen, dass sie unseren{' '}
                      <Link
                        style={{ textDecoration: 'underline' }}
                        to="/guidelines"
                      >
                        Website-Richtlinien
                      </Link>{' '}
                      entspricht. Wir erlauben keine Projekte, die Hass oder
                      Gewalt gegen andere Parteien schüren.
                    </p>
                  </div>
                </div>
              </div>

              <div className="intro" style={{ margin: 0, marginTop: 50 }}>
                <h2>Datenschutz</h2>
                <div className="intro__content" style={{ margin: 0 }}>
                  <div className="intro__content--icon">
                    <img
                      src={Datenschutz}
                      alt="sank"
                      width="50%"
                      height="auto"
                    />
                  </div>
                  <div className="intro__content--content">
                    <p>
                      Wir sind verpflichtet, die Privatsphäre aller Daten durch
                      unsere internen Kontrollen zu schützen. Wo erforderlich,
                      stellen wir sicher, dass alle Drittanbieter über
                      Schutzmaßnahmen verfügen, um die Daten in Übereinstimmung
                      mit den geltenden Datenschutzgesetzen zu schützen.
                    </p>
                  </div>
                </div>
              </div> */}

              <h2 style={{ marginTop: '50px' }}>
                Wir vertrauen der Community!
              </h2>
              <SvgWrapper width={isMobile() ? '100%' : '350px'} height="auto">
                <ConnectedIcon />
              </SvgWrapper>
              <p>
                Wir sind auf unsere Community angewiesen! Um Lücken zu
                schließen, die wir möglicherweise übersehen haben oder externen
                und unzugänglichen Informationen nachzugehen, benötigen wir eure
                Hilfe. Bitte wendet euch bei Auffälligkeiten an unser Team unter{' '}
                {''}
                <a
                  style={{ textDecoration: 'underline' }}
                  href="mailto:support@commonsplace.de"
                >
                  support@commonsplace.de
                </a>
              </p>
              <h2 style={{ marginTop: 70 }}>
                Transparenz und Vertrauen beim Projektinitiator
              </h2>
              <div
                className="intro__content margin-less"
                style={{ marginBottom: 0, marginTop: 0 }}
              >
                <div className="intro__content--icon">
                  <SvgWrapper width="90%" height="auto">
                    <SecurityDateIcon />
                  </SvgWrapper>
                </div>
                <div className="intro__content--content">
                  <p>
                    Der Projektinitiator gibt bei der Erstellung seines Projekts
                    eine zeitliche Abschätzung an, wann das Projekt realisiert
                    wird. Der Unterstützer wird durch regelmäßige Updates des
                    Projektinitiatoren nachverfolgen können, wie sich das
                    Projekt entwickelt. Verzögerungen und unerwartete Szenarien
                    sind beim Projektmanagement ganz normal. Wir vertrauen
                    darauf, dass der Projektersteller eigenständig, ehrlich und
                    transparent die Entwicklungen seines Projekts an seine
                    Unterstützer kommuniziert. Die Verantwortung die
                    Unterstützer mit Hilfe von Updates auf der Projektseite über
                    die Neuigkeiten und Updates zu informieren, liegt beim
                    Projektersteller. Sollte das Fundingziel nicht erreicht
                    werden, erhalten Projektinitiatoren von sozialen Initiativen
                    die Unterstützungen trotzdem und müssen angeben, was mit den
                    Unterstützungen passiert. Projekte unternehmerischer Natur
                    erhalten die Unterstützungen ausschließlich bei Erreichen
                    des Fundingziels, ansonsten werden die Gelder jeweils an die
                    Unterstützer zurückgezahlt.
                  </p>
                </div>
              </div>
            </div>
          </FeesPageWrapper>
        </Container>
      </BaseLayout>
    </div>
  );
}
