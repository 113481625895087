function numberWithCommas(x) {
  if(x !==null){
    const parts = x?.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts?.join(',');
  }else{
    return 0
  }

}
export default numberWithCommas;
