import styled from '@emotion/styled';
import React, { Component } from 'react';
import { SupportComponent } from './';
import PropTypes from 'prop-types';
import { Colors, device } from 'utils';
import moment from 'moment';
import { Spin } from 'antd';
const SupportWrapper = styled.div`
  &{
    width:100% !important
  }
  h1 {
    font-size: 25px;
    font-weight: bold;
    margin-top: 50px;
    text-align: center;
    @media ${device.laptop} {
      font-size: 22px;
    }
  }
  & > div {
    max-height: 315px;
    overflow-y: scroll;
    // border: 2px solid ${Colors.grey};
    border-radius: 8px;
    padding: 20px 10px !important;
    @media ${device.allMobile} {
      padding: 5px 5px !important;
    }
  }
  .show-more {
    text-align: center;
    button {
      display: inline-block;
      text-decoration: none;
      background: none;
      border: none;
      border-bottom: 2px solid ${Colors.transparent};
      margin: 0px auto 0;
      color: ${Colors.secondaryColor};
      &:hover {
        border-bottom: 2px solid ${Colors.secondaryColor};
      }
    }
  }
`;

export default class RecentSupport extends Component {
  componentDidMount() {
    this.handleShowSupports();
  }
  state = {
    showMore: true,
    perPage: 0,
  };

  handleShowSupports = () => {
    const { projectId, getProjectDonations } = this.props;
    const { perPage } = this.state;
    getProjectDonations(projectId, perPage + 3, 1);
    this.setState({ perPage: perPage + 3 });
  };

  render() {
    const { perPage } = this.state;
    const { donations, loadingDonations } = this.props;

    return (
      <SupportWrapper>
        {/* <h1>Bisherige Unterstützungen</h1> */}
        {donations && donations.donations && (
          <>
            {donations.donations.length < 1 ? (
              <div className="No_Support_content">
                <p style={{ marginBottom: "10px", lineHeight: "1" }}>
                  „…Und was ihr an Gutem spendet, soll euch erstattet werden…“
                  [2:272]
                </p>
                <p>Noch hat keiner das Projekt unterstützt.</p>
                <p>Sei der/die erste Unterstützer/in!</p>
              </div>
            ) : (
              <>
                <Spin spinning={loadingDonations}>
                  {donations.donations.map((donation, index) => (
                    <SupportComponent
                      key={`${JSON.stringify(donation)}-${index}`}
                      amount={donation.amount}
                      time={moment.unix(Number(donation.time)).fromNow()}
                      name={
                        donation.last_name || donation.first_name
                          ? `${donation.first_name || ''} ${donation.last_name || ''
                          } `
                          : false
                      }
                      message={donation.message}
                    />
                  ))}
                  {donations.count > perPage && (
                    <div className="show-more">
                      <button onClick={() => this.handleShowSupports()}>
                        Mehr anzeigen
                      </button>
                    </div>
                  )}
                </Spin>
              </>
            )}
          </>
        )}


      </SupportWrapper>
    );
  }
}

RecentSupport.propTypes = {
  loadingDonations: PropTypes.bool.isRequired,
  getProjectDonations: PropTypes.func.isRequired,
  donations: PropTypes.object.isRequired,
  projectId: PropTypes.number.isRequired,
};
