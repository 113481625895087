import React, { Component } from 'react';
import { Form, Input, Spin } from 'antd';
import { Header } from 'components';
import { Container, Colors, FormWrapper, PrimaryButton, Section } from 'utils';
import styled from '@emotion/styled';
import { store } from 'react-notifications-component';
import { adminServices } from 'services';
import { CitiesSelect, CategoriesSelect } from 'components';

const notification = {
  insert: 'top',
  container: 'top-right',
  animationIn: ['animate__animated animate__fadeIn'], // `animate.css v4` classes
  animationOut: ['animate__animated animate__fadeOut'], // `animate.css v4` classes
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
};

const FormItem = Form.Item;

const Wrapper = styled.div`
  .container {
    max-width: 850px;
    color: ${Colors.GreenColor};
    h1 {
      color: ${Colors.GreenColor};
      display: inline-block;
      padding: 10px;
      border-radius: 10px;
      font-size: 35px;
      margin-top: 30px;
      margin-bottom: 0px;
      font-weight: bold;
      width: 100%;
      text-align: center;
    }
  }
  label {
    font-weight: bold;
    font-size: 22px;
    display: block;
  }

  .submit-button {
    text-align: center;
    margin-top: 50px;
  }
  .ant-form-item {
    display: block;
    .ant-form-item-label {
      color: ${Colors.GreenColor};
      margin-bottom: 10px;
    }
    .ant-picker,
    .ant-input-affix-wrapper {
      width: 100%;
      padding: 10px;
      border: 1px solid ${Colors.GreenColor};
      border-radius: 10px;
    }
  }
  .ant-select-selector,
  input,
  textarea {
    border-radius: 15px !important;
    width: 100%;
    border-radius: 10px;
    line-height: 22px;
    vertical-align: middle;
  }
  .subtitle {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 30px;
  }
  .intro-text {
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 30px;
  }
`;

class CreateDraftPage extends Component {
  state = {
    loading: false,
  };
  onFinish = (values) => {
    this.setState({ loading: true });

    adminServices
      .createDraftEmail(values)
      .then(() => {
        store.addNotification({
          ...notification,
          title: 'Erfolg',
          type: 'success',
          message: 'Entwurf erstellt',
        });
        this.setState({ loading: false });
      })
      .catch(() => {
        this.setState({ loading: false });
        store.addNotification({
          ...notification,
          title: 'Fehler!',
          type: 'danger',
          message: 'Konnte nicht erstellt werden',
        });
      });
  };

  render() {
    return (
      <Wrapper>
        <Header relativeHeader={true} />
        <Container className="container">
          <Spin spinning={this.state.loading}>
            <Section>
              <h1>Entwurf erstellen</h1>

              <Form onFinish={this.onFinish}>
                <FormWrapper>
                  <>
                    <FormItem
                      name="user_email"
                      label="User Email "
                      rules={[
                        {
                          required: true,
                          message: 'erforderlich',
                        },
                      ]}
                    >
                      <Input type="email" />
                    </FormItem>

                    <FormItem
                      name="title"
                      label="Titel"
                      rules={[
                        {
                          required: true,
                          message: 'erforderlich',
                        },
                      ]}
                    >
                      <Input />
                    </FormItem>

                    <FormItem
                      name="goal"
                      label="Finanzierungsziel"
                      rules={[
                        {
                          required: true,
                          message: 'erforderlich',
                        },
                      ]}
                    >
                      <Input type="number" min="1" />
                    </FormItem>

                    <CitiesSelect required />

                    <CategoriesSelect />

                    <FormItem className="submit-button">
                      <PrimaryButton
                        type="primary"
                        htmltype="submit"
                        minWidth="250px"
                      >
                        Einreichen
                      </PrimaryButton>
                    </FormItem>
                  </>
                </FormWrapper>
              </Form>
            </Section>
          </Spin>
        </Container>
      </Wrapper>
    );
  }
}

export default CreateDraftPage;
