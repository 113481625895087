import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DonationsActions } from 'redux/actions';
import { H2, numberWithCommas, Colors, PrimaryButton } from 'utils';
import styled from '@emotion/styled';
import moment from 'moment';
import { Table } from 'antd';
import Papa from 'papaparse';
const MySupportsWrapper = styled.div`
  h2 {
    color: ${Colors.GreenColor};
    display: inline-block;
    font-size: 35px;
    margin-bottom: 50px;
  }
  .ant-table-thead {
    th {
      font-size: 22px;
      font-weight: bold;
      text-align: center;
    }
  }
  .project-name {
    font-size: 20px;
    font-weight: bold;
  }
  .ant-table-content {
    .ant-table-thead,
    .ant-table-cell,
    .ant-table-tbody .ant-table-row {
      border: 1px solid ${Colors.GreenColor};
    }
  }
  .ant-table-cell {
    text-align: center;
    .delete {
      text-align: center;
      button {
        border: none;
        background: none;
      }
    }
  }
  .btn {
    display: flex;
    justify-content: end;
  }
`;

export class DonationsTable extends Component {
  static propTypes = {
    getProjectDonations: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    donations: PropTypes.object.isRequired,
    projectId: PropTypes.string.isRequired,
  };
  state = {
    perPage: 5,
  };
  componentDidMount() {
    const { projectId, getProjectDonations, getPatreonDonations } = this.props;
    const { perPage } = this.state;
    getProjectDonations(projectId, perPage, 1);
    getPatreonDonations(projectId);
  }

  handleShowSupports = () => {
    const { projectId, getProjectDonations } = this.props;
    const { perPage } = this.state;
    getProjectDonations(projectId, perPage);
    this.setState({ perPage: perPage + 5 });
  };

  handleDownloadCSV = () => {
    const { patreonDonations } = this.props;

    // Convert goodies data to CSV format
    const csvData = Papa.unparse(patreonDonations.donations);

    // Create a blob containing the CSV data
    const blob = new Blob([csvData], { type: 'text/csv' });

    // Create a temporary anchor element to trigger the download
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'patreon.csv';
    link.click();
  };

  render() {
    const {
      donations,
      loading,
      getProjectDonations,
      projectId,
      patreonDonations,
    } = this.props;
    const { perPage } = this.state;
    const columns = [
      {
        title: 'Datum',
        dataIndex: 'time',
        key: 'time',
        render: (text) => <p>{moment.unix(Number(text)).fromNow()}</p>,
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text, record) => {
          const name =
            !record.last_name && !record.first_name
              ? 'Anonym'
              : `${record.first_name || ''} ${record.last_name || ''}`;
          return <p>{name}</p>;
        },
      },
      {
        title: 'Betrag',
        dataIndex: 'amount',
        key: 'amount',
        render: (text) => <p>{numberWithCommas(text)} €</p>,
      },
      {
        title: 'Nachricht',
        dataIndex: 'message',
        key: 'message',
        render: (text) => {
          return <p>{text}</p>;
        },
      },
    ];

    const columnsPatreon = [
      {
        title: 'Vorname',
        dataIndex: 'first_name',
        key: 'first_name',
      },
      {
        title: 'Familienname, Nachname',
        dataIndex: 'last_name',
        key: 'last_name',
      },
      {
        title: 'Betrag',
        dataIndex: 'amount',
        key: 'amount',
        render: (text) => <p>{text !== null ? numberWithCommas(text) + ' €' : '0 €'}</p>,

      },
      {
        title: 'Spendenart',
        dataIndex: 'donation_type',
        key: 'donation_type',
      },
    ];
    return (
      <MySupportsWrapper>
        {donations && donations.donations && donations.donations.length > 0 && (
          <>
            <H2>Supporterliste</H2>
            <Table
              columns={columns}
              dataSource={donations.donations}
              loading={loading}
              pagination={{
                total: donations.count,
                pageSize: perPage,
                hideOnSinglePage: true,
                pageSizeOptions: [5, 10, 20, 50],
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} Donations`,
                onChange: (page, pageSize) => {
                  this.setState({ perPage: pageSize });
                  getProjectDonations(projectId, pageSize, page);
                },
              }}
            />
            {patreonDonations?.count > 0 && (
              <div style={{ margin: '20px 0px' }}>
                <H2>Patreon Supporterliste</H2>
                <div className="btn">
                  <PrimaryButton onClick={() => this.handleDownloadCSV()}>
                    Download CSV
                  </PrimaryButton>
                </div>
                <Table
                  columns={columnsPatreon}
                  dataSource={patreonDonations?.count > 0 ? patreonDonations?.donations : []}
                  // loading={loading}
                  // pagination={{
                  //   total: donations.count,
                  //   pageSize: perPage,
                  //   hideOnSinglePage: true,
                  //   pageSizeOptions: [5, 10, 20, 50],
                  //   showTotal: (total, range) =>
                  //     `${range[0]}-${range[1]} of ${total} Donations`,
                  //   onChange: (page, pageSize) => {
                  //     this.setState({ perPage: pageSize });
                  //     getProjectDonations(projectId, pageSize, page);
                  //   },
                  // }}
                />
              </div>
            )}
          </>
        )}
      </MySupportsWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  const { loading, donations, patreonDonations } = state.donations;
  return {
    loading,
    donations,
    patreonDonations,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getProjectDonations: (projectId, perPage, page) =>
    dispatch(DonationsActions.getProjectDonations(projectId, perPage, page)),
  getPatreonDonations: (projectId) =>
    dispatch(DonationsActions.getPatreonDonations(projectId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DonationsTable);
