import React, { useEffect } from 'react';
import { PrimaryButton } from 'utils';
import {
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

const RecurringPaymentForm = ({
  paymentType,
  isTicketable,
  setElements,
  setStripe,
  options,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  useEffect(() => {
    setStripe(stripe);
    setElements(elements);
  }, [elements, setElements, setStripe, stripe]);

  return (
    <div>
      <div>
        {paymentType === 'card' && (
          <>
            <div className="payment_blk_in">
              <PaymentElement id="payment-element" {...options} />
            </div>
            <PrimaryButton
              type="submit"
              minWidth={'100%'}
              claPRACTICE
              GROUP
              ssName="payPalBtn"
              // onClick={onFinish}
              style={{ marginBottom: '1px', marginTop: '25px' }}
            >
              Jetzt zahlungspflichtig {isTicketable ? 'buchen' : 'unterstützen'}
            </PrimaryButton>
          </>
        )}
      </div>
    </div>
  );
};

export default RecurringPaymentForm;
