import React from 'react';
import {
  ContactIcon,
  IdeaIcon,
  StartProjectIcon,
  RightArrow,
  BeforeBackground,
  AfterBackground,
} from 'images';
import { Link } from 'react-router-dom';
import {
  Colors,
  SvgWrapper,
  Container,
  Section,
  device,
  isLabTop,
  isTablet,
  isMobile,
} from 'utils';
import styled from '@emotion/styled';

const HowItWorkWrapper = styled.div`
  text-align: center;
  position: relative;
  h1 {
    font-weight: bold;
    font-size: 40px;
    margin-bottom: 120px;
    @media ${device.laptop} {
      font-size: 30px;
    }
    @media ${device.allMobile} {
      font-size: 25px;
    }
  }
  .container {
    background: #f9f9f9;
    margin-top: -8px;
  }
  .after-background {
    position: relative;
    margin-top: -5px;
    z-index: -1;
  }
  .show-more {
    background: ${Colors.secondaryColor};
    border: 1px solid ${Colors.secondaryColor};
    color: ${Colors.white};
    text-align: center;
    border-radius: 12px;
    cursor: pointer;
    margin-bottom: 20px;
    padding: 15px 20px;
    min-width: 200px;
    font-size: 18px;
    &:hover {
      background: ${Colors.transparent};
      color: ${Colors.secondaryColor};
    }
  }
`;

const HowItWorkList = styled.ul`
  display: flex;
  justify-content: space-between;
  padding: 0;
  list-style: none;
  position: relative;
  margin-bottom: 50px;
  @media ${device.allMobile} {
    display: block;
  }
  &:after {
    content: '';
    width: 300px;
    height: 4px;
    background: ${Colors.secondaryColor};
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
  }
  ol {
    width: 25%;
    padding: 0;
    @media ${device.allMobile} {
      width: 100%;
    }
    p {
      font-size: 16px;
      line-height: 1.6;
      margin-top: 30px;
      &.three-text {
        margin-top: 40px;
      }
    }
    header {
      position: relative;
      font-size: 28px;
      font-weight: bold;
      margin-bottom: 30px;
      @media ${device.allMobile} {
        margin-bottom: 20px;
      }
      @media ${device.laptop} {
        font-size: 22px;
      }
      @media ${device.tablet} {
        margin-bottom: 0;
      }
      span {
        display: block;
        font-size: 14px;
        font-weight: normal;
      }
      .number {
        background: ${Colors.secondaryColor};
        color: ${Colors.white};
        font-size: 20px;
        font-weight: bold;
        padding: 10px 15px;
        border-radius: 50%;
        position: absolute;
        left: -75px;
        width: 50px;
        height: 50px;
        top: 50%;
        transform: translateY(-50%);
        &.first {
          top: 23px;
        }
        @media ${device.minLaptop} {
          display: none;
        }
      }
    }
  }
  .next-step {
    width: 7%;
    margin: auto;
    @media ${device.allMobile} {
      width: 25%;
      margin: 20px auto;
      transform: rotate(90deg);
    }
    @media ${device.laptop} {
      margin-top: 250px;
    }
    @media ${device.tablet} {
      margin-top: 200px;
    }
  }
`;

export default function HowItWork() {
  return (
    <Section>
      <HowItWorkWrapper>
        <div className="background">
          <SvgWrapper width="100%" height="100%">
            <BeforeBackground />
          </SvgWrapper>
        </div>
        <div className="container">
          <Container>
            <>
              <h2>Wie funktioniert commonsplace?</h2>
              <HowItWorkList>
                <ol>
                  <header>
                    {!isTablet() && <span className="number first">1</span>}
                    <h3>Alles fängt mit einer Idee an</h3>
                  </header>
                  <SvgWrapper
                    width={isMobile() ? '100%' : '100%'}
                    height={
                      isTablet() ? '270px' : isLabTop() ? '320px' : '307px'
                    }
                  >
                    <IdeaIcon />
                  </SvgWrapper>
                  <p>
                    Du hast eine kreative Idee: Ob du eine neue App, ein
                    Obdachlosenprojekt in deiner Stadt oder ein Theaterstück
                    starten willst, all das beansprucht eine finanzielle Quelle.
                    Auf commonsplace findest du eine Community, die dich bei
                    deiner Idee unterstützt.
                  </p>
                </ol>
                <div className="next-step">
                  <SvgWrapper width={'80%'} height={'auto'}>
                    <RightArrow />
                  </SvgWrapper>
                </div>

                <ol>
                  <header>
                    {!isTablet() && <span className="number">2</span>}
                    <h3>Erstelle dein Projekt</h3>
                  </header>
                  <SvgWrapper
                    width={'100%'}
                    height={isTablet() ? '270px' : '350px'}
                  >
                    <ContactIcon />
                  </SvgWrapper>
                  <p>
                    Du registrierst dich ganz einfach auf commonsplace. Nach
                    Abschluss deiner Registrierung, kannst du nun dein Projekt
                    erstellen, indem du deine Projektdaten einpflegst, wie z.B.
                    eine motivierende Projektbeschreibung, die Fundingsumme und
                    deine Projektlaufzeit. Jetzt kann es auch schon losgehen!
                  </p>
                </ol>
                <div className="next-step">
                  <SvgWrapper width={'80%'} height={'auto'}>
                    <RightArrow />
                  </SvgWrapper>
                </div>
                <ol>
                  <header>
                    {!isTablet() && <span className="number">3</span>}
                    <h3>Starte dein Projekt</h3>
                  </header>
                  <SvgWrapper
                    width={'100%'}
                    height={isTablet() ? '270px' : '340px'}
                  >
                    <StartProjectIcon />
                  </SvgWrapper>
                  <p className="three-text">
                    Dein Projekt ist jetzt online und deine Community kann deine
                    Idee unterstützen. Steigere die Aufmerksamkeit deiner Idee:
                    Wende dich an Freunde und Familie, damit sie dich dabei
                    unterstützen das Projekt zu realisieren. Schon bald ist dein
                    Traum Wirklichkeit.
                  </p>
                </ol>
              </HowItWorkList>
              <Link to="/how-it-works" className="show-more">
                Mehr anzeigen
              </Link>
            </>
          </Container>
        </div>
        <div className="after-background">
          <SvgWrapper width="100%" height="100%">
            <AfterBackground />
          </SvgWrapper>
        </div>
      </HowItWorkWrapper>
    </Section>
  );
}
