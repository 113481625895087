import React from 'react';
import { ChooseProject, Support, Share } from './';
import { Colors, Container, Section, TitleBanner } from 'utils';
import styled from '@emotion/styled';
const HowToSupportWrapper = styled.div`
  text-align: center;
  h1 {
    margin-bottom: 50px;
  }
`;

export default function HowToSupport() {
  return (
    <Section>
      <HowToSupportWrapper>
        <Container>
          <>
            <TitleBanner color={Colors.GreenColor}>
              <h3>Wie unterstützt du ein Projekt?</h3>
            </TitleBanner>
            <ChooseProject />
          </>
        </Container>
        <Support />
        <Container>
          <Share />
        </Container>
      </HowToSupportWrapper>
    </Section>
  );
}
