/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { Colors } from 'utils';

const MailIcon = ({ color, width, height }) => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      enableBackground="new 0 0 512 512"
      xmlSpace="preserve"
      height={`${height || '100%'}`}
      width={`${width || '100%'}`}
      css={css`
        fill: ${color || Colors.secondaryColor};
        max-width: ${width || '100%'};
        max-height: ${height || '100%'};
        height: ${height || '100%'};
        width: ${width || '100%'};
        display: inline-block;
      `}
    >
      <g>
        <g>
          <path
            d="M504.888,111.933L264.994,297.997c-2.666,2.007-5.83,3.003-8.994,3.003s-6.328-0.996-8.994-3.003L7.112,111.933
			C2.664,118.909,0,127.13,0,136v240c0,24.814,20.186,45,45,45h422c24.814,0,45-20.186,45-45V136
			C512,127.13,509.336,118.909,504.888,111.933z"
          />
        </g>
      </g>
      <g>
        <g>
          <path d="M467,91H45c-4.618,0-8.987,0.901-13.184,2.199L256,267.25L480.184,93.199C475.987,91.901,471.618,91,467,91z" />
        </g>
      </g>
    </svg>
  );
};

export default MailIcon;

MailIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};
