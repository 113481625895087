import { combineReducers } from 'redux';
import { default as authentication } from './AuthReducer';
import { default as projects } from './ProjectsReducer';
import { default as payment } from './PaymentReducer';
import { default as donations } from './DonationsReducer';
import { default as alert } from './AlertsReducer';
import { default as admin } from './AdminReducer';
const appReducer = combineReducers({
  authentication,
  projects,
  payment,
  donations,
  alert,
  admin,
});

export default appReducer;
