import React from 'react';
import styled from '@emotion/styled';
import { Colors, Container, TitleBanner, device } from 'utils';

const IntroWrapper = styled.section`
  text-align: center;

  margin-bottom: 100px;
  @media ${device.allMobile} {
    margin-bottom: 30px;
  }
  h1 {
    margin: 50px auto 20px;
  }
  p {
    font-size: 18px;
    margin: auto;
    color: ${Colors.black};
    margin-bottom: 20px;
  }
`;

export default function Intro() {
  return (
    <IntroWrapper>
      <Container>
        <div>
          <TitleBanner color={Colors.GreenColor}>
            Wie funktioniert es?
          </TitleBanner>
        </div>
      </Container>
    </IntroWrapper>
  );
}
