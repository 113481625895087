import React, { Component } from 'react';
import { Layout, SingUpForm } from 'components';
import { Container, Colors, device } from 'utils';
import styled from '@emotion/styled';

const LoginWrapper = styled.div`
  max-width: 550px;
  margin: 100px auto;
  min-width: 500px;
  padding: 0 50px 40px;
  @media ${device.allMobile} {
    min-width: 1px;
    padding: 0;
  }
  h1 {
    font-size: 25px;
    font-weight: bold;
  }
  .ant-form-item:last-of-type,
  .ant-form-item:last-of-type button {
    margin: 0;
    min-width: 100%;
  }
  .ant-form-item {
    display: block;
    .ant-form-item-label {
      color: ${Colors.GreenColor};
      margin-bottom: 10px;
      label {
        font-weight: bold;
        font-size: 18px;
      }
    }
    .ant-form-item-control-input-content > input,
    .ant-input-affix-wrapper {
      width: 100%;
      padding: 10px;
      border: 1px solid ${Colors.GreenColor};
      border-radius: 10px;
    }
  }
  .login-actions {
    display: flex;
    justify-content: space-between;
  }
`;
export default class LoginPage extends Component {
  render() {
    return (
      <Layout>
        <Container>
          <LoginWrapper>
            <SingUpForm />
          </LoginWrapper>
        </Container>
      </Layout>
    );
  }
}
