import React, { Component } from 'react';
import styled from '@emotion/styled';
import { RightSideBackground } from 'images';
import { Colors } from 'utils';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const SideBarWrapper = styled.aside`
  display: flex;
  background: url('${RightSideBackground}');
  background-repeat: no-repeat;
  background-position: 50%, 50%;
  background-size: cover;
  padding-left: 40px;
`;

const NavigationWrapper = styled.nav`
  padding: 20px;
  width: 250px;
  position: relative;
  padding-top: 20px;
  h1 {
    margin-top: 0;
  }
  & > ul {
    padding: 0;
    list-style: none;
    position: sticky;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100vh - 134px);
    top: 120px;
    margin-bottom: 0;
    & > li {
      border-bottom: none;
      background: ${Colors.white};
      border: 1px solid ${Colors.secondaryColor};
      color: ${Colors.secondaryColor};
      text-align: center;
      border-radius: 12px;
      cursor: pointer;
      padding: 15px 20px;
      min-width: 200px;
      font-size: 18px;
      &:hover {
        background: ${Colors.secondaryColor};
        color: ${Colors.white};
      }
    }
  }
`;

export default class SideBar extends Component {
  static propTypes = {
    projectId: PropTypes.number,
  };
  render() {
    const { projectId } = this.props;
    return (
      <SideBarWrapper>
        <NavigationWrapper>
          <ul>
            <li>
              <Link to={`/view/${projectId}`}>
                Vorschau
              </Link>
              {/* <Link>
                Vorschau
              </Link> */}
            </li>

            <li>
              <Link to="profile" target="_blank">
                Mein Profil
              </Link>
            </li>
          </ul>
        </NavigationWrapper>
      </SideBarWrapper>
    );
  }
}
