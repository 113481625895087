import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { projectsServices } from 'services';
import { Row, Col, Pagination, Spin } from 'antd';
import {
  CheckGreen,
  CloseRed,
  ReloadOrange,
  TruckGreen,
  TruckOrange,
  TruckRed,
} from 'images';
import { numberWithCommas } from 'utils';
const RewardsWrapper = styled.div`
  .order-info {
    border: 2px solid black;
    margin: 25px 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 20px;
    border-radius: 20px;
    text-align: start;
    font-size: 24px;
    font-weight: 700;
    @media (max-width: 500px) {
      margin: 25px 0px;
    }
    @media (max-width: 1150px) and (min-width: 950px) {
      margin: 25px 5px;
    }
  }

  .order-info__lower {
    margin: 15px 10px 0px;
    font-weight: 600;
    font-size: 16px;
    @media (max-width: 1050px) {
      font-size: 14px;
    }

    img {
      height: 30px;
      width: 30px;
      margin: auto 15px 0px 5px;
    }
  }
`;
class YourRewards extends Component {
  async componentDidMount() {
    const rewardsData = await projectsServices.getUserRewards(3, 1);
    this.setState({ rewardsData });
  }

  state = {
    rewardsData: null,
    loading: false,
  };

  static propTypes = {};

  render() {
    const { rewardsData, loading } = this.state;
    return (
      <RewardsWrapper>
        <h1 style={{ fontSize: '32px' }}>Deine Goodies</h1>

        <Spin size="large" spinning={loading}>
          {/* No Rewards Found */}
          {rewardsData && rewardsData.orders.length === 0 && (
            <div>No Rewards Found</div>
          )}
          {/* Display Rewards */}
          {rewardsData &&
            rewardsData.orders.length > 0 &&
            rewardsData.orders.map((reward, index) => {
              return (
                <div className="order-info" key={index}>
                  <Row>
                    <Col span={8} style={{ textAlign: 'start' }}>
                      {reward.project_title}
                    </Col>
                    <Col
                      span={10}
                      style={{ textAlign: 'center', overflow: 'clip' }}
                    >
                      {reward.reward_title}
                    </Col>
                    <Col span={6} style={{ textAlign: 'end' }}>
                      {numberWithCommas(Number(reward.price).toFixed(2))}€
                    </Col>
                  </Row>
                  <Row className="order-info__lower">
                    <Col span={12} style={{ textAlign: 'start' }}>
                      {reward.project_status === 'accepted' && (
                        <span>
                          <img src={ReloadOrange} alt="reload" />
                          Projekt noch im Finanzierungszeitraum
                        </span>
                      )}
                      {reward.project_status === 'successful' && (
                        <span>
                          <img src={CheckGreen} alt="check-success" />
                          Projekt war erfolgreich
                        </span>
                      )}
                      {reward.project_status === 'finished' && (
                        <span>
                          <img src={CloseRed} alt="close" />
                          Projekt war nicht erfolgreich
                        </span>
                      )}
                    </Col>
                    <Col span={12} style={{ textAlign: 'end' }}>
                      {(reward.project_status === 'accepted' ||
                        reward.project_status === 'successful') &&
                        reward.sent === false && (
                          <span>
                            <img src={TruckOrange} alt="reload" />
                            Dankeschön noch nicht versendet
                          </span>
                        )}
                      {reward.sent === true && (
                        <span>
                          <img src={TruckGreen} alt="check-success" />
                          Dankeschön wurde versendet
                        </span>
                      )}
                      {reward.project_status === 'finished' &&
                        reward.sent === false && (
                          <span>
                            <img src={TruckRed} alt="close" />
                            Dankeschön wird nicht versendet
                          </span>
                        )}
                    </Col>
                  </Row>
                </div>
              );
            })}
          {rewardsData && rewardsData.orders.length > 0 && (
            <Pagination
              defaultCurrent={1}
              total={rewardsData.count}
              pageSize={3}
              hideOnSinglePage={true}
              onChange={async (page) => {
                this.setState({ loading: true });
                const rewardsData = await projectsServices.getUserRewards(
                  3,
                  page
                );
                this.setState({
                  rewardsData,
                  loading: false,
                });
              }}
              style={{ float: 'right' }}
            />
          )}
        </Spin>
      </RewardsWrapper>
    );
  }
}

export default YourRewards;
