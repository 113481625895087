import React from 'react';
import { Colors, PrimaryButton, TitleBanner, device } from 'utils';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

function scrollDown() {
  const pageHeight = window.innerHeight - 50;
  window.scrollBy(0, pageHeight);
}

const ProjectBannerComponentWrapper = styled.div`
  text-align: center;
  @media ${device.allMobile} {
    padding: 0 20px;
  }
  @media ${device.tablet} {
    padding: 0 20px;
  }
  button {
    margin-top: 50px;
  }
  .project-banner__content {
    text-align: center;
    h1 {
      margin-bottom: 50px;
      margin-left: 100px;
      margin-right: 100px;
      font-size: 60px;
      color: ${Colors.white};
      word-break: break-word;
      @media ${device.allMobile} {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 25px;
        font-size: 25px;
      }
    }
    p {
      max-width: 550px;
      margin: auto;
      font-size: 28px;
      color: ${Colors.white};
      @media ${device.allMobile} {
        display: block;
        font-size: 18px;
      }
    }
  }
`;

export default function ProjectBannerComponent(props) {
  const { title, subtitle, video } = props;
  return (
    <ProjectBannerComponentWrapper>
      <div className="project-banner__content">
        <TitleBanner color={Colors.GreenColor}>{title || 'Title'}</TitleBanner>
        <p>{subtitle || 'Untertitel'}</p>
      </div>
      {video && (
        <PrimaryButton onClick={() => scrollDown()}>
          Video anschauen
        </PrimaryButton>
      )}
    </ProjectBannerComponentWrapper>
  );
}

ProjectBannerComponent.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  video: PropTypes.string,
};
