import styled from '@emotion/styled';
import React, { Component } from 'react';
import { PrimaryButton, SvgWrapper, Colors } from 'utils';
import { MailIcon, ViewIcon } from 'images';
import { RichEditor } from 'components';
import { EditorState } from 'draft-js';

const ViewBlogWrapper = styled.div`
  h2 {
    font-size: 20px;
    margin-top: 0;
    margin-bottom: 20px;
  }
  .blog-form__warpper {
    background: ${Colors.grey};
    padding: 20px;
    .content__wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: ${Colors.white};
      border-bottom: 1px solid ${Colors.grey};
      padding: 0 20px;
      div {
        display: flex;
        align-items: center;

        .svg-wrapper + .svg-wrapper {
          margin-left: 10px;
        }
      }
    }
    form {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      & > div {
        width: 50%;
        margin-bottom: 20px;
        label {
          display: block;
          font-size: 18px;
        }
        input {
          width: 98%;
          height: 40px;
          border: none;
        }
      }
      .text-editor {
        width: 100%;
      }
      .submit-button {
        width: auto;
        margin-bottom: 0;
        margin-top: 20px;
        display: inline-block;
        margin-left: auto;
      }
    }
  }
`;

export default class Blog extends Component {
  state = {
    viewBlog: false,
    editorState: EditorState.createEmpty(),
  };

  handleViewBlog = () => this.setState({ viewBlog: !this.state.viewBlog });
  handelEditorState = (editorState) => this.setState({ editorState });
  render() {
    const { viewBlog, editorState } = this.state;

    return (
      <div>
        <h1>Blog</h1>
        <p>
          With the blog on your project page, you can inform your community
          about milestones reached, delivery of the rewards, and changes in your
          project plan.
        </p>
        <p>
          If your blog post doesn't contain advertising, you can also email it
          to your supporters.
        </p>
        <PrimaryButton onClick={() => this.handleViewBlog()}>
          Create blog post as draft
        </PrimaryButton>
        <ViewBlogWrapper>
          <h2>Posts</h2>
          <div className="blog-form__warpper">
            <div className="content__wrapper">
              <p>Title</p>
              <div>
                <SvgWrapper className="svg-wrapper">
                  <MailIcon />
                </SvgWrapper>
                <SvgWrapper className="svg-wrapper">
                  <ViewIcon />
                </SvgWrapper>
              </div>
            </div>

            {viewBlog && (
              <>
                <div>
                  <div className="content__wrapper">
                    <p>Blog</p>
                  </div>
                  <form>
                    <div>
                      <label>Title</label>
                      <input type="text" />
                    </div>
                    <div>
                      <label>Media (YouTube, Vimeo, Soundcloud)</label>
                      <input type="text" />
                    </div>
                    <div>
                      <label>Media</label>
                      <input type="text" />
                    </div>
                    <div>
                      <label>Media</label>
                      <input type="text" />
                    </div>
                    <div>
                      <label htmlFor="file">Image</label>
                      <input
                        name="file"
                        id="file"
                        type="file"
                        className="custom-file-input"
                      />
                    </div>
                    <div className="text-editor">
                      <label>Text</label>
                      <RichEditor
                        editorState={editorState}
                        onChange={this.handelEditorState}
                      />
                    </div>
                    <div className="submit-button">
                      <PrimaryButton>Save</PrimaryButton>
                    </div>
                  </form>
                </div>
              </>
            )}
          </div>
        </ViewBlogWrapper>
      </div>
    );
  }
}
