import React, { Component } from 'react';
import styled from '@emotion/styled';
import { Header } from 'components';
import PropTypes from 'prop-types';
import { ProjectsActions, PaymentActions } from 'redux/actions';
import { connect } from 'react-redux';
import { Colors, Container, H1, device, Modal, PrimaryButton } from 'utils';
import { Link, withRouter } from 'react-router-dom';
import {
  SideBar,
  RightBar,
  AboutYou,
  BasicInformation,
  // Blog,
  // CommunicationPlan,
  ContractDocuments,
  // Cooperations,
  // FeedBackAnswers,
  // FeedBackParticipants,
  FundingDuration,
  FundingGoal,
  // ImprintAndBillingAddress,
  // MarketingReferrer,
  Media,
  // PartnerCampaigns,
  ProjectDescription,
  Rewards,
  SocialNetworks,
  StripeAccount,
  // Team,
  // VerificationOverview,
  // VoluntaryCommission,
  // Legitimation,
} from './components';
import PayPalAccount from './components/PayPalAccount';

const ProjectFormWrapper = styled.div`
  .header-wrapper {
    position: relative;
    background: ${Colors.white};
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
      0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
    ul {
      li {
        color: ${Colors.black};
      }
    }
    .start-project {
      color: ${Colors.GreenColor};
    }
  }
  .relative-header {
    z-index: 1003;
    background: ${Colors.white};
  }
  .container {
    margin-top: 0;
    max-width: none;
    padding: 0;
  }
  .inside-form-container {
    max-width: 950px;
  }
  .content-Wrapper {
    max-width: none;
    margin: auto;
    background: ${Colors.white};
  }
`;

const ProjectFormFormWrapper = styled.div`
  display: flex;
  @media ${device.allMobile} {
    display: none;
  }

  .project-form__content {
    padding: 20px;
    height: calc(100vh - 100px);
    overflow: auto;
    width: 100%;
    direction: rtl;
    * {
      direction: ltr;
    }
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: #f5f5f5;
      display: none;
    }

    &::-webkit-scrollbar {
      width: 12px;
      background-color: #f5f5f5;
      display: none;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: ${Colors.secondaryColor};
      display: none;
    }
    input,
    select,
    textarea,
    .ant-select-selector {
      border-radius: 10px !important;
    }
    .ant-col-24.ant-form-item-label {
      padding: 0 !important;
    }
    textarea {
      // min-height: 200px;
    }
    .ant-picker {
      width: 100%;
      border-radius: 10px;
    }
    .ant-input-group {
      input {
        border-radius: 10px 0 0 10px !important;
      }
      .ant-input-group-addon {
        border-radius: 0 10px 10px 0;
      }
    }
    h1 {
      font-weight: bold;
      color: ${Colors.GreenColor};
      margin: 35px 0;
      line-height: 1;
    }
    h2 {
      color: ${Colors.GreenColor};
      margin: 30px 0 0;
    }
    p {
      font-size: 16px;
      margin: 0;
      span {
        font-weight: bold;
      }
    }
  }
`;
const GotoDiskTop = styled.div`
  display: none;
  @media ${device.allMobile} {
    display: block;
    margin: 150px 20px;
    text-align: center;
    border: 1px solid ${Colors.grey};
    border-radius: 15px;
    padding: 20px 10px;
    color: ${Colors.white};
    background: ${Colors.secondaryColor};
    h1 {
      color: ${Colors.white};
      font-weight: bold;
    }
  }
`;
const LockWrapper = styled.div`
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #000;
    opacity: 0.7;
    z-index: 1000;
  }
  .text {
    position: fixed;

    left: 50%;
    transform: translateX(-50%);
    z-index: 1001;
    width: 750px;
    h1 {
      margin-top: 50px;
    }
    p,
    h1 {
      color: ${Colors.white};
    }
  }
  .link {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    @media ${device.laptop} {
      font-size: 16px;
    }
    button {
      margin-left: 50px;
      margin-right: 50px;
    }
    a,
    button {
      display: inline-block;
      border-bottom: none;
      background: ${Colors.white};
      border: 1px solid ${Colors.secondaryColor};
      color: ${Colors.secondaryColor};
      text-align: center;
      border-radius: 12px;
      cursor: pointer;
      margin-bottom: 20px;
      padding: 15px 20px;
      min-width: 200px;
      font-size: 18px;
      @media ${device.laptop} {
        font-size: 16px;
      }
      &:hover {
        background: ${Colors.secondaryColor};
        color: ${Colors.white};
      }
    }
  }
`;
const MoveModal = styled.div`
  .actions {
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
    button + button {
      background: #f44336;
      border: 1px solid #f44336;
      &:hover {
        background: ${Colors.transparent};
        border: 1px solid #f44336;
        color: #f44336;
      }
    }
  }
`;
class ProjectForm extends Component {
  static propTypes = {
    updateDraft: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    uploadImages: PropTypes.func.isRequired,
    updateDraftAndImages: PropTypes.func.isRequired,
    updateDraftReward: PropTypes.func.isRequired,
    deleteDraftReward: PropTypes.func.isRequired,
    handleSteps: PropTypes.func.isRequired,
    currentStep: PropTypes.number.isRequired,
    reEditDraft: PropTypes.func.isRequired,
    getStripeStatus: PropTypes.func.isRequired,
    stripeStatus: PropTypes.string,
  };
  componentDidMount() {
    const {
      updateDraft,
      getStripeStatus,
      match: {
        params: { id },
      },
    } = this.props;
    id && updateDraft(id, {});
    id && getStripeStatus(id);
    this.setSelectedStep(1);
    id &&
      fetch('create_paypal_customer_account', {
        method: 'POST',
        body: JSON.stringify({
          project_id: id,
          return_url: window.location.href,
        }),
        redirect: 'follow',
      })
        .then((result) => {
          this.setpayPalStatus(result?.completed == undefined ? false : true);
        })
        .catch((err) => console.log('error'));
  }
  state = {
    canMove: true,
    showModal: false,
    selectedStep: 0,
    submitFromModal: false,
    payPalStatus: false,
  };
  setSelectedStep = (selectedStep) => this.setState({ selectedStep });
  setpayPalStatus = (payPalStatus) => this.setState({ payPalStatus });
  setCanMove = (canMove) => this.setState({ canMove });
  handelCloseModal = () => {
    this.setState({ showModal: false });
    document.body.style.overflowY = 'unset';
  };
  setSubmitFromModal = (submitFromModal) => this.setState({ submitFromModal });
  handelShowModal = () => {
    this.setState({ showModal: true, submitFromModal: true });
    document.body.style.overflowY = 'hidden';
  };
  setHandleStatusChange = (status) => this.setState({ status });

  handelSaveAndMove = () => {
    const submitButton = document.getElementById('submit-button');
    submitButton.click();
    this.setCanMove(true);
    this.handelCloseModal();
  };

  render() {
    const { canMove, showModal, selectedStep, submitFromModal } = this.state;
    const {
      updateDraft,
      match: {
        params: { id },
      },
      project,
      uploadImages,
      updateDraftAndImages,
      updateDraftReward,
      deleteDraftReward,
      handleSteps,
      currentStep,
      reEditDraft,
      stripeStatus,
    } = this.props;
    const isPending = Boolean(
      project &&
        (project.status === 'pending' ||
          project.status === 'accepted' ||
          project.status === 'edit_accepted' ||
          project.status === 'edit_pending' ||
          project.status === 'declined' ||
          project.status === 'edit_declined')
    );
    const isStripeDone = Boolean(
      stripeStatus === 'enabled_and_eventually_due' ||
        stripeStatus === 'enabled'
    );
    if (isPending) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    const handleCreateProject = (values) => {
      fetch('create_project_draft_user', {
        method: 'POST',
        body: JSON.stringify({ ...values, category_ids:[`${values.category_id}`] }),
        redirect: 'follow',
      })
        .then((result) => {
          console.log('result---> ', result);
          this.props.history.push(`/start-project/${result?.id}`);
        })
        .catch((err) => console.log('error'));
    };

    const handleCurrentComponent = () => {
      const { currentStep } = this.props;
      switch (currentStep) {
        case 1:
          return (
            <BasicInformation
              updateDraft={updateDraft}
              projectId={id}
              setCanMove={this.setCanMove}
              showModal={submitFromModal}
              setSubmitFromModal={this.setSubmitFromModal}
              selectedStep={selectedStep}
              handleCreateProject={handleCreateProject}
              setSelectedStep={this.setSelectedStep}
            />
          );
        case 2:
          return (
            <ProjectDescription
              updateDraft={updateDraft}
              projectId={id}
              setCanMove={this.setCanMove}
              showModal={submitFromModal}
              setSubmitFromModal={this.setSubmitFromModal}
              selectedStep={selectedStep}
            />
          );
        case 3:
          return (
            <FundingDuration
              updateDraft={updateDraft}
              projectId={id}
              setCanMove={this.setCanMove}
              showModal={submitFromModal}
              setSubmitFromModal={this.setSubmitFromModal}
              selectedStep={selectedStep}
            />
          );
        case 4:
          return (
            <FundingGoal
              updateDraft={updateDraft}
              projectId={id}
              setCanMove={this.setCanMove}
              showModal={submitFromModal}
              setSubmitFromModal={this.setSubmitFromModal}
              selectedStep={selectedStep}
            />
          );
        case 5:
          return (
            <Media
              updateDraft={updateDraft}
              projectId={id}
              uploadImages={uploadImages}
              updateDraftAndImages={updateDraftAndImages}
              setCanMove={this.setCanMove}
              showModal={submitFromModal}
              setSubmitFromModal={this.setSubmitFromModal}
              selectedStep={selectedStep}
            />
          );
        case 6:
          return (
            <AboutYou
              updateDraft={updateDraft}
              projectId={id}
              setCanMove={this.setCanMove}
              showModal={submitFromModal}
              setSubmitFromModal={this.setSubmitFromModal}
              selectedStep={selectedStep}
            />
          );
        case 7:
          return (
            <SocialNetworks
              updateDraft={updateDraft}
              projectId={id}
              setCanMove={this.setCanMove}
              showModal={submitFromModal}
              setSubmitFromModal={this.setSubmitFromModal}
              selectedStep={selectedStep}
            />
          );
        case 8:
          return (
            <ContractDocuments
              updateDraft={updateDraft}
              projectId={id}
              setCanMove={this.setCanMove}
              showModal={submitFromModal}
              setSubmitFromModal={this.setSubmitFromModal}
              selectedStep={selectedStep}
            />
          );
        case 9:
          return <StripeAccount projectId={id} />;

        case 10:
          return (
            <Rewards
              updateDraft={updateDraft}
              updateDraftReward={updateDraftReward}
              deleteDraftReward={deleteDraftReward}
              projectId={id}
              setCanMove={this.setCanMove}
              showModal={submitFromModal}
              setSubmitFromModal={this.setSubmitFromModal}
              selectedStep={selectedStep}
            />
          );

        case 11:
          return <PayPalAccount projectId={id} />;

        default:
          break;
      }
    };
    const handelOverlayText = (status) => {
      switch (status) {
        case 'pending':
          return (
            <>
              <h1>Herzlichen Glückwunsch! </h1>
              <p>
                Dein Projekt ist bei uns eingegangen. Wir bitten nun um etwas
                Geduld!
              </p>
              <p>
                Die Überprüfung deiner Angaben kann bis zu 24 Stunden in Anspruch nehmen.
                Wird dein Projekt genehmigt, ist es online und
                für alle sichtbar. Während unserer Bearbeitungszeit hast du
                weiterhin die Chance deine Projektinformationen zu ändern.
                Vergiss jedoch hinterher nicht erneut das Projekt abzusenden!
              </p>
              <p>
                Doch kein Grund zur Sorge: Auch nachdem dein Projekt online
                gegangen ist, kannst du es immer noch bearbeiten. Deine
                Veränderungen müssen wir trotz alle dem zusätzlich genehmigen.
              </p>
              <p>
                Wenn bei äußerst dringenden Notfällen eine schnellere
                Bearbeitungszeit notwendig ist, kontaktiere uns bitte mit dem
                entsprechenden Grund unter{' '}
                <a href="mailto:support@commonsplace.de" rel="noreferrer">
                  support@commonsplace.de
                </a>
              </p>
              <p>
                Die Zeit während der Bearbeitung deines Projekts kannst du
                derweil mit der Vorbereitung deiner Marketingaktionen
                überbrücken. Damit kann dein Projekte im Nachhinein fleißig
                geteilt und verbreitet werden!
              </p>
              <p>Wir wünschen viel Erfolg!</p>
              <p>Dein commonsplace Team</p>
            </>
          );
        case 'edit_pending':
          return (
            <>
              <h1>Danke für die Bearbeitung.</h1>
              <p>
                Die Bearbeitung ist bei uns eingegangen. Wir bitten nun um 24h 
                Geduld, um deine Veränderungen überprüfen zu können.
                Wenn wir dein Projekt genehmigen, ist es online und für jeden
                sichtbar.
              </p>
              <p>
                Während unserer Überprüfungszeit hast du weiterhin die
                Gelegenheit deine Projektinformationen zu verändern. Vergiss
                hinterher nicht die Bearbeitung erneut abzusenden.
              </p>
              <p>
                Du kannst dein Projekt nach dem es online geht immer noch
                bearbeiten. Deine Veränderungen müssen wir dann zusätzlich
                genehmigen.
              </p>
              <p>
                Bereite derweil deine Marketingaktionen vor, damit du dein
                Projekt fleißig teilen verbreiten kannst, sobald wir das „Go“
                geben!
              </p>
              <p>Wir wünschen viel Erfolg!</p>
              <p>Dein commonsplace Team</p>
            </>
          );
        case 'accepted':
          return (
            <>
              <h1>Das Projekt ist online!</h1>
              <p>
                Die Überprüfung deiner Angaben kann bis zu 3 Werktage in Anspruch nehmen.
                Wird dein Projekt genehmigt, ist es online und für alle sichtbar.
                Während unserer Bearbeitungszeit hast du weiterhin die Chance deine Projektinformationen zu ändern.
                Vergiss jedoch hinterher nicht erneut das Projekt abzusenden!
              </p>

              <p>
                <a href="mailto:support@commonsplace.de" rel="noreferrer">
                  support@commonsplace.de
                </a>
              </p>
              <p>
                Bereite derweil deine Marketingaktionen vor, damit du dein
                Projekt fleißig teilen verbreiten kannst, sobald wir das „Go“
                geben!
              </p>
              <p>Wir wünschen viel Erfolg!</p>
              <p>Dein commonsplace Team</p>
            </>
          );
        case 'declined':
          return (
            <>
              <h1>Dein Projektantrag wurde abgelehnt.</h1>
              <p>
                Wir haben dein Projekt überprüft. Dein Projektantrag wurde
                leider abgelehnt.
              </p>
              <p>Grund: {project.message}</p>
              <p>
                Du kannst über{' '}
                <a href="mailto:support@commonsplace.de" rel="noreferrer">
                  support@commonsplace.de
                </a>{' '}
                Einspruch erheben oder nach weiteren Details fragen.
              </p>
              <p>Dein commonsplace Team</p>
            </>
          );
        case 'edit_declined':
          return (
            <>
              <h1>Die Bearbeitung deines Projektes wurde abgelehnt.</h1>
              <p>
                Wir haben deine Bearbeitung überprüft. Deine Bearbeitung wurde
                leider abgelehnt.
              </p>
              <p>Grund: {project.message}</p>
              <p>
                Du kannst über{' '}
                <a href="mailto:support@commonsplace.de" rel="noreferrer">
                  support@commonsplace.de
                </a>{' '}
                Einspruch erheben oder nach weiteren Details fragen.
              </p>
              <p>Dein commonsplace Team</p>
            </>
          );
        case 'edit_accepted':
          return (
            <>
              <h1>Die Bearbeitung deines Projektes wurde genehmigt. </h1>
              <p>Die Veränderungen sind online und für jeden sichtbar.</p>
              <p>
                Du kannst dein Projekt weiterhin bearbeiten. Weitere
                Veränderungen müssen wir genehmigen.
              </p>
              <p>
                Doch kein Grund zur Sorge: Auch nachdem dein Projekt online
                gegangen ist, kannst du es immer noch bearbeiten. Deine
                Veränderungen müssen wir trotz alle dem zusätzlich genehmigen.
              </p>
              <p>
                Wenn bei äußerst dringenden Notfällen eine schnellere
                Bearbeitungszeit notwendig ist, kontaktiere uns bitte mit dem
                entsprechenden Grund unter{' '}
                <a href="mailto:support@commonsplace.de" rel="noreferrer">
                  support@commonsplace.de
                </a>
              </p>

              <p>Wir wünschen viel Erfolg!</p>
              <p>Dein commonsplace Team</p>
            </>
          );
        default:
          break;
      }
    };

    return (
      <>
        <ProjectFormWrapper>
          <Header relativeHeader={true} />
          <Container className="container">
            <>
              {isPending && id && (
                <LockWrapper>
                  <div className="overlay"></div>
                  <div className="text">
                    {handelOverlayText(project.status)}
                    <div className="link">
                      <Link to={`/view/${id}`}>Vorschau</Link>
                      {/* <Link>Vorschau</Link> */}

                      <button onClick={() => reEditDraft(id)}>
                        Projekt bearbeiten
                      </button>
                      <Link to={`/profile`}>Zurück zum Profil</Link>
                    </div>
                  </div>
                </LockWrapper>
              )}

              <ProjectFormFormWrapper pending={isPending}>
                <SideBar
                  handelCurrentView={handleSteps}
                  currentView={currentStep}
                  projectId={id}
                  project={project}
                  isStripeDone={isStripeDone}
                  canMove={canMove}
                  handelShowModal={this.handelShowModal}
                  setSelectedStep={this.setSelectedStep}
                  getPayPalStatus={this.state.payPalStatus}
                  setStatus={this.setHandleStatusChange}
                />
                <div className="project-form__content">
                  {handleCurrentComponent()}
                </div>
                <RightBar projectId={project.id} />
              </ProjectFormFormWrapper>
              <GotoDiskTop>
                <H1>INFO</H1>
                <p>
                  Achtung! Einzigartige Ideen benötigen Präzision und eine
                  ordentliche Bearbeitung. Bitte bearbeite dein Projekt deshalb
                  am Desktoprechner.
                </p>
              </GotoDiskTop>
            </>
          </Container>
        </ProjectFormWrapper>
        <Modal showModal={showModal} handelCloseModal={this.handelCloseModal}>
          <MoveModal>
            <p>
              Zuletzt durchgeführte Änderungen musst du speichern; diese gehen
              sonst verloren.
            </p>
            <div className="actions">
              <PrimaryButton onClick={this.handelSaveAndMove}>
                Speichern und schließen
              </PrimaryButton>
              <PrimaryButton
                onClick={() => {
                  handleSteps(selectedStep);
                  this.handelCloseModal();
                  this.setCanMove(true);
                }}
              >
                Schließen
              </PrimaryButton>
            </div>
          </MoveModal>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { project, loading, errors, currentStep } = state.projects;
  const { stripeStatus } = state.payment;
  return {
    project,
    loading,
    errors,
    currentStep,
    stripeStatus,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getStripeStatus: (projectId) =>
    dispatch(PaymentActions.getStripeStatus(projectId)),
  updateDraft: (projectId, project) =>
    dispatch(ProjectsActions.updateDraft(projectId, project)),
  // updateDraft: (projectId, project, currentStep = null) =>
  //   dispatch(ProjectsActions.updateDraft(projectId, project, currentStep)),
  uploadImages: (projectId, formDate, currentStep = null) =>
    dispatch(ProjectsActions.uploadImages(projectId, formDate, currentStep)),
  updateDraftReward: (projectId, rewardJSON, rewardImage, currentStep) =>
    dispatch(
      ProjectsActions.updateDraftReward(
        projectId,
        rewardJSON,
        rewardImage,
        currentStep
      )
    ),
  deleteDraftReward: (projectId, rewardId, currentStep) =>
    dispatch(
      ProjectsActions.deleteDraftReward(projectId, rewardId, currentStep)
    ),
  updateDraftAndImages: (
    projectId,
    projectJSON,
    projectImages,
    currentStep
  ) => {
    dispatch(
      ProjectsActions.updateDraftAndImages(
        projectId,
        projectJSON,
        projectImages,
        currentStep
      )
    );
  },
  handleSteps: (step) => dispatch(ProjectsActions.handleSteps(step)),
  reEditDraft: (projectId) => dispatch(ProjectsActions.reEditDraft(projectId)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProjectForm)
);
