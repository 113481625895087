const donationsConstants = {
  GET_PROJECT_DONATIONS_REQUEST: 'GET_PROJECT_DONATIONS_REQUEST',
  GET_RECURRING_DONATIONS_REQUEST: 'GET_RECURRING_DONATIONS_REQUEST',
  GET_RECURRING_DONATIONS_CANCEL_REQUEST: 'GET_RECURRING_DONATIONS_CANCEL_REQUEST',
  GET_PROJECT_DONATIONS_SUCCESS: 'GET_PROJECT_DONATIONS_SUCCESS',
  GET_PROJECT_DONATIONS_FAILURE: 'GET_PROJECT_DONATIONS_FAILURE',
  RECURRING_DONATIONS_FAILURE: 'RECURRING_DONATIONS_FAILURE',
  GET_MY_DONATIONS_REQUEST: 'GET_MY_DONATIONS_REQUEST',
  GET_MY_DONATIONS_SUCCESS: 'GET_MY_DONATIONS_SUCCESS',
  GET_MY_DONATIONS_FAILURE: 'GET_MY_DONATIONS_FAILURE',
  REFUND_DONATIONS_REQUEST: 'REFUND_DONATIONS_REQUEST',
  REFUND_DONATIONS_SUCCESS: 'REFUND_DONATIONS_SUCCESS',
  RECURRING_DONATIONS_CANCEL_SUCCESS: 'RECURRING_DONATIONS_CANCEL_SUCCESS',
  REFUND_DONATIONS_FAILURE: 'REFUND_DONATIONS_FAILURE',
  RECURRING_DONATIONS_CANCEL_FAILURE: 'RECURRING_DONATIONS_CANCEL_FAILURE',
  RECURRING_DONATIONS_SUCCESS: 'RECURRING_DONATIONS_SUCCESS',
  GET_PATREON_DONATIONS_REQUEST: 'GET_PATREON_DONATIONS_REQUEST',
  GET_PATREON_DONATIONS_SUCCESS: 'GET_PATREON_DONATIONS_SUCCESS',
  GET_PATREON_DONATIONS_FAILURE: 'GET_PATREON_DONATIONS_FAILURE',
};

export default donationsConstants;
