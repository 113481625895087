import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const ContainerWrapper = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;
  position: relative;
  padding: 0 20px;
  @media (max-width: 1441px) and (min-width: 950px) {
    max-width: 1120px;
    // padding: 0;
    margin: 0 auto;
  }
  @media (max-width: 767px) {
    padding: 0 20px;
    max-width: 100%;
  }
  @media (max-width: 950px) and (min-width: 768px) {
    padding: 0 20px;
    max-width: 100%;
  }
`;

export default function Container(props) {
  return <ContainerWrapper {...props}>{props.children}</ContainerWrapper>;
}

Container.propTypes = {
  children: PropTypes.any,
};
