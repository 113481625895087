import React from 'react';
import { ReloadIcon } from 'images';
import { SvgWrapper, Colors, dateFormat } from 'utils';
import styled from '@emotion/styled';
import moment from 'moment';
import PropTypes from 'prop-types';

const HoldenEditWrapper = styled.div`
  background: #f9ad00;
  padding: 20px 30px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  border-radius: 15px;
  p {
    font-weight: bold;
    font-size: 22px;
    color: ${Colors.white};
    margin: 0;
    margin-left: 15px;
  }
`;
export default function HoldenEdit({ time }) {
  return (
    <HoldenEditWrapper>
      <SvgWrapper width="50px" height="50px">
        <ReloadIcon />
      </SvgWrapper>
      <p>
        Veränderung vom {moment.unix(time).format(dateFormat)} ist in Prüfung
      </p>
    </HoldenEditWrapper>
  );
}

HoldenEdit.propTypes = {
  time: PropTypes.number.isRequired,
};
