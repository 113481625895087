import React from 'react';
import { StorageIcon, ShotLogo, Spenden } from 'images';
import { Colors, SvgWrapper, device } from 'utils';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { GiftFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const LeftSideBarWrapper = styled.aside`
  width: 290px;
  padding: 20px;
  min-height: calc(100vh - 96px);
  background: ${Colors.grey};
  @media ${device.minLaptop} {
    display: block;
    width: 150px;
    mix-width: 150px;
  }
  nav {
    position: sticky;
    top: 20px;
    ul {
      padding: 0;
      list-style: none;
      li {
        font-size: 20px;
        font-weight: bold;
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        align-items: center;
        .word {
          margin: 0 15px;
          @media ${device.minLaptop} {
            display: none;
          }
        }
        .number {
          width: 50px;
          text-align: center;
          line-height: 50px;
          color: ${Colors.white};
          border-radius: 10px;
          height: 50px;
          background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 1) 0%,
            rgba(149, 189, 81, 1) 100%
          );
        }
        .gift {
          color: #95bd51;
          font-size: 35px;
        }
      }
      .hoverable-link:hover {
        color: #95bd51;
        cursor: pointer;
      }
      .active {
        color: #95bd51;
      }
    }
  }
`;
export default function LeftSideBar({
  supportedLength,
  myProjectsLength,
  rewardsLength,
  setCurrentView,
  currentView,
}) {
  return (
    <LeftSideBarWrapper>
      <nav>
        <ul>
          <li
            className="hoverable-link"
            onClick={() => {
              setCurrentView('profile');
              setTimeout(() => {
                let offsetPosition =
                  document
                    .getElementById('started-projects')
                    .getBoundingClientRect().top +
                  window.pageYOffset -
                  220;

                window.scrollTo({
                  top: offsetPosition,
                  behavior: 'smooth',
                });
              }, 100);
            }}
          >
            <SvgWrapper width={'40px'} height={'40px'}>
              <StorageIcon />
            </SvgWrapper>
            <span className="word">Supportet</span>
            <span className="number">{supportedLength}</span>
          </li>
          <li
            className="hoverable-link"
            onClick={() => {
              setCurrentView('profile');
              setTimeout(() => {
                let offsetPosition =
                  document
                    .getElementById('supported-projects')
                    .getBoundingClientRect().top +
                  window.pageYOffset -
                  205;

                window.scrollTo({
                  top: offsetPosition,
                  behavior: 'smooth',
                });
              }, 100);
            }}
          >
            <SvgWrapper width={'40px'} height={'40px'}>
              <img src={ShotLogo} alt="crowd-funding-logo" />
            </SvgWrapper>
            <span className="word">Gestartet</span>
            <span className="number">{myProjectsLength}</span>
          </li>

          <li
            className="hoverable-link"
            onClick={() => {
              setCurrentView('profile');
              setTimeout(() => {
                let offsetPosition =
                  document
                    .getElementById('supported-projects')
                    .getBoundingClientRect().top +
                  window.pageYOffset -
                  205;

                window.scrollTo({
                  top: offsetPosition,
                  behavior: 'smooth',
                });
              }, 100);
            }}
          >
            <SvgWrapper width={'40px'} height={'40px'}>
              <img src={Spenden} alt="Spenden" />
            </SvgWrapper>
            <span className="word" style={{paddingLeft:'20px'}}>
              <Link to="/recurring-payments">Wiederkehrende Unterstützungen</Link>
            </span>
          </li>
          {/* <li
            onClick={() => setCurrentView('rewards')}
            className={
              currentView === 'rewards'
                ? 'hoverable-link active'
                : 'hoverable-link'
            }
          >
            <SvgWrapper width={'40px'} height={'40px'}>
              <GiftFilled className="gift" />
            </SvgWrapper>
            <span className="word">Goodies</span>
            <span className="number">{rewardsLength}</span>
          </li> */}
        </ul>
      </nav>
    </LeftSideBarWrapper>
  );
}

LeftSideBar.propTypes = {
  supportedLength: PropTypes.number,
  myProjectsLength: PropTypes.number,
  rewardsLength: PropTypes.number,
  setCurrentView: PropTypes.func.isRequired,
  currentView: PropTypes.string.isRequired,
};
