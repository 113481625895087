import moment from 'moment';
const generateProjectObject = (project) => {
  const isBasicInfoDone =
    project.title &&
      project.subtitle &&
      project.url &&
      project.category_id &&
      project.project_type
      ? true
      : false;
  const isProjectDescriptionDone =
    project.description && project.faq && project.faq.length === 5
      ? true
      : false;
  const isAboutUsDone =
    project.initiator_email &&
      project.initiator_first_name &&
      project.initiator_last_name &&
      project.initiator_birthday &&
      project.initiator_phone_number &&
      project.initiator_street &&
      project.initiator_building_number &&
      project.initiator_zip_code &&
      project.initiator_city &&
      project.initiator_country &&
      project.initiator_nationality
      ? true
      : false;
  const isContractDocumentsDone =
    project.terms_1 && project.terms_2 && project.terms_3 && project.terms_4;

  // project.account_holder && project.iban ? true : false;

  const isRunTimeDone = project.funding_days ? true : false;
  const isFundingTargetDone =
    project.goal && project.goal_explanation ? true : false;
  const isNetWorkDone =
    project.facebook_url && project.instagram_url && project.website_url
      ? true
      : false;
  // const isMediaDone =
  //   // project.banner &&
  //   project.images && project.images.length > 0 && project.outer_image
  //     ? true
  //     : false;
  const isMediaDone =
    project?.outer_image && project?.images?.length
      ? true
      : false;
  const basicInfoPercentage = isBasicInfoDone ? 20 : 0;
  const projectDescriptionPercentage = isProjectDescriptionDone ? 20 : 0;
  const runTimePercentage = isRunTimeDone ? 15 : 0;
  const fundingTargetPercentage = isFundingTargetDone ? 15 : 0;
  const videoPercentage = isMediaDone ? 10 : 0;
  const aboutUsPercentage = isAboutUsDone ? 10 : 0;
  const networkPercentage = isNetWorkDone ? 0 : 0;
  const contractDocumentsPercentage = isContractDocumentsDone ? 5 : 0;
  const isLegitimationDone = project.stripe_status === 'enabled_and_eventually_due' ? true : false;
  const projectPercentage =
    basicInfoPercentage +
    projectDescriptionPercentage +
    runTimePercentage +
    fundingTargetPercentage +
    videoPercentage +
    aboutUsPercentage +
    networkPercentage +
    contractDocumentsPercentage;

  const canSubmit =
    isBasicInfoDone &&
    isProjectDescriptionDone &&
    isAboutUsDone &&
    isContractDocumentsDone &&
    isRunTimeDone &&
    isFundingTargetDone &&
    isMediaDone && isLegitimationDone;
  const projectObject = {
    id: project.id,
    status: project.status,
    stripeStatus: project.stripe_status,
    percentage: projectPercentage,
    canSubmit: canSubmit,
    basicInfo: {
      title: project.title || null,
      subtitle: project.subtitle || null,
      url: project.url || null,
      tags: project.tags || null,
      city: project.city || null,
      category_id: project.category_id || null,
      project_type: project.project_type || null,
      isDone: isBasicInfoDone,
      percentage: basicInfoPercentage,
    },
    projectDescription: {
      description: project.description || null,
      realization_start_date: [
        project.realization_start_date
          ? moment.unix(Number(project.realization_start_date))
          : null,
        project.realization_end_date
          ? moment.unix(Number(project.realization_end_date))
          : null,
      ],
      faq: project.faq || [],
      projekt:
        (project.faq && project.faq.length >= 0 && project.faq[0]) || null,
      zielgruppe:
        (project.faq && project.faq.length >= 1 && project.faq[1]) || null,
      unterstützen:
        (project.faq && project.faq.length >= 2 && project.faq[2]) || null,
      finanzierung:
        (project.faq && project.faq.length >= 3 && project.faq[3]) || null,
      hinter:
        (project.faq && project.faq.length >= 4 && project.faq[4]) || null,
      isDone: isProjectDescriptionDone,
      percentage: projectDescriptionPercentage,
    },

    runTime: {
      funding_days: project.funding_days || null,
      isDone: isRunTimeDone,
      percentage: runTimePercentage,
    },

    fundingTarget: {
      goal: project.goal || null,
      final_goal: project.final_goal || null,
      goal_explanation: project.goal_explanation || null,
      bill_possible: project.bill_possible ? true : false,
      donation_receipts_possible: project.donation_receipts_possible
        ? true
        : false,
      isDone: isFundingTargetDone,
      percentage: fundingTargetPercentage,
      is_ticketable: project.is_ticketable || false
    },
    media: {
      video: project.video || null,
      banner: project.banner ? [project.banner] : null,
      images: project.images ? project.images : [],
      outer_image: project.outer_image ? [project.outer_image] : null,
      mobile_image: project.mobile_image ? [project.mobile_image] : null,
      mobile_array_images: project.mobile_array_images ? project.mobile_array_images : [],
      isDone: isMediaDone,
      percentage: videoPercentage,
    },
    aboutYou: {
      initiator_email: project.initiator_email || null,
      initiator_first_name: project.initiator_first_name || null,
      initiator_last_name: project.initiator_last_name || null,
      initiator_birthday: project.initiator_birthday
        ? moment(project.initiator_birthday)
        : null,
      initiator_phone_number: project.initiator_phone_number || null,
      street: project.initiator_street || null,
      building_number: project.initiator_building_number || null,
      postcode: project.initiator_zip_code || null,
      city: project.initiator_city || null,
      country: project.initiator_country || null,
      initiator_nationality: project.initiator_nationality || null,
      isDone: isAboutUsDone,
      percentage: aboutUsPercentage,
      learned_about: project.learned_about,
    },
    netWork: {
      facebook_url: project.facebook_url || null,
      instagram_url: project.instagram_url || null,
      website_url: project.website_url || null,
      isDone: true,
      percentage: networkPercentage,
    },
    contractDocuments: {
      account_holder: project.account_holder || null,
      iban: project.iban || null,
      bic: project.bic || null,
      terms: project.terms_1,
      guidelines: project.terms_2,
      bestätige: project.terms_3,
      myDate: project.terms_4,
      isDone: isContractDocumentsDone,
      percentage: contractDocumentsPercentage,
      paypal_check: project?.paypal_check,
      paypal_email: project.paypal_email,
    },
    // Seif Added
    rewards: project.rewards || [],
  };
  return projectObject;
};

export default generateProjectObject;
