import React, { Component } from 'react';
import styled from '@emotion/styled';
import { Colors } from 'utils';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { authActions } from 'redux/actions';

const RightSideBarWrapper = styled.aside`
  position: absolute;
  right: 0;
  width: 80%;
  padding: 20px;
  min-height: 100vh;
  background: ${Colors.grey};
  text-align: center;
  z-index: 1001;
  margin-top: -179px;
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: sticky;
    top: 20px;
    min-height: calc(100vh - 116px);
  }

  button {
    display: block;
    border: 1px solid ${Colors.GreenColor};
    background: ${Colors.GreenColor};
    color: ${Colors.white};
    text-align: center;
    border-radius: 12px;
    cursor: pointer;
    margin-bottom: 20px;
    padding: 15px 20px;
    min-width: 250px;
    font-size: 18px;
    &:hover {
      color: ${Colors.GreenColor};
      background: ${Colors.transparent};
    }
  }
  ul {
    padding: 0;
    list-style: none;
    li {
      button {
        display: block;
        background: ${Colors.transparent};
        border: 1px solid ${Colors.GreenColor};
        color: ${Colors.GreenColor};
        text-align: center;
        border-radius: 12px;
        cursor: pointer;
        margin-bottom: 20px;
        padding: 15px 20px;
        min-width: 250px;
        font-size: 18px;
        &.active,
        &:hover {
          background: ${Colors.GreenColor};
          color: ${Colors.white};
        }
      }
    }
  }
  .setting-list {
    padding-left: 50px;
    button {
      min-width: 200px;
    }
  }
`;
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #000;
  opacity: 0.5;
  z-index: 1000;
  overflow-y: hidden;
`;
class RightSideBar extends Component {
  static propTypes = {
    setCurrentView: PropTypes.func.isRequired,
    currentView: PropTypes.string.isRequired,
    logout: PropTypes.func.isRequired,
    handleCloseMenu: PropTypes.func.isRequired,
  };
  state = {
    showSettingList: false,
  };
  render() {
    const { showSettingList } = this.state;
    const { setCurrentView, currentView, logout, handleCloseMenu } = this.props;
    return (
      <>
        <Overlay onClick={handleCloseMenu} />
        <RightSideBarWrapper>
          <div>
            <div>
              <nav>
                <ul>
                  <li>
                    <button
                      onClick={() => setCurrentView('profile')}
                      htmltype="button"
                      className={currentView === 'profile' ? 'active' : ''}
                    >
                      Profil
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => setCurrentView('projects')}
                      htmltype="button"
                      className={currentView === 'projects' ? 'active' : ''}
                    >
                      Eigene Projekte
                    </button>
                  </li>
                  <li>
                    <button
                      htmltype="button"
                      onClick={() =>
                        this.setState({ showSettingList: !showSettingList })
                      }
                    >
                      Einstellungen
                    </button>
                    {(showSettingList ||
                      [
                        'userInfo',
                        'resetPassword',
                        'resetEmail',
                        'suppers',
                        'payment',
                      ].includes(currentView)) && (
                      <ul className="setting-list">
                        <li>
                          <button
                            onClick={() => setCurrentView('userInfo')}
                            htmltype="button"
                            className={
                              currentView === 'userInfo' ? 'active' : ''
                            }
                          >
                            Persönliche Daten
                          </button>
                        </li>
                        <li>
                          <button
                            onClick={() => setCurrentView('resetPassword')}
                            htmltype="button"
                            className={
                              currentView === 'resetPassword' ? 'active' : ''
                            }
                          >
                            Passwort ändern
                          </button>
                        </li>
                        <li>
                          <button
                            onClick={() => setCurrentView('resetEmail')}
                            htmltype="button"
                            className={
                              currentView === 'resetEmail' ? 'active' : ''
                            }
                          >
                            E-Mail ändern
                          </button>
                        </li>
                        <li>
                          <button
                            onClick={() => setCurrentView('suppers')}
                            htmltype="button"
                            className={
                              currentView === 'suppers' ? 'active' : ''
                            }
                          >
                            Unterstützungen
                          </button>
                        </li>
                        {/* <li>
                          <button
                            onClick={() => setCurrentView('payment')}
                            htmltype="button"
                            className={
                              currentView === 'payment' ? 'active' : ''
                            }
                          >
                            Zahlungsmethoden
                          </button>
                        </li> */}
                      </ul>
                    )}
                  </li>
                  <li>
                    <button
                      onClick={() => setCurrentView('rewards')}
                      htmltype="button"
                      className={currentView === 'rewards' ? 'active' : ''}
                    >
                      Goodies
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
            <div>
              <button htmltype="button" onClick={() => logout()}>
                Abmelden
              </button>
            </div>
          </div>
        </RightSideBarWrapper>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { user } = state.authentication;
  return {
    user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(authActions.logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RightSideBar);
