import { paymentServices } from 'services';
import { AlertActions } from 'redux/actions';
import paymentConstants from './PaymentTypes';

const getMyCards = () => {
  return (dispatch) => {
    dispatch(getMyCardsRequest());
    paymentServices
      .getMyCards()
      .then((res) => {
        return dispatch(getMyCardsSuccess(res.cards));
      })
      .catch((error) => {
        error.errors.map((err) => dispatch(AlertActions.error(err)));
        return dispatch(getMyCardsFailure(error.errors));
      });
  };
};

const getMyCardsRequest = () => ({
  type: paymentConstants.GET_MY_CARDS_REQUEST,
});
const getMyCardsSuccess = (cards) => ({
  type: paymentConstants.GET_MY_CARDS_SUCCESS,
  cards,
});
const getMyCardsFailure = (error) => ({
  type: paymentConstants.GET_MY_CARDS_FAILURE,
  error,
});

const prepareCard = async (user) => {
  return (dispatch) => {
    dispatch(prepareCardRequest());
    paymentServices
      .prepareCard(user)
      .then((res) => {
        return dispatch(prepareCardSuccess({ ...res.card, email: user.email }));
      })
      .catch((error) => {
        error.errors.map((err) => dispatch(AlertActions.error(err)));
        return dispatch(prepareCardFailure(error.errors));
      });
  };
};

const prepareCardRequest = () => ({
  type: paymentConstants.PREPARE_CARD_REQUEST,
});
const prepareCardSuccess = (card) => ({
  type: paymentConstants.PREPARE_CARD_SUCCESS,
  card,
});
const prepareCardFailure = (error) => ({
  type: paymentConstants.PREPARE_CARD_FAILURE,
  error,
});

const addCard = (card) => {
  return (dispatch) => {
    dispatch(addCardRequest());
    paymentServices
      .getToken(card)
      .then((res) => {
        paymentServices
          .saveCard(res.card, card.Id)
          .then((res) => {
            dispatch(AlertActions.success('Your Card has been Added'));
            return dispatch(
              addCardSuccess({
                ...res.card,
                Alias: card.number,
                Id: res.card.CardId,
              })
            );
          })
          .catch((error) => {
            error.errors.map((err) => dispatch(AlertActions.error(err)));
            return dispatch(addCardFailure(error.errors));
          });
      })
      .catch((error) => {
        error.errors.map((err) => dispatch(AlertActions.error(err)));
        return dispatch(addCardFailure(error.errors));
      });
  };
};

const addCardRequest = () => ({
  type: paymentConstants.ADD_CARD_REQUEST,
});
const addCardSuccess = (card) => ({
  type: paymentConstants.ADD_CARD_SUCCESS,
  card,
});
const addCardFailure = (error) => ({
  type: paymentConstants.ADD_CARD_FAILURE,
  error,
});

const deleteCard = (cardId) => {
  return (dispatch) => {
    dispatch(deleteCardRequest());
    paymentServices
      .deleteCard(cardId)
      .then(() => {
        dispatch(AlertActions.success('Your Card has been Deleted'));
        return dispatch(deleteCardSuccess(cardId));
      })
      .catch((error) => {
        error.errors.map((err) => dispatch(AlertActions.error(err)));
        return dispatch(deleteCardFailure(error.errors));
      });
  };
};

const deleteCardRequest = () => ({
  type: paymentConstants.DELETE_CARD_REQUEST,
});
const deleteCardSuccess = (cardId) => ({
  type: paymentConstants.DELETE_CARD_SUCCESS,
  cardId,
});
const deleteCardFailure = (error) => ({
  type: paymentConstants.DELETE_CARD_FAILURE,
  error,
});

const makeDonation = (donation) => {
  return (dispatch) => {
    dispatch(makeDonationRequest());
    paymentServices
      .makeDonation(donation)
      .then(async (res) => {
        return dispatch(makeDonationSuccess(res));
      })
      .catch((error) => {
        error.errors.map((err) => dispatch(AlertActions.error(err)));
        return dispatch(makeDonationFailure(error.errors));
      });
  };
};

const makeDonationRequest = () => ({
  type: paymentConstants.MAKE_DONATION_REQUEST,
});
const makeDonationSuccess = (donation) => ({
  type: paymentConstants.MAKE_DONATION_SUCCESS,
  donation,
});
const makeDonationFailure = (error) => ({
  type: paymentConstants.MAKE_DONATION_FAILURE,
  error,
});

const setUpStripeAccountURL = (projectId) => {
  return (dispatch) => {
    dispatch(setUpStripeAccountURLRequest());
    paymentServices
      .setUpStripeAccountURL(projectId)
      .then((res) => {
        window.open(res.url.url, '_self');
        return dispatch(setUpStripeAccountURLSuccess(res.url));
      })
      .catch((error) => {
        error.errors.map((err) => dispatch(AlertActions.error(err)));
        return dispatch(setUpStripeAccountURLFailure(error.errors));
      });
  };
};

const setUpStripeAccountURLRequest = () => ({
  type: paymentConstants.GET_STRIPE_URL_REQUEST,
});
const setUpStripeAccountURLSuccess = (url) => ({
  type: paymentConstants.GET_STRIPE_URL_SUCCESS,
  url,
});
const setUpStripeAccountURLFailure = (error) => ({
  type: paymentConstants.GET_STRIPE_URL_FAILURE,
  error,
});

const getStripeStatus = (projectId) => {
  return (dispatch) => {
    dispatch(getStripeStatusRequest());
    paymentServices
      .getStripeStatus(projectId)
      .then((res) => {
        return dispatch(getStripeStatusSuccess(res.status.status));
      })
      .catch((error) => {
        error.errors.map((err) => dispatch(AlertActions.error(err)));
        return dispatch(getStripeStatusFailure(error.errors));
      });
  };
};

const getStripeStatusRequest = () => ({
  type: paymentConstants.GET_STRIPE_STATUS_REQUEST,
});
const getStripeStatusSuccess = (status) => ({
  type: paymentConstants.GET_STRIPE_STATUS_SUCCESS,
  status,
});
const getStripeStatusFailure = (error) => ({
  type: paymentConstants.GET_STRIPE_STATUS_FAILURE,
  error,
});

const setCard = (cardId) => {
  return (dispatch) => {
    return dispatch(setCardSuccess(cardId));
  };
};
const setCardSuccess = (cardId) => ({
  type: paymentConstants.SET_CARD,
  cardId,
});
const resetDonation = () => {
  return (dispatch) => {
    return dispatch(resetDonationSuccess());
  };
};
const resetDonationSuccess = () => ({
  type: paymentConstants.REST_DONATION,
});

const PaymentActions = {
  getMyCards,
  prepareCard,
  addCard,
  deleteCard,
  setCard,
  makeDonation,
  resetDonation,
  setUpStripeAccountURL,
  getStripeStatus,
};

export default PaymentActions;
