import React, { Component } from 'react';
import { H1, Container } from 'utils';
import { store } from 'react-notifications-component';
import { Layout } from 'components';
const notification = {
  insert: 'top',
  container: 'top-right',
  animationIn: ['animate__animated animate__fadeIn'], // `animate.css v4` classes
  animationOut: ['animate__animated animate__fadeOut'], // `animate.css v4` classes
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
};

export default class NewsletterPage extends Component {
  async componentDidMount() {
    const newsLetter = await this.fetchNewsLetter();
    this.setState({ newsLetter: newsLetter.res });
  }
  state = {
    newsLetter: null,
  };
  fetchNewsLetter = () => {
    const requestOptions = {
      method: 'GET',
      redirect: 'follow',
    };
    return fetch(`newsletter`, requestOptions)
      .then((res) => {
        return { res };
      })
      .catch((error) => {
        store.addNotification({
          ...notification,
          title: 'Fehler!',
          type: 'danger',
          message: error,
        });
        return Promise.reject(error);
      });
  };
  render() {
    const { newsLetter } = this.state;

    return (
      <Layout>
        <Container>
          <>
            <H1>News letter</H1>
            {newsLetter &&
              newsLetter.length > 0 &&
              newsLetter.map((email, index) => (
                <p key={`${email}-${index}`}>{email}</p>
              ))}
          </>
        </Container>
      </Layout>
    );
  }
}
