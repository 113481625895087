import fetchIntercept from 'fetch-intercept';
import { IsJsonString } from 'utils';
import config from 'config';
const API_URL = config.REACT_APP_API_URL;

const unregister = fetchIntercept.register({
  request: (url, config) => {
    // Modify the url or config here
    const check = url.startsWith('https') || url.startsWith('http');
    const user = JSON.parse(localStorage.getItem('user'));
    const isPayment = url.startsWith(
      'https://homologation-webpayment.payline.com'
    );
    if (!check) {
      const myHeaders = new Headers();
      if (typeof config.body === 'string') {
        myHeaders.append('Content-Type', 'application/json');
      }
      if (user && user.uid) {
        myHeaders.append('access-token', localStorage.getItem('access-token'));
        myHeaders.append('client', localStorage.getItem('client'));
        myHeaders.append('uid', localStorage.getItem('uid'));
      }
      if (config.headers && config.headers.client !== '') {
        myHeaders.append('access-token', config.headers['access-token']);
        myHeaders.append('client', config.headers.client);
        myHeaders.append('uid', config.headers.uid);
      }
      config.headers = myHeaders;
    } else if (check && isPayment) {
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
    }
    url = check ? url : `${API_URL}${url}`;

    return [url, config];
  },

  requestError: (error) => {
    // Called when an error occured during another 'request' interceptor call
    return Promise.reject(error);
  },

  response: (response) => {
    if (response.headers.get('client')) {
      localStorage.setItem('client', response.headers.get('client'));
    }
    if (response.headers.get('access-token')) {
      localStorage.setItem(
        'access-token',
        response.headers.get('access-token')
      );
    }
    if (response.headers.get('uid')) {
      localStorage.setItem('uid', response.headers.get('uid'));
    }
    if (
      response.url.startsWith(
        'https://api.commonsplace.de/rails/active_storage'
      )
    ) {
      return response;
    }

    return response.text().then((text) => {
      const data = text && IsJsonString(text) ? JSON.parse(text) : text;
      if (!response.ok) {
        let error;

        switch (response.status) {
          case 401:
            error = data.errors;
            logout();
            break;
          case 12163:
            error = 'Please check you internet connection!';
            break;
          case 400:
            error = data.errors || response.statusText;
            break;
          case 422:
            error =
              data.errors?.full_messages || data.errors || data.error || response.statusText;
            break;
          case 403:
            error = data.errors || response.statusText;
            break;
          case 500:
            error =
              { full_messages: ['Internal Server Error'] } ||
              response.statusText;
            break;
          default:
            error = data.errors || response.statusText;
            break;
        }
        return Promise.reject(error);
      }
      if (!IsJsonString(text) && data.startsWith('error')) {
        return Promise.reject(data);
      }
      return data || response;
    });
  },

  responseError: (error) => {
    // Handle an fetch error
    return Promise.reject(error);
  },
});

export default unregister;
const logout = () => {
  // remove user from local storage to log user out
  localStorage.removeItem('user');
  localStorage.removeItem('access-token');
  localStorage.removeItem('client');
  localStorage.removeItem('uid');
  localStorage.removeItem('user-type');
};
