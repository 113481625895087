import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Colors } from 'utils';
import device from './device';

const Sub = styled.p`
  margin: 10px 0 0;
  font-size: 22px;
  max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}` : '500px')};
  color: ${(props) => (props.color ? `${props.color}` : Colors.white)};
  @media ${device.allMobile} {
    font-size: 16px;
  }
`;
export default function SubTitle(props) {
  return <Sub {...props}>{props.children}</Sub>;
}

SubTitle.propTypes = {
  children: PropTypes.string,
  color: PropTypes.string,
  maxWidth: PropTypes.string,
};
