const adminConstants = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  SET_PROJECTS_HOME_REQUEST: 'SET_PROJECTS_HOME_REQUEST',
  SET_PROJECTS_HOME_SUCCESS: 'SET_PROJECTS_HOME_SUCCESS',
  SET_PROJECTS_HOME_FAILURE: 'SET_PROJECTS_HOME_FAILURE',
  GET_ALL_PROJECTS_REQUEST: 'GET_ALL_PROJECTS_REQUEST',
  GET_ALL_PROJECTS_SUCCESS: 'GET_ALL_PROJECTS_SUCCESS',
  GET_ALL_PROJECTS_FAILURE: 'GET_ALL_PROJECTS_FAILURE',
  GET_ALL_INVOICES_REQUEST: 'GET_ALL_INVOICES_REQUEST',
  GET_ALL_INVOICES_SUCCESS: 'GET_ALL_INVOICES_SUCCESS',
  GET_ALL_INVOICES_FAILURE: 'GET_ALL_INVOICES_FAILURE',
  SHOW_PROJECT_REQUEST: 'SHOW_PROJECT_REQUEST',
  SHOW_PROJECT_SUCCESS: 'SHOW_PROJECT_SUCCESS',
  SHOW_PROJECT_FAILURE: 'SHOW_PROJECT_FAILURE',
  ACCEPT_PROJECT_REQUEST: 'ACCEPT_PROJECT_REQUEST',
  ACCEPT_PROJECT_SUCCESS: 'ACCEPT_PROJECT_SUCCESS',
  ACCEPT_PROJECT_FAILURE: 'ACCEPT_PROJECT_FAILURE',
  DECLINE_PROJECT_REQUEST: 'DECLINE_PROJECT_REQUEST',
  DECLINE_PROJECT_SUCCESS: 'DECLINE_PROJECT_SUCCESS',
  DECLINE_PROJECT_FAILURE: 'DECLINE_PROJECT_FAILURE',
  UPDATE_PROJECT_REQUEST: 'UPDATE_PROJECT_REQUEST',
  UPDATE_PROJECT_SUCCESS: 'UPDATE_PROJECT_SUCCESS',
  UPDATE_PROJECT_FAILURE: 'UPDATE_PROJECT_FAILURE',
  START_PROJECT_REQUEST: 'START_PROJECT_REQUEST',
  START_PROJECT_SUCCESS: 'START_PROJECT_SUCCESS',
  START_PROJECT_FAILURE: 'START_PROJECT_FAILURE',
  GET_ALL_BOOK_GOODIES_REQUEST: 'GET_ALL_BOOK_GOODIES_REQUEST',
  GET_ALL_BOOK_GOODIES_SUCCESS: 'GET_ALL_BOOK_GOODIES_SUCCESS',
  GET_ALL_BOOK_GOODIES_FAILURE: 'GET_ALL_BOOK_GOODIES_FAILURE',
  LOGOUT: 'LOGOUT',
  GET_ADMIN_RECURRING_DONATIONS_REQUEST: 'GET_ADMIN_RECURRING_DONATIONS_REQUEST',
  ADMIN_RECURRING_DONATIONS_SUCCESS: 'ADMIN_RECURRING_DONATIONS_SUCCESS',
  ADMIN_RECURRING_DONATIONS_FAILURE: 'ADMIN_RECURRING_DONATIONS_FAILURE',
  ADMIN_RECURRING_DONATIONS_CANCEL_REQUEST: 'ADMIN_RECURRING_DONATIONS_CANCEL_REQUEST',
  ADMIN_RECURRING_DONATIONS_CANCEL_SUCCESS: 'ADMIN_RECURRING_DONATIONS_CANCEL_SUCCESS',
  ADMIN_RECURRING_DONATIONS_CANCEL_FAILURE: 'ADMIN_RECURRING_DONATIONS_CANCEL_FAILURE',
};

export default adminConstants;
