import { authConstants } from 'redux/actions/types';
import moment from 'moment';
let user = JSON.parse(localStorage.getItem('user'));
const initialState = user
  ? { loading: false, user, errors: [], singUpSuccess: false }
  : { loading: false, user: {}, errors: [], singUpSuccess: false };

function authentication(state = initialState, action) {
  switch (action.type) {
    case authConstants.LOGIN_REQUEST:
      return {
        ...state,
        errors: [],
        loading: true,
      };
    case authConstants.LOGIN_SUCCESS:
      return {
        ...state,
        errors: [],
        loading: false,
        user: action.user,
      };
    case authConstants.LOGIN_FAILURE:
      return {
        loading: false,
        errors: action.error,
      };
    case authConstants.SIGN_UP_REQUEST:
      return {
        loading: true,
        errors: [],
      };
    case authConstants.SIGN_UP_SUCCESS:
      return {
        loading: false,
        errors: [],
        singUpSuccess: true,
      };
    case authConstants.SIGN_UP_FAILURE:
      return {
        loading: false,
        errors: action.error,
      };
    case authConstants.UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        errors: [],
        loading: true,
      };
    case authConstants.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        errors: [],
        loading: false,
        user: {
          user: {
            ...action.user,
            birthday: action.user.birthday
              ? moment(action.user.birthday)
              : null,
          },
        },
      };
    case authConstants.UPDATE_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.error,
      };
    case authConstants.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        errors: [],
        loading: true,
      };
    case authConstants.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        errors: [],
        loading: false,
        user: {
          user: {
            ...action.user,
          },
        },
      };
    case authConstants.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.error,
      };
    case authConstants.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        errors: [],
        loading: true,
      };
    case authConstants.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        errors: [],
        loading: false,
        user: action.user,
      };
    case authConstants.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.error,
      };
    case authConstants.LOGOUT:
      return {
        ...state,
        loading: false,
        user: {},
      };
    default:
      return state;
  }
}

export default authentication;
