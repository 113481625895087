import styled from '@emotion/styled';
import { Colors } from 'utils';
import PropTypes from 'prop-types';
import device from './device';
const GreenButton = styled.button`
  border-bottom: none;
  font-weight: bold;
  background: ${Colors.GreenColor};
  border: 1px solid ${Colors.GreenColor};
  color: ${Colors.white};
  text-align: center;
  border-radius: 12px;
  cursor: pointer;
  margin-bottom: 20px;
  padding: 15px 20px;
  min-width: ${(props) => (props.minWidth ? props.minWidth : '200px')};
  font-size: 22px;
  @media ${device.laptop} {
    font-size: 18px;
  }
  &:hover {
    background: ${Colors.white};
    color: ${Colors.GreenColor};
  }
  &:disabled {
    background: ${Colors.steel};
    border: ${Colors.steel};
    color: ${Colors.grey};
    cursor: not-allowed;
  }
`;

export default GreenButton;

GreenButton.propTypes = {
  minWidth: PropTypes.string,
};
