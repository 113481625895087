import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PaymentActions } from 'redux/actions';
import InfoCardForm from './InfoCardForm';
import { PaymentCard } from 'components';
import styled from '@emotion/styled';
import { IsLoggedIn } from 'utils';

const AddCardWrapper = styled.div`
  width: 550px;
`;

export class AddCard extends Component {
  static propTypes = {
    prepareCard: PropTypes.func.isRequired,
    card: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    errors: PropTypes.array.isRequired,
    closeModal: PropTypes.func.isRequired,
  };

  state = {
    currentView: IsLoggedIn() ? 'card' : 'info',
  };

  componentDidMount() {
    const { prepareCard } = this.props;

    if (IsLoggedIn()) {
      return prepareCard({ user: true });
    } else {
      return;
    }
  }

  setCurrentView = (currentView) => this.setState({ currentView });

  render() {
    const { currentView } = this.state;
    const { closeModal } = this.props;

    return (
      <AddCardWrapper>
        {currentView === 'info' ? (
          <InfoCardForm setCurrentView={this.setCurrentView} />
        ) : (
          <PaymentCard closeModal={closeModal} />
        )}
      </AddCardWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  const { card, loading, errors } = state.payment;
  return {
    card,
    loading,
    errors,
  };
};

const mapDispatchToProps = (dispatch) => ({
  prepareCard: (user) => dispatch(PaymentActions.prepareCard(user)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AddCard);
