/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { Colors } from 'utils';

const TimeIcon = (props) => {
  const { color, width, height } = props;
  return (
    <svg
      {...props}
      height={`${height || '512'}px`}
      width={`${width || '512'}px`}
      viewBox="0 0 512 512"
      css={css`
        fill: ${color || Colors.GreenColor};
        max-width: ${width || '100%'};
        max-height: ${height || '100%'};
        height: ${height || '100%'};
        width: ${width || '100%'};
        display: inline-block;
        enable-background: new 0 0 512 512;
      `}
    >
      <g>
        <g xmlns="http://www.w3.org/2000/svg">
          <g>
            <path
              d="M497.003,241.001c-8.284,0-15,6.716-15,15c0,124.617-101.384,226-226,226c-124.617,0-226-101.383-226-226    s101.383-226,226-226c37.999,0,74.962,9.435,107.959,27.413l-18.753,18.753c-4.29,4.29-5.573,10.741-3.252,16.347    c2.322,5.605,7.791,9.26,13.858,9.26h71.773c8.284,0,15-6.716,15-15V15.001c0-6.067-3.654-11.536-9.26-13.858    c-5.607-2.323-12.058-1.039-16.347,3.252l-31.017,31.017c-39.289-23.197-83.959-35.41-129.962-35.41    c-68.38,0-132.668,26.629-181.02,74.98c-48.352,48.353-74.98,112.64-74.98,181.02s26.628,132.667,74.98,181.019    c48.353,48.353,112.64,74.982,181.02,74.982s132.667-26.629,181.019-74.982c48.353-48.352,74.98-112.639,74.98-181.019    C512.003,247.717,505.287,241.001,497.003,241.001z"
              fill={color || Colors.GreenColor}
              data-original="#000000"
            />
          </g>
        </g>
        <g xmlns="http://www.w3.org/2000/svg">
          <g>
            <path
              d="M352.402,241.001h-81.399v-81.4c0-8.284-6.716-15-15-15s-15,6.716-15,15v96.4c0,8.284,6.716,15,15,15h96.399    c8.284,0,15-6.716,15-15S360.686,241.001,352.402,241.001z"
              fill={color || Colors.GreenColor}
              data-original="#000000"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default TimeIcon;

TimeIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};
