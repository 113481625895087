const projectsConstants = {
  GET_MY_PROJECTS_REQUEST: 'GET_MY_PROJECTS_REQUEST',
  GET_MY_PROJECTS_SUCCESS: 'GET_MY_PROJECTS_SUCCESS',
  GET_MY_PROJECTS_FAILURE: 'GET_MY_PROJECTS_FAILURE',
  GET_PROJECTS_REQUEST: 'GET_PROJECTS_REQUEST',
  GET_PROJECTS_SUCCESS: 'GET_PROJECTS_SUCCESS',
  GET_PROJECTS_FAILURE: 'GET_PROJECTS_FAILURE',
  GET_EVENT_PROJECTS_REQUEST: 'GET_EVENT_PROJECTS_REQUEST',
  GET_EVENT_PROJECTS_SUCCESS: 'GET_EVENT_PROJECTS_SUCCESS',
  GET_EVENT_PROJECTS_FAILURE: 'GET_EVENT_PROJECTS_FAILURE',
  GET_SUCCESSFULL_EVENT_PROJECTS_REQUEST:
    'GET_SUCCESSFULL_EVENT_PROJECTS_REQUEST',
  GET_SUCCESSFULL_EVENT_PROJECTS_SUCCESS:
    'GET_SUCCESSFULL_EVENT_PROJECTS_SUCCESS',
  GET_SUCCESSFULL_EVENT_PROJECTS_FAILURE:
    'GET_SUCCESSFULL_EVENT_PROJECTS_FAILURE',
  GET_RANDOM_PROJECTS_REQUEST: 'GET_RANDOM_PROJECTS_REQUEST',
  GET_RANDOM_PROJECTS_SUCCESS: 'GET_RANDOM_PROJECTS_SUCCESS',
  GET_RANDOM_PROJECTS_FAILURE: 'GET_RANDOM_PROJECTS_FAILURE',
  GET_SUCCESSFUL_PROJECTS_REQUEST: 'GET_SUCCESSFUL_PROJECTS_REQUEST',
  GET_SUCCESSFUL_PROJECTS_SUCCESS: 'GET_SUCCESSFUL_PROJECTS_SUCCESS',
  GET_SUCCESSFUL_PROJECTS_FAILURE: 'GET_SUCCESSFUL_PROJECTS_FAILURE',
  GET_HOME_PROJECTS_REQUEST: 'GET_HOME_PROJECTS_REQUEST',
  GET_HOME_PROJECTS_SUCCESS: 'GET_HOME_PROJECTS_SUCCESS',
  GET_HOME_PROJECTS_FAILURE: 'GET_HOME_PROJECTS_FAILURE',
  GET_MY_SUPPORTED_PROJECTS_REQUEST: 'GET_MY_SUPPORTED_PROJECTS_REQUEST',
  GET_MY_SUPPORTED_PROJECTS_SUCCESS: 'GET_MY_SUPPORTED_PROJECTS_SUCCESS',
  GET_MY_SUPPORTED_PROJECTS_FAILURE: 'GET_MY_SUPPORTED_PROJECTS_FAILURE',
  GET_MY_DONATIONS_REQUEST: 'GET_MY_DONATIONS_REQUEST',
  GET_MY_DONATIONS_SUCCESS: 'GET_MY_DONATIONS_SUCCESS',
  GET_MY_DONATIONS_FAILURE: 'GET_MY_DONATIONS_FAILURE',
  SHOW_PROJECT_REQUEST: 'SHOW_PROJECT_REQUEST',
  SHOW_PROJECT_SUCCESS: 'SHOW_PROJECT_SUCCESS',
  SHOW_PROJECT_FAILURE: 'SHOW_PROJECT_FAILURE',
  CREATE_DRAFT_REQUEST: 'CREATE_DRAFT_REQUEST',
  CREATE_DRAFT_SUCCESS: 'CREATE_DRAFT_SUCCESS',
  CREATE_DRAFT_FAILURE: 'CREATE_DRAFT_FAILURE',
  UPDATE_DRAFT_REQUEST: 'UPDATE_DRAFT_REQUEST',
  UPDATE_DRAFT_SUCCESS: 'UPDATE_DRAFT_SUCCESS',
  UPDATE_DRAFT_FAILURE: 'UPDATE_DRAFT_FAILURE',
  UPLOAD_DRAFT_IMAGES_REQUEST: 'UPLOAD_DRAFT_IMAGES_REQUEST',
  UPLOAD_DRAFT_IMAGES_SUCCESS: 'UPLOAD_DRAFT_IMAGES_SUCCESS',
  UPLOAD_DRAFT_IMAGES_FAILURE: 'UPLOAD_DRAFT_IMAGES_FAILURE',
  SUBMIT_DRAFT_REQUEST: 'SUBMIT_DRAFT_REQUEST',
  SUBMIT_DRAFT_SUCCESS: 'SUBMIT_DRAFT_SUCCESS',
  SUBMIT_DRAFT_FAILURE: 'SUBMIT_DRAFT_FAILURE',
  CHECK_URL_REQUEST: 'CHECK_URL_REQUEST',
  CHECK_URL_SUCCESS: 'CHECK_URL_SUCCESS',
  CHECK_URL_FAILURE: 'CHECK_URL_FAILURE',
  GET_CATEGORIES_REQUEST: 'GET_CATEGORIES_REQUEST',
  GET_CATEGORIES_SUCCESS: 'GET_CATEGORIES_SUCCESS',
  GET_CATEGORIES_FAILURE: 'GET_CATEGORIES_FAILURE',
  CREATE_UPDATE_REQUEST: 'CREATE_UPDATE_REQUEST',
  CREATE_UPDATE_SUCCESS: 'CREATE_UPDATE_SUCCESS',
  CREATE_UPDATE_FAILURE: 'CREATE_UPDATE_FAILURE',
  GET_UPDATES_REQUEST: 'GET_UPDATES_REQUEST',
  GET_UPDATES_SUCCESS: 'GET_UPDATES_SUCCESS',
  GET_UPDATES_FAILURE: 'GET_UPDATES_FAILURE',
  DELETE_UPDATE_REQUEST: 'DELETE_UPDATE_REQUEST',
  DELETE_UPDATE_SUCCESS: 'DELETE_UPDATE_SUCCESS',
  DELETE_UPDATE_FAILURE: 'DELETE_UPDATE_FAILURE',
  CLEAR_PROJECT_SUCCESS: 'CLEAR_PROJECT_SUCCESS',
  HANDLE_STEPS: 'HANDLE_STEPS',
  REEDIT_DRAFT_REQUEST: 'REEDIT_DRAFT_REQUEST',
  REEDIT_DRAFT_SUCCESS: 'REEDIT_DRAFT_SUCCESS',
  REEDIT_DRAFT_FAILURE: 'REEDIT_DRAFT_FAILURE',
  GET_PROJECT_STATUS_REQUEST: 'GET_PROJECT_STATUS_REQUEST',
  GET_PROJECT_STATUS_SUCCESS: 'GET_PROJECT_STATUS_SUCCESS',
  GET_PROJECT_STATUS_FAILURE: 'GET_PROJECT_STATUS_FAILURE',
  REMOVE_EDIT_REQUEST: 'REMOVE_EDIT_REQUEST',
  REMOVE_EDIT_SUCCESS: 'REMOVE_EDIT_SUCCESS',
  REMOVE_EDIT_FAILURE: 'REMOVE_EDIT_FAILURE',
  SET_CATEGORIES: 'SET_CATEGORIES',
  GET_ADMIN_CATEGORIES_REQUEST: 'GET_ADMIN_CATEGORIES_REQUEST',
  GET_ADMIN_CATEGORIES_SUCCESS: 'GET_ADMIN_CATEGORIES_SUCCESS',
  GET_ADMIN_CATEGORIES_FAILURE: 'GET_ADMIN_CATEGORIES_FAILURE',
  GET_CREATION_CATEGORIES_REQUEST: 'GET_CREATION_CATEGORIES_REQUEST',
  GET_CREATION_CATEGORIES_SUCCESS: 'GET_CREATION_CATEGORIES_SUCCESS',
  GET_CREATION_CATEGORIES_FAILURE: 'GET_CREATION_CATEGORIES_FAILURE',
  SET_TEMP_CATEGORY: 'SET_TEMP_CATEGORY',
  ALL_SUCCESSFULL_EVENT_REQUEST: 'ALL_SUCCESSFULL_EVENT_REQUEST',
  ALL_SUCCESSFULL_EVENT_SUCCESS: 'ALL_SUCCESSFULL_EVENT_SUCCESS',
};

export default projectsConstants;
