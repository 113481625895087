import React, { Component } from 'react';
import { Form, Select, Spin } from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ProjectsActions } from 'redux/actions';
const FormItem = Form.Item;
const Option = Select.Option;

class CategoriesSelect extends Component {
  static propTypes = {
    getProjectCategoriesCreation: PropTypes.func.isRequired,
    categoriesCreation: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
  };
  state = {
    value: [],
  };

  componentDidMount() {
    const { getProjectCategoriesCreation, categoriesCreation } = this.props;
    categoriesCreation.length === 0 && getProjectCategoriesCreation();
  }

  handleChange = (value) => {
    this.setState({ value });
  };



  
  render() {
    const { value } = this.state;
    const { categoriesCreation, loading } = this.props;
    return (
      <FormItem
        name="category_id"
        label="Kategorie deines Projekts"
        rules={[
          {
            required: true,
            message:
              'Bitte wähle die zu deinem Projekt am besten passende Kategorie.',
          },
        ]}
      >
        <Select
          showSearch
          value={value}
          tokenSeparators={[',']}
          placeholder="Wähle eine Kategorie"
          notFoundContent={
            loading ? <Spin size="small" /> : <p>there is no result </p>
          }
          filterOption={false}
          onChange={this.handleChange}
          style={{ width: '100%' }}
        >
          {/* <Option value={1}>
                Moschee
              </Option> */}
          {categoriesCreation &&
            categoriesCreation.map((category) => (
              <Option key={JSON.stringify(category)} value={category.value}>
                {category.category}
              </Option>
            ))}
        </Select>
      </FormItem>
    );
  }
}

const mapStateToProps = (state) => {
  const { categoriesCreation, loading, errors } = state.projects;
  return {
    categoriesCreation,
    loading,
    errors,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getProjectCategoriesCreation: () => dispatch(ProjectsActions.getProjectCreationCategories()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesSelect);
