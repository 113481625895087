import React, { Component } from 'react';
import { Layout } from 'components';
import { Container } from 'utils';
import styled from '@emotion/styled';

const ANBWrapper = styled.div`
  li {
    margin-bottom: 15px;
  }
  ul {
    list-style: none;
    padding: 0;
  }
  .no-padding {
    padding: 0;
  }
  ol.sub-list {
    list-style: upper-alpha;
  }
  .end {
    font-size: 22px;
  }
  .first {
    font-size: 22px;
  }
`;
export default class ANB extends Component {
  render() {
    return (
      <Layout>
        <Container>
          <ANBWrapper style={{ marginTop: '100px', marginBottom: '100px' }}>
            <h1>Allgemeine Nutzungsbedingungen</h1>
            <p className="first">
              commonsplace ist eine Online-Plattform, die Dich dabei
              unterstützt, großartige Arbeit zu leisten.
            </p>
            <p className="first">
              Wir sind berechtigt, diese Bedingungen von Zeit zu Zeit zu ändern.
              Bitte überprüfe diese Seite regelmäßig auf Aktualisierungen. Alle
              Änderungen werden in den Diensten veröffentlicht. Wenn Du mit den
              geänderten Bedingungen nicht einverstanden bist oder diese nicht
              einhalten kannst, musst Du die Nutzung der Dienste einstellen. Die
              aktualisierten Bedingungen treten nach ihrer Veröffentlichung in
              Kraft und gelten für die Zukunft, sofern nicht in einer Mitteilung
              an Dich etwas anderes bestimmt ist und sofern nicht im Abschnitt
              "Schiedsgerichtsbarkeit und Verzicht auf Sammelklagen" dieser
              Bedingungen etwas anderes bestimmt ist. Deine fortgesetzte Nutzung
              der Dienste nach einer solchen Aktualisierung stellt Deine
              verbindliche Annahme dieser Änderungen dar
            </p>
            <h2>§ 1 Allgemeines </h2>
            <ul>
              <li>
                (1) Wir, die commonsplace UG (haftungsbeschränkt),
                Romain-Rolland-Straße 137, 13089 Berlin, (im Folgenden auch als
                "wir", "Plattformbetreiber" oder "commonsplace" bezeichnet)
                betreiben die Online-Plattform "commonsplace.de" (nachfolgend
                auch "Plattform" bezeichnet), über welche natürliche und
                juristische Personen sowie rechtsfähige Personenmehrheiten
                Projekte der Öffentlichkeit vorstellen, durch Dritte finanzieren
                lassen sowie selbst unterstützen können.
              </li>
              <li>
                (2) Die Nutzung unserer Plattform bestimmt sich ausschließlich
                nach den vorliegenden Allgemeinen Nutzungsbedingungen, soweit
                nicht im Einzelfall vertraglich etwas anderes geregelt ist.
                Abweichenden Allgemeinen Geschäftsbedingungen der Nutzer wird
                ausdrücklich widersprochen.
              </li>
              <li>
                (3) Die Rechtsverhältnisse zwischen Nutzern und
                Projektinitiatoren richten sich nach den zwischen diesen zu
                schließenden separaten Verträgen.
              </li>
              <li>
                (4) Ergänzend zu diesen Allgemeinen Nutzungsbedingungen ist die
                Datenschutzerklärung für die Plattform zu beachten.
              </li>
            </ul>
            <h2>§ 2 Definitionen</h2>
            <p>
              Im Sinne dieser Allgemeinen Nutzungsbedingungen werden verstanden:
            </p>
            <ol>
              <li>
                Allgemeine Nutzungsbedingungen (AGBs)” – Die hier vorliegenden
                Allgemeinen Nutzungsbedingungen
              </li>
              <li>
                “Plattform”: Die von der commonsplace UG betriebene
                Online-Plattform www.commonsplace.de
              </li>
              <li>
                “Projektinitiatoren”: Nutzer, welche ein Projekt auf der
                Plattform einstellen und hierfür Unterstützer gewinnen möchten.
                <ol className="sub-list">
                  <li>
                    “privater Projektinitiator”: natürliche Person, die sowohl
                    ein soziales als auch ein nicht-soziales Projekt auf der
                    Plattform einstellen kann.
                  </li>
                  <li>
                    “Organisationen”: juristische Person, die entweder ein
                    gemeinnütziges oder ein gewinnorientiertes Projekt auf der
                    Plattform einstellen kann
                  </li>
                </ol>
              </li>
              <li>
                “Nutzer”: eine natürliche Person, welche die Plattform
                verwendet.
              </li>
              <li>
                “Registrierter Nutzer”: eine natürliche Person, die sich auf der
                Plattform registriert
              </li>
              <li>
                “Unterstützer/Supporter”: Nutzer, welcher einzelne oder mehrere
                Projekte unterstützt.
              </li>
              <li>
                “Anonymer Unterstützer”: Nutzer, welcher einzelne oder mehrere
                Projekte unterstützt, ohne für die Allgemeinheit identifizierbar
                zu sein.
              </li>
              <li>
                “Nutzerbereich”: Seiten mit gesondertem Funktionsumfang, die von
                registrierten Nutzern auf der Plattform eingesehen werden
                können.
              </li>
              <li>
                “Profil”: ein Plattformkonto, das vom jeweiligen Nutzer
                verwaltet wird.
              </li>
              <li>
                "Projekt”: ein Vorhaben eines Projektinitiators, dass eine
                konkrete Initiative mit einer in der Projektbeschreibung
                definierten Zielsetzung, welches mit Geldbeträgen unterstützt
                werden kann
              </li>
              <li>
                “soziales Projekt”: ein Projekt, das mildtätig und nicht
                gewinnorientiert ist.
              </li>
              <li>
                “Fundingziel”: Das Endziel, welches für die Realisierung des
                Projektes benötigt wird. Wird das erste Etappenziel vor Ablauf
                der Finanzierungsphase erreicht, so kann der Projektinitiator
                bei Erhalt weiterer Unterstützungen eine Überfinanzierung
                erreichen.
              </li>
              <li>
                “Finanzierungszeitraum”: Zeitraum, in dem Nutzer ein Projekt
                unterstützen können
              </li>
              <li>
                “Unterstützung”: ein definierter Geldbetrag, der von einem
                Unterstützer einem konkreten Projekt zugewiesen wurde.
              </li>
              <li>
                “Trinkgeld”: ein frei definierter Geldbetrag, der von einem
                Unterstützer an die Plattform geleistet wurde.
              </li>
              <li>
                "Goody/Goodies" / “Belohnung”: Gegenleistung von
                Projektinitiatoren gegenüber Unterstützer im Rahmen ihres
                Projektes entsprechend eines gewählten Unterstützungsbetrags
                (z.B. Nennung im Buch oder Erhalt eines Buches oder sonstige
                Leistungen vom Projektinitator);
              </li>
              <li>
                "Erstes Etappenziel“: Geldbetrag, der von Projektinitiatoren auf
                der eigenen Projektseite als erforderlicher Mindestbetrag
                angegeben wird. Wird diese Etappe erreicht, gilt das Projekt als
                erfolgreich
              </li>
              <li>
                "nächstes Etappenziel": Geldbetrag, der eine Stufe nach
                erreichtem ersten Etappenziel beschreibt
              </li>
            </ol>
            <h2>§ 3 Unsere Leistungen</h2>
            <ol>
              <li>
                Wir stellen nach Maßgabe der folgenden Bestimmungen und im dort
                beschriebenen Umfang die technologischen Voraussetzungen für die
                Nutzung unserer Plattform bereit.
              </li>
              <li>
                Wir stellen dem Nutzer ein Profil zur Verfügung und betreiben
                Öffentlichkeitsarbeit für die Plattform. Art und Umfang der
                Öffentlichkeitsarbeit für unsere Plattform und/oder die Projekte
                stehen jeweils in unserem freien Ermessen.
              </li>
              <li>
                Die Datenkommunikation mit unserer Plattform nimmst du durch
                eigenständigen Onlinezugriff vor, wie beispielsweise über den
                Webbrowser.
              </li>
              <li>
                Die Einzelheiten hinsichtlich des Ablaufs der Registrierung und
                Inanspruchnahme sonstiger Leistungen werden jeweils an
                geeigneter Stelle auf den Internetseiten unserer Plattform
                beschrieben. Wir sind berechtigt, jederzeit selbständig Updates
                und Erweiterungen unseres Leistungsspektrums auf unserer
                Plattform durchzuführen
              </li>
              <li>
                Wir sind berechtigt, unsere Leistungen durch Dritte erbringen zu
                lassen
              </li>
              <li>
                Wir können die Projekte vor ihrer Veröffentlichung auf den
                sozialen Zweck ihrer beabsichtigten Umsetzung prüfen. Wir lassen
                uns zudem durch Vorlage eines aktuell gültigen
                Freistellungsbescheids nachweisen, dass die projekttragende
                Organisation im Sinne des aktuell geltenden deutschen
                Steuerrechts als gemeinnützig anerkannt ist.
              </li>
              <li>
                commonsplace wird sich bemühen, die Plattform möglichst
                unterbrechungsfrei verfügbar zu halten. Angestrebt wird eine
                durchgehende Verfügbarkeit. Eine bestimmte Verfügbarkeit wird
                nicht zugesagt. Insbesondere ist commonsplace.de regelmäßig und
                außerplanmäßig berechtigt, die Plattform für Wartungsarbeiten
                aus dem Netz zu nehmen. commonsplace ist berechtigt,
                Funktionalitäten, Aussehen oder andere Features der Plattform
                nach eigenem Belieben zu ändern. Zugang besteht nur zur
                Plattform in der jeweils aktuellen Version. Zur Aufbewahrung
                oder Speicherung von Versionen oder Inhalten ist commonsplace.de
                gegenüber Nutzern nicht verpflichtet.
              </li>
              <li>
                Durch deine Unterstützung eines Projekts erwirbst du zusätzlich
                folgende Services:
                <ol className="sub-list">
                  <li>
                    Du abonnierst unseren Newsletter, der dich über Neuigkeiten
                    rund um commonsplace informiert.
                  </li>
                  <li>
                    Du erhältst Updates über das von dir unterstützte Projekt
                    durch den Projektinitiator.
                  </li>
                </ol>
              </li>
            </ol>
            <h2>
              § 4 Anmeldung als Nutzer, Vertragsschluss über die Nutzung der
              Plattform
            </h2>
            <ol>
              <li>
                Die Nutzung des Nutzerbereiches unserer Plattform setzt eine
                dauerhafte Registrierung von dir voraus. Die Registrierung ist
                für dich kostenfrei. Um dich zu registrieren, gibst du die
                erforderlichen Daten in das Registrierungsformular auf unserer
                Plattform ein. Durch den Abschluss des Registrierungsvorganges
                gibst du ein verbindliches Angebot zum Abschluss eines Vertrages
                über die Registrierung auf unserer Plattform ab.
              </li>
              <li>
                Wurden deine Daten im Rahmen der Registrierung erfolgreich
                übermittelt, so geht dir unverzüglich eine Bestätigung per
                E-Mail zu. Der Vertrag über die Plattformnutzung kommt zustande,
                indem du auf den darin enthaltenen Bestätigungslink klickst.
              </li>
              <li>
                Mit deiner Unterstützung speichern wir – unabhängig davon, ob du
                über ein Profil verfügst oder nicht – deinen Namen und
                E-MailAdresse im Zusammenhang mit der jeweils unterstützten
                Projekt. Solange du kein Profil anlegst, erhältst du in den
                E-Mails unserer Plattform einen Link um die Registrierung zur
                Eröffnung eines Profils abzuschließen.
              </li>
              <li>
                Zur Nutzung berechtigt sind nur unbeschränkt geschäftsfähige
                natürliche Personen oder beschränkt Geschäftsfähige, die mit
                Einwilligung ihrer gesetzlichen Vertreter handeln. Das
                Mindestalter der Nutzer beträgt 16 Jahre.
              </li>
              <li>
                Bist du bereits Nutzer, kannst du nicht nochmals Nutzer werden -
                Ausschuss der Doppelmitgliedschaft.
              </li>
              <li>
                Wir behalten uns das Recht vor deine Registrierung ohne Angabe
                von Gründen abzulehnen, dies selbst dann, wenn deinerseits alle
                Voraussetzungen für die Aufnahme als Nutzer vorliegen sollten.
              </li>
              <li>
                Wir weisen ausdrücklich darauf hin, dass mit jeder Unterstützung
                ein separater Vertrag zwischen dem jeweiligen Projektinitiator
                und Unterstützer abgeschlossen wird.
              </li>
              <li>
                Du bist dafür verantwortlich, uns unter support@commonsplace.de
                zu benachrichtigen, wenn du von einer unbefugten Nutzung deines
                Kontos oder einem unbefugten Zugriff darauf Kenntnis erhältst.
                Du verstehst und erklärst dich damit einverstanden, dass wir von
                dir Informationen verlangen können, die zur Bestätigung deiner
                Identität und zur Gewährleistung der Sicherheit deines Kontos
                verwendet werden können. commonsplace ist nicht haftbar für
                Verluste, Schäden, Haftungen, Ausgaben oder Anwaltsgebühren, die
                dur dadurch entstehen, dass eine andere Person dein Passwort
                oder dein Konto mit oder ohne dein Wissen und/oder Ihre
                Autorisierung verwendet, und zwar unabhängig davon, ob du uns
                über eine solche unbefugte Nutzung informiert hast oder nicht.
                Du bist haftbar für Verluste, Schäden, Haftung, Ausgaben und
                Anwaltskosten, die commonsplace oder einer Drittpartei dadurch
                entstehen, dass eine andere Person deinKonto benutzt.
              </li>
            </ol>
            <h2>
              § 5 Verpflichtung der Projektinitiator zur Belehrung über
              gesetzliche Widerrufsrechte oder sonstige Verbraucherinformationen
            </h2>
            <p>
              Beim Abschlusses der einzelnen Verträge über Unterstützungen
              (bspw. hinsichtlich des Erwerbs der Goodies” ist allein vom
              Projektinitiator des Projekts über Verbraucherinformationen wie
              etwaige Widerrufsrechte, deren gesetzliche Voraussetzungen und
              Ausübung zu informieren. Wir sind insoweit rechtlich Dritter.
            </p>
            <h2>
              § 6 Vertragsverhältnisse der Projektinitiatoren zu den
              Unterstützern, Vertragsschluss zwischen Projektinitiator und
              Unterstützer
            </h2>
            <ol>
              <li>
                Projektinitiatoren können Unterstützer im Rahmen der
                Vertragsanbahnung für die Zuweisung von Unterstützungen
                Gegenleistungen in Form von Goodies in Aussicht stellen.
              </li>
              <li>
                Dir stehen als Unterstützer die auf unseren Internetseiten im
                Rahmen der jeweiligen Projekt näher beschriebenen Bezahlmethoden
                zur Verfügung.
              </li>
              <li>
                Ein Unterstützer gibt durch eine Unterstützung ein für die Dauer
                der Finanzierungsphase gültiges Angebot gegenüber einem
                Projektinitator auf Abschluss eines von commonsplace
                unabhängigen Vertragsverhältnisses ab (z.B. Spende bei
                Unterstützung ohne Goodie/Belohnung oder auch ein Kauf- oder
                Dienstvertrag). Als Unterstützer wählst du hierzu ein
                gewünschtes Goodie/ eine gewünschte Belohnung eines Projekts aus
                oder entscheidest dich für einen individuellen Geldbetrag.
                Danach öffnet sich eine Übersicht, auf der du dich nochmals
                vergewissern kannst, ob die von dir eingegebenen Daten richtig
                sind und du deine Auswahl verändern willst. Mit Betätigung des
                Buttons “Weiter zur Zahlung” erhältst du die Möglichkeit, die
                gewünschte Bezahlmethode auszuwählen und ggf. deine von dir
                benötigten Adressdaten anzugeben. Der von dir angegebene Betrag
                wird dem Projekt nach Betätigung des Buttons "Jetzt
                zahlungspflichtig unterstützen" verbindlich zugewiesen. Nach
                Abgabe deines Angebots erhältst du eine E-Mail mit den
                wesentlichen Vertragsdaten. Dies stellt keine Annahme, sondern
                eine bloße Eingangsbestätigung dar. Im Falle des Projekterfolgs
                erhältst du eine weitere informatorische E-Mail aus unserem
                System.
              </li>
              <li>
                Hinsichtlich der zur Verfügung gestellten Bezahlmethoden (Bspw.:
                Kreditkarte, Lastschrift) gelten die jeweiligen
                Geschäftsbedingungen der Anbieter.
                <ol className="sub-list">
                  <li>
                    Für die Abwicklung der Zahlmethoden nutzt commonsplace den
                    Paymentdienstleister Stripe Technology Europe Ltd., 25-28
                    North Wall Quay, Dublin 1, Ireland. Stripe hält das Geld bis
                    zur Auszahlungsreife auf einem Client Money-Konto des
                    Projektinitiators nach Maßgabe der durch den
                    Projektinitiator mit Stripe abgeschlossenen
                    Zusatzvereinbarung unter
                    https://stripe.com/de/connect-account/legal#translation. Mit
                    dem Erreichen der Auszahlungsreife übermittelt der
                    Projektinitiator die Anweisung zur Auszahlung der dort
                    gesammelten Gelder über commonsplace als seinen
                    Erklärungsboten an Stripe.
                  </li>
                  {/* <li>
                    <p>
                      Im Projektformular wird abgefragt, ob der Projektinitiator
                      über ein PayPal-Konto verfügt. Wenn der Projektinitiator
                      kein PayPal-Konto hat, wird die Option, über PayPal zu
                      spenden, im Check-Out-Prozess deaktiviert. Allerdings kann
                      der Projektinitiator jederzeit eine PayPal-Adresse
                      nachtragen.
                    </p>
                    <p>
                      Wenn der Projektinitiator eine PayPal-Adresse hat, sollte
                      er uns diese mitteilen, damit wir das Geld an die richtige
                      Adresse auszahlen können. Sollten uns jedoch keine
                      korrekten Informationen vorliegen, halten wir das Geld
                      ein, bis uns die korrekten Angaben übermittelt werden. In
                      diesem Fall ist es wichtig, dass der Projektinitiator uns
                      so schnell wie möglich die korrekten Informationen
                      zukommen lässt, damit wir die Auszahlung vornehmen können.
                    </p>
                  </li> */}
                  <li>
                    Wenn die Zahlung des Unterstützers aus Gründen, für die der
                    Unterstützer verantwortlich ist (z. B. aufgrund des Ablaufs
                    des Bankkontos oder der Kreditkarte), nicht zurückgezahlt
                    werden können (z. B. nach Stornierung) und wir ihn nicht
                    innerhalb von 4 Tagen erreichen, darf commonsplace, dieses
                    Geld in ein anderes auf commonsplace existierende Projekt
                    investieren.
                  </li>
                </ol>
              </li>
              <li>
                Unterstützt du ein Projekt, welche auf einer für dich fremden
                Währung basiert, musst du in der Lage sein, die Zahlung von
                deinem Bankkonto entsprechend in der Fremdwährung durchführen zu
                können. Überweisungen in einer abweichenden Währung werden durch
                die beteiligten Zahlungsdiensteanbieter automatisch
                zurücküberwiesen. Dadurch evtl. entstehende Bank-Gebühren
                und/oder Umrechnungsverluste sind seitens des in abweichender
                Währung zahlenden Unterstützers zu tragen und werden automatisch
                bei der Rücküberweisung abgezogen.
              </li>
              <li>
                Kann eine an den Unterstützer zu erstattende Zahlung (bspw. nach
                einer Stornierung) aus vom Unterstützer zu vertretenden Gründen
                nicht zurückgezahlt werden (z.B. auf Grund eines erloschenen
                Bankkontos oder einer abgelaufenen Kreditkarte) und können wir
                ihn nicht innerhalb von vier Wochen erreichen, so ist
                commonsplace berechtigt, diesen Geldbetrag einer von
                commonsplace zu bestimmenden Projekt, deren Projektinitiator
                gemeinnützig ist, zur Verfügung zu stellen.
              </li>
              <li>
                Der Unterstützer verpflichtet sich, den von ihm angegebenen
                Geldbetrag ohne Abzüge für etwaige Kosten des Geldtransfers
                endgültig für seine gewünschte Zielunterstützung zur Verfügung
                zu stellen.
              </li>
            </ol>
            <h2>
              § 7 Auszahlung der Unterstützungssumme an den Projektinitiator im
              Falle des Erreichens des Fundingziels
            </h2>
            <ol>
              <li>
                Wird das erste Etappenziel vor Ablauf der Finanzierungsphase
                erreicht, so kann der Projektinitiator bei Erhalt weiterer
                Unterstützungen eine Überfinanzierung erreichen.
              </li>
              <li>
                Wird innerhalb der Finanzierungsphase mindestens das Fundingziel
                erreicht und sind alle weiteren Voraussetzungen der Auszahlung
                eingetreten, so erhält der Projektinitiator innerhalb von 2-4
                Werktagen nach Erreichen des Fundingziels Zugriff auf den ihm
                zustehenden Geldbetrag. Die Summe der ursprünglich erlangten
                Unterstützungen können dabei bereits durch etwaig erklärte
                Stornierungen oder Widerrufe der Unterstützer verringert sein.
              </li>
            </ol>
            <h2>§ 8 Deine Nebenpflichten als Besucher</h2>
            <ol>
              <li>
                Zur Gewährleistung eines ordnungsgemäßen Ablaufs der Nutzung
                bestehen für dich nachfolgende Verhaltenspflichten, deren
                Nichtbefolgung für dich zu Nachteilen – insbesondere zur
                Kündigung und Geltendmachung von Schadensersatzansprüchen
                unsererseits oder Dritter – führen kann.
              </li>
              <li>
                Der Nutzer ist für sein Verhalten auf der Plattform und für alle
                von ihm eingestellten Inhalte und deren Richtigkeit
                uneingeschränkt verantwortlich. Vor der konkreten Teilnahme oder
                dem Einstellen von Inhalten muss der Nutzer sicherstellen, dass
                sein Verhalten oder seine Inhalte nicht gegen die
                Nutzungsbedingungen oder Regeln von commonsplace, gesetzliche
                Vorschriften, die guten Sitten oder Rechte Dritter verstoßen
                können (z.B. Marken-, Namens-, Urheber- und Datenschutzrecht,
                jugendschutzrechtliche Bestimmungen, Allgemeines
                Persönlichkeitsrecht, Wettbewerbsrecht). Insbesondere
                verpflichtet sich der Nutzer, vor dem Einstellen von Texten oder
                Bildern die erforderlichen Einwilligungen der
                Beteiligten/Betroffenen/Berechtigten, beispielsweise der
                Fotografen oder der abgebildeten oder genannten Personen,
                einzuholen und commonsplace auf Verlangen jederzeit
                nachzuweisen. Der Nutzer stellt commonsplace von etwaigen
                Ansprüchen Dritter, die aus einem Verstoß gegen diese
                Verpflichtung hervorgehen, frei
              </li>
              <li>
                Du verpflichtest dich auf der Plattform, folgende unzulässige
                Inhalte unmittelbar einzustellen, darauf zu verlinken oder sonst
                den Zugang dazu zu erleichtern:
                <ol className="sub-list">
                  <li>
                    Inhalte, die gegen allgemein anerkannte islamische
                    Grundsätze verstoßen, zu publizieren
                  </li>
                  <li>
                    bei erforderlichen Registrierungen und sonstigen zur
                    Erreichung des Vertragszwecks erforderlichen Abfragen (z.B.
                    im Rahmen der Einstellung eines Projekts oder Leistung von
                    Unterstützungen) vollständige und wahrheitsgemäße Angaben zu
                    machen,insbesondere zu Identität und Alter,
                  </li>
                  <li>
                    bei deinem Verhalten auf der Plattform zu berücksichtigen,
                    dass die commonsplace-Community (also die Gemeinschaft aller
                    Nutzer und commonsplace) von gegenseitiger Rücksichtnahme
                    und einem fairen und angemessenen Umgang miteinander lebt.
                    Insbesondere bei Themen der politischen und religiösen
                    Überzeugung ist Sensibilität und Respekt gegenüber
                    Andersdenkenden geboten.
                  </li>
                  <li>
                    Benutzerinhalte zu veröffentlichen, die andere Personen
                    belästigen, missbrauchen oder bedrohen, oder die obszöne
                    Inhalte enthalten; die falsch, irreführend oder ungenau
                    sind; die andere aufgrund von Geschlecht, Rasse, Klasse,
                    ethnischer Herkunft, nationaler Herkunft, Religion,
                    sexueller Präferenz, Behinderung oder einer anderen
                    Klassifizierung herabwürdigen; die rechtswidrig, schädlich,
                    unerlaubt, verleumderisch, beleidigend oder die Privatsphäre
                    anderer Personen verletzen;
                  </li>
                  <li>
                    Jugend- oder entwicklungsgefährdendes oder
                    -beeinträchtigendes Material, insbesondere
                    gewaltverherrlichende, pornografische oder sonst
                    sittenwidrige Inhalte
                  </li>
                  <li>
                    Benutzerinhalte einstellen, die Gewalt oder Terrorismus
                    unterstützen;. Inhalte, die Hass, Gewalt oder jegliche Form
                    der Diskriminierung fördern oder verherrlichen
                  </li>
                  <li>Unwahre Tatsachenbehauptungen oder Beleidigungen</li>
                  <li>
                    Vulgäre oder obszöne Ausdrücke, despektierlicher,
                    provokativer und aggressiver Umgang mit anderen Nutzern
                  </li>
                  <li>Irreführende Inhalte</li>
                  <li>
                    Aufrufe zu Boykotten oder unfriedlichen bzw. sonst
                    rechtswidrigen Handlungen
                  </li>
                  <li>
                    Inhalte, die gegen die öffentliche Sicherheit und Ordnung
                    verstoßen
                  </li>
                  <li>
                    es zu unterlassen, sich ein zweites Mal zu registrieren,
                  </li>
                  <li>
                    bei einer nachträglichen Änderung der abgefragten Daten
                    diese unverzüglich in der dafür vorgesehenen
                    Verwaltungsfunktion unserer Plattform zu berichtigen,
                  </li>
                  <li>
                    sicherzustellen, dass dein Benutzername sowie das
                    dazugehörige Passwort keinem unbefugten Dritten zugänglich
                    wird,
                  </li>
                  <li>
                    jede unbefugte Nutzung unserer Plattform Dritter unter
                    Verwendung deiner Zugangsdaten zu unterbinden,
                  </li>
                  <li>
                    uns unverzüglich unter{' '}
                    <a href="mailto: support@commonsplace.de">
                      support@commonsplace.de
                    </a>{' '}
                    mitzuteilen, falls eine missbräuchliche Benutzung deiner
                    Zugangsdaten bzw. Benutzerkontos vorliegt oder Anhaltspunkte
                    für eine bevorstehende missbräuchliche Nutzung bestehen und
                  </li>
                  <li>
                    etwaig über unsere Plattform geschlossene Verträge bei
                    Fälligkeit zu erfüllen
                  </li>
                  <li>
                    commonsplace unverzüglich per E-Mail an
                    <a href="mailto: support@commonsplace.de">
                      support@commonsplace.de
                    </a>{' '}
                    mitzuteilen, wenn eine missbräuchliche Benutzung des
                    Passworts bzw. des Accounts vorliegt oder Anhaltspunkte für
                    eine bevorstehende missbräuchliche Nutzung bestehen.
                  </li>
                  <li>
                    Eine übermäßige Belastung der Plattform von commonsplace.de
                    ist zu vermeiden. Insbesondere sind Maßnahmen untersagt, die
                    die Integrität, Stabilität oder Verfügbarkeit der IT-Systeme
                    von commonsplace.de gefährden können.
                  </li>
                  <li>
                    Sobald ein Nutzer gesperrt ist, darf dieser Nutzer die
                    Plattform auch mit anderen Plattform-Konten nicht mehr
                    nutzen und sich nicht erneut anmelden.
                  </li>
                  <li>
                    Inhalte, die nicht mit den jeweils aktuellen Grundsätzen von
                    commonsplace vereinbar sind, die commonsplace auf der
                    Plattform transparent kommuniziert.
                  </li>
                </ol>
              </li>
              <li>
                Solltest du gegen eine deiner Nebenpflichten aus Absatz 3
                verstoßen, so sind wir berechtigt, nach unserer Wahl betroffene
                Inhalte mit sofortiger Wirkung vorübergehend zu sperren und/oder
                zu löschen und/oder dich vorübergehend oder dauernd vom Angebot
                auszuschließen und/oder dir fristlos zu kündigen. Das gleiche
                gilt, wenn wir von Dritten darauf hingewiesen werden, dass ein
                Verstoß deinerseits gegen die in Absatz 3 enthaltenen Pflichten
                vorliegen würde, sofern die Behauptung des Dritten nicht
                offensichtlich unrichtig ist.
              </li>
              <li>
                Verletzt du vorstehende Pflichten, musst du uns den ggf. daraus
                resultierenden Schaden ersetzen bzw. ggf. von Ansprüchen Dritter
                freistellen, es sei denn, du hast die Pflichtverletzung nicht zu
                vertreten.
              </li>
              <li>
                commonsplace behält sich vor, einen Nutzer zu löschen, wenn
                dieser sein Plattform-Konto mehr als 2 Jahre nicht nutzt
              </li>
            </ol>
            <h2>
              § 9 Urheberrechte und sonstige Schutzrechte hinsichtlich
              nutzergenerierter Inhalte innerhalb unserer Plattform
            </h2>
            <p>
              Insbesondere erhält commonsplace.de das Recht, die Inhalte zu
              speichern und öffentlich über die Plattform zugänglich zu machen
              sowie das Recht die Inhalte zu bearbeiten, insbesondere zu kürzen,
              unter Berücksichtigung des ursprünglichen Sinns. Unberührt bleiben
              beim Einstellen der Inhalte deutlich und klar angebrachte
              anderweitige Hinweise
            </p>
            <ol>
              <li>
                Für den Fall, dass du selbst über dein Profil Inhalte in unsere
                Plattform einpflegst, an denen dir Urheber- oder sonstige
                Schutzrechte zustehen, sind wir für die Dauer unserer
                Leistungserbringung zu denjenigen Verwertungshandlungen
                berechtigt, welche dem Zweck der einzelnen Leistungen im Rahmen
                der Plattform entsprechen. commonsplace darf die vom Nutzer auf
                der Plattform eingestellten und dort veröffentlichten Inhalte
                auf Webseiten von commonsplace.de einschließlich
                Social-Media-Kanälen und Printmedien – verwenden, um für alle
                einen größeren Adressatenkreis zu erreichen.{' '}
              </li>
              <li>
                Auch nach Beendigung der Mitgliedschaft werden deine Beiträge
                auf Seiten der Plattform nicht gelöscht. Du räumst an diesen
                Informationen unwiderruflich und unentgeltlich ein einfaches
                Nutzungsrecht für jedermann auch über die Dauer der
                Registrierung als Nutzer hinaus ein.
              </li>
              <li>
                Die enthaltenen Daten unserer Plattform unterliegen ggf. (u.a.
                urheber-)rechtlichem Schutz. Dem Besucher ist es daher nicht
                gestattet, diese Daten über die von uns oder dem jeweiligen
                Rechtsinhaber im Einzelfall gewährte Nutzungsrechte hinaus zu
                verwerten, insbesondere nicht zu kopieren, zu bearbeiten
                und/oder zu verbreiten. Dies gilt insbesondere auch für unsere
                Rechte als Datenbankhersteller gemäß der §§ 87c ff. UrhG. Die
                wiederholte und systematische Vervielfältigung, Verbreitung oder
                öffentliche Wiedergabe von Inhalten unserer Plattform läuft
                einer normalen Auswertung unserer Datenbank zuwider und
                beeinträchtigt unsere berechtigten Interessen.
              </li>
            </ol>
            <h2>§ 10 Vertraulichkeit und Datenschutz </h2>
            <p>
              Deine Daten werden von uns ausschließlich zu den sich aus der
              Registrierung ergebenden Zwecken unter Beachtung der einschlägigen
              gesetzlichen Bestimmungen des Datenschutzes gespeichert und
              verarbeitet (konsultiere auch unsere Datenschutzerklärung).
            </p>
            <h2>§ 11 Haftungsklausel </h2>
            <ol>
              <li>
                Wir leisten Schadenersatz oder Ersatz vergeblicher Aufwendungen,
                gleich aus welchem Rechtsgrund (z.B. aus rechtsgeschäftlichen
                und rechtsgeschäftsähnlichen Schuldverhältnissen,
                Pflichtverletzung oder unerlaubter Handlung), nur in folgendem
                Umfang:
                <ol className="sub-list">
                  <li>
                    Die Haftung bei Vorsatz oder aus Garantie bleibt
                    unbeschränkt
                  </li>
                  <li>
                    Im Falle grober oder einfacher Fahrlässigkeit haften wir
                    gegenüber Unternehmern (§ 14 BGB), juristischen Personen des
                    öffentlichen Rechts oder öffentlich-rechtlichen
                    Sondervermögen nur in Höhe des typischen und bei
                    Vertragsabschluss vorhersehbaren Schadens, soweit es sich
                    nicht um Verletzung einer so wesentlichen Pflicht handelt,
                    deren Erfüllung die ordnungsgemäße Durchführung des
                    Vertragszwecks überhaupt erst ermöglicht und auf deren
                    Einhaltung der Unternehmer daher regelmäßig vertrauen darf
                    (sog. Kardinalpflicht).
                  </li>
                  <li>
                    Befinden wir uns jedoch mit unserer Leistung in Verzug, so
                    haften wir auch für Zufall, es sei denn, der Schaden wäre
                    auch bei rechtzeitiger Leistung eingetreten.
                  </li>
                  <li>
                    Im Übrigen ist eine Haftung durch uns für einfache
                    Fahrlässigkeit ausgeschlossen.
                  </li>
                </ol>
              </li>
              <li>
                Wir behalten uns den Einwand des Mitverschuldens vor. Du hast
                die Pflicht zur Datensicherung nach dem aktuellen Stand der
                Technik.
              </li>
              <li>
                Soweit unsere Haftung ausgeschlossen oder beschränkt ist, gilt
                dies auch für die persönliche Haftung unserer Mitarbeiter,
                Vertreter und Erfüllungsgehilfen
              </li>
              <li>
                Die vorstehenden Absätze des § 12 (Haftung) gelten nicht im
                Falle der Verletzung von Leben, des Körpers, der Gesundheit und
                bei Ansprüchen aus dem Produkthaftungsgesetz
              </li>
            </ol>
            <h2>§ 14 Löschung des Profils durch den Nutzer </h2>
            <ol>
              <li>
                Der Vertrag über die Nutzung unserer Plattform wird auf
                unbestimmte Zeit geschlossen. Er ist beiderseits jederzeit
                ordentlich kündbar
              </li>
              <li>
                Die ordentliche Kündigung nach Absatz 1 wird jedoch nicht
                wirksam, solange: du Projektinitiator bist und sich dein Projekt
                in der Start- oder Finanzierungsphase befindet und/oder oder die
                von dir unterstützte Projekte sich noch in der
                Finanzierungsphase befinden und/oder die Rückbuchung ein von dir
                unterstütztes erfolgloses Projekt noch nicht abgeschlossen ist.
              </li>
              <li>
                Das beiderseitige Recht zur außerordentlichen Kündigung aus
                wichtigem Grund bleibt unberührt.
              </li>
              <li>
                Kündigungen sind in Textform (z.B. per E-Mail) oder über die
                hierfür technologisch seitens unserer Plattform bereitgestellten
                Funktion mit einer Frist von 14 Tagen zu erklären. Mit
                Wirksamwerden der Kündigung kann der registrierte Nutzer die
                jeweiligen Funktionalitäten der Plattform, die er gemäß des
                Nutzungsvertrags nutzen kann, nicht mehr nutzen.
              </li>
            </ol>
            <h2>§ 12 Projektinitiator-Bedingungen</h2>
            <p>
              Wir gewähren dem Nutzer das Recht, unsere Plattform gemäß
              nachfolgenden Zusatzbestimmungen zum Einstellen von
              CrowdfundingProjekte zu nutzen.
            </p>
            <ol>
              <li>
                Der Projektinitiator ist darauf hingewiesen, dass im Falle der
                Unterstützung durch die Nutzer der Plattform mit diesen ein
                separates und von dem Plattformbetreiber unabhängiges
                Vertragsverhältnis entsteht. Der Projektinitiator stellt die
                Vorhabensbeschreibung und das Projektziel in zeitlicher wie auch
                inhaltlicher Hinsicht dar und bestimmt das erwünschte
                Fundingziel.
              </li>
              <li>
                Die Registrierung eines Projekts einer Organisation darf nur
                durch einen Vertretungsberechtigten vorgenommen werden. Der
                Vertretungsberechtigte, der eine Organisation und/ oder ein
                Projekt bzw. ein Unternehmen auf der Plattform registriert,
                verwaltet, durchführt und / oder inhaltlich verantwortet,
                versichert, dass er gegenüber der Organisation bzw. gegenüber
                dem Unternehmen dazu berechtigt ist und dass die Angaben über
                die Organisation, das Projekt einschließlich der
                Projektbeschreibung und der Produktneuigkeiten und die
                Beteiligten bzw. die Angaben über das Unternehmen zutreffend
                sind. Er weist diese Berechtigung auf Anfrage von commonsplace
                durch geeignete Nachweise nach. Er bleibt bis zur anderweitigen
                Mitteilung berechtigt, alle Fragen, die den Auftritt der
                Organisation und deren Projekts bzw. das Unternehmen auf der
                Plattform betreffen, verbindlich zu klären und die
                erforderlichen Erklärungen gegenüber commonsplace.de abzugeben
                bzw. in Empfang zu nehmen.
              </li>
              <li>
                commonsplace leistet keine Rechtsberatung. Der Projektinitiator
                ist daher eigenverantwortlich verpflichtet, sich vor Beginn
                seines Projektes über dessen rechtliche Voraussetzungen
                umfassend zu informieren (z.B. Widerrufsbelehrung,
                Impressumspflichten). So der Projektinitiator nicht selbst über
                die erforderlichen Kenntnisse verfügt, ist er verpflichtet, sich
                an einen Rechtsanwalt oder Steuerberater zu wenden. Dies gilt
                auch dann, wenn der Projektinitiator Formulierungsbeispiele,
                Muster oder Hinweise aus den FAQ der Plattform oder Dritter in
                Anspruch nimmt.
              </li>
              <li>
                Der Projektinitiator ist verpflichtet, sämtliche gesetzliche
                Hinweispflichten – insbesondere zu Verträgen im Fernabsatz und
                elektronischen Rechtsverkehr – einzuhalten. Dies beinhaltet auch
                die Einrichtung eines den gesetzlichen Anforderungen
                entsprechenden Impressums. Die Plattform sieht hierfür eigene
                Hinweisfelder im Rahmen der Einrichtung der Projekt vor
              </li>
              <li>
                Eine nachträgliche Verkürzung oder Verlängerung der
                Finanzierungsphase und/oder der Erhöhung oder Verringerung des
                Fundingziels bedarf einer Zustimmung der Plattform.
              </li>
              <li>
                Der Spender hat die Möglichkeit als “anonymer Unterstützer”
                Unterstützungen zu leisten. Der Unterstützer gibt im
                Unterstützungsprozess seinen Namen, seine E-Mail-Adresse, seine
                Postadresse und Kontodaten an. Diese Daten sind notwendig, damit
                auch die Daten eines “anonymen Unterstützers” verarbeitet werden
                können. Im Rahmen der Unterstützung kann der Unterstützer
                wählen, ob seine Unterstützung mit Namen oder ohne Namen auf der
                Plattform angezeigt wird. Entscheidet sich der Unterstützer
                dafür, dass seine Spende ohne Namen angezeigt wird, wird dieser
                als „anonymer Unterstützer” angezeigt.
              </li>
            </ol>
            <h2>§ 13 Kosten </h2>
            <ol>
              <li>
                Keine Kosten der Erstellung des Projekts entstehen vor dessen
                Start
                <p>
                  Das bloße Vorbereiten von Projekten über dein Profil ist für
                  dich als Projektinitiator kostenfrei. Erst mit Eintritt des
                  Projektes in den Finanzierungszeitraum können dir nach Maßgabe
                  des § 16 Absatz 2 Kosten entstehen. Hinsichtlich der Kosten
                  sonstiger Leistungen der commonsplace UG (haftungsbeschränkt)
                  gelten die jeweils dort im Einzelfall vereinbarten Preise
                </p>
              </li>
              <li>
                Kosten nach Start einer Projekt
                <ol className="sub-list">
                  <li>
                    Im Falle einer Unterstützung besteht die Möglichkeit,
                    bezüglich eines Teils der geleisteten Summe oder der ganzen
                    geleisteten Summe als gewünschtes Unterstützungsziel den
                    Verbleib der angegebenen Summe bei commonsplace für die
                    Verfolgung ihrer satzungsgemäßen Zwecke anzugeben. Auf diese
                    Möglichkeit wird im Unterstützungsprozess hingewiesen. Der
                    vorgeschlagene Betrag kann vom Unterstützer geändert oder
                    ganz entfernt werden{' '}
                  </li>
                  <li>
                    Die bei Start der Projekte anfallenden Kosten ergeben sich
                    aus der bei Start des Projekts jeweils gültigen{' '}
                    <a href="http://commonsplace.de/fees"> Preisliste</a>.
                  </li>
                  <p>
                    Ca. 3-4 Prozent Nutzungsgebühr gehen an unsere
                    Zahlungsdienstleister und 5 Prozent an Commonsplace. Die
                    Nutzungsgebühr ermöglicht den Betrieb unserer Plattform und
                    deckt zusätzliche Kosten ab, die potenziell entstehen, wenn
                    beispielsweise Unterstützer ihre Zahlungen widerrufen oder
                    Banken die Gelder anfechten. Diese Kosten werden direkt
                    commonsplace in Rechnung gestellt. Wir möchten den Nutzern
                    transparent machen, dass bei jedem Projekt signifikante
                    Kosten entstehen können, die durch solche Vorfälle
                    verursacht werden.
                  </p>
                  <p>
                    Falls du Bedenken bezüglich der Nutzungsgebühren hast,
                    kontaktiere uns, bevor das Projekt startet. In individuellen
                    Fällen bieten wir Sonderkonditionen an.
                  </p>
                  <li>
                    Hinsichtlich der Kosten sonstiger Leistungen der
                    commonsplace UG (haftungsbeschränkt) gelten die hierfür
                    vereinbarten Preise
                  </li>
                  <li>
                    Etwaige Änderung der Gebühren gelten jeweils nur für
                    diejenigen Projekte, die nach der jeweiligen Bekanntmachung
                    der Änderung auf unserer Plattform in den
                    Finanzierungszeitraum eintreten.
                  </li>
                  <li>
                    Für etwaige Steuern und sonstige Abgaben, welche auf die
                    erhaltenen Unterstützungsbeträge oder im Rahmen der
                    Leistungserbringung des Projektinitiators ggü. dem
                    Unterstützer anfallen können, bist du als Projektinitiators
                    selbst verantwortlich
                  </li>
                </ol>
              </li>
            </ol>
            <p>
              Der Unterstützer verpflichtet sich, den von ihm angegebenen
              Geldbetrag ohne Abzüge für etwaige Kosten des Geldtransfers
              endgültig für seine gewünschte Spendenziel-Unterstützung zur
              Verfügung zu stellen
            </p>
            <h2>
              § 14 Vertragsschluss über den Projektestart und etwaige
              Zusatzleistungen
            </h2>
            <ol>
              <li>
                Durch Abschluss der Entwurfsphase mit abschließendem Klick auf
                "Projekt starten", zum Wechsel in den Finanzierungszeitraum,
                gibst du ein verbindliches Angebot zum Abschluss eines Vertrages
                über den Projektestart auf unserer Plattform ab.
              </li>
              <li>
                Sobald der Wechsel in den Finanzierungszeitraum beantragt und
                die Projekt erfolgreich überschlägig (vgl. § 8 Absatz 2) durch
                einen Mitarbeiter von commonsplace auf Einhaltung der
                commmonsplace-Regelnüberprüft wurde, kann der Projektinitiator
                den Finanzierungszeitraum selbstständig freischalten.
              </li>
              <li>
                commonsplace behält sich das Recht vor, die Projekte ohne Angabe
                von Gründen abzulehnen und den Zugang in den
                Finanzierungszeitraum zu sperren, auch wenn alle Voraussetzungen
                für den Start des Projektes vorliegen sollten.
              </li>
            </ol>
            <h2>§ 15 Verifizierung und Legitimation des Projektinitiators</h2>
            <ol>
              <li>
                commonsplace beauftragt zur geldwäscherechtlich notwendige
                Identitäts- und Legitimationsprüfung des Projektinitiators
                Dritte. Die Aufgaben werden von Stripe Technology Europe Ltd.,
                25-28 North Wall Quay, Dublin 1, Ireland als
                Zahlungsdienstanbieter übernommen.{' '}
              </li>
              <li>
                Das genaue Verfahren zur Verifizierung und Legitimation sowie
                den Status der Verifizierung kann der Projektinitiator innerhalb
                seines Projektes einsehen.
              </li>
              <li>
                Eine erfolgreiche Legitimation ist Voraussetzung für die
                Auszahlung jeglicher Unterstützung nach Erreichen der
                Fundingsumme.
              </li>
            </ol>
            <h2>§ 16 Ablauf des Projekt</h2>
            <ol>
              <li>
                Eine inhaltliche/rechtliche Kontrolle der Projektebeschreibung
                des Projektinitiators durch uns findet nicht statt. Wir behalten
                uns jedoch vor, ohne hierzu verpflichtet zu sein, Projektdaten
                vor Aktivierung oder zu einem späteren Zeitpunkt
                stichprobenartig zu prüfen und zum Schutz der sonstigen Nutzer
                oder der Rechte Dritter ganz oder in Teilen vorübergehend zu
                sperren, falls dies auf Grund von inhaltlichen Widersprüchen in
                der Projektebeschreibung oder Verdacht auf
                Schutzrechtsverletzungen notwendig erscheint. In diesem Fall
                werden wir den Projektinitiator hiervon in Kenntnis setzen, auf
                etwaige Probleme hinweisen und dem Projektinitiator Gelegenheit
                zur Konkretisierung, Entfernung oder Richtigstellung solcher
                Inhalte bieten.
              </li>
              <li>
                Wir behalten uns vor, das Einstellen von Projekten jederzeit von
                weiteren zusätzlichen Voraussetzungen abhängig zu machen
              </li>
              <li>
                Soweit es deinerseits als Projektinitiator zu Problemen oder
                Verzögerungen im Rahmen der Erfüllung der mit den Unterstützern
                geschlossenen Verträge kommen sollte, so bist du verpflichtet
                die Unterstützer stetig und aktiv hierüber sowie über die zur
                Problembehebung oder Beschleunigung ergriffenen Maßnahmen zu
                informieren.
              </li>
            </ol>
            <h2>§ 17 Keine Änderungen nach Abschluss der Projekt</h2>
            <ol>
              <li>
                Projekte können aus Dokumentationsgründen nicht gelöscht werden,
                wenn diese einmal in der Finanzierungsphase waren. Nach
                Abschluss eines Projektes sind die Daten des Projektes nicht
                mehr veränderbar. Möglich ist lediglich, neue Updates zu
                schreiben.
              </li>
              <li>
                Die Löschung des Profils des Projektinitiators hat nach
                Beendigung des Projektes keine Auswirkungen auf den Inhalt von
                beendeten Projekte.
              </li>
            </ol>
            <h2>§ 18 Rechteeinräumung durch den Projektinitiator</h2>
            <ol>
              <li>
                Unsere Plattform wächst durch stetige Verlinkung,
                Öffentlichkeitsarbeit durch uns wie auch durch die sonstigen
                Nutzer und teils auch gezielte Werbung. Dies ist auch in deinem
                Interesse und zu diesem Zweck hast du uns ausgewählt.
              </li>
              <li>
                Soweit wir zur Bewerbung deiner Projekt wie auch der Plattform
                im Allgemeinen auf unserer Plattform oder Internetseiten von
                Dritten deine Inhalte vervielfältigen, verbreiten, öffentlich
                zugänglich machen oder sonst verwerten müssen, so räumst du uns
                die zur Erreichung des Vertragszwecks – nämlich dem Erreichen
                eines größeren Adressatenkreises für alle – erforderlichen
                Nutzungsrechte ein.{' '}
              </li>
            </ol>
            <h2>
              § 19 Vorausabtretung hinsichtlich bestehender Vergütungsansprüche
            </h2>
            <ol>
              <li>
                Der Projektinitiator tritt hinsichtlich der Vergütungsansprüche,
                die bei der commonsplace UG (Haftungsbeschränkt) und/oder
                sonstigen Dritten gegen den Projektinitiator auf Grund einer
                Inanspruchnahme gesondert als solche ausgewiesener
                zahlungspflichtiger Leistungen (z.B. Transaktionsgebühren
                und/oder Provisionsvereinbarungen) entstehen und diesen auch als
                solche zustehen (§ 364 BGB), seine Zahlungsansprüche gegenüber
                dem Zahlungsdiensteanbieter bis zur Höhe der jeweiligen
                Ansprüche ab.
              </li>
              <li>
                Die darüber hinausgehende Unterstützungssumme zahlt der
                Zahlungsdienstleister an den Projektinitiator aus.
              </li>
            </ol>
            <h2>§ 20 Änderungen unserer Allgemeinen Nutzungsbedingungen</h2>
            <ol>
              <li>
                Wir sind berechtigt, den Inhalt dieser Allgemeinen
                Nutzungsbedingungen zu ändern.
              </li>
              <li>
                In diesem Fall werden wir dem Nutzer den Änderungsvorschlag
                unter Benennung des Grundes und des konkreten Umfangs in
                Textform (z.B. per E-Mail) mitteilen. Die Änderungen gelten als
                vom Nutzer genehmigt, wenn der Nutzer diesen nicht in mindestens
                Textform widerspricht. Wir werden den Nutzer auf diese Folge im
                Mitteilungsschreiben besonders hinweisen. Der Widerspruch muss
                innerhalb von sechs Wochen nach Zugang der Mitteilung über die
                Änderung bei uns eingegangen sein. Übt der Nutzer sein
                Widerspruchsrecht aus, gilt der Änderungswunsch als abgelehnt.
                Im Falle der Ablehnung muss der widersprechende Nutzer mit einer
                ordentlichen Kündigung seitens commonsplace i.S.d. § 13 rechnen.
              </li>
            </ol>
            <h2>
              § 21 Hinweis auf Europäische Online-Streitbeilegungs-Plattform für
              Verbraucher sowie Information i.S.d. § 36 VSBG
            </h2>
            <ol>
              <li>
                Nach der EU-Verordnung Nr. 524/2013 über die
                Online-Streitbeilegung in Verbraucherangelegenheiten ist für
                Verbraucher die Möglichkeit vorgesehen, Streitigkeiten mit
                Unternehmern im Zusammenhang mit Online-Kaufverträgen oder
                OnlineDienstleistungsverträgen außergerichtlich über eine
                Online-Plattform (OS-Plattform) beizulegen. Diese Plattform
                wurde von der EUKommission eingerichtet und über den folgenden
                Link zugänglich gemacht: http://ec.europa.eu/consumers/odr/.
              </li>
              <li>
                Wir sind weder bereit noch verpflichtet, an einem
                Streitbeilegungsverfahren vor einer
                Verbraucherschlichtungsstelle teilzunehmen (vgl. § 36 VSBG)
              </li>
            </ol>
            <h2>§ 22 Anwendbares Recht, Gerichtsstand</h2>
            <ol>
              <li>
                Es gilt das Recht der Bundesrepublik Deutschland unter
                Ausschluss des UN-Kaufrechts. Zwingende Bestimmungen des
                Staates, in dem der Nutzer seinen gewöhnlichen Aufenthalt hat,
                bleiben jedoch unberührt.
              </li>
              <li>
                Erfüllungsort und Gerichtsstand für alle Streitigkeiten aus
                diesem Vertrag ist bei Verträgen mit Kaufleuten, juristischen
                Personen des öffentlichen Rechts oder öffentlich-rechtlichen
                Sondervermögen Dresden, Deutschland.
              </li>
            </ol>

            <p className="end">Stand: Januar 2021</p>
          </ANBWrapper>
        </Container>
      </Layout>
    );
  }
}
