import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Input, DatePicker, Spin } from 'antd';
import { authActions } from 'redux/actions';
import {
  H2,
  FormWrapper,
  PrimaryButton,
  Colors,
  dateFormat,
  eighteenYearsFromNow,
} from 'utils';
import { AddressForm } from 'components';
import styled from '@emotion/styled';
import moment from 'moment';

const FormItem = Form.Item;

const UserInfoWrapper = styled.div`
  h2 {
    color: ${Colors.GreenColor};
    display: inline-block;
    padding: 10px;
    border: 1px solid ${Colors.GreenColor};
    border-radius: 10px;
    font-size: 35px;
  }
  .row-wrapper {
    display: flex;
    justify-content: space-between;
    .ant-form-item {
      width: 48%;
    }
    &.address-wrapper {
      .ant-form-item {
        width: 18%;
        &:first-of-type {
          width: 78%;
        }
      }
    }
    &.phone-wrapper {
      .ant-form-item {
        width: 100%;
      }
    }
  }

  .ant-form-item {
    display: block;
    .ant-form-item-label {
      color: ${Colors.GreenColor};
      margin-bottom: 10px;
      label {
        font-weight: bold;
        font-size: 22px;
      }
    }
    .ant-picker,
    .ant-input,
    .ant-select-selector {
      width: 100%;
      padding: 10px;
      border: 1px solid ${Colors.GreenColor};
      border-radius: 10px;
      height: 44px;
      line-height: 44px;
      vertical-align: middle;
    }
  }
`;

export class UserInfo extends Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    user: PropTypes.object.isRequired,
    updateProfile: PropTypes.func.isRequired,
  };
  onFinish = (values) => {
    const { updateProfile } = this.props;
    values.birthday = Number(moment(values.birthday).format('X'));
    updateProfile(values);
  };
  render() {
    const { loading, user } = this.props;
    return (
      <UserInfoWrapper>
        <H2>Persönliche Daten</H2>
        <Form
          initialValues={{
            ...user,
            birthday: user.birthday ? moment.unix(user.birthday) : null,
          }}
          onFinish={this.onFinish}
        >
          <Spin spinning={loading}>
            <FormWrapper>
              <>
                <div className="row-wrapper">
                  <FormItem
                    name="first_name"
                    label="Vorname"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </FormItem>
                  <FormItem
                    name="last_name"
                    label="Nachname"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </FormItem>
                </div>
                <AddressForm />
                <div className="row-wrapper">
                  <FormItem name="birthday" label="Geburtsdatum">
                    <DatePicker
                      format={dateFormat}
                      disabledDate={(d) =>
                        !d || d.isAfter(eighteenYearsFromNow)
                      }
                    />
                  </FormItem>
                  <FormItem name="nationality" label="Staatsangehörigkeit">
                    <Input />
                  </FormItem>
                </div>
                <div className="row-wrapper phone-wrapper">
                  <FormItem name="phone_number" label="Tel. Nr">
                    <Input />
                  </FormItem>
                </div>
                <FormItem>
                  <PrimaryButton
                    type="primary"
                    htmltype="submit"
                    minWidth="150px"
                  >
                    Speichern
                  </PrimaryButton>
                </FormItem>
              </>
            </FormWrapper>
          </Spin>
        </Form>
      </UserInfoWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, loading, errors } = state.authentication;
  return {
    user,
    loading,
    errors,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateProfile: (user) => dispatch(authActions.updateProfile(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo);
