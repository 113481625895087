import React from 'react';
import { AcceptIcon, RejectIcon } from 'images';
import { Colors, dateFormat, SvgWrapper } from 'utils';
import styled from '@emotion/styled';
import moment from 'moment';
import PropTypes from 'prop-types';

const AcceptEditWrapper = styled.div`
  background: ${Colors.secondaryColor};
  padding: 20px 30px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  border-radius: 15px;
  position: relative;
  p {
    font-weight: bold;
    font-size: 22px;
    color: ${Colors.white};
    margin: 0;
    margin-left: 15px;
  }
  .remove-button {
    background: none;
    border: none;
    position: absolute;
    top: 10px;
    right: 10px;
  }
`;

export default function AcceptEdit({ time, projectId, removeEdit }) {
  return (
    <AcceptEditWrapper>
      <SvgWrapper width="50px" height="50px">
        <AcceptIcon />
      </SvgWrapper>
      <p>
        Veränderung vom {moment.unix(time).format(dateFormat)} wurde genehmigt
      </p>
      <button onClick={() => removeEdit(projectId)} className="remove-button">
        <SvgWrapper width="20px" height="20px">
          <RejectIcon color={Colors.secondaryColor} />
        </SvgWrapper>
      </button>
    </AcceptEditWrapper>
  );
}

AcceptEdit.propTypes = {
  time: PropTypes.number.isRequired,
  projectId: PropTypes.number.isRequired,
  removeEdit: PropTypes.func.isRequired,
};
