import styled from '@emotion/styled';
import device from './device';
const SectionWrapper = styled.section`
  padding: 75px 0;
  @media ${device.allMobile} {
    padding: 20px 0;
  }
  @media ${device.laptop} {
    padding: 50px 0;
  }
`;

export default SectionWrapper;
