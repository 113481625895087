import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AdminActions } from 'redux/actions';
import { Layout } from 'components';
import { Form, Select, Spin } from 'antd';
import { Container, H1, PrimaryButton, Section } from 'utils';
import { adminServices } from 'services';

const { Option } = Select;
const FormItem = Form.Item;

export class SelectHomeProject extends Component {
  componentDidMount() {
    const { getAllProjects } = this.props;
    this.getSelectedProjects();
    getAllProjects(100000, 1, 'accepted');
  }
  static propTypes = {
    projects: PropTypes.array.isRequired,
    getAllProjects: PropTypes.func.isRequired,
    setHomeProjects: PropTypes.func.isRequired,
    // loading: PropTypes.bool.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      showError: false,
      selectedAllProjects: [],
      loading: true,
      selectedProject1: '',
      selectedProject2: '',
      selectedProject3: '',
      selectedProject4: '',
      selectedProject5: '',
      selectedProject6: '',
      selectedProject7: '',
      selectedProject8: '',
      selectedProject9: '',
      selectedProject10: '',
      selectedProject11: '',
      selectedProject12: '',
    };
  }

  onFinish = (values) => {
    const { setHomeProjects } = this.props;
    const {
      selectedProject1,
      selectedProject2,
      selectedProject3,
      selectedProject4,
      selectedProject5,
      selectedProject6,
      selectedProject7,
      selectedProject8,
      selectedProject9,
      selectedProject10,
      selectedProject11,
      selectedProject12,
    } = this.state;

    if (
      !selectedProject1 ||
      !selectedProject2 ||
      !selectedProject3 ||
      !selectedProject4 ||
      !selectedProject5 ||
      !selectedProject6 ||
      !selectedProject7 ||
      !selectedProject8 ||
      !selectedProject9 ||
      !selectedProject10 ||
      !selectedProject11 ||
      !selectedProject12
    ) {
      return this.setState({ showError: true });
    }

    setHomeProjects([
      selectedProject1,
      selectedProject2,
      selectedProject3,
      selectedProject4,
      selectedProject5,
      selectedProject6,
      selectedProject7,
      selectedProject8,
      selectedProject9,
      selectedProject10,
      selectedProject11,
      selectedProject12,
    ]);
  };
  async getSelectedProjects() {
    try {
      const res = await adminServices.getAllSelectedProjects();
      this.setState({ selectedAllProjects: res?.projects });
      const selectedProjects = {};
      res?.projects.forEach((el, idx) => {
        selectedProjects[`selectedProject${idx + 1}`] = el.id;
      });
      this.setState(selectedProjects);
      this.setState({ loading: false });
    } catch (error) {
      this.setState({ loading: false });
    }
  }

  render() {
    const { loading } = this.state;
    const projects = this.props.projects.projects;

    return (
      <Layout>
        <Container>
          <Section>
            <H1>Select Project</H1>
            <Spin spinning={loading}>
              <Form onFinish={this.onFinish}>
                <FormItem
                  valuePropName="defaultValue"
                  name={'selectedProject1'}
                  label="Select Project 1"
                >
                  <Select
                    showSearch
                    filterOption={false}
                    style={{ width: '100%' }}
                    defaultValue={this.state.selectedProject1}
                    value={this.state.selectedProject1}
                    onChange={(value) => {
                      this.setState({ selectedProject1: value });
                    }}
                  >
                    {projects &&
                      projects.map((project) => (
                        <Option
                          key={`${JSON.stringify(project)}-1`}
                          value={project.id}
                        >
                          {project.title}
                        </Option>
                      ))}
                  </Select>
                  {this.state.showError && !this.state.selectedProject1 && (
                    <span style={{ color: 'red' }}>
                      Please input your Project1!
                    </span>
                  )}
                </FormItem>
                <FormItem
                  valuePropName="defaultValue"
                  name={'selectedProject2'}
                  label="Select Project 2"
                >
                  <Select
                    showSearch
                    filterOption={false}
                    style={{ width: '100%' }}
                    defaultValue={this.state.selectedProject2}
                    value={this.state.selectedProject2}
                    onChange={(value) => {
                      this.setState({ selectedProject2: value });
                    }}
                  >
                    {projects &&
                      projects.map((project) => (
                        <Option
                          key={`${JSON.stringify(project)}-6`}
                          value={project.id}
                        >
                          {project.title}
                        </Option>
                      ))}
                  </Select>
                  {this.state.showError && !this.state.selectedProject2 && (
                    <span style={{ color: 'red' }}>
                      Please input your Project2!
                    </span>
                  )}
                </FormItem>
                <FormItem
                  valuePropName="defaultValue"
                  name={'selectedProject3'}
                  label="Select Project 3"
                >
                  <Select
                    showSearch
                    filterOption={false}
                    style={{ width: '100%' }}
                    defaultValue={this.state.selectedProject3}
                    value={this.state.selectedProject3}
                    onChange={(value) => {
                      this.setState({ selectedProject3: value });
                    }}
                  >
                    {projects &&
                      projects.map((project) => (
                        <Option
                          key={`${JSON.stringify(project)}-2`}
                          value={project.id}
                        >
                          {project.title}
                        </Option>
                      ))}
                  </Select>
                  {this.state.showError && !this.state.selectedProject3 && (
                    <span style={{ color: 'red' }}>
                      Please input your Project3!
                    </span>
                  )}
                </FormItem>
                <FormItem
                  valuePropName="defaultValue"
                  name={'selectedProject4'}
                  label="Select Project 4"
                >
                  <Select
                    showSearch
                    filterOption={false}
                    style={{ width: '100%' }}
                    defaultValue={this.state.selectedProject4}
                    value={this.state.selectedProject4}
                    onChange={(value) => {
                      this.setState({ selectedProject4: value });
                    }}
                  >
                    {projects &&
                      projects.map((project) => (
                        <Option
                          key={`${JSON.stringify(project)}-3`}
                          value={project.id}
                        >
                          {project.title}
                        </Option>
                      ))}
                  </Select>
                  {this.state.showError && !this.state.selectedProject4 && (
                    <span style={{ color: 'red' }}>
                      Please input your Project4!
                    </span>
                  )}
                </FormItem>
                <FormItem
                  valuePropName="defaultValue"
                  name={'selectedProject5'}
                  label="Select Project 5"
                >
                  <Select
                    showSearch
                    filterOption={false}
                    style={{ width: '100%' }}
                    defaultValue={this.state.selectedProject5}
                    value={this.state.selectedProject5}
                    onChange={(value) => {
                      this.setState({ selectedProject5: value });
                    }}
                  >
                    {projects &&
                      projects.map((project) => (
                        <Option
                          key={`${JSON.stringify(project)}-4`}
                          value={project.id}
                        >
                          {project.title}
                        </Option>
                      ))}
                  </Select>
                  {this.state.showError && !this.state.selectedProject5 && (
                    <span style={{ color: 'red' }}>
                      Please input your Project5!
                    </span>
                  )}
                </FormItem>
                <FormItem
                  valuePropName="defaultValue"
                  name={'selectedProject6'}
                  label="Select Project 6"
                >
                  <Select
                    showSearch
                    filterOption={false}
                    style={{ width: '100%' }}
                    defaultValue={this.state.selectedProject6}
                    value={this.state.selectedProject6}
                    onChange={(value) => {
                      this.setState({ selectedProject6: value });
                    }}
                  >
                    {projects &&
                      projects.map((project) => (
                        <Option
                          key={`${JSON.stringify(project)}-5`}
                          value={project.id}
                        >
                          {project.title}
                        </Option>
                      ))}
                  </Select>
                  {this.state.showError && !this.state.selectedProject6 && (
                    <span style={{ color: 'red' }}>
                      Please input your Project6!
                    </span>
                  )}
                </FormItem>
                <FormItem
                  valuePropName="defaultValue"
                  name={'selectedProject7'}
                  label="Select Project 7"
                >
                  <Select
                    showSearch
                    filterOption={false}
                    style={{ width: '100%' }}
                    defaultValue={this.state.selectedProject7}
                    value={this.state.selectedProject7}
                    onChange={(value) => {
                      this.setState({ selectedProject7: value });
                    }}
                  >
                    {projects &&
                      projects.map((project) => (
                        <Option
                          key={`${JSON.stringify(project)}-5`}
                          value={project.id}
                        >
                          {project.title}
                        </Option>
                      ))}
                  </Select>
                  {this.state.showError && !this.state.selectedProject7 && (
                    <span style={{ color: 'red' }}>
                      Please input your Project7!
                    </span>
                  )}
                </FormItem>
                <FormItem
                  valuePropName="defaultValue"
                  name={'selectedProject8'}
                  label="Select Project 8"
                >
                  <Select
                    showSearch
                    filterOption={false}
                    style={{ width: '100%' }}
                    defaultValue={this.state.selectedProject8}
                    value={this.state.selectedProject8}
                    onChange={(value) => {
                      this.setState({ selectedProject8: value });
                    }}
                  >
                    {projects &&
                      projects.map((project) => (
                        <Option
                          key={`${JSON.stringify(project)}-5`}
                          value={project.id}
                        >
                          {project.title}
                        </Option>
                      ))}
                  </Select>
                  {this.state.showError && !this.state.selectedProject8 && (
                    <span style={{ color: 'red' }}>
                      Please input your Project8!
                    </span>
                  )}
                </FormItem>
                <FormItem
                  valuePropName="defaultValue"
                  name={'selectedProject9'}
                  label="Select Project 9"
                >
                  <Select
                    showSearch
                    filterOption={false}
                    style={{ width: '100%' }}
                    defaultValue={this.state.selectedProject9}
                    value={this.state.selectedProject9}
                    onChange={(value) => {
                      this.setState({ selectedProject9: value });
                    }}
                  >
                    {projects &&
                      projects.map((project) => (
                        <Option
                          key={`${JSON.stringify(project)}-5`}
                          value={project.id}
                        >
                          {project.title}
                        </Option>
                      ))}
                  </Select>
                  {this.state.showError && !this.state.selectedProject9 && (
                    <span style={{ color: 'red' }}>
                      Please input your Project9!
                    </span>
                  )}
                </FormItem>
                <FormItem
                  valuePropName="defaultValue"
                  name={'selectedProject10'}
                  label="Select Project 10"
                >
                  <Select
                    showSearch
                    filterOption={false}
                    style={{ width: '100%' }}
                    defaultValue={this.state.selectedProject10}
                    value={this.state.selectedProject10}
                    onChange={(value) => {
                      this.setState({ selectedProject10: value });
                    }}
                  >
                    {projects &&
                      projects.map((project) => (
                        <Option
                          key={`${JSON.stringify(project)}-5`}
                          value={project.id}
                        >
                          {project.title}
                        </Option>
                      ))}
                  </Select>
                  {this.state.showError && !this.state.selectedProject10 && (
                    <span style={{ color: 'red' }}>
                      Please input your Project10!
                    </span>
                  )}
                </FormItem>
                <FormItem
                  valuePropName="defaultValue"
                  name={'selectedProject11'}
                  label="Select Project 11"
                >
                  <Select
                    showSearch
                    filterOption={false}
                    style={{ width: '100%' }}
                    defaultValue={this.state.selectedProject11}
                    value={this.state.selectedProject11}
                    onChange={(value) => {
                      this.setState({ selectedProject11: value });
                    }}
                  >
                    {projects &&
                      projects.map((project) => (
                        <Option
                          key={`${JSON.stringify(project)}-5`}
                          value={project.id}
                        >
                          {project.title}
                        </Option>
                      ))}
                  </Select>
                  {this.state.showError && !this.state.selectedProject11 && (
                    <span style={{ color: 'red' }}>
                      Please input your Project11!
                    </span>
                  )}
                </FormItem>
                <FormItem
                  valuePropName="defaultValue"
                  name={'selectedProject12'}
                  label="Select Project 12"
                >
                  <Select
                    showSearch
                    filterOption={false}
                    style={{ width: '100%' }}
                    defaultValue={this.state.selectedProject12}
                    value={this.state.selectedProject12}
                    onChange={(value) => {
                      this.setState({ selectedProject12: value });
                    }}
                  >
                    {projects &&
                      projects.map((project) => (
                        <Option
                          key={`${JSON.stringify(project)}-5`}
                          value={project.id}
                        >
                          {project.title}
                        </Option>
                      ))}
                  </Select>
                  {this.state.showError && !this.state.selectedProject12 && (
                    <span style={{ color: 'red' }}>
                      Please input your Project12!
                    </span>
                  )}
                </FormItem>

                <FormItem valuePropName="defaultValue">
                  <PrimaryButton
                    type="primary"
                    htmltype="submit"
                    minWidth="100%"
                  >
                    Submit
                  </PrimaryButton>
                </FormItem>
              </Form>
            </Spin>
          </Section>
        </Container>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  const { projects, loading } = state.admin;
  return {
    projects,
    loading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllProjects: (perPage, page, status) =>
    dispatch(AdminActions.getAllProjects(perPage, page, status)),
  setHomeProjects: (projectsIdsArray) =>
    dispatch(AdminActions.setHomeProjects(projectsIdsArray)),
});
export default connect(mapStateToProps, mapDispatchToProps)(SelectHomeProject);
