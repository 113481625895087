import React, { Component } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { SvgWrapper, H1, Container, Colors, isMobile, device } from 'utils';
import { BaseLayout } from 'components';
import {
  ShortBackground,
  LowersIcons,
  CheckIcon,
  BeforeBackground,
  AfterBackground,
} from 'images';
import CloseIcon from 'images/close.png';
import { Helmet } from 'react-helmet';
const Intro = styled.div`
  text-align: center;
  h1 {
    font-size: 55px;
    @media ${device.allMobile} {
      font-size: 30px;
    }
  }
`;
const GuidelinesWrapper = styled.div`
  margin-bottom: 100px;
  p {
    text-align: center;
    font-size: 22px;
    @media ${device.allMobile} {
      font-size: 18px;
    }
  }
  .icon {
    text-align: center;
    margin: 70px auto;
  }
  ul {
    max-width: 950px;
    margin: auto;
    margin-top: 100px;
    margin-bottom: 100px;
    list-style-image: url(${CloseIcon});
    @media ${device.allMobile} {
      margin-top: 25px;
      margin-bottom: 25px;
    }
    li {
      font-size: 22px;
      margin: 15px 0;
      @media ${device.allMobile} {
        font-size: 18px;
      }
    }
  }
  .text {
    font-size: 30px;
    max-width: 1150px;
    margin: auto;
    @media ${device.allMobile} {
      font-size: 20px;
    }
  }
  .center {
    max-width: 950px;
    margin: auto;
    text-align: center;
    .margin {
      margin: 100px auto;
      @media ${device.allMobile} {
        margin: 25px auto;
      }
    }
    h2 {
      margin-top: 25px;
      font-size: 25px;
    }

    .title {
      font-size: 50px;
      margin: 100px auto;
      @media ${device.allMobile} {
        font-size: 30px;
      }
    }
  }
  .check-icon {
    line-height: 28px;
  }
  .last-ul {
    text-align: left;
  }
`;

const MuhammedQuot = styled.section`
  padding: 250px auto;
  text-align: center;
  font-family: 'Shadows Into Light', cursive;
  @media ${device.allMobile} {
    padding: 50px auto;
  }
  p {
    font-size: 25px;
    max-width: 80%;
    margin: auto;
    @media ${device.allMobile} {
      font-size: 22px;
      max-width: 100%;
    }
  }
  q {
    display: block;
    position: relative;
    max-width: 100%;
    font-size: 30px;
    margin-bottom: 60px;
    @media ${device.allMobile} {
      font-size: 20px;
      max-width: 100%;
      margin-bottom: 30px;
    }
    &::before {
      content: '“';
      font-size: 500px;
      line-height: 1;
      color: ${Colors.secondaryColor};
      position: absolute;
      top: -159px;
      left: -150px;
      @media ${device.allMobile} {
        font-size: 50px;
        top: -7px;
        left: -2px;
      }
      @media ${device.tablet} {
        font-size: 300px;
        top: -57px;
        left: -48px;
      }
      @media ${device.minLaptop} {
        font-size: 300px;
        top: -86px;
        left: -55px;
      }
    }
    &::after {
      content: '„';
      font-size: 500px;
      line-height: 1;
      position: absolute;
      color: ${Colors.secondaryColor};
      bottom: -150px;
      right: -150px;
      @media ${device.allMobile} {
        font-size: 50px;
        bottom: -15px;
        right: -3px;
      }
      @media ${device.tablet} {
        font-size: 300px;
        bottom: -63px;
        right: -55px;
      }
      @media ${device.minLaptop} {
        font-size: 300px;
        bottom: -76px;
        right: -85px;
      }
    }
    & + p {
      position: relative;
      &:after {
        content: '';
        width: 300px;
        height: 4px;
        display: inline-block;
        background: ${Colors.secondaryColor};
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -25px;
        @media ${device.allMobile} {
          width: 100px;
          top: -25px;
        }
      }
    }
  }
  span {
    font-size: 150px;
    color: ${Colors.secondaryColor};
    font-weight: bold;
    @media ${device.allMobile} {
      font-size: 70px;
    }
  }
`;
const QouteWrapper = styled.div`
  .container {
    background: #f9f9f9;
    margin-top: -8px;
  }
  .after-background {
    position: relative;
    margin-top: -5px;
    z-index: -1;
  }
`;
export default class GuidelinesPage extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
  };

  render() {
    return (
      <div>
      <Helmet>
        <meta
          name="description"
          content="Mehr über die Richtlinien von commonsplace erfahren. Engagement für transparente und faire Praktiken in der Projektfinanzierung."
        />
        </Helmet>
      <BaseLayout
        maxHeightProps="500px"
        maxHeightMobileProps="250px"
        container={false}
        img={ShortBackground}
        bannerComponent={
          <Intro>
            <H1>Richtlinien</H1>
          </Intro>
        }
      >
        <>
          <Container>
            <GuidelinesWrapper>
              <div className="icon">
                <SvgWrapper
                  width={isMobile() ? '100%' : '550px'}
                  height={'auto'}
                >
                  <LowersIcons />
                </SvgWrapper>
              </div>
              <p>
                commonsplace ist die muslimische Plattform für Ideen und
                Erfindergeist aller Art. Der Schutz unserer Community steht an
                erster Stelle. Mit den Regelnsorgen wir für eine geregelte,
                selbstorganisierte und harmonisierende Community. Als Nutzer
                dieser Plattform verpflichtest du dich, folgende
                Regelneinzuhalten und zu respektieren:
              </p>

              <ul>
                <li>
                  Die vollständige Angabe wahrheitsgetreuer Inhalte ist
                  unabdinglich. Falsche, irreführende oder ungenaue Inhalte
                  werden abgemahnt.
                </li>
                <li>
                  Gegenseitige Rücksichtnahme sowie fairer und angemessener
                  Umgang mit der Community wird vorausgesetzt.
                </li>
                <li>
                  Inhalte, die zu Personenmissbrauch aufrufen, Personen
                  diskriminieren, belästigen, bedrohen oder die Privatsphäre von
                  Personen verletzen, sind unzulässig und können zu einer
                  strafgerichtlichen Verfolgung führen.
                </li>
                <li>
                  Inhalte, die Personen aufgrund von Ethnie, sexueller
                  Orientierung, Behinderung, Religion, nationaler Herkunft,
                  Bildungsstand oder sozialer Klasse diskriminieren, sind
                  unzulässig.
                </li>
                <li>
                  Gewaltverherrlichende, zu Terrorismus aufrufende oder auf
                  gesellschaftliche Spaltung und Konfrontation abzielende
                  Projekte sind verboten und werden strafgerichtlich verfolgt.
                </li>
                <li>
                  Islamisch nichtkonforme und sittenwidrige Inhalte sind
                  unzulässig.
                </li>
                <li>
                  Volksverhetzende Inhalte, oder solche, die die
                  gesellschaftliche Harmonie gefährden oder gegen die
                  freiheitlich demokratische Grundordnung gerichtet sind werden
                  gelöscht.
                </li>
                <li>
                  Politisch motivierte Boykotte oder Kampagnen gegen Personen
                  des öffentlichen Lebens, PolitikerInnen, politische Parteien
                  oder politisch ausgerichtete Organisationen sind untersagt.
                </li>
                <li>Mehrfachregistrierungen sind nicht gestattet.</li>
                <li>
                  Geschäftsgeheimnisse, das geistige Eigentum anderer oder
                  andere vertrauliche Inhalte dürfen nicht veröffentlich werden.
                </li>
                <li>
                  Inhalte, die illegale Aktivitäten befürworten oder der
                  Diskussion beabsichtigter illegaler Inhalte dienen, sind
                  untersagt.
                </li>
                <li>
                  Eine missbräuchliche Nutzung der Plattform, die das
                  ordnungsgemäße Funktionieren der Plattform deaktivieren,
                  überlasten oder beeinträchtigen könnte, ist verboten.
                </li>
                <li>
                  Spam oder andere Werbungen, Umfragen, das Versenden von
                  Schneeballsystemen, das Werben für kommerzielle Unternehmen
                  sowie die anderweitige Beteiligung an kommerziellen
                  Aktivitäten auf der Plattform sind nicht erlaubt.
                </li>
                <li>
                  Alle Spenden, die an ein Projekt eines Projekterstellers
                  gegeben werden, dürfen ausschließlich für das angegebene
                  Projekt verwendet werden.
                </li>
              </ul>
              {/* <div className="center">
              <MuhammedQuot>
                <q>
                  Wer die höchste Stufe im Paradies erreichen möchte, der soll
                  mit der Gemeinde sein.
                </q>
                <p>[Musnad Ahmad]</p>
              </MuhammedQuot>
            </div> */}
              <p className="text">
                commonsplace ist die deutsch-muslimische crowdfunding-Plattform.
                Sie bietet eine digitale Infrastruktur, die visionäre, kreative
                und engagierte Muslime mit Tatendrang und freigiebige
                Unterstützer verbindet.
              </p>

              <div className="center">
                <h2 className="title">Ein Ort für…</h2>
                <h2>
                  <SvgWrapper
                    width={'20px'}
                    height={'20px'}
                    className="check-icon"
                  >
                    <CheckIcon />
                  </SvgWrapper>
                  ...einzigartige Ideen!
                </h2>
                <p>Entfaltung durch Vielfalt.</p>
                <h2>
                  <SvgWrapper
                    width={'20px'}
                    height={'20px'}
                    className="check-icon"
                  >
                    <CheckIcon />
                  </SvgWrapper>
                  ...unabhängiges Wachsen!
                </h2>
                <p>Mündigkeit der muslimischen Community.</p>
                <h2>
                  <SvgWrapper
                    width={'20px'}
                    height={'20px'}
                    className="check-icon"
                  >
                    <CheckIcon />
                  </SvgWrapper>
                  ...gegenseitige Fürsorge, gegenseitigen Respekt!
                </h2>
                <p>
                  Alle für Einen und Einer für alle! Es braucht ein ganzes Dorf,
                  um ein Kind großzuziehen.
                </p>
                <h2>
                  <SvgWrapper
                    width={'20px'}
                    height={'20px'}
                    className="check-icon"
                  >
                    <CheckIcon />
                  </SvgWrapper>
                  ...gegenseitiges Kennenlernen!
                </h2>
                <p>Wir möchten uns kennenlernen und vernetzen!</p>
                <h2>
                  <SvgWrapper
                    width={'20px'}
                    height={'20px'}
                    className="check-icon"
                  >
                    <CheckIcon />
                  </SvgWrapper>
                  ...Vielfalt und Offenheit!
                </h2>
                <p>
                  Wir glauben an eine kunterbunte Gemeinschaft, die mit ihren
                  Werten und Prinzipien die Gesellschaft bereichert.
                </p>
                <h2>
                  <SvgWrapper
                    width={'20px'}
                    height={'20px'}
                    className="check-icon"
                  >
                    <CheckIcon />
                  </SvgWrapper>
                  ...Originalität!
                </h2>
                <p>
                  Wir glauben an tief verborgene Talente in jedem einzelnen
                  Individuum.
                </p>
                <h2>
                  <SvgWrapper
                    width={'20px'}
                    height={'20px'}
                    className="check-icon"
                  >
                    <CheckIcon />
                  </SvgWrapper>
                  ...zivilgesellschaftliche Power!
                </h2>
                <p>
                  Ob Entrepreneurship, Social Business, Stiftungen, soziale
                  Initiativen, Vereine u.v.m.: Wir glauben daran, dass
                  einzigartige Ideen die Gesellschaft bereichern.
                </p>
                <h2>
                  <SvgWrapper
                    width={'20px'}
                    height={'20px'}
                    className="check-icon"
                  >
                    <CheckIcon />
                  </SvgWrapper>
                  ...Religiöses!
                </h2>
                <p>
                  Ob Moscheen, Bildung, Reisen u.v.m.: Wir glauben daran, dass
                  wir das religiöse Angebot gemeinsam auf eine neue Ebene
                  bringen können.
                </p>
                <h2>
                  <SvgWrapper
                    width={'20px'}
                    height={'20px'}
                    className="check-icon"
                  >
                    <CheckIcon />
                  </SvgWrapper>
                  ...Kunst und Kultur
                </h2>
                <p>
                  Wir glauben an die Veredlung des Menschen durch die
                  herausragende Bedeutung von Ästhetik.
                </p>
                <h2>
                  <SvgWrapper
                    width={'20px'}
                    height={'20px'}
                    className="check-icon"
                  >
                    <CheckIcon />
                  </SvgWrapper>
                  ...Nachhaltigkeit!
                </h2>
                <p>
                  Wir glauben an anhaltende soziale, ökonomische und ökologische
                  Lösungen!
                </p>
                <h2>
                  <SvgWrapper
                    width={'20px'}
                    height={'20px'}
                    className="check-icon"
                  >
                    <CheckIcon />
                  </SvgWrapper>
                  …Wohltätigkeiten und Nothilfen jeder Art!
                </h2>
                <p>
                  Wir sind davon überzeugt, dass wir gemeinsam Ungerechtigkeit,
                  Armut und Notfälle überwinden!
                </p>
                <h2>
                  <SvgWrapper
                    width={'20px'}
                    height={'20px'}
                    className="check-icon"
                  >
                    <CheckIcon />
                  </SvgWrapper>
                  ...Transparenz und Sicherheit!
                </h2>
                <p>
                  Wir überprüfen die Identität der Projektersteller und sorgen
                  dafür, dass die relevantesten Informationen über die Projekte
                  zur Verfügung gestellt werden. Wir gewährleisten transparente
                  und sichere Vorgänge und versichern, dass die Unterstützungen
                  ankommen.
                </p>
                <h2>
                  <SvgWrapper
                    width={'20px'}
                    height={'20px'}
                    className="check-icon"
                  >
                    <CheckIcon />
                  </SvgWrapper>
                  ... Kofinanzierung!
                </h2>
                <p>
                  Dein Projekt wird staatlich/öffentlich finanziert, und du
                  musst ein Eigenanteil zur Finanzierung deines Projektes
                  erbringen? Der Eigenanteil kann jetzt über deine Community auf
                  commonsplace erbracht werden!
                </p>
                <h2>
                  <SvgWrapper
                    width={'20px'}
                    height={'20px'}
                    className="check-icon"
                  >
                    <CheckIcon />
                  </SvgWrapper>
                  …Ehrlichkeit und Vertrauen!
                </h2>
                <p>
                  Wir schreiben Ehrlichkeit groß und vertrauen auf eine ehrliche
                  Community!
                </p>
              </div>
            </GuidelinesWrapper>
          </Container>
          <QouteWrapper>
            <div className="background">
              <SvgWrapper width="100%" height="100%">
                <BeforeBackground />
              </SvgWrapper>
            </div>
            <div className="container">
              <Container>
                <MuhammedQuot>
                  <p>
                    <q>
                      Und für jeden gibt es eine Richtung, die er einnimmt. So
                      wetteifert um die gottgefällig guten Taten! Allerorts, wo
                      auch immer ihr euch befindet, wird ALLAH euch
                      zusammenbringen, allesamt. Gewiß, ALLAH ist über alles
                      allmächtig.
                    </q>
                    <p>[2:148]</p>
                  </p>
                </MuhammedQuot>
              </Container>
            </div>
            <div className="after-background">
              <SvgWrapper width="100%" height="100%">
                <AfterBackground />
              </SvgWrapper>
            </div>
          </QouteWrapper>
          <Container>
            <GuidelinesWrapper>
              <div className="center">
                <h2>Wofür ist commonsplace nicht der richtige Ort:</h2>
                <ul className="last-ul">
                  <li> siehe Richtlinien</li>
                  <li>Privatreisen</li>
                  <li>Privaterwerb</li>
                  <li>Privatinvestitionen</li>
                  <li>
                    Ausbildung- und Studiumfinanzierung (Ausnahmen sind nach
                    vorheriger Absprache mit uns gestattet)
                  </li>
                </ul>
              </div>
            </GuidelinesWrapper>
          </Container>
        </>
      </BaseLayout>
      </div>
    );
  }
}
