import React, { Component } from 'react';
import { Header, Footer, LoginForm } from 'components';
import { Container, Modal } from 'utils';

import PropTypes from 'prop-types';
import styled from '@emotion/styled';
const LayoutWrapper = styled.div`
  & > .container {
    position: relative;
    // z-index: 2;
  }
`;

export default class Layout extends Component {
  state = {
    showModal: false,
  };
  handelCloseModal = () => {
    this.setState({ showModal: false });
  };
  handelShowModal = () => {
    this.setState({ showModal: true });
  };
  render() {
    const { showModal } = this.state;
    const { container, children } = this.props;
    return (
      <>
        <LayoutWrapper>
          <Header relativeHeader={true} showLogin={this.handelShowModal} />
          {container ? (
            <Container className="container">
              <div className="content-Wrapper">{children}</div>
            </Container>
          ) : (
            <div className="content-Wrapper">{children}</div>
          )}
          <Footer />
        </LayoutWrapper>
        <Modal showModal={showModal} handelCloseModal={this.handelCloseModal}>
          <LoginForm />
        </Modal>
      </>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.object,
  container: PropTypes.bool,
};
