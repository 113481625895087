import React, { Component } from 'react';
import { RichEditor } from 'components';
import { EditorState } from 'draft-js';
import styled from '@emotion/styled';
import { Colors, PrimaryButton } from 'utils';

const CommunicationPlanWrapper = styled.div`
  h1 {
    margin-bottom: 5px;
    & + p {
      margin: 0;
      font-size: 18px;
      margin-bottom: 25px;
    }
  }
  .checkbox-wrapper,
  .radio-wrapper {
    display: flex;
    flex-wrap: wrap;

    & > div {
      width: 33%;
    }
  }
  .submit-button {
    width: auto;
    margin-bottom: 0;
    margin-top: 20px;
    text-align: right;
  }
  .form-wrapper {
    background: ${Colors.grey};
    padding: 20px;
    margin-bottom: 20px;
    p {
      margin: 0;
      font-size: 15px;
      color: ${Colors.steel};
    }
  }
  /* The container */
  .container-checkbox {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .container-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  /* Create a custom checkbox */
  .container-checkbox .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
  }

  /* On mouse-over, add a grey background color */
  .container-checkbox:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  .container-checkbox input:checked ~ .checkmark {
    background-color: ${Colors.secondaryColor};
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .container-checkbox .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container-checkbox input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container-checkbox .checkmark:after {
    left: 7px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  /* The container */
  .container-radio {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default radio button */
  .container-radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  /* Create a custom radio button */
  .container-radio .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 50%;
  }

  /* On mouse-over, add a grey background color */
  .container-radio:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the radio button is checked, add a blue background */
  .container-radio input:checked ~ .checkmark {
    background-color: ${Colors.secondaryColor};
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .container-radio .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  .container-radio input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
  .container-radio .checkmark:after {
    top: 6px;
    left: 6px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
  }
`;

export default class CommunicationPlan extends Component {
  state = {
    editorState: EditorState.createEmpty(),
  };

  handleViewBlog = () => this.setState({ viewBlog: !this.state.viewBlog });
  handelEditorState = (editorState) => this.setState({ editorState });

  render() {
    const { editorState } = this.state;
    return (
      <CommunicationPlanWrapper>
        <h1>Communication Plan</h1>
        <p>
          Here's a list of a number of important considerations and questions to
          help you prepare your communication plan. Your communication plan is
          visible only to you.
        </p>
        <form>
          <div className="form-wrapper text-editor">
            <h2>Storytelling</h2>
            <RichEditor
              editorState={editorState}
              onChange={this.handelEditorState}
            />
            <p>
              Before starting your project, think about the types of news and
              stories you might share with supporters over the course of your
              campaign. We recommend that you create a plan for the different
              topic areas and decide which content to share over which
              communication channels.
            </p>
          </div>
          <div className="form-wrapper">
            <h2>Communication channels</h2>
            <div className="checkbox-wrapper">
              <div>
                <label className="container-checkbox" htmlFor="social-media">
                  Social media
                  <input
                    type="checkbox"
                    id="social-media"
                    name="social-media"
                    value="socialMedia"
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div>
                <label className="container-checkbox" htmlFor="yourWebsite">
                  Your website
                  <input
                    type="checkbox"
                    id="yourWebsite"
                    name="your-website"
                    value="yourWebsite"
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div>
                <label className="container-checkbox" htmlFor="PersonalE-mails">
                  Personal e-mails
                  <input
                    type="checkbox"
                    id="PersonalE-mails"
                    name="PersonalE-mails"
                    value="PersonalE-mails"
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div>
                <label className="container-checkbox" htmlFor="Newsletters">
                  Newsletters
                  <input
                    type="checkbox"
                    id="Newsletters"
                    name="newsletters"
                    value="Newsletters"
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div>
                <label className="container-checkbox" htmlFor="blog">
                  Blog
                  <input type="checkbox" id="blog" name="blog" value="blog" />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div>
                <label className="container-checkbox" htmlFor="press">
                  Press
                  <input
                    type="checkbox"
                    id="press"
                    name="press"
                    value="press"
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div>
                <label className="container-checkbox" htmlFor="event">
                  Event
                  <input
                    type="checkbox"
                    id="event"
                    name="event"
                    value="event"
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div>
                <label className="container-checkbox" htmlFor="Advertising">
                  Advertising (flyers, posters, etc.)
                  <input
                    type="checkbox"
                    id="Advertising"
                    name="Advertising"
                    value="Advertising"
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              <p>Which communication channels are you planning on using?</p>
            </div>
          </div>

          <div className="form-wrapper">
            <h2>Reach</h2>
            <div className="radio-wrapper">
              <div>
                <label className="container-radio" htmlFor="up-to-100">
                  up to 100 contacts
                  <input type="radio" id="up-to-100" name="radio" />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div>
                <label className="container-radio" htmlFor="100-500">
                  ca. 100 - 500
                  <input type="radio" id="100-500" name="radio" />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div>
                <label className="container-radio" htmlFor="500-1000">
                  ca. 500 - 1.000
                  <input type="radio" id="500-1000" name="radio" />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div>
                <label className="container-radio" htmlFor="1000-2500">
                  ca. 1.000 - 2.500
                  <input type="radio" id="1000-2500" name="radio" />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div>
                <label className="container-radio" htmlFor="2500-10000">
                  ca. 2.500 - 10.000
                  <input type="radio" id="2500-10000" name="radio" />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div>
                <label className="container-radio" htmlFor="more-than-10000">
                  more than 10.000
                  <input type="radio" id="more-than-10000" name="radio" />
                  <span className="checkmark"></span>
                </label>
              </div>
              <p>
                About how large is the network of people you are already able to
                reach (taking into account existing newsletters, e-mail
                contacts, Facebook fans, Twitter followers, etc.)? The larger
                your network is, the more realistic it will be for you to reach
                a higher funding goal.
              </p>
              <p>
                Support for a crowdfunding project almost always begins with
                your own network – with family, fans, or your own audience and
                your networks. If you actively communicate with supporters and
                your project starts to grow, slowly but surely, people who do
                not know you personally will also begin to support your project.
              </p>
            </div>
          </div>
          <div className="submit-button">
            <PrimaryButton>Save</PrimaryButton>
          </div>
        </form>
      </CommunicationPlanWrapper>
    );
  }
}
