import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import { BaseLayout } from 'components';
import { defaultImage } from 'images';
import { PrimaryButton, Colors, device } from 'utils';

import {
  ProjectDetails,
  ProjectTab,
  // Tabs,
  RelatedProjects,
  Rewards,
  TimeLineTab,
  BannerComponent,
  Hadeth,
} from './components';
import { Spin } from 'antd';
import { ProjectsActions, DonationsActions } from 'redux/actions';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const ProjectWrapper = styled.div`
  .content-Wrapper {
    max-width: 1440px;
    margin: auto;
    @media (max-width: 1441px) {
      max-width: 950px;
    }
  }
  .start-project__component {
    padding: 0 20px;
    h1 {
      font-size: 50px;
      margin: 50px auto;
      max-width: 950px;
      font-weight: bold;
      @media ${device.allMobile} {
        font-size: 25px;
        margin: 15px auto;
      }
      @media ${device.tablet} {
        font-size: 35px;
      }
    }
  }
`;
const StartProjectWrapper = styled.div`
  margin: 100px auto;
  text-align: center;

  h1 {
    font-size: 40px;
    color: ${Colors.GreenColor};
    font-weight: bold;
    @media ${device.allMobile} {
      font-size: 25px;
    }
    @media ${device.tablet} {
      font-size: 30px;
    }
  }
`;

export class ProjectPage extends Component {
  componentDidMount() {
    const {
      match: {
        params: { url, id },
      },
      location,
      showProject,
      project,
      updateDraft,
    } = this.props;
    const isViewPage = location.pathname.startsWith('/view');

    if (isViewPage) {
      if (!(project && project.id === id)) {
        updateDraft(id, {});
      }
    } else if (url) {
      showProject(url);
      this.setState({ url });
    }
  }
  state = { url: null, rewardsExist: false };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.url && prevState.url !== nextProps.match.params.url) {
      nextProps.showProject(nextProps.match.params.url);
      return {
        url: nextProps.match.params.url,
      };
    }
    return null;
  }

  static propTypes = {
    showProject: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    project: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    updateDraft: PropTypes.func.isRequired,
    updates: PropTypes.array.isRequired,
    loadingDonations: PropTypes.bool.isRequired,
    getProjectDonations: PropTypes.func.isRequired,
    donations: PropTypes.object.isRequired,
    randomProjects: PropTypes.array.isRequired,
  };

  setRewardsExist = (rewardsExist) => {
    this.setState({ rewardsExist });
  };

  render() {
    const {
      project,
      loading,
      updates,
      loadingDonations,
      getProjectDonations,
      donations,
      randomProjects,
      match: {
        params: { url },
      },
      location,
    } = this.props;

    const isViewPage = location.pathname.startsWith('/view');
    function generateProjectImages() {
      let projectImages = [];
      if (isViewPage) {
        if (project && project.media && Array.isArray(project.media.images)) {
          projectImages =
            project.media.video &&
            (project.media.video.startsWith('https://www.youtube.com') ||
              project.media.video.startsWith('https://youtu'))
              ? [project.media.video, ...project.media.images]
              : [...project.media.images];
        } else {
          projectImages = [
            defaultImage,
            defaultImage,
            defaultImage,
            defaultImage,
            defaultImage,
            defaultImage,
            defaultImage,
          ];
        }
      } else {
        if (project.images && Array.isArray(project.images)) {
          projectImages = [...project.images];
        }
        if (
          project.video &&
          (project.video.startsWith('https://www.youtube.com') ||
            project.video.startsWith('https://youtu'))
        ) {
          projectImages.unshift(project.video);
        }
      }
      return projectImages;
    }
    const projectImages = generateProjectImages();
    console.log(projectImages[0]);
    return (
      <>
        <Helmet>
          <title>your keyword rich title of the website and/or webpage</title>
          <meta
            property="og:title"
            content={project ? project.title : 'Loading...'}
          />
          <meta
            name="description"
            content="description of your website/webpage, make sure you use keywords!"
          />
          <meta
            property="og:description"
            content={project ? project.subtitle : 'Loading...'}
          />
          <meta
            property="og:url"
            content={`https://commonsplace.de/project/${url}`}
          />
          {/* <meta property="og:image" content={projectImages[0]} /> */}
          <meta
            property="og:image"
            content="https://i.ebayimg.com/images/g/M4AAAOSwAWtlXDcK/s-l400.jpg"
          />
          <meta property="og:image:width" content="300" />
          <meta property="og:image:height" content="200" />
        </Helmet>
        <ProjectWrapper>
          <Spin spinning={loading}>
            <BaseLayout
              img={
                isViewPage
                  ? (project.media && project.media.banner) || null
                  : project.banner || null
              }
              whiteHeader={true}
              layout={true}
              bannerClass="project-page-banner"
              bannerComponent={
                <BannerComponent
                  title={
                    isViewPage
                      ? project.basicInfo && project.basicInfo.title
                      : project.title
                  }
                  subtitle={
                    isViewPage
                      ? project.basicInfo && project.basicInfo.subtitle
                      : project.subtitle
                  }
                  video={
                    isViewPage
                      ? project.media && project.media.video
                      : project.video
                  }
                />
              }
            >
              <div>
                <ProjectDetails
                  projectUrl={url}
                  projectId={project.id}
                  projectStatus={project.status}
                  projectDescription={
                    isViewPage
                      ? project.projectDescription &&
                        project.projectDescription.description
                      : project.description
                  }
                  projectDonations={
                    isViewPage
                      ? 0
                      : project.donations_sum && Number(project.donations_sum)
                  }
                  projectSupporters={
                    isViewPage
                      ? 0
                      : project.donations_count &&
                        Number(project.donations_count)
                  }
                  projectDays={
                    isViewPage
                      ? project.runTime && project.runTime.funding_days
                      : project.days_left && Number(project.days_left)
                  }
                  projectImages={projectImages}
                  projectGoal={project.goal && Number(project.goal)}
                  projectFinalGoal={
                    project.final_goal && Number(project.final_goal)
                  }
                  isViewPage={isViewPage}
                  banner={project && project.banner}
                  title={
                    isViewPage
                      ? project.basicInfo && project.basicInfo.title
                      : project.title
                  }
                  subtitle={
                    isViewPage
                      ? project.basicInfo && project.basicInfo.subtitle
                      : project.subtitle
                  }
                  rewardsExist={this.state.rewardsExist}
                />
                {/* <Tabs /> */}
                {project && (
                  <ProjectTab
                    {...this.props}
                    project={project}
                    isViewPage={isViewPage}
                    updates={updates}
                    loadingDonations={loadingDonations}
                    projectId={project.id}
                    getProjectDonations={getProjectDonations}
                    donations={donations}
                  />
                )}

                {project.id && (
                  <Rewards
                    projectStatus={project.status}
                    projectId={project.id}
                    setRewardsExist={this.setRewardsExist}
                  />
                )}

                {!isViewPage && updates.length > 0 && (
                  <TimeLineTab updates={[...updates]} key={Date.now()} />
                )}

                {!isViewPage && (
                  <>
                    <RelatedProjects
                      randomProjects={randomProjects}
                      isTicketable={project.is_ticketable}
                    />

                    <StartProjectWrapper className="start-project__component">
                      <>
                        <h3>
                          Starte dein eigenes Projekt und bereichere die
                          Community!
                        </h3>

                        <Link to="/start-project">
                          <PrimaryButton>Jetzt Projekt starten</PrimaryButton>
                        </Link>
                      </>
                    </StartProjectWrapper>
                    <Hadeth />
                  </>
                )}
              </div>
            </BaseLayout>
          </Spin>
        </ProjectWrapper>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { updates, project, loading, errors, randomProjects } = state.projects;
  const {
    loading: loadingDonations,
    errors: errorsDonations,
    donations,
  } = state.donations;

  return {
    project,
    updates,
    loading,
    errors,
    donations,
    loadingDonations,
    errorsDonations,
    randomProjects,
  };
};

const mapDispatchToProps = (dispatch) => ({
  showProject: (url) => dispatch(ProjectsActions.showProject(url)),
  updateDraft: (id, project) =>
    dispatch(ProjectsActions.updateDraft(id, project)),
  getProjectDonations: (projectId, perPage, page) =>
    dispatch(DonationsActions.getProjectDonations(projectId, perPage, page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectPage);
