import React, { Component } from 'react';
import pickBy from 'loadsh/pickBy';
import identity from 'loadsh/identity';
import isEmpty from 'loadsh/isEmpty';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Input, Spin } from 'antd';
import {
  Container,
  PrimaryButton,
  formLayout,
  formValidateMessages,
  H1,
  FormWrapper,
} from 'utils';
import { ProjectsActions } from 'redux/actions';

const FormItem = Form.Item;
class FundingDuration extends Component {
  static propTypes = {
    updateDraft: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    projectId: PropTypes.string,
    currentStep: PropTypes.number.isRequired,
    setCanMove: PropTypes.func.isRequired,
    showModal: PropTypes.bool.isRequired,
    selectedStep: PropTypes.number.isRequired,
    setSubmitFromModal: PropTypes.func.isRequired,
  };
  onFinish = (values) => {
    const {
      updateDraft,
      projectId,
      currentStep,
      setCanMove,
      showModal,
      selectedStep,
      setSubmitFromModal,
    } = this.props;

    if (showModal) {
      updateDraft(projectId, values, selectedStep - 1);
      setSubmitFromModal(false);
    } else {
      updateDraft(projectId, values, currentStep);
    }

    setCanMove(true);
    this.props.handleSteps(4);
  };
  render() {
    const { project, loading, setCanMove, projectId } = this.props;
    return (
      <Container className="inside-form-container">
        <Spin spinning={loading}>
          <H1>Finanzierungszeitraum</H1>
          <p>
            Hier stellst du ein, wie lang deine Community dich unterstützen kann
            und der Finanzierungszeitraum endet. Der Finanzierungszeitraum auf commonsplace
            beträgt max. 90 Tage.
          </p>
          { !loading && (
            <Form
              {...formLayout}
              name="funding-duration"
              onFinish={this.onFinish}
              onChange={() => setCanMove(false)}
              validateMessages={formValidateMessages}
              initialValues={projectId ? project ? pickBy(project.runTime, identity) : {} : ""}
            >
              <FormWrapper>
                <>
                  <FormItem
                    name="funding_days"
                    label="Finanzierungszeitraum in Tage"
                    rules={[
                      {
                        required: true,
                        message: 'Finanzierungszeitraum ist erforderlich',
                      },
                    ]}
                  >
                    <Input type="number" min={1} max={90} />
                  </FormItem>
                </>
              </FormWrapper>
              <FormItem>
                <PrimaryButton
                  type="primary"
                  htmltype="submit"
                  minWidth="150px"
                  id="submit-button"
                >
                  Speichern
                </PrimaryButton>
              </FormItem>
            </Form>
          )}
        </Spin>
      </Container>
    );
  }
}
const mapStateToProps = (state) => {
  const { project, loading, errors, currentStep } = state.projects;
  return {
    project,
    loading,
    errors,
    currentStep,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleSteps: (step) => dispatch(ProjectsActions.handleSteps(step)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FundingDuration);
