const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  allMobile: '767px',
  tablet: '768px',
  laptop: '1025px',
  laptopL: '1440px',
  desktop: '2560px',
};

const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  allMobile: `(max-width: 767px)`,
  tablet: `(max-width: 950px) and (min-width: ${size.tablet})`,
  minLaptop: `(max-width: ${size.laptop}) and (min-width: 950px)`,
  laptop: `(max-width: ${size.laptopL}) and (min-width: ${size.tablet})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
};

export default device;
