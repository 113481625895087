import React from 'react';
import styled from '@emotion/styled';
import { Colors } from 'utils';
import PropTypes from 'prop-types';

const ProgressBarWrapper = styled.div`
  width: 100%;
  background: ${Colors.grey};
  border-radius: 5px;
  border: 1px solid ${Colors.grey};
  div {
    width: ${(props) =>
      props.percentage
        ? `${props.percentage > 100 ? '100' : props.percentage}%`
        : '0'};
    background: ${Colors.secondaryColor};
    height: 5px;
    border-radius: 5px;
    border: 1px solid ${Colors.secondaryColor};
  }
`;
export default function ProgressBar(props) {
  return (
    <ProgressBarWrapper
      percentage={props.percentage}
      className={
        props.percentage >= 100 ? 'progress-bar complete' : 'progress-bar'
      }
    >
      <div></div>
    </ProgressBarWrapper>
  );
}

ProgressBar.propTypes = {
  percentage: PropTypes.number.isRequired,
};
