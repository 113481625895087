/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { Colors } from 'utils';
const InstagramIcon = ({ color, width, height }) => {
  return (
    <svg
      height={`${height || '24'}px`}
      width={`${width || '24'}px`}
      viewBox="0 0 24 24"
      css={css`
        fill: ${color || Colors.secondaryColor};
        max-width: ${width || '24'}px;
        max-height: ${height || '24'}px;
        height: ${height || '24'}px;
        width: ${width || '24'}px;
      `}
    >
      <g id="surface1">
        <path
          style={{
            stroke: 'none',
            fillRull: 'nonzero',
            fill: color || Colors.secondaryColor,
            fillOpacity: '1',
          }}
          d="M 17.1875 0 L 7.8125 0 C 3.5 0 0 3.5 0 7.8125 L 0 17.1875 C 0 21.5 3.5 25 7.8125 25 L 17.1875 25 C 21.5 25 25 21.5 25 17.1875 L 25 7.8125 C 25 3.5 21.5 0 17.1875 0 Z M 22.65625 17.1875 C 22.65625 20.203125 20.203125 22.65625 17.1875 22.65625 L 7.8125 22.65625 C 4.796875 22.65625 2.34375 20.203125 2.34375 17.1875 L 2.34375 7.8125 C 2.34375 4.796875 4.796875 2.34375 7.8125 2.34375 L 17.1875 2.34375 C 20.203125 2.34375 22.65625 4.796875 22.65625 7.8125 Z M 22.65625 17.1875 "
        />
        <path
          style={{
            stroke: 'none',
            fillRull: 'nonzero',
            fill: color || Colors.secondaryColor,
            fillOpacity: '1',
          }}
          d="M 12.5 6.25 C 9.046875 6.25 6.25 9.046875 6.25 12.5 C 6.25 15.953125 9.046875 18.75 12.5 18.75 C 15.953125 18.75 18.75 15.953125 18.75 12.5 C 18.75 9.046875 15.953125 6.25 12.5 6.25 Z M 12.5 16.40625 C 10.347656 16.40625 8.59375 14.652344 8.59375 12.5 C 8.59375 10.34375 10.347656 8.59375 12.5 8.59375 C 14.652344 8.59375 16.40625 10.34375 16.40625 12.5 C 16.40625 14.652344 14.652344 16.40625 12.5 16.40625 Z M 12.5 16.40625 "
        />
        <path
          style={{
            stroke: 'none',
            fillRull: 'nonzero',
            fill: color || Colors.secondaryColor,
            fillOpacity: '1',
          }}
          d="M 20.050781 5.78125 C 20.050781 6.242188 19.679688 6.613281 19.21875 6.613281 C 18.757812 6.613281 18.386719 6.242188 18.386719 5.78125 C 18.386719 5.320312 18.757812 4.949219 19.21875 4.949219 C 19.679688 4.949219 20.050781 5.320312 20.050781 5.78125 Z M 20.050781 5.78125 "
        />
      </g>
    </svg>
  );
};

export default InstagramIcon;

InstagramIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};
