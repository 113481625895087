import styled from '@emotion/styled';
import React from 'react';
import { PrimaryButton, Colors } from 'utils';

const MarketingReferrerWrapper = styled.div`
  h1 {
    font-size: 22px;
  }
  p {
    margin: 0;
    line-height: 1.6;
  }
  h2 {
    margin: 0;
    line-height: 1;
    margin-top: 30px;
    font-size: 20px;
  }
  form {
    margin-top: 15px;
    background: ${Colors.grey};
    padding: 20px;
    label {
      display: block;
      font-size: 18px;
      font-weight: bold;
    }
    input {
      height: 40px;
      width: 100%;
    }
    p {
      margin: 0;
      font-size: 15px;
      color: ${Colors.steel};
    }
    .submit-button {
      width: auto;
      margin-bottom: 0;
      margin-top: 20px;
      text-align: right;
    }
  }
`;
export default function MarketingReferrer() {
  return (
    <MarketingReferrerWrapper>
      <h1>Marketing Referrer</h1>
      <p>
        Here you can set up marketing referrers to track how many page views
        come from specific websites or social media channels to your Startnext
        project page.
      </p>
      <h2>How it works </h2>
      <p>
        When you define a marketing referrer, you get an individual project link
        to share your project. Separate statistics are collected for this
        individual project link. This is useful if, for example, you are
        implementing a specific campaign and would like to see how effective a
        particular campaign was. You can view and evaluate the results of your
        marketing referrers in the interface under <span>"Statistics"</span>.
      </p>
      <div>
        <h2>Marketing Referrer</h2>
        <form>
          <label>Campaign name: </label>
          <input type="text" />
          <p>
            Specify the name of the campaign to get an invidivual project link
            and track the communication.
          </p>
          <div className="submit-button">
            <PrimaryButton>Save</PrimaryButton>
          </div>
        </form>
      </div>
    </MarketingReferrerWrapper>
  );
}
