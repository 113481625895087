import { donationsServices } from 'services';
import { AlertActions } from 'redux/actions';
import donationsConstants from './DonationsType';

const getProjectDonations = (projectId, perPage, page) => {
  return (dispatch) => {
    dispatch(getProjectDonationsRequest());
    donationsServices
      .getProjectDonations(projectId, perPage, page)
      .then((res) => {
        dispatch(getProjectDonationsSuccess(res.donations));
      })
      .catch((error) => {
        error.errors.full_messages
          ? error.errors.full_messages.map((err) =>
              dispatch(AlertActions.error(err))
            )
          : error.errors.map((err) => dispatch(AlertActions.error(err)));
        return dispatch(getProjectDonationsFailure(error.errors));
      });
  };
};
const getProjectDonationsRequest = () => ({
  type: donationsConstants.GET_PROJECT_DONATIONS_REQUEST,
});
const getProjectDonationsSuccess = (donations) => ({
  type: donationsConstants.GET_PROJECT_DONATIONS_SUCCESS,
  donations,
});
const getProjectDonationsFailure = (error) => ({
  type: donationsConstants.GET_PROJECT_DONATIONS_FAILURE,
  error,
});

const getMyRecurringPayments = () => {
  return (dispatch) => {
    dispatch(getMyRecurringPaymentsRequest());
    donationsServices
      .getMyRecurringPayments()
      .then((res) => {
        dispatch(getMyRecurringPaymentsSuccess(res.donations));
      })
      .catch((error) => {
        error.errors.full_messages
          ? error.errors.full_messages.map((err) =>
              dispatch(AlertActions.error(err))
            )
          : error.errors.map((err) => dispatch(AlertActions.error(err)));
        return dispatch(getMyRecurringPaymentsFailure(error.errors));
      });
  };
};
const getMyRecurringPaymentsRequest = () => ({
  type: donationsConstants.GET_RECURRING_DONATIONS_REQUEST,
});
const getMyRecurringPaymentsSuccess = (donations) => ({
  type: donationsConstants.RECURRING_DONATIONS_SUCCESS,
  donations,
});
const getMyRecurringPaymentsFailure = (error) => ({
  type: donationsConstants.RECURRING_DONATIONS_FAILURE,
  error,
});

const getMyDonations = (perPage, page) => {
  return (dispatch) => {
    dispatch(getMyDonationsRequest());
    donationsServices
      .getMyDonations(perPage, page)
      .then((res) => {
        dispatch(getMyDonationsSuccess(res.donations));
      })
      .catch((error) => {
        error.errors.map((err) => dispatch(AlertActions.error(err)));
        return dispatch(getMyDonationsFailure(error.errors));
      });
  };
};

const getMyDonationsRequest = () => ({
  type: donationsConstants.GET_MY_DONATIONS_REQUEST,
});
const getMyDonationsSuccess = (donations) => ({
  type: donationsConstants.GET_MY_DONATIONS_SUCCESS,
  donations,
});
const getMyDonationsFailure = (error) => ({
  type: donationsConstants.GET_MY_DONATIONS_FAILURE,
  error,
});

const refundDonations = (id) => {
  return (dispatch) => {
    dispatch(refundDonationsRequest());
    donationsServices
      .refundDonations(id)
      .then(() => {
        dispatch(AlertActions.success('Your payment has been refund'));
        dispatch(refundDonationsSuccess(id));
      })
      .catch((error) => {
        error.errors.map((err) => dispatch(AlertActions.error(err)));
        return dispatch(refundDonationsFailure(error.errors));
      });
  };
};

const refundDonationsRequest = () => ({
  type: donationsConstants.REFUND_DONATIONS_REQUEST,
});
const refundDonationsSuccess = (id) => ({
  type: donationsConstants.REFUND_DONATIONS_SUCCESS,
  id,
});
const refundDonationsFailure = (error) => ({
  type: donationsConstants.REFUND_DONATIONS_FAILURE,
  error,
});


const cancelRecurringPayment = (id) => {
  return (dispatch) => {
    dispatch(cancelRecurringPaymentRequest());
    donationsServices.getMyRecurringPaymentsCanceled(id)
      .then((res) => {
        dispatch(AlertActions.success('Ihre Spende wurde storniert!'));
        dispatch(cancelRecurringPaymentSuccess(res));
      })
      .catch((error) => {
        error.errors.map((err) => dispatch(AlertActions.error(err)));
        return dispatch(cancelRecurringPaymentFailure(error.errors));
      });
  };
};

const cancelRecurringPaymentRequest = () => ({
  type: donationsConstants.GET_RECURRING_DONATIONS_CANCEL_REQUEST,
});
const cancelRecurringPaymentSuccess = (id) => ({
  type: donationsConstants.RECURRING_DONATIONS_CANCEL_SUCCESS,
  id,
});
const cancelRecurringPaymentFailure = (error) => ({
  type: donationsConstants.RECURRING_DONATIONS_CANCEL_FAILURE,
  error,
});

const getPatreonDonations = (projectId) => {
  return (dispatch) => {
    dispatch(getPatreonDonationsRequest());
    donationsServices
      .getPatreonDonations(projectId)
      .then((res) => {
        dispatch(getPatreonDonationsSuccess(res.donations));
      })
      .catch((error) => {
        error.errors.map((err) => dispatch(AlertActions.error(err)));
        return dispatch(getPatreonDonationsFailure(error.errors));
      });
  };
};

const getPatreonDonationsRequest = () => ({
  type: donationsConstants.GET_PATREON_DONATIONS_REQUEST,
});
const getPatreonDonationsSuccess = (donations) => ({
  type: donationsConstants.GET_PATREON_DONATIONS_SUCCESS,
  donations,
});
const getPatreonDonationsFailure = (error) => ({
  type: donationsConstants.GET_PATREON_DONATIONS_FAILURE,
  error,
});

const DonationsActions = {
  getProjectDonations,
  getMyDonations,
  refundDonations,
  getMyRecurringPayments,
  cancelRecurringPayment,
  getPatreonDonations
};

export default DonationsActions;
