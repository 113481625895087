const HandlePaymentErrorMassage = (error) => {
  switch (error) {
    case 'errorCode=02625':
      return 'Invalid card number';
    case 'errorCode=02626':
      return 'Invalid date. Use MMYY format';
    case 'errorCode=02627':
      return 'Invalid CCV number';
    case 'errorCode=02628':
      return 'Transaction refused';
    case 'errorCode=02101':
      return 'Internal Error';
    case 'errorCode=09102':
      return 'Account is locked or inactive';
    case 'errorCode=01902':
      return 'This card is not active';
    case 'errorCode=02624':
      return 'Card expired';
    case 'errorCode=09104':
      return 'Client certificate is disabled';
    case 'errorCode=09201':
      return 'You do not have permissions to make this API call';
    case 'errorCode=02631':
      return 'Delay exceeded';
    default:
      break;
  }
};

export default HandlePaymentErrorMassage;
