import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';

const SvgWrapper = styled.span`
  max-width: ${(props) => (props.width ? props.width : '24px')};
  min-width: ${(props) => (props.width ? props.width : '24px')};
  height: ${(props) => (props.height ? props.height : '24px')};
  display: inline-block;
  vertical-align: middle;
  svg {
    display: inline-block;
  }
  img {
    user-select: none; /* Prevent image selection */
    pointer-events: none; /* Disable pointer events on the image */
    user-drag: none; /* Disable image dragging */
    -webkit-user-drag: none; /* For Safari */
    -webkit-touch-callout: none; /* Disable long-press on image */
    -moz-user-select: none; /* Disable image selection in Firefox */
    -ms-user-select: none; /* Disable image selection in IE/Edge */
    -webkit-user-select: none; /* Disable image selection in WebKit/Blink */
  }
`;

export default SvgWrapper;

SvgWrapper.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};
