import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import Card from 'react-credit-cards';
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
  formatFormData,
  PrimaryButton,
} from 'utils';
import { PaymentActions } from 'redux/actions';
import 'react-credit-cards/es/styles-compiled.css';
const PaymentFormWrapper = styled.div`
  .App-payment {
    padding: 30px;
  }

  form {
    margin: 30px auto 0;
    max-width: 400px;
  }

  .form-actions {
    margin-top: 15px;
  }

  .App-cards {
    margin: 0 auto;
    max-width: 1280px;
    text-align: center;
  }

  .App-cards-list {
    display: flex;
    margin-top: 30px;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .App-cards-list > * {
    transform: scale(0.8);
    margin-bottom: 30px !important;
  }

  .App-highlight {
    font-family: serif;
    margin: 15px auto 0;
    max-width: 300px;
  }

  .App-highlight > div {
    padding-left: 40px;
  }

  .App-badges {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .App-badges a {
    display: block;
  }

  .App-badges a + a {
    margin-top: 18px;
  }

  .App-credits {
    background-color: #000;
    color: #fff;
    line-height: 50px;
    text-align: center;
  }

  .App-credits a {
    color: #fff;
    font-weight: bold;
  }

  @media screen and (min-width: 600px) {
    .App-badges {
      flex-direction: row;
    }

    .App-badges a + a {
      margin-top: 0;
      margin-left: 18px;
    }
  }
  .form-group {
    margin-bottom: 1rem;
  }
  .form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;

    .col-6 {
      flex: 0 0 50%;
      max-width: 50%;
      padding-right: 15px;
      padding-left: 15px;
    }
  }
`;
class PaymentForm extends Component {
  state = {
    number: '',
    name: '',
    expiry: '',
    cvc: '',
    issuer: '',
    focused: '',
    formData: null,
    preview: false,
  };
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    errors: PropTypes.array.isRequired,
    addCard: PropTypes.func.isRequired,
    card: PropTypes.object.isRequired,
    closeModal: PropTypes.func,
  };
  handleCallback = ({ issuer }, isValid, number) => {
    if (number.startsWith('6788')) {
      this.setState({ issuer: 'maestro', preview: true });
    } else if (isValid) {
      this.setState({ issuer, preview: false });
    } else {
      this.setState({ issuer: '', preview: false });
    }
  };

  handleInputFocus = ({ target }) => {
    this.setState({
      focused: target.name,
    });
  };

  handleInputChange = ({ target }) => {
    if (target.name === 'number') {
      target.value = formatCreditCardNumber(target.value);
    } else if (target.name === 'expiry') {
      target.value = formatExpirationDate(target.value);
    } else if (target.name === 'cvc') {
      target.value = formatCVC(target.value);
    }

    this.setState({ [target.name]: target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { addCard, card, closeModal } = this.props;
    const nextCard = [...e.target.elements]
      .filter((d) => d.name)
      .reduce((acc, d) => {
        acc[d.name] = d.value.replace('/', '').replaceAll(' ', '');
        return acc;
      }, {});

    addCard({ ...card, ...nextCard });
    closeModal();
    // addCard('99632882', card);
    // this.setState({ formData });
    // this.form.reset();
  };

  render() {
    const { name, number, expiry, cvc, focused, issuer, formData, preview } =
      this.state;
    const { loading } = this.props;

    return (
      <PaymentFormWrapper>
        <Spin spinning={loading}>
          <div className="App-payment">
            <Card
              number={number}
              name={name}
              expiry={expiry}
              cvc={cvc}
              focused={focused}
              callback={(card, isValid) =>
                this.handleCallback(card, isValid, number)
              }
              issuer={issuer}
              preview={preview}
            />
            <form ref={(c) => (this.form = c)} onSubmit={this.handleSubmit}>
              <div className="form-group">
                <input
                  type="tel"
                  name="number"
                  className="form-control"
                  placeholder="Card Number"
                  pattern="[\d| ]{16,22}"
                  required
                  onChange={this.handleInputChange}
                  onFocus={this.handleInputFocus}
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder="Name"
                  required
                  onChange={this.handleInputChange}
                  onFocus={this.handleInputFocus}
                />
              </div>
              <div className="row">
                <div className="col-6">
                  <input
                    type="tel"
                    name="expiry"
                    className="form-control"
                    placeholder="Valid Thru"
                    pattern="\d\d/\d\d"
                    required
                    onChange={this.handleInputChange}
                    onFocus={this.handleInputFocus}
                  />
                </div>
                <div className="col-6">
                  <input
                    type="tel"
                    name="cvc"
                    className="form-control"
                    placeholder="CVC"
                    pattern="\d{3,4}"
                    required
                    onChange={this.handleInputChange}
                    onFocus={this.handleInputFocus}
                  />
                </div>
              </div>
              <input type="hidden" name="issuer" value={issuer} />
              <div className="form-actions">
                <PrimaryButton
                  htmltype="submit"
                  className="btn btn-primary btn-block"
                  minWidth={'100%'}
                >
                  PAY
                </PrimaryButton>
              </div>
            </form>
            {formData && (
              <div className="App-highlight">
                {formatFormData(formData).map((d, i) => (
                  <div key={i}>{d}</div>
                ))}
              </div>
            )}
          </div>
        </Spin>
      </PaymentFormWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  const { card, loading, errors } = state.payment;
  return {
    loading,
    card,
    errors,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addCard: (card) => dispatch(PaymentActions.addCard(card)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentForm);
