import React from 'react';
import Slider from 'react-slick';
import styled from '@emotion/styled';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { Colors, device } from 'utils';

const CampaignWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 98%;
  background: ${Colors.white};
  margin: auto;
  box-shadow: 0 8px 12px 1px rgba(0, 0, 0, 0.14),
    0 3px 15px 3px rgba(0, 0, 0, 0.12), 0 4px 5px -3px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  margin-bottom: 30px;
  padding: 30px 40px;
  @media ${device.allMobile} {
    flex-wrap: wrap;
    width: 90%;
    padding: 15px 0;
  }
  .campaign {
    &__image {
      width: 32%;
      display: inline-block;
      position: relative;
      @media ${device.allMobile} {
        width: 100%;
      }
      & > span {
        display: inline-block;
        height: 100%;
        width: 100%;
        height: 300px;
        background: ${Colors.grey};
        position: relative;
        border-radius: 10px;
        @media ${device.allMobile} {
          width: 100%;
          margin-bottom: 15px;
        }
        span {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateY(-50%) translateX(-50%);
        }
      }
    }
    &__details {
      width: 32%;
      display: inline-block;
      text-align: center;
      @media ${device.allMobile} {
        width: 100%;
      }
      p {
        font-weight: 700;
        @media ${device.allMobile} {
          font-size: 16px;
        }
        span {
          font-weight: 400;
          display: block;
        }
      }
      a {
        display: inline-block;
        color: ${Colors.secondaryColor};
        text-decoration: none;
        &:hover {
          border-bottom: 2px solid ${Colors.secondaryColor};
        }
      }
    }
    &__title {
      width: 32%;
      @media ${device.allMobile} {
        width: 100%;
        margin-bottom: 20px;
      }
      p,
      h1 {
        margin: 0;
        line-height: 1;
        text-align: center;
      }
      h1 {
        font-weight: 700;
        font-size: 30px;
        margin-bottom: 10px;
        @media ${device.allMobile} {
          font-size: 25px;
        }
      }
      p {
        margin-bottom: 0;
        font-size: 22px;
        @media ${device.allMobile} {
          font-size: 18px;
          margin-bottom: 10px;
        }
      }
    }
  }
`;
const CampaignsWrapper = styled.div`
  margin: 30px auto;
  .slick-dots {
    bottom: 0px;
    li {
      button:before {
        content: '-';
        font-size: 60px;
        color: ${Colors.secondaryColor};
      }
    }
  }
`;

export default function Campaigns() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  return (
    <CampaignsWrapper>
      <Slider {...settings}>
        <div>
          <CampaignWrapper>
            <div className="campaign__title">
              <h1>MITWIRKEN Der Crowdfunding-Contest</h1>
              <p>Das Hertie-Förderprogramm für gelebte Demokratie</p>
            </div>
            <div className="campaign__image">
              <span>
                <span>Photo 1*1</span>
              </span>
            </div>
            <div className="campaign__details">
              <p>
                200,000 €<span>to be awarded</span>
              </p>
              <a href="#!">Details</a>
            </div>
          </CampaignWrapper>
        </div>
        <div>
          <CampaignWrapper>
            <div className="campaign__title">
              <h1>MITWIRKEN Der Crowdfunding-Contest</h1>
              <p>Das Hertie-Förderprogramm für gelebte Demokratie</p>
            </div>
            <div className="campaign__image">
              <span>
                <span>Photo 1*1</span>
              </span>
            </div>
            <div className="campaign__details">
              <p>
                200,000 €<span>to be awarded</span>
              </p>
              <a href="#!">Details</a>
            </div>
          </CampaignWrapper>
        </div>
        <div>
          <CampaignWrapper>
            <div className="campaign__title">
              <h1>MITWIRKEN Der Crowdfunding-Contest</h1>
              <p>Das Hertie-Förderprogramm für gelebte Demokratie</p>
            </div>
            <div className="campaign__image">
              <span>
                <span>Photo 1*1</span>
              </span>
            </div>
            <div className="campaign__details">
              <p>
                200,000 €<span>to be awarded</span>
              </p>
              <a href="#!">Details</a>
            </div>
          </CampaignWrapper>
        </div>
        <div>
          <CampaignWrapper>
            <div className="campaign__title">
              <h1>MITWIRKEN Der Crowdfunding-Contest</h1>
              <p>Das Hertie-Förderprogramm für gelebte Demokratie</p>
            </div>
            <div className="campaign__image">
              <span>
                <span>Photo 1*1</span>
              </span>
            </div>
            <div className="campaign__details">
              <p>
                200,000 €<span>to be awarded</span>
              </p>
              <a href="#!">Details</a>
            </div>
          </CampaignWrapper>
        </div>
        <div>
          <CampaignWrapper>
            <div className="campaign__title">
              <h1>MITWIRKEN Der Crowdfunding-Contest</h1>
              <p>Das Hertie-Förderprogramm für gelebte Demokratie</p>
            </div>
            <div className="campaign__image">
              <span>
                <span>Photo 1*1</span>
              </span>
            </div>
            <div className="campaign__details">
              <p>
                200,000 €<span>to be awarded</span>
              </p>
              <a href="#!">Details</a>
            </div>
          </CampaignWrapper>
        </div>
        <div>
          <CampaignWrapper>
            <div className="campaign__title">
              <h1>MITWIRKEN Der Crowdfunding-Contest</h1>
              <p>Das Hertie-Förderprogramm für gelebte Demokratie</p>
            </div>
            <div className="campaign__image">
              <span>
                <span>Photo 1*1</span>
              </span>
            </div>
            <div className="campaign__details">
              <p>
                200,000 €<span>to be awarded</span>
              </p>
              <a href="#!">Details</a>
            </div>
          </CampaignWrapper>
        </div>
      </Slider>
      .
    </CampaignsWrapper>
  );
}
