import styled from '@emotion/styled';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Colors } from 'utils';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { authActions } from 'redux/actions';

const RightSideBarWrapper = styled.aside`
  width: 290px;
  padding: 20px;
  min-height: calc(100vh - 96px);
  background: ${Colors.grey};
  text-align: center;
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: sticky;
    top: 20px;
    min-height: calc(100vh - 116px);
  }

  button {
    display: block;
    border: 1px solid ${Colors.GreenColor};
    background: ${Colors.GreenColor};
    color: ${Colors.white};
    text-align: center;
    border-radius: 12px;
    cursor: pointer;
    margin-bottom: 20px;
    padding: 15px 20px;
    min-width: ${'200px'};
    font-size: 18px;
    &:hover {
      color: ${Colors.GreenColor};
      background: ${Colors.transparent};
    }
  }
  ul {
    padding: 0;
    list-style: none;
    li {
      a {
        display: block;
        background: ${Colors.transparent};
        border: 1px solid ${Colors.GreenColor};
        color: ${Colors.GreenColor};
        text-align: center;
        border-radius: 12px;
        cursor: pointer;
        margin-bottom: 20px;
        padding: 15px 20px;
        min-width: ${'200px'};
        font-size: 18px;
        &:hover {
          background: ${Colors.GreenColor};
          color: ${Colors.white};
        }
      }
    }
  }
`;

class RightSideBar extends Component {
  static propTypes = {
    logout: PropTypes.func.isRequired,
  };
  render() {
    const { logout } = this.props;
    return (
      <RightSideBarWrapper>
        <div>
          <div>
            <ul>
              <li>
                <Link to="/profile">Profil</Link>
              </li>
              <li>
                <Link to="/profile">Eigene Projekte</Link>
              </li>
            </ul>
          </div>
          <div>
            <button htmltype="button" onClick={() => logout()}>
              Abmelden
            </button>
          </div>
        </div>
      </RightSideBarWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  const { user } = state.authentication;
  return {
    user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(authActions.logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RightSideBar);
