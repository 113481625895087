const getProjectDonations = (projectId, perPage, page = 1) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  return fetch(
    `projects/${projectId}/donations?per_page=${perPage}&page=${page}`,
    requestOptions
  )
    .then((donations) => {
      return { donations };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};
const getMyDonations = (perPage, page) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  return fetch(`my_donations?per_page=${perPage}&page=${page}`, requestOptions)
    .then((donations) => {
      return { donations };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const getPatreonDonations = (projectId) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  return fetch(`/project_donations/recurring?project_id=${projectId}`, requestOptions)
    .then((donations) => {
      return { donations };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const refundDonations = (id) => {
  const requestOptions = {
    method: 'POST',
    redirect: 'follow',
  };
  return fetch(`donations/refund/${id}`, requestOptions)
    .then(({ message }) => {
      return { message };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const getMyRecurringPayments = () => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  return fetch(`recurring_donations`, requestOptions)
    .then((donations) => {
      return { donations };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const getMyRecurringPaymentsCanceled = (id) => {
  const RAW = { donation_id: id };
  const requestOptions = {
    method: 'POST',
    redirect: 'follow',
    body: JSON.stringify(RAW),
  };
  return fetch(`cancel_donations`, requestOptions)
    .then((res) => {
      return { res };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const donationsServices = {
  getProjectDonations,
  getMyDonations,
  refundDonations,
  getMyRecurringPayments,
  getMyRecurringPaymentsCanceled,
  getPatreonDonations
};

export default donationsServices;
