import React, { Component } from 'react';
import { RewardCard } from './';
import { Colors, device } from 'utils';
import styled from '@emotion/styled';
import { Row, Col, Spin } from 'antd';
import PropTypes from 'prop-types';
import { projectsServices } from 'services';

const RewardsWrapper = styled.div`
  text-align: center;
  margin-top: 30px;
  h1 {
    font-size: 30px;
    color: ${Colors.GreenColor};
    border-bottom: 2px solid ${Colors.secondaryColor};
    padding: 0 60px 10px 60px;
    display: inline-block;
    margin: 0 auto 25px;
    @media ${device.allMobile} {
      text-align: center;
      font-size: 24px;
      width: 90%;
      margin: auto;
      margin-bottom: 20px;
    }
  }
  .reward {
    cursor: pointer;
  }
  .reward:hover {
    border-color: green;
  }
`;

class Rewards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectId: props.projectId,
      projectStatus:props.projectStatus,
      rewards: [],
      loading: true,
      currentSelected: -1,
    };
  }

  openCards = (index) => {
    if (index === this.state.currentSelected) {
      this.setState({ currentSelected: -1 });
    } else {
      this.setState({ currentSelected: index });
    }
  };

  componentDidMount = async () => {
    let rewards = [];
    // Send Request to get the rewards
    try {
      rewards = await projectsServices.getProjectRewards(this.state.projectId); // Change to 1 for test
    } catch (error) {}
    this.setState({ loading: false, rewards });

    // Set the Rewards Flag
    this.props.setRewardsExist(rewards.length > 0);
  };

  static propTypes = {
    projectId: PropTypes.number.isRequired,
    setRewardsExist: PropTypes.func.isRequired,
    isTicketable: PropTypes.bool.isRequired
  };

  render() {
    const {  isTicketable } = this.props;
    return (
      <div style={{ textAlign: 'center' }} id="rewards-section">
        {this.state.loading && <Spin spinning={this.state.loading}></Spin>}
        {!this.state.loading && this.state.rewards.length > 0 && (
          <RewardsWrapper>
            <h1>{isTicketable? "Tickets" : "Goodies"}</h1>
            <Row>
              {this.state.rewards.map((reward, i) => {
                return (
                  <Col
                    style={{ padding: '10px' }}
                    key={i}
                    xs={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                    md={{ span: 12, offset: 0 }}
                    lg={{ span: 8, offset: 0 }}
                    xl={{ span: 8, offset: 0 }}
                  >
                    <RewardCard
                      projectStatus={this.state.projectStatus}
                      reward={reward}
                      open={i === this.state.currentSelected}
                      projectId={this.state.projectId}
                      isTicketable={isTicketable}
                      order={i + 1}
                      openCards={this.openCards}
                    />
                  </Col>
                );
              })}
            </Row>
          </RewardsWrapper>
        )}
      </div>
    );
  }
}

export default Rewards;
