import React, { Component } from 'react';
import { Form, Input, Spin } from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { authActions } from 'redux/actions';
import { H2, FormWrapper, PrimaryButton, Colors } from 'utils';
import styled from '@emotion/styled';
const FormItem = Form.Item;

const ResetPasswordWrapper = styled.div`
  h2 {
    color: ${Colors.GreenColor};
    display: inline-block;
    padding: 10px;
    border: 1px solid ${Colors.GreenColor};
    border-radius: 10px;
    font-size: 35px;
  }

  .ant-form-item {
    display: block;
    .ant-form-item-label {
      color: ${Colors.GreenColor};
      margin-bottom: 10px;
      label {
        font-weight: bold;
        font-size: 22px;
      }
    }
    .ant-picker,
    .ant-input-affix-wrapper {
      width: 100%;
      padding: 10px;
      border: 1px solid ${Colors.GreenColor};
      border-radius: 10px;
    }
  }
  .errors {
    p {
      color: ${Colors.GreenColor};
      background: #ff000036;
      padding: 5px;
      border-radius: 5px;
    }
  }
`;
class ResetPassword extends Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    user: PropTypes.object.isRequired,
    updateProfile: PropTypes.func.isRequired,
    errors: PropTypes.array,
  };
  onFinish = (values) => {
    const { password, current_password } = values;
    const { updateProfile } = this.props;
    updateProfile({ password, current_password });
  };
  render() {
    const { loading, errors } = this.props;
    return (
      <ResetPasswordWrapper>
        <H2>Passwort ändern</H2>
        <Form onFinish={this.onFinish}>
          <FormWrapper>
            <Spin spinning={loading}>
              <FormItem
                name="current_password"
                label="Altes Passwort"
                rules={[
                  {
                    required: true,
                    message: 'Bitte gib dein Passwort ein.',
                  },
                ]}
              >
                <Input.Password />
              </FormItem>
              <FormItem
                name="password"
                label="Neues Passwort"
                rules={[
                  {
                    required: true,
                    message: 'Bitte gib dein Passwort ein.',
                  },
                ]}
                hasFeedback
              >
                <Input.Password />
              </FormItem>

              <FormItem
                name="confirm"
                label="Neues Passwort wiederholen"
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Bitte bestätigte dein Passwort!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        'Die eingegebenen Passwörter stimmen nicht überein!'
                      );
                    },
                  }),
                ]}
              >
                <Input.Password />
              </FormItem>
              <div className="errors">
                {errors &&
                  errors.length > 0 &&
                  errors.map((err) => <p>{err}</p>)}
              </div>

              <FormItem>
                <PrimaryButton
                  type="primary"
                  htmltype="submit"
                  minWidth="150px"
                >
                  Speichern
                </PrimaryButton>
              </FormItem>
            </Spin>
          </FormWrapper>
        </Form>
      </ResetPasswordWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, loading, errors } = state.authentication;
  return {
    user,
    loading,
    errors,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateProfile: (user) => dispatch(authActions.updateProfile(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
