import React, { useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Space } from 'antd';
import { useSelector } from 'react-redux';
import '../../../utils/Payment.css';



const DropDown = ({ setSelectedKey, options, className }) => {
    const selectedKey = useSelector((state) => state.payment.donationType);
    // const selectedKey = 0;
    const handleMenuClick = (e) => {
        setSelectedKey(e.key * 1);
    };

    const menu = (
        <Menu onClick={handleMenuClick} selectedKeys={[selectedKey]}>
            {options.map(item => (
                <Menu.Item key={item.key} style={selectedKey == item.key ? { backgroundColor: 'black', color: 'white', zIndex: 0 } : null}>
                    {item.label}
                </Menu.Item>
            ))}
        </Menu>
    );

    // const buttonStyle = {
    //     borderRadius: '10px',
    //     border: '1px solid black',
    //     fontWeight: 'bold',
    //     padding: '10px',
    //     maxWidth: '165px',
    //     background: 'none',
    //     width: '100%',
    //     color: 'black',
    //     height: '56px',
    //     fontSize: '20px',
    //     '@media screen and (max-width: 768px)': {
    //         fontSize: '16px', 
    //         height: '40px',   
    //       },
    //  };

    return (
        <>
            <Dropdown overlay={menu} trigger={['click']}>
                <Button className='dropdown-button'>
                    <Space>
                        {options[selectedKey].label}
                        <DownOutlined />
                    </Space>
                </Button>
            </Dropdown>
        </>
    );
};

export default DropDown;

