import styled from '@emotion/styled';
import { Spin } from 'antd';
import React, { useState } from 'react';
import { Colors } from 'utils';
import { useHistory } from 'react-router-dom';
import { isAuthenticated, loginUser } from 'utils/Authentication';
import { Logo } from 'images';

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: lightgrey;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const Card = styled.div`
  width: 350px;
  // height: 250px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-color: white;

  display: flex;
  flex-direction: column;
  align-items:center;
  gap: 10px;
`;

const Input = styled.input`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  width:100%
`;

const Button = styled.button`
  padding: 10px 15px;
  border-radius: 5px;
  border: none;
  background-color: ${Colors.secondaryColor};
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-top: auto;
  width: 100%;
  border: 1px solid ${Colors.secondaryColor};
  margin-top:20px;

  &:hover {
    background-color: transparent;
    color: ${Colors.secondaryColor};
  }

  &:disabled {
    background-color: grey;
    color: ;
  }
`;

const EMAIL = 'admin';
const PASSWORD = 'Hajj2024!!!';

function Locked({ history }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  if (isAuthenticated()) {
    history.replace('/');
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!email || !password) {
      return alert('Please fill all the fields');
    }

    if (email === EMAIL && password === PASSWORD) {
      loginUser({ email });
      history.push('/');
    } else {
      alert('Invalid Credentials');
    }
  };

  return (
    <Container>
      <Card>
        <div
          style={{
            width: '150px',
            margin:'20px 0'
          }}
        >
          <img
            style={{
              height: '100%',
              width: '100%',
            }}
            src={Logo}
            alt="crowd-funding-logo-icon"
          />
        </div>
        <Input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="text"
          placeholder="Username"
        />
        <Input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />

        <Button onClick={handleSubmit} type="submit">
          Submit
        </Button>
      </Card>
    </Container>
  );
}

export default Locked;
