import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { ArrowDown } from 'images';

const CollapseWrapper = styled.div`
  .collapsible {
    overflow: hidden;
    transition: max-height 0.3s ease-out; // note that we're transitioning max-height, not height!
    height: auto;
    max-height: 600px; // still have to hard-code a value!
    p {
      font-size: 18px;
    }
  }
  .collapsed {
    max-height: 0;
  }
`;

export default class Collapse extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.object.isRequired,
  };
  state = {
    open: false,
  };
  handelCollapse = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    const { title, children } = this.props;
    const { open } = this.state;
    return (
      <CollapseWrapper>
        <div
          onClick={() => {
            this.handelCollapse();
          }}
        >
          <h2>{title}</h2>
          <ArrowDown className="arrow-down" />
        </div>
        <div className={open ? 'collapsible' : 'collapsible collapsed'}>
          {children}
        </div>
      </CollapseWrapper>
    );
  }
}
