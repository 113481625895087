import React, { Component } from 'react';
import { Form, Select } from 'antd';
import {
  Privatperson,
  Einzelunternehmen,
  Unternehmen,
  Verein,
} from './Legitimation/index';
import styled from '@emotion/styled';
const FormItem = Form.Item;
const { Option } = Select;

const LegitimationFormWrapper = styled.div`
  h1 {
    margin-bottom: 25px !important;
  }
  .ant-form-item {
    display: block;
  }
  .ant-form-item-label {
    display: block;
    text-align: left;
  }
`;
export default class Legitimation extends Component {
  state = {
    userType: null,
    organisationType: null,
  };
  onPrivatpersonAndOrganisationChange = (value) => {
    this.setState({ userType: value });
  };
  onOrganisationChange = (value) => {
    this.setState({ organisationType: value });
  };
  render() {
    const { userType, organisationType } = this.state;
    return (
      <LegitimationFormWrapper>
        <h1>Legitimation</h1>
        <Form>
          <>
            <FormItem
              name={['Unterscheide']}
              label="Unterscheide zwischen "
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                placeholder="Select Project type"
                style={{ width: '100%' }}
                onChange={this.onPrivatpersonAndOrganisationChange}
              >
                <Option key={'Privatperson'}>Privatperson</Option>
                <Option key={'Organisation'}>Organisation</Option>
              </Select>
            </FormItem>
          </>
          {userType && userType === 'Privatperson' && <Privatperson />}
          {userType && userType === 'Organisation' && (
            <>
              <>
                <FormItem
                  name={['organisation']}
                  label="Organisation"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Project type"
                    style={{ width: '100%' }}
                    onChange={this.onOrganisationChange}
                  >
                    <Option key={'Unternehmen'}>Unternehmen</Option>
                    <Option key={'Verein'}>Verein</Option>
                    <Option key={'Einzelunternehmen'}>Einzelunternehmen</Option>
                  </Select>
                </FormItem>
              </>
              {organisationType && organisationType === 'Unternehmen' && (
                <Unternehmen />
              )}
              {organisationType && organisationType === 'Verein' && <Verein />}
              {organisationType && organisationType === 'Einzelunternehmen' && (
                <Einzelunternehmen />
              )}
            </>
          )}
        </Form>
      </LegitimationFormWrapper>
    );
  }
}
