import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { authActions } from 'redux/actions';
import { Form, Input, Spin } from 'antd';
import { Header, Footer } from 'components';
import { Container, H1, Colors, FormWrapper, PrimaryButton } from 'utils';
import styled from '@emotion/styled';
import { withRouter } from 'react-router-dom';
const FormItem = Form.Item;

const ResetPasswordWrapper = styled.div`
  max-width: 600px;
  margin: auto;
  h1 {
    color: ${Colors.GreenColor};
    display: inline-block;
    padding: 10px;
    border-radius: 10px;
    font-size: 35px;
    margin-top: 50px;
    font-weight: bold;
  }

  .ant-form-item {
    display: block;
    .ant-form-item-label {
      color: ${Colors.GreenColor};
      margin-bottom: 10px;
      label {
        font-weight: bold;
        font-size: 22px;
      }
    }
    .ant-picker,
    .ant-input-affix-wrapper {
      width: 100%;
      padding: 10px;
      border: 1px solid ${Colors.GreenColor};
      border-radius: 10px;
    }
  }
`;

export class ResetPassword extends Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    user: PropTypes.object.isRequired,
    changePassword: PropTypes.func.isRequired,
    errors: PropTypes.array,
    location: PropTypes.any,
  };

  constructor(props) {
    super(props);
    const search = this.props.location.search;
    const accessToken = new URLSearchParams(search).get('access-token');
    const client = new URLSearchParams(search).get('client');
    const uid = new URLSearchParams(search).get('uid');
    this.state = {
      accessToken,
      uid,
      client,
    };
  }
  onFinish = async(values) => {
    const { password, password_confirmation } = values;
    const { changePassword, history } = this.props;
    await changePassword({
      password,
      password_confirmation,
      accessToken: this.state.accessToken,
      client: this.state.client,
      uid: this.state.uid,
      addHeaders: true,
    });
    history.push('/login');
  };
  render() {
    const { loading } = this.props;
    return (
      <div>
        <Header relativeHeader={true} />
        <Container>
          <ResetPasswordWrapper>
            <H1>Passwort ändern</H1>
            <Spin spinning={loading}>
              <Form onFinish={this.onFinish}>
                <FormWrapper>
                  <>
                    <FormItem
                      name="password"
                      label="Neues Passwort"
                      rules={[
                        {
                          required: true,
                          message: 'Bitte gib dein Passwort ein.',
                        },
                      ]}
                      hasFeedback
                    >
                      <Input.Password />
                    </FormItem>

                    <FormItem
                      name="password_confirmation"
                      label="Neues Passwort wiederholen"
                      dependencies={['password']}
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Bitte bestätigte dein Passwort!',
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve();
                            }

                            return Promise.reject(
                              'Die eingegebenen Passwörter stimmen nicht überein!'
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password />
                    </FormItem>
                    <FormItem>
                      <PrimaryButton
                        type="primary"
                        htmltype="submit"
                        minWidth="150px"
                      >
                        Speichern
                      </PrimaryButton>
                    </FormItem>
                  </>
                </FormWrapper>
              </Form>
            </Spin>
          </ResetPasswordWrapper>
        </Container>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, loading, errors } = state.authentication;
  return {
    user,
    loading,
    errors,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changePassword: (password) => dispatch(authActions.changePassword(password)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
);
