import styled from '@emotion/styled';
import React, { Component } from 'react';
import {
  Colors,
  // Container,
  H1,
} from 'utils';

const Wordsdiv = styled.div`
  margin: 100px auto;
  text-align: center;
  h1 {
    padding: 15px;
    background: ${Colors.secondaryColor};
    display: block;
    color: ${Colors.white};
  }
`;
// const WordsWrapper = styled.div`
//   position: relative;
//   width: 100%;
//   height: 300px;

//   p {
//     display: inline-block;
//     font-size: 22px;
//     font-weight: bold;
//     position: absolute;
//     &:nth-of-type(1n + 1) {
//       color: ${Colors.GreenColor};
//     }
//     &:nth-of-type(2n + 2) {
//       color: ${Colors.secondaryColor};
//     }
//     &:nth-of-type(3n + 3) {
//       color: ${Colors.steel};
//     }
//     &:nth-of-type(1) {
//       top: 8%;
//       left: 3%;
//     }
//     &:nth-of-type(2) {
//       top: 21%;
//       left: 20%;
//     }
//     &:nth-of-type(3) {
//       top: 10%;
//       left: 34%;
//     }
//     &:nth-of-type(4) {
//       top: 40%;
//       left: 11%;
//     }
//     &:nth-of-type(5) {
//       top: 1%;
//       left: 49%;
//     }
//     &:nth-of-type(6) {
//       top: 35%;
//       left: 42%;
//     }
//     &:nth-of-type(7) {
//       top: 23%;
//       left: 64%;
//     }
//     &:nth-of-type(8) {
//       top: 40%;
//       left: 80%;
//     }
//     &:nth-of-type(9) {
//       top: 80%;
//       left: 80%;
//     }
//     &:nth-of-type(10) {
//       top: 90%;
//       left: 90%;
//     }
//     &:nth-of-type(11) {
//       top: 100%;
//       left: 100%;
//     }
//     &:nth-of-type(12) {
//       top: 43%;
//       left: 20%;
//     }
//     &:nth-of-type(13) {
//       top: 100%;
//       left: 0%;
//     }
//     &:nth-of-type(14) {
//       top: 90%;
//       left: 10%;
//     }
//     &:nth-of-type(15) {
//       top: 80%;
//       left: 20%;
//     }
//     &:nth-of-type(16) {
//       top: 24%;
//       left: 50%;
//     }
//     &:nth-of-type(17) {
//       top: 70%;
//       left: 30%;
//     }
//     &:nth-of-type(18) {
//       top: 63%;
//       left: 71%;
//     }
//     &:nth-of-type(19) {
//       top: 60%;
//       left: 53%;
//     }
//     &:nth-of-type(20) {
//       top: 60%;
//       left: 16%;
//     }
//     &:nth-of-type(21) {
//       top: 40%;
//       left: 60%;
//     }
//     &:nth-of-type(22) {
//       top: 30%;
//       left: 70%;
//     }
//     &:nth-of-type(22) {
//       top: 20%;
//       left: 80%;
//     }
//     &:nth-of-type(23) {
//       top: 10%;
//       left: 94%;
//     }
//     &:nth-of-type(24) {
//       top: 44%;
//       left: 44%;
//     }
//   }
// `;
export default class Words extends Component {
  render() {
    return (
      <Wordsdiv>
        <H1>Wir stehen für:</H1>
      </Wordsdiv>
    );
  }
}
