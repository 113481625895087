import React, { Component } from 'react';
import { Input, Form } from 'antd';
import { CitiesSelect, CountriesSelect } from 'components';
import PropTypes from 'prop-types';
const FormItem = Form.Item;

export default class AddressFrom extends Component {
  static propTypes = {
    required: PropTypes.bool,
  };
  state = {
    cityValue: '',
    countryValue: '',
  };

  handleCityChange = (cityValue) => {
    this.setState({
      cityValue,
    });
  };
  handleCountryChange = (countryValue) => {
    this.setState({
      countryValue,
    });
  };
  render() {
    const { cityValue, countryValue } = this.state;
    const { required } = this.props;
    return (
      <div>
        <FormItem
          name="street"
          label="Straße"
          rules={[
            {
              required: required ? true : false,
              message: 'Bitte gib dein Straße ein.',
            },
          ]}
        >
          <Input />
        </FormItem>
        <FormItem
          name="building_number"
          label="Nummer"
          rules={[
            {
              required: required ? true : false,
              message: 'Bitte gib dein Nummer ein.',
            },
          ]}
        >
          <Input type="number" />
        </FormItem>
        <FormItem
          name="postcode"
          label="Postleitzahl"
          rules={[
            {
              required: required ? true : false,
              message: 'Bitte gib dein Postleitzahl ein.',
            },
          ]}
        >
          <Input type="number" />
        </FormItem>

        <CitiesSelect
          handleCityChange={this.handleCityChange}
          cityValue={cityValue}
          required={required ? true : false}
        />

        <CountriesSelect
          handleCountryChange={this.handleCountryChange}
          countryValue={countryValue}
          required={required ? true : false}
        />
      </div>
    );
  }
}
