import React from 'react';
import { RejectIcon } from 'images';
import { Colors, dateFormat, SvgWrapper } from 'utils';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import moment from 'moment';
import PropTypes from 'prop-types';

const RejectEditWrapper = styled.div`
  background: red;
  padding: 20px 40px 20px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  border-radius: 15px;
  position: relative;
  & > div {
    display: flex;
    align-items: center;
  }
  a {
    background: ${Colors.white};
    border: 1px solid ${Colors.white};
    color: ${Colors.black};
    text-align: center;
    border-radius: 12px;
    cursor: pointer;
    padding: 15px 20px;
    min-width: 200px;
    font-size: 18px;
    & + a {
      margin-left: 25px;
    }
    &:hover {
      background: ${Colors.transparent};
      color: ${Colors.white};
    }
  }
  p {
    font-weight: bold;
    font-size: 22px;
    color: ${Colors.white};
    margin: 0;
    margin-left: 15px;
  }
  .remove-button {
    background: none;
    border: none;
    position: absolute;
    top: 10px;
    right: 10px;
  }
`;
export default function RejectEdit({ time, message, projectId, removeEdit }) {
  return (
    <RejectEditWrapper>
      <div>
        <SvgWrapper width="50px" height="50px">
          <RejectIcon />
        </SvgWrapper>
        <div>
          <p>
            Veränderung vom {moment.unix(time).format(dateFormat)} wurde
            abgelehnt
          </p>
          {message && (
            <p>Dein Projektantrag wurde leider abgelehnt. Grund: {message}</p>
          )}
        </div>
      </div>
      <button onClick={() => removeEdit(projectId)} className="remove-button">
        <SvgWrapper width="20px" height="20px">
          <RejectIcon />
        </SvgWrapper>
      </button>
      <Link to="/contact-us">Support</Link>
    </RejectEditWrapper>
  );
}

RejectEdit.propTypes = {
  time: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired,
  projectId: PropTypes.number.isRequired,
  removeEdit: PropTypes.func.isRequired,
};
