import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { ArrowDown } from 'images';
import { SvgWrapper } from 'utils';

const openingCollape = keyframes`
  0% { 
    max-height: 0;
  }
  100% { 
    max-height: 1000px;
  }
`;

const closeingCollape = keyframes`
  0% { 
    max-height: 1000px;
  }
  100% { 
    max-height: 0;
  }
`;

const CollapseWrapper = styled.div`
  .collapse-header,
  .collapse-title.collapse-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    li {
      padding: 0;
      &:before {
        content: none;
      }
    }
  }
  h2 {
    margin: 0;
  }
  .arrow-down {
    transition: transform 0.3s ease-out;
    transform: rotate(0);
  }
  .open-arrow {
    transform: rotate(180deg);
  }
  .collapsible {
    overflow: hidden;
    max-height: 0;
    height: 0;
  }
  .collapsed {
    animation: ${closeingCollape} 0.5s forwards;
    height: 0;
  }
  .open-collaps {
    animation: ${openingCollape} 0.5s forwards;
    height: auto;
  }
`;

export default class Collapse extends Component {
  static propTypes = {
    title: PropTypes.object.isRequired,
    children: PropTypes.object.isRequired,
    arrowWidth: PropTypes.string,
    defaultState: PropTypes.bool,
    handleMultiCollapes: PropTypes.func,
    witchQuestions: PropTypes.string,
  };
  state = {
    open: false,
  };
  handelCollapse = () => {
    const { defaultState, handleMultiCollapes, witchQuestions } = this.props;
    this.setState({ open: !this.state.open });
    if (handleMultiCollapes) {
      handleMultiCollapes(!defaultState, witchQuestions);
    }
  };

  render() {
    const { title, children, arrowWidth, defaultState } = this.props;
    const { open } = this.state;
    const collapseState =
      defaultState !== null && defaultState !== undefined ? defaultState : open;

    return (
      <CollapseWrapper {...this.props}>
        <div
          onClick={() => {
            this.handelCollapse();
          }}
          className="collapse-header collapse-title"
        >
          {title}
          <SvgWrapper
            width={arrowWidth ? arrowWidth : null}
            height={arrowWidth ? arrowWidth : null}
          >
            <ArrowDown
              className={collapseState ? 'open-arrow arrow-down' : 'arrow-down'}
            />
          </SvgWrapper>
        </div>
        <div
          className={
            collapseState ? 'collapsible open-collaps' : 'collapsible collapsed'
          }
        >
          {children}
        </div>
      </CollapseWrapper>
    );
  }
}
