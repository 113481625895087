import React, { Component } from 'react';
import {
  Colors,
  isMobile,
  isLabTop,
  isTablet,
  isMinLaptop,
  is4K,
  H3,
} from 'utils';
import { ShareIcon } from 'images';
import styled from '@emotion/styled';
import { SvgWrapper, device } from 'utils';
import SectionTitleH2 from 'utils/SectionTitleH2';

const ShareWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 150px;
  @media ${device.allMobile} {
    flex-wrap: wrap;
    margin-bottom: 25px;
  }
  .content {
    width: 60%;
    text-align: left;
    @media ${device.allMobile} {
      width: 100%;
      order: 2;
      text-align: center;
    }
    h2 {
      margin-bottom: 10px;
      margin-top: 0;
      display: flex;
      align-items: center;
      font-weight: bold;
      @media ${device.laptop} {
        font-size: 25px;
      }
      @media ${device.allMobile} {
        justify-content: center;
      }
      &::before {
        content: '3';
        font-size: 30px;
        background: ${Colors.secondaryColor};
        width: 50px;
        text-align: center;
        height: 50px;
        line-height: 50px;
        vertical-align: middle;
        border-radius: 50%;
        display: inline-block;
        margin-right: 10px;
        color: ${Colors.white};
      }
    }
    p {
      margin: 0 0 10px;
      font-size: 22px;
      margin-left: 60px;
      max-width: 90%;
      @media ${device.laptop} {
        font-size: 18px;
      }
      @media ${device.allMobile} {
        margin: auto;
        max-width: 90%;
      }
    }
  }
  .image {
    width: 40%;
    @media ${device.allMobile} {
      width: 100%;
      order: 1;
    }
    img {
      width: 60%;
    }
  }
`;

export default class Share extends Component {
  componentDidMount() {
    const Icon = document.getElementById('freepik_stories-mobile-marketing');
    window.addEventListener('scroll', function () {
      if (isMobile()) {
        if (window.scrollY >= 6102 && window.scrollY <= 6844) {
          Icon.classList.add('animated');
        } else {
          Icon.classList.remove('animated');
        }
      } else if (isTablet()) {
        if (window.scrollY >= 4761 && window.scrollY <= 5768) {
          Icon.classList.add('animated');
        } else {
          Icon.classList.remove('animated');
        }
      } else if (isMinLaptop()) {
        if (window.scrollY >= 5000 && window.scrollY <= 6030) {
          Icon.classList.add('animated');
        } else {
          Icon.classList.remove('animated');
        }
      } else if (isLabTop()) {
        if (window.scrollY >= 5659 && window.scrollY <= 6613) {
          Icon.classList.add('animated');
        } else {
          Icon.classList.remove('animated');
        }
      } else if (is4K()) {
        if (window.scrollY >= 8159 && window.scrollY <= 9519) {
          Icon.classList.add('animated');
        } else {
          Icon.classList.remove('animated');
        }
      } else {
        if (window.scrollY >= 7799 && window.scrollY <= 8700) {
          Icon.classList.add('animated');
        } else {
          Icon.classList.remove('animated');
        }
      }
    });
  }
  render() {
    return (
      <ShareWrapper>
        <div className="image">
          <SvgWrapper width="75%" height="auto">
            <ShareIcon />
          </SvgWrapper>
        </div>
        <div className="content">
          <SectionTitleH2>Teile und verbreite es</SectionTitleH2>
          <p>
            Teile die Projektseite mit deiner Familie und deinen Freunden und
            trage somit zusätzlich dazu bei, dass das Projekt sein Fundingziel
            erreicht.
          </p>
        </div>
      </ShareWrapper>
    );
  }
}
