import React from 'react';
import { Colors, H1, H2, H3, SvgWrapper, device } from 'utils';
import { IdeaIcon } from 'images';
import styled from '@emotion/styled';
import SectionTitleH3 from 'utils/SectionTitleH3';
import SectionTitleH2 from 'utils/SectionTitleH2';

const IdeaWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 150px;
  @media ${device.allMobile} {
    flex-wrap: wrap;
    margin-top: 25px;
  }
  .content {
    width: 60%;
    text-align: left;
    @media ${device.allMobile} {
      width: 100%;
      order: 2;
      text-align: center;
    }
    h2 {
      margin-bottom: 30px;
      margin-top: 0;
      display: flex;
      align-items: center;
      font-weight: bold;
      @media ${device.laptop} {
        font-size: 25px;
      }
      @media ${device.allMobile} {
        justify-content: center;
      }
      &::before {
        content: '1';
        font-size: 30px;
        background: ${Colors.secondaryColor};
        width: 50px;
        text-align: center;
        height: 50px;
        line-height: 50px;
        vertical-align: middle;
        border-radius: 50%;
        display: inline-block;
        margin-right: 10px;
        color: ${Colors.white};
      }
    }
    p {
      margin: 0 0 10px;
      font-size: 22px;
      max-width: 100%;
      margin-left: 60px;
      @media ${device.laptop} {
        font-size: 18px;
      }
      @media ${device.allMobile} {
        margin: auto;
      }
    }
  }
  .image {
    width: 40%;
    @media ${device.allMobile} {
      width: 100%;
      order: 1;
    }
  }
`;

export default function Idea() {
  return (
    <IdeaWrapper>
      <div className="content">
        <SectionTitleH2>Alles beginnt mit einer Idee</SectionTitleH2>
        <p>
          Du hast eine kreative Idee: Ob du eine neue App, ein
          Obdachlosenprojekt in deiner Stadt oder ein Theaterstück starten
          willst, all das beansprucht eine finanzielle Quelle. Auf commonsplace
          findest du eine Community, die dich bei deiner Idee unterstützt.
        </p>
      </div>
      <div className="image">
        <SvgWrapper width="75%" height="100%">
          <IdeaIcon />
        </SvgWrapper>
      </div>
    </IdeaWrapper>
  );
}
