/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import PropTypes from 'prop-types';

const PaymentIcon = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      height={width || '512'}
      width={height || '512'}
      x={0}
      y={0}
      viewBox="0 0 473.94 473.94"
      style={{ enableBackground: 'new 0 0 512 512' }}
      xmlSpace="preserve"
      css={css`
        max-width: ${width || '100%'};
        max-height: ${height || '100%'};
        height: ${height || '100%'};
        width: ${width || '100%'};
        display: inline-block;
      `}
    >
      <g>
        <circle
          xmlns="http://www.w3.org/2000/svg"
          style={{}}
          cx="236.97"
          cy="236.97"
          r="236.97"
          fill="#22312b"
          data-original="#f3f2f2"
        />
        <rect
          xmlns="http://www.w3.org/2000/svg"
          x="127.93"
          y="250.85"
          width="53.02"
          height="88.04"
          fill="#ffffff"
          data-original="#000000"
          style={{}}
        />
        <circle
          xmlns="http://www.w3.org/2000/svg"
          cx="154.91"
          cy="206.84"
          r="30.01"
          fill="#ffffff"
          data-original="#000000"
          style={{}}
        />
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M281.358,88.47H91.142v288.475h190.216v-0.06c41.549-0.569,74.461-10.881,97.821-30.761  c28.415-24.172,42.817-62.33,42.817-113.424c0-24.415-3.794-46.327-11.263-65.107c-7.147-17.964-17.713-33.246-31.397-45.406  c-24.101-21.418-57.93-33.002-97.982-33.657v-0.06L281.358,88.47L281.358,88.47z M277.762,107.628  c36.864,0,67.587,9.983,88.852,28.883c24.03,21.365,36.22,53.732,36.22,96.193c0,84.16-40.912,125.08-125.072,125.08  c-6.541,0-150.636,0-167.466,0c0-17.163,0-232.992,0-250.152C127.126,107.628,271.221,107.628,277.762,107.628z"
          fill="#ffffff"
          data-original="#000000"
          style={{}}
        />
        <path
          xmlns="http://www.w3.org/2000/svg"
          style={{}}
          d="M272.524,338.977H205.28V130.95h67.243h-2.713c56.082,0,115.781,22.133,115.781,104.283  c0,86.846-59.7,103.744-115.781,103.744H272.524z"
          fill="#8f8b8b"
          data-original="#cc1b69"
        />
        <g xmlns="http://www.w3.org/2000/svg">
          <path
            style={{}}
            d="M193.685,184.652h18.469c4.165,0,7.338,0.314,9.523,0.954c2.937,0.864,5.444,2.406,7.543,4.606   c2.092,2.215,3.689,4.913,4.778,8.112c1.089,3.195,1.639,7.139,1.639,11.831c0,4.116-0.52,7.671-1.534,10.649   c-1.257,3.648-3.042,6.593-5.358,8.846c-1.755,1.706-4.123,3.038-7.094,3.992c-2.238,0.703-5.22,1.059-8.947,1.059h-19.016v-50.05   h-0.003L193.685,184.652L193.685,184.652z M203.776,193.138v33.085h7.562c2.829,0,4.876-0.161,6.125-0.479   c1.643-0.404,3.005-1.1,4.086-2.077c1.089-0.977,1.964-2.586,2.653-4.827c0.688-2.238,1.029-5.283,1.029-9.141   c0-3.865-0.344-6.825-1.029-8.894s-1.639-3.674-2.874-4.838c-1.227-1.164-2.799-1.946-4.685-2.354   c-1.418-0.318-4.187-0.475-8.318-0.475L203.776,193.138L203.776,193.138z"
            fill="#ffffff"
            data-original="#ffffff"
          />
          <path
            style={{}}
            d="M192.315,183.279v52.792h20.381c3.85,0,7.001-0.382,9.354-1.123   c3.173-1.014,5.736-2.466,7.637-4.318c2.462-2.395,4.385-5.542,5.699-9.377c1.066-3.117,1.609-6.851,1.609-11.098   c0-4.823-0.576-8.95-1.71-12.269c-1.156-3.375-2.866-6.271-5.078-8.614c-2.253-2.376-4.999-4.052-8.15-4.98   c-2.339-0.681-5.575-1.014-9.908-1.014L192.315,183.279L192.315,183.279z M212.15,186.018c4.011,0,7.091,0.303,9.141,0.898   c2.683,0.797,5.014,2.215,6.93,4.239c1.946,2.054,3.45,4.614,4.479,7.607c1.036,3.042,1.557,6.874,1.557,11.394   c0,3.948-0.49,7.382-1.456,10.208c-1.175,3.416-2.866,6.215-5.021,8.307c-1.594,1.549-3.802,2.788-6.563,3.674   c-2.084,0.659-4.954,0.992-8.52,0.992c0,0-15.289,0-17.65,0c0-2.518,0-44.804,0-47.318   C197.404,186.018,212.15,186.018,212.15,186.018z"
            fill="#ffffff"
            data-original="#ffffff"
          />
          <path
            style={{}}
            d="M202.411,191.761v35.824h8.932c2.975,0,5.085-0.168,6.462-0.516c1.848-0.464,3.42-1.268,4.67-2.395   c1.28-1.156,2.279-2.93,3.046-5.437c0.73-2.391,1.081-5.512,1.081-9.545c0-4.049-0.359-7.094-1.096-9.324   c-0.752-2.271-1.841-4.093-3.237-5.399c-1.403-1.328-3.199-2.238-5.336-2.698c-1.519-0.344-4.333-0.513-8.602-0.513h-5.92   L202.411,191.761L202.411,191.761z M208.33,194.504c4.992,0,7.109,0.239,8.019,0.442c1.643,0.359,3.005,1.033,4.049,2.013   c1.059,0.999,1.901,2.436,2.514,4.277c0.629,1.916,0.95,4.76,0.95,8.464c0,3.704-0.322,6.649-0.965,8.748   c-0.606,1.983-1.366,3.398-2.26,4.202c-0.906,0.823-2.092,1.418-3.51,1.766c-1.134,0.292-3.083,0.442-5.788,0.442   c0,0-4.355,0-6.189,0c0-2.41,0-27.943,0-30.349C206.485,194.504,208.33,194.504,208.33,194.504z"
            fill="#ffffff"
            data-original="#ffffff"
          />
          <path
            style={{}}
            d="M247.053,234.706v-50.054h37.051v8.486h-26.959v11.083h25.074v8.49h-25.074v13.511h27.917v8.486   h-38.009L247.053,234.706L247.053,234.706z"
            fill="#ffffff"
            data-original="#ffffff"
          />
          <path
            style={{}}
            d="M245.68,183.279v52.792h40.751v-11.218c0,0-25.44,0-27.917,0c0-1.964,0-8.812,0-10.78   c2.447,0,25.074,0,25.074,0v-11.222c0,0-22.626,0-25.074,0c0-1.804,0-6.544,0-8.348c2.462,0,26.959,0,26.959,0v-11.225   L245.68,183.279L245.68,183.279z M282.731,186.018c0,1.557,0,4.187,0,5.744c-2.462,0-26.952,0-26.952,0v13.826   c0,0,22.626,0,25.074,0c0,1.553,0,4.195,0,5.744c-2.447,0-25.074,0-25.074,0v16.254c0,0,25.433,0,27.91,0c0,1.557,0,4.191,0,5.747   c-2.447,0-32.819,0-35.27,0c0-2.518,0-44.804,0-47.318C250.863,186.018,280.292,186.018,282.731,186.018z"
            fill="#ffffff"
            data-original="#ffffff"
          />
          <path
            style={{}}
            d="M341.585,234.706h-11.001l-4.363-11.364h-19.996l-4.15,11.364h-10.72l19.453-50.054h10.758   L341.585,234.706z M322.978,214.863l-6.9-18.548l-6.761,18.548H322.978z"
            fill="#ffffff"
            data-original="#ffffff"
          />
          <path
            style={{}}
            d="M309.874,183.279l-20.512,52.792h13.672c0,0,3.588-9.83,4.142-11.356c1.65,0,16.479,0,18.103,0   c0.569,1.493,4.37,11.356,4.37,11.356h13.953l-21.107-52.792L309.874,183.279L309.874,183.279z M320.639,186.018   c0.636,1.601,17.553,43.898,18.922,47.318c-2.413,0-6.776,0-8.03,0c-0.569-1.489-4.37-11.356-4.37-11.356h-21.897   c0,0-3.588,9.837-4.135,11.356c-1.265,0-5.414,0-7.768,0c1.317-3.394,17.751-45.694,18.387-47.318   C313.155,186.018,319.236,186.018,320.639,186.018z"
            fill="#ffffff"
            data-original="#ffffff"
          />
          <path
            style={{}}
            d="M307.359,216.225h17.583l-8.879-23.869L307.359,216.225z M316.093,200.278   c1.497,4.03,4.026,10.844,4.913,13.212c-2.398,0-7.345,0-9.736,0C312.134,211.136,314.618,204.315,316.093,200.278z"
            fill="#ffffff"
            data-original="#ffffff"
          />
          <path
            style={{}}
            d="M350.027,234.706V185.06h10.092v41.159h25.148v8.486h-35.24L350.027,234.706L350.027,234.706z"
            fill="#ffffff"
            data-original="#ffffff"
          />
          <path
            style={{}}
            d="M348.661,183.694v52.377h37.979v-11.218c0,0-22.705,0-25.156,0c0-2.563,0-41.159,0-41.159H348.661z    M358.753,186.429c0,2.559,0,41.159,0,41.159s22.698,0,25.148,0c0,1.557,0,4.191,0,5.747c-2.425,0-30.08,0-32.508,0   c0-2.518,0-44.388,0-46.907C353.117,186.429,357.028,186.429,358.753,186.429z"
            fill="#ffffff"
            data-original="#ffffff"
          />
        </g>
      </g>
    </svg>
  );
};

export default PaymentIcon;

PaymentIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};
