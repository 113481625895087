import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PaymentActions, ProjectsActions } from 'redux/actions';
import { Colors, PrimaryButton } from 'utils';
import styled from '@emotion/styled';
import SLI from './../../../images/stripe-legitimate.png'
import LT from './../../../images/legitimation.png'

const StripeAccountWrapper = styled.div`
  p {
    color: ${Colors.GreenColor};
    font-weight: bold;
    margin: 25px 0 15px !important;
    span {
      color: ${Colors.secondaryColor};
    }
    & + p {
      font-weight: normal;
      margin-top: 10px !important;
      margin-bottom: 10px !important;
    }
  }
`;
export class StripeAccount extends Component {
  static propTypes = {
    stripeStatus: PropTypes.string.isRequired,
    getStripeStatus: PropTypes.func.isRequired,
    setUpStripeAccountURL: PropTypes.func.isRequired,
    projectId: PropTypes.string.isRequired,
  };
  componentDidMount() {
    const { projectId, getStripeStatus } = this.props;
    getStripeStatus(projectId);
  }

  render() {
    const { stripeStatus, setUpStripeAccountURL, projectId } = this.props;
    const handleButtonText = (stripeStatus) => {
      switch (stripeStatus) {
        case 'no_account':
          return 'Jetzt legitimieren';

        case 'registration_not_complete':
          return 'Legitimation abschließen';

        default:
          return 'Mein Stripe-Account';
      }
    };
    const handleStatusText = (stripeStatus) => {
      switch (stripeStatus) {
        case 'no_account':
          return 'Du benötigst einen Stripe Account, um fortzuführen zu können.';
        case 'registration_not_complete':
          return 'Bitte schließe deine Anmeldung bei Stripe ab.';
        case 'enabled':
          return 'Du kannst nun Zahlungen annehmen.';
        case 'enabled_and_eventually_due':
          return 'Du kannst nun Zahlungen annehmen. Eventuell benötigen wir Zusatzinformationen von dir.';
        case 'pending':
          return 'Stripe überprüft deine Anmeldung.';
        case 'disabled':
          return 'Gesperrt: Bitte prüfe deinen Stripe Account.';
        default:
          return 'Bitte prüfe deinen Stripe Account.';
      }
    };

    return (
      <StripeAccountWrapper>
        <p>
          You Account Status : <span>{handleStatusText(stripeStatus)}</span>
        </p>
        <p>
          Für die Genehmigung deines Projekts bei commonsplace ist eine Legitimation deiner
          Identität <br/> erforderlich, die von unserem Zahlungspartner Stripe durchgeführt wird.
        </p>
        <p>
          Klicke auf "Jetzt legitimieren", um die Legitimation bei Stripe durchzuführen.<br/>
          Nach Abschluss der Legitimation kannst du deinen Legitimation-Status in deinem<br/>
          Profil einsehen und wirst benachrichtigt, falls weitere Dokumente benötigt werden.
        </p>
        <p>
          Bei Problemen mit der Legitimation kontaktiere unser Support-Team unter <br/>
          <a href="mailto:support@commonsplace.de" rel="noreferrer">
            support@commonsplace.de
          </a>
        </p>
        <p><b>Information – Deine Unternehmenskategorie verstehen und auswählen</b></p>
        <p>Stripe wird dich im Legitimationsprozess nach deinem Geschäftstypen fragen. Nachfolgend erhältst <br /> du Unterstützung:</p>
        <div> </div>
        <img style={{margin:'50px 0px'}} src={SLI} alt="Your Image Description" />
        <img style={{marginBottom:'100px'}} src={LT} alt="Your Image Description" />

        <PrimaryButton
          onClick={() =>
            setUpStripeAccountURL(projectId).then((res) => {
              res && this.props.handleSteps(10);
            })
          }
          minWidth={'100%'}
        >
          {handleButtonText(stripeStatus)}
        </PrimaryButton>
      </StripeAccountWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  const { stripeStatus } = state.payment;
  return {
    stripeStatus,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getStripeStatus: (projectId) =>
    dispatch(PaymentActions.getStripeStatus(projectId)),
  setUpStripeAccountURL: (projectId) =>
    dispatch(PaymentActions.setUpStripeAccountURL(projectId)),
  handleSteps: (step) => dispatch(ProjectsActions.handleSteps(step)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StripeAccount);
