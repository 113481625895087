/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import PropTypes from 'prop-types';

const AcceptIcon = ({ width, height }) => {
  return (
    <svg
      version="1.1"
      x="0"
      y="0"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      height={`${height || '100%'}`}
      width={`${width || '100%'}`}
      css={css`
        max-width: ${width || '100%'};
        max-height: ${height || '100%'};
        height: ${height || '100%'};
        width: ${width || '100%'};
        display: inline-block;
        enable-background: new 0 0 512 512;
      `}
    >
      <path
        d="M256,0C115.3,0,0,115.3,0,256s115.3,256,256,256s256-115.3,256-256S396.7,0,256,0z"
        fill="#ffffff"
      />
      <path
        d="M512,256c0,140.7-115.3,256-256,256V0C396.7,0,512,115.3,512,256z"
        fill="#ffffff"
      />
      <polygon
        points="401.8,212.5 226,388.299 99.699,262.299 142.301,219.699 226,303.699 256,273.699   359.5,170.2 "
        fill="#95bd51"
      />
      <polygon
        points="401.8,212.5 256,358.299 256,273.699 359.5,170.2 "
        fill="#95bd51"
      />
    </svg>
  );
};

export default AcceptIcon;

AcceptIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  color: PropTypes.string,
};
