import React, { Component } from 'react';
import { Form, Input, DatePicker, Upload, Select } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { dateFormat, H2, eighteenYearsFromNow } from 'utils';
import { AddressForm } from 'components';
const { Option } = Select;
const FormItem = Form.Item;

export default class Verein extends Component {
  state = {
    showPersonalInfo: false,
  };
  onChange = (value) => {
    this.setState({ showPersonalInfo: value === 'Ja' ? false : true });
  };
  render() {
    const { showPersonalInfo } = this.state;
    return (
      <>
        <>
          <FormItem
            name={['Verein', 'verein']}
            label="Verein"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select style={{ width: '100%' }}>
              <Option key={'e.V'} value={'e.V'}>
                eingetragener Verein (e.V)
              </Option>
              <Option key={'Stiftung'} value={'Stiftung'}>
                Stiftung
              </Option>
            </Select>
          </FormItem>
          <FormItem
            name={['Verein', 'name']}
            label="Firmenname"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </FormItem>
          <FormItem
            name={['Verein', 'email']}
            label="E-Mail-Adresse"
            rules={[
              {
                required: true,
                message: 'Bitte gib deine E-Mail-Adresse ein.',
              },
            ]}
          >
            <Input type="email" />
          </FormItem>
          <FormItem
            name={['Verein', 'CompanyNumber']}
            label="Handelsregisternummer"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input type="number" />
          </FormItem>
          <AddressForm />
        </>
        <>
          <FormItem
            name="aboutYou"
            label="bist du der gesetzlich Vertreter?"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select style={{ width: '100%' }} onChange={this.onChange}>
              <Option key={'Ja'} value={'Ja'}>
                Ja
              </Option>
              <Option key={'Nein'} value={'Nein'}>
                Nein
              </Option>
            </Select>
          </FormItem>
          <H2>Gesetzlicher Vertreter</H2>
          {showPersonalInfo && (
            <>
              <FormItem
                name={['Gesetzlicher', 'firstName']}
                label="Vorname"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </FormItem>
              <FormItem
                name={['Gesetzlicher', 'lastName']}
                label="Nachname"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </FormItem>
              <FormItem
                name={['Gesetzlicher', 'DateOfBirth']}
                label="Geburtsdatum"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker
                  format={dateFormat}
                  disabledDate={(d) => !d || d.isAfter(eighteenYearsFromNow)}
                />
              </FormItem>
              <FormItem
                name={['Gesetzlicher', 'phone']}
                label="Telefonnummer"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </FormItem>
              <AddressForm />
              <FormItem
                name={['Gesetzlicher', 'email']}
                label="E-Mail-Adresse"
                rules={[
                  {
                    required: true,
                    message: 'Bitte gib deine E-Mail-Adresse ein.',
                  },
                ]}
              >
                <Input type="email" />
              </FormItem>
              <FormItem
                name={['Gesetzlicher', 'nationality']}
                label="Staatsangehörigkeit"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </FormItem>
            </>
          )}

          <FormItem
            name="dragger"
            valuePropName="fileList"
            getValueFromEvent={this.normFile}
            label="Ausweis/Reisepass/Führerschein ['IDENTITY_PROOF']"
          >
            <Upload.Dragger name="files">
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload.
              </p>
            </Upload.Dragger>
          </FormItem>
          <FormItem
            name="dragger"
            valuePropName="fileList"
            getValueFromEvent={this.normFile}
            label="Vereinssatzung ['ARTICLES_OF_ASSOCIATION']"
          >
            <Upload.Dragger name="files">
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload.
              </p>
            </Upload.Dragger>
          </FormItem>
          <FormItem
            name="dragger"
            valuePropName="fileList"
            getValueFromEvent={this.normFile}
            label="Vereinsregisterauszug ['REGISTRATION PROOF']"
          >
            <Upload.Dragger name="files">
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload.
              </p>
            </Upload.Dragger>
          </FormItem>
        </>
      </>
    );
  }
}
