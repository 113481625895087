import React from 'react';
// import { EyeIcon } from 'images';
import {
  Colors,
  //  SvgWrapper
} from 'utils';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const LeftSideBarWrapper = styled.aside`
  width: 250px;
  padding: 20px;
  min-height: calc(100vh - 96px);
  background: ${Colors.grey};
  nav {
    position: sticky;
    top: 20px;
    ul {
      padding: 0;
      list-style: none;
      li {
        font-size: 25px;
        font-weight: bold;
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        align-items: center;
      }
      .state {
        text-align: center;
        width: 100%;
        h1 {
          background: ${Colors.GreenColor};
          color: ${Colors.white};
          border-radius: 10px;
          padding: 10px 20px;
          width: 100%;
          margin: auto;
        }
      }
    }
  }
`;
LeftSideBar.propTypes = {
  status: PropTypes.string.isRequired,
};
export default function LeftSideBar({ status }) {
  const handleStatus = (status) => {
    switch (status) {
      case 'accepted':
      case 'edit_pending':
      case 'edit_accepted':
      case 'edit_declined':
      case 'accepted_and_draft':
        return 'Online';
      case 'declined':
      case 'pending':
      case 'totally_declined':
        return 'Offline';
      default:
        return 'Offline';
    }
  };

  return (
    <LeftSideBarWrapper>
      <nav>
        <ul>
          <li>
            <div className="state">
              <h1>{handleStatus(status)}</h1>
            </div>
          </li>
          {/* {(status === 'accepted' || status === 'accepted_and_draft') && (
            <li>
              <SvgWrapper width={'50px'} height={'50px'}>
                <EyeIcon />
              </SvgWrapper>
              <span>8965</span>
            </li>
          )} */}
        </ul>
      </nav>
    </LeftSideBarWrapper>
  );
}
