import React from 'react';
import { Colors, Container, H1, H2, SvgWrapper, device } from 'utils';
import {
  AliProfilePic,
  LinkedIn,
  Mail,
  BurakProfilePic,
  SamyProfilePic,
  // SouheilProfilePic,
  SamimProfilePic,
  BeforeBackground,
  AfterBackground,
  Martin,
} from 'images';
import Naima from 'images/Naima.png';
import styled from '@emotion/styled';

const WhoAreWeWrapper = styled.div`
  text-align: center;
  position: relative;
  .container {
    background: #f9f9f9;
    margin-top: -8px;
  }

  .after-background {
    position: relative;
    margin-top: -0;
  }
  h1 {
    padding: 15px;
    background: ${Colors.secondaryColor};
    display: block;
    color: ${Colors.white};
    margin-bottom: 100px;
    width: 100%;
  }
`;
const COsWrapper = styled.div`
  display: flex;
  justify-content: space-around;

  @media ${device.allMobile} {
    display: block;
  }
  @media ${device.tablet} {
    flex-wrap: wrap;
  }
  & > div {
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    padding: 0 10px;
    color: ${Colors.GreenColor};
    @media ${device.allMobile} {
      width: 100%;
      margin-top: 25px;
    }
    @media ${device.tablet} {
      width: 50%;
      margin-top: 25px;
    }
    picture,
    img {
      width: 100%;
      border-radius: 50%;
      height: 280px;
      object-fit: cover;
      @media ${device.allMobile},
        ${device.tablet},
        ${device.minLaptop},
        ${device.laptop} {
        height: auto;
      }
    }
    div {
      h2 {
        color: ${Colors.GreenColor};
        margin-top: 20px;
        @media ${device.laptop} {
          font-size: 16px;
        }
      }
      p {
        margin-bottom: 20px;
        @media ${device.laptop} {
          font-size: 14px;
        }
      }
      a + a {
        margin-left: 15px;
      }
    }
  }
`;
export default function WhoAreWe() {
  return (
    <WhoAreWeWrapper>
      <div className="background">
        <SvgWrapper width="100%" height="100%">
          <BeforeBackground />
        </SvgWrapper>
      </div>
      <div className="container">
        <H2>Das Team</H2>
        <Container>
          <COsWrapper>
            {/* M. Samy Adamou */}
            <div>
              <div>
                <picture>
                  <img src={SamyProfilePic} alt="M. Samy Adamou" />
                </picture>
                <div>
                  <div>
                    <H2>M. Samy Adamou</H2>
                    <p>Geschäftsführer & Gründer</p>
                  </div>
                  <p>
                    Samy ist gebürtiger Aachener und hat Abschlüsse in
                    Internationaler Wirtschaft von der Universität Maastricht,
                    in Islamologie von der Universität in Novi Pazar sowie in
                    Public Policy von der Hertie School in Berlin erworben. Ihm
                    liegen insbesondere die Gemeinschaft, der Zusammenhalt und
                    das soziale Engagement in der muslimischen Community am
                    Herzen. Aus diesem Grund hat er commonsplace gegründet.
                  </p>
                </div>
              </div>
              <div>
                <a href="mailto:samy@commonsplace.de" rel="noreferrer">
                  <SvgWrapper>
                    <Mail color={Colors.GreenColor} />
                  </SvgWrapper>
                </a>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/m-samy-adamou-43704b137/"
                  rel="noreferrer"
                >
                  <SvgWrapper>
                    <LinkedIn color={Colors.GreenColor} />
                  </SvgWrapper>
                </a>
              </div>
            </div>
            {/* Samim */}
            <div>
              <div>
                <picture>
                  <img src={SamimProfilePic} alt="Samin-Fiaz" />
                </picture>
                <div>
                  <div>
                    <H2>M. H. Samim Faiz</H2>
                    <p>CTO & Gründer</p>
                  </div>
                  <p>
                    Samim ist gebürtiger Bonner und Cyber Security Senior
                    Manager bei Accenture. Die Vision des Einheitsgedanken
                    innerhalb der muslimischen Community, den er als absolut
                    notwendige Basis für eine nachhaltige Bereicherung der
                    Gesamtgesellschaft versteht, treibt ihn an. commonsplace ist
                    für ihn die optimale Möglichkeit, diese Visionen zu
                    entfalten.
                  </p>
                </div>
              </div>
              <div>
                <a href="mailto:samim@commonsplace.de" rel="noreferrer">
                  <SvgWrapper>
                    <Mail color={Colors.GreenColor} />
                  </SvgWrapper>
                </a>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/mhsfaiz/"
                  rel="noreferrer"
                >
                  <SvgWrapper>
                    <LinkedIn color={Colors.GreenColor} />
                  </SvgWrapper>
                </a>
              </div>
            </div>
            {/* Burak Erkovan */}
            <div>
              <div>
                <picture>
                  <img
                    src={Naima}
                    alt="Naima Hassan"
                    style={{ objectPosition: 'top' }}
                  />
                </picture>
                <div>
                  <div>
                    <H2>Naima Hassan</H2>
                    <p>Outreach und Kooperation</p>
                  </div>
                  <p>
                    Naima, gebürtige Wiesbadenerin und IT Requirements Engineer
                    am Frankfurter Flughafen, investiert gerne Zeit und Energie
                    in die Förderung des Einheitsgedankens in der muslimischen
                    Gemeinschaft. Daher ist commonsplace für sie eine bedeutsame
                    Plattform, die für die Stärkung und den Zusammenhalt der
                    Gemeinschaft steht.
                  </p>
                </div>
              </div>
              <div>
                <a href="mailto:Naima@commonsplace.de" rel="noreferrer">
                  <SvgWrapper>
                    <Mail color={Colors.GreenColor} />
                  </SvgWrapper>
                </a>
                <a
                  target="_blank"
                  href="https://de.linkedin.com/in/nima-hassan-935a17229"
                  rel="noreferrer"
                >
                  <SvgWrapper>
                    <LinkedIn color={Colors.GreenColor} />
                  </SvgWrapper>
                </a>
              </div>
            </div>
            {/* M. Ali Abderrahmane */}
            <div>
              <div>
                <picture>
                  <img src={Martin} alt="Martin Mohamed Marzouk" />
                </picture>
                <div>
                  <div>
                    <H2>Martin M. Marzouk</H2>
                    <p>Outreach und Kooperation</p>
                  </div>
                  <p>
                    Martin ist in der Nähe von Frankfurt geboren und
                    aufgewachsen und ist seit 2017 in der Medizinbranche
                    hauptberuflich tätig. Die muslimische Community strukturell
                    weiterzubringen und zu vernetzen sind Dinge, die ihn bei
                    seinem Engagement für commonsplace antreiben.
                  </p>
                </div>
              </div>
              <div>
                <a href="mailto:martin@commonsplace.de" rel="noreferrer">
                  <SvgWrapper>
                    <Mail color={Colors.GreenColor} />
                  </SvgWrapper>
                </a>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/martin-marzouk/"
                  rel="noreferrer"
                >
                  <SvgWrapper>
                    <LinkedIn color={Colors.GreenColor} />
                  </SvgWrapper>
                </a>
              </div>
            </div>
          </COsWrapper>
        </Container>
      </div>
      <div className="after-background">
        <SvgWrapper width="100%" height="100%">
          <AfterBackground />
        </SvgWrapper>
      </div>
    </WhoAreWeWrapper>
  );
}
