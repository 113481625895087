function IsLoggedIn() {
  const user = JSON.parse(localStorage.getItem('user'));
  const userToken = localStorage.getItem('access-token');
  const client = localStorage.getItem('client');
  const isLoggedIn = Boolean(user && userToken && client);
  if (isLoggedIn) {
    return true;
  } else {
    return false;
  }
}

export default IsLoggedIn;
