/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import PropTypes from 'prop-types';

const AboutUsSecondary = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 500"
      css={css`
        max-width: ${width || '100%'};
        max-height: ${height || '100%'};
        height: ${height || '100%'};
        width: ${width || '100%'};
        display: inline-block;
      `}
    >
      <g id="freepik--background-complete--inject-3">
        <rect
          x="59.58"
          y="69.81"
          width="111.16"
          height="194.48"
          style={{ fill: '#fafafa' }}
        />
        <path
          d="M94.2,266.16S82,178.66,75.65,169.05c0,0,36.41-27.35,37.53-101.45H57.92V266.16Z"
          style={{ fill: '#ebebeb' }}
        />
        <path
          d="M136.09,265.77s12.18-87.5,18.56-97.11c0,0-36.42-27.34-37.53-101.45h55.26V265.77Z"
          style={{ fill: '#ebebeb' }}
        />
        <path
          d="M173.66,62.41H49.27V270.72H178.81V62.41ZM81.92,170.64v49.53H59.58V170.64Zm-22.34-3.59V115.78H81.92v51.27Zm56.35,0V115.78h27.94v51.27Zm27.94,3.59v49.53H115.93V170.64Zm-61-3.59V115.78h29.44v51.27Zm29.44,3.59v49.53H82.91V170.64ZM82.91,114.9V69.81h29.44V114.9Zm29.44,106.16v43.25H82.91V221.06Zm3.58,0h27.94v43.25H115.93Zm0-106.16V69.81h27.94V114.9Zm28.94,55.74h23.64v49.53H144.87Zm0-3.59V115.78h23.64v51.27Zm23.64-52.15H144.87V69.81h23.64ZM81.92,69.81V114.9H59.58V69.81ZM59.58,221.06H81.92v43.25H59.58Zm85.29,43.25V221.06h23.64v43.25Z"
          style={{ fill: '#e0e0e0' }}
        />
        <rect
          x="341.3"
          y="69.81"
          width="111.16"
          height="194.48"
          style={{ fill: '#fafafa' }}
        />
        <path
          d="M375.93,266.16s-12.18-87.5-18.56-97.11c0,0,36.42-27.35,37.53-101.45H339.64V266.16Z"
          style={{ fill: '#ebebeb' }}
        />
        <path
          d="M417.82,265.77s12.17-87.5,18.55-97.11c0,0-36.41-27.34-37.53-101.45H454.1V265.77Z"
          style={{ fill: '#ebebeb' }}
        />
        <path
          d="M455.39,62.41H331V270.72H460.54V62.41ZM363.65,170.64v49.53H341.3V170.64Zm-22.35-3.59V115.78h22.35v51.27Zm56.35,0V115.78h28v51.27Zm28,3.59v49.53h-28V170.64Zm-61-3.59V115.78h29.43v51.27Zm29.43,3.59v49.53H364.64V170.64ZM364.64,114.9V69.81h29.43V114.9Zm29.43,106.16v43.25H364.64V221.06Zm3.58,0h28v43.25h-28Zm0-106.16V69.81h28V114.9Zm28.94,55.74h23.64v49.53H426.59Zm0-3.59V115.78h23.64v51.27Zm23.64-52.15H426.59V69.81h23.64ZM363.65,69.81V114.9H341.3V69.81ZM341.3,221.06h22.35v43.25H341.3Zm85.29,43.25V221.06h23.64v43.25Z"
          style={{ fill: '#e0e0e0' }}
        />
        <rect
          x="208.86"
          y="116.75"
          width="27.03"
          height="34.12"
          style={{ fill: '#ebebeb' }}
        />
        <rect
          x="220.67"
          y="150.1"
          width="3.75"
          height="0.91"
          style={{ fill: '#e0e0e0' }}
        />
        <polygon
          points="223.24 128.49 218.26 129.22 216.41 133.9 219.53 137.84 224.5 137.11 226.36 132.44 223.24 128.49"
          style={{ fill: '#e0e0e0' }}
        />
        <polygon
          points="225.57 131.51 220.59 132.24 218.73 136.92 221.85 140.86 226.83 140.14 228.69 135.46 225.57 131.51"
          style={{ fill: '#fafafa' }}
        />
        <rect
          x="221.2"
          y="116.28"
          width="3.75"
          height="0.78"
          style={{ fill: '#e0e0e0' }}
        />
        <rect
          x="256.35"
          y="71.2"
          width="45.13"
          height="56.97"
          style={{ fill: '#ebebeb' }}
        />
        <rect
          x="276.07"
          y="126.9"
          width="6.26"
          height="1.52"
          style={{ fill: '#e0e0e0' }}
        />
        <polygon
          points="280.36 90.82 272.04 92.03 268.94 99.84 274.15 106.43 282.46 105.22 285.56 97.41 280.36 90.82"
          style={{ fill: '#e0e0e0' }}
        />
        <polygon
          points="284.24 95.86 275.93 97.08 272.82 104.89 278.04 111.48 286.35 110.26 289.45 102.46 284.24 95.86"
          style={{ fill: '#fafafa' }}
        />
        <rect
          x="276.95"
          y="70.43"
          width="6.26"
          height="1.3"
          style={{ fill: '#e0e0e0' }}
        />
        <path
          d="M225.86,308.78c-6.12,1-8.85,8.5-8.32,14.68s3.08,12.21,2.4,18.37c-.87,7.83-6.78,14.44-7.47,22.28-.81,9.25,5.73,17.45,7.68,26.53.95,4.41.82,9.08,2.46,13.29a16.62,16.62,0,0,0,32.1-5.73c.13-7.1-4.25-13.79-3.72-20.87.39-5.24,3.46-10.2,2.81-15.41-.79-6.19-6.53-10.59-8.55-16.5s-.08-12.42-.78-18.63c-1.26-11.14-7.43-18.91-18.61-18"
          style={{ fill: '#f5f5f5' }}
        />
        <path
          d="M194.61,362.71c-1,10.32,3.52,22.46,8.83,31.37a49.22,49.22,0,0,0,23.11,20.05c1.07.45,2.42.83,3.33.1a3.36,3.36,0,0,0,.84-2.65,50.68,50.68,0,0,0-36.11-48.87"
          style={{ fill: '#ebebeb' }}
        />
        <path
          d="M239.57,435.71a2.64,2.64,0,0,1-.3-.57c-.19-.45-.43-1-.73-1.69-.62-1.48-1.55-3.6-2.73-6.2a220.26,220.26,0,0,0-10.25-19.84,219.78,219.78,0,0,0-12.25-18.67c-1.69-2.3-3.09-4.15-4.07-5.42l-1.11-1.47a2.33,2.33,0,0,1-.35-.53s.17.14.44.46l1.2,1.4c1,1.22,2.48,3,4.22,5.32a188.73,188.73,0,0,1,12.43,18.61,190.41,190.41,0,0,1,10.12,20c1.13,2.63,2,4.78,2.56,6.28.26.7.47,1.28.63,1.73A2.78,2.78,0,0,1,239.57,435.71Z"
          style={{ fill: '#e0e0e0' }}
        />
        <path
          d="M232.92,337.33a6.56,6.56,0,0,1,.12,1c.05.7.12,1.65.2,2.84.16,2.53.39,6.08.68,10.46.55,8.83,1.34,21,2.28,34.49s1.88,25.66,2.57,34.48c.33,4.37.61,7.93.8,10.45.08,1.19.14,2.14.19,2.84a6.29,6.29,0,0,1,0,1,6.7,6.7,0,0,1-.15-1c-.07-.69-.17-1.64-.3-2.83-.24-2.52-.57-6.07-1-10.43-.79-8.82-1.79-21-2.74-34.48s-1.67-25.67-2.12-34.51c-.2-4.38-.37-7.94-.49-10.47,0-1.19-.07-2.14-.09-2.84A6.3,6.3,0,0,1,232.92,337.33Z"
          style={{ fill: '#e0e0e0' }}
        />
        <path
          d="M223.43,421h25.85v19a12.41,12.41,0,0,1-12.41,12.41h0a12.41,12.41,0,0,1-12.39-11.75Z"
          style={{ fill: '#e0e0e0' }}
        />
      </g>
      <g id="freepik--Floor--inject-3">
        <path
          d="M473.88,452.73c0,.14-101.19.26-226,.26s-226-.12-226-.26,101.17-.26,226-.26S473.88,452.59,473.88,452.73Z"
          style={{ fill: '#263238' }}
        />
      </g>
      <g id="freepik--character-2--inject-3">
        <path
          d="M332.52,241.42l-4-.83c-3.57-1.07-5.59-1.53-6.42-2.7-1.1-1.57,1-2.47,1-2.47s-2.46-1.43-1.89-3a1.61,1.61,0,0,1,1.65-.94s-2.14-1.37-2.41-2.43c-.37-1.44,1.41-1.82,1.41-1.82-2.78-2.58-2.11-4.46-1.34-4.5s3,1.57,3,1.57c2,1,7.32,3.87,5.83.31s-1.3-6.85-.63-7.71a1.21,1.21,0,0,1,2.07,1,14.32,14.32,0,0,0,.79,3.81,9.23,9.23,0,0,0,1.43,2.77c.74,1,1.4,1.77,2,2.43l2.89,4.14Z"
          style={{ fill: '#ffbe9d' }}
        />
        <path
          d="M317.12,448.73l3.44-7.26,13.64,6.36-4.92,10.67-.87-.34c-3.86-1.55-19.52-8.13-21.68-10.07C304.32,445.93,317.12,448.73,317.12,448.73Z"
          style={{ fill: '#8BBE3B' }}
        />
        <g style={{ opacity: '0.30000000000000004' }}>
          <path
            d="M324.38,456.08a5.23,5.23,0,0,1,3.1-2.09,5,5,0,0,1,3.65.49l-1.78,3.81Z"
            style={{ fill: '#fff' }}
          />
        </g>
        <g style={{ opacity: '0.30000000000000004' }}>
          <path
            d="M310.77,447.56s-4.6-.72-4.41.19,15.77,8.15,22.92,10.75l.12-.32-18.24-8.3S311.55,448,310.77,447.56Z"
            style={{ fill: '#fff' }}
          />
        </g>
        <path
          d="M329.61,458.27l-.24-.08-.69-.27-2.53-1.05c-2.13-.9-5.06-2.18-8.25-3.67s-6.07-2.89-8.13-3.94L307.34,448l-.65-.35-.23-.13a.89.89,0,0,1,.24.1l.67.32,2.46,1.21c2.07,1,4.95,2.4,8.14,3.89s6.11,2.78,8.23,3.71l2.5,1.1.68.3Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M311.07,450.09a6.47,6.47,0,0,0,.08-1.39,6.28,6.28,0,0,0-.4-1.35,2.47,2.47,0,0,1,.32,2.74Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M316,449.93a1.73,1.73,0,0,1-.19-.73,1.55,1.55,0,0,1,0-.74s.13.31.18.72A1.53,1.53,0,0,1,316,449.93Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M317.42,449.86c-.05,0-.2-.23-.34-.55s-.21-.59-.16-.61.2.22.33.54A1.26,1.26,0,0,1,317.42,449.86Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M318.71,448.89c0,.05-.35-.06-.75-.24a1.89,1.89,0,0,1-.68-.4c0-.05.36.06.75.24A1.89,1.89,0,0,1,318.71,448.89Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M319.38,448.1a1.55,1.55,0,0,1-.91-.14,1.52,1.52,0,0,1-.76-.49,4.67,4.67,0,0,1,.83.32A5.26,5.26,0,0,1,319.38,448.1Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M316.13,448.52a2.86,2.86,0,0,1-.87-.41,5.35,5.35,0,0,1-.84-.64A2.83,2.83,0,0,1,314,447a.52.52,0,0,1-.09-.37.36.36,0,0,1,.29-.29,1.8,1.8,0,0,1,1.79,2.11.63.63,0,0,1-.09.25,3,3,0,0,0-.05-.94,1.8,1.8,0,0,0-.52-.83,1.5,1.5,0,0,0-1.08-.42c-.19,0-.21.23-.1.4a2.75,2.75,0,0,0,.4.44,6.9,6.9,0,0,0,.79.65C315.81,448.34,316.14,448.49,316.13,448.52Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M315.88,448.46a1,1,0,0,1,.19-.66,1.67,1.67,0,0,1,.53-.53,1,1,0,0,1,.94-.1.39.39,0,0,1,.08.53,1,1,0,0,1-.33.32,2.77,2.77,0,0,1-.66.34,1.41,1.41,0,0,1-.67.12s.25-.08.62-.24a2.85,2.85,0,0,0,.61-.36c.21-.13.45-.42.25-.56s-.54,0-.75.1a1.68,1.68,0,0,0-.52.45A5.64,5.64,0,0,0,315.88,448.46Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M331.07,454.41s-.37-.22-1-.43a4.86,4.86,0,0,0-2.62-.12,5,5,0,0,0-2.28,1.3c-.48.49-.68.87-.71.86a.78.78,0,0,1,.13-.28,3.63,3.63,0,0,1,.49-.67,4.67,4.67,0,0,1,2.33-1.39,4.61,4.61,0,0,1,2.7.18,3.93,3.93,0,0,1,.74.36C331,454.33,331.08,454.4,331.07,454.41Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M332.3,448.08a24.45,24.45,0,0,1-1.18,2.85,26.92,26.92,0,0,1-1.36,2.79,27.88,27.88,0,0,1,1.19-2.86A27.34,27.34,0,0,1,332.3,448.08Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M323.28,454a9.28,9.28,0,0,1-2.08-.73,9.08,9.08,0,0,1-1.92-1.09c0-.05.89.42,2,.92S323.3,453.91,323.28,454Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M326,455.2s-.16.15-.35.31-.32.32-.36.3,0-.25.24-.44S326,455.15,326,455.2Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M327.55,454.41s-.14.15-.34.23-.39.1-.41.05.14-.15.34-.22S327.53,454.36,327.55,454.41Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M329.26,454.51s-.21-.05-.45-.07-.42,0-.44,0,.18-.18.46-.15S329.29,454.47,329.26,454.51Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M330.41,454.72s-.11.08-.25.07-.24-.06-.23-.11.11-.08.25-.07S330.41,454.67,330.41,454.72Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M432.17,452.7l-3.4-7.28L442.36,439l5.09,10.59-.82.46c-3.65,2-18.7,9.85-21.57,10.28C421.86,460.78,432.17,452.7,432.17,452.7Z"
          style={{ fill: '#8BBE3B' }}
        />
        <g style={{ opacity: '0.30000000000000004' }}>
          <path
            d="M442.47,451.79a5.16,5.16,0,0,1,.36-3.72,5.06,5.06,0,0,1,2.7-2.49l1.81,3.79Z"
            style={{ fill: '#fff' }}
          />
        </g>
        <g style={{ opacity: '0.30000000000000004' }}>
          <path
            d="M427.23,456.85s-3.49,3.09-2.67,3.52,16.33-7,22.89-10.81l-.17-.29-18,8.76S428,456.51,427.23,456.85Z"
            style={{ fill: '#fff' }}
          />
        </g>
        <path
          d="M447.49,449.16l-.22.13-.65.36-2.42,1.28c-2.05,1.07-4.9,2.51-8.09,4s-6.09,2.82-8.21,3.74l-2.52,1.08-.69.28-.24.08.23-.12.67-.31,2.5-1.12c2.1-.95,5-2.28,8.19-3.8s6-2.93,8.1-4l2.44-1.23.67-.33A1.07,1.07,0,0,1,447.49,449.16Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M429.37,458.24a6.42,6.42,0,0,0-1-1,6.48,6.48,0,0,0-1.29-.55,2.48,2.48,0,0,1,2.32,1.51Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M432.41,454.3s-.33-.1-.68-.32-.61-.43-.58-.47.33.1.68.32S432.43,454.26,432.41,454.3Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M433.23,453.19c0,.05-.3,0-.63-.09s-.59-.22-.58-.27a1.32,1.32,0,0,1,.63.1C433,453,433.24,453.14,433.23,453.19Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M433.32,451.58c0,.05-.28.24-.67.43s-.72.31-.75.26a1.89,1.89,0,0,1,.67-.43C433,451.65,433.29,451.53,433.32,451.58Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M433.12,450.56s-.23.36-.68.61a1.64,1.64,0,0,1-.87.28,58.51,58.51,0,0,0,1.55-.89Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M431.38,453.33a2.61,2.61,0,0,1-.87.41,5.22,5.22,0,0,1-1,.24,2.65,2.65,0,0,1-.64,0,.48.48,0,0,1-.34-.17.38.38,0,0,1,0-.41,1.78,1.78,0,0,1,2.21-.47,1.84,1.84,0,0,1,.56.45.59.59,0,0,1,.13.22,2.73,2.73,0,0,0-.75-.55,1.68,1.68,0,0,0-1-.13,1.48,1.48,0,0,0-1,.56c-.09.17,0,.31.24.33a2.92,2.92,0,0,0,.59,0,6.08,6.08,0,0,0,1-.19C431,453.46,431.37,453.31,431.38,453.33Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M431.18,453.48s-.25-.14-.39-.56a1.62,1.62,0,0,1-.07-.75,1,1,0,0,1,.52-.79.39.39,0,0,1,.46.28,1.06,1.06,0,0,1,0,.46,2.72,2.72,0,0,1-.17.72c-.14.4-.31.61-.33.59s.1-.24.21-.63a3.18,3.18,0,0,0,.12-.69c0-.25-.05-.62-.28-.55s-.35.41-.4.64a1.57,1.57,0,0,0,0,.69A2.8,2.8,0,0,0,431.18,453.48Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M445.44,445.57s-.4.15-1,.52A4.69,4.69,0,0,0,442.7,448a4.85,4.85,0,0,0-.45,2.58c.07.69.23,1.09.2,1.1a1,1,0,0,1-.13-.28,3.67,3.67,0,0,1-.2-.8,4.68,4.68,0,0,1,.41-2.68,4.58,4.58,0,0,1,1.86-2,3.75,3.75,0,0,1,.76-.34A.89.89,0,0,1,445.44,445.57Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M441.35,440.59a27.69,27.69,0,0,1,1.44,2.73,26.42,26.42,0,0,1,1.28,2.82,27.22,27.22,0,0,1-1.44-2.74A23.68,23.68,0,0,1,441.35,440.59Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M440.13,451.28a8.53,8.53,0,0,1-1.89,1.14,8.38,8.38,0,0,1-2.06.79s.89-.42,2-.95S440.1,451.24,440.13,451.28Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M442.79,450s0,.23,0,.47.05.45,0,.47-.18-.18-.18-.46S442.74,450,442.79,450Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M443.2,448.29c.05,0,0,.2-.05.41s-.17.36-.21.35,0-.2,0-.41S443.15,448.27,443.2,448.29Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M444.36,447c0,.05-.17.14-.33.3s-.26.34-.31.33,0-.26.18-.46S444.36,447,444.36,447Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M445.26,446.28s0,.14-.1.24-.2.14-.24.11,0-.14.1-.24S445.22,446.24,445.26,446.28Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M448.06,442.58l-22,10.23L386.5,382.38c-5.15-18.58-10.12-84-10.12-84L405,300.56s4.41,47,11.32,71.16Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M422.54,445.92s.28-.21.83-.5,1.36-.7,2.36-1.19c2-1,4.79-2.31,7.88-3.75s5.89-2.71,7.93-3.6c1-.45,1.86-.81,2.43-1a3.6,3.6,0,0,1,.92-.32,3.39,3.39,0,0,1-.83.5c-.56.29-1.36.7-2.36,1.19-2,1-4.79,2.31-7.87,3.75s-5.89,2.7-7.94,3.6c-1,.45-1.85.81-2.43,1A3.47,3.47,0,0,1,422.54,445.92Z"
          style={{ fill: '#455a64' }}
        />
        <path
          d="M418,272.05c.54,0,6.39,17.33.76,28.82-.57,1.15-5,7.48-5,7.48-15.58,18.38-31.36,54-35.76,63.39-9,19.21-42.36,82.4-42.36,82.4l-21.4-12.25,31.29-80.45,28.4-72.6Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M379.42,346.38s-1.51,7.31-2.29,6.89c-2.23-1.21-17.56-6.92-17.56-6.92l4.14-4.59Z"
          style={{ fill: '#455a64' }}
        />
        <path
          d="M374.9,352.06s-2.87,11.79-6.56,11.15-11.4-4.52-11.4-4.52l4.65-11.63Z"
          style={{ fill: '#455a64' }}
        />
        <polygon
          points="377.13 353.27 360.02 346.43 360.84 348.94 374.39 353.9 377.13 353.27"
          style={{ fill: '#263238' }}
        />
        <path
          d="M406.83,317.6a1.87,1.87,0,0,1-.28.44l-.88,1.19c-.76,1-1.84,2.56-3.09,4.49-2.51,3.86-5.67,9.38-8.9,15.62s-6,12-7.95,16.14l-2.35,4.93-.65,1.33a2.22,2.22,0,0,1-.26.45,2,2,0,0,1,.16-.49l.57-1.37c.5-1.19,1.25-2.9,2.19-5,1.9-4.2,4.58-10,7.83-16.23a160.68,160.68,0,0,1,9-15.58c1.3-1.91,2.42-3.41,3.24-4.41.38-.46.7-.83,1-1.13A2.1,2.1,0,0,1,406.83,317.6Z"
          style={{ fill: '#455a64' }}
        />
        <path
          d="M315.87,438.14c.06-.12,5.11,2.28,11.28,5.37s11.11,5.71,11,5.84-5.11-2.28-11.28-5.37S315.81,438.27,315.87,438.14Z"
          style={{ fill: '#455a64' }}
        />
        <path
          d="M341.3,230.63a72.17,72.17,0,0,1-3.94,7.63,68.73,68.73,0,0,1-4.4,7.38A73.3,73.3,0,0,1,336.9,238,72.07,72.07,0,0,1,341.3,230.63Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M381.73,200.2c1.17,0,2.56,8.5,3.74,23.77,1.34,17.32,1.22,34.8-3.57,45.79a9.54,9.54,0,0,1-5,5.33c-12.4,5.12-46.61-31.56-46.61-31.56l8.42-14.77,24.09,19.45s2.4-19.68,1.94-28.75C364.37,212.58,371.24,200.28,381.73,200.2Z"
          style={{ fill: '#455a64' }}
        />
        <path
          d="M392.39,200.37s20.92,3.06,26.33,11.28,2.67,80.72,2.67,80.72l-53.78,3.18s4.34-92.28,7-93.33C381.29,199.6,391.59,200.11,392.39,200.37Z"
          style={{ fill: '#455a64' }}
        />
        <path
          d="M306.29,181.28a12.91,12.91,0,0,1,1.19-6.4,6.37,6.37,0,0,0,.64-2.74v-2.43H276.77a4.81,4.81,0,0,0-4.81,4.81v31.22h-2.12a6.44,6.44,0,0,1-2.82-.68,13,13,0,1,0-.4,23.61,6.46,6.46,0,0,1,2.63-.58H272v30.44a4.81,4.81,0,0,0,4.81,4.82h84.69a3.92,3.92,0,0,0,3.92-3.92v-85.8a3.91,3.91,0,0,0-3.92-3.92h-30.6v3.47a6.06,6.06,0,0,0,.46,2.34,13,13,0,1,1-25,5.76Z"
          style={{ fill: '#8BBE3B' }}
        />
        <path
          d="M371.26,215.79a4.13,4.13,0,0,1,.05.6c0,.46,0,1,0,1.73,0,1.57,0,3.73.08,6.36,0,5.37.09,12.79-.08,21s-.62,15.62-1.17,21c-.28,2.68-.55,4.83-.77,6.32-.11.7-.2,1.26-.28,1.71a2.09,2.09,0,0,1-.13.59,4.48,4.48,0,0,1,0-.6c.05-.45.1-1,.18-1.72.15-1.5.37-3.66.6-6.33.46-5.35.87-12.75,1-20.94s.16-15.6.23-21c0-2.63.07-4.79.09-6.36,0-.7,0-1.28.05-1.73A2.41,2.41,0,0,1,371.26,215.79Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M403.25,166.19c-.86,5.46-.81,10.25,1.86,13.81a20.77,20.77,0,0,1,1.6,2.16,3.29,3.29,0,0,1,.41,2.58,2.28,2.28,0,0,1-3.9.79,3.14,3.14,0,0,1-1.53,3.15,2,2,0,0,1-2.8-1.44s.71,2.77-2.47,2.34l2.24-19Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M389,208.19h0a8.85,8.85,0,0,1-8.8-9.78c.29-2.84.55-5.15.55-5.15s-7-1.09-7.09-8.09,1.13-23.07,1.13-23.07h0a24.33,24.33,0,0,1,24.44,3.08l1,.79-2.43,34A8.85,8.85,0,0,1,389,208.19Z"
          style={{ fill: '#ffbe9d' }}
        />
        <path
          d="M376.59,174.74a1,1,0,1,0-1.43-.07A1,1,0,0,0,376.59,174.74Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M374.65,172.53c.11.12.8-.41,1.76-.41s1.67.49,1.77.37-.05-.27-.36-.51a2.32,2.32,0,0,0-1.41-.44A2.39,2.39,0,0,0,375,172C374.7,172.26,374.6,172.48,374.65,172.53Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M385.76,174a1,1,0,0,0-.34,1.39,1,1,0,0,0,1.4.34,1,1,0,0,0,.33-1.4A1,1,0,0,0,385.76,174Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M385.12,173.38c.11.12.8-.41,1.76-.41s1.67.49,1.77.37-.06-.27-.36-.51a2.33,2.33,0,0,0-1.42-.44,2.29,2.29,0,0,0-1.4.48C385.17,173.11,385.07,173.33,385.12,173.38Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M381.26,181.33a5.13,5.13,0,0,0-1.56-.33c-.25,0-.45-.08-.47-.22a1.31,1.31,0,0,1,.18-.72l.76-2a26.22,26.22,0,0,0,1.65-5.15,27.13,27.13,0,0,0-2.16,5c-.25.68-.49,1.33-.72,2a1.56,1.56,0,0,0-.14,1.05.65.65,0,0,0,.43.4,1.91,1.91,0,0,0,.43.06A4.69,4.69,0,0,0,381.26,181.33Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M384.39,182.4c1-.6-2.77,1.47-4.73,1.37a2.66,2.66,0,0,0,3.14,1.06C384.9,184.06,384.39,182.4,384.39,182.4Z"
          style={{ fill: '#fff' }}
        />
        <path
          d="M380.69,193.16a16.24,16.24,0,0,0,9.12-2.6s-2.16,5-9.14,4.41Z"
          style={{ fill: '#eb996e' }}
        />
        <path
          d="M400.23,172.75c.15-.05,5.22-.4,4.38,5.5s-6.52,3.1-6.51,2.93S400.23,172.75,400.23,172.75Z"
          style={{ fill: '#ffbe9d' }}
        />
        <path
          d="M400.2,179.37s.09.09.26.19a1,1,0,0,0,.76.13,2.57,2.57,0,0,0,1.5-2.12,3.3,3.3,0,0,0-.07-1.51,1.21,1.21,0,0,0-.66-.9.52.52,0,0,0-.65.2c-.1.15-.08.27-.12.28s-.11-.12,0-.35a.62.62,0,0,1,.29-.34.74.74,0,0,1,.59-.07A1.45,1.45,0,0,1,403,176a3.39,3.39,0,0,1,.12,1.69,2.69,2.69,0,0,1-1.86,2.34,1.13,1.13,0,0,1-.92-.29C400.19,179.51,400.18,179.38,400.2,179.37Z"
          style={{ fill: '#eb996e' }}
        />
        <path
          d="M372.91,161.43a2.3,2.3,0,0,1,2.62-1.88,2.63,2.63,0,1,1,1.74-4.77,7.83,7.83,0,0,1-1.84-2.63,2.64,2.64,0,0,1,.68-3,3,3,0,0,1,3,0c1.44.73,2.53,2.05,4,2.67s3.2.47,4.83.64a8.61,8.61,0,0,1,4.91,2.05,5.79,5.79,0,0,1,2,4.82c2.21-.13,4.63,0,6.34,1.37a5.08,5.08,0,0,1,1.92-4.75,6.48,6.48,0,0,1,6.29-.62,3.65,3.65,0,0,1,2.17,2c.45,1.35-.4,2.8-1.47,3.74a8.39,8.39,0,0,1-7.35,1.77,7.63,7.63,0,0,1,.5,3.28c-.11,1.93-1.66,3.93-2.34,5.74h0l-.15,1.19-1.16,4.95-3.1-.25.85-10.4s.58-4.37-3.22-4.22-9.19,4.1-16.47,2.57a8.1,8.1,0,0,1-3.36-1.19A3.4,3.4,0,0,1,372.91,161.43Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M413.68,166a4.44,4.44,0,0,1-2,.86,7.92,7.92,0,0,1-8.94-4.39,4.43,4.43,0,0,1-.55-2.12c.09,0,.25.81.88,2a8.16,8.16,0,0,0,8.54,4.19C412.89,166.28,413.64,165.9,413.68,166Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M406.25,171.76c-.08,0,0-.62-.18-1.54a10.7,10.7,0,0,0-1.45-3.38,28.48,28.48,0,0,1-1.9-3.3,3.22,3.22,0,0,1-.4-1.54,9.8,9.8,0,0,1,.73,1.38c.45.84,1.22,1.92,2,3.19a8.88,8.88,0,0,1,1.38,3.59A2.52,2.52,0,0,1,406.25,171.76Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M354.44,229.6a111.22,111.22,0,0,1-10.07-6.91c-3.87-3.27-3.59-3.63-3.14-4,1.2-1.07,7,5.5,6.57,3.34s-8-7.18-6.72-8.24c1.7-1.39,7.66,5.6,8.21,6.15s1.35-.24.83-.71c-.75-.68-8.43-8-6.78-9.38.92-.77,2.1,1.05,2.1,1.05s6.7,7.41,7.46,6.73-3-5.17-4.4-6.59-1.27-2.63-.16-2.74c.75-.07,5.08,4.17,6.42,5.6s4.92,5.44,4.72,2,.9-6.18,1.7-6.71,1.81.43,1.43,1.43a12.71,12.71,0,0,0-.44,3.46,8.4,8.4,0,0,0,.38,2.77c.24.77.48,1.45.71,2.05l2.35,6.47-5.9,7.47Z"
          style={{ fill: '#ffbe9d' }}
        />
        <path
          d="M412.65,207.36c1.24.31,8.62,4.13,8.74,20.86.09,13.3-.93,42.82-10,47.21-4.11,2-10.35,2-15.77-1.55-15.28-9.87-42.34-41.69-42.34-41.69l12.11-12.9s31.44,32,32.78,30.82c2-1.78-1.07-17.06.65-32.16C400,207.21,405,205.45,412.65,207.36Z"
          style={{ fill: '#455a64' }}
        />
        <path
          d="M365.37,219.75a1.18,1.18,0,0,1-.21.22l-.65.64c-.57.55-1.41,1.37-2.47,2.47a85,85,0,0,0-8.3,10v-.23c8.54,8.71,21,21.38,34.83,35.47a27.32,27.32,0,0,0,11.6,6.55,14,14,0,0,0,6.29.28,12.17,12.17,0,0,0,5.37-2.71,20.92,20.92,0,0,0,5.93-9.49,50.86,50.86,0,0,0,1.92-9.74c.81-6,1.24-11,1.43-14.37.1-1.7.15-3,.19-3.92l0-1a1.44,1.44,0,0,1,0-.34,1.35,1.35,0,0,1,0,.35c0,.25,0,.59,0,1,0,.9,0,2.22-.09,3.93-.13,3.41-.5,8.35-1.27,14.41a50.88,50.88,0,0,1-1.87,9.83,21.37,21.37,0,0,1-6,9.72,12.6,12.6,0,0,1-5.58,2.83,14.51,14.51,0,0,1-6.51-.28,27.72,27.72,0,0,1-11.84-6.66c-13.88-14.11-26.24-26.87-34.72-35.6l-.11-.11.09-.12a81.21,81.21,0,0,1,8.44-9.93c1.09-1.07,2-1.87,2.55-2.41l.68-.59Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M365.38,219.29s.07,0,.17.14l.47.47,1.79,1.81c1.55,1.58,3.82,3.84,6.67,6.61s6.29,6.07,10.17,9.69l6.12,5.67c2.15,1.94,4.37,4,6.75,5.94l-.41.2c.07-1.18.14-2.43.21-3.69.4-6.61.77-12.91,1.11-18.64.21-2.86.26-5.59.57-8.14a20,20,0,0,1,1.94-6.86,11.45,11.45,0,0,1,3.84-4.44,8.91,8.91,0,0,1,4.2-1.43,6.89,6.89,0,0,1,2.78.34,3.58,3.58,0,0,1,.89.4s-.31-.13-.92-.3a7.28,7.28,0,0,0-2.74-.24,8.88,8.88,0,0,0-4.05,1.47,11.53,11.53,0,0,0-3.68,4.37,20.11,20.11,0,0,0-1.84,6.74c-.28,2.52-.31,5.25-.51,8.11l-1.07,18.64c-.07,1.26-.15,2.52-.21,3.69l0,.51-.39-.31c-2.44-2-4.62-4-6.77-6s-4.17-3.88-6.11-5.7c-3.86-3.65-7.28-7-10.1-9.77s-5.05-5.12-6.56-6.74c-.77-.8-1.33-1.44-1.72-1.88l-.43-.49C365.42,219.35,365.37,219.29,365.38,219.29Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M358.62,237.53a12.16,12.16,0,0,1,1.53-2.14c1-1.27,2.42-3,4.05-4.87s3.14-3.52,4.26-4.69a12.76,12.76,0,0,1,1.91-1.81,13.69,13.69,0,0,1-1.64,2.06l-4.14,4.78-4.16,4.77A13.87,13.87,0,0,1,358.62,237.53Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M400.25,257.33a23.16,23.16,0,0,1-1.52-3.81,23.65,23.65,0,0,1-1.36-3.87,19.9,19.9,0,0,1,2.88,7.68Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M403,255.2a16.54,16.54,0,0,1-3.22-2.59,17.18,17.18,0,0,1-2.87-3A32.52,32.52,0,0,1,403,255.2Z"
          style={{ fill: '#263238' }}
        />
      </g>
      <g id="freepik--character-1--inject-3">
        <polygon
          points="103.48 404.17 71.36 433.16 62.56 421.9 90.71 387.03 103.48 404.17"
          style={{ fill: '#ffbe9d' }}
        />
        <path
          d="M72.1,434.25l1.1-1L63.06,420.8l-4.57,4,.61.78c2.73,3.47,14.14,17.45,16.76,19.07C78.78,446.45,72.1,434.25,72.1,434.25Z"
          style={{ fill: '#8BBE3B' }}
        />
        <g style={{ opacity: '0.5' }}>
          <path
            d="M62.42,429.06a5.5,5.5,0,0,0,1.2-3.78,5.32,5.32,0,0,0-1.6-3.57l-3.35,3Z"
            style={{ fill: '#fff' }}
          />
        </g>
        <path
          d="M75.19,440.37s2.12,4.48,1.13,4.55-13-13.62-17.83-20.12l.29-.22,13.93,16.09S74.54,439.7,75.19,440.37Z"
          style={{ fill: '#fff', opacity: '0.5' }}
        />
        <path
          d="M58.62,424.39a1.25,1.25,0,0,0,.16.22l.48.63,1.83,2.26c1.55,1.9,3.74,4.49,6.21,7.3s4.77,5.31,6.46,7.1l2,2.1.55.56a1.32,1.32,0,0,0,.21.19l-.18-.22-.53-.58c-.53-.59-1.19-1.3-2-2.14-1.66-1.81-3.94-4.32-6.41-7.13s-4.67-5.39-6.26-7.27l-1.87-2.22-.51-.6A1.14,1.14,0,0,0,58.62,424.39Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M72.52,440.83a6.14,6.14,0,0,1,1.4-.51,6.56,6.56,0,0,1,1.49,0,2.12,2.12,0,0,0-1.52-.2A2.16,2.16,0,0,0,72.52,440.83Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M71.2,435.71a1.71,1.71,0,0,0,.79,0c.43-.06.77-.16.76-.22a3.25,3.25,0,0,0-1.55.25Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M70.86,434.29s.28.13.65.17.67,0,.68,0-.29-.13-.66-.17S70.86,434.23,70.86,434.29Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M71.45,432.68s.17.34.47.69.58.61.62.57-.17-.34-.48-.69S71.49,432.64,71.45,432.68Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M72.06,431.76a1.57,1.57,0,0,0,.41.88c.33.43.7.68.73.64s-.27-.34-.57-.76A6,6,0,0,0,72.06,431.76Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M72.6,435.2a2.93,2.93,0,0,0,.69.76,5.39,5.39,0,0,0,.9.66,2.31,2.31,0,0,0,.61.3.56.56,0,0,0,.4,0,.4.4,0,0,0,.21-.39,1.91,1.91,0,0,0-2.69-1.18.71.71,0,0,0-.23.17,2.69,2.69,0,0,1,1-.23,1.85,1.85,0,0,1,1,.28,1.57,1.57,0,0,1,.74,1c0,.2-.16.28-.36.22a3.72,3.72,0,0,1-.57-.27,7.53,7.53,0,0,1-.91-.61C72.88,435.47,72.63,435.17,72.6,435.2Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M72.74,435.43a1,1,0,0,0,.61-.38,1.66,1.66,0,0,0,.38-.71,1,1,0,0,0-.17-1,.42.42,0,0,0-.57.08,1.2,1.2,0,0,0-.23.43,3.07,3.07,0,0,0-.14.78,1.41,1.41,0,0,0,.07.71,4.87,4.87,0,0,0,.06-.7,4.28,4.28,0,0,1,.18-.73c.07-.25.3-.58.51-.42a1,1,0,0,1,.12.8,1.69,1.69,0,0,1-.31.66A4.19,4.19,0,0,1,72.74,435.43Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M62.11,421.74s.34.31.75.91a5.14,5.14,0,0,1,.91,2.64,5.24,5.24,0,0,1-.64,2.71c-.36.63-.69,1-.66,1s.1-.06.24-.21a3.92,3.92,0,0,0,.53-.7,4.87,4.87,0,0,0,.72-2.79,4.94,4.94,0,0,0-1-2.71,3.85,3.85,0,0,0-.6-.64C62.22,421.79,62.12,421.73,62.11,421.74Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M64.9,429.55a9.1,9.1,0,0,0,1.39,1.89A9,9,0,0,0,68,433.06s-.7-.78-1.55-1.75S65,429.52,64.9,429.55Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M62.85,427.19c-.06,0-.11.21-.22.44s-.23.43-.19.47.24-.11.37-.39S62.9,427.19,62.85,427.19Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M63.17,425.33c-.05,0-.11.18-.13.42s0,.43.07.43.11-.18.13-.42S63.22,425.33,63.17,425.33Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M62.56,423.62s.11.2.2.43.11.44.17.45.13-.24,0-.52S62.58,423.57,62.56,423.62Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M62,422.51s0,.14,0,.27.13.23.18.21,0-.14,0-.28S62.05,422.49,62,422.51Z"
          style={{ fill: '#263238' }}
        />
        <polygon
          points="160.61 403.87 164.48 447.66 147.93 447.66 140.63 403.87 160.61 403.87"
          style={{ fill: '#ffbe9d' }}
        />
        <path
          d="M164,448.07l0-1.47-16.07-.07.19,6.07,1,0c4.42.08,22.46.12,25.37-.88C177.63,450.64,164,448.07,164,448.07Z"
          style={{ fill: '#8BBE3B' }}
        />
        <g style={{ opacity: '0.5' }}>
          <path
            d="M153.8,452.26a5.52,5.52,0,0,0-2.16-3.33,5.4,5.4,0,0,0-3.77-1l.16,4.47Z"
            style={{ fill: '#fff' }}
          />
        </g>
        <path
          d="M170.65,449.56s4.8,1.2,4.24,2-18.8,1.45-26.87,1v-.36l21.28-.57S169.72,449.64,170.65,449.56Z"
          style={{ fill: '#fff', opacity: '0.5' }}
        />
        <path
          d="M147.78,452.24l.28,0,.79,0,2.9,0c2.46,0,5.85,0,9.59-.17s7.13-.32,9.58-.49l2.9-.22.79-.08.27,0h-.27l-.79,0-2.91.16c-2.45.14-5.83.31-9.57.44s-7.13.19-9.59.22l-2.9,0-.79,0Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M169.31,451.92a6.65,6.65,0,0,1,.49-1.41,6.57,6.57,0,0,1,1-1.14,2.07,2.07,0,0,0-1.12,1.05A2.16,2.16,0,0,0,169.31,451.92Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M164.51,449.69c.05,0,.27-.25.49-.63s.36-.7.31-.73a1.89,1.89,0,0,0-.48.64A1.84,1.84,0,0,0,164.51,449.69Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M163.2,449.06s.28-.14.55-.4.45-.5.41-.53-.28.13-.55.39S163.16,449,163.2,449.06Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M162.33,447.58a1.81,1.81,0,0,0,.83.07,2,2,0,0,0,.84-.11,1.82,1.82,0,0,0-.84-.08C162.7,447.47,162.33,447.52,162.33,447.58Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M162,446.52a1.6,1.6,0,0,0,.94.24,1.61,1.61,0,0,0,1-.16,4.45,4.45,0,0,0-.94,0A5.87,5.87,0,0,0,162,446.52Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M165,448.28a2.88,2.88,0,0,0,1,0,6.26,6.26,0,0,0,1.09-.28,3,3,0,0,0,.61-.28.58.58,0,0,0,.25-.33.41.41,0,0,0-.17-.4,1.87,1.87,0,0,0-2.31.63,1.76,1.76,0,0,0-.31.7.52.52,0,0,0,0,.27,3.31,3.31,0,0,1,.44-.89,1.89,1.89,0,0,1,.85-.6,1.59,1.59,0,0,1,1.23,0c.17.12.11.31-.07.43a3.57,3.57,0,0,1-.56.26,6.54,6.54,0,0,1-1.05.31C165.4,448.23,165,448.25,165,448.28Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M165.28,448.32a1.06,1.06,0,0,0,.09-.71,1.61,1.61,0,0,0-.31-.74,1,1,0,0,0-.87-.5.42.42,0,0,0-.3.49,1,1,0,0,0,.19.45,2.86,2.86,0,0,0,.51.6,1.42,1.42,0,0,0,.6.4s-.21-.18-.51-.49a4.67,4.67,0,0,1-.45-.6c-.15-.22-.26-.6,0-.66s.53.22.69.41a1.64,1.64,0,0,1,.31.66A4.28,4.28,0,0,1,165.28,448.32Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M148,447.86s.46-.06,1.18,0a5.06,5.06,0,0,1,2.61,1,5.14,5.14,0,0,1,1.69,2.21c.27.68.31,1.14.35,1.13a1.11,1.11,0,0,0,0-.32,4.67,4.67,0,0,0-.21-.85,4.86,4.86,0,0,0-1.7-2.33,5,5,0,0,0-2.72-.95,5.2,5.2,0,0,0-.88,0A1,1,0,0,0,148,447.86Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M155.76,450.65a8.81,8.81,0,0,0,2.34.13,9.28,9.28,0,0,0,2.33-.28c0-.06-1.05.05-2.34.09S155.76,450.6,155.76,450.65Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M152.63,450.74s.09.22.21.45.18.45.23.45.08-.26-.06-.53S152.66,450.7,152.63,450.74Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M151.4,449.32s.07.2.24.36.34.26.38.22-.07-.2-.25-.36S151.43,449.28,151.4,449.32Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M149.69,448.7c0,.06.22.05.46.12s.41.2.45.16-.1-.26-.4-.34S149.67,448.66,149.69,448.7Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M148.47,448.43c0,.05.08.13.21.18s.26,0,.28,0-.08-.13-.21-.18S148.49,448.38,148.47,448.43Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M131.52,275.35s12.95,86,11.07,90.19-43.83,47.1-43.83,47.1L83,385.68s27.76-30.75,27.36-32.34-20-84.91-20-84.91Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M136.13,307.11c-.13-.92-10.58-24.41-10.58-24.41l5.07-7.16.9-.19Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M83,267.25l-.79,3.19a37.61,37.61,0,0,0,4,27.73L121.29,359a48.55,48.55,0,0,1,5.38,13.84l8.75,39.36h28.8s-3.56-27-6.73-46.69c-2.4-14.87-22.3-61-31.94-82.84l6-7.35-10.37-1.73Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M126.31,282.53a30.27,30.27,0,0,0,1.47,3.85c1,2.35,2.28,5.6,3.68,9.21s2.63,6.91,3.51,9.29a31.53,31.53,0,0,0,1.52,3.83,4.37,4.37,0,0,0-.25-1.09c-.19-.68-.49-1.67-.88-2.88-.79-2.42-2-5.73-3.36-9.36s-2.79-6.85-3.85-9.16c-.53-1.16-1-2.09-1.29-2.72A4.2,4.2,0,0,0,126.31,282.53Z"
          style={{ fill: '#455a64' }}
        />
        <path
          d="M129.46,384.79a3.47,3.47,0,0,0,0-.68c-.06-.51-.13-1.15-.22-1.95a29.58,29.58,0,0,0-.46-3.07c-.23-1.18-.45-2.55-.84-4-.18-.74-.37-1.52-.57-2.32s-.48-1.63-.74-2.49c-.48-1.73-1.16-3.52-1.82-5.42a122.84,122.84,0,0,0-5.35-12,209.05,209.05,0,0,0-12.26-20.37c-1.69-2.52-3.09-4.55-4.07-5.94L102,325a3.76,3.76,0,0,0-.43-.53,2.94,2.94,0,0,0,.33.6l1.05,1.65c1,1.49,2.3,3.53,3.92,6,3.29,5.1,7.83,12.18,12.09,20.4A129.85,129.85,0,0,1,124.27,365c.67,1.88,1.37,3.66,1.87,5.37.26.85.53,1.67.76,2.47s.41,1.56.6,2.3c.42,1.46.66,2.81.92,4s.44,2.2.56,3,.24,1.43.33,1.93A3.22,3.22,0,0,0,129.46,384.79Z"
          style={{ fill: '#455a64' }}
        />
        <path
          d="M87.73,295.3s-.08-.09-.22-.3l-.61-1v0l0,0a6.62,6.62,0,0,1,4.3-2.94,10.52,10.52,0,0,1,4-.12,26.57,26.57,0,0,1,4.58,1.23,33.33,33.33,0,0,1,5,2,8.52,8.52,0,0,1,4.07,4.36,5.42,5.42,0,0,1,.19,3.24,16.24,16.24,0,0,1-1.29,3,6.28,6.28,0,0,0-.85,3.06,4.29,4.29,0,0,0,1.39,2.83c1.92,1.91,4.76,2.26,7.11,3.45a12.21,12.21,0,0,1,5.34,5.3,12.48,12.48,0,0,1,1.5,6.64,11.43,11.43,0,0,1-1.88,5.64,12.82,12.82,0,0,1-6.73,5.18,11.4,11.4,0,0,1-2.29.47,5.48,5.48,0,0,1-.81,0,18.34,18.34,0,0,0,3-.7,12.82,12.82,0,0,0,6.48-5.18,11.11,11.11,0,0,0,1.76-5.46,12.28,12.28,0,0,0-1.49-6.4,11.85,11.85,0,0,0-5.15-5.07c-1.13-.56-2.4-.94-3.67-1.42a9.77,9.77,0,0,1-3.58-2.11,4.8,4.8,0,0,1-1.54-3.18,6.86,6.86,0,0,1,.91-3.31,16.06,16.06,0,0,0,1.27-2.92,4.87,4.87,0,0,0-.18-2.95,8,8,0,0,0-3.82-4.13,33.22,33.22,0,0,0-4.92-2.05,24.92,24.92,0,0,0-4.49-1.26,10,10,0,0,0-3.87.05A6.6,6.6,0,0,0,87,294V294l.55,1A1.56,1.56,0,0,1,87.73,295.3Z"
          style={{ fill: '#fafafa' }}
        />
        <path
          d="M160.44,384.9a9,9,0,0,0-2.37-1.5,14.15,14.15,0,0,0-3.2-.84c-1.27-.21-2.75-.34-4.37-.6a14.74,14.74,0,0,1-5.11-1.73,14.31,14.31,0,0,1-6.31-17.78c.95-2.35,2.38-4.39,2.78-6.66a13,13,0,0,0-.94-6.53,52.63,52.63,0,0,1-1.94-5.85,11.39,11.39,0,0,1,0-5.39,12.12,12.12,0,0,1,4.12-6.52,8.43,8.43,0,0,1,2.46-1.45,18.52,18.52,0,0,0-2.33,1.6,12.23,12.23,0,0,0-3.89,6.45,11.11,11.11,0,0,0,0,5.22,53.68,53.68,0,0,0,2,5.78,13.39,13.39,0,0,1,1,6.77c-.42,2.43-1.89,4.5-2.79,6.78a13.89,13.89,0,0,0,6,17.2,14.57,14.57,0,0,0,5,1.75c1.6.28,3.08.43,4.36.67a13.57,13.57,0,0,1,3.23,1,7.31,7.31,0,0,1,1.79,1.14C160.28,384.7,160.46,384.88,160.44,384.9Z"
          style={{ fill: '#fafafa' }}
        />
        <path
          d="M147,413.59c-.1,0-.13-1.3-.65-3.31a18.74,18.74,0,0,0-1.25-3.41,21.43,21.43,0,0,0-2.37-3.76,21.16,21.16,0,0,0-3.16-3.13,19,19,0,0,0-3.06-2c-1.85-.94-3.12-1.25-3.09-1.35a2.63,2.63,0,0,1,.9.17,6.69,6.69,0,0,1,1,.31,10.86,10.86,0,0,1,1.32.54,17.34,17.34,0,0,1,3.2,1.91,18.49,18.49,0,0,1,5.66,7.06,17.37,17.37,0,0,1,1.18,3.53,10.68,10.68,0,0,1,.24,1.41,6.27,6.27,0,0,1,.08,1.07A2.77,2.77,0,0,1,147,413.59Z"
          style={{ fill: '#fafafa' }}
        />
        <path
          d="M132.22,300.47s-.13,0-.39.08a10.66,10.66,0,0,1-1.15.1,15.15,15.15,0,0,1-4.2-.45,17.1,17.1,0,0,1-5.72-2.56,16,16,0,0,1-7.11-13.1,17.27,17.27,0,0,1,1-6.19,15.4,15.4,0,0,1,1.92-3.77,8.77,8.77,0,0,1,.7-.9c.17-.2.27-.3.28-.29a22.12,22.12,0,0,0-2.55,5.08,17.51,17.51,0,0,0-.84,6.06A15.88,15.88,0,0,0,121,297.25a17.55,17.55,0,0,0,5.53,2.6A21.79,21.79,0,0,0,132.22,300.47Z"
          style={{ fill: '#fafafa' }}
        />
        <path
          d="M106.58,356.58a3.54,3.54,0,0,1,.56.25c.37.17.89.46,1.55.85a18.67,18.67,0,0,1,4.89,4.29,12.9,12.9,0,0,1,2.14,4,11.08,11.08,0,0,1,.34,5.19,9,9,0,0,1-2.64,5.1c-1.48,1.44-3.65,1.92-4.91,3.5s-1.54,3.65-1.73,5.58a20,20,0,0,1-1,5.64,7.59,7.59,0,0,1-3.49,3.83,10.94,10.94,0,0,1-4.34,1.3,21.3,21.3,0,0,1-6.48-.64c-.75-.18-1.32-.33-1.71-.44a2.4,2.4,0,0,1-.58-.19,3.12,3.12,0,0,1,.61.09l1.72.34a22.34,22.34,0,0,0,6.4.47,10.62,10.62,0,0,0,4.16-1.3,7.26,7.26,0,0,0,3.27-3.64,20.29,20.29,0,0,0,.93-5.51,19.1,19.1,0,0,1,.46-3,7,7,0,0,1,1.38-2.86c1.44-1.73,3.62-2.2,5-3.54a8.52,8.52,0,0,0,2.51-4.8,10.88,10.88,0,0,0-.28-5,12.89,12.89,0,0,0-2-3.89,19.53,19.53,0,0,0-4.71-4.34c-.63-.42-1.14-.73-1.49-.93A2.67,2.67,0,0,1,106.58,356.58Z"
          style={{ fill: '#fafafa' }}
        />
        <path
          d="M109.65,149.66c-5.55-1.38-11.52,2.09-14.28,7.09s-2.79,11.13-1.49,16.69c.55,2.33,1.32,4.74.78,7.08-.58,2.51-2.58,4.46-3.54,6.86-1.83,4.63.5,9.76,2.78,14.18,1,2,2.37,4.25,4.59,4.69,2.49.49,4.68-1.5,6.78-2.93a17.83,17.83,0,0,1,12.64-2.85c5,.77,9.52,3.64,14.54,3.73a13.59,13.59,0,0,0,11.47-6.15c1.49-2.33,2-5.84-.15-7.56-1.42-1.13-3.59-1.09-4.84-2.41-1.52-1.61-.86-4.2-.08-6.26s1.56-4.58.17-6.3c-1.55-1.94-4.91-1.57-6.39-3.56s.08-5,0-7.64a7.75,7.75,0,0,0-3.43-5.8c-1.88-1.37-2.14-1.81-4.73-6.52"
          style={{ fill: '#263238' }}
        />
        <path
          d="M101.91,195.39l20,1.71s14.57-2.13,20.15,34.82l22.14-.91,1,14.72-30.45,1.5-3.59-2.15.36,30.27-50.37-3.71c1.14-6.87,6.51-15.91,8.31-21.71-1.83-4.58-11.95-23.25-12.45-32.84-1.36-26.26,24.9-21.7,24.9-21.7"
          style={{ fill: '#8BBE3B' }}
        />
        <path
          d="M133.41,222.48s3.54,4,3.6,7.37c.09,5.4-1.27,8.17.42,14.07l-4.82,1.16s-.74-1.44-.11-4.52,1.85-6.83,2.39-10.18A11.43,11.43,0,0,0,133.41,222.48Z"
          style={{ opacity: '0.30000000000000004' }}
        />
        <path
          d="M132.61,245.08a5,5,0,0,1-.3-1.18,13,13,0,0,1,0-3.32,32.86,32.86,0,0,1,1-4.79,44.38,44.38,0,0,0,1.32-5.78,11.31,11.31,0,0,0-.61-5.74,14.81,14.81,0,0,0-2.58-4c-1.82-2.07-3.11-3.22-3-3.29a6,6,0,0,1,.95.75,29,29,0,0,1,2.37,2.3,14.43,14.43,0,0,1,2.75,4.09,11.41,11.41,0,0,1,.67,6,38.6,38.6,0,0,1-1.37,5.84,39.25,39.25,0,0,0-1.13,4.71,15.86,15.86,0,0,0-.17,3.25A5.6,5.6,0,0,1,132.61,245.08Z"
          style={{ fill: '#263238' }}
        />
        <g style={{ opacity: '0.2' }}>
          <path d="M133.27,244.7l-14.92.34a8.42,8.42,0,0,1-3.5-.41c-1.57-.66-2.58-2.19-3.46-3.65q-3.93-6.48-7.2-13.32s3.94,12.53,6.41,18a2.85,2.85,0,0,0,3.49,1.8A67.17,67.17,0,0,0,133.27,244.7Z" />
        </g>
        <path
          d="M230.81,211.7a12.91,12.91,0,0,1,6.4,1.19,6.32,6.32,0,0,0,2.73.64h2.44V182.18a4.81,4.81,0,0,0-4.82-4.81H206.34v-2.12a6.51,6.51,0,0,1,.68-2.83,12.82,12.82,0,0,0,1.1-7.86,13,13,0,0,0-25.81,2.24,12.87,12.87,0,0,0,1.11,5.23,6.62,6.62,0,0,1,.58,2.62v2.72H153.55a4.81,4.81,0,0,0-4.81,4.81v84.69a3.92,3.92,0,0,0,3.92,3.92h85.8a3.93,3.93,0,0,0,3.92-3.92V236.26h-3.47a6.22,6.22,0,0,0-2.34.46,12.81,12.81,0,0,1-4.84.95,13,13,0,0,1-.92-26Z"
          style={{ fill: '#8BBE3B' }}
        />
        <path
          d="M230.81,211.7a12.91,12.91,0,0,1,6.4,1.19,6.32,6.32,0,0,0,2.73.64h2.44V182.18a4.81,4.81,0,0,0-4.82-4.81H206.34v-2.12a6.51,6.51,0,0,1,.68-2.83,12.82,12.82,0,0,0,1.1-7.86,13,13,0,0,0-25.81,2.24,12.87,12.87,0,0,0,1.11,5.23,6.62,6.62,0,0,1,.58,2.62v2.72H153.55a4.81,4.81,0,0,0-4.81,4.81v84.69a3.92,3.92,0,0,0,3.92,3.92h85.8a3.93,3.93,0,0,0,3.92-3.92V236.26h-3.47a6.22,6.22,0,0,0-2.34.46,12.81,12.81,0,0,1-4.84.95,13,13,0,0,1-.92-26Z"
          style={{ fill: '#fff', opacity: '0.2' }}
        />
        <path
          d="M101.64,196.09l-1.55-35.3,14-10.62,12.74,6.23s.43,16,0,22.94-7.42,7.68-7.42,7.68.17,4.06.33,8.33a9.07,9.07,0,0,1-9.15,9.41h0A9.07,9.07,0,0,1,101.64,196.09Z"
          style={{ fill: '#ffbe9d' }}
        />
        <path
          d="M125.47,168.06a.89.89,0,1,1-.89-.89A.89.89,0,0,1,125.47,168.06Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M126.33,167.09c-.12.12-.77-.44-1.73-.49s-1.67.41-1.77.28.07-.27.38-.48a2.33,2.33,0,0,1,2.79.17C126.29,166.82,126.38,167,126.33,167.09Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M115.18,168.06a.89.89,0,1,1-.89-.89A.89.89,0,0,1,115.18,168.06Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M115.73,167.09c-.12.12-.77-.44-1.73-.49s-1.67.41-1.77.28.07-.27.38-.48A2.34,2.34,0,0,1,114,166a2.37,2.37,0,0,1,1.37.55C115.69,166.82,115.78,167,115.73,167.09Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M119.44,187a17.32,17.32,0,0,1-9.19-3.11s2.3,5.19,9.26,4.91Z"
          style={{ fill: '#eb996e' }}
        />
        <path
          d="M119.18,175.17a7,7,0,0,1,1.56-.2c.24,0,.48,0,.54-.22a1.43,1.43,0,0,0-.14-.78q-.31-.93-.66-2c-.45-1.41-.84-2.69-1.11-3.62a6.7,6.7,0,0,1-.35-1.54,6.37,6.37,0,0,1,.61,1.45c.33.92.77,2.18,1.22,3.59l.63,2a1.5,1.5,0,0,1,.09,1,.6.6,0,0,1-.4.35,2.27,2.27,0,0,1-.42,0A6.56,6.56,0,0,1,119.18,175.17Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M118.47,176a2.29,2.29,0,0,0-1,.56.93.93,0,0,0,1,1.55,1.31,1.31,0,0,0,.87-.65,1,1,0,0,0-.12-1.07.81.81,0,0,0-1-.15"
          style={{ fill: '#eb996e' }}
        />
        <path
          d="M119.71,177.38c0,.14-.79.36-1.64-.13s-1.08-1.3-.94-1.33.5.5,1.2.88S119.69,177.23,119.71,177.38Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M114.87,148.79a10,10,0,0,0-7.82,3.35,11.38,11.38,0,0,0-2.75,8.12,5,5,0,0,0,1,3.09c1,1.15,2.69,1.38,4.22,1.53a10.06,10.06,0,0,0,4.67-.24c1.48-.57,2.71-2.1,2.38-3.64-.73,1-.12,2.48.92,3.11a6.58,6.58,0,0,0,3.55.64l5.24,0a2.88,2.88,0,0,0,1.72-.37,3,3,0,0,0,.86-2.16c.28-2.79.54-5.76-.74-8.25a9.88,9.88,0,0,0-5.79-4.55c-2.39-.79-5-.43-7.49-.59"
          style={{ fill: '#263238' }}
        />
        <path
          d="M100.09,195.09c1.91,1,5.53-2.94,6.71-4.75a9.37,9.37,0,0,0,1-6.26c-.25-2.15-.82-4.26-.87-6.42-.07-3.5,1.25-6.85,1.87-10.3a26.24,26.24,0,0,0-.47-11.29c-.38-1.43-1-3-2.4-3.54a3.5,3.5,0,0,0-3.44.71,8.38,8.38,0,0,0-2.08,3l-1.11,8.42.83,11.79Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M150.43,239.49l5.1,17.4s-26.69,9.71-41.14,9.53c-13.55-.16-17.43-1.7-22.19-12.64S82,230.28,80.89,223.05c0,0-10.89-30.22,18.27-27.24l4,23s3.23,10.67,3.46,11.29c2.18,5.76,5,14.2,7.73,15.22S150.43,239.49,150.43,239.49Z"
          style={{ fill: '#8BBE3B' }}
        />
        <path
          d="M150.43,239.49a3.34,3.34,0,0,1-.7.18l-2,.43c-1.78.37-4.36.92-7.56,1.66s-7,1.65-11.36,2.45a69.85,69.85,0,0,1-14.38,1.33h-.17l-.06-.16c-.55-1.43-1.13-2.94-1.68-4.48-3.84-10.79-6.76-20.71-9.12-27.81-1.17-3.56-2.15-6.43-2.85-8.4-.33-1-.6-1.71-.8-2.27a4.52,4.52,0,0,1-.25-.81s.13.26.35.77.52,1.26.89,2.24c.76,1.95,1.79,4.8,3,8.35,2.46,7.08,5.44,17,9.27,27.75.55,1.54,1.12,3,1.67,4.47l-.24-.16a71.57,71.57,0,0,0,14.29-1.26c4.32-.78,8.15-1.67,11.37-2.34s5.8-1.19,7.6-1.51l2.06-.33A6.31,6.31,0,0,1,150.43,239.49Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M155.53,256.89a4.61,4.61,0,0,1-.78.36c-.52.22-1.29.52-2.29.89-2,.74-4.94,1.72-8.61,2.79s-8.06,2.24-12.95,3.48a83.09,83.09,0,0,1-16.19,2.83,29.82,29.82,0,0,1-4.34-.11,18.45,18.45,0,0,1-4.15-.87,19.91,19.91,0,0,1-6.79-4.15,40.8,40.8,0,0,1-8-10.79c-1.8-3.37-3-6.21-3.86-8.18-.41-1-.72-1.75-.92-2.28a4.65,4.65,0,0,1-.29-.8,4.09,4.09,0,0,1,.38.76c.25.56.59,1.3,1,2.24.89,1.94,2.16,4.75,4,8.09a41.07,41.07,0,0,0,8,10.6,19.67,19.67,0,0,0,6.63,4,17.89,17.89,0,0,0,4,.83,28.83,28.83,0,0,0,4.27.11,83.83,83.83,0,0,0,16.09-2.78c4.9-1.21,9.3-2.33,13-3.36s6.62-2,8.64-2.63l2.32-.8A5.65,5.65,0,0,1,155.53,256.89Z"
          style={{ fill: '#263238' }}
        />
        <path
          d="M91.2,251.14s5,19.2,18,18.81S134.41,264,134.41,264l-.58-.6s-22,4.74-28.11,1.76C94.11,259.52,91.2,251.14,91.2,251.14Z"
          style={{ opacity: '0.2' }}
        />
        <path
          d="M153.72,254.89l4.09,0c3.71-.36,5.78-.42,6.82-1.4,1.39-1.33-.52-2.62-.52-2.62s2.68-.93,2.43-2.56c-.17-1.12-1.44-1.24-1.44-1.24s2.37-.93,2.84-1.92c.63-1.34-1-2.06-1-2.06,3.23-2,2.94-4,2.19-4.16s-3.26,1-3.26,1c-2.13.61-7.94,2.38-5.79-.82s2.6-6.47,2.11-7.44a1.21,1.21,0,0,0-2.22.55,13.78,13.78,0,0,1-1.51,3.58,9.41,9.41,0,0,1-1.94,2.45c-.91.81-1.72,1.46-2.41,2l-3.64,3.5Z"
          style={{ fill: '#ffbe9d' }}
        />
      </g>
    </svg>
  );
};

export default AboutUsSecondary;

AboutUsSecondary.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};
