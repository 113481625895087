import React from 'react';
import PropTypes from 'prop-types';
import { ArrowIcon } from 'images';
import { Colors, SvgWrapper } from 'utils';

function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <SvgWrapper width="16px" height={'16px'}>
        <ArrowIcon color={Colors.secondaryColor} />
      </SvgWrapper>
    </div>
  );
}

NextArrow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default NextArrow;
