import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AdminActions } from 'redux/actions';
import { Layout } from 'components';
import { Table } from 'antd';
import { Container, PrimaryButton } from 'utils';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';

const TableWrapper = styled.div`
  padding: 50px 0;
`;

const Filter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const perPage = 2;
export class AllInvoices extends Component {
  static propTypes = {
    invoices: PropTypes.any.isRequired,
    getAllInvoices: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
  };
  componentDidMount() {
    const { getAllInvoices } = this.props;
    getAllInvoices(perPage, 1);
  }

  render() {
    const { invoices, loading, getAllInvoices } = this.props;
    const columns = [
      {
        title: 'Project name',
        dataIndex: 'title',
        key: 'title',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
      },
      { title: 'goal', dataIndex: 'goal', key: 'goal' },
      { title: 'city', dataIndex: 'city', key: 'city' },
      {
        title: 'View',
        dataIndex: 'view',
        key: 'view',
        render: (text, record) => (
          <Link to={`/admin/view-project/${record.id}`}>View {record.id}</Link>
        ),
      },
    ];
    return (
      <Layout>
        <Container>
          <Filter>
            <PrimaryButton onClick={() => getAllInvoices(perPage, 1)}>
              All
            </PrimaryButton>
            <PrimaryButton
              onClick={() => getAllInvoices(perPage, 1, 'accepted')}
            >
              accepted
            </PrimaryButton>
            <PrimaryButton
              onClick={() => getAllInvoices(perPage, 1, 'pending')}
            >
              pending
            </PrimaryButton>
            <PrimaryButton
              onClick={() => getAllInvoices(perPage, 1, 'declined')}
            >
              declined
            </PrimaryButton>
          </Filter>
          <TableWrapper>
            <Table
              columns={columns}
              dataSource={invoices.projects}
              loading={loading}
              pagination={{
                total: invoices.count,
                pageSize: perPage,
                hideOnSinglePage: true,
                onChange: (page) => getAllInvoices(perPage, page),
              }}
            />
          </TableWrapper>
        </Container>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  const { invoices, loading } = state.admin;
  return {
    invoices,
    loading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllInvoices: (perPage, page, status = null) =>
    dispatch(AdminActions.getAllInvoices(perPage, page, status)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AllInvoices);
