import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

class ScrollToTop extends React.Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
  };
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
      document.body.style.overflowY = 'auto';
    }
  }

  render() {
    return null;
  }
}

export default withRouter(ScrollToTop);
