import React, { Component, useEffect, useState } from 'react';
import { Colors, PrimaryButton } from 'utils';
import styled from '@emotion/styled';
import { useLocation } from 'react-router-dom'
import SLI from './../../../images/stripe-legitimate.png'
import LT from './../../../images/legitimation.png'

const StripeAccountWrapper = styled.div`
  p {
    color: ${Colors.GreenColor};
    font-weight: bold;
    margin: 25px 0 15px !important;
    span {
      color: ${Colors.secondaryColor};
    }
    & + p {
      font-weight: normal;
      margin-top: 10px !important;
      margin-bottom: 10px !important;
    }
  }
`
const PayPalAccount = ({ projectId }) => {
  const [accountResponse, setAccountResponse] = useState(null)
  const [isCompletedStatus, setisCompletedStatus] = useState(false)
  const [loading, setLoading] = useState(false)

  const { search } = useLocation()
  const isCompleted = search.includes("merchantId")
  useEffect(() => {
    if (!isCompleted) {
      setLoading(true)
      fetch('create_paypal_customer_account', {
        method: 'POST',
        body: JSON.stringify({
          project_id: projectId,
          return_url: window.location.href
        }),
        redirect: 'follow',
      })
        .then((result) => {
          setLoading(false)
          setisCompletedStatus(result?.completed == undefined ? false : true)
          setAccountResponse(result?.response?.links[1])
          localStorage.setItem("URL", result?.response.links[0].href)
        })
        .catch((err) => setLoading(false));
    } else {
      setLoading(true)
      fetch('/save_paypal_account_details', {
        method: 'POST',
        body: JSON.stringify({
          link: localStorage.getItem('URL'),
          project_id: projectId
        }),
        redirect: 'follow',
      })
        .then((result) => {
          setLoading(false)
          setisCompletedStatus(result?.status)

        })
        .catch((err) => setLoading(false));
    }

  }, [])

  return (
    <StripeAccountWrapper>
      {loading ? "Loading..." : (<>    <p>
        {`Your Account Status is ${isCompletedStatus == true ? "completed" : "not completed"}`}
      </p>
        <p>
          Für die Genehmigung deines Projekts bei commonsplace ist eine Legitimation deiner Identität <br/>
          erforderlich, die von unserem Zahlungspartner Stripe durchgeführt wird.
        </p>
        <p>
          Klicke auf "Jetzt legitimieren", um die Legitimation bei Stripe durchzuführen. Nach Abschluss der Legitimation kannst du <br/>
          deinen Legitimationsstatus in deinem Profil einsehen und wirst benachrichtigt, falls weitere <br/>
          Dokumente benötigt werden.
        </p>
        <p>
          Bei Problemen mit der Legitimation kontaktiere unser Support-Team unter <br/>
          <a href="mailto:support@commonsplace.de" rel="noreferrer">
            support@commonsplace.de
          </a>
        </p>
        <img src={SLI} alt="Your Image Description" />
        <img src={LT} alt="Your Image Description" />

        {((isCompletedStatus == !true) && isCompleted == false) && (
          <PrimaryButton
            minWidth={'100%'}
            onClick={() => { window.open(`${accountResponse?.href}`, "_self"); }}
          >
            Verbinden Paypal
          </PrimaryButton>
        )}</>)}


    </StripeAccountWrapper>
  )
}

export default PayPalAccount
