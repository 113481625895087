import React, { Component } from 'react';
import {
  Colors,
  isMobile,
  device,
  Container,
  isLabTop,
  isTablet,
  isMinLaptop,
  is4K,
  H3,
} from 'utils';
import { SupportIcon, BeforeBackground, AfterBackground } from 'images';
import styled from '@emotion/styled';
import { SvgWrapper } from 'utils';
import SectionTitleH2 from 'utils/SectionTitleH2';

const SupportWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  .container {
    background: #f9f9f9;
    margin-top: -8px;
  }
  @media ${device.allMobile} {
    flex-wrap: wrap;
  }
  .content {
    width: 60%;

    text-align: left;
    @media ${device.allMobile} {
      width: 100%;
      order: 2;
      text-align: center;
    }
    h2 {
      margin-bottom: 30px;
      margin-top: 0;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      line-height: 1;
      font-weight: bold;
      @media ${device.laptop} {
        font-size: 25px;
      }
      @media ${device.allMobile} {
        justify-content: center;
      }
      &::before {
        content: '2';
        font-size: 30px;
        background: ${Colors.secondaryColor};
        width: 50px;
        text-align: center;
        height: 50px;
        line-height: 50px;
        vertical-align: middle;
        border-radius: 50%;
        display: inline-block;
        margin-right: 10px;
        color: ${Colors.white};
        position: relative;
      }
      span {
        display: block;
        font-size: 25px;
        margin-left: 65px;
      }
    }
    p {
      margin: 0 0 10px;
      font-size: 22px;
      max-width: 100%;
      margin-left: 60px;
      @media ${device.laptop} {
        font-size: 18px;
      }
      @media ${device.allMobile} {
        margin: auto;
        max-width: 90%;
      }
    }
  }
  .image {
    width: 40%;
    @media ${device.allMobile} {
      width: 100%;
      order: 1;
    }
    img {
      width: 60%;
    }
  }
`;
const StartWrapper = styled.div`
  position: relative;
  .container {
    background: #f9f9f9;
    margin-top: -8px;
  }
`;
export default class Support extends Component {
  componentDidMount() {
    const Icon = document.getElementById('freepik_stories-team-spirit');
    window.addEventListener('scroll', function () {
      if (isMobile()) {
        if (window.scrollY >= 5307 && window.scrollY <= 6102) {
          Icon.classList.add('animated');
        } else {
          Icon.classList.remove('animated');
        }
      } else if (isTablet()) {
        if (window.scrollY >= 4337 && window.scrollY <= 5397) {
          Icon.classList.add('animated');
        } else {
          Icon.classList.remove('animated');
        }
      } else if (isMinLaptop()) {
        if (window.scrollY >= 4440 && window.scrollY <= 5447) {
          Icon.classList.add('animated');
        } else {
          Icon.classList.remove('animated');
        }
      } else if (isLabTop()) {
        if (window.scrollY >= 5023 && window.scrollY <= 6030) {
          Icon.classList.add('animated');
        } else {
          Icon.classList.remove('animated');
        }
      } else if (is4K()) {
        if (window.scrollY >= 7205 && window.scrollY <= 8400) {
          Icon.classList.add('animated');
        } else {
          Icon.classList.remove('animated');
        }
      } else {
        if (window.scrollY >= 6951 && window.scrollY <= 7852) {
          Icon.classList.add('animated');
        } else {
          Icon.classList.remove('animated');
        }
      }
    });
  }
  render() {
    return (
      <StartWrapper>
        <div className="background">
          <SvgWrapper width="100%" height="100%">
            <BeforeBackground />
          </SvgWrapper>
        </div>
        <div className="container">
          <Container>
            <SupportWrapper>
              <div className="content">
                <SectionTitleH2>Unterstütze es</SectionTitleH2>
                <p>
                  Wenn dich ein Projekt überzeugt hat, unterstütze es mit einem
                  freien Betrag und verhelfe zu seiner Realisierung! Auf der
                  Projektseite werden vom Projektinitiator regelmäßige Updates
                  hochgeladen, damit du immer auf dem neuesten Stand bleibst.
                </p>
              </div>
              <div className="image">
                <SvgWrapper width="75%" height="auto">
                  <SupportIcon />
                </SvgWrapper>
              </div>
            </SupportWrapper>
          </Container>
        </div>

        <div className="after-background">
          <SvgWrapper width="100%" height="100%">
            <AfterBackground />
          </SvgWrapper>
        </div>
      </StartWrapper>
    );
  }
}
