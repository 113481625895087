import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { ProgressBar } from 'components';
import { MinusCircleFilled, CheckCircleFilled, InfoCircleOutlined } from '@ant-design/icons';
import { LeftSideBackground } from 'images';
import { ProjectsActions } from 'redux/actions';
import { connect } from 'react-redux';
// import { Collapse } from 'components';
import {
  Colors,
  PrimaryButton,
  // SvgWrapper
} from 'utils';
import { Button, Tooltip } from 'antd';

// const fillWidth = keyframes`
//   0% {
//     width: 0;
//     background: ${Colors.secondaryColor};
//   }
//   100% {
//     width: 100%;
//     background: ${Colors.primaryColor};
//   }
// `;
const SideBarWrapper = styled.aside`
  height: calc(100vh - 100px);
  overflow: auto;
  direction: rtl;
  * {
    direction: ltr;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
    display: none;
  }

  &::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${Colors.secondaryColor};
    display: none;
  }
`;
// const StepsWrapper = styled.div`
//   height: calc(100vh - 94px);
//   background: ${Colors.white};
//   width: 75px;
//   text-align: center;
//   ul {
//     padding: 0;
//     margin: 0;
//     list-style: none;
//     .home {
//       width: 100%;
//       height: auto;
//       padding: 20px;
//       background: ${Colors.steel};
//       border-radius: 0;
//       border: none;
//       opacity: 1;
//       margin-bottom: 10px;
//       &:after {
//         content: none;
//       }
//     }
//     li {
//       width: 40px;
//       height: 40px;
//       border: 2px solid ${Colors.steel};
//       color: ${Colors.steel};
//       position: relative;
//       border-radius: 50%;
//       opacity: 0.5;
//       margin: auto;
//       margin-bottom: 30px;
//       &:last-child {
//         &:after {
//           content: none;
//         }
//       }
//       span {
//         line-height: 38px;
//         vertical-align: meddile;
//       }
//       &:after {
//         content: '';
//         width: 7px;
//         height: 7px;
//         display: inline-block;
//         position: absolute;
//         border-radius: 50%;
//         background: ${Colors.steel};
//         opacity: 0.5;
//         bottom: -20px;
//         left: 50%;
//         transform: translateX(-50%);
//       }
//       &.active {
//         border: 2px solid ${Colors.secondaryColor};
//         color: ${Colors.secondaryColor};
//         opacity: 1;
//       }
//     }
//   }
// `;
const NavigationWrapper = styled.nav`
  padding: 20px;
  width: 350px;
  position: relative;
  height: 100%;
  .state {
    text-align: center;
    h1 {
      background: ${Colors.GreenColor};
      color: ${Colors.white};
      border-radius: 10px;
      padding: 10px 20px;
      width: 80%;
      margin: auto;
      margin-bottom: 35px;
    }
  }
  & > .progress-bar {
    margin: 35px 20px;
    .progress-bar {
      height: 25px;
      background: ${Colors.white};
      div {
        height: 25px;
        position: relative;
        &::after {
          content: ${(props) =>
    props.percentage ? `'${props.percentage}%'` : `'0%'`};
          text-align: center;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          display: inline-block;
          position: absolute;
          background: ${Colors.secondaryColor};
          color: ${Colors.white};
          border: 1px solid ${Colors.secondaryColor};
          right: -20px;
          top: 50%;
          transform: translateY(-50%);
          line-height: 38px;
          font-size: 12px;
          font-weight: bold;
        }
      }
    }
  }
  & > ul {
    padding: 0;
    list-style: none;
    position: sticky;
    top: 120px;
    text-align: center;
    margin-bottom: 0;
    & > li {
      border-bottom: none;
      background: ${Colors.white};
      border: 1px solid ${Colors.secondaryColor};
      color: ${Colors.secondaryColor};
      text-align: center;
      border-radius: 12px;
      cursor: pointer;
      padding: 15px 20px;
      min-width: 200px;
      font-size: 18px;
      width: 80%;
      margin: auto;
      margin-bottom: 20px;
      position: relative;
      &.submit {
        background: none;
        border: none;
        color: ${Colors.transparent};
        padding: 0;
        margin-top: 20px;
        button {
          margin-bottom: 0;
        }

        &:hover {
          background: ${Colors.transparent};
          color: ${Colors.transparent};
        }
        button {
          width: 100%;
        }
      }
      &.active,
      &:hover {
        background: ${Colors.secondaryColor};
        color: ${Colors.white};
      }
      .check-mark {
        position: absolute;
        left: -30px;
        top: 50%;
        transform: translateY(-50%);
        color: ${Colors.steel};
        &.done {
          color: ${Colors.secondaryColor};
        }
      }
      ul {
        list-style: none;
        padding: 0;
        li {
          padding-left: 15px;
          &:hover {
            background: ${Colors.steel};
          }
        }
      }
      .edit-btn{
        background: white;
        color: #95bd51;
        &:hover{
          background: #95bd51;
          color: white;
        }
      }
      .edit-btn-1{
        background: #95bd51;
        color: white;
      }
    }
  }
`;

const BackgroundWrapper = styled.div`
  background: url('${LeftSideBackground}');
  background-repeat: no-repeat;
  background-position: 50%, 50%;
  background-size: cover;
  padding-right: 41px;
`;
class SideBar extends Component {
  static propTypes = {
    handelCurrentView: PropTypes.func.isRequired,
    currentView: PropTypes.number.isRequired,
    submitDraft: PropTypes.func.isRequired,
    projectId: PropTypes.string,
    project: PropTypes.object.isRequired,
    isStripeDone: PropTypes.bool.isRequired,
    canMove: PropTypes.bool.isRequired,
    handelShowModal: PropTypes.func.isRequired,
    setSelectedStep: PropTypes.func.isRequired,
    getPayPalStatus: PropTypes.bool.isRequired,
  };
  state = {
    isAnyStepSelected: false,
  }
  componentDidMount() {
    let payPalStatus = window.location.href.includes('merchantId');
    if (payPalStatus) {
      this.props.setSelectedStep(11);
      this.props.handelCurrentView(11);
    }
    const { isStripeDone, setSelectedStep } = this.props;
    if (isStripeDone) {
      setSelectedStep(11);
    }
  }
  render() {
    const {
      handelCurrentView,
      currentView,
      submitDraft,
      projectId,
      project,
      isStripeDone,
      canMove,
      handelShowModal,
      setSelectedStep,
      getPayPalStatus,
    } = this.props;
    const {
      basicInfo,
      projectDescription,
      runTime,
      fundingTarget,
      media,
      aboutYou,
      netWork,
      contractDocuments,
      canSubmit,
      percentage,
      rewards,
    } = project;
    const handleStatus = (status) => {
      switch (status) {
        case 'accepted':
        case 'edit_pending':
        case 'edit_accepted':
        case 'edit_declined':
        case 'accepted_and_draft':
          return 'Online';
        case 'declined':
        case 'pending':
        case 'totally_declined':
          return 'Offline';
        default:
          return 'Offline';
      }
    };
    const payPalStatus = window.location.href.includes('merchantId');
    const stepArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    const renderCheckMark = (condition) => (
      <>
        <div className={`check-mark ${condition ? 'done' : ''}`}>
          {condition ? <CheckCircleFilled /> : <MinusCircleFilled />}
        </div>
      </>
    );
    return (
      <BackgroundWrapper>
        <SideBarWrapper>
          <NavigationWrapper percentage={projectId ? percentage : 0}>
            <div className="state">
              <h1>{handleStatus(project.status)}</h1>
            </div>
            <div className="progress-bar">
              <ProgressBar
                percentage={
                  projectId
                    ? percentage
                      ? isStripeDone && getPayPalStatus
                        ? percentage + 5
                        : percentage
                      : 0
                    : 0
                }
              />
            </div>
            <ul>
              <li
                className={currentView === 1 ? 'active' : ''}
                onClick={() => {
                  setSelectedStep(projectId && 1);
                  if (canMove) {
                    handelCurrentView(1);
                  } else {
                    handelShowModal();
                  }
                }}
              >
                {renderCheckMark(project.basicInfo && basicInfo.isDone && projectId)}
                {project.status === 'accepted_and_draft' &&
                  renderCheckMark(project.status === 'accepted_and_draft' && currentView !== 1)}
                Grundlegendes
                <Tooltip
                  title={<span style={{ color: 'black' }}>Beginne bitte das Projektformular hier.</span>}
                  color="#95bd51"
                >
                  <Button type="link" style={{ padding: '0px 5px' }}>
                    <InfoCircleOutlined
                      style={{ color: 'black', fontSize: '16px' }}
                    />
                  </Button>
                </Tooltip>
              </li>
              <li
                className={currentView === 2 ? 'active' : ''}
                onClick={() => {
                  setSelectedStep(projectId && 2);
                  if (canMove) {
                    handelCurrentView(2);
                  } else {
                    handelShowModal();
                  }
                }}
              >
                {renderCheckMark(project.basicInfo && projectDescription.isDone && projectId)}
                {project.status === 'accepted_and_draft' &&
                  renderCheckMark(project.status === 'accepted_and_draft' && currentView !== 2)}
                Projektinformationen
              </li>
              <li
                className={currentView === 3 ? 'active' : ''}
                onClick={() => {
                  setSelectedStep(projectId && 3);
                  if (canMove) {
                    handelCurrentView(3);
                  } else {
                    handelShowModal();
                  }
                }}
              >
                {renderCheckMark(project.basicInfo && runTime.isDone && projectId)}
                {project.status === 'accepted_and_draft' &&
                  renderCheckMark(project.status === 'accepted_and_draft' && currentView !== 3)}
                Finanzierungszeitraum
              </li>
              <li
                className={currentView === 4 ? 'active' : ''}
                onClick={() => {
                  setSelectedStep(projectId && 4);
                  if (canMove) {
                    handelCurrentView(4);
                  } else {
                    handelShowModal();
                  }
                }}
              >
                {renderCheckMark(project.basicInfo && fundingTarget.isDone && projectId)}
                {project.status === 'accepted_and_draft' &&
                  renderCheckMark(project.status === 'accepted_and_draft' && currentView !== 4)}
                Fundingziel
              </li>

              <li
                className={currentView === 5 ? 'active' : ''}
                onClick={() => {
                  setSelectedStep(projectId && 5);
                  if (canMove) {
                    handelCurrentView(5);
                  } else {
                    handelShowModal();
                  }
                }}
              >
                {renderCheckMark(project.basicInfo && media.isDone && projectId)}
                {project.status === 'accepted_and_draft' &&
                  renderCheckMark(project.status === 'accepted_and_draft' && currentView !== 5)}
                Bilder und Videos
              </li>

              <li
                className={currentView === 6 ? 'active' : ''}
                onClick={() => {
                  setSelectedStep(projectId && 6);
                  if (canMove) {
                    handelCurrentView(6);
                  } else {
                    handelShowModal();
                  }
                }}
              >
                {renderCheckMark(project?.basicInfo && aboutYou?.isDone && projectId)}
                {project.status === 'accepted_and_draft' &&
                  renderCheckMark(project.status === 'accepted_and_draft' && currentView !== 6)}
                Persönliche Daten
              </li>

              <li
                className={currentView === 7 ? 'active' : ''}
                onClick={() => {
                  setSelectedStep(projectId && 7);
                  if (canMove) {
                    handelCurrentView(7);
                  } else {
                    handelShowModal();
                  }
                }}
              >
                {renderCheckMark(project.basicInfo && netWork.isDone && projectId)}
                {project.status === 'accepted_and_draft' &&
                  renderCheckMark(project.status === 'accepted_and_draft' && currentView !== 7)}
                Soziale Medien
              </li>
              <li
                className={currentView === 8 ? 'active' : ''}
                onClick={() => {
                  setSelectedStep(projectId && 8);
                  if (canMove) {
                    handelCurrentView(8);
                  } else {
                    handelShowModal();
                  }
                }}
              >
                {renderCheckMark(project.basicInfo && contractDocuments.isDone && projectId)}
                {project.status === 'accepted_and_draft' &&
                  renderCheckMark(project.status === 'accepted_and_draft' && currentView !== 8)}
                Dokumente
              </li>
              <li
                className={currentView === 9 ? 'active' : ''}
                onClick={() => {
                  setSelectedStep(projectId && 9);
                  if (canMove) {
                    handelCurrentView(9);
                  } else {
                    handelShowModal();
                  }
                }}
              >
                {renderCheckMark(isStripeDone && projectId)}
                {project.status === 'accepted_and_draft' &&
                  renderCheckMark(project.status === 'accepted_and_draft' && currentView !== 9)}
                Legitimation
              </li>
              {/* 
              <li
                className={currentView === 11 ? 'active' : ''}
                onClick={() => {
                  setSelectedStep(11);
                  if (canMove) {
                    handelCurrentView(11);
                  } else {
                    handelShowModal();
                  }
                }}
              >
                {(getPayPalStatus || payPalStatus) ? (
                  <>
                    <div className="check-mark done">
                      <CheckCircleFilled />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="check-mark">
                      <MinusCircleFilled />
                    </div>
                  </>
                )}
                PayPal
              </li> */}

              {/* @SEIF */}
              <li
                className={currentView === 10 ? 'active' : ''}
                onClick={() => {
                  setSelectedStep(projectId && 10);
                  if (canMove) {
                    handelCurrentView(10);
                  } else {
                    handelShowModal();
                  }
                }}
              >
                {renderCheckMark((rewards?.length > 0) && projectId)}
                {project.status === 'accepted_and_draft' &&
                  renderCheckMark(project.status === 'accepted_and_draft' && currentView !== 10)}
                Goodies
              </li>

              <li className={'submit'}>
                {canSubmit ? (
                  <>
                    <div className="check-mark done">
                      <CheckCircleFilled />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="check-mark">
                      <MinusCircleFilled />
                    </div>
                  </>
                )}
                {renderCheckMark(canSubmit && isStripeDone)}
                {project.status === 'accepted_and_draft' &&
                  renderCheckMark(project.status === 'accepted_and_draft' && stepArray.includes(currentView) ? false : true)}
                <PrimaryButton
                  className={isStripeDone && (!(currentView == 10 || currentView == 7) ? 'edit-btn' : 'edit-btn-1')}
                  disabled={!canSubmit && !isStripeDone}
                  onClick={projectId && (() => submitDraft(projectId))}
                >
                  Einreichen
                </PrimaryButton>
              </li>
            </ul>
          </NavigationWrapper>
        </SideBarWrapper>
      </BackgroundWrapper>
    );
  }
}
const mapStateToProps = (state) => {
  const { project, loading, errors } = state.projects;
  return {
    project,
    loading,
    errors,
  };
};

const mapDispatchToProps = (dispatch) => ({
  submitDraft: (projectId) => dispatch(ProjectsActions.submitDraft(projectId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
