import styled from '@emotion/styled';
import { Colors } from 'utils';
import device from './device';
const SectionTitleH3 = styled.h3`
  text-align: center;
  font-size: 20px;
  color: ${Colors.black};
  display: inline-block;
  padding-bottom: 7px;
  font-weight: bold;
  @media ${device.laptop} {
    font-size: 20px;
  }
  @media ${device.allMobile} {
    text-align: center;
    font-size: 20px;
    width: 90%;
    margin: auto;
    margin-bottom: 20px;
  }
`;

export default SectionTitleH3;
