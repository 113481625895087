import React from 'react';
import { Colors, H1, H3, SvgWrapper, device } from 'utils';
import { StartProjectIcon } from 'images';
import styled from '@emotion/styled';
import SectionTitleH2 from 'utils/SectionTitleH2';

const CreateProjectWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 150px;
  @media ${device.allMobile} {
    flex-wrap: wrap;
    margin-bottom: 25px;
  }
  .content {
    width: 60%;
    text-align: left;
    padding-top: 50px;
    @media ${device.allMobile} {
      width: 100%;
      order: 2;
      text-align: center;
    }
    h2 {
      margin-bottom: 30px;
      margin-top: 0;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      line-height: 1;
      font-weight: bold;
      @media ${device.laptop} {
        font-size: 25px;
      }
      @media ${device.allMobile} {
        justify-content: center;
      }
      &::before {
        content: '3';
        font-size: 30px;
        background: ${Colors.secondaryColor};
        width: 50px;
        text-align: center;
        height: 50px;
        line-height: 50px;
        vertical-align: middle;
        border-radius: 50%;
        display: inline-block;
        margin-right: 10px;
        color: ${Colors.white};
        position: relative;
      }
      span {
        display: block;
        font-size: 25px;
        margin-left: 65px;
      }
    }
    p {
      margin: 0 0 10px;
      font-size: 22px;
      max-width: 100%;
      margin-left: 60px;
      @media ${device.laptop} {
        font-size: 18px;
      }
      @media ${device.allMobile} {
        margin: auto;
        max-width: 90%;
      }
    }
  }
  .image {
    width: 40%;

    @media ${device.allMobile} {
      width: 100%;
      order: 1;
    }
    img {
      width: 80%;
    }
  }
`;

export default function CreateProject() {
  return (
    <CreateProjectWrapper>
      <div className="content">
        <SectionTitleH2>Starte deine Projekt</SectionTitleH2>
        <p>
          Dein Projekt ist jetzt online und deine Community kann deine Idee
          unterstützen. Steigere die Aufmerksamkeit deiner Idee: Wende dich an
          Freunde und Familie, damit sie dich dabei unterstützen das Projekt zu
          realisieren. Schon bald ist dein Traum Wirklichkeit.
        </p>
      </div>
      <div className="image">
        <SvgWrapper width="75%" height="auto">
          <StartProjectIcon />
        </SvgWrapper>
      </div>
    </CreateProjectWrapper>
  );
}
