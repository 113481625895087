import React from 'react';
import { Colors, Container, PrimaryButton, SvgWrapper, device } from 'utils';
import styled from '@emotion/styled';
import { Commuity, BeforeBackground, AfterBackground } from 'images';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
const StartProjectWrapper = styled.div`
  margin: 0 auto 100px;
  text-align: center;
  position: relative;
  .container {
    background: #f9f9f9;
    margin-top: -8px;
  }
  .after-background {
    position: relative;
    margin-top: -5px;
  }
  .img {
    text-align: center;
    margin: 0 auto 100px;
    picture,
    img {
      width: 700px;
      height: auto;
      margin: auto;
    }
  }
  h1 {
    font-size: 40px;
    color: ${Colors.GreenColor};
    font-weight: bold;
    @media ${device.allMobile} {
      font-size: 25px;
    }
  }
`;

export default function StartProject(props) {
  return (
    <StartProjectWrapper className="start-project__component">
      <div className="background">
        <SvgWrapper width="100%" height="100%">
          <BeforeBackground />
        </SvgWrapper>
      </div>
      <div className="container">
        <Container>
          <>
            {props.withImg && (
              <div className="img">
                <picture>
                  <img src={Commuity} alt="supporter-img" />
                </picture>
              </div>
            )}
            <h1>
              Worauf wartest du? Starte dein eigenes Projekt und bereichere die
              Community!
            </h1>
            <Link to="/start-project">
              <PrimaryButton>Jetzt Projekt starten</PrimaryButton>
            </Link>
          </>
        </Container>
      </div>
      <div className="after-background">
        <SvgWrapper width="100%" height="100%">
          <AfterBackground />
        </SvgWrapper>
      </div>
    </StartProjectWrapper>
  );
}

StartProject.propTypes = {
  withImg: PropTypes.bool,
};
