/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { Colors } from 'utils';
const VisaCard = ({ color, width, height }) => {
  return (
    <svg
      height={`${height || '100%'}`}
      width={`${width || '100%'}`}
      viewBox="0 0 512 512"
      css={css`
        fill: ${color || Colors.white};
        max-width: ${width || '100%'};
        max-height: ${height || '100%'};
        height: ${height || '100%'};
        width: ${width || '100%'};
        display: inline-block;
      `}
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 512 512"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      xmlSpace="preserve"
    >
      <g xmlns="http://www.w3.org/2000/svg">
        <path
          d="M165.632,177.024c-8.288-3.072-17.536,1.056-20.608,9.344L100.896,304H96V192   c0-8.832-7.168-16-16-16H64c-8.832,0-16,7.168-16,16s7.168,16,16,16v112c0,8.832,7.168,16,16,16h32   c6.656,0,12.64-4.128,14.976-10.368l48-128C178.08,189.344,173.888,180.128,165.632,177.024z"
          fill={color || Colors.white}
          data-original="#2196f3"
        />
        <path
          d="M211.872,176.48c-8.544-2.272-17.248,3.04-19.392,11.648l-32,128   c-2.144,8.576,3.072,17.248,11.648,19.392c1.28,0.32,2.592,0.48,3.872,0.48c7.168,0,13.696-4.832,15.488-12.128l32-128   C225.664,187.328,220.448,178.624,211.872,176.48z"
          fill={color || Colors.white}
          data-original="#2196f3"
        />
        <path
          d="M285.184,239.936c-4.864-2.912-17.856-10.624-19.52-17.344c-0.16-0.736-0.672-2.88,2.048-7.328   c6.016-9.728,29.216-7.808,38.784-5.376c8.64,2.112,17.248-3.072,19.392-11.616s-3.008-17.216-11.552-19.392   c-5.44-1.408-53.92-12.608-73.856,19.584c-8.096,13.12-7.68,24.512-5.92,31.744c4.896,19.744,24.736,31.552,34.24,37.184   c14.752,8.8,20.448,16.576,19.456,20.096c-0.704,2.688-3.2,9.344-10.496,13.216c-8.832,4.672-22.368,4.192-39.008-1.504   c-8.448-2.784-17.44,1.664-20.288,10.016c-2.848,8.384,1.632,17.472,9.984,20.32c12.544,4.256,24.128,6.4,34.72,6.368   c11.008,0,20.928-2.304,29.632-6.944c12.896-6.88,22.528-18.944,26.368-33.056C323.008,281.92,320.224,260.8,285.184,239.936z"
          fill={color || Colors.white}
          data-original="#2196f3"
        />
        <path
          d="M432,176h-32c-6.048,0-11.584,3.424-14.304,8.832l-64,128c-3.936,7.904-0.736,17.504,7.168,21.472   C331.168,335.456,333.6,336,336,336c5.856,0,11.488-3.264,14.304-8.832L369.888,288H416v32c0,8.832,7.168,16,16,16s16-7.168,16-16   V192C448,183.168,440.832,176,432,176z M385.888,256l24-48H416v48H385.888z"
          fill={color || Colors.white}
          data-original="#2196f3"
        />
      </g>
    </svg>
  );
};

export default VisaCard;

VisaCard.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};
