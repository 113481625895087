import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Layout,
  ProjectCard,
  ProjectCategoryCard,
  BaseLayout,
  Card,
} from 'components';
import { Button, Spin } from 'antd';
import { Container, generateProjectsArray, isMobile, H1 } from 'utils';
import { ShortBackground } from 'images';
import { Colors, device } from 'utils';
import styled from '@emotion/styled';
import { adminServices } from 'services';
import { ProjectsActions } from 'redux/actions';
import { Link } from 'react-router-dom';
import { Cross } from 'images';
import CategoryMenu from 'components/layout/categoryMenu';

const perPage = 20;
const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  background: ${Colors.white};
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  padding: 30px 40px;
  @media ${device.allMobile}, ${device.tablet} {
    padding: 0;
    background: ${Colors.transparent};
    box-shadow: none;
    border-radius: 0;
  }

  .card {
    width: 32%;
    display: inline-block;
    text-align: center;
    margin-bottom: 20px;

    @media ${device.allMobile} {
      width: 90%;
      margin: auto;
      margin-bottom: 20px;
    }
    @media ${device.minLaptop} {
      width: 48%;
      margin: auto;
      margin-bottom: 20px;
      margin-top: 0;
    }
    &:nth-of-type(3n + 2) {
      margin: 0 15px 20px;
      @media ${device.tablet} {
        margin: 0 14px 20px;
      }
      @media ${device.allMobile} {
        width: 90%;
        margin: auto;
        margin-bottom: 20px;
      }
      @media ${device.minLaptop} {
        margin: auto;
        margin-top: 0;
        margin-bottom: 20px;
      }
    }
  }
`;

const RelatedProjectsWrapper = styled.div`
  text-align: center;
  margin-top: 20px;
  margin-bottom: 30px;
  h1 {
    text-align: left;
    font-size: 30px;
    color: ${Colors.secondaryColor};
    margin: 0;
    @media ${device.allMobile} {
      text-align: center;
      font-size: 24px;
      width: 90%;
      margin: auto;
      margin-bottom: 20px;
    }
  }
  & > button {
    background: none;
    border: none;
    display: inline-block;
    text-decoration: none;
    margin: 20px auto 0;
    color: ${Colors.secondaryColor};
    &:hover {
      border-bottom: 2px solid ${Colors.secondaryColor};
    }
  }
`;
const Intro = styled.div`
  text-align: center;
  h1 {
    font-size: 55px;
    @media ${device.allMobile} {
      font-size: 30px;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
`;
const ProjectWrapper = styled.div`
  .content-Wrapper {
    max-width: 950px;
    margin: auto;
  }
`;
const SuccessfulProjectsWrapper = styled.div`
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  width: 100%;
  @media ${device.allMobile} {
    display: block;
  }
  .card {
    width: 31%;
    margin: 1%;
    display: inline-block;
    text-align: center;
    margin-bottom: 65px;
    @media ${device.allMobile} {
      width: 90%;
      margin: auto;
      margin-bottom: 20px;
    }
  }
`;
const SuccessfulWrapper = styled.div`
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
  & > button {
    background: none;
    border: none;
    display: inline-block;
    text-decoration: none;
    margin: 20px auto 0;
    color: ${Colors.secondaryColor};
    &:hover {
      border-bottom: 2px solid ${Colors.secondaryColor};
    }
  }
`;
export class ProjectCategoryView extends Component {
  static propTypes = {
    projects: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    const {
      getProjectCategories,
      categories,
      adminCategories,
      getAdminCategories,
      match: {
        params: { categoryName },
      },
    } = this.props;
    getProjectCategories();
    getAdminCategories();
    this.processCategory();
    this.updateCategoryFromURL();
    categoryName && this.handleShowSuccessfulProjects(categoryName);
  }

  componentDidUpdate(prevProps) {
    // if (
    //   prevProps.categories !== this.props.categories &&
    //   this.props.categories.length > 0
    // ) {
    //   this.processCategory();
    //   this.updateCategoryFromURL();
    // }
    if (
      prevProps.adminCategories !== this.props.adminCategories &&
      this.props.adminCategories.length > 0
    ) {
      this.processCategory();
      this.updateCategoryFromURL();
    }

    if (
      prevProps.match.params.categoryName !==
      this.props.match.params.categoryName
    ) {
      this.updateCategoryFromURL();
    }
  }

  updateCategoryFromURL() {
    const {
      adminCategories: categories,
      match: {
        params: { categoryName },
      },
    } = this.props;
    if (!categories?.length) return;

    const catOriginalCase = categories.find(
      (el) => el.category?.toLowerCase() === categoryName?.toLowerCase()
    );
    if (catOriginalCase) this.setState({ category: catOriginalCase });
  }

  processCategory() {
    const {
      adminCategories: categories,
      match: {
        params: { categoryName },
      },
    } = this.props;

    if (!categories?.length) return;
    // const catOriginalCase = categories.find(
    //   (el) => el.category?.toLowerCase() === categoryName?.toLowerCase()
    // );

    // if (!catOriginalCase) {
    //   history.push('/');
    // }
    this.getProjectById(categoryName);
  }

  handleShowSuccessfulProjects = (name) => {
    const requestOptions = {
      method: 'GET',
      redirect: 'follow',
    };
    return fetch(`category/successfull_projects?name=${name}`, requestOptions)
      .then((res) => {
        this.setState({
          successfulProjects: res ? generateProjectsArray(res.projects) : [],
        });
      })
      .catch((error) => {
        return Promise.reject({ errors: error });
      });
  };

  state = {
    status: null,
    projects: [],
    closeMenu: null,
    openMenu: false,
    category: {},
    category_name: '',
    category_title: '',
    category_id: undefined,
    successfulProjects: [],
  };
  async getProjectById(name) {
    let type = '';

    if (this.props.match?.url?.includes('event')) {
      type = 'events';
    } else {
      type = 'projects';
    }
    try {
      const res = await adminServices.getCategoryBasedProjects(
        name,
        true,
        type
      );

      const data = generateProjectsArray(res?.projects);
      this.setState({
        projects: data,
        category_name: res.category_name,
        category_title: res.category_title,
        category_id: res.category_id,
      });
      this.setState({ loading: false });
    } catch (error) {
      this.setState({ loading: false });
    }
  }

  handelButton = () => {
    this.setState({ showModal: true });
    document.body.style.overflowY = 'hidden';
  };
  handleToggleMenu = () => {
    this.setState({
      openMenu: !this.state.openMenu,
      closeMenu: this.state.openMenu ? true : false,
    });
    const header = document.querySelector('header#main-header');
    const burger = header?.querySelector('ul.burger-menu');

    if (this.state.openMenu) {
      document.body.style.overflow = 'unset';
      if (burger) burger.style.display = 'block';
    } else {
      document.body.style.overflow = 'hidden';
      if (burger) burger.style.display = 'none';
    }
  };

  render() {
    const {
      loading,
      projects,
      category_id,
      category_name,
      category_title,
      successfulProjects,
    } = this.state;
    const {
      match: {
        params: { categoryName },
      },
    } = this.props;

    const { categories } = this.props;
    const isEvent = this.props.match?.url?.includes('event');
    const category = categories?.find((el) => el.value === category_id) ?? {};

    const ogTitleTag = document.querySelector('meta[property="og:title"]');
    const ogImageTag = document.querySelector('meta[property="og:image"]');
    const ogImageTagSecure = document.querySelector(
      'meta[property="og:image:secure_url"]'
    );
    if (ogTitleTag && categoryName) {
      ogTitleTag.setAttribute('content', categoryName);
    }
    if (category?.image && ogImageTag && ogImageTagSecure) {
      ogImageTag.setAttribute('content', category?.image);
      ogImageTagSecure.setAttribute('content', category?.image);
    }

    return (
      <ProjectWrapper>
        <div className="categorybanner">
          <BaseLayout
            maxHeightProps="500px"
            maxHeightMobileProps="250px"
            container={false}
            img={category?.image || ShortBackground}
            style={category?.image ? { objectFit: 'cover' } : {}}
            bannerComponent={
              <Intro>
                <H1>
                  {category_title ??
                    category_name ??
                    categoryName?.toUpperCase()?.replace(/_/g, ' ')}
                </H1>
              </Intro>
            }
          >
            <CategoryMenu
              openMenu={this.state.openMenu}
              handleToggleMenu={this.handleToggleMenu}
              closeMenu={this.state.closeMenu}
            />
            <Container style={{ display: 'flex', flexDirection: 'column' }}>
              {/* {isMobile() ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '5px',
                }}
              >
                <FilterBtn>
                  <span>{categoryName}</span>
                  <Link to={isEvent ? '/events' :'/projects'}>
                    <div style={{ height: '18px', marginTop: '-1px' }}>
                      <img
                        style={{ height: '100%', width: '100%', filter: 'invert(1)' }}
                        src={Cross}
                        alt=""
                      />
                    </div>
                  </Link>
                </FilterBtn>
                <FilterBtn
                  onClick={this.handleToggleMenu}
                >
                  Filter zeigen
                </FilterBtn>
              </div>
            ) : (
              <Link to={isEvent ? '/events' :'/projects'}>
                <img
                  width="50"
                  height="50"
                  src="https://img.icons8.com/ios-filled/50/long-arrow-left.png"
                  alt="long-arrow-left"
                />
              </Link>
            )} */}

              {/* <Link to={isEvent ? '/events' :'/projects'}>
                <img
                  width="50"
                  height="50"
                  src="https://img.icons8.com/ios-filled/50/long-arrow-left.png"
                  alt="long-arrow-left"
                />
              </Link> */}

              <RelatedProjectsWrapper>
                <Spin spinning={loading}>
                  {/* <h2>{categoryName}</h2> */}
                  {/* {!isMobile() && (
                  <CategoryContainer>
                    {categories &&
                      categories.length > 0 &&
                      categories.map((project, index) => (
                        <ProjectCategoryCard
                          project={project}
                          key={`${JSON.stringify(project)}-${index}`}
                          data={categories}
                          categoryName={categoryName}
                          onClick={(v) => this.getProjectById(v)}
                          url={isEvent?'/event/':''}
                        />
                      ))}
                  </CategoryContainer>
                )} */}
                  <CardsWrapper>
                    {projects?.length > 0 ? (
                      projects?.map((project, index) => (
                        <ProjectCard
                          project={project}
                          key={`${JSON.stringify(project)}-${index}`}
                        />
                      ))
                    ) : (
                      <text>No project found</text>
                    )}
                  </CardsWrapper>
                </Spin>
                {perPage < projects.count && (
                  <button
                    className="show-more"
                    onClick={() => this.handleShowProjects()}
                  >
                    Mehr anzeigen
                  </button>
                )}
              </RelatedProjectsWrapper>
              {successfulProjects && successfulProjects.length > 0 && (
                <SuccessfulWrapper>
                  <div>
                    <H1>Erfolgreiche Projekte</H1>
                  </div>
                  <Spin spinning={loading}>
                    <SuccessfulProjectsWrapper>
                      {successfulProjects.map((project, index) => (
                        <Card
                          project={project}
                          key={`${JSON.stringify(project)}-${index * 33}`}
                        />
                      ))}
                    </SuccessfulProjectsWrapper>
                  </Spin>
                </SuccessfulWrapper>
              )}
            </Container>
          </BaseLayout>
        </div>
      </ProjectWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  const { projects, loading } = state.admin;
  const { categories, successfulProjects, adminCategories } = state.projects;

  return {
    projects,
    loading,
    categories,
    adminCategories,
    successfulProjects,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getProjectCategories: () => dispatch(ProjectsActions.getProjectCategories()),
  getAdminCategories: () =>
    dispatch(ProjectsActions.getProjectAdminCategories()),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectCategoryView);
