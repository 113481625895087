import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Input, Spin, Checkbox } from 'antd';
import { PrimaryButton, H1, Colors, device } from 'utils';
import { PaymentActions } from 'redux/actions';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';

const FormItem = Form.Item;

const InfoFormWrapper = styled.div`
h1 + p {
  font-size: 22px;
  @media ${device.allMobile} {
    font-size: 16px;
  }
  span {
    text-decoration: underline;
  }
}
input {
  display: block;
  border: 0;
  border-bottom: 1px solid ${Colors.black};
  &.ant-input::placeholder {
    font-size: 18px;
    color: ${Colors.black}
    text-align: center;
    @media ${device.allMobile} {
      font-size: 14px;
    }
  }
}
`;

export class InfoCardForm extends Component {
  static propTypes = {
    prepareCard: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    errors: PropTypes.array.isRequired,
    card: PropTypes.object.isRequired,
    setCurrentView: PropTypes.func.isRequired,
  };
  onFinish = (values) => {
    const { prepareCard, setCurrentView } = this.props;
    prepareCard(values);
    setCurrentView('card');
  };
  render() {
    const { loading } = this.props;
    return (
      <InfoFormWrapper>
        <H1>Personeninformationen</H1>
        <Spin spinning={loading}>
          <Form
            ref={(z) => (this.form = z)}
            name="personal_info"
            onFinish={this.onFinish}
            initialValues={{ terms: true }}
          >
            <FormItem
              name="first_name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="Vorname" />
            </FormItem>
            <FormItem
              name="last_name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="Nachname" />
            </FormItem>
            <FormItem
              name="email"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input type="email" placeholder="Email" />
            </FormItem>
            <FormItem name="terms" valuePropName="checked">
              <Checkbox>
                Ich akzeptiere die
                <Link to="/anb">Nutzungsbedingungen</Link> für und{' '}
                <Link to="/datenschutz">Datenschutzbestimmungen</Link> und
                bestätige, dass ich mindestens 18 Jahre alt bin.
              </Checkbox>
            </FormItem>
            <FormItem>
              <PrimaryButton type="primary" htmltype="submit" minWidth="100%">
                Nächster
              </PrimaryButton>
            </FormItem>
          </Form>
        </Spin>
      </InfoFormWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  const { card, loading, errors } = state.payment;
  return {
    card,
    loading,
    errors,
  };
};

const mapDispatchToProps = (dispatch) => ({
  prepareCard: (user) => dispatch(PaymentActions.prepareCard(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoCardForm);
