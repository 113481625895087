import React, { Component } from 'react';
import { Form, Upload, Modal } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { createFile } from 'utils';

const FormItem = Form.Item;

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
const UploadImageWrapper = styled.div`
  .ant-upload-select {
    display: ${(props) =>
      props.multiple
        ? 'inline-block'
        : props.fileList && props.fileList.length !== 1
        ? 'inline-block'
        : 'none'};
  }
`;

export default class UploadImage extends Component {
  componentDidMount() {
    const { fileList, setImage } = this.props;
    if (fileList) {
      fileList.map((file) =>
        createFile(file).then((res) => {
          setImage([...this.state.fileList, res]);
          return this.setState({ fileList: [...this.state.fileList, res] });
        })
      );
    }
  }
  static propTypes = {
    multiple: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    setImage: PropTypes.func.isRequired,
    fileList: PropTypes.array,
    required: PropTypes.bool,
  };
  state = {
    previewVisible: false,
    previewImage: '',
    fileList: [],
  };

  handleCancelUpload = () => this.setState({ previewVisible: false });

  handleChangeUpload = ({ fileList }) => {
    const { setImage } = this.props; 
    setImage(fileList);
    
    this.setState({ fileList });
  };

  handlePreviewUpload = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    this.setState({
      previewImage: file.url || file.preview || file.thumbUrl,
      previewVisible: true,
    });
  };

  handleBeforeUpload = (file) => {
    this.setState((state) => ({
      fileList: [...state.fileList, file],
    }));
    return false;
  };

  handleChange = ({ fileList }) => {
    this.setState({ fileList });
  };

  render() {
    const { loading, previewVisible, previewImage, fileList } = this.state;
    const { label, multiple, name, required } = this.props;

    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    return (
      <UploadImageWrapper multiple={multiple} fileList={fileList}>
        <FormItem
          name={name}
          label={label}
          rules={[
            {
              required: required,
            },
          ]}
        >
          <div className="clearfix">
            <Upload
              listType="picture-card"
              multiple={multiple}
              fileList={fileList}
              onPreview={this.handlePreviewUpload}
              onChange={this.handleChangeUpload}
              beforeUpload={this.handleBeforeUpload}
              accept="image/png, image/jpeg, image/webp , image/avif" 
            >
              {multiple ? (
                <>{uploadButton}</>
              ) : (
                <>{fileList && fileList.length !== 1 && <>{uploadButton}</>}</>
              )}
            </Upload>
            <Modal
              visible={previewVisible}
              footer={null}
              onCancel={this.handleCancelUpload}
            >
              <img alt="preview-img" style={{ width: '100%' }} src={previewImage} />
            </Modal>
          </div>
        </FormItem>
      </UploadImageWrapper>
    );
  }
}
