/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import PropTypes from 'prop-types';

const LegitimationIcon = ({ width, height }) => {
  return (
    <svg
      className="animated"
      id="freepik_stories-fingerprint"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 500"
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      css={css`
        max-width: ${width || '100%'};
        max-height: ${height || '100%'};
        height: ${height || '100%'};
        width: ${width || '100%'};
        display: inline-block;
      `}
    >
      <style
        dangerouslySetInnerHTML={{
          __html:
            'svg#freepik_stories-fingerprint:not(.animated) .animable {opacity: 0;}svg#freepik_stories-fingerprint.animated #freepik--background-complete--inject-2 {animation: 1s 1 forwards cubic-bezier(.36,-0.01,.5,1.38) slideLeft;animation-delay: 0s;}svg#freepik_stories-fingerprint.animated #freepik--Character--inject-2 {animation: 1s 1 forwards cubic-bezier(.36,-0.01,.5,1.38) zoomOut;animation-delay: 0s;}svg#freepik_stories-fingerprint.animated #freepik--Desk--inject-2 {animation: 1s 1 forwards cubic-bezier(.36,-0.01,.5,1.38) slideRight;animation-delay: 0s;}svg#freepik_stories-fingerprint.animated #freepik--Icons--inject-2 {animation: 1s 1 forwards cubic-bezier(.36,-0.01,.5,1.38) zoomOut;animation-delay: 0s;}svg#freepik_stories-fingerprint.animated #freepik--Fingerprint--inject-2 {animation: 1s 1 forwards cubic-bezier(.36,-0.01,.5,1.38) slideLeft;animation-delay: 0s;}            @keyframes slideLeft {                0% {                    opacity: 0;                    transform: translateX(-30px);                }                100% {                    opacity: 1;                    transform: translateX(0);                }            }                    @keyframes zoomOut {                0% {                    opacity: 0;                    transform: scale(1.5);                }                100% {                    opacity: 1;                    transform: scale(1);                }            }                    @keyframes slideRight {                0% {                    opacity: 0;                    transform: translateX(30px);                }                100% {                    opacity: 1;                    transform: translateX(0);                }            }        .animator-hidden { display: none; }',
        }}
      />
      <g
        id="freepik--background-complete--inject-2"
        style={{ transformOrigin: '246.07501220703125px 257.1700096130371px' }}
        className="animable animator-hidden"
      >
        <path
          d="M413,371.62c-.1.14-1-.4-2-1.19s-1.71-1.55-1.6-1.68,1,.4,2,1.19S413.12,371.49,413,371.62Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '411.2009153366089px 370.18626594543457px',
          }}
          id="elmneku9sxyb"
          className="animable"
        />
        <path
          d="M440.1,331.13c.17-3.78.27-7.66-.92-11.25s-4-6.9-7.67-7.67c-4.24-.88-8.57,1.79-10.94,5.41s-3.19,8-3.89,12.26a433,433,0,0,0-5.31,89l17.32,2.43C436.3,390.26,438.72,363.1,440.1,331.13Z"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '425.58059883117676px 366.67554473876953px',
          }}
          id="elc604rj4bfca"
          className="animable"
        />
        <path
          d="M429.1,312.08a.76.76,0,0,1-.08.27c-.07.21-.17.47-.28.79l-1.16,3c-.24.65-.56,1.36-.82,2.16l-.84,2.63c-.3.94-.62,1.94-1,3l-.89,3.43a150.8,150.8,0,0,0-3.35,17.14c-.93,6.51-1.55,13.73-2,21.31-.89,15.17-1,28.94-1.42,38.9-.18,5-.35,9-.54,11.79-.09,1.35-.17,2.42-.22,3.21,0,.33-.05.6-.07.83a1,1,0,0,1-.05.28,1.29,1.29,0,0,1,0-.29c0-.22,0-.5,0-.83,0-.79.05-1.86.1-3.21.11-2.78.22-6.81.35-11.79.28-10,.34-23.73,1.23-38.93.48-7.59,1.12-14.82,2.09-21.35a142.58,142.58,0,0,1,3.49-17.17c.32-1.2.64-2.35.94-3.44l1-3c.31-.94.61-1.81.89-2.62s.61-1.5.87-2.15l1.26-3c.14-.31.25-.56.35-.76S429.09,312.07,429.1,312.08Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '422.73587799072266px 366.44870376586914px',
          }}
          id="elflwcux33bfk"
          className="animable"
        />
        <path
          d="M430.71,312.08a12.35,12.35,0,0,1,2.11,3.59,26.39,26.39,0,0,1,1.58,11.2c-.46,9.57-1.31,22.76-2.45,37.32s-2.42,27.72-3.44,37.23c-.51,4.76-.95,8.61-1.28,11.26-.16,1.29-.3,2.31-.39,3.06,0,.32-.09.58-.12.79a1,1,0,0,1-.06.27.92.92,0,0,1,0-.27c0-.22,0-.48,0-.8.07-.76.16-1.78.28-3.07.26-2.72.63-6.56,1.08-11.28.92-9.52,2.11-22.69,3.26-37.24s2.06-27.75,2.64-37.28a27.27,27.27,0,0,0-1.37-11.1A24.34,24.34,0,0,0,430.71,312.08Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '430.562903881073px 364.4399871826172px',
          }}
          id="elpotoqx1vyvg"
          className="animable"
        />
        <path
          d="M442.71,356.91a9.57,9.57,0,0,1-3.28-.85c-1.75-.63-3.13-1.27-3.07-1.43a9.47,9.47,0,0,1,3.28.85C441.4,356.1,442.77,356.75,442.71,356.91Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '439.534987449646px 355.77000427246094px',
          }}
          id="eldqxgak3kzf9"
          className="animable"
        />
        <path
          d="M444.8,339.26a10.69,10.69,0,0,1-3.12,1.73c-1.8.81-3.31,1.33-3.38,1.17a10.66,10.66,0,0,1,3.13-1.73C443.22,339.63,444.73,339.1,444.8,339.26Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '441.54998779296875px 340.7101958990097px',
          }}
          id="elb47tttcdpp"
          className="animable"
        />
        <path
          d="M443.72,324.85a5.57,5.57,0,0,1-2.58.13,5.51,5.51,0,0,1-2.54-.48,10.92,10.92,0,0,1,5.12.35Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '441.16001892089844px 324.7091321647167px',
          }}
          id="elq7z89caf0u"
          className="animable"
        />
        <path
          d="M425.85,315.62c-.15.09-.91-.85-1.7-2.11a6.23,6.23,0,0,1-1.17-2.44c.15-.09.91.85,1.7,2.11A6.23,6.23,0,0,1,425.85,315.62Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '424.41502380371094px 313.3450083732605px',
          }}
          id="el3ippepfb1gt"
          className="animable"
        />
        <path
          d="M419.94,324.33a5.08,5.08,0,0,1-2.46.26c-1.35,0-2.45-.19-2.44-.36a5.08,5.08,0,0,1,2.46-.26C418.85,324,420,324.16,419.94,324.33Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '417.49110174179077px 324.27998173236847px',
          }}
          id="el9zz8mm3x7w5"
          className="animable"
        />
        <path
          d="M415.7,341.76a6,6,0,0,1-2.59-.71c-1.36-.55-2.42-1.13-2.35-1.28a6,6,0,0,1,2.59.71C414.71,341,415.77,341.6,415.7,341.76Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '413.23002219200134px 340.7650146484375px',
          }}
          id="el4mfrta5fbpo"
          className="animable"
        />
        <path
          d="M415.25,356.42c0,.16-.95.51-2.2.78s-2.3.34-2.33.17.95-.52,2.2-.78S415.21,356.25,415.25,356.42Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '412.9846646785736px 356.8962748646736px',
          }}
          id="elj0rqeq6weg"
          className="animable"
        />
        <path
          d="M413.25,380.39c0,.17-1,.5-2.34.74s-2.41.29-2.44.12,1-.5,2.34-.73S413.22,380.22,413.25,380.39Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '410.85967922210693px 380.82305467128754px',
          }}
          id="elqdcw7zw37i"
          className="animable"
        />
        <path
          d="M411.93,393.63c.08.15-.79.77-1.95,1.38s-2.16,1-2.24.83.79-.77,1.95-1.38S411.85,393.48,411.93,393.63Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '409.8349964618683px 394.7383130788803px',
          }}
          id="elfe89ntrni3"
          className="animable"
        />
        <path
          d="M422.74,408.65c-.15.08-.91-1-1.7-2.35a7.06,7.06,0,0,1-1.17-2.65c.15-.09.91,1,1.7,2.34A7.27,7.27,0,0,1,422.74,408.65Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '421.3049621582031px 406.14949321746826px',
          }}
          id="el8sdjzf5f1ek"
          className="animable"
        />
        <path
          d="M425.85,383.69c.12.12-.72,1.14-1.89,2.29s-2.21,2-2.33,1.84.73-1.14,1.9-2.29S425.73,383.56,425.85,383.69Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '423.74006390571594px 385.7579188346863px',
          }}
          id="eloheuypj5b9"
          className="animable"
        />
        <path
          d="M425.16,370.59c-.12.11-.91-.53-1.75-1.44s-1.43-1.75-1.31-1.86.92.52,1.76,1.44S425.29,370.47,425.16,370.59Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '423.6312357187271px 368.939924120903px',
          }}
          id="elgcoe3ewfwns"
          className="animable"
        />
        <path
          d="M427.31,353.85c.13.12-.58,1.11-1.58,2.23s-1.91,1.92-2,1.81.58-1.11,1.58-2.23S427.18,353.74,427.31,353.85Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '425.52363717556px 355.87001872062683px',
          }}
          id="elhtb25ea3xo6"
          className="animable"
        />
        <path
          d="M428.52,341.71c-.12.12-1.11-.66-2.21-1.73s-1.89-2.05-1.77-2.18,1.11.66,2.21,1.73S428.63,341.58,428.52,341.71Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '426.52913880348206px 339.75408136844635px',
          }}
          id="el08e1f0ghzsly"
          className="animable"
        />
        <path
          d="M430.89,325.29a6.16,6.16,0,0,1-1.11,2.46c-.76,1.27-1.49,2.22-1.64,2.14a6.16,6.16,0,0,1,1.11-2.46C430,326.16,430.74,325.2,430.89,325.29Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '429.5150146484375px 327.5894253253937px',
          }}
          id="eltwla45lfd2m"
          className="animable"
        />
        <path
          d="M435.35,310.07a5.81,5.81,0,0,1-1,2.46c-.69,1.28-1.37,2.25-1.52,2.17a5.8,5.8,0,0,1,1-2.46C434.51,311,435.2,310,435.35,310.07Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '434.0900115966797px 312.3855690956116px',
          }}
          id="el37w12tv6e78"
          className="animable"
        />
        <path
          d="M440.41,317c.07.16-.62.64-1.55,1.09s-1.74.68-1.82.52.62-.64,1.55-1.08S440.33,316.83,440.41,317Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '438.7243410348892px 317.80414003133774px',
          }}
          id="eljcrmi6tgf0l"
          className="animable"
        />
        <path
          d="M440.53,371.63a10.57,10.57,0,0,1-6.33-2.7c.1-.15,1.45.57,3.18,1.31S440.57,371.45,440.53,371.63Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '437.3654224872589px 370.2699908018112px',
          }}
          id="elkdys62ts1mi"
          className="animable"
        />
        <path
          d="M438.46,383.2c0,.17-1,.51-2.33.76a5,5,0,0,1-2.45.16c0-.17,1-.51,2.33-.76S438.43,383,438.46,383.2Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '436.0699920654297px 383.6499819159508px',
          }}
          id="elm0bxannom9c"
          className="animable"
        />
        <path
          d="M435.92,403c-.1.13-1.43-.77-2.64-2.31s-1.8-3-1.65-3.1,1,1.24,2.14,2.71A21.88,21.88,0,0,1,435.92,403Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '433.7635552883148px 400.2985544204712px',
          }}
          id="ell56sfdoyj6f"
          className="animable"
        />
        <path
          d="M408.08,412.12c-.11-.13.59-.92,1.57-1.75s1.86-1.41,2-1.28-.6.91-1.58,1.75S408.19,412.25,408.08,412.12Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '409.8678158521652px 410.605122089386px',
          }}
          id="ela00sqro52r9"
          className="animable"
        />
        <path
          d="M400.08,368.81c-1.13-3.52-3.24-7.63-6.94-7.68a6.16,6.16,0,0,0-5.2,3.48,12.87,12.87,0,0,0-1.4,6.37,166,166,0,0,0,7.94,46.13H408A160.51,160.51,0,0,0,400.08,368.81Z"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '397.2648868560791px 389.12001037597656px',
          }}
          id="elt7biluurwqh"
          className="animable"
        />
        <path
          d="M375.62,346.65c-1.89-7.56-7.52-11.64-14.07-12-6,1.05-8.3,6.93-9,13s1.16,12,3,17.8q8.9,27.84,17.8,55.67l16.82-1.83C384.85,394.36,381.79,371.32,375.62,346.65Z"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '371.284517288208px 377.88499450683594px',
          }}
          id="el18fe557olpe"
          className="animable"
        />
        <path
          d="M393.14,361.13a15.92,15.92,0,0,0-1.15,1.92,12.72,12.72,0,0,0-.63,6c.26,2.57.56,5.67,1,9.06s1,7.12,1.81,11c1.53,7.77,3.25,14.74,4.35,19.82.55,2.54,1,4.6,1.23,6,.11.66.21,1.2.28,1.65a2.16,2.16,0,0,1,.06.59,2.41,2.41,0,0,1-.18-.56c-.11-.44-.24-1-.39-1.63-.36-1.5-.85-3.53-1.43-6-1.2-5.05-3-12-4.53-19.8-.76-3.89-1.36-7.63-1.77-11.05-.21-1.71-.36-3.34-.49-4.86s-.29-2.93-.36-4.24a12.18,12.18,0,0,1,.85-6.11A5.32,5.32,0,0,1,393.14,361.13Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '395.47358417510986px 389.15000915527344px',
          }}
          id="elh767pcips9d"
          className="animable"
        />
        <path
          d="M361.79,334.15a23,23,0,0,0-2,2.73,19.14,19.14,0,0,0-2.56,8.8c-.26,3.92.61,8.56,2.08,13.55s3.1,10.5,4.8,16.26c3.35,11.53,6.39,22,8.61,29.6l2.54,9c.27,1,.49,1.83.66,2.45a4.17,4.17,0,0,1,.19.87,4.72,4.72,0,0,1-.31-.83c-.19-.62-.45-1.42-.77-2.42-.67-2.18-1.59-5.21-2.73-8.94-2.27-7.61-5.36-18-8.78-29.54-1.69-5.78-3.3-11.27-4.76-16.29s-2.29-9.74-2-13.72a18.86,18.86,0,0,1,2.79-8.9A10.44,10.44,0,0,1,361.79,334.15Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '366.40626430511475px 375.7799987792969px',
          }}
          id="elhyn0suhnyqe"
          className="animable"
        />
        <path
          d="M386.5,417.43a4.66,4.66,0,0,1-.38-.81c-.22-.54-.57-1.32-.95-2.36-.81-2.07-1.83-5.12-2.9-8.94s-2.12-8.42-3.13-13.56S377.09,381,376,375s-2.47-11.52-4-16.52a91.52,91.52,0,0,0-4.91-13,69.22,69.22,0,0,0-4.53-8.18c-.58-.94-1.11-1.62-1.42-2.11a4,4,0,0,1-.47-.76,4.66,4.66,0,0,1,.57.69c.35.46.91,1.12,1.52,2.05a61.34,61.34,0,0,1,4.73,8.12,87.33,87.33,0,0,1,5,13,168.44,168.44,0,0,1,4.05,16.57c1.1,5.94,2.16,11.59,3.12,16.75s2,9.74,3,13.56,2,6.88,2.69,9c.35,1.06.67,1.85.85,2.41A4.7,4.7,0,0,1,386.5,417.43Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '373.5849914550781px 375.9300079345703px',
          }}
          id="el54vxra43ouj"
          className="animable"
        />
        <path
          d="M355.85,362.61c0,.17-.87.54-2,.82s-2.15.38-2.19.21.87-.53,2-.81S355.81,362.45,355.85,362.61Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '353.75435853004456px 363.1284739971161px',
          }}
          id="elp5grb90mdi8"
          className="animable"
        />
        <path
          d="M354.07,351.13c0,.18-1.28.25-2.84.31a8.41,8.41,0,0,1-2.85-.08,5.74,5.74,0,0,1,2.83-.54A6,6,0,0,1,354.07,351.13Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '351.2250061035156px 351.14553835988045px',
          }}
          id="elerwdn3b6f2h"
          className="animable"
        />
        <path
          d="M356,340.65c-.11.13-1-.47-2.05-1.33s-1.75-1.66-1.64-1.8,1,.47,2,1.33S356.08,340.52,356,340.65Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '354.15217661857605px 339.0838541984558px',
          }}
          id="elzsjr0iva7p"
          className="animable"
        />
        <path
          d="M361.1,330.94c.17,0,.12.86.09,1.85s0,1.8-.2,1.83-.45-.79-.41-1.85S360.93,330.9,361.1,330.94Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '360.89824560284615px 332.7796733379364px',
          }}
          id="elb7d8ytfx31"
          className="animable"
        />
        <path
          d="M371.58,335.51c.14.1-.27.93-.91,1.86s-1.29,1.59-1.43,1.49.27-.93.92-1.85S371.44,335.42,371.58,335.51Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '370.4099988937378px 337.185938000679px',
          }}
          id="el5slwfsd8zrs"
          className="animable"
        />
        <path
          d="M378.39,347.34c.06.16-.81.66-1.95,1.12s-2.11.72-2.18.56.81-.66,1.95-1.13S378.32,347.18,378.39,347.34Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '376.3245208263397px 348.1789336800575px',
          }}
          id="el8iubhmnw43c"
          className="animable"
        />
        <path
          d="M382.52,359.5a5.08,5.08,0,0,1-2.67,1.16c-1.58.37-2.91.27-2.91.1a11.62,11.62,0,0,1,2.77-.7A11.18,11.18,0,0,1,382.52,359.5Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '379.72998046875px 360.20624923706055px',
          }}
          id="elmiomyy6jh6s"
          className="animable"
        />
        <path
          d="M363.56,356.05c-.16-.06,0-.9.27-1.88s.69-1.74.85-1.69.05.89-.26,1.87S363.73,356.1,363.56,356.05Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '364.1246203184128px 354.26498329639435px',
          }}
          id="elmqq4zdaq2n"
          className="animable"
        />
        <path
          d="M366.21,371.32c-.16-.07.18-1.2.76-2.53s1.17-2.35,1.33-2.28a6,6,0,0,1-.77,2.52C367,370.36,366.36,371.38,366.21,371.32Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '367.23520851135254px 368.91457867622375px',
          }}
          id="el9a1xvcy4mg5"
          className="animable"
        />
        <path
          d="M359.67,375.5c-.06.16-1.13-.13-2.42-.54s-2.33-.77-2.29-.94a4.64,4.64,0,0,1,2.48.35C358.76,374.78,359.74,375.34,359.67,375.5Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '357.3161931037903px 374.76184672117233px',
          }}
          id="el9zljq7woe5"
          className="animable"
        />
        <path
          d="M363.44,388c.1.14-.62.82-1.61,1.53s-1.87,1.16-2,1,.62-.82,1.61-1.53S363.34,387.81,363.44,388Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '361.63230550289154px 389.2602891921997px',
          }}
          id="elafz8bqbxg5b"
          className="animable"
        />
        <path
          d="M366.46,400.36c0,.17-.85.53-2,.8s-2.1.37-2.14.2.85-.52,2-.8S366.42,400.19,366.46,400.36Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '364.38932061195374px 400.85885322093964px',
          }}
          id="elhenfwg7eezc"
          className="animable"
        />
        <path
          d="M382.85,369.87c.09.14-.68.75-1.79,1.17s-2.08.46-2.11.3.84-.48,1.89-.88S382.76,369.72,382.85,369.87Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '380.9031927585602px 370.630313038826px',
          }}
          id="el44q32uthc04"
          className="animable"
        />
        <path
          d="M385.19,389.18c-.07.16-1-.12-2-.61s-1.84-1-1.76-1.17,1,.12,2,.61S385.26,389,385.19,389.18Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '383.3094220161438px 388.28809320926666px',
          }}
          id="el6r54wmlm02w"
          className="animable"
        />
        <path
          d="M389.32,403a6.29,6.29,0,0,1-2.67-.72,6.39,6.39,0,0,1-2.46-1.29,6.32,6.32,0,0,1,2.68.72C388.29,402.26,389.39,402.84,389.32,403Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '386.756587266922px 401.9949951171875px',
          }}
          id="el3afyfsjszqf"
          className="animable"
        />
        <path
          d="M367.12,413.83c-.08-.16,1-.9,2.3-1.9s2.26-1.9,2.39-1.78-.68,1.22-2,2.26S367.2,414,367.12,413.83Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '369.4697186946869px 411.99985802173615px',
          }}
          id="el2930zr4jqzx"
          className="animable"
        />
        <path
          d="M378.05,406.8c-.18,0-.41-.91-.84-2s-.86-1.93-.71-2,.84.64,1.29,1.81S378.21,406.79,378.05,406.8Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '377.31335669755936px 404.79762840270996px',
          }}
          id="el56dh9c497m"
          className="animable"
        />
        <path
          d="M375.15,390.18c-.1.14-.8-.2-1.57-.75s-1.32-1.11-1.22-1.25.81.19,1.58.75S375.25,390,375.15,390.18Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '373.755073428154px 389.1796805858612px',
          }}
          id="el7s1do06k44"
          className="animable"
        />
        <path
          d="M400.62,364.13c.11.14-.58.82-1.53,1.52s-1.8,1.16-1.9,1,.58-.82,1.53-1.52S400.52,364,400.62,364.13Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '398.9058723449707px 365.39437103271484px',
          }}
          id="el51z181fkdg3"
          className="animable"
        />
        <path
          d="M404.46,374.63a3.73,3.73,0,0,1-2.39.24c-1.33-.14-2.34-.59-2.28-.75a7.25,7.25,0,0,1,2.35.14C403.4,374.4,404.45,374.45,404.46,374.63Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '402.12372183799744px 374.5237185060978px',
          }}
          id="elfwqz48joqp"
          className="animable"
        />
        <path
          d="M388,377c0,.17-1,.52-2.25.61s-2.32-.14-2.3-.3,1-.24,2.26-.32S387.93,376.81,388,377Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '385.7248697280884px 377.26065105199814px',
          }}
          id="el7rsv8ut75j7"
          className="animable"
        />
        <path
          d="M389.38,367.06c0,.16-1,.19-2.12-.32s-1.74-1.28-1.63-1.4.88.38,1.89.84S389.4,366.88,389.38,367.06Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '387.498895406723px 366.24276381731033px',
          }}
          id="elb5yzudgco7w"
          className="animable"
        />
        <path
          d="M405.88,385.9c-.09.14-1.12-.35-2.3-1.09s-2.07-1.46-2-1.61,1.12.34,2.31,1.09S406,385.75,405.88,385.9Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '403.7333345413208px 384.5484439134598px',
          }}
          id="el96cndodmg3j"
          className="animable"
        />
        <rect
          x="358.11"
          y="415.35"
          width="88.04"
          height="15.6"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '402.13px 423.15000000000003px',
          }}
          id="el3ocsi40wzaf"
          className="animable"
        />
        <polygon
          points="364.68 427.85 375.39 474.48 427.86 474.48 439.57 427.85 364.68 427.85"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '402.125px 451.1650085449219px',
          }}
          id="el2xu4p6x4suu"
          className="animable"
        />
        <path
          d="M446.09,431c0,.17-19.85.31-44.34.31s-44.36-.14-44.36-.31,19.86-.31,44.36-.31S446.09,430.8,446.09,431Z"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '401.74000549316406px 431px',
          }}
          id="eljn3fhfhkr7k"
          className="animable"
        />
        <path
          d="M397.13,377.32c-.14.09-.74-.61-1.34-1.56s-1-1.81-.82-1.9.75.61,1.35,1.57S397.28,377.23,397.13,377.32Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '396.0452936887741px 375.5900208950043px',
          }}
          id="eld2q3fqzr1kp"
          className="animable"
        />
        <path
          d="M397.17,389.88c-.17-.06,0-1.12.46-2.37s.88-2.23,1.05-2.18-.05,1.12-.47,2.37S397.33,389.93,397.17,389.88Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '397.9215951561928px 387.6050114631653px',
          }}
          id="el8s1op1v6pja"
          className="animable"
        />
        <path
          d="M401.46,403.06c-.15.07-.81-1-1.47-2.36a6.56,6.56,0,0,1-.91-2.62c.16-.07.82,1,1.47,2.36S401.62,403,401.46,403.06Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '400.2870315313339px 400.5700218677521px',
          }}
          id="elrlegzke8x79"
          className="animable"
        />
        <path
          d="M392.17,413.66c-.13-.11.39-.93,1.17-1.82s1.51-1.53,1.64-1.42-.4.93-1.17,1.82S392.29,413.77,392.17,413.66Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '393.57489836215973px 412.0399966239929px',
          }}
          id="elmsa53rvn2zr"
          className="animable"
        />
        <path
          d="M390.21,394.41c0,.17-.87.16-1.89,0s-1.81-.46-1.78-.63.87-.16,1.89,0S390.24,394.24,390.21,394.41Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '388.3749874830246px 394.0950067937374px',
          }}
          id="eldmrxqqhajgu"
          className="animable"
        />
        <polygon
          points="321.17 426.25 321.17 476.1 248.28 476.1 167.89 476.1 167.89 426.25 321.17 426.25"
          style={{
            fill: 'rgb(245, 245, 245)',
            transformOrigin: '244.5300064086914px 451.1750030517578px',
          }}
          id="elvgixe31flcd"
          className="animable"
        />
        <polygon
          points="327.61 412.32 327.61 426.25 162.53 426.25 162.53 412.32 248.28 412.32 327.61 412.32"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '245.0699920654297px 419.2850036621094px',
          }}
          id="eleg9pngo41g"
          className="animable"
        />
        <polygon
          points="245.07 476.1 167.89 476.1 167.89 426.25 162.53 426.25 162.53 412.32 248.28 412.32 248.28 424.96 245.07 476.1"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '205.40499877929688px 444.2100067138672px',
          }}
          id="elbffr96taava"
          className="animable"
        />
        <rect
          x="268.65"
          y="434.29"
          width="31.09"
          height="7.5"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '284.195px 438.04px',
          }}
          id="elxy8ef83v63j"
          className="animable"
        />
        <g id="elyeyshcp0grs">
          <rect
            x="263.82"
            y="451.18"
            width="39.92"
            height="15.84"
            style={{
              fill: 'rgb(255, 255, 255)',
              transformOrigin: '283.78px 459.1px',
              transform: 'rotate(180deg)',
            }}
            className="animable"
            id="elygo8plbdqsg"
          />
        </g>
        <rect
          x="55.83"
          y="38.28"
          width="144.52"
          height="150.65"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '128.09px 113.605px',
          }}
          id="el9i8rki767zr"
          className="animable"
        />
        <rect
          x="46.05"
          y="38.28"
          width="144.52"
          height="150.65"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '118.31px 113.605px',
          }}
          id="el28bnanf515h"
          className="animable"
        />
        <path
          d="M190.62,189H46V38.24H190.62Z"
          style={{
            fill: 'rgb(245, 245, 245)',
            transformOrigin: '118.30999755859375px 113.61999893188477px',
          }}
          id="elb8xyoxhu8fs"
          className="animable"
        />
        <rect
          x="65.48"
          y="58.54"
          width="105.67"
          height="110.15"
          style={{
            fill: 'rgb(250, 250, 250)',
            transformOrigin: '118.315px 113.61500000000001px',
          }}
          id="el636t7s43yst"
          className="animable"
        />
        <path
          d="M65.48,169.42a17.34,17.34,0,0,1-2.61,3c-1.69,1.78-4.06,4.19-6.72,6.81s-5.1,5-6.9,6.61a17,17,0,0,1-3.06,2.54,17,17,0,0,1,2.6-3c1.68-1.78,4.06-4.2,6.73-6.82s5.11-5,6.92-6.62S65.39,169.32,65.48,169.42Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '55.834999084472656px 178.89655876159668px',
          }}
          id="elhbewr8bp7ns"
          className="animable"
        />
        <path
          d="M171.14,169.15a17,17,0,0,1,3,2.6c1.79,1.69,4.2,4.06,6.81,6.72s5,5.1,6.61,6.9a16.3,16.3,0,0,1,2.54,3.06,17.38,17.38,0,0,1-3-2.59c-1.78-1.69-4.2-4.07-6.83-6.74s-5-5.11-6.62-6.91A16.8,16.8,0,0,1,171.14,169.15Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '180.6199951171875px 178.79000091552734px',
          }}
          id="elgd3r2zpl78d"
          className="animable"
        />
        <path
          d="M170.85,58.83a17.34,17.34,0,0,1,2.61-3c1.69-1.78,4.06-4.19,6.72-6.81s5.1-5,6.9-6.61a16.57,16.57,0,0,1,3.06-2.54,17,17,0,0,1-2.6,3c-1.69,1.78-4.06,4.19-6.73,6.82s-5.11,5-6.92,6.62S170.94,58.93,170.85,58.83Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '180.49500274658203px 49.353434562683105px',
          }}
          id="elhou8rxbfdvv"
          className="animable"
        />
        <path
          d="M65.19,59.1a17.58,17.58,0,0,1-3-2.61c-1.78-1.68-4.19-4.06-6.8-6.71s-5-5.11-6.61-6.91a17,17,0,0,1-2.55-3,16.25,16.25,0,0,1,3,2.59C51,44.1,53.41,46.48,56,49.14s5,5.12,6.62,6.92A16.8,16.8,0,0,1,65.19,59.1Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '55.710004806518555px 49.48499870300293px',
          }}
          id="el9gloeyr5k7o"
          className="animable"
        />
        <path
          d="M170.85,168.69s0-.19,0-.54,0-.88,0-1.54c0-1.38,0-3.36-.05-5.94,0-5.22-.06-12.81-.11-22.45-.05-19.29-.12-46.78-.2-79.68l.4.4L65.49,59h0l.46-.46c0,40.43,0,78.25-.06,110.15l-.4-.41,76.3.21,21.43.1,5.67,0,1.46,0,.51,0-.46,0-1.42,0-5.6.05-21.34.1-76.55.21h-.41v-.4c0-31.9,0-69.72-.06-110.15v-.47h.48l105.36.06h.41v.41c-.08,33-.16,60.58-.21,79.94,0,9.6-.08,17.16-.1,22.36,0,2.54,0,4.5,0,5.86,0,.64,0,1.13,0,1.5A3,3,0,0,1,170.85,168.69Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '118.14498901367188px 113.44002914428711px',
          }}
          id="elccy0vd8zojp"
          className="animable"
        />
        <path
          d="M98.83,143.46c-8-8-8.46-22.24-1-30.75,2.94-3.37,6.84-5.83,9.56-9.37,5.89-7.69,5.81-19.78,14-24.93,6-3.76,14.56-1.61,18.93,3.94S145,96,142.18,102.43c-2.09,4.77-5.72,8.83-7.22,13.83s-.65,10.7-2.24,15.79c-1.91,6.15-8.42,10-14.46,12.27a27,27,0,0,1-19.43-.86"
          style={{
            fill: 'rgb(245, 245, 245)',
            transformOrigin: '118.26382446289062px 111.12613296508789px',
          }}
          id="eluc00xm266v"
          className="animable"
        />
        <path
          d="M128.55,92.72a20.09,20.09,0,0,1-1,2.91c-.71,1.86-1.82,4.5-3.28,7.74a217,217,0,0,1-13.06,24.47,217,217,0,0,1-16.1,22.59c-2.25,2.75-4.13,4.92-5.48,6.38A20.13,20.13,0,0,1,87.46,159a19.75,19.75,0,0,1,1.87-2.45c1.26-1.53,3.06-3.77,5.23-6.56a262.48,262.48,0,0,0,15.82-22.67,264,264,0,0,0,13.26-24.25c1.53-3.19,2.74-5.8,3.55-7.6A19,19,0,0,1,128.55,92.72Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '108.00499725341797px 125.86000061035156px',
          }}
          id="elw9rqxba39is"
          className="animable"
        />
        <path
          d="M106.69,111.81a36.13,36.13,0,0,1,.85,5.57c.41,3.47.93,8.32,1.47,13.79l-.71-.36,2.28-1.33c5.28-3.08,10.09-5.83,13.59-7.77a44,44,0,0,1,5.77-3,43,43,0,0,1-5.43,3.56c-3.42,2.09-8.17,4.93-13.45,8l-2.28,1.33-.64.37-.07-.74c-.51-5.47-.91-10.33-1.16-13.82A35.54,35.54,0,0,1,106.69,111.81Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '118.30102634429932px 121.88998794555664px',
          }}
          id="elo98sg3feve"
          className="animable"
        />
        <path
          d="M134.45,103.66a18.24,18.24,0,0,1-3.56,2c-2.26,1.12-5.39,2.62-8.88,4.21l-1.09.49-.57.26-.09-.61c-.55-3.52-1-6.66-1.27-8.93a15.3,15.3,0,0,1-.27-3.68,14.36,14.36,0,0,1,.9,3.58c.45,2.24,1,5.36,1.57,8.88l-.66-.36,1.09-.5c3.48-1.59,6.66-3,9-3.95A16.84,16.84,0,0,1,134.45,103.66Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '126.57632493972778px 104.0099983215332px',
          }}
          id="elwov9m1qkp9m"
          className="animable"
        />
      </g>
      <g
        id="freepik--Character--inject-2"
        style={{ transformOrigin: '123.53034782409668px 281.0206069946289px' }}
        className="animable animator-hidden"
      >
        <path
          d="M183.4,219.9a6.5,6.5,0,0,1-2.47,3.51,5.67,5.67,0,0,1-4.76.92,5.92,5.92,0,0,1-4-4.75,5.49,5.49,0,0,1,.78-3.63,11.34,11.34,0,0,1,2.86-3.07c2.29-1.8,5.46-3.29,7.28-6.42a13.24,13.24,0,0,0,1.24-10.64,15.46,15.46,0,0,0-2.69-5.1,22.5,22.5,0,0,0-4.34-4,30.38,30.38,0,0,1-4.34-3.7,7.49,7.49,0,0,1-2.09-4.75,9.23,9.23,0,0,1,1.42-4.9,18.27,18.27,0,0,1,3.21-3.93c2.41-2.33,5-4.4,6.63-7.08a14.54,14.54,0,0,0,2.09-8.23,18.71,18.71,0,0,0-1.86-6.74,35.45,35.45,0,0,1-1.85-4.85,11.06,11.06,0,0,1-.27-4.36s0,.09-.1.27a4.83,4.83,0,0,0-.19.85,8.93,8.93,0,0,0,.2,3.33,31.15,31.15,0,0,0,1.72,5,18.49,18.49,0,0,1,1.67,6.57,13.92,13.92,0,0,1-2.08,7.78c-1.55,2.51-4.09,4.51-6.57,6.86a19.4,19.4,0,0,0-3.38,4.1,10.19,10.19,0,0,0-1.58,5.4,8.44,8.44,0,0,0,2.32,5.35,30,30,0,0,0,4.47,3.82,22.59,22.59,0,0,1,4.18,3.83,14.85,14.85,0,0,1,2.54,4.79,12.4,12.4,0,0,1-1.1,9.91c-1.63,2.89-4.63,4.35-7,6.25a12,12,0,0,0-3,3.32,6.12,6.12,0,0,0-.8,4.1,6.47,6.47,0,0,0,4.56,5.17,6.25,6.25,0,0,0,7.14-3.81A3.42,3.42,0,0,0,183.4,219.9Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '177.42655038833618px 181.6016845703125px',
          }}
          id="elohzojpv0wd"
          className="animable"
        />
        <path
          d="M80.77,126.8c-3.19-.18-6.08-2.06-9.27-2.39-4.54-.47-8.78,2.26-12.19,5.3a30.75,30.75,0,0,0-8.1,10.58c-2.93,6.85-2.13,14.74-.14,21.92,1.78,6.45,4.42,13.61,1.24,19.5-1,1.85-2.51,3.38-3.69,5.13a17.16,17.16,0,0,0-2.29,14c1.26,4.55,4.36,8.39,5.82,12.87a19.09,19.09,0,0,1-.78,13.67c-1.09,2.39-2.67,4.55-3.68,7a16.57,16.57,0,0,0-.2,12.06c1.23,3.24,3.49,6.11,4.09,9.52,1,5.41-2.43,10.83-1.73,16.28,13.2,3.3,27.51,6.53,40.05,1.25a2.58,2.58,0,0,0,2-3.54A63.05,63.05,0,0,0,82.62,248c-3-4.44-6.68-9.14-6-14.43.94-7,9.44-12.28,7.82-19.16-1-4.23-5.49-6.63-7.62-10.42-2-3.52-1.73-7.88-.74-11.8s2.66-7.65,3.37-11.62c1-5.51.16-11.45,2.61-16.47"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '68.91618156433105px 200.3214874267578px',
          }}
          id="el0m1gxkwpn7d"
          className="animable"
        />
        <path
          d="M86.44,122.51c1,1,1.23,2.64,1.25,4.31a27.49,27.49,0,0,1-.6,5.36,26.7,26.7,0,0,1-1.5,5.18c-.65,1.53-1.45,2.95-2.8,3.5s-3-.07-4.42-2a10,10,0,0,1-1.53-3.76,13.07,13.07,0,0,1,1.89-9.46,9.82,9.82,0,0,1,2.85-2.89C83.61,121.42,85.45,121.52,86.44,122.51Z"
          style={{
            fill: '#95BD51',
            transformOrigin: '82.15846967697144px 131.4056568145752px',
          }}
          id="els723tmztib"
          className="animable"
        />
        <path
          d="M70.78,175.11c-1.84-5.23-.16-11.05,2.34-16s5.8-9.53,7.51-14.8c1.86-5.7,1.75-12,4-17.5a22,22,0,0,1,13.21-12.33c3.57-1.18,7.53-1.48,10.61-3.63s4.83-6,7.24-9c3.92-5,7.67-9.19,13.49-11.62,6.64-2.77,16.34-3.79,23-1.08a19.25,19.25,0,0,1,9.45,9.24,40.46,40.46,0,0,1,3.63,12.94c6.8,1.31,10.74,8.28,13.76,14.51,1.7,3.51,3.43,7.15,3.56,11,.15,4.34-3.58,19.24-5.68,23-10.87,19.64-27,23.77-46.68,34.64-7.58,4.2-12.27,13.84-20.93,13.5-10.09-.39-19.91-.78-25.76-9S71.09,183,70.23,173"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '126.29281997680664px 147.77498626708984px',
          }}
          id="elootd4slt9vo"
          className="animable"
        />
        <path
          d="M174.67,144c-.56-14.07-9.45-31.52-23.49-30.38l-44,10a8.24,8.24,0,0,0-7.57,8.18l2.17,105.5-.3.06s9.18,30,38.3,21.65c5.78-1.65,8.55-7.85,9.86-14.26l.29,0c0-.76.06-1.47.09-2.2a69.58,69.58,0,0,0,.55-14.47h0c.3-8.5.38-12,.38-11.91s18-2.56,22.29-25.88C175.41,178.78,175.28,159.67,174.67,144Z"
          style={{
            fill: 'rgb(153, 105, 100)',
            transformOrigin: '137.33389282226562px 187.0223617553711px',
          }}
          id="el15ibii692jt"
          className="animable"
        />
        <path
          d="M136.82,162.55a3,3,0,0,1-3,3,2.88,2.88,0,0,1-3-2.78,3,3,0,0,1,3-3A2.88,2.88,0,0,1,136.82,162.55Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '133.82000732421875px 162.65999794006348px',
          }}
          id="elpe5ed2aea5"
          className="animable"
        />
        <path
          d="M167.22,161.64a3,3,0,0,1-3,3,2.87,2.87,0,0,1-3-2.78,3,3,0,0,1,3-3A2.88,2.88,0,0,1,167.22,161.64Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '164.22000122070312px 161.75021290779114px',
          }}
          id="elvi6lpofokyk"
          className="animable"
        />
        <path
          d="M171.5,154.3c-.38.52-3-1.52-6.49-1.36s-6,2.31-6.4,1.82c-.2-.21.16-1.12,1.23-2.12a8,8,0,0,1,10.3-.37C171.27,153.19,171.69,154.07,171.5,154.3Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '165.05063104629517px 152.7013065814972px',
          }}
          id="elh9rqgjihlbi"
          className="animable"
        />
        <path
          d="M138.92,154.77c-.46.46-3-1.79-6.74-2s-6.61,1.63-7,1.11c-.19-.23.3-1.08,1.55-1.94a9.11,9.11,0,0,1,5.63-1.5,8.92,8.92,0,0,1,5.33,2.21C138.8,153.66,139.14,154.57,138.92,154.77Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '132.06184816360474px 152.62626838684082px',
          }}
          id="eldta5qq8crom"
          className="animable"
        />
        <path
          d="M149.67,178c0-.19,2-.49,5.27-.83.83-.06,1.61-.21,1.76-.77a4.17,4.17,0,0,0-.49-2.46q-1.1-3-2.29-6.34c-3.18-9-5.45-16.44-5.09-16.56s3.24,7.09,6.41,16.11c.76,2.23,1.49,4.35,2.19,6.38a4.78,4.78,0,0,1,.35,3.25,2.07,2.07,0,0,1-1.39,1.17,5.27,5.27,0,0,1-1.4.16C151.71,178.18,149.68,178.15,149.67,178Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '153.3557004928589px 164.59222221374512px',
          }}
          id="elxdotayq1kmk"
          className="animable"
        />
        <path
          d="M151,216.26a59.61,59.61,0,0,1-31.16-9.11s7.49,16.78,31,15.15Z"
          style={{
            fill: 'rgb(120, 77, 72)',
            transformOrigin: '135.41999435424805px 214.78074312210083px',
          }}
          id="elslo45kzfafh"
          className="animable"
        />
        <path
          d="M148.12,185.72a5.84,5.84,0,0,0-5.17-2.29,5.3,5.3,0,0,0-3.72,1.89,3.36,3.36,0,0,0-.35,3.84,3.89,3.89,0,0,0,4.21,1.17,12.11,12.11,0,0,0,4.19-2.36,3.54,3.54,0,0,0,.95-.94,1.07,1.07,0,0,0,0-1.2"
          style={{
            fill: 'rgb(120, 77, 72)',
            transformOrigin: '143.42474842071533px 186.9706792831421px',
          }}
          id="el1245wbp8gn9"
          className="animable"
        />
        <path
          d="M140.11,180c.52,0,.45,3.49,3.39,6.05s6.68,2.27,6.7,2.76c0,.22-.85.66-2.43.67a8.81,8.81,0,0,1-5.6-2.1,7.66,7.66,0,0,1-2.62-5.07C139.45,180.81,139.87,180,140.11,180Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '144.86743688583374px 184.73999786376953px',
          }}
          id="elyont7i6atdm"
          className="animable"
        />
        <path
          d="M139.71,143.82c-.34.87-3.55.37-7.35.74s-6.9,1.33-7.38.53c-.22-.38.33-1.2,1.59-2a12.87,12.87,0,0,1,11.23-1C139.18,142.69,139.86,143.4,139.71,143.82Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '132.33156061172485px 143.321879863739px',
          }}
          id="elsaiyqzylf2m"
          className="animable"
        />
        <path
          d="M169.31,144.49c-.67.72-3.19-.1-6.25-.21s-5.66.36-6.24-.42c-.25-.38.17-1.11,1.32-1.77a9.59,9.59,0,0,1,5.11-1.09,9.42,9.42,0,0,1,4.95,1.6C169.27,143.38,169.6,144.14,169.31,144.49Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '163.0803303718567px 142.88112723827362px',
          }}
          id="elvpy79z9j4lo"
          className="animable"
        />
        <path
          d="M97.47,126.07c2.48-3.09,6.51-4.4,10.33-5.45A215.31,215.31,0,0,1,158.32,113c1.75,3.67-.67,8.18-4.09,10.38s-7.58,2.78-11.51,3.81-8,2.83-10,6.4c-2.06,3.8-1.13,8.64-3,12.54-3.21,6.71-13.15,8-16.32,14.7-2.4,5.08-.84,3.92-.63,9.54.23,6.37-3.69,7.33-8.72,11.24-4,3.08-11.63,6.61-16.63,6.2.86-20.09,6.06-29.75,6.92-49.84C94.56,133.83,94.85,129.33,97.47,126.07Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '123.16216278076172px 150.42131423950195px',
          }}
          id="el86egzuc75f5"
          className="animable"
        />
        <path
          d="M125.56,94.12a1.92,1.92,0,0,1,.41-.3,11.09,11.09,0,0,1,1.27-.77,19.87,19.87,0,0,1,5.1-1.91,22.15,22.15,0,0,1,8.07-.37,20.59,20.59,0,0,1,15.78,10.92,21.93,21.93,0,0,1,2.5,7.68,19.58,19.58,0,0,1,0,5.45,12.48,12.48,0,0,1-.27,1.45,2,2,0,0,1-.14.5,26.17,26.17,0,0,0,0-7.35,22.48,22.48,0,0,0-2.56-7.5,20.49,20.49,0,0,0-15.43-10.68,22.74,22.74,0,0,0-7.92.25A26.73,26.73,0,0,0,125.56,94.12Z"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '142.22027969360352px 103.66536045074463px',
          }}
          id="elrwkwrrjftfc"
          className="animable"
        />
        <path
          d="M103.05,113.1a2.71,2.71,0,0,1,.68,0,9.71,9.71,0,0,0,1.91-.18,12,12,0,0,0,2.85-1,24.38,24.38,0,0,0,3.39-2.18c1.21-.89,2.47-2,3.88-3a25.43,25.43,0,0,1,4.79-3.08,26.39,26.39,0,0,1,12.71-2.48A25.76,25.76,0,0,1,145.63,105a26.46,26.46,0,0,1,7.64,7.35,25.22,25.22,0,0,1,3.22,6.39,17,17,0,0,1,.53,1.87,2.87,2.87,0,0,1,.12.67c-.06,0-.25-.9-.84-2.47a26.79,26.79,0,0,0-3.33-6.25,26.46,26.46,0,0,0-7.6-7.15,25.43,25.43,0,0,0-12.13-3.75,26.25,26.25,0,0,0-12.49,2.4,25.22,25.22,0,0,0-4.73,3c-1.41,1.07-2.69,2.11-3.92,3a23.81,23.81,0,0,1-3.48,2.14,11.27,11.27,0,0,1-3,1,8.09,8.09,0,0,1-2,.08A5.16,5.16,0,0,1,103.05,113.1Z"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '130.09500122070312px 111.2099027633667px',
          }}
          id="elufm7wqy0ee"
          className="animable"
        />
        <path
          d="M83.33,138.13a40.25,40.25,0,0,0,1.22,5.73,26,26,0,0,0,20,17.88,44.28,44.28,0,0,0,5.84.58,2.26,2.26,0,0,1-.41.06,10.58,10.58,0,0,1-1.17.06,23,23,0,0,1-4.32-.34A25.42,25.42,0,0,1,84.2,144a23.62,23.62,0,0,1-.82-4.26c-.06-.5-.06-.9-.07-1.17S83.31,138.13,83.33,138.13Z"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '96.84803867340088px 150.28643894195557px',
          }}
          id="el795jm7xqei3"
          className="animable"
        />
        <path
          d="M105.55,172.89a8.18,8.18,0,0,1-1.59-.25,41.84,41.84,0,0,1-4.24-1.09,32.26,32.26,0,0,1-12.57-7,20.94,20.94,0,0,1-6.49-12.72,15.51,15.51,0,0,1,.15-4.39,9.15,9.15,0,0,1,.29-1.16c.07-.26.12-.4.14-.39a20.68,20.68,0,0,0-.22,5.9,21.21,21.21,0,0,0,6.49,12.38,33.52,33.52,0,0,0,12.32,7c1.74.59,3.18,1,4.18,1.25A11,11,0,0,1,105.55,172.89Z"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '93.05988883972168px 159.38974571228027px',
          }}
          id="el9yhbg9sqql9"
          className="animable"
        />
        <path
          d="M179,109.36a28.26,28.26,0,0,0-7.66.13,28.31,28.31,0,0,0-7.19,2.7,7,7,0,0,1,1.87-1.33,17.69,17.69,0,0,1,5.22-1.88,18,18,0,0,1,5.54-.17A6.62,6.62,0,0,1,179,109.36Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '171.57498931884766px 110.43100273609161px',
          }}
          id="elgdt295q5mu6"
          className="animable"
        />
        <path
          d="M169.65,97.23a7.83,7.83,0,0,1,.17,2.46,21.27,21.27,0,0,1-1,5.86,21,21,0,0,1-2.61,5.35,7.56,7.56,0,0,1-1.58,1.89,35.8,35.8,0,0,0,3.69-7.4A35.66,35.66,0,0,0,169.65,97.23Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '167.24052834510803px 105.01000213623047px',
          }}
          id="elux1n7st129r"
          className="animable"
        />
        <path
          d="M72.56,210a27.88,27.88,0,0,0,3.43,1.9,15,15,0,0,0,17.18-3.68,29.25,29.25,0,0,0,2.34-3.13,3.42,3.42,0,0,1-.42,1,12.82,12.82,0,0,1-1.65,2.4,14.72,14.72,0,0,1-17.6,3.77,12.87,12.87,0,0,1-2.49-1.52A3.34,3.34,0,0,1,72.56,210Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '84.03499603271484px 209.3978819847107px',
          }}
          id="elzjs3g80j3w"
          className="animable"
        />
        <path
          d="M65.92,202.82a15.7,15.7,0,0,1,1.74.22,9.34,9.34,0,0,0,4.63-.31,4.39,4.39,0,0,0,2.26-2.09A3.62,3.62,0,0,0,75,199a2.55,2.55,0,0,0-.65-1.62,3.12,3.12,0,0,0-3.57-.53,3.91,3.91,0,0,0-2,3.5,5.68,5.68,0,0,0,1.64,3.85,8.67,8.67,0,0,0,3.37,2.2,10.31,10.31,0,0,0,6.8,0,9.8,9.8,0,0,0,4-2.45l.5-.54.33-.44c.19-.23.29-.35.31-.34a1.62,1.62,0,0,1-.22.4l-.29.47-.48.59a9.56,9.56,0,0,1-4,2.66,10.56,10.56,0,0,1-7.08.12,9.12,9.12,0,0,1-3.58-2.3,6.17,6.17,0,0,1-1.79-4.21,4.4,4.4,0,0,1,2.24-3.95,3.62,3.62,0,0,1,4.16.67,3,3,0,0,1,.74,1.93,4,4,0,0,1-.48,1.87,4.79,4.79,0,0,1-2.52,2.23,9,9,0,0,1-4.79.17c-.56-.1-1-.2-1.27-.28A1.38,1.38,0,0,1,65.92,202.82Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '75.82500076293945px 201.72694873809814px',
          }}
          id="elu5bti8r8wl"
          className="animable"
        />
        <path
          d="M105.76,163.6c-.36-.17-14.64-5-14.78,9.78s14.71,11.86,14.74,11.43S105.76,163.6,105.76,163.6Z"
          style={{
            fill: 'rgb(153, 105, 100)',
            transformOrigin: '98.36951017379761px 174.01947689056396px',
          }}
          id="elb1ig4ykx63c"
          className="animable"
        />
        <path
          d="M101.26,179.18c-.06-.05-.25.17-.69.37a2.55,2.55,0,0,1-1.92,0c-1.56-.59-2.85-3.08-2.9-5.74a8.56,8.56,0,0,1,.76-3.68,3,3,0,0,1,1.95-2,1.29,1.29,0,0,1,1.51.72c.2.41.1.71.18.73s.32-.24.21-.85a1.6,1.6,0,0,0-.58-.93,1.88,1.88,0,0,0-1.42-.39,3.6,3.6,0,0,0-2.71,2.26,8.92,8.92,0,0,0-.93,4.11c.07,3,1.57,5.77,3.7,6.41a2.73,2.73,0,0,0,2.35-.37C101.23,179.53,101.32,179.2,101.26,179.18Z"
          style={{
            fill: 'rgb(120, 77, 72)',
            transformOrigin: '97.99770140647888px 173.8503556251526px',
          }}
          id="ela759vbii49o"
          className="animable"
        />
        <path
          d="M93.34,181.6a3.48,3.48,0,0,1-.67.43c-.45.27-1.15.62-2,1.15a14.06,14.06,0,0,0-5.21,6.35,13.86,13.86,0,0,0,12.94,19.16,13.68,13.68,0,0,0,3.89-.6,12.93,12.93,0,0,0,3.47-1.61,13.86,13.86,0,0,0,4.81-5.31,13.94,13.94,0,0,0-5-18.15,26.6,26.6,0,0,0-2.72-1.42,10.51,10.51,0,0,1,2.83,1.25,14,14,0,0,1,5.57,6.27,14.24,14.24,0,0,1-5.26,17.79,13.24,13.24,0,0,1-3.59,1.67,13.91,13.91,0,0,1-4,.63,14.2,14.2,0,0,1-12.74-7.56A14.12,14.12,0,0,1,90.58,183c.86-.52,1.57-.83,2-1.07A6.25,6.25,0,0,1,93.34,181.6Z"
          style={{
            fill: 'rgb(255, 255, 255)',
            transformOrigin: '98.23542213439941px 195.40561294555664px',
          }}
          id="ele57pzzuvbmu"
          className="animable"
        />
        <path
          d="M82.14,136.55a4,4,0,0,1,0,.73,15.86,15.86,0,0,1-.2,2.06,29.17,29.17,0,0,1-2.18,7.29c-1.25,3-3.11,6.33-5.06,10.08a60.82,60.82,0,0,0-2.77,6,28.26,28.26,0,0,0-1.81,6.83,25.16,25.16,0,0,0,2,13.34,27.45,27.45,0,0,0,3,5.13c1.12,1.52,2.2,2.9,3.2,4.15,2,2.5,3.7,4.47,4.88,5.82l1.36,1.55a5.86,5.86,0,0,1,.45.56s-.2-.15-.53-.49-.82-.83-1.43-1.49c-1.23-1.31-3-3.24-5-5.72-1-1.24-2.13-2.61-3.26-4.12a27.3,27.3,0,0,1-3.14-5.19,25.44,25.44,0,0,1-2.08-13.61,28.81,28.81,0,0,1,1.85-6.94,63.12,63.12,0,0,1,2.82-6.06c2-3.76,3.87-7.07,5.16-10a31.83,31.83,0,0,0,2.31-7.19,19.36,19.36,0,0,0,.29-2A3.61,3.61,0,0,1,82.14,136.55Z"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '77.19489622116089px 168.31999969482422px',
          }}
          id="el7oupwk2q5kt"
          className="animable"
        />
        <path
          d="M36,220.37s0-.12-.15-.33a1.49,1.49,0,0,0-.82-.64,2.29,2.29,0,0,0-1.67.1,2.63,2.63,0,0,0-1.29,1.87,7.76,7.76,0,0,0,0,3,6,6,0,0,0,1.39,3.18,4.07,4.07,0,0,0,3.67,1.14A6,6,0,0,0,41,226.4a8.66,8.66,0,0,0,1.55-4.68,20.92,20.92,0,0,0-.51-5.44c-.75-3.75-2.17-7.59-3.37-11.74s-2.07-8.73-1.23-13.44a15,15,0,0,1,2.9-6.59c1.52-1.92,3.4-3.54,4.48-5.75a17.39,17.39,0,0,0,1.76-7,33.81,33.81,0,0,0-.41-7.06c-.72-4.61-1.86-9-1.94-13.39a25.51,25.51,0,0,1,9.36-20.24,25,25,0,0,1,7.72-4.22,25.3,25.3,0,0,1,6.52-1.29,24.08,24.08,0,0,1,4.2.09c.48,0,.84.12,1.08.16a2.41,2.41,0,0,1,.37.07,2.16,2.16,0,0,1-.38,0L72,125.77a27.38,27.38,0,0,0-4.18,0,25.94,25.94,0,0,0-6.44,1.36,25,25,0,0,0-16.69,24.17c.09,4.29,1.23,8.69,2,13.33a33.41,33.41,0,0,1,.43,7.17A18.11,18.11,0,0,1,45.24,179c-1.13,2.31-3.07,4-4.54,5.83a14.55,14.55,0,0,0-2.8,6.36c-.82,4.58,0,9.13,1.19,13.22s2.59,8,3.33,11.78a21.2,21.2,0,0,1,.49,5.56,9,9,0,0,1-1.66,4.87,6.27,6.27,0,0,1-4,2.35,4.35,4.35,0,0,1-3.92-1.26,6.32,6.32,0,0,1-1.43-3.34,8.18,8.18,0,0,1,0-3.05,2.82,2.82,0,0,1,1.42-2,2.35,2.35,0,0,1,1.78-.06,1.42,1.42,0,0,1,.82.71A.94.94,0,0,1,36,220.37Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '52.618295669555664px 177.27481079101562px',
          }}
          id="elgnfna81gv9l"
          className="animable"
        />
        <path
          d="M105.91,235.66s19.29,9.74,29,8c12.15-2.17,15.2-7.4,15.2-7.4L155,247.37l35,227.11H75.11L99.4,241.82Z"
          style={{
            fill: '#95BD51',
            transformOrigin: '132.55500030517578px 355.0699920654297px',
          }}
          id="eljoxve6pv9s"
          className="animable"
        />
        <path
          d="M140,297.2s-6.43-33-32.84-58.88L99.25,216s-9.85,2.05-11.34,6.52a65.47,65.47,0,0,0-2.23,10.78s-34.59,6.7-39.8,13.39S68.94,375.76,68.94,375.76,48,424.13,45.73,440.87a276.68,276.68,0,0,0-2.66,33.61h98.85c0-10.41,4-101,4-101Z"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '94.49499893188477px 345.239990234375px',
          }}
          id="elqyeoshga04p"
          className="animable"
        />
        <g id="elmqd16rkq8c">
          <g
            style={{
              opacity: '0.30000000000000004',
              transformOrigin: '104.73500442504883px 247.75498962402344px',
            }}
            className="animable"
            id="elme5j67xz7qb"
          >
            <path
              d="M91.46,248.76a18.74,18.74,0,0,0,4.89,8.35,8,8,0,0,1,3.87-7.83,13.11,13.11,0,0,1,9-1.43,39.37,39.37,0,0,1,8.79,2.89,80.9,80.9,0,0,0-8.4-9.84c-1.18-1.22-1.41-1.92-3-2.5a76.12,76.12,0,0,0-7.16,3.42,31.54,31.54,0,0,0-7.94,6.94"
              id="elmuncq468uz"
              style={{
                transformOrigin: '104.73500442504883px 247.75498962402344px',
              }}
              className="animable"
            />
          </g>
        </g>
        <path
          d="M107.12,238.32a45.59,45.59,0,0,0-5.54,2.17,26.43,26.43,0,0,0-5.31,3.59A30.93,30.93,0,0,0,91.13,250c-.32.47-.62,1-.91,1.4l-.23.36-.21-.36a29.37,29.37,0,0,1-4.06-12.64,26.79,26.79,0,0,1,0-4c0-.47.08-.83.11-1.07s.06-.38.08-.37a10.72,10.72,0,0,1,0,1.45,35.18,35.18,0,0,0,.19,3.94,30.64,30.64,0,0,0,4.15,12.41h-.44c.29-.45.59-.94.92-1.43a30.16,30.16,0,0,1,5.26-6,24.63,24.63,0,0,1,5.46-3.56,24.12,24.12,0,0,1,4.12-1.52c.5-.13.9-.21,1.16-.26A2.07,2.07,0,0,1,107.12,238.32Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '96.38261222839355px 242.53974151611328px',
          }}
          id="el224hcopo72t"
          className="animable"
        />
        <path
          d="M131.8,320.9a5.15,5.15,0,0,1-.16-.86q-.13-.92-.36-2.49c-.08-.54-.16-1.14-.26-1.79s-.26-1.37-.4-2.13c-.29-1.52-.6-3.25-1.08-5.15a182.54,182.54,0,0,0-9.53-28.9c-2.29-5.5-4.67-10.65-7-15.24s-4.62-8.65-6.61-12-3.77-6-5-7.74l-1.47-2a7.58,7.58,0,0,1-.49-.73,3.84,3.84,0,0,1,.57.66l1.55,2c1.3,1.75,3.11,4.35,5.18,7.67s4.38,7.37,6.72,12,4.78,9.75,7.07,15.26a170.81,170.81,0,0,1,9.41,29c.46,1.9.74,3.65,1,5.18.13.77.26,1.48.37,2.14s.14,1.26.21,1.81c.11,1.05.2,1.88.26,2.5A4.73,4.73,0,0,1,131.8,320.9Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '115.6244068145752px 281.385009765625px',
          }}
          id="elsmvhk000uv"
          className="animable"
        />
        <path
          d="M94.59,403.42s-.08.14-.28.39l-.88,1.06-3.27,3.86c-2.76,3.27-6.5,7.83-10.48,13s-7.4,10-9.85,13.48c-1.18,1.71-2.16,3.11-2.89,4.16l-.79,1.13a2,2,0,0,1-.31.37s.06-.16.22-.43l.71-1.18c.63-1,1.57-2.47,2.76-4.25,2.37-3.57,5.76-8.41,9.74-13.59s7.78-9.72,10.61-12.93c1.41-1.6,2.58-2.89,3.4-3.76l1-1C94.46,403.52,94.58,403.4,94.59,403.42Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '80.21500396728516px 422.14393615722656px',
          }}
          id="elqdconvoir6h"
          className="animable"
        />
        <path
          d="M104,412.7a1.82,1.82,0,0,1-.32.47l-1,1.27c-.86,1.1-2.14,2.67-3.75,4.57C95.68,422.82,91,428,85.62,433.3s-10.72,10-14.57,13.18l-.13.1-.11-.11-4.21-4-1.12-1.11c-.26-.26-.38-.4-.37-.42a3.18,3.18,0,0,1,.44.34l1.19,1,4.32,3.91h-.25c3.79-3.25,9-7.87,14.44-13.27s10.08-10.41,13.38-14.16l3.88-4.46,1-1.21A2.88,2.88,0,0,1,104,412.7Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '84.55471611022949px 429.63999938964844px',
          }}
          id="elxanmmsov799"
          className="animable"
        />
        <path
          d="M103.52,412.32a41.67,41.67,0,0,1-4.68-4.74,43.42,43.42,0,0,1-4.29-5.09,43.82,43.82,0,0,1,4.68,4.74A43.42,43.42,0,0,1,103.52,412.32Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '99.03499603271484px 407.40501403808594px',
          }}
          id="eld80g5vl9ouo"
          className="animable"
        />
        <g id="elrinjdt6kc4q">
          <g
            style={{
              opacity: '0.30000000000000004',
              transformOrigin: '100.88875579833984px 380.0185852050781px',
            }}
            className="animable"
            id="elb17g3hoze44"
          >
            <path
              d="M143.26,353.68c-1.09-1.62-3.32-1.93-5.27-1.92-8,.05-15.73,3-22.57,7.09s-12.87,9.42-18.87,14.69c-1.93,1.69-4,3.87-3.6,6.4.3,1.73,1.59.35,2.13,2,.64,2,3.24,7.29,3.24,7.29-1.32,1.58-8.12,7.87-9.95,8.79-10,5-19.71,6.83-29.2,2.35L58,402.49c4.4,5.75,9.91,6,17.14,5.71s14.11-3.26,20.37-6.88c4.22-2.43,8.64-5.82,9.13-10.66.22-2.15-.4-4.35,0-6.48.86-4.79,6.06-7.21,10.36-9.5a53.66,53.66,0,0,0,17.77-15.28c2.53.68,5.24,1.35,7.7.47S144.7,355.86,143.26,353.68Z"
              id="ell1lwdwrh9sj"
              style={{
                transformOrigin: '100.88875579833984px 380.0185852050781px',
              }}
              className="animable"
            />
          </g>
        </g>
        <path
          d="M141.29,314.18a4.87,4.87,0,0,1-.12-.86c-.06-.61-.14-1.43-.24-2.47-.16-2.15-.56-5.24-1.1-9.05A119.29,119.29,0,0,0,137,288.6c-.62-2.46-1.52-5-2.39-7.6L133,277.06c-.51-1.33-1.15-2.64-1.73-4a112.46,112.46,0,0,0-8-14.48c-1.45-2.09-2.76-4.12-4.15-5.91l-2-2.62c-.66-.81-1.3-1.59-1.9-2.34-1.19-1.52-2.36-2.81-3.36-4s-1.87-2.14-2.61-2.93L107.67,239a4.86,4.86,0,0,1-.55-.67,6.9,6.9,0,0,1,.63.6l1.74,1.77c.76.77,1.67,1.72,2.68,2.88s2.21,2.42,3.42,3.92l1.93,2.33,2,2.61c1.42,1.78,2.75,3.81,4.21,5.91a108.34,108.34,0,0,1,8,14.52c.59,1.35,1.23,2.66,1.74,4s1,2.66,1.52,3.95c.87,2.64,1.76,5.18,2.38,7.66a113.26,113.26,0,0,1,2.77,13.27c.5,3.83.85,6.93.94,9.09.06,1,.11,1.86.14,2.48A5.09,5.09,0,0,1,141.29,314.18Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '124.20501136779785px 276.25499725341797px',
          }}
          id="elsz7r71r1kcq"
          className="animable"
        />
        <path
          d="M129.28,474.48a1,1,0,0,1,0-.31q0-.35.09-.9c.1-.83.24-2,.41-3.46s.42-3.35.65-5.48.53-4.57.78-7.27c.56-5.38,1.11-11.8,1.61-18.94s.86-15,1.08-23.26.18-16.13.07-23.28-.38-13.6-.67-19c-.12-2.71-.3-5.15-.42-7.3s-.26-4-.38-5.5-.18-2.65-.23-3.48c0-.37,0-.67-.05-.91a1.27,1.27,0,0,1,0-.31,2.1,2.1,0,0,1,.05.31c0,.23.06.53.1.9.08.83.19,2,.34,3.47s.31,3.36.46,5.5.36,4.59.5,7.3c.34,5.4.61,11.85.78,19s.16,15,0,23.31-.63,16.13-1.12,23.27-1.12,13.57-1.72,19c-.28,2.7-.6,5.13-.86,7.26s-.52,4-.74,5.47-.38,2.63-.5,3.45l-.15.9A2.4,2.4,0,0,1,129.28,474.48Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '131.92085242271423px 414.7799987792969px',
          }}
          id="el927iw2aubo8"
          className="animable"
        />
        <path
          d="M174.54,230.63s9.74-1.12,20.19,13.06S217.45,303,218,319.82s5.06,43.39-6.89,51.19-19.78,7.28-26.13,3.24-10-17.46-10-17.46Z"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '196.9137420654297px 303.86934661865234px',
          }}
          id="elcewyojnv5xm"
          className="animable"
        />
        <path
          d="M168,474.48c-10.57-23.89-13.56-41.87-16-71.57s-8.47-92.4-9.8-93.11c-1-.51-2.92-22.19-3.89-33.73a28.15,28.15,0,0,1,2.23-13.59c2.21-5.12,5-11.44,5.38-11.82.64-.63,4.18-14.4,4.18-14.4l.45-17.74L160.92,228l13.62,2.62,13.06,146.5s17.5,44.6,20.53,59.53.94,37.82.94,37.82"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '173.99102401733398px 346.49999237060547px',
          }}
          id="elhkgw1q7k86i"
          className="animable"
        />
        <g id="el2oy524a2dom">
          <g
            style={{
              opacity: '0.30000000000000004',
              transformOrigin: '165.96357536315918px 355.83867263793945px',
            }}
            className="animable"
            id="eluh15wflwe"
          >
            <path
              d="M183.47,361.35c-2.95-4-6-8.12-10.05-11S164,346,159.28,347.69c-4.38,1.62-7.43,5.71-11.7,7.6l.57,4.85c2.44,2.54,5.86,2.92,9.27,2,3.15-.82,6-3.19,9.21-2.92,3.07.25,5.55,2.83,8.61,3.18,1.44.17,3-.16,4.3.46.78.38,1.38,1.05,2.13,1.49a1.93,1.93,0,0,0,2.39-.13C184.73,363.41,184.1,362.21,183.47,361.35Z"
              id="elc4p4l6h40b8"
              style={{
                transformOrigin: '165.96357536315918px 355.83867263793945px',
              }}
              className="animable"
            />
          </g>
        </g>
        <path
          d="M161,228s.12.13.32.4.49.66.84,1.2a52.13,52.13,0,0,1,2.8,4.73,55.82,55.82,0,0,1,5.78,18.16l.07.47-.42-.22A28.36,28.36,0,0,0,149.63,251l.17-.32c.9,2.7,1.77,5.29,2.58,7.75l.08.21-.21.08a20,20,0,0,0-7.69,5.57,20.93,20.93,0,0,0-3.79,6.42,20.28,20.28,0,0,0-1.15,4.75c-.11,1.12-.14,1.74-.17,1.74a1.54,1.54,0,0,1,0-.45c0-.3,0-.74,0-1.31a19.17,19.17,0,0,1,1-4.82,20.63,20.63,0,0,1,3.76-6.59,20.14,20.14,0,0,1,7.81-5.75l-.14.3c-.82-2.45-1.7-5-2.61-7.74l-.09-.25.25-.07a28.13,28.13,0,0,1,8-1.24,28.54,28.54,0,0,1,13.2,3.05l-.35.25a57.48,57.48,0,0,0-5.57-18.09c-1.05-2.1-2-3.7-2.67-4.79l-.77-1.24A2.85,2.85,0,0,1,161,228Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '155.12174034118652px 252.59999084472656px',
          }}
          id="elna9wefigjwo"
          className="animable"
        />
        <path
          d="M155.4,297.46a4.65,4.65,0,0,0-5.29-3l-1.19.46a4.48,4.48,0,1,0,6.48,2.59Z"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '151.11719179153442px 298.8373866081238px',
          }}
          id="el5ewvb9chjqn"
          className="animable"
        />
        <path
          d="M155.4,297.46s.06.08.12.26a3.46,3.46,0,0,1,.16.81,4.7,4.7,0,0,1-.81,2.93,4.61,4.61,0,0,1-1.56,1.47,4.34,4.34,0,0,1-2.42.52,5,5,0,0,1-2.57-.91,4.4,4.4,0,0,1-1.72-2.37,4.89,4.89,0,0,1,2.2-5.5h0l1.2-.46h0a4.54,4.54,0,0,1,2.8.31,5,5,0,0,1,1.73,1.29,4.1,4.1,0,0,1,.7,1.18c.11.3.13.47.11.48a7.12,7.12,0,0,0-1-1.49,4.83,4.83,0,0,0-1.68-1.12,4.21,4.21,0,0,0-2.56-.22h0l-1.19.47h0A4.47,4.47,0,0,0,147.1,300a4.33,4.33,0,0,0,3.83,2.94,4,4,0,0,0,3.64-1.72,4.69,4.69,0,0,0,.9-2.71C155.48,297.85,155.36,297.47,155.4,297.46Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '151.05529356002808px 298.7888717651367px',
          }}
          id="elwwgli5aka7"
          className="animable"
        />
        <path
          d="M162.35,370.33a4.64,4.64,0,0,0-5.3-3l-1.19.46a4.48,4.48,0,1,0,6.49,2.58Z"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '158.06835556030273px 371.70251846313477px',
          }}
          id="el9u2crolkciu"
          className="animable"
        />
        <path
          d="M162.35,370.33s.06.09.12.26a3.17,3.17,0,0,1,.15.82,4.71,4.71,0,0,1-.8,2.93,4.63,4.63,0,0,1-1.57,1.46,4.21,4.21,0,0,1-2.41.53,5,5,0,0,1-2.57-.91,4.38,4.38,0,0,1-1.72-2.37,4.89,4.89,0,0,1,2.2-5.5h0l1.19-.45h0a4.52,4.52,0,0,1,2.8.32,4.73,4.73,0,0,1,1.73,1.28,4,4,0,0,1,.71,1.19,1.22,1.22,0,0,1,.11.47,7.64,7.64,0,0,0-1-1.48,4.53,4.53,0,0,0-1.68-1.13,4.27,4.27,0,0,0-2.56-.22h0L156,368l0,0a4.46,4.46,0,0,0-1.93,4.95,4.32,4.32,0,0,0,3.82,3,4,4,0,0,0,3.64-1.72,4.71,4.71,0,0,0,.91-2.71C162.42,370.72,162.3,370.34,162.35,370.33Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '158.00060319900513px 371.67540216445923px',
          }}
          id="elmxtjrxrprm8"
          className="animable"
        />
        <path
          d="M167,419.47a4.63,4.63,0,0,0-5.29-3l-1.19.46a4.48,4.48,0,1,0,6.48,2.58Z"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '162.71983432769775px 420.845094203949px',
          }}
          id="el5o5hhd1mpew"
          className="animable"
        />
        <path
          d="M167,419.47s.06.09.12.26a3.57,3.57,0,0,1,.16.82,4.73,4.73,0,0,1-.81,2.93,4.59,4.59,0,0,1-1.56,1.46,4.26,4.26,0,0,1-2.42.53,5,5,0,0,1-2.57-.91,4.43,4.43,0,0,1-1.72-2.37,4.89,4.89,0,0,1,2.2-5.5h0l1.2-.45h0a4.53,4.53,0,0,1,2.8.32,4.82,4.82,0,0,1,1.73,1.28,4.13,4.13,0,0,1,.7,1.19c.11.3.13.47.11.47a7.25,7.25,0,0,0-1-1.48,4.71,4.71,0,0,0-1.68-1.13,4.28,4.28,0,0,0-2.56-.22h0l-1.19.48h0a4.45,4.45,0,0,0-1.93,4.94,4.32,4.32,0,0,0,3.83,2.95,4,4,0,0,0,3.64-1.72,4.69,4.69,0,0,0,.9-2.71C167.12,419.86,167,419.48,167,419.47Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '162.65500831604004px 420.8154535293579px',
          }}
          id="ellqpk3tv7zd"
          className="animable"
        />
        <path
          d="M195.37,307.11a9.08,9.08,0,0,1-.13-1.61c-.06-1-.16-2.52-.37-4.35a82.64,82.64,0,0,0-3-14.1,108,108,0,0,0-5-13.51c-.76-1.68-1.39-3-1.85-4a10.1,10.1,0,0,1-.64-1.48,11.31,11.31,0,0,1,.83,1.39c.51.9,1.18,2.24,2,3.9a94,94,0,0,1,5.18,13.53,72.13,72.13,0,0,1,2.81,14.2c.17,1.85.22,3.35.21,4.39A10.2,10.2,0,0,1,195.37,307.11Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '189.90301513671875px 287.58497619628906px',
          }}
          id="elrwfblhjpgd"
          className="animable"
        />
        <g id="el2b5bcq2a4s7">
          <g
            style={{
              opacity: '0.30000000000000004',
              transformOrigin: '153.931884765625px 265.5587902069092px',
            }}
            className="animable"
            id="elurymgf534xd"
          >
            <path
              d="M138.82,281.76c1.68-9.34,11.94-16.81,21.43-16.75-.32-1.84-.65-3.68-1-5.52l9.42,3.8c1.52-4.62-1-9.92-5.13-12.52s-9.28-1.14-14,0c.75,3.19,1.84,4.55,2.6,7.74-8.42,2-14,14.85-13.34,23.29"
              id="eladzx3xsr5b8"
              style={{
                transformOrigin: '153.931884765625px 265.5587902069092px',
              }}
              className="animable"
            />
          </g>
        </g>
        <path
          d="M204.08,422.39a2.28,2.28,0,0,1-.26-.58c-.17-.43-.38-1-.64-1.69l-2.27-6.24-7.3-20.68c-2.85-8.07-5.5-15.36-7.47-20.61-1-2.58-1.75-4.7-2.33-6.23l-.62-1.69a3.07,3.07,0,0,1-.18-.61,2.74,2.74,0,0,1,.28.57c.19.43.42,1,.71,1.66.61,1.45,1.46,3.55,2.49,6.16,2,5.23,4.76,12.5,7.61,20.58s5.36,15.41,7.16,20.72c.87,2.62,1.59,4.76,2.11,6.31.22.7.41,1.27.55,1.72A3.78,3.78,0,0,1,204.08,422.39Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '193.54501342773438px 393.2250213623047px',
          }}
          id="elasgpwmtm9zq"
          className="animable"
        />
        <path
          d="M190.87,474.48a5.63,5.63,0,0,1-.39-.71c-.25-.52-.59-1.21-1-2.08-.89-1.81-2.12-4.45-3.59-7.74-3-6.57-6.79-15.77-10.42-26.15s-6.35-20-8.13-27c-.89-3.49-1.57-6.32-2-8.29-.21-.94-.38-1.7-.5-2.26a5.84,5.84,0,0,1-.14-.8,4.09,4.09,0,0,1,.24.77c.15.56.35,1.31.59,2.24.5,2,1.23,4.78,2.17,8.25,1.87,6.95,4.64,16.51,8.26,26.88s7.4,19.57,10.26,26.17c1.44,3.3,2.62,6,3.44,7.8.4.89.71,1.6.94,2.13A3.67,3.67,0,0,1,190.87,474.48Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '177.7850112915039px 436.96502685546875px',
          }}
          id="elk4k87fib7l"
          className="animable"
        />
        <path
          d="M45.88,246.69s-14.26,11-17.15,35.12S31.26,337,31.26,337l1.8,21.28s-2.16,11.53,2.16,19.82,0,13.71,13,17.67,12.62,9,28.12,6.49,22-13,22-13L81.37,351s-.72-4.5-9-.72L68,294.79S53.29,249.07,45.88,246.69Z"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '63.110782623291016px 324.81126403808594px',
          }}
          id="elk8fvzlptrwk"
          className="animable"
        />
        <path
          d="M68,294.79s0,.33.11,1,.14,1.63.25,2.89c.21,2.55.52,6.29.92,11.08.77,9.63,1.88,23.5,3.24,40.56l-.27-.16a17.24,17.24,0,0,1,4.8-1.49,5.57,5.57,0,0,1,2.67.28A3,3,0,0,1,81.58,351l0-.05,17,38.2.06.13-.08.1c-4.92,7-13.19,11.63-22.19,13.11a48.6,48.6,0,0,1-6.86.66,20.87,20.87,0,0,1-6.94-1,57.52,57.52,0,0,1-6.42-3,52.21,52.21,0,0,0-6.55-2.72,51.43,51.43,0,0,1-5.56-2,12.23,12.23,0,0,1-4.64-3.64,14.83,14.83,0,0,1-2-5.47,29.84,29.84,0,0,0-1.56-5.44,42.94,42.94,0,0,1-2.2-5.22,29.2,29.2,0,0,1-1.09-5.51,42.13,42.13,0,0,1,.33-10.95v.07q-.93-11-1.79-21.28v0a254.58,254.58,0,0,1-3.31-38.5,136.12,136.12,0,0,1,.81-16.25,67.11,67.11,0,0,1,3-13.54,54.79,54.79,0,0,1,4.66-10.23A51.2,51.2,0,0,1,41,251.63a44.34,44.34,0,0,1,3.55-3.79c.44-.39.77-.69,1-.87a3.12,3.12,0,0,1,.36-.28l-.34.31c-.22.19-.55.5-1,.9a46.16,46.16,0,0,0-3.48,3.83,50.74,50.74,0,0,0-4.68,6.87,55.63,55.63,0,0,0-4.58,10.21,67.57,67.57,0,0,0-3,13.49,138,138,0,0,0-.76,16.21,258.19,258.19,0,0,0,3.39,38.42v0h0q.87,10.24,1.82,21.27v.07A41.49,41.49,0,0,0,33,369.1a28,28,0,0,0,1.07,5.41,43.23,43.23,0,0,0,2.19,5.17,32.22,32.22,0,0,1,1.59,5.52,14.28,14.28,0,0,0,1.94,5.29A11.74,11.74,0,0,0,44.23,394a53.35,53.35,0,0,0,5.49,2,51.26,51.26,0,0,1,6.62,2.75,59.62,59.62,0,0,0,6.36,2.95c4.4,1.5,9.12,1,13.55.31a36.51,36.51,0,0,0,12.45-4.42,31.19,31.19,0,0,0,5.21-3.77,25.33,25.33,0,0,0,4.2-4.67l0,.23L81.18,351.12v-.05a2.56,2.56,0,0,0-1.57-1.76,5.26,5.26,0,0,0-2.46-.26,17.3,17.3,0,0,0-4.69,1.44l-.26.11,0-.27c-1.29-17.07-2.34-30.94-3.07-40.57-.35-4.8-.63-8.54-.81-11.09-.09-1.26-.16-2.22-.2-2.89S68,294.79,68,294.79Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '63.20659828186035px 324.9234619140625px',
          }}
          id="elgppt6kyoczp"
          className="animable"
        />
        <path
          d="M47.36,371a5.64,5.64,0,0,1,.24-1.36,22.11,22.11,0,0,1,1.19-3.55,25.15,25.15,0,0,1,7.49-9.77,28.5,28.5,0,0,1,11-5.62,17.63,17.63,0,0,1,3.72-.48,6.87,6.87,0,0,1,1.38.08,31.28,31.28,0,0,0-5,.76,30,30,0,0,0-10.73,5.67,26.14,26.14,0,0,0-7.48,9.51C47.82,369.1,47.46,371,47.36,371Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '59.87000274658203px 360.6068572998047px',
          }}
          id="el6l95josskm6"
          className="animable"
        />
        <path
          d="M79.25,397.48a2,2,0,0,1-.53-.23,16.49,16.49,0,0,1-1.46-.84,23.6,23.6,0,0,1-4.69-4,33.13,33.13,0,0,1-5.1-7.6,67.69,67.69,0,0,1-4-10.46c-1.16-3.77-2.22-7.51-2-11a11.69,11.69,0,0,1,1.32-4.76,14.58,14.58,0,0,1,2.7-3.33,35.13,35.13,0,0,1,4.9-3.75c.61-.4,1.09-.7,1.42-.89a2.69,2.69,0,0,1,.52-.28,2.42,2.42,0,0,1-.46.37l-1.37,1a40.53,40.53,0,0,0-4.75,3.85,14.47,14.47,0,0,0-2.58,3.28A11.41,11.41,0,0,0,62,363.35c-.21,3.4.82,7.07,2,10.84a71.25,71.25,0,0,0,3.93,10.4,33.66,33.66,0,0,0,4.95,7.56,25.31,25.31,0,0,0,4.52,4.09C78.56,397.07,79.28,397.44,79.25,397.48Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '70.34587478637695px 373.9100036621094px',
          }}
          id="elizji4fmosm"
          className="animable"
        />
        <path
          d="M43.65,262.79a7.44,7.44,0,0,1,1.07,1c.67.64,1.65,1.54,2.9,2.61,2.47,2.18,6.14,4.9,9.87,8.33A32.47,32.47,0,0,1,65.29,285a18.39,18.39,0,0,1,1.21,3.74,8,8,0,0,1,.17,1.06c0,.24,0,.37,0,.37a30.24,30.24,0,0,0-1.74-5,34.15,34.15,0,0,0-7.82-10.06c-3.69-3.4-7.33-6.17-9.76-8.43-1.22-1.12-2.17-2.06-2.79-2.75A7.59,7.59,0,0,1,43.65,262.79Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '55.15999794006348px 276.47999572753906px',
          }}
          id="el1p05k5lvado"
          className="animable"
        />
        <g id="elex0xe8zqrif">
          <g
            style={{
              opacity: '0.30000000000000004',
              transformOrigin: '68.2470498085022px 382.04224395751953px',
            }}
            className="animable"
            id="eli31unrp9nt"
          >
            <path
              d="M74.38,394.2A30.34,30.34,0,0,1,66.3,382a85.4,85.4,0,0,1-4.38-13.62l-.21.09c-1.27,6.08,1.36,11.86,3.38,17.75A20.1,20.1,0,0,0,68.4,393c1.64,1.92,4.27,3.2,6.7,2.52C75.21,395,74.77,394.53,74.38,394.2Z"
              id="elf5gz7lb4w2c"
              style={{
                transformOrigin: '68.2470498085022px 382.04224395751953px',
              }}
              className="animable"
            />
          </g>
        </g>
        <path
          d="M76.1,354.88l51.07-30.06,3.3,30L88.12,388.12s-6.63-9.69-8.84-17A106.13,106.13,0,0,1,76.1,354.88Z"
          style={{
            fill: 'rgb(153, 105, 100)',
            transformOrigin: '103.28499984741211px 356.4700012207031px',
          }}
          id="elhrgjjkpg8ak"
          className="animable"
        />
        <path
          d="M158.89,325l21-16.94a4.16,4.16,0,0,1,2.62-.92l29.35.07a2.23,2.23,0,0,1,2.23,2.11,4,4,0,0,1-1,3.2l-44.6,36.78-35.73-2.67Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '173.44176864624023px 328.2199935913086px',
          }}
          id="elypnhm91e8xi"
          className="animable"
        />
        <path
          d="M127.17,324.82s11.29-9.91,14.07-10.64,15.5-1.72,15.5-1.72l11.19,5.22-6.24,5c-4.68-.63-6.15-2.31-6.15-2.31l-7.35,1.11Z"
          style={{
            fill: 'rgb(153, 105, 100)',
            transformOrigin: '147.54999542236328px 318.63999938964844px',
          }}
          id="ellg21jn8nlv"
          className="animable"
        />
        <path
          d="M140.36,319.82l19.58,4.95,27.63,1.59s.63,5.52-4.93,6.59c-3.27.62-9,1.5-9,1.5s-1,5.14-5.12,6.61c0,0-1.56,3.7-3.72,5.2a3.88,3.88,0,0,1-.8.43c-2.57,1-7.2,8.53-10.62,9.78s-22.91-1.66-22.91-1.66l-3.3-30Z"
          style={{
            fill: 'rgb(153, 105, 100)',
            transformOrigin: '157.38076210021973px 338.300989151001px',
          }}
          id="elhvnh3b71t4e"
          className="animable"
        />
        <path
          d="M147.27,334.88c0-.09,1.53.19,4,.47a73.14,73.14,0,0,0,9.66.46,71.33,71.33,0,0,0,9.63-.82c2.45-.38,3.95-.72,4-.64a4,4,0,0,1-1,.35c-.67.19-1.65.42-2.87.66a57.68,57.68,0,0,1-9.69,1,58.73,58.73,0,0,1-9.72-.61c-1.23-.19-2.22-.38-2.9-.54A4.53,4.53,0,0,1,147.27,334.88Z"
          style={{
            fill: 'rgb(120, 77, 72)',
            transformOrigin: '160.91500854492188px 335.3551459312439px',
          }}
          id="elbbmu1wcglra"
          className="animable"
        />
        <path
          d="M151.58,342.36a4.21,4.21,0,0,1,0,1.89,3.88,3.88,0,0,1-.87,1.93,3.32,3.32,0,0,1-2.39,1,6.22,6.22,0,0,1-2.52-.5c-.72-.3-1.33-.61-1.85-.85-1-.5-1.71-.67-1.7-.75a4,4,0,0,1,1.85.41,11.43,11.43,0,0,0,4.2,1.17,2.91,2.91,0,0,0,2.06-.83,3.65,3.65,0,0,0,.86-1.68A17.61,17.61,0,0,1,151.58,342.36Z"
          style={{
            fill: 'rgb(120, 77, 72)',
            transformOrigin: '146.96867275238037px 344.7702329158783px',
          }}
          id="elwa8l2a0428o"
          className="animable"
        />
        <path
          d="M165.77,343.06a7.65,7.65,0,0,1-2,.7,45.4,45.4,0,0,1-5.07,1.05,43.54,43.54,0,0,1-5.15.5,8.29,8.29,0,0,1-2.15-.06c0-.16,3.25-.34,7.22-.95S165.73,342.91,165.77,343.06Z"
          style={{
            fill: 'rgb(120, 77, 72)',
            transformOrigin: '158.5850067138672px 344.19723534584045px',
          }}
          id="elqc5vpqnx0wc"
          className="animable"
        />
        <path
          d="M152,352.15a5.33,5.33,0,0,1-3,1.62,5.22,5.22,0,0,1-3.43-.07c0-.15,1.53,0,3.3-.43S151.93,352,152,352.15Z"
          style={{
            fill: 'rgb(120, 77, 72)',
            transformOrigin: '148.78500366210938px 353.0791010260582px',
          }}
          id="elw5oj0198i0q"
          className="animable"
        />
        <path
          d="M158.84,351.23c0,.14-.86.46-2,.71a4.53,4.53,0,0,1-2.12.2c0-.14.87-.46,2-.71A4.49,4.49,0,0,1,158.84,351.23Z"
          style={{
            fill: 'rgb(120, 77, 72)',
            transformOrigin: '156.7800064086914px 351.6845247745514px',
          }}
          id="elg1ea1n39kg"
          className="animable"
        />
        <path
          d="M159.94,324.77c-.05.21-4.17-.75-9.15-2.23-2.5-.74-4.74-1.46-6.35-2a11.28,11.28,0,0,1-2.55-1.08,19.4,19.4,0,0,1,2.66.72l6.38,1.89,6.38,1.89C158.94,324.43,159.94,324.72,159.94,324.77Z"
          style={{
            fill: 'rgb(120, 77, 72)',
            transformOrigin: '150.91500091552734px 322.1293680667877px',
          }}
          id="elh2q3h40uoya"
          className="animable"
        />
        <path
          d="M188.39,329.33s-2.17-.56-.17-4.07,8.68,0,8.68,0l8.53,4.17s2.17,4.07,0,5.37l-1.5,2.2s3.44,2.94,2.39,5.87a4.37,4.37,0,0,1-4.21,2.78s.84,5.66-.83,8a6.19,6.19,0,0,1-3.33,2.68,10.22,10.22,0,0,1-6.51,6.69c-5.34,1.84-8.18,2.18-9.85,0a25.18,25.18,0,0,1-2.85-5s-12.2-5.17-12.71-8.26,0-3.42,0-3.42-1.16-2.51-.13-4.51,5.8-.72,5.8-.72l15.2,2.26s-12.39-4.62-14-6.34-.24-4.08,4.61-3.91,19.54,1.69,19.54,1.69a4.43,4.43,0,0,0-1.84-1.71c-1-.33-4.14-1.83-4.14-1.83Z"
          style={{
            fill: 'rgb(153, 105, 100)',
            transformOrigin: '185.99647331237793px 344.11507987976074px',
          }}
          id="elsassoaizdo"
          className="animable"
        />
        <path
          d="M193.83,351.63c0,.14-6.21-.9-13.8-2.32s-13.72-2.7-13.7-2.84,6.2.9,13.8,2.32S193.85,351.49,193.83,351.63Z"
          style={{
            fill: 'rgb(120, 77, 72)',
            transformOrigin: '180.08000469207764px 349.0499985218048px',
          }}
          id="elqxgjlmz3v8i"
          className="animable"
        />
        <path
          d="M187.39,360.36a18.55,18.55,0,0,1-4.46-.76c-1.21-.3-2.3-.61-3.07-.89a5.4,5.4,0,0,1-.9-.4.92.92,0,0,1-.23-.18c-.06-.06,0-.14,0-.11s1.91.48,4.32,1.07A22.21,22.21,0,0,1,187.39,360.36Z"
          style={{
            fill: 'rgb(120, 77, 72)',
            transformOrigin: '183.0466709136963px 359.1867184638977px',
          }}
          id="el50cxqs7pptt"
          className="animable"
        />
        <path
          d="M199,344.6c0,.15-3-.2-6.64-.49s-6.62-.44-6.62-.59a30,30,0,0,1,6.67.07A30,30,0,0,1,199,344.6Z"
          style={{
            fill: 'rgb(120, 77, 72)',
            transformOrigin: '192.37001037597656px 344.0016778707504px',
          }}
          id="elympvtugytvp"
          className="animable"
        />
        <path
          d="M202,336.58c-.08.13-1.34-.6-3-1.05s-3.14-.48-3.15-.63a5.41,5.41,0,0,1,3.28.12A5.59,5.59,0,0,1,202,336.58Z"
          style={{
            fill: 'rgb(120, 77, 72)',
            transformOrigin: '198.9250030517578px 335.6484512090683px',
          }}
          id="el1y5uj40dal6"
          className="animable"
        />
        <path
          d="M212.81,309.83a2.2,2.2,0,0,1-.55,0l-1.58-.06c-1.36-.06-3.33-.16-5.77-.23-4.87-.15-11.61-.18-19,.26-1.84.13-3.71.1-5.14,1a21.33,21.33,0,0,0-2,1.58l-1.89,1.6-6.57,5.5-4.46,3.68-1.23,1c-.29.22-.44.33-.46.31a2.19,2.19,0,0,1,.39-.39l1.16-1.06,4.36-3.8,6.5-5.59,1.88-1.61a21.58,21.58,0,0,1,2.05-1.63,6.51,6.51,0,0,1,2.61-.91c.92-.13,1.84-.16,2.77-.22a180.89,180.89,0,0,1,19.09-.11c2.44.12,4.41.27,5.77.4l1.56.16A2.08,2.08,0,0,1,212.81,309.83Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '188.4849853515625px 316.7111372947693px',
          }}
          id="el9xnbxxrghv5"
          className="animable"
        />
        <path
          d="M194.84,313.94a1.45,1.45,0,0,0-1.46-.43l.06,0-.23.07-.09,0a2,2,0,0,0-1.09.74,1.21,1.21,0,0,0,.09,1.54,1.4,1.4,0,0,0,.84.31,2,2,0,0,0,1.66-.56A1.4,1.4,0,0,0,194.84,313.94Z"
          style={{
            fill: 'rgb(255, 255, 255)',
            transformOrigin: '193.4084907770157px 314.8226420879364px',
          }}
          id="el7vuc8220v4f"
          className="animable"
        />
      </g>
      <g
        id="freepik--Desk--inject-2"
        style={{ transformOrigin: '245.99998474121094px 474.4800109863281px' }}
        className="animable"
      >
        <path
          d="M464.77,474.48c0,.14-97.95.26-218.76.26s-218.78-.12-218.78-.26,97.93-.26,218.78-.26S464.77,474.34,464.77,474.48Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '245.99998474121094px 474.4800109863281px',
          }}
          id="elplnvbdc6a4j"
          className="animable"
        />
      </g>
      <g
        id="freepik--Icons--inject-2"
        style={{ transformOrigin: '377.0679016113281px 198.6116828918457px' }}
        className="animable"
      >
        <path
          d="M303.22,344.16a6.37,6.37,0,1,1-6.38-6.36A6.37,6.37,0,0,1,303.22,344.16Z"
          style={{
            fill: '#95BD51',
            transformOrigin: '296.8500142097473px 344.17002153396606px',
          }}
          id="ellsx2e05a9e9"
          className="animable"
        />
        <path
          d="M321,331.4a6.37,6.37,0,1,1-6.37-6.36A6.36,6.36,0,0,1,321,331.4Z"
          style={{
            fill: '#95BD51',
            transformOrigin: '314.6300091743469px 331.41002464294434px',
          }}
          id="el41o32u27yo1"
          className="animable"
        />
        <path
          d="M321.08,358.39a6.39,6.39,0,1,1-6.38-6.92A6.67,6.67,0,0,1,321.08,358.39Z"
          style={{
            fill: '#95BD51',
            transformOrigin: '314.70350217819214px 357.86004304885864px',
          }}
          id="elg2i6einuri"
          className="animable"
        />
        <path
          d="M312.83,357.58c-.27.4-4.5-2-9.46-5.37s-8.74-6.42-8.47-6.82,4.51,2,9.46,5.37S313.11,357.18,312.83,357.58Z"
          style={{
            fill: '#95BD51',
            transformOrigin: '303.8654489517212px 351.4849843978882px',
          }}
          id="el37z8h4kp5fd"
          className="animable"
        />
        <path
          d="M313.34,331c.3.38-3,3.41-7.3,6.77s-8.08,5.76-8.38,5.38,3-3.41,7.3-6.77S313.05,330.58,313.34,331Z"
          style={{
            fill: '#95BD51',
            transformOrigin: '305.49999141693115px 337.0712480545044px',
          }}
          id="elvd554upaxhc"
          className="animable"
        />
        <path
          d="M431.6,249.81l-16.39-6L399,249.72c-2.07,1-3.89,1.61-3.89,3.59v9.86a38,38,0,0,0,5.35,19.62,33.62,33.62,0,0,0,14.94,13.53c6.63-2.21,11.58-8.1,14.61-13.14a38,38,0,0,0,5.35-19.63v-8.86A4.62,4.62,0,0,0,431.6,249.81Z"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '415.2410488128662px 270.0649871826172px',
          }}
          id="elik20mkztyw"
          className="animable"
        />
        <path
          d="M431.6,249.81a4.8,4.8,0,0,1,1,.35,4.34,4.34,0,0,1,2.29,2.12,5.37,5.37,0,0,1,.49,2.25c0,.84,0,1.75,0,2.74,0,2,0,4.22,0,6.72a38.2,38.2,0,0,1-4.17,17.21A39.7,39.7,0,0,1,425.1,290a24.3,24.3,0,0,1-9.67,6.52l-.07,0-.08,0a33.43,33.43,0,0,1-13.42-11.15A37.71,37.71,0,0,1,395,266c-.12-1.64-.1-3.26-.1-4.85V256.5c0-.77,0-1.53,0-2.28,0-.38,0-.74,0-1.13a2.7,2.7,0,0,1,.34-1.14A4.13,4.13,0,0,1,397,250.5c.64-.35,1.3-.63,1.93-.93h0l16.25-5.92h.09l12.05,4.5,3.2,1.22.82.32.28.13-.29-.09-.83-.29-3.23-1.15-12.09-4.39h.09l-16.21,6h0c-.63.31-1.29.6-1.91.94a3.77,3.77,0,0,0-1.57,1.32,3.93,3.93,0,0,0-.3,2.08v2.28c0,1.53,0,3.09,0,4.67s0,3.2.11,4.82a37.41,37.41,0,0,0,6.81,19.12,33.19,33.19,0,0,0,13.25,11h-.15a24.06,24.06,0,0,0,9.53-6.41A39.67,39.67,0,0,0,431,281,38.14,38.14,0,0,0,435.19,264c0-2.5,0-4.75,0-6.72s.19-3.7-.44-4.93a4.32,4.32,0,0,0-2.19-2.12c-.32-.15-.57-.25-.74-.31Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '415.1393756866455px 270.08499908447266px',
          }}
          id="eltvlvfsi0i2"
          className="animable"
        />
        <path
          d="M426.65,276.26h-3.44V257.52a8,8,0,0,0-16,0v18.74h-3.44V257.52a11.44,11.44,0,0,1,22.88,0Z"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '415.2099914550781px 261.1699905395508px',
          }}
          id="elquwhxwc4sip"
          className="animable"
        />
        <g id="elvza1fw1gpbm">
          <g
            style={{
              opacity: '0.4',
              transformOrigin: '415.2099914550781px 261.1699905395508px',
            }}
            className="animable"
            id="el8i12920eatp"
          >
            <path
              d="M426.65,276.26h-3.44V257.52a8,8,0,0,0-16,0v18.74h-3.44V257.52a11.44,11.44,0,0,1,22.88,0Z"
              id="elgzm05evq0yk"
              style={{
                transformOrigin: '415.2099914550781px 261.1699905395508px',
              }}
              className="animable"
            />
          </g>
        </g>
        <rect
          x="399.79"
          y="267.92"
          width="30.83"
          height="19.78"
          rx="3.39"
          style={{
            fill: '#95BD51',
            transformOrigin: '415.20500000000004px 277.81px',
          }}
          id="ellin4ibjm5i"
          className="animable"
        />
        <g id="elh016g88j0zb">
          <g
            style={{
              opacity: '0.5',
              transformOrigin: '415.19994592666626px 277.09830045700073px',
            }}
            className="animable"
            id="elqk80jdi3li8"
          >
            <path
              d="M417.78,275.24a2.58,2.58,0,1,0-3.28,2.46v3.86h1.41V277.7A2.56,2.56,0,0,0,417.78,275.24Z"
              id="elsdsxqs4rhas"
              style={{
                transformOrigin: '415.19994592666626px 277.09830045700073px',
              }}
              className="animable"
            />
          </g>
        </g>
        <path
          d="M401.43,285.83c-.07,0-.12-3.3-.12-7.37s.05-7.37.12-7.37.13,3.3.13,7.37S401.5,285.83,401.43,285.83Z"
          style={{
            fill: 'rgb(250, 250, 250)',
            transformOrigin: '401.43499755859375px 278.4599914550781px',
          }}
          id="elnbsrgpbflvn"
          className="animable"
        />
        <path
          d="M413.19,278.54a.82.82,0,0,1-.3-.17,2.86,2.86,0,0,1-.67-.68,3.62,3.62,0,0,1,1.29-5.42,3.38,3.38,0,0,1,.89-.31c.22,0,.35,0,.35,0a6.59,6.59,0,0,0-1.16.48,3.59,3.59,0,0,0-1.22,5.16A7.23,7.23,0,0,0,413.19,278.54Z"
          style={{
            fill: 'rgb(250, 250, 250)',
            transformOrigin: '413.12195348739624px 275.25px',
          }}
          id="el4tymxta6nns"
          className="animable"
        />
        <path
          d="M459.67,170.94a8.44,8.44,0,0,1,2.05-.89l.42-2,3.42-.06.49,2a8.42,8.42,0,0,1,2.08.82l1.73-1.13,2.46,2.37-1.07,1.77a8.44,8.44,0,0,1,.89,2l2,.42.06,3.42-2,.49a8.42,8.42,0,0,1-.82,2.08l1.13,1.73-2.37,2.46-1.77-1.07a8.44,8.44,0,0,1-2.05.89l-.42,2-3.42.06-.49-2a8.42,8.42,0,0,1-2.08-.82l-1.73,1.13-2.46-2.38,1.07-1.76a8.44,8.44,0,0,1-.89-2.05l-2-.42-.06-3.42,2-.5a8.51,8.51,0,0,1,.82-2.07l-1.13-1.73,2.37-2.46,1.77,1.07Zm1.83,4.56a3.67,3.67,0,1,0,5.19-.09A3.67,3.67,0,0,0,461.5,175.5Z"
          style={{
            fill: '#95BD51',
            transformOrigin: '464.02001953125px 178.14500427246094px',
          }}
          id="elq5sn2xqo4h"
          className="animable"
        />
        <path
          d="M461.5,175.5a2.76,2.76,0,0,1,.68-.6,3.68,3.68,0,0,1,4,0,3.81,3.81,0,0,1,1.32,1.48,3.65,3.65,0,0,1,.33,1,3.3,3.3,0,0,1,0,1.13,3.76,3.76,0,0,1-1,2.12,3.83,3.83,0,0,1-2.08,1.09,3.31,3.31,0,0,1-1.13,0,3.63,3.63,0,0,1-1-.29,3.85,3.85,0,0,1-1.53-1.27,3.76,3.76,0,0,1-.63-1.61,3.67,3.67,0,0,1,.48-2.41,2.66,2.66,0,0,1,.58-.7s-.22.24-.51.74a3.67,3.67,0,0,0-.4,2.35,3.47,3.47,0,0,0,.63,1.53,3.61,3.61,0,0,0,1.46,1.19,3.3,3.3,0,0,0,1,.27,3.21,3.21,0,0,0,1.07,0,3.58,3.58,0,0,0,2.93-3,3.54,3.54,0,0,0-.32-2.05,3.7,3.7,0,0,0-1.24-1.43,3.67,3.67,0,0,0-3.89-.08A7.61,7.61,0,0,0,461.5,175.5Z"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '464.15015935897827px 178.03881740570068px',
          }}
          id="elbtad26tck45"
          className="animable"
        />
        <path
          d="M459.67,170.94l-.11-.06-.34-.2c-.31-.18-.76-.44-1.33-.79h0c-.61.63-1.4,1.47-2.36,2.48v-.07l1.14,1.72,0,0,0,0a8.84,8.84,0,0,0-.8,2.06v0h0l-2,.5.06-.07c0,1.07,0,2.22.07,3.42l-.07-.08,2,.41.05,0v0a8.48,8.48,0,0,0,.89,2l0,.05,0,0-1.07,1.77,0-.11,2.47,2.37h-.12l1.72-1.14.05,0,.06,0a8.51,8.51,0,0,0,2,.81h.06v.06c.16.63.33,1.32.5,2l-.1-.08,3.42-.06-.1.08c.13-.64.27-1.33.42-2v-.06l.06,0a8.32,8.32,0,0,0,2-.88h0l.06,0,.05,0,1.77,1.07-.13,0,2.38-2.46v.13l-1.14-1.73,0-.06,0,0a8.58,8.58,0,0,0,.81-2.05v-.06l.06,0,2-.49-.07.1q0-1.75-.06-3.42l.08.09-2-.42h-.05l0-.06a8.3,8.3,0,0,0-.88-2l0,0,0-.05,1.08-1.76v.11l-2.46-2.39h.11l-1.74,1.13,0,0,0,0a8.13,8.13,0,0,0-2-.82h0v0l-.49-2,.06.05-3.42,0,.05,0c-.18.81-.32,1.47-.44,2v0h0a8.68,8.68,0,0,0-1.57.6l-.36.2-.13.06.12-.08.36-.21a7.87,7.87,0,0,1,1.56-.63l0,0,.4-2v0h0l3.42-.08h.05v0c.16.62.32,1.28.5,2l-.05-.05a8.17,8.17,0,0,1,2.1.81h-.08l1.73-1.14.05,0,.05,0,2.46,2.37.05.05,0,.06-1.07,1.77v-.1a8.54,8.54,0,0,1,.9,2.07l-.07-.06,2,.41.07,0v.08c0,1.11,0,2.25.07,3.42v.08l-.08,0-2,.49.08-.07a8.75,8.75,0,0,1-.83,2.1v-.11l1.13,1.73.05.07-.06.06-2.38,2.46-.06.07-.07-.05-1.77-1.07h.11a8.73,8.73,0,0,1-2.07.9l.07-.08c-.14.7-.28,1.39-.42,2v.08h-.09l-3.42.06h-.08l0-.08c-.17-.69-.34-1.38-.49-2l.07.07a8.5,8.5,0,0,1-2.1-.82h.1l-1.73,1.13-.06,0-.06-.06-2.46-2.38-.05,0,0-.06,1.07-1.77v.09a8.25,8.25,0,0,1-.89-2.07l.06.07-2-.43h-.07v-.07c0-1.2,0-2.34-.05-3.42v-.06l.06,0,2-.48-.05.05a8.15,8.15,0,0,1,.83-2.09v.06l-1.13-1.73,0,0,0,0,2.39-2.45,0,0,0,0,1.3.81.34.21Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '464.1500244140625px 178.0449981689453px',
          }}
          id="el0e7wrnd3lmkr"
          className="animable"
        />
        <path
          d="M439.3,167.84a5.07,5.07,0,0,1,1.22-.53l.25-1.21,2.05,0,.3,1.2a5.09,5.09,0,0,1,1.24.49l1-.68,1.47,1.43-.64,1.05a5.14,5.14,0,0,1,.53,1.23L448,171l0,2.05-1.2.29a5.32,5.32,0,0,1-.49,1.25l.68,1-1.43,1.48-1.06-.64a5.07,5.07,0,0,1-1.22.53l-.25,1.21-2.05,0-.3-1.21a4.85,4.85,0,0,1-1.24-.49l-1,.68-1.48-1.42.64-1.06a5.25,5.25,0,0,1-.53-1.22l-1.21-.26,0-2L437,171a4.69,4.69,0,0,1,.48-1.24l-.67-1,1.42-1.47,1.06.64Zm1.09,2.73a2.2,2.2,0,1,0,3.11-.06A2.21,2.21,0,0,0,440.39,170.57Z"
          style={{
            fill: '#95BD51',
            transformOrigin: '441.9300231933594px 172.13499450683594px',
          }}
          id="elm4s651d6rs"
          className="animable"
        />
        <path
          d="M440.39,170.57a1.59,1.59,0,0,1,.41-.36,2.18,2.18,0,0,1,1.43-.34,2.25,2.25,0,0,1,2,1.85,2.57,2.57,0,0,1,0,.68,2.36,2.36,0,0,1-.61,1.27,2.27,2.27,0,0,1-1.25.65,2.16,2.16,0,0,1-.67,0,2.18,2.18,0,0,1-.63-.18,2.26,2.26,0,0,1-.91-.75,2.15,2.15,0,0,1-.38-1A2.22,2.22,0,0,1,440,171a1.67,1.67,0,0,1,.35-.42,4.49,4.49,0,0,0-.3.45,2.18,2.18,0,0,0-.25,1.4,2.2,2.2,0,0,0,.38.92,2.23,2.23,0,0,0,.88.71,1.91,1.91,0,0,0,.59.16,2,2,0,0,0,.64,0,2.13,2.13,0,0,0,1.76-1.82,2.29,2.29,0,0,0,0-.64,2.19,2.19,0,0,0-.19-.58,2.23,2.23,0,0,0-.74-.86,2.2,2.2,0,0,0-.93-.34,2.24,2.24,0,0,0-1.4.29A5,5,0,0,0,440.39,170.57Z"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '442.00114917755127px 172.10143876075745px',
          }}
          id="el93uf4qhuoq"
          className="animable"
        />
        <path
          d="M439.3,167.84l-.07,0-.2-.11-.8-.47h0l-1.41,1.48v0l.69,1v0a4.85,4.85,0,0,0-.48,1.23v0h0l-1.21.3,0,0c0,.65,0,1.33,0,2l0,0,1.21.25h0v0a4.75,4.75,0,0,0,.53,1.21l0,0,0,0-.64,1.06v-.07l1.48,1.42h-.08l1-.68,0,0,0,0a4.64,4.64,0,0,0,1.23.48h0v0c.1.38.2.79.3,1.2l-.06-.05,2.05,0-.06.05c.08-.39.16-.8.25-1.22v0h0a5,5,0,0,0,1.21-.53h0l0,0,0,0,1.06.64h-.08l1.42-1.47v.08l-.67-1,0,0v0a5.18,5.18,0,0,0,.49-1.23v0h0l1.2-.3,0,.06c0-.7,0-1.38,0-2.05l0,.06-1.21-.26h0v0a5.48,5.48,0,0,0-.53-1.21l0,0,0,0c.22-.37.44-.72.65-1.06v.06l-1.47-1.42h.06l-1,.68h-.05a5,5,0,0,0-1.23-.49h0v0c-.11-.44-.2-.83-.29-1.2l0,0-2,0,0,0c-.1.48-.19.88-.26,1.21h0a4.89,4.89,0,0,0-.94.36l-.22.11-.07,0,.07-.05.21-.12a4.67,4.67,0,0,1,.94-.38h0l.25-1.21v0h0l2.05,0h0v0q.13.55.3,1.2l0,0a5.14,5.14,0,0,1,1.25.49h0l1-.68,0,0,0,0,1.48,1.42,0,0,0,0c-.2.35-.42.7-.63,1.06v-.05a5,5,0,0,1,.53,1.23l0,0L448,171h0V171c0,.67,0,1.35,0,2.05v0H448l-1.2.29.05,0a5.43,5.43,0,0,1-.5,1.26v-.07c.22.35.45.69.68,1l0,0,0,0-1.42,1.47,0,0,0,0-1.06-.64h.07a5.37,5.37,0,0,1-1.24.54l0-.05c-.09.42-.17.83-.25,1.21v0h0l-2.05,0h-.05v-.05c-.1-.41-.2-.83-.3-1.2l.05,0a5.25,5.25,0,0,1-1.26-.49h.06l-1,.68,0,0,0,0-1.47-1.42,0,0,0,0,.65-1.06v.06a4.92,4.92,0,0,1-.54-1.24l0,0-1.21-.26h0v0c0-.71,0-1.4,0-2v0h0l1.21-.29,0,0a5.17,5.17,0,0,1,.49-1.25v0l-.67-1v0l1.43-1.47h0l.78.49.2.12Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '441.9099884033203px 172.16997528076172px',
          }}
          id="el7bcf08kalbc"
          className="animable"
        />
        <path
          d="M439,185.77a11.61,11.61,0,0,1,3.09-.37l1.38-2.54,4.6,1.29L448,187a11.83,11.83,0,0,1,2.45,1.93l2.77-.83,2.34,4.17-2.15,1.94a11.22,11.22,0,0,1,.37,3.09l2.54,1.38-1.29,4.6-2.89-.14a11.86,11.86,0,0,1-1.92,2.45l.82,2.77-4.17,2.34-1.94-2.15a11.22,11.22,0,0,1-3.09.37l-1.38,2.54-4.6-1.29.14-2.89a12.13,12.13,0,0,1-2.45-1.92l-2.77.82-2.34-4.17,2.15-1.94a11.22,11.22,0,0,1-.37-3.09l-2.54-1.38,1.29-4.6,2.89.14a12.13,12.13,0,0,1,1.92-2.45l-.82-2.77,4.17-2.34,1.94,2.15Zm.61,6.83a5.13,5.13,0,1,0,7,2A5.13,5.13,0,0,0,439.65,192.6Z"
          style={{
            fill: 'none',
            transformOrigin: '441.99998474121094px 197.16000366210938px',
          }}
          id="eldr334ivumcf"
          className="animable"
        />
        <path
          d="M439.65,192.6a4,4,0,0,1,1.15-.53,5.2,5.2,0,0,1,5.38,1.63,5.25,5.25,0,0,1,1.17,2.52,4.76,4.76,0,0,1,0,1.51,5.14,5.14,0,0,1-.42,1.52,5.31,5.31,0,0,1-2.22,2.43,5.25,5.25,0,0,1-3.22.62,5.11,5.11,0,0,1-1.52-.42,5,5,0,0,1-1.27-.82,5.23,5.23,0,0,1-1.75-4.71,5.11,5.11,0,0,1,1.62-3,3.65,3.65,0,0,1,1-.71,12.58,12.58,0,0,0-1,.8,5.15,5.15,0,0,0-1.48,3,5,5,0,0,0,1.71,4.47,4.88,4.88,0,0,0,1.21.77,5,5,0,0,0,4.49-.2,5,5,0,0,0,2.09-2.29,4.4,4.4,0,0,0,.41-1.43,4.62,4.62,0,0,0,0-1.44,5,5,0,0,0-2.93-3.79,5.12,5.12,0,0,0-3.32-.29A12.51,12.51,0,0,0,439.65,192.6Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '442.15256452560425px 197.10713815689087px',
          }}
          id="el7hcki8mansv"
          className="animable"
        />
        <path
          d="M439,185.77l-.13-.13-.38-.4-1.46-1.58h.05L433,186l0-.09c.26.84.54,1.77.84,2.77v0l0,0a11.77,11.77,0,0,0-1.9,2.43l0,.05h-.06l-2.88-.14.1-.07c-.4,1.45-.83,3-1.28,4.6l-.06-.13,2.55,1.37.06,0V197a11.84,11.84,0,0,0,.37,3.06l0,.08-.06.05-2.14,1.94,0-.17,2.34,4.17-.16-.06,2.77-.83.08,0,.06.06a11.7,11.7,0,0,0,2.43,1.9l.07,0v.09c-.05.9-.1,1.89-.15,2.89l-.1-.15,4.6,1.29-.17.07,1.38-2.54,0-.08h.09a11.15,11.15,0,0,0,3.05-.36h0l.08,0,.06.06,1.94,2.15-.18,0,4.17-2.34-.07.17c-.28-.93-.55-1.86-.82-2.77l0-.09.06-.06A11.38,11.38,0,0,0,452,203.1l0-.07h.09l2.89.15-.15.11c.45-1.57.88-3.11,1.3-4.61l.07.16-2.54-1.38-.07,0v-.08a11.5,11.5,0,0,0-.36-3.06l0-.08.06,0,2.14-1.93,0,.15-2.33-4.17.13.05-2.77.82h-.06l-.05,0a12.06,12.06,0,0,0-2.42-1.92l-.05,0V187c.06-1.05.11-2,.16-2.88l.06.09-4.6-1.32.08,0-1.4,2.53v0h0a12.73,12.73,0,0,0-2.33.17l-.57.11-.19,0s.06,0,.18-.06l.57-.13a11.7,11.7,0,0,1,2.34-.23l0,0,1.36-2.55,0-.05,0,0,4.61,1.27.06,0v.07c0,.89-.09,1.83-.14,2.88l0-.08a11.76,11.76,0,0,1,2.47,1.93l-.1,0,2.76-.83.09,0,0,.08q1.13,2,2.35,4.16l.05.09-.07.06-2.14,1.94,0-.12a11.93,11.93,0,0,1,.37,3.13l-.07-.12,2.55,1.37.09.05,0,.11-1.29,4.6,0,.11H455l-2.89-.14.13-.07a11.54,11.54,0,0,1-1.94,2.48l0-.14c.28.91.55,1.83.83,2.77l0,.11-.1.05-4.17,2.35-.1,0-.08-.08-1.94-2.15.15.05h0a12.24,12.24,0,0,1-3.14.37l.14-.08c-.48.87-.95,1.74-1.38,2.54l-.06.1-.11,0-4.6-1.29-.11,0v-.12c.05-1,.1-2,.15-2.88l.07.13a12.26,12.26,0,0,1-2.48-2l.14,0-2.77.82-.1,0-.06-.09-2.33-4.18-.05-.09.08-.07,2.14-1.93,0,.13a12,12,0,0,1-.36-3.13l.06.1-2.54-1.38-.08,0,0-.09c.45-1.62.89-3.15,1.3-4.6l0-.08H429l2.88.16-.09,0a12,12,0,0,1,1.95-2.47l0,.09c-.29-1-.56-1.93-.81-2.77l0-.06.06,0,4.18-2.32,0,0,0,0,1.41,1.61.37.42A.8.8,0,0,1,439,185.77Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '441.9599914550781px 197.10999298095703px',
          }}
          id="eljp30yatu6"
          className="animable"
        />
        <path
          d="M429.92,127l-22.77-2.72a2.87,2.87,0,0,1-2.51-3.19l3.75-31.53A2.87,2.87,0,0,1,411.58,87L426,88.76l9.81,12.86-2.73,22.85A2.87,2.87,0,0,1,429.92,127Z"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '420.21478366851807px 106.99862480163574px',
          }}
          id="ela8duqj11ztv"
          className="animable"
        />
        <path
          d="M429.92,127a3.37,3.37,0,0,1,.5,0,2.69,2.69,0,0,0,1.34-.46,2.78,2.78,0,0,0,1.23-1.86q.15-1.4.36-3.18c.55-4.8,1.33-11.63,2.27-19.86l0,.15-9.83-12.83L426,89l-8.34-1-4.36-.52c-.72-.07-1.51-.22-2.18-.22a2.6,2.6,0,0,0-1.8.85,2.53,2.53,0,0,0-.64,1.26c-.07.48-.12,1-.19,1.53-.12,1-.24,2.06-.37,3.08-.24,2-.48,4-.72,6-.47,3.94-.93,7.74-1.37,11.36-.22,1.81-.43,3.58-.64,5.3-.1.85-.21,1.7-.31,2.53l-.15,1.24a7,7,0,0,0-.09,1.18,2.69,2.69,0,0,0,2,2.37c.33.08.73.11,1.1.16l4.29.53,13,1.63,3.52.47.91.13a1,1,0,0,1,.31.07l-.31,0-.92-.08-3.54-.37-13-1.47-4.3-.49a10.16,10.16,0,0,1-1.16-.17,2.94,2.94,0,0,1-1.11-.54,3.09,3.09,0,0,1-1.19-2.19,6.39,6.39,0,0,1,.09-1.26c0-.41.09-.83.14-1.24.1-.83.2-1.68.3-2.54.2-1.71.4-3.48.62-5.29.42-3.63.87-7.43,1.34-11.37.23-2,.47-4,.71-6l.36-3.08c.07-.52.11-1,.2-1.57a3,3,0,0,1,.76-1.51,3.12,3.12,0,0,1,2.17-1c.83,0,1.51.15,2.26.22l4.36.52,8.34,1h.1l.07.08L436,101.49l.05.07v.09c-1,8.22-1.86,15-2.46,19.84-.15,1.2-.27,2.25-.41,3.18a2.93,2.93,0,0,1-1.34,1.94,2.78,2.78,0,0,1-1.41.42A1.81,1.81,0,0,1,429.92,127Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '420.2438049316406px 106.90809059143066px',
          }}
          id="el93xazm99mf8"
          className="animable"
        />
        <path
          d="M426,88.76,425,97.64a2.67,2.67,0,0,0,2.34,3l8.53,1Z"
          style={{
            fill: 'rgb(245, 245, 245)',
            transformOrigin: '430.42363595962524px 95.20000076293945px',
          }}
          id="el6gj9ppbz3gk"
          className="animable"
        />
        <path
          d="M425,98.43a3,3,0,0,0,2,3.14,1.43,1.43,0,0,0,.27.09c1.1.34,8.27,2.52,8.29,2.35s.29-2.39.29-2.39l-8.53-1S425.18,100.28,425,98.43Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '430.41710901260376px 101.22471332550049px',
          }}
          id="el6sz7ma594rc"
          className="animable"
        />
        <path
          d="M423.1,97.05a35.58,35.58,0,0,1-5.29-.42,32.93,32.93,0,0,1-5.24-.84,35.58,35.58,0,0,1,5.29.42A32.93,32.93,0,0,1,423.1,97.05Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '417.8350067138672px 96.42000579833984px',
          }}
          id="elrdrte7tko8l"
          className="animable"
        />
        <path
          d="M429.31,102.88c0,.11-3.91-.26-8.69-.83s-8.67-1.13-8.65-1.24a86.92,86.92,0,0,1,8.7.83C425.45,102.21,429.33,102.76,429.31,102.88Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '420.6399850845337px 101.85467255115509px',
          }}
          id="el7mbgv9p3e73"
          className="animable"
        />
        <path
          d="M430.37,107.25c0,.12-4.25-.3-9.47-.92s-9.44-1.22-9.43-1.33,4.26.3,9.48.92S430.39,107.14,430.37,107.25Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '420.92001247406006px 106.1263278722763px',
          }}
          id="elun3nygcjotj"
          className="animable"
        />
        <path
          d="M429.9,111.2c0,.12-4.25-.29-9.47-.92S411,109.06,411,109s4.25.3,9.48.92S429.92,111.09,429.9,111.2Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '420.4500322341919px 110.10718321800232px',
          }}
          id="elvdy3vvvi6pg"
          className="animable"
        />
        <path
          d="M429.43,115.16c0,.11-4.25-.3-9.47-.92s-9.45-1.22-9.43-1.34,4.25.3,9.47.92S429.45,115,429.43,115.16Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '419.9800052642822px 114.02867817878723px',
          }}
          id="elwhauiisbcgq"
          className="animable"
        />
        <path
          d="M429,119.11c0,.11-4.26-.3-9.48-.92s-9.44-1.22-9.42-1.34,4.25.3,9.47.93S429,119,429,119.11Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '419.5499620437622px 117.97868275642395px',
          }}
          id="el80igc4knla"
          className="animable"
        />
        <path
          d="M435.84,101.62a20.26,20.26,0,0,1-3.2-.19l-3.45-.36-2.05-.23a2.93,2.93,0,0,1-2-1.15,2.12,2.12,0,0,1-.44-1.16,7.9,7.9,0,0,1,.08-1.13c.09-.7.18-1.38.26-2l.47-3.44a20.06,20.06,0,0,1,.56-3.16,19.66,19.66,0,0,1-.2,3.2c-.1,1-.22,2.2-.35,3.46-.08.65-.15,1.33-.23,2a2.4,2.4,0,0,0,.27,1.93c.82,1.17,2.45,1,3.71,1.2l3.45.47A19.76,19.76,0,0,1,435.84,101.62Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '430.26999139785767px 95.21199703216553px',
          }}
          id="eldgdgm5ic59"
          className="animable"
        />
        <path
          d="M298,50.31a8.91,8.91,0,0,0,5.3-8.23A8.36,8.36,0,1,0,286.64,42a8.89,8.89,0,0,0,5.32,8.3c-12.84,1.94-12.25,15.89-12.25,15.89l30.35.14S310.75,52.23,298,50.31Z"
          style={{
            fill: '#95BD51',
            transformOrigin: '294.8862237930298px 49.651620864868164px',
          }}
          id="el4jsrmq1f2hb"
          className="animable"
        />
      </g>
      <g
        id="freepik--Fingerprint--inject-2"
        style={{ transformOrigin: '310.3600158691406px 197.27013397216797px' }}
        className="animable"
      >
        <circle
          cx="392.52"
          cy="271.22"
          r="1.88"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '392.52px 271.22px',
          }}
          id="elvybck1j7zl"
          className="animable"
        />
        <path
          d="M392.52,271.22a3.32,3.32,0,0,1-.66,0l-1.89.05-7,.08-23,.08h-7.32l-.08-.13c-3.49-6.35-6.62-11.84-9-15.7-1.17-1.92-2.15-3.44-2.87-4.44a8.22,8.22,0,0,0-.9-1.08.81.81,0,0,0-.29-.19c-.07,0-.11,0-.11,0s0-.06.12-.05a.67.67,0,0,1,.34.17,6.84,6.84,0,0,1,1,1c.78,1,1.79,2.47,3,4.38,2.42,3.82,5.61,9.28,9.13,15.63l-.22-.14h7.17l23,.07,7,.09,1.89,0A2.78,2.78,0,0,1,392.52,271.22Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '365.9599914550781px 260.634388923645px',
          }}
          id="elqhtppkzb5sg"
          className="animable"
        />
        <circle
          cx="395.38"
          cy="112.98"
          r="1.88"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '395.38px 112.98px',
          }}
          id="elegat6laf25i"
          className="animable"
        />
        <path
          d="M395.38,113a2.25,2.25,0,0,1-.61,0l-1.74,0-6.43.08-21.19.08h-9.68l.18-.08-9.82,10.76c-1.19,1.28-2.15,2.32-2.87,3.08l-.78.8c-.18.18-.28.27-.29.26a1.62,1.62,0,0,1,.23-.31l.72-.86,2.76-3.17,9.69-10.89.07-.09h9.79l21.19.08,6.43.08,1.74.05A2.92,2.92,0,0,1,395.38,113Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '368.76502990722656px 120.32039022445679px',
          }}
          id="elamnxiacuamh"
          className="animable"
        />
        <path
          d="M303,322.41a2.89,2.89,0,1,1,.48-4.06A2.88,2.88,0,0,1,303,322.41Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '301.21316957473755px 320.14014434814453px',
          }}
          id="elifxjzlgbdtp"
          className="animable"
        />
        <path
          d="M300.91,319.73c-.14,0-1.69-12.67-3.45-28.34S294.39,263,294.53,263s1.69,12.68,3.45,28.35S301.05,319.72,300.91,319.73Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '297.71999859809875px 291.36500549316406px',
          }}
          id="elfhh58fnewch"
          className="animable"
        />
        <circle
          cx="295.1"
          cy="73.39"
          r="1.88"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '295.1px 73.39px',
          }}
          id="elkx78yf9bh7"
          className="animable"
        />
        <path
          d="M294.88,73.39c.15,0,.26,6.52.26,14.57s-.11,14.58-.26,14.58S294.62,96,294.62,88,294.74,73.39,294.88,73.39Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '294.8800048828125px 87.96500015258789px',
          }}
          id="el917uvahoasi"
          className="animable"
        />
        <circle
          cx="416.01"
          cy="189.5"
          r="1.88"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '416.01px 189.5px',
          }}
          id="elruncj5km1g8"
          className="animable"
        />
        <path
          d="M415.35,189.5c0,.14-8.69.26-19.41.26s-19.41-.12-19.41-.26,8.69-.26,19.41-.26S415.35,189.35,415.35,189.5Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '395.94000244140625px 189.5px',
          }}
          id="el483zl8a1ssk"
          className="animable"
        />
        <path
          d="M287.61,274.85h-.26l-.74-.06-2.88-.25c-1.27-.07-2.8-.33-4.6-.6s-3.83-.69-6.1-1.16a97.54,97.54,0,0,1-15.72-5.06,91.81,91.81,0,0,1-9-4.33,73.19,73.19,0,0,1-9.24-5.89l.26,0L223.52,265l-.44.21.07-.48c.21-1.33.43-2.76.66-4.19.84-5.36,1.66-10.58,2.45-15.58l.08.23a71.45,71.45,0,0,1-14.07-18.29,81,81,0,0,1-6.84-17.09,79.08,79.08,0,0,1-2.33-12c-.16-1.42-.24-2.52-.27-3.26,0-.35,0-.63,0-.85s0-.28,0-.28a1.18,1.18,0,0,1,0,.28c0,.21,0,.49.07.84.06.74.17,1.84.35,3.25a85,85,0,0,0,2.48,11.89,81.58,81.58,0,0,0,6.91,17,71.7,71.7,0,0,0,14,18.13l.1.09,0,.13c-.78,5-1.6,10.22-2.44,15.58-.22,1.43-.44,2.86-.65,4.19l-.37-.28,15.8-7.44.14-.06.12.09a74,74,0,0,0,9.16,5.88,93.61,93.61,0,0,0,9,4.34,100.79,100.79,0,0,0,15.62,5.15c2.26.49,4.28,1,6.07,1.22s3.32.57,4.57.68l2.88.33.74.09A1.43,1.43,0,0,1,287.61,274.85Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '245.22000122070312px 234.1349868774414px',
          }}
          id="ela7tz2527zrd"
          className="animable"
        />
        <path
          d="M306,133.4a66.19,66.19,0,0,0-28.83,1.16.62.62,0,0,0,.34,1.2,64.83,64.83,0,0,1,28.16-1.15c.78.14,1.12-1.06.33-1.21Z"
          style={{
            fill: '#95BD51',
            transformOrigin: '291.5832300186157px 134.06005036830902px',
          }}
          id="elnurre7srsl"
          className="animable"
        />
        <path
          d="M314.11,139.91a60.61,60.61,0,0,0-44.74,1.7c-.73.33-.1,1.4.63,1.08a59.26,59.26,0,0,1,43.77-1.57c.76.27,1.09-.94.34-1.21Z"
          style={{
            fill: '#95BD51',
            transformOrigin: '291.78943824768066px 139.5913782119751px',
          }}
          id="el6nd6acd7l2h"
          className="animable"
        />
        <path
          d="M319.82,146c-13.6-4.91-29.1-7.21-43.16-2.88a44.21,44.21,0,0,0-12.56,6.16c-.65.46,0,1.55.63,1.08,12.94-9.14,29.41-9.71,44.39-6.23a90.63,90.63,0,0,1,10.36,3.08c.76.27,1.09-.93.34-1.21Z"
          style={{
            fill: '#95BD51',
            transformOrigin: '292.03676414489746px 145.6540665626526px',
          }}
          id="elnwqep4wfc1"
          className="animable"
        />
        <path
          d="M324.24,152.74c-14.77-8.21-32.93-10.38-48.89-4.41a57,57,0,0,0-14.62,8.14c-.63.48,0,1.57.64,1.08a54.89,54.89,0,0,1,25.36-10.85,57.82,57.82,0,0,1,24.9,2,60.63,60.63,0,0,1,12,5.08c.71.39,1.34-.69.64-1.08Z"
          style={{
            fill: '#95BD51',
            transformOrigin: '292.5220260620117px 151.30414485931396px',
          }}
          id="elgvge7klt964"
          className="animable"
        />
        <path
          d="M327.5,159.93c-15.24-12.31-36.43-14.36-54-6A60.23,60.23,0,0,0,258,164.55c-.58.56.3,1.44.89.89A56.12,56.12,0,0,1,286.16,151a49.87,49.87,0,0,1,27.31,2.27,48.21,48.21,0,0,1,13.14,7.56c.62.5,1.51-.38.89-.88Z"
          style={{
            fill: '#95BD51',
            transformOrigin: '292.7601661682129px 157.24949264526367px',
          }}
          id="elzek5cxsw87i"
          className="animable"
        />
        <path
          d="M296.48,152.69a46.69,46.69,0,0,0-21.69,4.43,50.81,50.81,0,0,0-18.57,15.43c-.48.64.6,1.26,1.08.63,9.32-12.29,23.64-19.81,39.18-19.24.8,0,.8-1.22,0-1.25Z"
          style={{
            fill: '#95BD51',
            transformOrigin: '276.5898723602295px 163.0496416091919px',
          }}
          id="elmq0tzdhndf"
          className="animable"
        />
        <path
          d="M330,168.36a49.81,49.81,0,0,0-19.75-13c-.76-.27-1.08.94-.33,1.21a48.49,48.49,0,0,1,19.2,12.71c.55.59,1.43-.29.88-.88Z"
          style={{
            fill: '#95BD51',
            transformOrigin: '319.83370208740234px 162.39812517166138px',
          }}
          id="el4gemgworae5"
          className="animable"
        />
        <path
          d="M331.45,177.65c-6.45-10.18-16.61-17.8-28.54-20.13a41.31,41.31,0,0,0-32.67,7.56,49.25,49.25,0,0,0-14,17c-.36.71.72,1.35,1.08.63,5.62-11,15-19.89,27-23.28A40.26,40.26,0,0,1,316.09,164a44.64,44.64,0,0,1,14.28,14.27c.43.68,1.51.05,1.08-.63Z"
          style={{
            fill: '#95BD51',
            transformOrigin: '293.8584098815918px 169.89905452728271px',
          }}
          id="el0h7b9p7yiiyk"
          className="animable"
        />
        <path
          d="M332,189.43a37.59,37.59,0,0,0-62.46-18.1c-6.67,6.26-11,14.59-13.4,23.32a.63.63,0,0,0,1.21.33c3.92-14.06,13.19-26.84,27.62-31.23a36.58,36.58,0,0,1,34.41,7.32,37.66,37.66,0,0,1,11.41,18.7.63.63,0,0,0,1.21-.34Z"
          style={{
            fill: '#95BD51',
            transformOrigin: '294.07194900512695px 178.14649391174316px',
          }}
          id="eldu653s47qx9"
          className="animable"
        />
        <path
          d="M260.36,203c.46-9.28,3.14-18.71,9.46-25.75a32.18,32.18,0,0,1,19.48-10.21,35.67,35.67,0,0,1,21.2,3.63,31.67,31.67,0,0,1,14.88,16.07c3.09,7.63,2.46,15.75,1.16,23.73a11.73,11.73,0,0,0-.09,3.83c.13.8,1.33.46,1.21-.33a17.33,17.33,0,0,1,.41-5.27c.26-1.78.47-3.56.62-5.35a51.26,51.26,0,0,0,.07-8,29.39,29.39,0,0,0-1.47-7,32.75,32.75,0,0,0-14.24-17.66,36.94,36.94,0,0,0-21.63-5.1,33.77,33.77,0,0,0-20.69,9c-6.71,6.35-10.17,15.23-11.27,24.26-.17,1.4-.28,2.8-.35,4.21,0,.81,1.21.8,1.25,0Z"
          style={{
            fill: '#95BD51',
            transformOrigin: '293.9966506958008px 190.1229305267334px',
          }}
          id="elzk4uzgxydx8"
          className="animable"
        />
        <path
          d="M324,219.91a20.08,20.08,0,0,1-.89-6c.09-1.27.27-2.53.39-3.79.25-2.4.49-4.8.7-7.2a44.44,44.44,0,0,0,.35-5.28.63.63,0,0,0-1.25,0,44.44,44.44,0,0,1-.35,5.28q-.33,3.7-.72,7.4c-.12,1.24-.32,2.5-.39,3.74a22.5,22.5,0,0,0,1,6.18c.18.78,1.39.45,1.2-.34Z"
          style={{
            fill: '#95BD51',
            transformOrigin: '323.1949920654297px 208.88702583312988px',
          }}
          id="el6tchhduxvgn"
          className="animable"
        />
        <path
          d="M265,205.8c.23-13.16,5.07-27.11,17.65-33.15a28.79,28.79,0,0,1,29.58,3.16,27.54,27.54,0,0,1,9.84,15.4c.19.78,1.4.45,1.21-.33a29,29,0,0,0-23.25-21.74,29.57,29.57,0,0,0-29.49,12.39c-4.86,7.09-6.64,15.8-6.79,24.27a.63.63,0,0,0,1.25,0Z"
          style={{
            fill: '#95BD51',
            transformOrigin: '293.5250549316406px 187.52232933044434px',
          }}
          id="elbhozupk4j6a"
          className="animable"
        />
        <path
          d="M268.94,224.54a.63.63,0,0,0,0-1.25.63.63,0,0,0,0,1.25Z"
          style={{
            fill: '#95BD51',
            transformOrigin: '268.94000244140625px 223.9149932861328px',
          }}
          id="elw5gpuympzm9"
          className="animable"
        />
        <path
          d="M320.82,223.25c-3.11-10.5.89-21.48-1.3-32.11a21.09,21.09,0,0,0-6.83-12,26,26,0,0,0-12.31-5.62c-7.05-1.27-14.73-.35-20.61,3.93-5.45,4-8.55,9.86-10.46,16.18-3,9.85-2.93,20.38-1,30.41.15.79,1.36.46,1.21-.33a63.45,63.45,0,0,1,.22-27c1.45-6,3.85-12,8.39-16.28,5-4.75,11.52-6.53,18.38-6.11,9.6.59,18.74,5.83,21.39,15.52,3,11.06-1.59,22.67,1.68,33.72a.63.63,0,0,0,1.21-.33Z"
          style={{
            fill: '#95BD51',
            transformOrigin: '293.8823528289795px 198.74296951293945px',
          }}
          id="ela7mj9mbdw2a"
          className="animable"
        />
        <path
          d="M272.35,198.06C271,208.41,272,219,273,229.27c.08.79,1.33.8,1.25,0-1-10.32-2-20.86-.65-31.21.1-.79-1.15-.78-1.25,0Z"
          style={{
            fill: '#95BD51',
            transformOrigin: '272.97450971603394px 213.66874504089355px',
          }}
          id="elg0uopuz0td"
          className="animable"
        />
        <path
          d="M318,226.66c-4.42-4.27-4.31-10.85-3.34-16.47,1-6,2.68-12,.86-18.06a21.59,21.59,0,0,0-23.18-15.24,20.69,20.69,0,0,0-14,7.88c-.49.65.6,1.27,1.08.64,6.24-8.32,18.91-9.74,27.18-3.73a21,21,0,0,1,8.05,12.15c1.41,6.22-.84,12.41-1.6,18.56-.67,5.32,0,11.24,4,15.15.58.56,1.46-.33.88-.88Z"
          style={{
            fill: '#95BD51',
            transformOrigin: '298.16800689697266px 202.23081016540527px',
          }}
          id="elolwttpjmdra"
          className="animable"
        />
        <path
          d="M314.13,228.38c-4-4-4.51-9.79-3.9-15.12.35-3.09,1-6.15,1.44-9.23a38,38,0,0,0,.78-8.36c-.66-9.08-9.6-15.1-18.18-15.14-7.6,0-13.51,5.74-16.5,12.27a20.82,20.82,0,0,0-1.51,5.91,63.65,63.65,0,0,0,0,14.07c.61,6.69,1.22,13.38,1.68,20.08,0,.8,1.3.81,1.25,0-.33-4.77-.75-9.54-1.16-14.31-.68-7.76-2.08-16.24.32-23.86,1.83-5.82,6.85-11.25,12.9-12.62,8.64-2,19.23,4.41,19.92,13.6.25,3.22-.48,6.51-1,9.67s-1.13,6.2-1.38,9.34c-.41,5.22.54,10.78,4.41,14.59.57.56,1.46-.32.88-.89Z"
          style={{
            fill: '#95BD51',
            transformOrigin: '295.0693874359131px 206.99690055847168px',
          }}
          id="elo5oap68gpw"
          className="animable"
        />
        <path
          d="M311.51,232c-5.93-7-5.89-16.22-4.27-24.77.87-4.58,2.78-9.49,1.26-14.14a12.33,12.33,0,0,0-7.24-7.31c-6.69-2.75-15.31-.15-18.82,6.32-1.74,3.23-2.25,7.09-2.53,10.69a77,77,0,0,0-.1,9.24c.36,8,1.14,16.6,4.47,24,.33.73,1.41.1,1.08-.63-2.91-6.43-3.69-13.92-4.16-20.89-.42-6.25-.62-13,1.22-19a13.81,13.81,0,0,1,17.5-8.85c3.71,1.2,6.93,4.11,7.69,8,.9,4.59-1.13,9.45-1.88,13.95-1.41,8.54-.94,17.41,4.89,24.28.52.62,1.4-.27.89-.88Z"
          style={{
            fill: '#95BD51',
            transformOrigin: '295.6902275085449px 210.52383995056152px',
          }}
          id="elsjz5ek0vq5a"
          className="animable"
        />
        <path
          d="M308.3,235a73.31,73.31,0,0,1-5.8-14.09,27.62,27.62,0,0,1-.95-7.57,30.59,30.59,0,0,1,1.38-7.52c1.09-3.88,2.1-8.08.28-11.91a8.2,8.2,0,0,0-9.08-4.29c-4.52.81-7.58,4.64-8.94,8.8-.25.77,1,1.1,1.21.34,1.23-3.77,4-7.23,8.06-7.93a7,7,0,0,1,8.38,6.07c.73,4.34-1.35,8.5-2.14,12.68a27.19,27.19,0,0,0,.77,12.32,78.62,78.62,0,0,0,5.75,13.73c.36.72,1.44.09,1.08-.63Z"
          style={{
            fill: '#95BD51',
            transformOrigin: '296.76476287841797px 212.68688583374023px',
          }}
          id="elv2uwe1k9kz"
          className="animable"
        />
        <path
          d="M288.4,219.41c1,7.17,4.13,13.68,6.89,20.28.3.73,1.51.41,1.2-.33-2.7-6.48-5.91-12.92-6.84-20-.1-.79-1.35-.8-1.25,0Z"
          style={{
            fill: '#95BD51',
            transformOrigin: '292.46737718582153px 229.4330759048462px',
          }}
          id="el97pfpetac8i"
          className="animable"
        />
        <path
          d="M303.87,236.52a45.46,45.46,0,0,1-6.61-21.7,44.16,44.16,0,0,1,1-11.35c.65-2.95,1.84-6.95-1.23-9s-6,1.13-7.14,3.77c-.32.73.75,1.37,1.07.63.72-1.62,2.05-3.93,4.13-3.8,3,.19,2.9,4,2.44,6A53.87,53.87,0,0,0,296,213.55a46.73,46.73,0,0,0,6.81,23.6c.42.68,1.5.06,1.08-.63Z"
          style={{
            fill: '#95BD51',
            transformOrigin: '296.9080910682678px 215.6295509338379px',
          }}
          id="eliygdy7yt05"
          className="animable"
        />
        <path
          d="M300.51,237.33c-6.84-11.31-8.1-24.74-5.82-37.57.14-.78-1.06-1.12-1.2-.33-2.34,13.16-1.08,26.93,5.94,38.53a.63.63,0,0,0,1.08-.63Z"
          style={{
            fill: '#95BD51',
            transformOrigin: '296.48752546310425px 218.59395599365234px',
          }}
          id="el2fftd9x48e7"
          className="animable"
        />
        <path
          d="M290,237.87c-7-14.46-5.72-34.41-5.7-34.61l1,.07c0,.2-1.28,19.88,5.61,34.1Z"
          style={{
            fill: '#95BD51',
            transformOrigin: '287.5529730319977px 220.56499481201172px',
          }}
          id="eli7q6wl456b"
          className="animable"
        />
        <path
          d="M289.15,200.44a34.39,34.39,0,0,0-1.36,15.41c.11.79,1.31.45,1.21-.34a32.76,32.76,0,0,1,1.36-14.74c.25-.76-1-1.09-1.21-.33Z"
          style={{
            fill: '#95BD51',
            transformOrigin: '288.92599523067474px 208.154034614563px',
          }}
          id="elnp03mejyqi"
          className="animable"
        />
        <path
          d="M284.74,200.11l-.12.67a.52.52,0,0,0,0,.25.49.49,0,0,0,.08.23.44.44,0,0,0,.16.17.36.36,0,0,0,.21.11l.17,0a.59.59,0,0,0,.31-.09l.13-.1a.57.57,0,0,0,.16-.27l.12-.67a.53.53,0,0,0,0-.25.49.49,0,0,0-.08-.23.46.46,0,0,0-.16-.18.53.53,0,0,0-.21-.11l-.17,0a.68.68,0,0,0-.31.08l-.13.1a.61.61,0,0,0-.16.28Z"
          style={{
            fill: '#95BD51',
            transformOrigin: '285.2898540496826px 200.58999633789062px',
          }}
          id="el75yxy35jhd4"
          className="animable"
        />
        <path
          d="M276.75,186.53a19.86,19.86,0,0,0-4,7.1A.63.63,0,0,0,274,194a18.27,18.27,0,0,1,3.65-6.54c.53-.61-.35-1.5-.88-.89Z"
          style={{
            fill: '#95BD51',
            transformOrigin: '275.2684211730957px 190.4161581993103px',
          }}
          id="el4vhpsz4tlm"
          className="animable"
        />
        <path
          d="M323.37,193.68l.12,1a.52.52,0,0,0,.05.24.6.6,0,0,0,.57.38.67.67,0,0,0,.45-.18.62.62,0,0,0,.18-.44l-.12-1a.39.39,0,0,0-.05-.24.53.53,0,0,0-.13-.2.62.62,0,0,0-.44-.18.6.6,0,0,0-.44.18.58.58,0,0,0-.19.44Z"
          style={{
            fill: '#95BD51',
            transformOrigin: '324.05495369434357px 194.18002843856812px',
          }}
          id="el8ygy6otvewe"
          className="animable"
        />
        <path
          d="M307.77,154.53a41.72,41.72,0,0,0-8.44-1.64.63.63,0,1,0,0,1.25,39.79,39.79,0,0,1,8.1,1.6c.77.25,1.1-1,.34-1.21Z"
          style={{
            fill: '#95BD51',
            transformOrigin: '303.4106225967407px 154.32894718647003px',
          }}
          id="el0utzoxolpwrh"
          className="animable"
        />
        <path
          d="M263.52,210.72a47.26,47.26,0,0,0,.46,6.06.62.62,0,0,0,1.2-.34,45.57,45.57,0,0,1-.41-5.72.63.63,0,0,0-1.25,0Z"
          style={{
            fill: '#95BD51',
            transformOrigin: '264.361823618412px 213.70130348205566px',
          }}
          id="elx13zsfppsi"
          className="animable"
        />
        <path
          d="M258.74,206.9l-.09,1.22a.61.61,0,0,0,.62.62.65.65,0,0,0,.44-.18.73.73,0,0,0,.19-.44l.09-1.22a.63.63,0,0,0-.19-.44.59.59,0,0,0-.44-.18.65.65,0,0,0-.44.18.67.67,0,0,0-.18.44Z"
          style={{
            fill: '#95BD51',
            transformOrigin: '259.31994265317917px 207.50993990898132px',
          }}
          id="els0u17hag2zi"
          className="animable"
        />
        <path
          d="M271,137.53l2.92-1.3a.63.63,0,0,0-.63-1.08l-2.92,1.3a.63.63,0,0,0,.63,1.08Z"
          style={{
            fill: '#95BD51',
            transformOrigin: '272.14498925209045px 136.3399976491928px',
          }}
          id="eljsxeqm1kz3"
          className="animable"
        />
        <path
          d="M331.18,192.34a21.53,21.53,0,0,1,.75,6.87c0,.81,1.21.8,1.25,0a22.45,22.45,0,0,0-.79-7.2.63.63,0,0,0-1.21.33Z"
          style={{
            fill: '#95BD51',
            transformOrigin: '332.1910078525543px 195.70852708816528px',
          }}
          id="elxs6553b6su"
          className="animable"
        />
        <path
          d="M332.25,180.4l.6,2a.62.62,0,0,0,.76.43.63.63,0,0,0,.44-.77l-.6-2a.62.62,0,1,0-1.2.33Z"
          style={{
            fill: '#95BD51',
            transformOrigin: '333.14957678318024px 181.22727918624878px',
          }}
          id="elf1f9tlo5fh"
          className="animable"
        />
        <path
          d="M367.61,183.22s0-.39-.06-1.17,0-1.94-.16-3.45a73,73,0,0,0-2.07-13.08,71.37,71.37,0,0,0-3-9.09,69.22,69.22,0,0,0-5-10.2,73.24,73.24,0,0,0-18.39-20.55,72.3,72.3,0,0,0-49.1-14.53,72.6,72.6,0,0,0-9.69,1.36,76.38,76.38,0,0,0-9.66,2.76,72.44,72.44,0,0,0-33.6,25.2A70.06,70.06,0,0,0,231,150c-.89,1.65-1.65,3.38-2.43,5.1s-1.42,3.52-2,5.34a72.28,72.28,0,0,0,0,45.63c.58,1.82,1.3,3.58,2,5.34s1.53,3.46,2.42,5.11a71.08,71.08,0,0,0,5.89,9.49,72.5,72.5,0,0,0,33.61,25.21,76.38,76.38,0,0,0,9.66,2.76,72.56,72.56,0,0,0,9.69,1.35,71.46,71.46,0,0,0,18.65-1,72.31,72.31,0,0,0,30.45-13.5,73.34,73.34,0,0,0,18.4-20.56,70,70,0,0,0,5-10.19,71.48,71.48,0,0,0,3-9.1,72.85,72.85,0,0,0,2.07-13.08c.14-1.51.11-2.67.16-3.44s.06-1.18.06-1.18,0,1.6-.11,4.63a70.5,70.5,0,0,1-4.93,22.26,70.83,70.83,0,0,1-5,10.25,73.4,73.4,0,0,1-18.43,20.7,72.56,72.56,0,0,1-49.35,14.69A73,73,0,0,1,280,254.4a79.3,79.3,0,0,1-9.74-2.77,72.93,72.93,0,0,1-33.85-25.36,72,72,0,0,1-5.93-9.55c-.9-1.67-1.66-3.41-2.45-5.15s-1.43-3.54-2-5.38a72.71,72.71,0,0,1,0-45.95c.58-1.83,1.3-3.6,2-5.38s1.54-3.47,2.44-5.14a71,71,0,0,1,5.94-9.55,73,73,0,0,1,33.84-25.36,77.52,77.52,0,0,1,9.74-2.76,71.39,71.39,0,0,1,9.75-1.35,72.19,72.19,0,0,1,18.75,1,72.7,72.7,0,0,1,30.6,13.63,73.33,73.33,0,0,1,18.42,20.7,70,70,0,0,1,5,10.26,70.31,70.31,0,0,1,4.93,22.26C367.65,181.63,367.61,183.22,367.61,183.22Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '294.96736907958984px 183.24536895751953px',
          }}
          id="elicpzrpjfefc"
          className="animable"
        />
        <path
          d="M295.1,263.46a81.21,81.21,0,0,1,0-162.42,1.5,1.5,0,0,1,0,3,78.21,78.21,0,1,0,78.2,78.21,1.5,1.5,0,1,1,3,0A81.3,81.3,0,0,1,295.1,263.46Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '295.0950012207031px 182.24999237060547px',
          }}
          id="elgcpt5kuxq7"
          className="animable"
        />
      </g>
      <defs>
        <filter id="active" height="200%">
          <feMorphology
            in="SourceAlpha"
            result="DILATED"
            operator="dilate"
            radius={2}
          />
          <feFlood floodColor="#32DFEC" floodOpacity={1} result="PINK" />
          <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
          <feMerge>
            <feMergeNode in="OUTLINE" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="hover" height="200%">
          <feMorphology
            in="SourceAlpha"
            result="DILATED"
            operator="dilate"
            radius={2}
          />
          <feFlood floodColor="#ff0000" floodOpacity="0.5" result="PINK" />
          <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
          <feMerge>
            <feMergeNode in="OUTLINE" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
          <feColorMatrix
            type="matrix"
            values="0   0   0   0   0                0   1   0   0   0                0   0   0   0   0                0   0   0   1   0 "
          />
        </filter>
      </defs>
    </svg>
  );
};

export default LegitimationIcon;

LegitimationIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};
