import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { AboutProject, ProjectFAQs, Support, RecentSupport } from './';
import { DonationIcon } from 'images';
import { device } from 'utils';
const ProjectTabWrapper = styled.div`
  display: flex;
  @media ${device.allMobile} {
    display: block;
    // margin-right: 20px;
    // margin-left: 20px;
  }
  @media ${device.tablet} {
    margin-right: 20px;
    margin-left: 20px;
  }
  .project {
    &__details {
      width: 100%;
      padding-right: 10px;
      @media ${device.allMobile} {
        width: 100%;
        padding-right: 10px;
        padding-left: 10px;
      }
      .donation-note {
        display: flex;
        align-items: center;
        padding: 20px;
        img {
          width: 24px;
          height: 24px;
          margin-right: 15px;
        }
      }
    }
    &__support {
      width: 34%;
      padding-left: 10px;
      @media ${device.allMobile} {
        width: 100%;
        padding-right: 10px;
        padding-left: 10px;
      }
    }
  }
`;

export default function ProjectTab(props) {
  const {
    match: {
      params: { url },
    },
    project,
    isViewPage,
    loadingDonations,
    getProjectDonations,
    donations,
    handelShowModal,
  } = props;

  return (
    <ProjectTabWrapper>
      <div className="project__details">
        <AboutProject isViewPage={isViewPage} handelShowModal ={handelShowModal} project={project} />
        <ProjectFAQs
          projectFaq={
            isViewPage
              ? project.projectDescription &&
                project.projectDescription.faq.length > 0
                ? project.projectDescription.faq
                : [
                    'Antwort aus der ersten Frage',
                    'Antwort aus der zweiten Frage',
                    'Antwort aus der dritten Frage',
                    'Antwort aus der vierten Frage',
                    'Antwort aus der fünften Frage',
                  ]
              : project.faq
          }
          isTicketable={project.is_ticketable}
          isPatreon={project.is_patreon}
        />
        {/* {project?.donation_receipts_possible && (
          <p className="donation-note">
            <img
              src={DonationIcon}
              alt="project"
              onClick={() => this.handelOpenImagesModal()}
            />
            Der Projektinitiator kann Spendenquittungen ausstellen.
          </p>
        )} */}
      </div>

      {/* <div className="project__support">
        {!isViewPage && (
          <>
            <Support
              projectUrl={url}
              projectId={project.id}
              projectStatus={project.status}
              donationsAverage={project.donations_average}
            />
            {project && project.id && (
              <RecentSupport
                loadingDonations={loadingDonations}
                projectId={project.id}
                getProjectDonations={getProjectDonations}
                donations={donations}
              />
            )}
          </>
        )}
      </div> */}
    </ProjectTabWrapper>
  );
}

ProjectTab.propTypes = {
  match: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  isViewPage: PropTypes.bool.isRequired,
  handelShowModal: PropTypes.bool.isRequired,
  updates: PropTypes.array.isRequired,
  loadingDonations: PropTypes.bool.isRequired,
  getProjectDonations: PropTypes.func.isRequired,
  donations: PropTypes.object.isRequired,
};
