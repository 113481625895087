import React from 'react';
import { Container, H1, H2, isMobile } from 'utils';
import { WhyIcon } from 'images';
import styled from '@emotion/styled';
import { SvgWrapper, Section, device } from 'utils';
import SectionTitleH2 from 'utils/SectionTitleH2';

const WhyCrowdfundingWrapper = styled.div`
  display: flex;
  align-items: center;
  @media ${device.allMobile} {
    flex-wrap: wrap;
  }
  .content {
    width: 60%;
    padding-left: 150px;
    text-align: center;
    @media ${device.laptop} {
      padding-left: 0;
    }
    @media ${device.allMobile} {
      width: 100%;
      order: 2;
      text-align: center;
      padding: 0;
    }
    h1 {
      margin-bottom: 10px;
      margin-top: 0;
      font-weight: bold;
      text-align: center;
      transform: translateX(-10%);
      @media ${device.laptop} {
        font-size: 20px;
      }
      @media ${device.allMobile} {
        justify-content: center;
        transform: translateX(0);
      }
    }
    p {
      margin: 0 0 10px;
      font-size: 22px;
      text-align: left;
      max-width: 90%;
      @media ${device.laptop} {
        font-size: 18px;
      }
      @media ${device.allMobile} {
        margin: auto;
        max-width: 90%;
        font-size: 16px;
      }
    }
  }
  .image {
    width: 40%;
    @media ${device.allMobile} {
      width: 100%;
      order: 1;
    }
    img {
      width: 60%;
      @media ${device.allMobile} {
        width: 100%;
      }
    }
  }
`;

export default function WhyCrowdfunding() {
  return (
    <Section>
      <Container>
        <WhyCrowdfundingWrapper>
          <div className="content">
            <SectionTitleH2>Was ist Crowdfunding?</SectionTitleH2>
            <p>
              “Private Projekte, innovative Produkte, Immobilien, Startups,
              etablierte Unternehmen und vieles mehr lassen sich durch das
              Crowdfunding finanzieren. Das Besondere dabei ist, dass eine
              Vielzahl von Menschen ein Projekt finanziell unterstützt und so
              möglich macht. Dabei wenden sich die Projektersteller direkt an
              die Öffentlichkeit, um möglichst viele Interessenten für eine
              gemeinschaftliche Finanzierung zu gewinnen. Ob ein Projekt
              realisiert wird, wird also nicht durch eine traditionelle Instanz
              – wie z.B. eine Bank oder Förderinstitution – sondern direkt durch
              die Community entschieden. Ideen können schnell und flexibel
              umgesetzt werden, während Anleger genau das finanzieren, woran sie
              glauben. Projektersteller und die Community können von der
              direkten Finanzierung profitieren.” (crowdfunding.de)
            </p>
          </div>
          <div className="image">
            <SvgWrapper width={isMobile() ? '100%' : '75%'} height="auto">
              <WhyIcon />
            </SvgWrapper>
          </div>
        </WhyCrowdfundingWrapper>
      </Container>
    </Section>
  );
}
