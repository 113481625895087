import React from 'react';
import { RejectIcon } from 'images';
import { SvgWrapper, Colors } from 'utils';
import styled from '@emotion/styled';

const FinishedPopWrapper = styled.div`
  background: #f9ad00;
  padding: 20px 30px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  border-radius: 15px;
  p {
    font-weight: bold;
    font-size: 22px;
    color: ${Colors.white};
    margin: 0;
    margin-left: 15px;
  }
`;
export default function FinishedPop() {
  return (
    <FinishedPopWrapper>
      <SvgWrapper width="50px" height="50px">
        <RejectIcon color={'#f9ad00'} />
      </SvgWrapper>
      <p>Der Finanzierungszeitraum deines Projektes ist zu Ende.</p>
    </FinishedPopWrapper>
  );
}
