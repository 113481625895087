import React from 'react';
import PropTypes from 'prop-types';
import { CardsComponent } from 'components';
import styled from '@emotion/styled';

const PaymentWrapper = styled.div`
  max-width: 650px;
  margin: auto;
`;

export default function Payment() {
  return (
    <PaymentWrapper>
      <CardsComponent noPay={true} />
    </PaymentWrapper>
  );
}

Payment.propTypes = {
  cards: PropTypes.array,
};
