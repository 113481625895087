import React, { Component } from 'react';
import { Collapse } from 'components';
import styled from '@emotion/styled';
import { Colors } from 'utils';
import PropTypes from 'prop-types';
import TextArea from 'antd/lib/input/TextArea';
import './project_details.css'
const ProjectFAQsWrapper = styled.div`
  padding: 5px 0px;
  .project__collapse {
    padding-bottom: 10px;
    padding-top: 10px;
    border-bottom: 0.5px solid ${Colors.secondaryColor};
    textarea{
      font-size:20px;
      line-height:32px;
      font-weight:400;
      color:#6d8083
    }
    h2 {
      font-size: 22px;
      font-weight: 600;
    }
    p {
      font-size: 20px;
      font-weight: 400;
      margin: 0;
      margin-bottom: 15px;
      color: ${Colors.steel};
    }
  }
`;

export default class ProjectFAQs extends Component {
  state = {
    currentOpen: null,
  };
  setCurrentOpen = (state, witchQut) => {
    if (state) {
      this.setState({ currentOpen: witchQut });
    } else {
      this.setState({ currentOpen: null });
    }
  };

  render() {
    const { currentOpen } = this.state;
    const { projectFaq, isTicketable, isPatreon } = this.props;
    return (
      <ProjectFAQsWrapper>
        <div>
          {isTicketable ? (<Collapse
            title={<h3>Was ist der Zweck der Veranstaltung?</h3>}
            arrowWidth={'20px'}
            className="project__collapse"
            defaultState={currentOpen === 'projectInfo' ? true : false}
            handleMultiCollapes={this.setCurrentOpen}
            witchQuestions={'projectInfo'}
          >
            {/* <p>{projectFaq && projectFaq[0]}</p> */}
            <TextArea
              autoSize
              readOnly
              className='text_area'
              value={projectFaq && projectFaq[0]}
            />
          </Collapse>) : isPatreon ? (<Collapse
            title={<h3>Wen unterstützt du?</h3>}
            arrowWidth={'20px'}
            className="project__collapse"
            defaultState={currentOpen === 'projectInfo' ? true : false}
            handleMultiCollapes={this.setCurrentOpen}
            witchQuestions={'projectInfo'}
          >
            {/* <p>{projectFaq && projectFaq[0]}</p> */}
            <TextArea
              autoSize
              readOnly
              className='text_area'
              value={projectFaq && projectFaq[0]}
            />
          </Collapse>): (<Collapse
            title={<h3>Was ist der genaue Zweck des Projektes?</h3>}
            arrowWidth={'20px'}
            className="project__collapse"
            defaultState={currentOpen === 'projectInfo' ? true : false}
            handleMultiCollapes={this.setCurrentOpen}
            witchQuestions={'projectInfo'}
          >
            {/* <p>{projectFaq && projectFaq[0]}</p> */}
            <TextArea
              autoSize
              readOnly
              className='text_area'
              value={projectFaq && projectFaq[0]}
            />
          </Collapse>)}
          <Collapse
            title={<h3>{isPatreon ? "Wieso solltest du mich unterstützen?" : "Was sind die Ziele und wer ist die Zielgruppe?"}</h3>}
            arrowWidth={'20px'}
            className="project__collapse"
            defaultState={currentOpen === 'projectGoal' ? true : false}
            handleMultiCollapes={this.setCurrentOpen}
            witchQuestions={'projectGoal'}
          >
            {/* <p>{projectFaq && projectFaq[1]}</p> */}
            <TextArea
              autoSize
              readOnly
              className='text_area'
              value={projectFaq && projectFaq[1]}
            />
          </Collapse>
          {!isTicketable && <Collapse
            title={
              <h3>{isPatreon ? "Wofür wird dein Support verwendet?" : "Warum sollte die Community dieses Projekt unterstützen?"}</h3>
            }
            arrowWidth={'20px'}
            className="project__collapse"
            defaultState={currentOpen === 'projectSupport' ? true : false}
            handleMultiCollapes={this.setCurrentOpen}
            witchQuestions={'projectSupport'}
          >
            {/* <p>{projectFaq && projectFaq[2]}</p> */}
            <TextArea
              autoSize
              readOnly
              className='text_area'
              value={projectFaq && projectFaq[2]}
            />
          </Collapse>}
          {!isTicketable && <Collapse
            title={<h3>{isPatreon ? "Was erhältst du für deinen Support?" : "Wofür wird das Geld benötigt?"}</h3>}
            arrowWidth={'20px'}
            className="project__collapse"
            defaultState={currentOpen === 'projectMoney' ? true : false}
            handleMultiCollapes={this.setCurrentOpen}
            witchQuestions={'projectMoney'}
          >
            {/* <p>{projectFaq && projectFaq[3]}</p> */}
            <TextArea
              autoSize
              readOnly
              className='text_area'
              value={projectFaq && projectFaq[3]}
            />
          </Collapse>}

          {isTicketable ? (
            <Collapse
              title={<h3>Wer sind die Organisatoren der Veranstaltung?</h3>}
              arrowWidth={'20px'}
              className="project__collapse"
              defaultState={currentOpen === 'projectPeople' ? true : false}
              handleMultiCollapes={this.setCurrentOpen}
              witchQuestions={'projectPeople'}
            >
              {/* <p>{projectFaq && projectFaq[4]}</p> */}
              <TextArea
                autoSize
                readOnly
                className='text_area'
                value={projectFaq && projectFaq[4]}
              />
            </Collapse>) : !isPatreon && (
            <Collapse
              title={<h3>Wer ist in diesem Projekt involviert?</h3>}
              arrowWidth={'20px'}
              className="project__collapse"
              defaultState={currentOpen === 'projectPeople' ? true : false}
              handleMultiCollapes={this.setCurrentOpen}
              witchQuestions={'projectPeople'}
            >
              {/* <p>{projectFaq && projectFaq[4]}</p> */}
              <TextArea
                autoSize
                readOnly
                className='text_area'
                value={projectFaq && projectFaq[4]}
              />
            </Collapse>)}
        </div>
      </ProjectFAQsWrapper>
    );
  }
}

ProjectFAQs.propTypes = {
  projectFaq: PropTypes.array,
};
