import React, { Component } from 'react';
import { Form, Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import uniqBy from 'lodash/uniqBy';
import PropTypes from 'prop-types';
const Option = Select.Option;
const FormItem = Form.Item;
export default class CitiesSelect extends Component {
  constructor(props) {
    super(props);
    this.lastFetchId = 0;
    this.fetchCities = debounce(this.fetchCities, 800);
  }
  static propTypes = {
    required: PropTypes.bool,
  };
  state = {
    link: '',
    data: [],
    fetching: false,
    value: [],
  };
  fetchCities = (value) => {
    this.lastFetchId += 1;
    const fetchId = this.lastFetchId;
    this.setState({ data: [], fetching: true });
    fetch(
      `https://wft-geo-db.p.rapidapi.com/v1/geo/cities?languageCode=DE&limit=10&includeDeleted=NONE&countryIds=DE&namePrefix=${value}`,
      {
        method: 'GET',
        headers: {
          'x-rapidapi-key':
            '4febbc1e2fmsh385c68cc8d777bdp191a31jsn35df7f7e0d4f',
          'x-rapidapi-host': 'wft-geo-db.p.rapidapi.com',
        },
      }
    ).then((cities) => {
      if (fetchId !== this.lastFetchId) {
        // for fetch callback order
        return;
      }
      const data = cities.data.map((city) => ({
        text: `${city.name}`,
        value: city.name,
      }));

      this.setState({ data: uniqBy(data, 'value'), fetching: false });
    });
  };
  handleChange = (value) => {
    this.setState({
      value,
      data: [],
      fetching: false,
    });
  };
  render() {
    const { fetching, data, value } = this.state;
    const { required } = this.props;
    return (
      <FormItem
        name="city"
        label="Deine Stadt"
        rules={[
          {
            required: required ? true : false,
            message: 'Bitte gib dein Deine Stadt ein.',
          },
        ]}
      >
        <Select
          showSearch
          value={value}
          placeholder="Wähle eine Stadt"
          notFoundContent={
            fetching ? <Spin size="small" /> : <p>Es gibt kein Ergebnis</p>
          }
          filterOption={false}
          onSearch={this.fetchCities}
          onKeyUp={this.handleChange}
          style={{ width: '100%' }}
        >
          {data.map((d) => (
            <Option key={d.value}>{d.text}</Option>
          ))}
        </Select>
      </FormItem>
    );
  }
}
