import SawBlack from './../../images/Black-sallallahu-alaihi-wasallam.png';
import './saw.css'
export default function MuhammedSAW(props) {
    const { image, ThankYouPage } = props;
    return (
        <div className={`${props.align ? 'flex-class2' : 'flex-class'}`}>
            <p className='p-class'></p>
            <p>Muhammed</p>
            <img className={` ${ThankYouPage ? 'image-class2' : 'image-class'}`} src={image || SawBlack} alt='صلى الله عليه وسلم'></img>
            <p></p>
        </div>
    )
};