import React from 'react';
import PropTypes from 'prop-types';
import { ArrowIcon } from 'images';
import { Colors, SvgWrapper } from 'utils';
import styled from '@emotion/styled';

const PrevArrowWrapper = styled.div`
  span {
    transform: rotate(180deg);
  }
`;

function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <PrevArrowWrapper className={className} onClick={onClick}>
      <SvgWrapper width="16px" height={'16px'}>
        <ArrowIcon color={Colors.secondaryColor} />
      </SvgWrapper>
    </PrevArrowWrapper>
  );
}

PrevArrow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default PrevArrow;
