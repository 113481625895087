import { authServices } from 'services';
import { AlertActions } from 'redux/actions';
import authConstants from './AuthTypes';

const login = ({ email, password }) => {
  return (dispatch) => {
    dispatch(loginRequest({ email }));
    authServices
      .login({ email, password })
      .then((res) => {
        localStorage.setItem('user', JSON.stringify(res.user.data));
        document.body.style.overflow = 'unset';
        return dispatch(loginSuccess(res.user.data));
      })
      .catch((error) => {
        error.errors.map((err) => dispatch(AlertActions.error(err)));
        dispatch(AlertActions.clear());
        return dispatch(loginFailure(error.errors));
      });
  };
};

const loginRequest = (user) => ({ type: authConstants.LOGIN_REQUEST, user });
const loginSuccess = (user) => ({ type: authConstants.LOGIN_SUCCESS, user });
const loginFailure = (error) => ({
  type: authConstants.LOGIN_FAILURE,
  error,
});

const register = (user) => {
  return (dispatch) => {
    dispatch(registerRequest(user));
    authServices
      .register(user)
      .then((res) => {
        localStorage.setItem('user', JSON.stringify(res.user.data));
        document.body.style.overflow = 'unset';
        dispatch(AlertActions.warning('Bitte bestätige deine E-Mail, um die Anmeldung abzuschließen.', 'Registrierung noch nicht abgeschlossen!'));
        return dispatch(registerSuccess(res.user.data));
      })
      .catch((error) => {
        error?.errors?.map((err) => dispatch(AlertActions.error(err)));
        dispatch(AlertActions.clear());
        return dispatch(registerFailure(error?.errors?.full_messages));
      });
  };
};

const registerRequest = (user) => ({
  type: authConstants.SIGN_UP_REQUEST,
  user,
});
const registerSuccess = (user) => ({
  type: authConstants.SIGN_UP_SUCCESS,
  user,
});
const registerFailure = (error) => ({
  type: authConstants.SIGN_UP_FAILURE,
  error,
});

const updateProfile = (user) => {
  return (dispatch) => {
    dispatch(updateProfileRequest(user));
    authServices
      .updateProfile(user)
      .then((res) => {
        localStorage.setItem('user', JSON.stringify(res.user.data));
        dispatch(
          AlertActions.success(`Dein Profil wurde aktualisiert`, 'Update')
        );
        return dispatch(updateProfileSuccess(res.user.data));
      })
      .catch((error) => {
        error.errors.map((err) => dispatch(AlertActions.error(err)));
        dispatch(AlertActions.clear());
        return dispatch(updateProfileFailure(error.errors.full_messages));
      });
  };
};

const updateProfileRequest = (user) => ({
  type: authConstants.UPDATE_PROFILE_REQUEST,
  user,
});
const updateProfileSuccess = (user) => ({
  type: authConstants.UPDATE_PROFILE_SUCCESS,
  user,
});
const updateProfileFailure = (error) => ({
  type: authConstants.UPDATE_PROFILE_FAILURE,
  error,
});

const resetPassword = (email) => {
  return (dispatch) => {
    dispatch(resetPasswordRequest());
    authServices
      .resetPassword(email)
      .then((res) => {
        dispatch(AlertActions.success('Bitte überprüfe deine E-Mail.'));
        return dispatch(resetPasswordSuccess(res));
      })
      .catch((error) => {
        error.errors.map((err) => dispatch(AlertActions.error(err)));
        dispatch(AlertActions.clear());
        return dispatch(resetPasswordFailure(error.errors));
      });
  };
};

const resetPasswordRequest = () => ({
  type: authConstants.RESET_PASSWORD_REQUEST,
});
const resetPasswordSuccess = (email) => ({
  type: authConstants.RESET_PASSWORD_SUCCESS,
  email,
});
const resetPasswordFailure = (error) => ({
  type: authConstants.RESET_PASSWORD_FAILURE,
  error,
});

const changePassword = (passwords) => {
  return async (dispatch) => {
    dispatch(changePasswordRequest());
    await authServices
      .changePassword(passwords)
      .then((res) => {
        dispatch(AlertActions.success('Dein Passwort wurde geändert.'));
        return dispatch(changePasswordSuccess(res));
      })
      .catch((error) => {
        console.log(error);
        error.errors.map((err) => dispatch(AlertActions.error(err)));
        dispatch(AlertActions.clear());
        return dispatch(changePasswordFailure(error.errors));
      });
  };
};

const changePasswordRequest = () => ({
  type: authConstants.CHANGE_PASSWORD_REQUEST,
});
const changePasswordSuccess = (passwords) => ({
  type: authConstants.CHANGE_PASSWORD_SUCCESS,
  passwords,
});
const changePasswordFailure = (error) => ({
  type: authConstants.CHANGE_PASSWORD_FAILURE,
  error,
});

const logout = () => {
  return (dispatch) => {
    authServices
      .logout()
      .then((res) => {
        localStorage.removeItem('user');
        localStorage.removeItem('user-type');
        localStorage.removeItem('access-token');
        localStorage.removeItem('client');
        localStorage.removeItem('uid');

        return dispatch(logoutSuccess(res));
      })
      .catch(() => {
        localStorage.removeItem('user');
        localStorage.removeItem('user-type');
        localStorage.removeItem('access-token');
        localStorage.removeItem('client');
        localStorage.removeItem('uid');
        return;
      });
  };
};

const sendContact = (email) => {
  return (dispatch) => {
    authServices
      .sendContact(email)
      .then((res) => {
        return dispatch(AlertActions.success('Gesendet.'));
      })
      .catch((error) => {
        error.errors.map((err) => dispatch(AlertActions.error(err)));

        return dispatch(AlertActions.clear());
      });
  };
};

const logoutSuccess = (user) => ({
  type: authConstants.LOGOUT,
  user,
});

const resetWelcome = () => {
  return (dispatch) => {
    return dispatch(resetWelcomeSuccess());
  };
};
const resetWelcomeSuccess = () => ({
  type: authConstants.REST_WELCOME,
});

const sendSuccessURL = (id) => {
  return (dispatch) => {
    console.log('SS-->', id);
    authServices.sendSessionId(id);
  };
};

const authActions = {
  login,
  register,
  updateProfile,
  resetPassword,
  changePassword,
  logout,
  resetWelcome,
  sendContact,
  sendSuccessURL,
};

export default authActions;
