import styled from '@emotion/styled';
import React from 'react';
import { SvgWrapper, H1, Colors, device, Container } from 'utils';
import { ContactIcon, BeforeBackground, AfterBackground } from 'images';

const StartProjectWrapper = styled.div`
  display: flex;
  align-items: end;
  // margin: 200px 0;
  @media ${device.allMobile} {
    flex-wrap: wrap;
    // margin: 25px 0;
  }
  .content {
    width: 60%;

    text-align: left;
    padding-top: 50px;
    margin-top: 10px;
    margin-bottom: 0;
    @media ${device.laptop} {
      padding-top: 0;
    }
    @media ${device.allMobile} {
      width: 100%;
      order: 2;
      text-align: center;
    }
    .content {
      width: 90%;
      padding-top: 0;
      margin-top: 0;
      margin-bottom: 0;
    }
    h1 {
      margin-bottom: 30px;
      margin-top: 0;
      display: flex;
      align-items: center;
      @media ${device.laptop} {
        font-size: 25px;
      }
      @media ${device.allMobile} {
        justify-content: center;
      }
      &::before {
        content: '2';
        font-size: 30px;
        background: ${Colors.secondaryColor};
        width: 50px;
        text-align: center;
        height: 50px;
        line-height: 50px;
        vertical-align: middle;
        border-radius: 50%;
        display: inline-block;
        margin-right: 10px;
        color: ${Colors.white};
      }
    }
    p {
      margin: 0 0 10px;
      font-size: 22px;
      max-width: 100%;
      margin-left: 60px;
      @media ${device.laptop} {
        font-size: 18px;
      }
      @media ${device.allMobile} {
        margin: auto;
        max-width: 90%;
      }
    }
  }
  .image {
    width: 40%;
    @media ${device.allMobile} {
      width: 100%;
      order: 1;
    }
  }
`;
const StartWrapper = styled.div`
  position: relative;
  .container {
    background: #f9f9f9;
    margin-top: -8px;
  }
`;
export default function StartProject() {
  return (
    <StartWrapper>
      <div className="background">
        <SvgWrapper width="100%" height="100%">
          <BeforeBackground />
        </SvgWrapper>
      </div>
      <div className="container">
        <Container>
          <StartProjectWrapper>
            <div className="image">
              <SvgWrapper width="70%" height="100%">
                <ContactIcon />
              </SvgWrapper>
            </div>
            <div className="content">
              <H1>Erstelle dein Projekt</H1>
              <p>
                Du registrierst dich ganz einfach auf commonsplace. Nach
                Abschluss deiner Registrierung, kannst du nun dein Projekt
                erstellen, indem du deine Projektdaten einpflegst, wie z.B. eine
                motivierende Projektbeschreibung, die Fundingsumme und deine
                Projektlaufzeit. Jetzt kann es auch schon losgehen!
              </p>
            </div>
          </StartProjectWrapper>
        </Container>
      </div>
      <div className="after-background">
        <SvgWrapper width="100%" height="100%">
          <AfterBackground />
        </SvgWrapper>
      </div>
    </StartWrapper>
  );
}
