/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { Colors } from 'utils';
const KlarnaCard = ({ color, width, height }) => {
  return (
    <svg
      height={`${height || '100%'}`}
      width={`${width || '100%'}`}
      viewBox="0 0 49.866 49.866"
      css={css`
        fill: ${color || Colors.white};
        max-width: ${width || '100%'};
        max-height: ${height || '100%'};
        height: ${height || '100%'};
        width: ${width || '100%'};
        display: inline-block;
      `}
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 512 512"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      xmlSpace="preserve"
    >
      <g>
        <g>
          <g>
            <path
              d="M13.201,18.265c-0.553,0-1.105,0-1.658,0c-0.415,0.001-0.454,0.042-0.454,0.462c0,2.029,0,4.059,0,6.088    c0,2.067,0,4.134,0,6.203c0,0.343,0.059,0.4,0.397,0.402c0.542,0.003,1.085,0,1.629,0c0.511,0,0.558-0.045,0.558-0.549    c0-3.478,0-6.956,0-10.433c0-0.572,0.001-1.143,0-1.715C13.672,18.334,13.6,18.266,13.201,18.265z"
              fill={color || Colors.white}
              data-original="#000000"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M2.339,18.266c-0.505-0.003-1.01-0.005-1.516,0.001C0.293,18.275,0,18.577,0,19.109c0,1.963,0,3.927,0,5.89    c0,1.993,0,3.983,0,5.976c0,0.349,0.047,0.396,0.397,0.396c0.514,0.004,1.029,0.004,1.543,0c0.54-0.004,0.811-0.273,0.811-0.81    c0-3.955,0-7.912,0-11.866C2.752,18.348,2.679,18.269,2.339,18.266z"
              fill={color || Colors.white}
              data-original="#000000"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M9.724,31.072c-0.147-1.464-0.68-2.763-1.705-3.836c-1.104-1.154-2.462-1.789-3.943-1.936    c-0.478-0.009-0.482-0.009-0.469,0.352c0.005,0.133,0.031,0.264,0.051,0.396c0.265,1.755,1.123,3.153,2.551,4.198    c0.936,0.688,1.995,1.045,3.148,1.153C9.7,31.432,9.758,31.408,9.724,31.072z"
              fill={color || Colors.white}
              data-original="#000000"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M9.365,18.298c-0.131,0.021-0.267,0.013-0.397,0.033c-1.502,0.239-2.777,0.904-3.791,2.043    c-0.929,1.042-1.445,2.271-1.567,3.659c-0.031,0.362-0.011,0.363,0.37,0.412c0.523-0.129,1.067-0.208,1.567-0.398    c2.497-0.948,3.863-2.788,4.178-5.428C9.765,18.284,9.709,18.246,9.365,18.298z"
              fill={color || Colors.white}
              data-original="#000000"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M22.763,24.496c-0.038-1.457-0.867-2.432-2.269-2.777c-1.555-0.386-3.096-0.16-4.629,0.167    c-0.182,0.038-0.257,0.169-0.253,0.36c0.008,0.341,0.002,0.685,0.002,1.028c0,0.489,0.064,0.513,0.548,0.484    c0.987-0.056,1.976-0.098,2.964-0.098c0.59,0,0.948,0.333,1.046,0.867c0.031,0.166,0.019,0.342,0.019,0.513    c0.002,0.663,0.001,0.669-0.653,0.661c-0.914-0.009-1.825,0.021-2.71,0.289c-0.914,0.274-1.571,0.815-1.769,1.789    c-0.127,0.629-0.114,1.264,0.039,1.896c0.212,0.875,0.742,1.471,1.608,1.729c0.962,0.283,1.915,0.242,2.83-0.201    c0.262-0.127,0.511-0.283,0.804-0.446c0.012,0.091,0.021,0.137,0.023,0.184c0.022,0.43,0.076,0.484,0.512,0.484    c0.514,0.001,1.028,0.002,1.543,0c0.299-0.002,0.405-0.097,0.404-0.388C22.809,28.856,22.82,26.674,22.763,24.496z M19.938,29.408    c-0.56,0.217-1.126,0.334-1.727,0.23c-0.374-0.064-0.648-0.286-0.68-0.666c-0.028-0.347-0.008-0.705,0.06-1.044    c0.073-0.362,0.339-0.627,0.702-0.673c0.592-0.073,1.191-0.089,1.787-0.11c0.053-0.002,0.154,0.127,0.159,0.201    c0.019,0.294,0.008,0.59,0.008,0.885c0,0.23-0.018,0.46,0.005,0.687C20.282,29.182,20.179,29.316,19.938,29.408z"
              fill={color || Colors.white}
              data-original="#000000"
            />
          </g>
        </g>
        <g>
          <g>
            <polygon
              points="48.592,20.968 48.592,21.043 48.802,21.043 48.802,21.656 48.892,21.656 48.892,21.043 49.102,21.043     49.102,20.968   "
              fill={color || Colors.white}
              data-original="#000000"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M49.823,20.968h-0.119l-0.123,0.333c-0.029,0.087-0.055,0.162-0.072,0.232h-0.001c-0.021-0.072-0.042-0.148-0.07-0.232    l-0.117-0.333h-0.113l-0.047,0.688h0.084l0.02-0.296c0.006-0.104,0.011-0.219,0.013-0.304h0.002    c0.021,0.081,0.047,0.17,0.079,0.267l0.112,0.329h0.067l0.121-0.335c0.035-0.095,0.062-0.181,0.086-0.261h0.002    c0,0.084,0.006,0.202,0.013,0.297l0.016,0.303h0.09L49.823,20.968z"
              fill={color || Colors.white}
              data-original="#000000"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M39.115,25.146c-0.006-0.52-0.067-1.046-0.18-1.557c-0.225-1.055-0.83-1.798-1.941-1.992    c-1.213-0.215-2.369-0.043-3.41,0.663c-0.102,0.068-0.204,0.13-0.379,0.241c-0.008-0.205-0.02-0.332-0.022-0.461    c-0.008-0.336-0.066-0.399-0.404-0.401c-0.537-0.003-1.069-0.004-1.602,0.001c-0.314,0.002-0.408,0.1-0.408,0.413    c-0.001,2.973-0.001,5.945,0,8.918c0,0.349,0.094,0.445,0.436,0.447c0.591,0.002,1.182-0.006,1.773,0.005    c0.264,0.003,0.383-0.101,0.378-0.372c-0.01-0.59-0.003-1.18-0.003-1.772c0-1.572-0.003-3.144,0.006-4.716    c0-0.13,0.034-0.325,0.121-0.379c0.656-0.404,1.36-0.652,2.143-0.522c0.431,0.071,0.733,0.324,0.814,0.769    c0.057,0.308,0.1,0.621,0.1,0.933c0.012,1.869,0.006,3.736,0.006,5.604c0,0.407,0.047,0.452,0.464,0.452    c0.534,0.003,1.069,0.003,1.602,0c0.461,0,0.514-0.051,0.514-0.502C39.123,28.996,39.136,27.072,39.115,25.146z"
              fill={color || Colors.white}
              data-original="#000000"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M48.216,25.035c-0.001-0.293-0.015-0.59-0.06-0.884c-0.178-1.14-0.789-1.954-1.896-2.323    c-0.464-0.155-0.959-0.262-1.446-0.293c-1.164-0.073-2.314,0.066-3.453,0.323c-0.25,0.058-0.355,0.194-0.354,0.438    c0.001,0.314,0,0.629,0,0.943c0,0.506,0.07,0.545,0.562,0.519c0.98-0.055,1.959-0.099,2.938-0.105    c0.632-0.002,0.986,0.323,1.093,0.948c0.048,0.28,0.022,0.571,0.037,0.854c0.01,0.188-0.079,0.25-0.259,0.244    c-0.41-0.006-0.825-0.041-1.229,0.008c-0.695,0.082-1.4,0.162-2.072,0.35c-0.891,0.251-1.469,0.874-1.615,1.812    c-0.059,0.389-0.049,0.793-0.03,1.189c0.076,1.608,1.303,2.664,2.908,2.534c0.811-0.063,1.575-0.256,2.239-0.751    c0.045-0.032,0.102-0.05,0.164-0.078c0.029,0.044,0.047,0.055,0.049,0.073c0.01,0.048,0.015,0.095,0.017,0.144    c0.022,0.415,0.049,0.44,0.475,0.44c0.496,0.003,0.99,0.003,1.486,0c0.399,0,0.448-0.045,0.448-0.438    C48.219,29,48.221,27.017,48.216,25.035z M45.697,29.009c0.007,0.182-0.071,0.278-0.23,0.349    c-0.585,0.259-1.186,0.392-1.829,0.287c-0.463-0.079-0.734-0.393-0.726-0.975c0.015-0.989,0.233-1.436,1.436-1.527    c0.359-0.028,0.724,0.001,1.085-0.007c0.203-0.009,0.268,0.086,0.264,0.274c-0.009,0.276-0.004,0.554-0.004,0.828    C45.695,28.494,45.685,28.75,45.697,29.009z"
              fill={color || Colors.white}
              data-original="#000000"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M29.692,21.877c0.001-0.255-0.113-0.364-0.366-0.354c-0.744,0.032-1.416,0.266-2.018,0.701    c-0.082,0.06-0.164,0.115-0.287,0.203C27.011,22.29,27,22.209,27,22.127c-0.008-0.41-0.08-0.486-0.481-0.486    c-0.517-0.002-1.029-0.001-1.544-0.001c-0.471,0.001-0.549,0.077-0.549,0.546c0,1.314,0,2.63,0,3.944c0,1.594-0.001,3.185,0,4.774    c0,0.422,0.093,0.516,0.494,0.516c0.542,0.003,1.087,0.003,1.63,0c0.438,0,0.508-0.066,0.508-0.518    c0.001-2.113,0.003-4.229-0.004-6.347c0-0.209,0.061-0.328,0.253-0.419c0.636-0.299,1.288-0.523,2.003-0.494    c0.262,0.011,0.389-0.088,0.383-0.365C29.682,22.809,29.684,22.344,29.692,21.877z"
              fill={color || Colors.white}
              data-original="#000000"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default KlarnaCard;

KlarnaCard.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};
