import React, { Component } from 'react';
import styled from '@emotion/styled';
import { TabsHeader, ProjectTab, TimeLineTab } from './';

const TabsBody = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 0 0 10px 10px;
  margin-bottom: 20px;
`;

export default class Tabs extends Component {
  state = {
    componentHasViewed: 'project',
  };

  setComponentHasViewed = (componentHasViewed) => {
    this.setState({ componentHasViewed });
  };

  render() {
    const { componentHasViewed } = this.state;
    const handleViewComponent = () => {
      switch (componentHasViewed) {
        case 'project':
          return <ProjectTab />;
        case 'timeLine':
          return <TimeLineTab />;
        case 'Supprters':
          return <h1>Supprters</h1>;
        case 'Sponsored':
          return <h1>Sponsored</h1>;
        case 'Tab5':
          return <h1>Tab 5</h1>;
        default:
          break;
      }
    };
    return (
      <div>
        <TabsHeader
          handleTabToggle={this.setComponentHasViewed}
          selected={componentHasViewed}
        />
        <TabsBody>{handleViewComponent()}</TabsBody>
      </div>
    );
  }
}
