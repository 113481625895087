/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { Colors } from 'utils';
const InfoIcon = ({ color, width, height }) => {
  return (
    <svg
      enableBackground="new 0 0 512 512"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      height={`${height || '100%'}`}
      width={`${width || '100%'}`}
      viewBox="0 0 512 512"
      css={css`
        fill: ${color || Colors.black};
        max-width: ${width || '100%'};
        max-height: ${height || '100%'};
        height: ${height || '100%'};
        width: ${width || '100%'};
        display: inline-block;
      `}
    >
      <g>
        <g>
          <g>
            <path
              d="M256,85.333c-23.531,0-42.667,19.135-42.667,42.667s19.135,42.667,42.667,42.667s42.667-19.135,42.667-42.667
				S279.531,85.333,256,85.333z M256,149.333c-11.76,0-21.333-9.573-21.333-21.333s9.573-21.333,21.333-21.333
				s21.333,9.573,21.333,21.333S267.76,149.333,256,149.333z"
            />
            <path
              d="M288,192h-85.333c-5.896,0-10.667,4.771-10.667,10.667v42.667c0,5.896,4.771,10.667,10.667,10.667h10.667v160
				c0,5.896,4.771,10.667,10.667,10.667h64c5.896,0,10.667-4.771,10.667-10.667V202.667C298.667,196.771,293.896,192,288,192z
				 M277.333,405.333h-42.667v-160c0-5.896-4.771-10.667-10.667-10.667h-10.667v-21.333h64V405.333z"
            />
            <path
              d="M256,0C114.844,0,0,114.844,0,256s114.844,256,256,256s256-114.844,256-256S397.156,0,256,0z M256,490.667
				C126.604,490.667,21.333,385.396,21.333,256S126.604,21.333,256,21.333S490.667,126.604,490.667,256S385.396,490.667,256,490.667
				z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default InfoIcon;

InfoIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};
