import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CancelImg } from 'images';
import config from 'config';
// import { PaymentActions } from 'redux/actions';
import {
  SvgWrapper,
  Container,
  H1,
  // IsLoggedIn,
  Colors,
  PrimaryButton,
  // PrimaryButton,
  isMobile,
} from 'utils';
import { Header, Footer } from 'components';
import { loadStripe } from '@stripe/stripe-js';
// import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { store } from 'react-notifications-component';
const notification = {
  insert: 'top',
  container: 'top-right',
  animationIn: ['animate__animated animate__fadeIn'], // `animate.css v4` classes
  animationOut: ['animate__animated animate__fadeOut'], // `animate.css v4` classes
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
};
const stripePromise = loadStripe(config.REACT_APP_STRIPE_KEY);
const ThankYouWrapper = styled.div`
  text-align: center;
  h1 {
    font-weight: bold;
    color: ${Colors.GreenColor};
    display: block;
    & + button {
      font-size: 22px;
    }
  }
`;

export class CancelPage extends Component {
  componentDidMount() {
    // const { resetDonation } = this.props;
    // resetDonation();
  }
  static propTypes = {
    // resetDonation: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
  };
  onClick = async () => {
    const {
      location: { search },
    } = this.props;
    const donation = new URLSearchParams(search).get('json');
    const stripe = await stripePromise;
    const response = await fetch('/payments/payment_session', {
      method: 'POST',
      body: JSON.stringify({ payment_session: JSON.parse(donation) }),
      redirect: 'follow',
    });
    // When the customer clicks on the button, redirect them to Checkout.
    const result = await stripe.redirectToCheckout({
      sessionId: response.id,
    });
    if (result.error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
      store.addNotification({
        ...notification,
        title: 'Fehler!',
        type: 'danger',
        message: result.error.message,
      });
    }
  };
  render() {
    return (
      <div>
        <Header relativeHeader={true} />
        <Container>
          <ThankYouWrapper>
            <div>
              <SvgWrapper width={isMobile() ? '80%' : '500px'} height={'auto'}>
                <picture>
                  <img src={CancelImg} alt="cancel-img" />
                </picture>
              </SvgWrapper>
            </div>

            <H1>Ups... irgendwas hat nicht funktioniert.</H1>
            <PrimaryButton onClick={() => this.onClick()}>
              Zurück zum Zahlungsprozess
            </PrimaryButton>
          </ThankYouWrapper>
        </Container>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  // resetDonation: () => dispatch(PaymentActions.resetDonation()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CancelPage);
