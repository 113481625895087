import React from 'react';
import { CheckCircleFilled } from '@ant-design/icons';
import { Colors, PrimaryButton } from 'utils';
import styled from '@emotion/styled';

const SuccessWrapper = styled.div`
  margin: 100px auto;
  text-align: center;
  .icon {
    color: ${Colors.secondaryColor};
    font-size: 150px;
  }
  h1 {
    font-size: 35px;
    max-width: 70%;
    margin: auto;
    margin-bottom: 50px;
  }
  button {
    margin-bottom: 50px;
  }
`;

export default function Success() {
  return (
    <SuccessWrapper>
      <div className="icon">
        <CheckCircleFilled />
      </div>

      <h1>
        Vielen Dank für dein Update! Die Veränderungen werden nun überprüft.
      </h1>
      <PrimaryButton>Wetiter zu Projektansicht</PrimaryButton>
    </SuccessWrapper>
  );
}
