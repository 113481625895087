import React from 'react';
import { PrimaryButton, H2 } from 'utils';
import PropTypes from 'prop-types';

export default function Danke(props) {
  return (
    <div>
      <div style={{ lineHeight: '14px', wordBreak:'normal' }}>
        <span style={{ fontSize: '14px', fontWeight: 'bold' }}>
          *Danke für dein Trinkgeld!
        </span>
        <span style={{ alignItems: 'center' }}>
          <span
            style={{ fontSize: '12px', lineHeight: '10px', marginLeft: '5px' }}
          >
            Wir glauben daran, dass Muslime in Deutschland mit ihren
            vielfältigen Werten und Prinzipien einen großartigen Beitrag für das
            Gemeinwohl leisten können. commonsplace arbeitet tagtäglich daran,
            diesem Ziel näher zu kommen. Um die Plattform stetig zu verbessern,
            Kosten decken und unabhängig bleiben zu können, brauchen wir deine
            Unterstützung!
          </span>
          <span
            onClick={() => props.handelShowModal()}
            style={{
              color: '#95BD51',
              fontWeight: 'bold',
              cursor: 'pointer',
              padding: '10px 5px',
              fontSize:"12px"
            }}
          >
            Mehr lesen
          </span>
        </span>
      </div>
    </div>
  );
}

Danke.propTypes = {
  handelShowModal: PropTypes.func.isRequired,
};
