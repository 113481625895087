import React, { Component } from 'react';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import "./RichTextCss.css";

function convertToPlainText(editorContent) {
  const blocks = convertToRaw(editorContent).blocks;
  const plainText = blocks.map(block => block.text).join('\n');
  return plainText;
}

export default class RichText extends Component {
  state = {
    editorState: EditorState.createEmpty(),
  };

  componentDidMount() {
    const { value } = this.props;
    if (value) {
      const contentState = ContentState.createFromText(value);
      const editorState = EditorState.createWithContent(contentState);
      this.setState({ editorState });
    }
  }

  onEditorStateChange = (editorState) => {
    this.setState({ editorState });

    const { getContent, name } = this.props;
    const contentState = editorState.getCurrentContent();
    const plainText = convertToPlainText(contentState);
    getContent(plainText, name);
  };

  render() {
    return (
      <div>
        <Editor
          editorState={this.state.editorState}
          toolbarClassName="toolbar"
          wrapperClassName=""
          editorClassName="editor"
          toolbar={false}
          onEditorStateChange={this.onEditorStateChange}
        />
      </div>
    );
  }
}
