import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Colors } from 'utils';
import moment from 'moment';
const SingleTimeWrapper = styled.div`
  margin-left: 30px;
  .timeline {
    &__title {
      position: relative;
      &::before {
        content: '';
        display: inline-block;
        width: 10px;
        height: 10px;
        background: ${Colors.secondaryColor};
        border-radius: 50%;
        position: absolute;
        left: -20px;
        top: 50%;
        transform: translateY(-50%);
      }
      h2 {
        font-size: 22px;
        font-weight: bold;
        color: ${Colors.black};
        margin: 0;
        span {
          margin-left: 5px;
          background: ${Colors.grey};
          padding: 5px;
        }
      }
    }
    &__content {
      position: relative;
      p {
        margin: 0;
        padding-top: 10px;
        padding-bottom: 10px;
      }
      &::before {
        content: '';
        display: inline-block;
        width: 4px;
        height: 100%;
        background: ${Colors.secondaryColor};
        position: absolute;
        left: -17px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
`;

function SingleTime(props) {
  const { time, by, content, image } = props;
  return (
    <SingleTimeWrapper>
      <div className="timeline__title">
        <h2>
          {moment.unix(time).format('DD.MM.YYYY')} <span> {by}</span>
        </h2>
      </div>
      <div className="timeline__content">
        <p>
          {image && (
            <img
              style={{ display: 'block' }}
              src={image}
              max-width="1024px"
              alt="project-updated-img"
            />
          )}
          {content}
        </p>
      </div>
    </SingleTimeWrapper>
  );
}

SingleTime.propTypes = {
  time: PropTypes.number.isRequired,
  by: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  image: PropTypes.any,
};

export default SingleTime;
