import React from 'react';
import styled from '@emotion/styled';
import { Idea, StartProject, CreateProject } from './';
import { Colors, Container, Section, TitleBanner } from 'utils';
import SectionTitleH2 from 'utils/SectionTitleH2';

const HowToStartProjectWrapper = styled.section`
  text-align: center;
`;
export default function HowToStartProject() {
  return (
    <Section>
      <HowToStartProjectWrapper>
        <Container>
          <>
            <TitleBanner color={Colors.GreenColor}>
              <h3>Wie startest du ein Projekt?</h3>
            </TitleBanner>
            <Idea />
          </>
        </Container>
        <StartProject />
        <Container>
          <>
            <CreateProject />
          </>
        </Container>
      </HowToStartProjectWrapper>
    </Section>
  );
}
