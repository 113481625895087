import React, { Component } from 'react';
import { Form, Select, Input, DatePicker, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { AddressForm } from 'components';
import { dateFormat, H2, eighteenYearsFromNow } from 'utils';
const FormItem = Form.Item;
const { Option } = Select;

export default class Unternehmen extends Component {
  state = {
    unternehmen: null,
    showPersonalInfo: false,
  };
  onChange = (value) => {
    this.setState({ unternehmen: value });
  };
  handleShowInfo = (value) => {
    this.setState({ showPersonalInfo: value === 'Ja' ? false : true });
  };
  render() {
    const { unternehmen, showPersonalInfo } = this.state;
    return (
      <>
        <>
          <FormItem
            name={['Unternehmen', 'type']}
            label="Unternehmen"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select style={{ width: '100%' }} onChange={this.onChange}>
              <Option key={'AG'} value={'AG'}>
                Aktiengesellschaft (AG),
              </Option>
              <Option key={'OHG'} value={'OHG'}>
                Offene Handelsgesellschaft (OHG)
              </Option>
              <Option key={'KG'} value={'KG'}>
                Kommanditgesellschaft (KG)
              </Option>
              <Option key={'GmbH'} value={'GmbH'}>
                Gesellschaft mit beschränkter Haftung (GmbH)
              </Option>
              <Option key={'haftungsbeschränkt'} value={'haftungsbeschränkt'}>
                Kommanditgesellschaft auf Aktien : GmbH &amp; Co oder AG &amp;
                Co, UG
              </Option>
              <Option key={'GbR'} value={'GbR'}>
                Gesellschaft bürgerlichen Rechts (GbR)
              </Option>
            </Select>
          </FormItem>
        </>
        <>
          <>
            <FormItem
              name={['Unternehmen', 'name']}
              label="Firmenname"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </FormItem>
            <FormItem
              name={['Unternehmen', 'email']}
              label="E-Mail-Adresse"
              rules={[
                {
                  required: true,
                  message: 'Bitte gib deine E-Mail-Adresse ein.',
                },
              ]}
            >
              <Input type="email" />
            </FormItem>
            <FormItem
              name={['Unternehmen', 'CompanyNumber']}
              label="Handelsregisternummer"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input type="number" />
            </FormItem>
            <AddressForm />
            <FormItem
              name="aboutYou"
              label="bist du der gesetzlich Vertreter?"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select style={{ width: '100%' }} onChange={this.handleShowInfo}>
                <Option key={'Ja'} value={'Ja'}>
                  Ja
                </Option>
                <Option key={'Nein'} value={'Nein'}>
                  Nein
                </Option>
              </Select>
            </FormItem>
          </>
        </>
        <>
          <>
            <H2>Gesetzlicher Vertreter</H2>
            <div>
              {showPersonalInfo && (
                <>
                  <FormItem
                    name={['Gesetzlicher', 'firstName']}
                    label="Vorname"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </FormItem>
                  <FormItem
                    name={['Gesetzlicher', 'lastName']}
                    label="Nachname"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </FormItem>
                  <FormItem
                    name={['Gesetzlicher', 'DateOfBirth']}
                    label="Geburtsdatum"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <DatePicker
                      format={dateFormat}
                      disabledDate={(d) =>
                        !d || d.isAfter(eighteenYearsFromNow)
                      }
                    />
                  </FormItem>
                  <FormItem
                    name={['Gesetzlicher', 'phone']}
                    label="Telefonnummer"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </FormItem>
                  <FormItem
                    name={['Gesetzlicher', 'address']}
                    label="Anschrift"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </FormItem>
                  <FormItem
                    name={['Gesetzlicher', 'email']}
                    label="E-Mail-Adresse"
                    rules={[
                      {
                        required: true,
                        message: 'Bitte gib deine E-Mail-Adresse ein.',
                      },
                    ]}
                  >
                    <Input type="email" />
                  </FormItem>
                  <FormItem
                    name={['Gesetzlicher', 'nationality']}
                    label="Staatsangehörigkeit"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </FormItem>{' '}
                </>
              )}

              <FormItem
                name="dragger"
                valuePropName="fileList"
                getValueFromEvent={this.normFile}
                label="Ausweis/Reisepass/Führerschein"
              >
                <Upload.Dragger name="files">
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    Support for a single or bulk upload.
                  </p>
                </Upload.Dragger>
              </FormItem>
              <FormItem
                name="dragger"
                valuePropName="fileList"
                getValueFromEvent={this.normFile}
                label="Gesellschaftsvertrag"
              >
                <Upload.Dragger name="files">
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    Support for a single or bulk upload.
                  </p>
                </Upload.Dragger>
              </FormItem>
              <FormItem
                name="dragger"
                valuePropName="fileList"
                getValueFromEvent={this.normFile}
                label={
                  unternehmen === 'GbR'
                    ? 'Gewerbeanmeldung (von allen Gesellschaftern)'
                    : 'Handelsregisterauszug'
                }
              >
                <Upload.Dragger name="files">
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    Support for a single or bulk upload.
                  </p>
                </Upload.Dragger>
              </FormItem>
            </div>
          </>
        </>
      </>
    );
  }
}
