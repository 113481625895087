import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Form, Checkbox, Input, Spin, Radio } from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Container,
  PrimaryButton,
  formLayout,
  formValidateMessages,
  H1,
  FormWrapper,
  H2,
} from 'utils';
import './RichTextCss.css';
import { ProjectsActions } from 'redux/actions';

const FormItem = Form.Item;
class ContractDocuments extends Component {
  static propTypes = {
    updateDraft: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    projectId: PropTypes.string,
    errors: PropTypes.object.isRequired,
    currentStep: PropTypes.number.isRequired,
    setCanMove: PropTypes.func.isRequired,
    showModal: PropTypes.bool.isRequired,
    selectedStep: PropTypes.number.isRequired,
    setSubmitFromModal: PropTypes.func.isRequired,
    handleOnChange: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      value: this.props.project?.contractDocuments?.paypal_check, // New state for the input field value
    };

    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleOnChange = (e) => {
    this.setState({ value: e.target.value });
  };

  // handleInputChange = (e) => {
  //   e.target.value = e.target.value
  //     .replace(/[^\dA-Z]/g, '')
  //     .replace(/(.{4})/g, '$1 ')
  //     .trim();
  // };
  onFinish = (values) => {
    const {
      updateDraft,
      projectId,
      currentStep,
      setCanMove,
      showModal,
      selectedStep,
      setSubmitFromModal,
    } = this.props;
    if (showModal) {
      updateDraft(
        projectId,
        {
          ...values,
          terms_1: values.terms,
          terms_2: values.guidelines,
          terms_3: values.bestätige,
          terms_4: values.myDate,
        },
        selectedStep - 1
      );
      setSubmitFromModal(false);
    } else {
      updateDraft(
        projectId,
        {
          ...values,
          terms_1: values.terms,
          terms_2: values.guidelines,
          terms_3: values.bestätige,
          terms_4: values.myDate,
        },
        currentStep
      );
    }

    setCanMove(true);
    this.props.handleSteps(9);
  };
  render() {
    const { project, loading, setCanMove, projectId } = this.props;

    return (
      <Container className="inside-form-container">
        <Spin spinning={loading}>
          <H1>Dokumente</H1>
          <p>
            Damit du dein Projekt erfolgreich auf commonsplace anlegen kannst,
            bitten wir dich die unten stehenden Aussagen zu bestätigen. So steht
            deinem Projektstart nichts mehr im Weg. Wir drücken dir die Daumen
            und wünschen dir viel Erfolg.
          </p>
          {!loading && (
            <Form
              {...formLayout}
              name="project-details"
              onFinish={this.onFinish}
              onChange={() => setCanMove(false)}
              validateMessages={formValidateMessages}
              initialValues={
                projectId
                  ? project
                    ? {
                        ...project.contractDocuments,
                      }
                    : {
                        terms: false,
                        myDate: false,
                        guidelines: false,
                        bestätige: false,
                      }
                  : {
                      terms: false,
                      myDate: false,
                      guidelines: false,
                      bestätige: false,
                    }
              }
            >
              <FormWrapper>
                <>
                  <FormItem
                    name="terms"
                    valuePropName="checked"
                    rules={[
                      {
                        required: true,
                        transform: (value) => value || undefined, // Those two lines
                        type: 'boolean', // Do the magic
                        message:
                          'Bitte stimme den Nutzungsbedingungen und den Gebühren zu.',
                      },
                    ]}
                  >
                    <Checkbox checked={true}>
                      Ich stimme den <Link to="/anb">ANB</Link> und den{' '}
                      <Link to="/fees">Gebühren</Link> zu.
                    </Checkbox>
                  </FormItem>
                  <FormItem
                    name="guidelines"
                    valuePropName="checked"
                    rules={[
                      {
                        required: true,
                        transform: (value) => value || undefined, // Those two lines
                        type: 'boolean', // Do the magic
                        message:
                          'Bitte bestätige, dass dein Projekt die Regelnerfüllt.',
                      },
                    ]}
                  >
                    <Checkbox checked={true}>
                      Mein Projekt erfüllt die{' '}
                      <Link to="/guidelines">Richtlinien.</Link>
                    </Checkbox>
                  </FormItem>
                  <FormItem
                    name="bestätige"
                    valuePropName="checked"
                    rules={[
                      {
                        required: true,
                        transform: (value) => value || undefined, // Those two lines
                        type: 'boolean', // Do the magic
                        message:
                          'Bitte bestätige, dass wir deine Daten an Stripe übermitteln dürfen.',
                      },
                    ]}
                  >
                    <Checkbox checked={true}>
                      Ich bestätige, dass meine Daten an unseren Zahlungspartner
                      Stripe zur Legitimation weitergegeben werden dürfen.
                    </Checkbox>
                  </FormItem>
                  <FormItem
                    name="myDate"
                    valuePropName="checked"
                    rules={[
                      {
                        required: true,
                        transform: (value) => value || undefined, // Those two lines
                        type: 'boolean', // Do the magic
                        message:
                          'Du musst der Einverständniserklärung zustimmen, um weiter machen zu können.',
                      },
                    ]}
                  >
                    <Checkbox checked={true}>
                      Ich erkläre hiermit, dass die Angaben in diesem Antrag und
                      allen beigefügten Anlagen richtig und vollständig sind.
                      Ich werde der commonsplace-Community jederzeit
                      unverzüglich über alle relevanten Sachverhaltsänderungen
                      informieren. Ich bin damit einverstanden, dass meine
                      persönlichen Daten von commonsplace elektronisch
                      gespeichert und verarbeitet werden, auch zum Zwecke der
                      Begutachtung, Statistik und Evaluation durch commonsplace.
                      Im Falle der Bewilligung des Projektantrags stimme ich zu,
                      dass die angegebenen Daten durch commonsplace
                      veröffentlicht werden dürfen. Mir ist bewusst, dass
                      commonsplace meinen Antrag ohne Angabe von Gründen
                      ablehnen kann.
                    </Checkbox>
                  </FormItem>
                </>
                <p>
                  Bei Fragen lies dir die <Link to="faqs">FAQs</Link> durch oder
                  kontaktiere uns unter: support@commonsplace.de
                </p>
              </FormWrapper>
              {/* <H2>
                Hast du ein PayPal-Konto, um Zahlungen darüber zu empfangen?<span style={{ color: 'red' }}> *</span>
              </H2>
              <FormWrapper style={{margin: '0px', paddingBottom: '0px'}}>
                <>
                  <FormItem style={{margin:'0px'}}
                    name="paypal_check"
                    rules={[
                      {
                        required: true,
                        message: 'Dieses Feld ist erforderlich.',
                      },
                    ]}
                  >
                    value={project.contractDocuments.paypal_check}
                    <Radio.Group onChange={this.handleOnChange} value={this.state.value}>
                      <div className="paypal_account">
                        <Radio className="radio_buttons large" value={true}>
                          Ja
                        </Radio>
                        <Radio className="radio_buttons large" value={false}>
                          Nein
                        </Radio>
                      </div>
                    </Radio.Group>
                  </FormItem>
                  {
                    this.state.value === true ? <FormItem
                    name="paypal_email"
                    label="Email"
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Input type="email" maxLength={50} />
                    <Input maxLength={100}/>
                  </FormItem> : null
                  }
                </>
              </FormWrapper> */}
              {/* <p className="sub_title" style={{marginBottom: '20px'}}>
                *Solltest du momentan über keine PayPal-Adresse verfügen, ist das kein Problem, da du standardmäßig über Stripe Zahlungen empfangen kannst. Du kannst das Projekt also auch ohne PayPal starten und später im Projektformular deine PayPal-Adresse ergänzen, um Zahlungen über Paypal zu empfangen.
                <br />
                <br />
                Falls du uns keine korrekten Informationen gibst, behalten wir
                das Geld ein, bis du uns die richtigen Angaben lieferst, damit
                wir es an dich auszahlen können.
              </p> */}
              {/* <H2>Bankdaten</H2>
              <FormWrapper>
                <>
                  <FormItem
                    name="account_holder"
                    label="Kontoinhaber"
                    rules={[
                      {
                        required: true,
                        message:
                          'Die Angabe zum Kontoinhaber ist erforderlich.',
                      },
                    ]}
                  >
                    <Input />
                  </FormItem>
                  <FormItem
                    name="iban"
                    label="IBAN"
                    rules={[
                      {
                        required: true,
                        message: 'Die Angabe zur IBAN ist erforderlich.',
                      },
                    ]}
                  >
                    <Input onInput={this.handleInputChange} />
                  </FormItem>
                  <FormItem name="bic" label="BIC">
                    <Input />
                  </FormItem>
                </>
              </FormWrapper> */}
              <FormItem>
                <PrimaryButton
                  type="primary"
                  htmltype="submit"
                  minWidth="150px"
                  id="submit-button"
                >
                  Speichern
                </PrimaryButton>
              </FormItem>
            </Form>
          )}
        </Spin>
      </Container>
    );
  }
}
const mapStateToProps = (state) => {
  const { project, loading, errors, currentStep, handleInput } = state.projects;
  return {
    project,
    loading,
    errors,
    currentStep,
    handleInput,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleSteps: (step) => dispatch(ProjectsActions.handleSteps(step)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContractDocuments);
