import React, { Component } from 'react';
import { Collapse } from 'components';
import styled from '@emotion/styled';
import { Colors } from 'utils';
import PropTypes from 'prop-types';
import '../ProjectForm/components/RichTextCss.css';
import '../Project/components/project_details.css';
import { Input } from 'antd';

const ProjectFAQsWrapper = styled.div`
  .project__collapse {
    padding-bottom: 10px;
    padding-top: 10px;
    border-bottom: 0.5px solid ${Colors.secondaryColor};
    h2 {
      font-size: 22px;
      font-weight: 600;
    }
    p {
      font-size: 20px;
      font-weight: 400;
      margin: 0;
      margin-bottom: 15px;
      color: ${Colors.steel};
    }
  }
`;

const { TextArea } = Input;

export default class ProjectFAQs extends Component {
  state = {
    currentOpen: null,
  };
  setCurrentOpen = (state, witchQut) => {
    if (state) {
      this.setState({ currentOpen: witchQut });
    } else {
      this.setState({ currentOpen: null });
    }
  };

  render() {
    const { currentOpen } = this.state;
    const { projectFaq } = this.props;
    return (
      <ProjectFAQsWrapper>
        <div>
          <Collapse
            title={<h3>Was ist der genaue Zweck des Projektes?</h3>}
            arrowWidth={'20px'}
            className="project__collapse"
            defaultState={currentOpen === 'projectInfo' ? true : false}
            handleMultiCollapes={this.setCurrentOpen}
            witchQuestions={'projectInfo'}
          >
            <TextArea
              autoSize
              readOnly
              className="text_area_view_project"
              value={projectFaq && projectFaq[0]}
            />
            {/* <p>{projectFaq && projectFaq[0]}</p> */}
          </Collapse>
          <Collapse
            title={<h3>Was sind die Ziele und wer ist die Zielgruppe?</h3>}
            arrowWidth={'20px'}
            className="project__collapse"
            defaultState={currentOpen === 'projectGoal' ? true : false}
            handleMultiCollapes={this.setCurrentOpen}
            witchQuestions={'projectGoal'}
          >
            <TextArea
              autoSize
              readOnly
              className="text_area_view_project"
              value={projectFaq && projectFaq[1]}
            />
            {/* <p>{projectFaq && projectFaq[1]}</p> */}
          </Collapse>
          <Collapse
            title={
              <h3>Warum sollte die Community dieses Projekt unterstützen?</h3>
            }
            arrowWidth={'20px'}
            className="project__collapse"
            defaultState={currentOpen === 'projectSupport' ? true : false}
            handleMultiCollapes={this.setCurrentOpen}
            witchQuestions={'projectSupport'}
          >
            <TextArea
              autoSize
              readOnly
              className="text_area_view_project"
              value={projectFaq && projectFaq[2]}
            />
            {/* <p>{projectFaq && projectFaq[2]}</p> */}
          </Collapse>
          <Collapse
            title={<h3>Wofür wird das Geld benötigt?</h3>}
            arrowWidth={'20px'}
            className="project__collapse"
            defaultState={currentOpen === 'projectMoney' ? true : false}
            handleMultiCollapes={this.setCurrentOpen}
            witchQuestions={'projectMoney'}
          >
            <TextArea
              autoSize
              readOnly
              className="text_area_view_project"
              value={projectFaq && projectFaq[3]}
            />
            {/* <p>{projectFaq && projectFaq[3]}</p> */}
          </Collapse>
          <Collapse
            title={<h3>Wer ist in diesem Projekt involviert?</h3>}
            arrowWidth={'20px'}
            className="project__collapse"
            defaultState={currentOpen === 'projectPeople' ? true : false}
            handleMultiCollapes={this.setCurrentOpen}
            witchQuestions={'projectPeople'}
          >
            <TextArea
              autoSize
              readOnly
              className="text_area_view_project"
              value={projectFaq && projectFaq[4]}
            />
            {/* <p>{projectFaq && projectFaq[4]}</p> */}
          </Collapse>
        </div>
      </ProjectFAQsWrapper>
    );
  }
}

ProjectFAQs.propTypes = {
  projectFaq: PropTypes.array,
};
