import React, { Component } from 'react';
import { Form, Input, Spin, Radio } from 'antd';
import { Header, Footer } from 'components';
import { Container, Colors, FormWrapper, PrimaryButton, Section } from 'utils';
import { Redirect } from 'react-router-dom';
import styled from '@emotion/styled';
import CurrencyInput from 'react-currency-input-field';
import { store } from 'react-notifications-component';
import TextArea from 'antd/lib/input/TextArea';
import { projectsServices } from 'services';
import { Select, Space } from 'antd';

const notification = {
  insert: 'top',
  container: 'top-right',
  animationIn: ['animate__animated animate__fadeIn'], // `animate.css v4` classes
  animationOut: ['animate__animated animate__fadeOut'], // `animate.css v4` classes
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
};

const FormItem = Form.Item;

const StartProjectWrapper = styled.div`
  .container {
    max-width: 850px;
    color: ${Colors.GreenColor};
    h1 {
      color: ${Colors.GreenColor};
      display: inline-block;
      padding: 10px;
      border-radius: 10px;
      font-size: 35px;
      margin-top: 30px;
      margin-bottom: 0px;
      font-weight: bold;
      width: 100%;
      text-align: center;
    }
  }
  label {
    font-weight: bold;
    font-size: 22px;
    display: block;
  }

  .submit-button {
    text-align: center;
    margin-top: 50px;
  }
  .ant-form-item {
    display: block;
    .ant-form-item-label {
      color: ${Colors.GreenColor};
      margin-bottom: 10px;
    }
    .ant-picker,
    .ant-input-affix-wrapper {
      width: 100%;
      padding: 10px;
      border: 1px solid ${Colors.GreenColor};
      border-radius: 10px;
    }
  }
  .ant-select-selector,
  input,
  textarea {
    border-radius: 15px !important;
    width: 100%;
    padding: 10px;
    border: 1px solid ${Colors.GreenColor};
    border-radius: 10px;
    line-height: 22px;
    vertical-align: middle;
  }
  .subtitle {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 30px;
  }
  .intro-text {
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 30px;
  }
  .ant-select {
    border-radius: 15px !important;
    width: 100%;
    // border: 1px solid black;
    border-radius: 10px;
    min-height: 44px;
    line-height: 22px;
    vertical-align: middle;
  }

  .ant-select-selector {
    padding: 10px !important;
    min-height: 44px;
    border:1px solid black !important;
  }
  .ant-select-show-search.ant-select:not(.ant-select-customize-input)
    .ant-select-selector
    input {
    height: 42px;
    line-height: 22px;
    vertical-align: middle;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    line-height: 22px;
    vertical-align: middle;
  }
`;

class StartProjectPage extends Component {
  state = {
    fundingziel: 0,
    projectCreated: false,
    loading: false,
    email: null,
    error: null,
    option_value: null,
    andere: false,
  };
  onFinish = (values) => {
    const { fundingziel } = this.state;
    if (fundingziel === 0 || fundingziel === null || isNaN(fundingziel)) {
      this.setState({ error: "Gib dein Fundingziel ein!" })
    } else {
      const project = {
        body: values.body,
        goal: Number(fundingziel),
        ...(values.organization_name && {
          organization_name: values.organization_name,
        }),
        name: values.name,
        email: values.email,
        telefon: values.telefon,
        contact_preference: values.contact_preference,
        friends_family:values.Select == "Andere" ? values?.Andere_text : values.Select
      };
      this.setState({ loading: true, email: values.email });
      projectsServices
        .createEmailRequest(project)
        .then(() => {
          this.setState({ loading: false, projectCreated: true });
        })
        .catch(() => {
          this.setState({ loading: false });
          store.addNotification({
            ...notification,
            title: 'Fehler!',
            type: 'danger',
            message: 'Gib dein Fundingziel ein!',
          });
        });
    }
  };

  componentDidMount() {
    this.setState({ option_value: "Please Select" })
  }

  render() {
    const handleChange = (value) => {
      this.setState({ option_value: value })
    };
    return (
      <StartProjectWrapper>
        {this.state.projectCreated && (
          <Redirect
            to={{
              pathname: `/start-project/thankyou`,
              state: this.state.email,
            }}
          />
        )}
        <Header relativeHeader={true} />
        <Container className="container">
          <Section>
            <Spin spinning={this.state.loading}>
              <h3>Starte dein Projekt jetzt auf commonsplace!</h3>
              <div className="subtitle">
                Großartig, dass du ein Projekt starten willst!
              </div>
              <div className="intro-text">
                <p>
                Wir sind begeistert, dass du dich entschieden hast, dein Projekt bei uns zu starten. Unsere Plattform ist der ideale Ort, um deine Ideen zum Leben zu erwecken und die Welt ein bisschen besser zu machen. Wir glauben an die Kraft der Gemeinschaft und daran, dass jeder eine positive Veränderung bewirken kann.
                </p>
                <div>
                  <p>
                    Präsentiere uns deine Idee und wir werden uns umgehend bei dir melden, um gemeinsam deine Vision auf commonsplace zu realisieren.
                  </p>
                  <p style={{ color: '#95bd51' }}>
                    Bismillah, lass uns loslegen und deine Idee zum Erfolg führen!
                  </p>
                </div>
              </div>
              <Form onFinish={this.onFinish}>
                <FormWrapper>
                  <>
                    <FormItem
                      name="body"
                      label="Projektidee"
                      rules={[
                        {
                          required: true,
                          message: 'Eine Idee ist gefragt!',
                        },
                      ]}
                    >
                      <TextArea placeholder="Beschreibe deine Idee möglichst kurz und knackig." />
                    </FormItem>

                    <label style={{ marginBottom: "8px" }}><span style={{ color: "#ff4d4f", fontSize: "14px", marginRight: "4px", lineHeight: "1" }}>*</span>Fundingziel :</label>
                    <div className="currency-input">
                      <CurrencyInput
                        decimalSeparator=","
                        groupSeparator="."
                        placeholder="0,00"
                        allowNegativeValue={false}
                        onChange={(e) =>
                          this.setState({
                            fundingziel: Number(
                              e.target.value
                                .replaceAll('.', '')
                                .replaceAll(',', '.')
                            ), error: e.target.value == null ? "erforderlich" : null
                          })
                        }
                      />

                    </div>
                    <p style={{ color: "red" }}>{(this.state.error != null) ? "erforderlich" : ""}</p>

                    <FormItem
                      name="name"
                      label="Dein Name"
                      rules={[
                        {
                          required: true,
                          message: 'erforderlich',
                        },
                      ]}
                    >
                      <Input />
                    </FormItem>

                    <FormItem
                      name="organization_name"
                      label="Name der Organisation"
                    >
                      <Input />
                    </FormItem>

                    <FormItem
                      name="email"
                      label="E-Mail Adresse"
                      rules={[
                        {
                          required: true,
                          message: 'erforderlich',
                        },
                      ]}
                    >
                      <Input type="email" />
                    </FormItem>
                    <FormItem
                      name="telefon"
                      label="Telefonnummer"
                      rules={[
                        {
                          required: true,
                          message: 'erforderlich',
                        },
                      ]}
                    >
                      <Input type="tel" />
                    </FormItem>

                    <div>
                        {this.state.andere ? (
                            <FormItem
                              name="entry"
                              label="Über commonsplace erfahren durch::"
                              rules={[
                                {
                                  required: true,
                                },
                              ]}
                            >
                              <Input />
                            </FormItem>
                        ) : (
                          <FormItem
                          name="learned_about"
                          label="Über commonsplace erfahren durch::"
                          rules={[
                            {
                              required: true,
                              message: 'erforderlich',
                            },
                          ]}
                        >
                          <Select
                            defaultValue="Please Select"
                            options={[
                              { value: 'Please Select', label: 'Please Select', disabled: true },
                              { value: 'Social Media', label: 'Social Media' },
                              { value: 'Freunde and Famlie', label: 'Freunde and Famlie'},
                              { value: 'WhatsApp', label: 'WhatsApp' },
                              { value: 'Newsletter', label: 'Newsletter' },
                              { value: 'Google Suche', label: 'Google Suche' },
                              { value: 'Printmedien wie Plakat oder Flyer von commonsplace', label: 'Printmedien wie Plakat oder Flyer' },
                              { value: 'Andere', label: 'Andere' },
                            ]}
                            style={{ width: '100%' }}
                            onChange={this.handleOnChange}
                          />
                    </FormItem>

                        )}
                      </div>

                    <FormItem
                      name="Select"
                      label="Über commonsplace erfahren durch:"
                    rules={[
                      {
                        required: true,
                        message: 'erforderlich',
                      },
                    ]}
                    >

                      <Select
                        defaultValue="Please Select"
                        onChange={handleChange}
                        options={[
                          { value: 'Please Select', label: 'Please Select', disabled: true },
                          { value: 'Social Media', label: 'Social Media' },
                          { value: 'Freunde and Famlie', label: 'Freunde and Famlie'},
                          { value: 'WhatsApp', label: 'WhatsApp' },
                          { value: 'Newsletter', label: 'Newsletter' },
                          { value: 'Google Suche', label: 'Google Suche' },
                          { value: 'Printmedien wie Plakat oder Flyer von commonsplace', label: 'Printmedien wie Plakat oder Flyer' },
                          { value: 'Andere', label: 'Andere' },
                        ]}
                        style={{ width: '100%' }}
                      />
                    </FormItem>

                    {this.state.option_value == "Andere" && (
                      <FormItem
                        name="Andere_text"
                      >
                        <TextArea placeholder="Schreibe etwas…. (optional)" />
                      </FormItem>)}

                    <FormItem
                      name="contact_preference"
                      label="Über welchen Weg sollen wir uns bei dir melden?"
                      rules={[
                        {
                          required: true,
                          message: 'erforderlich',
                        },
                      ]}
                      initialValue="E-Mail"
                    >
                      <Radio.Group
                        style={{
                          display: 'flex',
                          justifyContent: 'space-around',
                        }}
                      >
                        <Radio
                          value="E-Mail"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          E-Mail
                        </Radio>
                        <Radio
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                          value="Telefonisch"
                        >
                          Telefonisch
                        </Radio>
                      </Radio.Group>
                    </FormItem>

                    <FormItem className="submit-button">
                      <PrimaryButton
                        type="primary"
                        htmltype="submit"
                        minWidth="250px"
                      >
                        Absenden
                      </PrimaryButton>
                    </FormItem>
                  </>
                </FormWrapper>
              </Form>
            </Spin>
          </Section>
        </Container>
        <Footer />
      </StartProjectWrapper>
    );
  }
}

export default StartProjectPage;
