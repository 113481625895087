import React, { Component } from 'react';
import { Table } from 'antd';
import { Link } from 'react-router-dom';
// import { RejectIcon } from 'images'; for refund
//SvgWrapper for refund
// refundDonations
import { H2, numberWithCommas, Colors, dateFormat } from 'utils';
import styled from '@emotion/styled';
import { connect } from 'react-redux';
import { DonationsActions } from 'redux/actions';
import PropTypes from 'prop-types';
import moment from 'moment';

const MySupportsWrapper = styled.div`
  h2 {
    color: ${Colors.GreenColor};
    display: inline-block;
    padding: 10px;
    border: 1px solid ${Colors.GreenColor};
    border-radius: 10px;
    font-size: 35px;
    margin-bottom: 50px;
  }
  .ant-table-thead {
    th {
      font-size: 22px;
      font-weight: bold;
      text-align: center;
    }
  }
  .project-name {
    font-size: 20px;
    font-weight: bold;
  }
  .ant-table-content {
    .ant-table-thead,
    .ant-table-cell,
    .ant-table-tbody .ant-table-row {
      border: 1px solid ${Colors.GreenColor};
    }
  }
  .ant-table-cell {
    text-align: center;
    .delete {
      text-align: center;
      button {
        border: none;
        background: none;
      }
    }
  }
`;

const perPage = 5;
class MySupports extends Component {
  componentDidMount() {
    const { getMyDonations } = this.props;
    getMyDonations(perPage, 1);
  }
  static propTypes = {
    donations: PropTypes.array.isRequired,
    loadingDonations: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    getMyDonations: PropTypes.func.isRequired,
    refundDonations: PropTypes.func.isRequired,
  };

  render() {
    const { donations, loadingDonations, getMyDonations } = this.props;

    const columns = [
      {
        title: 'Projektname',
        dataIndex: 'title',
        key: 'id',
        render: (text, record) => (
          <Link to={`/project/${record.url}`} className="project-name">
            {text}
          </Link>
        ),
      },
      {
        title: 'Betrag',
        dataIndex: 'amount',
        key: 'id',
        render: (text) => <p>{numberWithCommas(text)} €</p>,
      },
      {
        title: 'Datum',
        dataIndex: 'time',
        key: 'id',
        render: (text) => {
          return <p>{moment.unix(Number(text)).format(dateFormat)}</p>;
        },
      },

      // {
      //   title: 'Action',
      //   key: 'action',
      //   render: (text, record) => {
      //     if (record.refundbale) {
      //       return (
      //         <div className="delete">
      //           <button onClick={() => refundDonations(record.id)}>
      //             <SvgWrapper width="35px" height="35px">
      //               <RejectIcon red={true} />
      //             </SvgWrapper>
      //           </button>
      //         </div>
      //       );
      //     } else {
      //       return;
      //     }
      //   },
      // },
    ];
    return (
      <MySupportsWrapper>
        <H2>Unterstützungen</H2>
        {donations && donations.donations && donations.donations.length > 0 && (
          <Table
            columns={columns}
            dataSource={donations.donations}
            loading={loadingDonations}
            pagination={{
              total: donations.count,
              pageSize: perPage,
              hideOnSinglePage: true,
              emptyText: 'Noch hat niemand dein Projekt unterstützt',
              onChange: (page) => getMyDonations(perPage, page),
            }}
          />
        )}
      </MySupportsWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  const { donations, loading: loadingDonations, errors } = state.donations;
  return {
    donations,
    loadingDonations,
    errors,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getMyDonations: (perPage, page) =>
    dispatch(DonationsActions.getMyDonations(perPage, page)),
  refundDonations: (id) => dispatch(DonationsActions.refundDonations(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MySupports);
