import React, { Component } from 'react';
import styled from '@emotion/styled';
import Slider from 'react-slick';
import ReactPlayer from 'react-player/youtube';
import { NextArrow, PrevArrow } from 'components';
import { SvgWrapper, Modal, device, Colors, isMobile } from 'utils';
import { PlayButton } from 'images';
import PropTypes from 'prop-types';
import config from 'config';
console.log(config, 'config');

const ProjectImagesWrapper = styled.div`
  .selected-images {
    width: 100%;
    margin-bottom: 10px;
    height: 300px;
    @media ${device.allMobile} {
      height: auto;
    }
    img {
      width: 100%;
      max-height: 300px;
      object-fit: cover;
      cursor: pointer;
      object-fit: contain;
    }
  }
  .images {
    margin-bottom: 25px;
    @media ${device.allMobile} {
      margin-bottom: 25px;
    }
    div:focus {
      outline: none;
    }
    .slick-dots {
      bottom: -20px;
      @media ${device.allMobile} {
        bottom: -40px;
      }
    }
    .slick-prev:before,
    .slick-next:before {
      content: '';
      font-size: 0;
    }
    .slick-next {
      right: -35px;
      z-index: 2;
      top: 50%;
      background: black;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      padding: 7px 0px;
      display: none;
    }
    .slick-prev {
      left: -35px;
      z-index: 2;
      top: 50%;
      background: black;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      padding: 7px 0px;
      display: none;
    }
    .slick-track > div {
      padding: 0 5px;
    }
    .img {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      width: 100px;
      // height: 587px;
      border-radius: 10px;
      overflow: hidden;
      @media ${device.allMobile} {
        height: auto;
        // height:300px;
        border-radius: 10px;
        overflow: hidden;
      }
      img {
        width: 100%;
        height: auto;
        margin: auto;
        width: 100%;
        height: 100%;
        @media ${device.allMobile} {
          max-width: auto;
          // max-height: 100px;
        }
      }
    }
  }
`;
const YouTubeWrapper = styled.div`
  width: 100%;
  height: 100%;
  // max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .play-button__icon {
    position: absolute;
  }

  height: 580px;
  @media (max-width: 990px) {
    aspect-ratio: 1;
  }

  @media (max-width: 1440px) {
    max-height: 450px;
  }
  @media (max-width: 500px) {
    max-height: 256px;
  }
  @media (min-width: 500px) and (max-width: 768px) {
    max-height: 356px;
  }
`;

const ModalWrapper = styled.div`
  max-height: 80vh;
  .images {
    overflow-x: scroll;
    max-height: 200px;
    display: flex;
    margin-top: 15px;
    align-items: center;
    @media ${device.laptop} {
      max-height: 150px;
      margin-top: 0;
    }
    img {
      height: auto;
      margin-right: 15px;
      cursor: pointer;
      margin-top: 10px;
      width: 100px;
      height: 100px;
      @media ${device.allMobile} {
        width: 28%;
      }
    }
  }
  .selected-image {
    width: 100%;
    height: 550px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media ${device.allMobile}, ${device.tablet}, ${device.minLaptop} {
      height: auto;
    }

    img {
      width: 100%;
      height: auto;
      max-height: 550px;
      object-fit: contain;
      @media (max-height: 800px) {
        max-height: 60vh;
      }
    }
  }
`;

const Wrapper = styled.div`
  .modal__content {
    min-width: 1440px;
    @media ${device.laptop} {
      min-width: 70vw;
    }
    @media ${device.allMobile}, ${device.tablet}, ${device.minLaptop} {
      min-width: 80vw;
    }
  }
  .modal__body {
    padding-top: 0;
    margin-top: -45px;
    padding-right: 45px;
  }
`;

const ImgBox = styled.div`
  width: 100%;
  height: 580px;
  overflow: hidden;

  @media (max-width: 990px) {
    aspect-ratio: 1;
  }

  @media (max-width: 1440px) {
    height: 450px;
  }
  @media (max-width: 500px) {
    // height: ;
    max-height: 256px;
    img{
      object-fit: revert-layer;
    }
  }
  @media (min-width: 500px) and (max-width: 768px) {
    height: 356px;
  }
`;

function getYouTubeID(url) {
  var regExp =
    /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  var match = url.match(regExp);
  if (match && match[2].length === 11) {
    return match[2];
  } else {
    return false;
  }
}
export default class ProjectImages extends Component {
  static propTypes = {
    projectImages: PropTypes.array,
  };
  state = {
    selectedImage: this.props.projectImages[0],
    showModal: false,
    selectedImageModal: null,
  };
  handleSelectedImage = (selectedImage) => {
    this.setState({ selectedImage });
  };
  handleSelectedImageModal = (selectedImageModal) => {
    this.setState({ selectedImageModal });
  };
  handelCloseModal = () => {
    this.setState({ showModal: false });
    document.body.style.overflowY = 'unset';
  };

  handelShowModal = () => {
    this.setState({ showModal: true });
    document.body.style.overflowY = 'hidden';
  };
  handelOpenImagesModal = () => {
    this.handelShowModal();
    this.handleSelectedImageModal(this.state.selectedImage);
  };
  render() {
    const { selectedImage, showModal, selectedImageModal } = this.state;
    const { projectImages } = this.props;
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      // nextArrow: <NextArrow />,
      // prevArrow: <PrevArrow />,
      // responsive: [
      //   {
      //     breakpoint: 767,
      //     settings: {
      //       slidesToShow: projectImages.length < 2 ? projectImages.length : 2,
      //       slidesToScroll: projectImages.length < 2 ? projectImages.length : 2,
      //     },
      //   },
      //   {
      //     breakpoint: 769,
      //     settings: {
      //       slidesToShow: projectImages.length < 4 ? projectImages.length : 4,
      //       slidesToScroll: projectImages.length < 4 ? projectImages.length : 4,
      //     },
      //   },
      //   {
      //     breakpoint: 1441,
      //     settings: {
      //       slidesToShow: projectImages.length < 3 ? projectImages.length : 3,
      //       slidesToScroll: projectImages.length < 3 ? projectImages.length : 3,
      //     },
      //   },
      // ],
    };

    return (
      <Wrapper>
        <ProjectImagesWrapper>
          {/* <div className="selected-images">
            {selectedImage.startsWith('https://www.youtube.com') ||
            selectedImage.startsWith('https://youtu') ? (
              <YouTubeWrapper className="plyr__video-embed" id="player">
                <ReactPlayer
                  url={selectedImage}
                  width="100%"
                  height="100%"
                  className="react-player"
                />
              </YouTubeWrapper>
            ) : (
              <img
                src={selectedImage}
                alt="project"
                onClick={() => this.handelOpenImagesModal()}
              />
            )}
          </div> */}
          <div className="images new images-test">
            <Slider {...settings}>
              {
                // isMobile() && projectMobileImages?.length > 0 ? (
                //   projectMobileImages?.map((img, index) => (
                //     <div
                //       key={`${img}-${index}`}
                //       className="img"
                //     // onClick={() => {
                //     //   this.handleSelectedImage(img);
                //     // }}
                //     >
                //       {img?.startsWith('https://www.youtube.com') ||
                //         img?.startsWith('https://youtu') ? (
                //         <YouTubeWrapper className="plyr__video-embed" id="player">
                //           <ReactPlayer
                //             url={selectedImage}
                //             width="100%"
                //             height="100%"
                //             className="react-player"
                //           // controls={true}
                //           />
                //         </YouTubeWrapper>
                //       ) : (
                //         img?.startsWith(
                //           // console.log(""`${config}rails/active_storage`)
                //           `${config.REACT_APP_API_URL}rails/active_storage`
                //         ) && <img src={img} alt="project-img" />
                //       )}
                //     </div>
                //   ))
                // ) : (
                projectImages?.map((img, index) => (
                  <div
                    key={`${img}-${index}`}
                    className="img"
                    // onClick={() => {
                    //   this.handleSelectedImage(img);
                    // }}
                  >
                    {img?.startsWith('https://www.youtube.com') ||
                    img?.startsWith('https://youtu') ? (
                      <YouTubeWrapper className="plyr__video-embed" id="player">
                        <ReactPlayer
                          url={selectedImage}
                          width="100%"
                          height="100%"
                          className="react-player"
                          // controls={true}
                        />
                      </YouTubeWrapper>
                    ) : (
                      img?.startsWith(
                        // console.log(""`${config}rails/active_storage`)
                        `${config.REACT_APP_API_URL}rails/active_storage`
                      ) && (
                        <ImgBox style={{}}>
                          {' '}
                          <img
                            src={img}
                            alt="project-img"
                            style={{
                              height: '100%',
                              width: '100%',
                              // objectFit: 'cover',
                            }}
                          />
                        </ImgBox>
                      )
                    )}
                  </div>
                ))
              }
            </Slider>
          </div>
        </ProjectImagesWrapper>
        <Modal showModal={showModal} handelCloseModal={this.handelCloseModal}>
          <ModalWrapper>
            <div className="selected-image">
              <img src={selectedImageModal} alt="project-img" />
            </div>
            <div className="images">
              {projectImages?.map((img, index) => {
                if (
                  !img?.startsWith('https://www.youtube.com') &&
                  !img?.startsWith('https://youtu')
                ) {
                  return (
                    <img
                      src={img}
                      alt="project-img"
                      onClick={() => this.handleSelectedImageModal(img)}
                      key={`${img}-${index + 1}`}
                    />
                  );
                } else {
                  return <></>;
                }
              })}
            </div>
          </ModalWrapper>
        </Modal>
      </Wrapper>
    );
  }
}
