import React, { Component } from 'react';
import { Form, Spin } from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import isEmpty from 'lodash/isEmpty';
import {
  Container,
  PrimaryButton,
  formLayout,
  formValidateMessages,
  FormWrapper,
  H2,
} from 'utils';
import RichText from './RichText';
import { ProjectsActions } from 'redux/actions';

const FormItem = Form.Item;

class ProjectDescription extends Component {
  formRef = React.createRef();

  static propTypes = {
    updateDraft: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    projectId: PropTypes.string,
    currentStep: PropTypes.number.isRequired,
    setCanMove: PropTypes.func.isRequired,
    showModal: PropTypes.bool.isRequired,
    selectedStep: PropTypes.number.isRequired,
    setSubmitFromModal: PropTypes.func.isRequired,
  };

  state = {
    initialValues: {
      description: "",
      projekt: "",
      zielgruppe: "",
      unterstützen: "",
      finanzierung: "",
      hinter: "",
    },
  };


  onFinish = (values) => {
    const { updateDraft, projectId, currentStep, setCanMove, showModal, selectedStep, setSubmitFromModal } = this.props;
    const { description, projekt, zielgruppe, unterstützen, finanzierung, hinter } = values;
    const projectDescription = {
      description,
      faq: [projekt, zielgruppe, unterstützen, finanzierung, hinter],
    };

    if (showModal) {
      updateDraft(projectId, projectDescription, selectedStep - 1);
      setSubmitFromModal(false);
    } else {
      updateDraft(projectId, projectDescription, currentStep);
    }

    setCanMove(true);
    this.props.handleSteps(3);
  };

  getContent = (content, name) => {
    this.formRef.current.setFieldsValue({ [name]: content });
  };

  render() {
    const { project, loading, setCanMove, projectId } = this.props;
    return (
      <Container className="inside-form-container">
        <Spin spinning={loading}>
          <h1>Projektinformationen</h1>
          <p>
            Erzähle der Community deine Geschichte! Sei prägnant und beschreibe anschaulich, warum deine Idee besonders und wichtig ist.
          </p>
          { !loading && (
            <Form
              ref={this.formRef}
              {...formLayout}
              name="project-details"
              onFinish={this.onFinish}
              onChange={() => setCanMove(false)}
              validateMessages={formValidateMessages}
              initialValues={
                projectId ? (project ? pickBy(project.projectDescription, identity) : {}) : ""
              }
            >
              <div>
                <H2>Beschreibung</H2>
                <FormWrapper>
                  <>
                    <FormItem
                      name="description"
                      label="Beschreibung"
                      rules={[
                        {
                          required: true,
                          message: 'Bitte geben Sie eine Beschreibung ein.',
                        },
                      ]}
                      validateTrigger="onBlur"
                    >
                      <RichText name="description" getContent={this.getContent} />
                    </FormItem>
                  </>
                </FormWrapper>
              </div>
              <div>
                <H2>Weitere Informationen:</H2>
                <FormWrapper>
                  <>
                    <FormItem
                      name={'projekt'}
                      label="Zweck: Was ist der genaue Zweck des Projektes?"
                      rules={[
                        {
                          required: true,
                          message: 'Bitte geben Sie den Zweck des Projektes ein.',
                        },
                      ]}
                    >
                      <RichText name="projekt" getContent={this.getContent} />
                    </FormItem>
                    <FormItem
                      name={'zielgruppe'}
                      label="Ziele und Zielgruppe: Was sind die Ziele und wer ist die Zielgruppe?"
                      rules={[
                        {
                          required: true,
                          message: 'Bitte geben Sie die Ziele und Zielgruppe ein.',
                        },
                      ]}
                    >
                      <RichText name="zielgruppe" getContent={this.getContent} />
                    </FormItem>
                    <FormItem
                      name={'unterstützen'}
                      label="Einordnung/Relevanz: Warum sollte die Community dein Projekt unterstützen?"
                      rules={[
                        {
                          required: true,
                          message: 'Bitte geben Sie die Einordnung/Relevanz ein.',
                        },
                      ]}
                    >
                      <RichText name="unterstützen" getContent={this.getContent} />
                    </FormItem>
                    <FormItem
                      name={'finanzierung'}
                      label="Wofür wird das Geld benötigt?"
                      rules={[
                        {
                          required: true,
                          message: 'Bitte geben Sie an, wofür das Geld benötigt wird.',
                        },
                      ]}
                    >
                      <RichText name="finanzierung" getContent={this.getContent} />
                    </FormItem>
                    <FormItem
                      name={'hinter'}
                      label="Die Macher: Wer ist alles in deinem Projekt involviert? Stelle dich vor, damit die Community weiß, wen sie unterstützt."
                      rules={[
                        {
                          required: true,
                          message: 'Bitte geben Sie die Macher des Projektes ein.',
                        },
                      ]}
                      validateTrigger="onBlur"
                    >
                      <RichText name="hinter" getContent={this.getContent} />
                    </FormItem>
                  </>
                </FormWrapper>
              </div>
              <FormItem>
                <PrimaryButton
                  type="primary"
                  htmlType="submit"
                  minWidth="150px"
                  id="submit-button"
                >
                  Speichern
                </PrimaryButton>
              </FormItem>
            </Form>
          )}
        </Spin>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const { project, loading, errors, currentStep } = state.projects;
  return {
    project,
    loading,
    errors,
    currentStep,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleSteps: (step) => dispatch(ProjectsActions.handleSteps(step)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDescription);
