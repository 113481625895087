import React from 'react';
import { SupportProject, YourProjects } from './';
export default function MyProjects() {
  const user = JSON.parse(localStorage.getItem('user'));
  return (
    <div>
      <h1>Salam {user?.first_name},</h1>
      <p>
        Wir freuen uns darüber, dass du Teil von commonsplace bist! Im
        Profilbereich kannst du deine eigenen Projekte und dein Profil pflegen
        und hast eine Übersicht über deine unterstützten Projekte. Comme on!
      </p>
      <SupportProject />
      <YourProjects />
    </div>
  );
}
