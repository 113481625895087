import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { BannerDownArrow } from 'images';
import { SvgWrapper, device, isMobile } from 'utils';

const BannerWrapper = styled.div`
  position: relative;
  min-height: ${(props) =>
    props.maxHeightProps ? props.maxHeightProps : '880px'};
  max-height: ${(props) =>
    props.maxHeightProps ? props.maxHeightProps : '100vh'};
  @media ${device.allMobile} {
    // height: 100vh;
    min-height:${(props) =>
      props.maxHeightMobileProps
        ? props.maxHeightMobileProps
        : props.maxHeightProps
        ? props.maxHeightProps
        : '100vh'} ;
    max-height: none;
  }
  &.project-page-banner {
    @media ${device.allMobile} {
      height: 70vh;
      min-height: 70vh;
    }
  }
  
  .layout {
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.4;
    position: absolute;
    max-width: 100vw;
  }
  .img-wrapper:before {
    // content: '';
    max-width: 100vw;
    width: 100%;
    height: ${(props) =>
      props.maxHeightProps ? props.maxHeightProps : '100vh'};
    min-height: ${(props) =>
      props.maxHeightProps ? props.maxHeightProps : '880px'};
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    opacity: 0.5;
    display: inline-block;
    z-index: 1;
    @media ${device.allMobile} {
      min-height: ${(props) =>
        props.maxHeightMobileProps
          ? props.maxHeightMobileProps
          : props.maxHeightProps
          ? props.maxHeightProps
          : '880px'};
      max-height: ${(props) =>
        props.maxHeightMobileProps
          ? props.maxHeightMobileProps
          : props.maxHeightProps
          ? props.maxHeightProps
          : 'none'};
    }
  }
  &.project-page-banner .img-wrapper:before {
    @media ${device.allMobile} {
      height: 70vh;
      min-height: 70vh;
    }
  }
  img {
    width: 100%;
    height: 100%;
    max-width: 100vw;
    min-height: ${(props) =>
      props.maxHeightProps ? props.maxHeightProps : '880px'};
    max-height: ${(props) =>
      props.maxHeightProps ? props.maxHeightProps : '100vh'};
    position: relative;
    z-index: -1;
    object-fit: fill;
    @media ${device.allMobile} {
      height: ${(props) =>
        props.maxHeightMobileProps
          ? props.maxHeightMobileProps
          : props.maxHeightProps
          ? props.maxHeightProps
          : props.maxHeightProps
          ? props.maxHeightProps
          : '100vh'};
      min-height: ${(props) =>
        props.maxHeightMobileProps
          ? props.maxHeightMobileProps
          : props.maxHeightProps
          ? props.maxHeightProps
          : props.maxHeightProps
          ? props.maxHeightProps
          : '880px'};
      max-height: ${(props) =>
        props.maxHeightMobileProps
          ? props.maxHeightMobileProps
          : props.maxHeightProps
          ? props.maxHeightProps
          : props.maxHeightProps
          ? props.maxHeightProps
          : 'none'};
      object-fit: fill;
    }
  }
  &.project-page-banner img {
    @media ${device.allMobile} {
      height: 70vh;
      max-height: 70vh;
      min-height: 70vh;
    }
  }
  .banner-down-arrow {
    position: absolute;
    bottom: 78px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    cursor: pointer;
    @media (max-width: 1441px) {
      width: 35px;
      height: 35px
      bottom: 71px;
    }
    @media (max-height: 901px) {
      bottom: 75px;
    }
  }
`;

const ContentWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;
  z-index: 2;
  @media (max-width: 1440px) and (min-width: 768px) {
    padding: 0;
    max-width: 950px;
  }
  @media ${device.allMobile} {
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    text-align: center;
    p {
      display: inline-block;
    }
  }
  .home-page-banner & {
    @media ${device.allMobile} {
      top: 75px;
      transform: translateX(-50%);
    }
  }
`;

function scrollDown() {
  const pageHeight = window.innerHeight - 50;
  window.scrollBy(0, pageHeight);
}
export default function Banner(props) {
  const { img, children, maxHeightProps, layout, maxHeightMobileProps, style } = props;
  return (
    <BannerWrapper
      id="banner"
      maxHeightProps={maxHeightProps}
      maxHeightMobileProps={maxHeightMobileProps}
      {...props}
    >
      {layout && <div className="layout"></div>}
      {img && (
        <div className="img-wrapper">
          <picture>
            <img src={img} alt="banner-image" />
          </picture>
        </div>
      )}

      <ContentWrapper>{children}</ContentWrapper>
      {maxHeightProps === '500px' ? (
        <></>
      ) : (
        <>
          {!isMobile() && (
            <SvgWrapper
              width={'50px'}
              height={'50px'}
              className="banner-down-arrow"
              onClick={() => scrollDown()}
            >
              <BannerDownArrow />
            </SvgWrapper>
          )}
        </>
      )}
    </BannerWrapper>
  );
}

Banner.propTypes = {
  img: PropTypes.string,
  children: PropTypes.object.isRequired,
  maxHeightProps: PropTypes.string,
  maxHeightMobileProps: PropTypes.string,
  layout: PropTypes.bool,
};
