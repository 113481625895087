import React, { Component } from 'react';
import { Form, Input, Spin } from 'antd';
import { H2, FormWrapper, PrimaryButton, Colors, device } from 'utils';
import styled from '@emotion/styled';
import { connect } from 'react-redux';
import { authActions } from 'redux/actions';
import PropTypes from 'prop-types';
const FormItem = Form.Item;

const ResetEmailWrapper = styled.div`
  h2 {
    color: ${Colors.GreenColor};
    display: inline-block;
    padding: 10px;
    border: 1px solid ${Colors.GreenColor};
    border-radius: 10px;
    font-size: 35px;
    @media ${device.allMobile} {
      font-size: 25px;
    }
  }

  .ant-form-item {
    display: block;
    .ant-form-item-label {
      color: ${Colors.GreenColor};
      margin-bottom: 10px;
      label {
        font-weight: bold;
        font-size: 22px;
        @media ${device.allMobile} {
          font-size: 16px;
        }
      }
    }
    .ant-picker,
    .ant-input {
      width: 100%;
      padding: 10px;
      border: 1px solid ${Colors.GreenColor};
      border-radius: 10px;
    }
  }
`;

class ResetEmail extends Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    updateProfile: PropTypes.func.isRequired,
  };

  onFinish = (values) => {
    const { updateProfile } = this.props;
    const user = JSON.parse(localStorage.getItem('user'));
    updateProfile({ ...user, email: values.email });
  };
  render() {
    const user = JSON.parse(localStorage.getItem('user'));
    const { loading } = this.props;
    return (
      <ResetEmailWrapper>
        <H2>E-Mail-Adresse ändern</H2>
        <Form onFinish={this.onFinish}>
          <FormWrapper>
            <Spin spinning={loading}>
              <FormItem
                name="oldemail"
                label="Alte E-Mail Adresse"
                rules={[
                  {
                    required: true,
                    message: 'Bitte gebe deine alte E-Mail Adresse ein!',
                  },
                  () => ({
                    validator(_, value) {
                      if (!value || user.email === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        'Bitte gib deine E-Mail-Adresse ein.'
                      );
                    },
                  }),
                ]}
              >
                <Input type="email" />
              </FormItem>
              <FormItem
                name="email"
                label="Neue E-Mail Adresse"
                rules={[
                  {
                    required: true,
                    message: 'Bitte gib deine neue E-Mail-Adresse ein.',
                  },
                ]}
                hasFeedback
              >
                <Input type="email" />
              </FormItem>

              <FormItem
                name="confirm"
                label="Neue E-Mail Adresse wiederholen"
                dependencies={'email'}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Bitte bestätigte deine E-Mail Adresse!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('email') === value) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        'Die eingegebenen E-Mail-Adressen stimmen nicht überein!'
                      );
                    },
                  }),
                ]}
              >
                <Input type="email" />
              </FormItem>
              <FormItem>
                <PrimaryButton
                  type="primary"
                  htmltype="submit"
                  minWidth="150px"
                >
                  Speichern
                </PrimaryButton>
              </FormItem>
            </Spin>
          </FormWrapper>
        </Form>
      </ResetEmailWrapper>
    );
  }
}
const mapStateToProps = (state) => {
  const { user, loading, errors } = state.authentication;
  return {
    user,
    loading,
    errors,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateProfile: (user) => dispatch(authActions.updateProfile(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetEmail);
