import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import isEmpty from 'loadsh/isEmpty';
import { connect } from 'react-redux';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const client = localStorage.getItem('client');
  const uid = localStorage.getItem('uid');
  const access = localStorage.getItem('access-token');
  const user = localStorage.getItem('user');
  const userType = localStorage.getItem('user-type');
  const isLoggedIn =
    user && !isEmpty(user) && access && uid && client && userType === 'admin'
      ? true
      : false;
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType,
  location: PropTypes.object,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { user } = state.admin;
  return {
    user,
  };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
