/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import PropTypes from 'prop-types';

const IdeaIcon = ({ width, height }) => {
  return (
    <svg
      className="animated"
      id="freepik_stories-launching"
      viewBox="0 0 500 500"
      css={css`
        max-width: ${width || '100%'};
        max-height: ${height || '100%'};
        height: ${height || '100%'};
        width: ${width || '100%'};
        display: inline-block;
      `}
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <style
        dangerouslySetInnerHTML={{
          __html:
            'svg#freepik_stories-launching:not(.animated) .animable {opacity: 0;}svg#freepik_stories-launching.animated #freepik--Rocket--inject-141 {animation: 1.5s Infinite  linear floating;animation-delay: 0s;}            @keyframes floating {                0% {                    opacity: 1;                    transform: translateY(0px);                }                50% {                    transform: translateY(-10px);                }                100% {                    opacity: 1;                    transform: translateY(0px);                }            }        .animator-hidden { display: none; }',
        }}
      />
      <g
        id="freepik--background-complete--inject-141"
        style={{ transformOrigin: '251.94939613342285px 244.0440902709961px' }}
        className="animable"
      >
        <path
          d="M409.54,208.59c-13.23-19.25-44.09-23-61.52-7.43a40.25,40.25,0,0,0-68.63-27.61c2.49-8.75-6.69-17.28-15.77-17.75s-17.49,4.41-25.28,9.1-16.25,9.52-25.33,8.91c-9.83-.66-18-7.52-27.18-11.07-22.48-8.69-50.93,6.73-55.91,30.31-24.88-10-56.8,6.4-63.06,32.49l364.28,4.25A29,29,0,0,0,409.54,208.59Z"
          style={{
            fill: 'rgb(245, 245, 245)',
            transformOrigin: '249.00001525878906px 192.77929306030273px',
          }}
          id="elewb2awwlfun"
          className="animable"
        />
        <path
          d="M394.88,91.36c.63-4.58-5.83-8.35-10.69-8.74-2.31-.18-4.72.17-6.91-.59-4.75-1.66-6.65-7.8-11.28-9.75-3.47-1.47-7.46-.16-10.82,1.53s-6.68,3.82-10.44,4.06c-3.26.21-6.81-1-9.66.59-2.13,1.19-3.25,3.64-5.21,5.09s-4.42,1.71-6.81,1.84-4.84.16-7,1.15-4,3.29-3.51,5.63Z"
          style={{
            fill: 'rgb(250, 250, 250)',
            transformOrigin: '353.6964797973633px 81.90634059906006px',
          }}
          id="elfxlgzyxxbk4"
          className="animable"
        />
        <path
          d="M77.77,53.7c.35-2.56-3.25-4.66-6-4.87A15.46,15.46,0,0,1,68,48.49c-2.65-.92-3.71-4.35-6.3-5.44-1.93-.81-4.16-.09-6,.86S51.89,46,49.8,46.17c-1.83.12-3.8-.56-5.4.33-1.19.67-1.81,2-2.9,2.84a6.91,6.91,0,0,1-3.8,1,10.76,10.76,0,0,0-3.92.64,3.1,3.1,0,0,0-2,3.14Z"
          style={{
            fill: 'rgb(250, 250, 250)',
            transformOrigin: '54.78222846984863px 48.409472942352295px',
          }}
          id="elehb6rutmas6"
          className="animable"
        />
        <path
          d="M453.26,138.05c.72-5.26-6.7-9.59-12.28-10-2.67-.22-5.43.19-8-.69-5.46-1.9-7.64-9-13-11.21-4-1.68-8.57-.18-12.44,1.76s-7.68,4.39-12,4.67c-3.76.24-7.83-1.15-11.12.68-2.44,1.37-3.72,4.19-6,5.85s-5.09,2-7.82,2.12-5.58.18-8.07,1.32-4.56,3.78-4,6.47Z"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '405.8731994628906px 127.22125434875488px',
          }}
          id="ele0to5nbaj26"
          className="animable"
        />
        <path
          d="M443.07,47.77c.51-3.71-4.72-6.75-8.65-7.07-1.87-.15-3.82.14-5.59-.48-3.85-1.34-5.38-6.31-9.13-7.89-2.81-1.18-6-.13-8.75,1.24s-5.41,3.09-8.45,3.28c-2.64.17-5.51-.81-7.82.48-1.72,1-2.62,3-4.21,4.12A10,10,0,0,1,385,42.94a15.72,15.72,0,0,0-5.68.93c-1.75.8-3.2,2.66-2.84,4.56Z"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '409.7646713256836px 40.12422180175781px',
          }}
          id="elach42fxwvo9"
          className="animable"
        />
        <path
          d="M92.82,136.2c.38-2.76-3.51-5-6.43-5.26a16.49,16.49,0,0,1-4.17-.36c-2.86-1-4-4.69-6.79-5.87-2.09-.88-4.49-.09-6.51.92s-4,2.3-6.28,2.45c-2,.12-4.1-.6-5.82.36-1.29.71-2,2.19-3.14,3.06a7.5,7.5,0,0,1-4.1,1.11,11.54,11.54,0,0,0-4.22.69,3.34,3.34,0,0,0-2.12,3.39Z"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '68.03715896606445px 130.50985193252563px',
          }}
          id="eloub9f03sh5s"
          className="animable"
        />
        <path
          d="M195.62,93.93c.8-5.82-7.42-10.61-13.59-11.11-2.95-.24-6,.21-8.8-.76-6-2.11-8.46-9.92-14.35-12.4-4.41-1.86-9.49-.21-13.76,1.94s-8.5,4.86-13.28,5.17c-4.15.26-8.66-1.28-12.3.75-2.71,1.51-4.12,4.63-6.62,6.48s-5.63,2.18-8.66,2.34-6.16.2-8.92,1.46S90.3,92,90.87,95Z"
          style={{
            fill: 'rgb(245, 245, 245)',
            transformOrigin: '143.22809982299805px 81.92612552642822px',
          }}
          id="elh9w015270ls"
          className="animable"
        />
        <path
          d="M27.85,302.91s-8.52,1.21-3.67,19.86S33.11,351,33.11,351s.65,4.23-2.76,4.11S20,352.28,17.64,356s-.29,8.41,5.08,13.21,16.72,15.73,17.74,16.73,1.77,3-.56,3.33S21,387.63,20.53,395.56s16.71,18.84,18.56,20.07,2.14,2.19,1.73,3.27-5.93-.09-10.68-.44-10.3.08-10.16,4.68,9.15,16.19,35.37,24.75l12.41,3.17L76.89,442c18.38-20.57,20.36-35.11,18.17-39.16s-7.22-1.62-11.15,1.06-8.12,6.46-9,5.74-1.12-1.71-.14-3.7,10.32-20,6-26.67S63,382.61,60.81,383.53s-2.55-1.25-2.17-2.6,4.72-16.54,7-23.38,1.78-11.89-2.24-14-8.52,3.86-11.41,5.68-4.44-2.17-4.44-2.17S46.21,336.72,41,318.16s-13.35-15.22-13.35-15.22"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '56.225210189819336px 376.8830261230469px',
          }}
          id="elx46prl97p5k"
          className="animable"
        />
        <path
          d="M67.75,454.14c-3.75-13.65-10-38-15.49-58.92s-10.46-39.8-14.15-53.47c-1.84-6.82-3.33-12.35-4.37-16.18l-1.19-4.41-.3-1.14-.09-.4s.05.13.13.39l.34,1.13c.29,1,.71,2.49,1.26,4.38,1.09,3.81,2.62,9.33,4.5,16.15,3.75,13.65,8.77,32.55,14.25,53.45S64.34,440.4,68,454.07"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '50.08000183105469px 386.88002014160156px',
          }}
          id="elfxdq8fqa78l"
          className="animable"
        />
        <path
          d="M27.22,360.2a5.13,5.13,0,0,1,.81.44c.52.3,1.25.76,2.15,1.33,1.79,1.15,4.22,2.79,6.88,4.66l6.78,4.8,2.06,1.45a4.1,4.1,0,0,1,.74.56,4,4,0,0,1-.82-.43c-.52-.3-1.25-.76-2.14-1.34-1.78-1.17-4.2-2.85-6.85-4.71L30,362.2,28,360.77A3.19,3.19,0,0,1,27.22,360.2Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '36.930006980895996px 366.82000732421875px',
          }}
          id="elehz1mwm5c1a"
          className="animable"
        />
        <path
          d="M47.67,372.83c-.1-.06,3.08-5.23,7.09-11.55S62.1,349.89,62.19,350s-3.08,5.22-7.09,11.54S47.76,372.89,47.67,372.83Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '54.92978477478027px 361.41436767578125px',
          }}
          id="elvwgfsbx1kke"
          className="animable"
        />
        <path
          d="M54.64,405.29a4.21,4.21,0,0,1,.68-.66l1.93-1.69c1.62-1.44,3.86-3.43,6.25-5.72s4.49-4.43,6-6l1.79-1.85a3.83,3.83,0,0,1,.69-.65,4.72,4.72,0,0,1-.58.75c-.39.47-1,1.13-1.69,1.94-1.45,1.62-3.52,3.8-5.93,6.09s-4.66,4.26-6.34,5.64c-.84.69-1.53,1.24-2,1.6A3.76,3.76,0,0,1,54.64,405.29Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '63.30999755859375px 397.00498962402344px',
          }}
          id="elqq6axfsdfhp"
          className="animable"
        />
        <path
          d="M33.63,398a3.66,3.66,0,0,1,.85.2l2.27.7c1.91.6,4.55,1.46,7.45,2.43s5.52,1.88,7.42,2.55l2.23.82a3.49,3.49,0,0,1,.8.35,4.16,4.16,0,0,1-.85-.21l-2.27-.69c-1.92-.61-4.55-1.47-7.46-2.44s-5.52-1.87-7.41-2.55l-2.23-.81A4.14,4.14,0,0,1,33.63,398Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '44.14000129699707px 401.52500915527344px',
          }}
          id="el32etiunurix"
          className="animable"
        />
        <path
          d="M36.14,427.39a6.51,6.51,0,0,1,1.09.27l2.91.88c2.46.76,5.84,1.84,9.58,3s7.1,2.33,9.53,3.16l2.88,1a5.93,5.93,0,0,1,1,.42,5.45,5.45,0,0,1-1.09-.27L59.17,435c-2.46-.76-5.84-1.84-9.58-3s-7.1-2.33-9.53-3.16l-2.88-1A5.09,5.09,0,0,1,36.14,427.39Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '49.63500213623047px 431.75502014160156px',
          }}
          id="el8bhamsdgqtp"
          className="animable"
        />
        <path
          d="M62.58,435.78a6.34,6.34,0,0,1,.84-.86l2.37-2.24c2-1.9,4.74-4.52,7.7-7.51s5.56-5.75,7.43-7.77L83.14,415a6.76,6.76,0,0,1,.85-.84,7.68,7.68,0,0,1-.73.94c-.49.6-1.22,1.45-2.13,2.48-1.82,2.07-4.4,4.87-7.36,7.86S68,431,66,432.89c-1,.91-1.87,1.65-2.46,2.15A7.65,7.65,0,0,1,62.58,435.78Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '73.28499603271484px 424.9700012207031px',
          }}
          id="el0zr9chhsb5zl"
          className="animable"
        />
        <path
          d="M422.15,455.15c-20.73-11.47-28.52-37-30.93-60.57-.4-3.94-1.36-8-.08-11.69s5-6.59,8.53-5.39c2.91,1,4.55,4.21,6.45,6.8a22.2,22.2,0,0,0,9.78,7.59c2.51,1,5.5,1.37,7.62-.3,2.91-2.31,2.65-7,2.11-10.92l-3.06-21.77c-.55-3.9-1.1-7.88-.46-11.73s2.68-7.64,6-9.15,7.91,0,9.15,3.74a38.42,38.42,0,0,1,.76,4.84c.32,1.6,1.26,3.29,2.76,3.55s2.83-1,3.76-2.34c3.24-4.46,5.11-9.88,6.81-15.25s3.33-10.85,6.17-15.61,7.1-8.82,12.27-9.85S481,308.74,483.05,314s-.18,11.3-2.85,16.2a85.37,85.37,0,0,1-14.61,19.6,7.12,7.12,0,0,0-2.4,3.65c-.44,2.7,2.21,5,4.73,5.49,2.84.59,5.75-.12,8.64-.15s6.15.88,7.61,3.61c2,3.77-.52,8.38-3.22,11.54A69.75,69.75,0,0,1,462,389.55c-2.54,1.42-5.24,2.73-7.13,5s-2.73,6-1.05,8.56,5.15,3,7.88,2,5-2.92,7.56-4.37c4.73-2.71,11.25-3.23,15.08.95,2.52,2.75,3.28,7,2.7,10.74a29.19,29.19,0,0,1-4.23,10.39c-6.67,11.18-15.86,20.71-26.9,26.62s-21.65,8.56-33.8,5.63"
          style={{
            fill: 'rgb(245, 245, 245)',
            transformOrigin: '438.8607940673828px 381.5757751464844px',
          }}
          id="elewvdho70uj4"
          className="animable"
        />
        <path
          d="M425.84,439.66a367.82,367.82,0,0,1,7.9-39.39c.87-3.44,1.73-6.85,2.57-10.2s1.69-6.67,2.71-9.86a180.11,180.11,0,0,1,6.81-17.95c2.43-5.59,4.93-10.8,7.3-15.65s4.67-9.33,6.93-13.31a136.34,136.34,0,0,1,12-17.9c1.56-2,2.82-3.48,3.69-4.48l1-1.13a2.85,2.85,0,0,1,.36-.37,3.44,3.44,0,0,1-.3.42l-.95,1.18c-.83,1-2.06,2.56-3.59,4.57a145.1,145.1,0,0,0-11.76,18c-2.23,4-4.49,8.47-6.85,13.32S448.8,357,446.4,362.56a180.82,180.82,0,0,0-6.75,17.89c-1,3.18-1.84,6.47-2.69,9.82s-1.7,6.76-2.57,10.2a379.05,379.05,0,0,0-7.94,39.29"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '451.47496032714844px 374.5900115966797px',
          }}
          id="elt7wy7enshl"
          className="animable"
        />
        <path
          d="M439.21,380.16a7.88,7.88,0,0,1-.58-1.61c-.34-1.05-.78-2.59-1.3-4.5-1-3.81-2.3-9.12-3.67-15s-2.71-11.14-3.8-14.92c-.54-1.89-1-3.42-1.31-4.47a9.31,9.31,0,0,1-.44-1.66,7.15,7.15,0,0,1,.68,1.57c.4,1,.93,2.53,1.52,4.41,1.21,3.76,2.63,9,4,14.91s2.56,11,3.48,15c.43,1.81.8,3.33,1.09,4.55A9.39,9.39,0,0,1,439.21,380.16Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '433.6600036621094px 359.0800018310547px',
          }}
          id="elt0sziifxyo"
          className="animable"
        />
        <path
          d="M484.81,364.33a9.44,9.44,0,0,1-1.84.5c-1.19.28-2.91.68-5,1.24-4.22,1.09-10,2.81-16.31,5s-11.91,4.47-15.93,6.24c-2,.86-3.62,1.61-4.74,2.12a10.74,10.74,0,0,1-1.76.73,8.83,8.83,0,0,1,1.65-1c1.08-.59,2.67-1.41,4.66-2.34a167.06,167.06,0,0,1,15.91-6.45,154.67,154.67,0,0,1,16.41-4.82c2.13-.49,3.87-.81,5.08-1A9.34,9.34,0,0,1,484.81,364.33Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '462.0199890136719px 372.2449645996094px',
          }}
          id="ellbhnur0nkq"
          className="animable"
        />
        <path
          d="M426.29,438.65s-.16-.18-.41-.56l-1-1.71c-.9-1.48-2.16-3.66-3.67-6.37-3-5.43-7-13.07-11.28-21.55s-8.18-16.16-11.1-21.65l-3.48-6.48-.94-1.78a2.45,2.45,0,0,1-.29-.64,2.68,2.68,0,0,1,.41.57l1,1.7c.9,1.48,2.16,3.66,3.67,6.38,3,5.43,7,13.06,11.28,21.55s8.18,16.15,11.1,21.64l3.48,6.49L426,438A2.83,2.83,0,0,1,426.29,438.65Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '410.2049865722656px 408.2799987792969px',
          }}
          id="elryjqe1jx78"
          className="animable"
        />
        <path
          d="M485.67,408.61a2.42,2.42,0,0,1-.58.36l-1.72.89L477,413.09c-5.37,2.73-12.74,6.59-20.88,10.87s-15.55,8.07-21,10.68c-2.71,1.31-4.91,2.34-6.45,3l-1.77.77c-.41.17-.63.25-.64.22a2.66,2.66,0,0,1,.58-.35l1.72-.9,6.36-3.23c5.36-2.73,12.74-6.59,20.88-10.87s15.55-8.07,21-10.68c2.71-1.3,4.92-2.34,6.46-3l1.77-.77A2.36,2.36,0,0,1,485.67,408.61Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '455.9650115966797px 423.62307357788086px',
          }}
          id="elsegzih14uie"
          className="animable"
        />
      </g>
      <g
        id="freepik--Floor--inject-141"
        style={{ transformOrigin: '249.00001525878906px 478.35499572753906px' }}
        className="animable"
      >
        <path
          d="M484.73,478.35c0,.15-105.55.27-235.72.27s-235.74-.12-235.74-.27,105.53-.26,235.74-.26S484.73,478.21,484.73,478.35Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '249.00001525878906px 478.35499572753906px',
          }}
          id="elzzs4534sp9q"
          className="animable"
        />
      </g>
      <g
        id="freepik--Device--inject-141"
        style={{ transformOrigin: '248.06999969482422px 349.49000549316406px' }}
        className="animable"
      >
        <rect
          x="56.21"
          y="220.39"
          width="381.71"
          height="252.42"
          rx="14.57"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '247.065px 346.59999999999997px',
          }}
          id="elwb7w3xruwrf"
          className="animable"
        />
        <path
          d="M41.29,456.51H454.85a0,0,0,0,1,0,0v4.66a17.42,17.42,0,0,1-17.42,17.42H58.71a17.42,17.42,0,0,1-17.42-17.42v-4.66a0,0,0,0,1,0,0Z"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '248.06999969482422px 467.5500183105469px',
          }}
          id="ell9qjxet2atp"
          className="animable"
        />
        <rect
          x="66.45"
          y="230.42"
          width="360.15"
          height="213.17"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '246.52499999999998px 337.005px',
          }}
          id="elosylwi19q2r"
          className="animable"
        />
        <polygon
          points="209.43 454.15 214.57 462.31 286.78 462.31 291.54 454.15 209.43 454.15"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '250.48500061035156px 458.22999572753906px',
          }}
          id="el8om3aqgwk04"
          className="animable"
        />
        <rect
          x="73.87"
          y="237.64"
          width="346.06"
          height="205.27"
          rx="3.27"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '246.9px 340.275px',
          }}
          id="elmjjnofx1xw"
          className="animable"
        />
        <polygon
          points="419.56 443.21 73.5 443.21 73.87 252.98 419.56 252.98 419.56 443.21"
          style={{
            fill: 'rgb(250, 250, 250)',
            transformOrigin: '246.52999877929688px 348.0949935913086px',
          }}
          id="elsepqwq7adsb"
          className="animable"
        />
        <path
          d="M102.25,245.57a2.76,2.76,0,1,1-2.76-2.77A2.76,2.76,0,0,1,102.25,245.57Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '99.49001574516296px 245.55998635292053px',
          }}
          id="el4hj0oeywgok"
          className="animable"
        />
        <path
          d="M85.5,245.57a2.77,2.77,0,1,1-2.76-2.77A2.76,2.76,0,0,1,85.5,245.57Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '82.72996520996094px 245.57001662254333px',
          }}
          id="elgva786r1vj"
          className="animable"
        />
        <path
          d="M93.54,245.57a2.76,2.76,0,1,1-2.76-2.77A2.76,2.76,0,0,1,93.54,245.57Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '90.780020236969px 245.55998635292053px',
          }}
          id="el8jse5ksyqpw"
          className="animable"
        />
        <path
          d="M434.64,454.15c0,.13-84.72.24-189.21.24s-189.22-.11-189.22-.24,84.7-.25,189.22-.25S434.64,454,434.64,454.15Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '245.4250030517578px 454.1449890136719px',
          }}
          id="elzi98w6bqvf"
          className="animable"
        />
        <path
          d="M383.11,334.88a34.54,34.54,0,0,0-11,34.36,28.07,28.07,0,0,0-33,32.83c-9.91-6.82-25.06-4.27-32.19,5.43-.15-6.4-.4-13.15-3.76-18.6s-11-8.81-16.39-5.35a14.45,14.45,0,0,0-14.56-23.5c-.15-7.68-5-62.06-9-107.94H229.05c-4,45.08-8.57,96.86-8.72,104.35A14.44,14.44,0,0,0,205.78,380c-5.39-3.46-13-.09-16.39,5.35s-3.62,12.2-3.77,18.6c-7.13-9.69-22.27-12.25-32.19-5.43a28.07,28.07,0,0,0-33-32.83,33.75,33.75,0,0,0-46.81-39.11V443.11h345.3v-113A34.53,34.53,0,0,0,383.11,334.88Z"
          style={{
            fill: 'rgb(149, 189, 81)',
            transformOrigin: '246.26998138427734px 347.6099853515625px',
          }}
          id="elvzyr2pjtydq"
          className="animable"
        />
        <g id="elvccl74ve3c">
          <g
            style={{
              opacity: '0.8',
              transformOrigin: '246.26998138427734px 347.6099853515625px',
            }}
            className="animable"
          >
            <path
              d="M383.11,334.88a34.54,34.54,0,0,0-11,34.36,28.07,28.07,0,0,0-33,32.83c-9.91-6.82-25.06-4.27-32.19,5.43-.15-6.4-.4-13.15-3.76-18.6s-11-8.81-16.39-5.35a14.45,14.45,0,0,0-14.56-23.5c-.15-7.68-5-62.06-9-107.94H229.05c-4,45.08-8.57,96.86-8.72,104.35A14.44,14.44,0,0,0,205.78,380c-5.39-3.46-13-.09-16.39,5.35s-3.62,12.2-3.77,18.6c-7.13-9.69-22.27-12.25-32.19-5.43a28.07,28.07,0,0,0-33-32.83,33.75,33.75,0,0,0-46.81-39.11V443.11h345.3v-113A34.53,34.53,0,0,0,383.11,334.88Z"
              style={{
                fill: 'rgb(255, 255, 255)',
                transformOrigin: '246.26998138427734px 347.6099853515625px',
              }}
              id="elt45eni9l20o"
              className="animable"
            />
          </g>
        </g>
        <path
          d="M381,419.84a54.57,54.57,0,0,0-77.61,17.88,32.25,32.25,0,0,0-11.58-9.7,22.33,22.33,0,0,0,.81-2.64,23.1,23.1,0,0,0-27.86-27.67l-10.22-145.6H241.14V386c-8.3,1.27-14.09,11.1-11.16,19a18.08,18.08,0,0,0-13.5,10c-.8,1.72-.92,5.36-.23,9.48a33.27,33.27,0,0,0-26,13.29,54.57,54.57,0,0,0-77.61-17.88c-3.51-17-22.52-29.08-39.4-25v48.53l155.79-.2a12,12,0,0,0,3.29.46l38.08-.45,150.07.19V394.8C403.55,390.76,384.54,402.84,381,419.84Z"
          style={{
            fill: 'rgb(149, 189, 81)',
            transformOrigin: '246.8550033569336px 347.8949890136719px',
          }}
          id="el5ppw2jlnna3"
          className="animable"
        />
        <g id="elgkmalmxduoj">
          <g
            style={{
              opacity: '0.6000000000000001',
              transformOrigin: '246.8550033569336px 347.8949890136719px',
            }}
            className="animable"
          >
            <path
              d="M381,419.84a54.57,54.57,0,0,0-77.61,17.88,32.25,32.25,0,0,0-11.58-9.7,22.33,22.33,0,0,0,.81-2.64,23.1,23.1,0,0,0-27.86-27.67l-10.22-145.6H241.14V386c-8.3,1.27-14.09,11.1-11.16,19a18.08,18.08,0,0,0-13.5,10c-.8,1.72-.92,5.36-.23,9.48a33.27,33.27,0,0,0-26,13.29,54.57,54.57,0,0,0-77.61-17.88c-3.51-17-22.52-29.08-39.4-25v48.53l155.79-.2a12,12,0,0,0,3.29.46l38.08-.45,150.07.19V394.8C403.55,390.76,384.54,402.84,381,419.84Z"
              style={{
                fill: 'rgb(255, 255, 255)',
                transformOrigin: '246.8550033569336px 347.8949890136719px',
              }}
              id="el2ba34x33l4v"
              className="animable"
            />
          </g>
        </g>
      </g>
      <g
        id="freepik--Rocket--inject-141"
        style={{ transformOrigin: '249.63587951660156px 209.21484375px' }}
        className="animable animator-active"
      >
        <path
          d="M233.25,206.53c-7.68,3.65-11.16,14.17-7.16,21.68,1.58,3,4.16,5.57,4.5,8.91.52,5.12-4.32,9.12-5.82,14a11.71,11.71,0,0,0,7.92,14.15c2.63.69,5.78.57,7.54,2.64,2.25,2.64.62,7,2.55,9.91,1.73,2.61,5.78,2.74,8.39,1s4.05-4.75,5.08-7.71,1.8-6.07,3.58-8.65c2-2.83,4.95-4.77,7.33-7.26,5.72-6,7.47-15,6.18-23.21s-5.26-15.72-9.75-22.69"
          style={{
            fill: 'rgb(149, 189, 81)',
            transformOrigin: '249.06613731384277px 243.25446701049805px',
          }}
          id="elnyrljn3dd4q"
          className="animable"
        />
        <g id="eljqyssapvoh">
          <g
            style={{
              opacity: '0.30000000000000004',
              transformOrigin: '249.06613731384277px 243.25446701049805px',
            }}
            className="animable"
          >
            <path
              d="M233.25,206.53c-7.68,3.65-11.16,14.17-7.16,21.68,1.58,3,4.16,5.57,4.5,8.91.52,5.12-4.32,9.12-5.82,14a11.71,11.71,0,0,0,7.92,14.15c2.63.69,5.78.57,7.54,2.64,2.25,2.64.62,7,2.55,9.91,1.73,2.61,5.78,2.74,8.39,1s4.05-4.75,5.08-7.71,1.8-6.07,3.58-8.65c2-2.83,4.95-4.77,7.33-7.26,5.72-6,7.47-15,6.18-23.21s-5.26-15.72-9.75-22.69"
              style={{
                fill: 'rgb(255, 255, 255)',
                transformOrigin: '249.06613731384277px 243.25446701049805px',
              }}
              id="elme17jy6p4yk"
              className="animable"
            />
          </g>
        </g>
        <path
          d="M240,210.85c-6.51,2.87-9.36,11.9-5.68,18,2.25,3.72,6.61,7.08,5.55,11.29-.72,2.86-3.78,4.61-4.66,7.42-1.17,3.74,2.37,7.71,6.27,8.18s7.66-1.61,10.61-4.2c10.21-9,12.7-26.6,6.33-38.61"
          style={{
            fill: 'rgb(149, 189, 81)',
            transformOrigin: '247.30164623260498px 233.32832527160645px',
          }}
          id="elfc0ww2buxk7"
          className="animable"
        />
        <path
          d="M273.56,153.61S307,175.73,303.35,214l-30.74-13.8Z"
          style={{
            fill: 'rgb(149, 189, 81)',
            transformOrigin: '288.1192350387573px 183.80500030517578px',
          }}
          id="ely03x48vsyd"
          className="animable"
        />
        <polygon
          points="218 199.43 225.6 212.02 265.01 212.02 272.61 200.15 218 199.43"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '245.30499267578125px 205.7249984741211px',
          }}
          id="el0nndhtcurebp"
          className="animable"
        />
        <path
          d="M243.26,72.4A29.22,29.22,0,0,0,216,102.16l2,98h54.61l1.82-98.05A29.22,29.22,0,0,0,243.26,72.4Z"
          style={{
            fill: 'rgb(149, 189, 81)',
            transformOrigin: '245.21446418762207px 136.24721908569336px',
          }}
          id="el7ornro81492"
          className="animable"
        />
        <g id="el791bw97gstp">
          <g
            style={{
              opacity: '0.8',
              transformOrigin: '245.21446418762207px 136.24721908569336px',
            }}
            className="animable"
          >
            <path
              d="M243.26,72.4A29.22,29.22,0,0,0,216,102.16l2,98h54.61l1.82-98.05A29.22,29.22,0,0,0,243.26,72.4Z"
              style={{
                fill: 'rgb(255, 255, 255)',
                transformOrigin: '245.21446418762207px 136.24721908569336px',
              }}
              id="elc1ovkgl9xfk"
              className="animable"
            />
          </g>
        </g>
        <path
          d="M217,153.61S183.6,175.73,187.26,214L218,200.18Z"
          style={{
            fill: 'rgb(149, 189, 81)',
            transformOrigin: '202.48988723754883px 183.80500030517578px',
          }}
          id="elxs3kf2v0npn"
          className="animable"
        />
        <path
          d="M216.3,98.91c11.55-10.11,47.8-9.89,57.53-1.66-2.5-36.38-30.28-50-30.28-50C215.13,66.93,216.3,98.91,216.3,98.91Z"
          style={{
            fill: 'rgb(149, 189, 81)',
            transformOrigin: '245.06162071228027px 73.08000183105469px',
          }}
          id="elit1jlri5wcd"
          className="animable"
        />
        <path
          d="M243.55,47.26l.33.3c.21.2.53.49.93.88l1.52,1.44,1,.92,1,1.12c.72.79,1.54,1.66,2.41,2.64l2.66,3.32c.45.61.9,1.27,1.37,1.93s1,1.34,1.42,2.09c.89,1.48,1.88,3,2.73,4.75a54.4,54.4,0,0,1,4.51,11.72,52.37,52.37,0,0,1,1.31,6.88c.17,1.2.26,2.42.43,3.63a36.44,36.44,0,0,1,.13,3.78c0,10.15-.13,21.31-.26,33-.28,23.44-.61,44.65-.86,60-.13,7.67-.24,13.88-.32,18.18,0,2.14-.08,3.81-.1,5,0,.56,0,1,0,1.28s0,.44,0,.44,0-.15,0-.44,0-.72,0-1.28c0-1.14,0-2.81.05-5,.05-4.3.13-10.51.22-18.18.2-15.37.47-36.58.78-60,.13-11.72.28-22.88.28-33a33.06,33.06,0,0,0-.13-3.74c-.16-1.23-.25-2.44-.41-3.63a52.07,52.07,0,0,0-1.3-6.86,54.75,54.75,0,0,0-4.45-11.68C257.9,65,256.91,63.48,256,62c-.44-.75-1-1.41-1.41-2.09s-.9-1.32-1.35-1.93l-2.64-3.33c-.86-1-1.67-1.85-2.39-2.65l-1-1.13-.95-.93-1.5-1.46-.91-.9Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '254.42781352996826px 128.9099998474121px',
          }}
          id="eln6drz6gu98"
          className="animable"
        />
        <g id="elbhaldw63qqa">
          <ellipse
            cx="243.23"
            cy="120.8"
            rx="14.42"
            ry="13.4"
            style={{
              fill: 'rgb(250, 250, 250)',
              transformOrigin: '243.23px 120.8px',
              transform: 'rotate(-77.55deg)',
            }}
            className="animable"
          />
        </g>
        <path
          d="M246.34,106.72a.8.8,0,0,1-.23,0l-.65-.09-.48-.08-.59,0-.68,0c-.25,0-.51,0-.78,0a13.11,13.11,0,0,0-1.81.24,12.78,12.78,0,0,0-2.08.63,14,14,0,0,0-4.44,2.84,14.69,14.69,0,0,0-3.57,5.26,13.06,13.06,0,0,0-.55,1.67c-.07.29-.16.58-.23.87l-.15.9-.08.46,0,.46c0,.31-.05.63-.07.94s0,.63,0,.95v.48l.05.49a14.19,14.19,0,0,0,3,7.44,12.46,12.46,0,0,0,15.65,3.46,14.28,14.28,0,0,0,5.82-5.51l.25-.41.21-.44.42-.85.33-.88.16-.44.13-.45.24-.88c.07-.29.1-.59.16-.88a15.13,15.13,0,0,0,.2-1.75,14.57,14.57,0,0,0-1-6.28,13.68,13.68,0,0,0-4.45-5.89,14.32,14.32,0,0,0-1.54-1c-.25-.12-.47-.26-.69-.36l-.63-.26-.55-.22-.46-.14-.64-.19a1,1,0,0,1-.21-.08l.22,0,.64.16.48.13.55.21.64.25.7.35a14.41,14.41,0,0,1,1.58,1,15,15,0,0,1,1.65,1.45,14,14,0,0,1,2.9,4.47,14.64,14.64,0,0,1,1.07,6.37,13.62,13.62,0,0,1-.19,1.78c-.06.3-.09.6-.16.9s-.16.6-.25.9l-.12.45-.16.44-.34.9-.42.87-.21.45-.26.42a14.36,14.36,0,0,1-5.92,5.62,12.71,12.71,0,0,1-16-3.53,14.43,14.43,0,0,1-3-7.6l0-.49v-.49c0-.32,0-.65,0-1s0-.64.08-1l0-.47.07-.47c.06-.3.11-.61.16-.91s.16-.59.24-.89a12,12,0,0,1,.57-1.69,14.67,14.67,0,0,1,3.65-5.33,14,14,0,0,1,4.52-2.83,13,13,0,0,1,2.11-.62,13.31,13.31,0,0,1,1.83-.22c.28,0,.54,0,.79,0l.69,0,.59,0,.48.09.65.12Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '243.3264970779419px 120.808349609375px',
          }}
          id="elkwfdu69l31o"
          className="animable"
        />
        <path
          d="M229.42,118.64c0,.17-3.08.09-6.79.76s-6.57,1.79-6.63,1.63a5.85,5.85,0,0,1,1.82-.89,26.68,26.68,0,0,1,9.58-1.7A6.27,6.27,0,0,1,229.42,118.64Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '222.70999908447266px 119.73632478713989px',
          }}
          id="elinh87hth0y"
          className="animable"
        />
        <path
          d="M273.57,118.62a12.4,12.4,0,0,1-2.46-.23c-1.5-.18-3.58-.42-5.88-.59s-4.39-.21-5.91-.24a12.57,12.57,0,0,1-2.46-.11,8.82,8.82,0,0,1,2.45-.28,51.16,51.16,0,0,1,6,.08,52.2,52.2,0,0,1,5.9.75A9.86,9.86,0,0,1,273.57,118.62Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '265.2150115966797px 117.86884015798569px',
          }}
          id="els6ra0lgk4l"
          className="animable"
        />
        <path
          d="M273.87,174.7a2.14,2.14,0,0,1-.58,0l-1.65-.13-6.07-.46c-5.12-.36-12.21-.71-20-.7s-14.92.37-20.05.74l-6.06.48-1.65.12a2,2,0,0,1-.58,0,2.21,2.21,0,0,1,.57-.12l1.64-.23c1.42-.19,3.49-.42,6-.65,5.12-.48,12.23-.9,20.08-.91s15,.4,20.07.87c2.57.23,4.63.45,6.06.64l1.64.22A2.21,2.21,0,0,1,273.87,174.7Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '245.5500030517578px 173.80546581745148px',
          }}
          id="elxlscpeaisu"
          className="animable"
        />
        <path
          d="M230.87,132.69c0,.08-.34.11-.57.5a.84.84,0,0,0-.11.69.68.68,0,0,0,.62.42.69.69,0,0,0,.69-.32.85.85,0,0,0,0-.7c-.17-.42-.5-.5-.48-.58s.46-.15.83.39a1.23,1.23,0,0,1,.12,1.1,1.15,1.15,0,0,1-1.19.67A1.12,1.12,0,0,1,230,133C230.44,132.47,230.91,132.62,230.87,132.69Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '230.88363766670227px 133.7422399520874px',
          }}
          id="elok0rryxggxi"
          className="animable"
        />
        <path
          d="M242.77,139.1c0,.09-.34.12-.57.51a.85.85,0,0,0-.11.68.67.67,0,0,0,.62.43.7.7,0,0,0,.69-.33.87.87,0,0,0,0-.69c-.18-.42-.51-.5-.49-.58s.46-.15.83.39a1.21,1.21,0,0,1,.12,1.09,1.16,1.16,0,0,1-1.19.68,1.12,1.12,0,0,1-.78-1.91C242.34,138.89,242.81,139,242.77,139.1Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '242.7508020401001px 140.15026366710663px',
          }}
          id="el4bxv6aycdt8"
          className="animable"
        />
        <path
          d="M255.9,133.63c0,.09-.33.12-.57.51a.85.85,0,0,0-.11.68.7.7,0,0,0,.63.43.67.67,0,0,0,.68-.33.87.87,0,0,0,0-.69c-.17-.42-.51-.5-.49-.59s.46-.14.83.4a1.18,1.18,0,0,1,.13,1.09,1.19,1.19,0,0,1-1.2.68,1.16,1.16,0,0,1-1.07-.85,1.2,1.2,0,0,1,.29-1.06C255.47,133.42,255.94,133.57,255.9,133.63Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '255.8908644914627px 134.68805766105652px',
          }}
          id="elp4egn0jg9xr"
          className="animable"
        />
        <path
          d="M260.17,123.82c0,.08-.34.11-.58.5a.87.87,0,0,0-.1.69.67.67,0,0,0,.62.43.69.69,0,0,0,.68-.33.87.87,0,0,0,0-.69c-.17-.42-.5-.51-.49-.59s.46-.14.84.4a1.2,1.2,0,0,1,.12,1.09,1.16,1.16,0,0,1-1.19.67,1.17,1.17,0,0,1-1.08-.85,1.21,1.21,0,0,1,.29-1.06C259.73,123.61,260.2,123.76,260.17,123.82Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '260.1519080400467px 124.87346172332764px',
          }}
          id="el3i88dgv6lzy"
          className="animable"
        />
        <path
          d="M226.53,122.87c0,.08-.34.11-.57.5a.88.88,0,0,0-.11.69.68.68,0,0,0,.62.43.72.72,0,0,0,.69-.33.83.83,0,0,0,0-.69c-.17-.42-.5-.51-.48-.59s.46-.14.83.4a1.2,1.2,0,0,1,.12,1.09,1.15,1.15,0,0,1-1.19.67,1.17,1.17,0,0,1-1.08-.85,1.19,1.19,0,0,1,.3-1.06C226.1,122.66,226.57,122.81,226.53,122.87Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '226.52196848392487px 123.92386043071747px',
          }}
          id="elnbw31fphru7"
          className="animable"
        />
        <path
          d="M255.88,109.41c0-.08.34-.11.57-.5a.84.84,0,0,0,.11-.69.7.7,0,0,0-.62-.43.69.69,0,0,0-.68.33.79.79,0,0,0,0,.69c.17.42.5.51.48.59s-.46.14-.83-.4a1.2,1.2,0,0,1-.12-1.09,1.15,1.15,0,0,1,1.19-.67,1.12,1.12,0,0,1,.78,1.91C256.31,109.63,255.84,109.47,255.88,109.41Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '255.8986165523529px 108.35779845714569px',
          }}
          id="el0l920mbpaegh"
          className="animable"
        />
        <path
          d="M242.75,103.59c0-.08.34-.11.57-.5a.88.88,0,0,0,.11-.69.71.71,0,0,0-.63-.43.69.69,0,0,0-.68.33.87.87,0,0,0,0,.69c.17.43.51.51.49.59s-.46.15-.83-.4a1.11,1.11,0,0,1,1.07-1.76,1.16,1.16,0,0,1,1.07.85,1.21,1.21,0,0,1-.29,1.06C243.18,103.81,242.71,103.66,242.75,103.59Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '242.75202989578247px 102.53673946857452px',
          }}
          id="el3txd213qtyw"
          className="animable"
        />
        <path
          d="M229.77,109.41c0-.08.34-.11.57-.5a.88.88,0,0,0,.11-.69.71.71,0,0,0-.63-.43.69.69,0,0,0-.68.33.87.87,0,0,0,0,.69c.17.42.51.51.49.59s-.46.14-.83-.4a1.11,1.11,0,0,1,1.07-1.76,1.16,1.16,0,0,1,1.07.85,1.21,1.21,0,0,1-.29,1.06C230.2,109.63,229.73,109.47,229.77,109.41Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '229.7720398902893px 108.35469233989716px',
          }}
          id="eltomuwp1a7is"
          className="animable"
        />
        <path
          d="M241.09,157.63l1.17,58.51a3.07,3.07,0,0,0,3.13,3h0a3.07,3.07,0,0,0,3-3c.12-10.54.51-46,.57-58.57a3.93,3.93,0,0,0-4.52-3.9h0A3.94,3.94,0,0,0,241.09,157.63Z"
          style={{
            fill: 'rgb(149, 189, 81)',
            transformOrigin: '245.02474761009216px 186.38302993774414px',
          }}
          id="el2t5wcygq737"
          className="animable"
        />
        <g id="elhv7ey5if8fa">
          <g
            style={{
              opacity: '0.30000000000000004',
              transformOrigin: '245.02474761009216px 186.38302993774414px',
            }}
            className="animable"
          >
            <path
              d="M241.09,157.63l1.17,58.51a3.07,3.07,0,0,0,3.13,3h0a3.07,3.07,0,0,0,3-3c.12-10.54.51-46,.57-58.57a3.93,3.93,0,0,0-4.52-3.9h0A3.94,3.94,0,0,0,241.09,157.63Z"
              id="ely6l1dpeilsi"
              style={{
                transformOrigin: '245.02474761009216px 186.38302993774414px',
              }}
              className="animable"
            />
          </g>
        </g>
        <path
          d="M242.9,50.6c.17.2-1.77,2.08-4.6,5.38-1.42,1.64-3,3.68-4.67,6a85.48,85.48,0,0,0-4.9,7.91,84.54,84.54,0,0,0-3.89,8.44c-1,2.67-1.85,5.12-2.44,7.21-1.19,4.18-1.69,6.84-2,6.8s-.11-2.76.81-7.09a59.81,59.81,0,0,1,2.14-7.46,68.32,68.32,0,0,1,3.86-8.7,71.68,71.68,0,0,1,5.12-8,59.85,59.85,0,0,1,5.05-5.9C240.53,52,242.77,50.45,242.9,50.6Z"
          style={{
            fill: 'rgb(255, 255, 255)',
            transformOrigin: '231.58720588684082px 71.46525955200195px',
          }}
          id="elaxjqswocwk"
          className="animable"
        />
        <path
          d="M215.77,159.43c.16.25-2.61,2-6.47,5.62l-1.49,1.42c-.52.5-1,1.07-1.54,1.62-1.09,1.1-2.11,2.4-3.22,3.73a65.27,65.27,0,0,0-6.15,9.42,66.75,66.75,0,0,0-4.32,10.4c-.47,1.66-1,3.24-1.26,4.75-.16.76-.35,1.48-.47,2.19s-.23,1.38-.33,2c-.78,5.21-.71,8.5-1,8.51-.12,0-.23-.81-.33-2.31,0-.38-.05-.8-.07-1.25s0-.95,0-1.48a35,35,0,0,1,.21-3.61c.07-.67.15-1.37.23-2.1s.25-1.48.38-2.25c.25-1.57.7-3.2,1.15-4.92a55.52,55.52,0,0,1,10.75-20.35,50.47,50.47,0,0,1,3.42-3.72c.56-.55,1.09-1.11,1.64-1.59l1.6-1.37a34,34,0,0,1,2.87-2.21c.43-.31.83-.61,1.21-.86l1.07-.64C215,159.7,215.7,159.33,215.77,159.43Z"
          style={{
            fill: 'rgb(255, 255, 255)',
            transformOrigin: '202.44384479522705px 184.2519416809082px',
          }}
          id="eldlzxl567sp"
          className="animable"
        />
        <path
          d="M172.73,350.21a7.56,7.56,0,0,1,2.3-14c2.74-.54,6.17.24,7.89-2,1.57-2,.39-4.64,1.08-7.84s3.44-4.71,6.47-4.67a9,9,0,0,1,7.36,4.64,17.55,17.55,0,0,1,2.11,9.7c-.81,6.91-4.5,10.45-9.09,13.38s-13.28,3.6-18.12.73"
          style={{
            fill: 'rgb(149, 189, 81)',
            transformOrigin: '184.44244003295898px 336.8453245162964px',
          }}
          id="elgqcvd3jvu3m"
          className="animable"
        />
        <g id="el8jt9hima80g">
          <g
            style={{
              opacity: '0.8',
              transformOrigin: '184.44244003295898px 336.8453245162964px',
            }}
            className="animable"
          >
            <path
              d="M172.73,350.21a7.56,7.56,0,0,1,2.3-14c2.74-.54,6.17.24,7.89-2,1.57-2,.39-4.64,1.08-7.84s3.44-4.71,6.47-4.67a9,9,0,0,1,7.36,4.64,17.55,17.55,0,0,1,2.11,9.7c-.81,6.91-4.5,10.45-9.09,13.38s-13.28,3.6-18.12.73"
              style={{
                fill: 'rgb(255, 255, 255)',
                transformOrigin: '184.44244003295898px 336.8453245162964px',
              }}
              id="elxt4dwd19pw"
              className="animable"
            />
          </g>
        </g>
        <path
          d="M284.92,294.82a11.8,11.8,0,0,1,21.94,2.75c1,4.25,0,9.63,3.53,12.2,3.21,2.33,7.27.32,12.31,1.2s7.56,5.08,7.67,9.82-2.79,9.24-6.8,11.76a27.45,27.45,0,0,1-15,3.88c-10.83-.85-16.58-6.4-21.43-13.38s-6.41-20.51-2.22-28.23"
          style={{
            fill: 'rgb(149, 189, 81)',
            transformOrigin: '306.4338150024414px 312.4658679962158px',
          }}
          id="el46g7pa5j02n"
          className="animable"
        />
        <g id="ely9bfle7igv">
          <g
            style={{
              opacity: '0.8',
              transformOrigin: '306.4338150024414px 312.4658679962158px',
            }}
            className="animable"
          >
            <path
              d="M284.92,294.82a11.8,11.8,0,0,1,21.94,2.75c1,4.25,0,9.63,3.53,12.2,3.21,2.33,7.27.32,12.31,1.2s7.56,5.08,7.67,9.82-2.79,9.24-6.8,11.76a27.45,27.45,0,0,1-15,3.88c-10.83-.85-16.58-6.4-21.43-13.38s-6.41-20.51-2.22-28.23"
              style={{
                fill: 'rgb(255, 255, 255)',
                transformOrigin: '306.4338150024414px 312.4658679962158px',
              }}
              id="elvwf3fumq07p"
              className="animable"
            />
          </g>
        </g>
        <path
          d="M204.11,280.64a7.37,7.37,0,0,0-4,6.56,7,7,0,1,0,4-6.56"
          style={{
            fill: 'rgb(149, 189, 81)',
            transformOrigin: '207.10808563232422px 286.96657705307007px',
          }}
          id="el8ntb7a4zdge"
          className="animable"
        />
        <g id="eln8bjg2uxoga">
          <g
            style={{
              opacity: '0.8',
              transformOrigin: '207.10808563232422px 286.96657705307007px',
            }}
            className="animable"
          >
            <path
              d="M204.11,280.64a7.37,7.37,0,0,0-4,6.56,7,7,0,1,0,4-6.56"
              style={{
                fill: 'rgb(255, 255, 255)',
                transformOrigin: '207.10808563232422px 286.96657705307007px',
              }}
              id="elm9blncrchd"
              className="animable"
            />
          </g>
        </g>
        <path
          d="M310.43,364.5a3.71,3.71,0,0,0-2,3.28,3.51,3.51,0,1,0,2-3.28"
          style={{
            fill: 'rgb(149, 189, 81)',
            transformOrigin: '311.9391498565674px 367.66947984695435px',
          }}
          id="el19laafrs6p1"
          className="animable"
        />
        <g id="el0apvkgp7ku2q">
          <g
            style={{
              opacity: '0.8',
              transformOrigin: '311.9391498565674px 367.66947984695435px',
            }}
            className="animable"
          >
            <path
              d="M310.43,364.5a3.71,3.71,0,0,0-2,3.28,3.51,3.51,0,1,0,2-3.28"
              style={{
                fill: 'rgb(255, 255, 255)',
                transformOrigin: '311.9391498565674px 367.66947984695435px',
              }}
              id="el4dfmwk2vyl5"
              className="animable"
            />
          </g>
        </g>
      </g>
      <g
        id="freepik--Button--inject-141"
        style={{ transformOrigin: '251.5px 358.2550048828125px' }}
        className="animable"
      >
        <rect
          x="200.68"
          y="337.13"
          width="101.64"
          height="42.25"
          rx="19.74"
          style={{
            fill: 'rgb(149, 189, 81)',
            transformOrigin: '251.5px 358.255px',
          }}
          id="el9rruupnw56"
          className="animable"
        />
        <path
          d="M231.07,361.05l.69-1.53a5.15,5.15,0,0,0,2.89.92c1.2,0,1.69-.4,1.69-.94,0-1.64-5.1-.52-5.1-3.77,0-1.49,1.21-2.73,3.7-2.73a5.89,5.89,0,0,1,3,.77l-.62,1.54a5,5,0,0,0-2.43-.68c-1.21,0-1.67.45-1.67,1,0,1.61,5.1.5,5.1,3.72,0,1.46-1.22,2.72-3.72,2.72A6.24,6.24,0,0,1,231.07,361.05Z"
          style={{
            fill: 'rgb(255, 255, 255)',
            transformOrigin: '234.69501495361328px 357.53516721725464px',
          }}
          id="elji5cw4y1trb"
          className="animable"
        />
        <path
          d="M241.44,354.8h-2.81v-1.65h7.64v1.65h-2.8v7.12h-2Z"
          style={{
            fill: 'rgb(255, 255, 255)',
            transformOrigin: '242.45000457763672px 357.5349884033203px',
          }}
          id="elh7oljog3yt"
          className="animable"
        />
        <path
          d="M252.69,360h-4.07l-.77,1.88h-2.08l3.9-8.77h2l3.92,8.77h-2.13Zm-.64-1.54-1.39-3.36-1.39,3.36Z"
          style={{
            fill: 'rgb(255, 255, 255)',
            transformOrigin: '250.67998504638672px 357.49501037597656px',
          }}
          id="elxe10bn3u0pk"
          className="animable"
        />
        <path
          d="M262.1,361.92l-1.7-2.45h-1.86v2.45h-2v-8.77h3.8c2.34,0,3.8,1.21,3.8,3.18a2.86,2.86,0,0,1-1.8,2.77l2,2.82Zm-1.91-7.12h-1.65v3.06h1.65c1.24,0,1.87-.58,1.87-1.53S261.43,354.8,260.19,354.8Z"
          style={{
            fill: 'rgb(255, 255, 255)',
            transformOrigin: '260.44000244140625px 357.53501892089844px',
          }}
          id="elvu2dm0f1t5"
          className="animable"
        />
        <path
          d="M267.4,354.8h-2.8v-1.65h7.64v1.65h-2.81v7.12h-2Z"
          style={{
            fill: 'rgb(255, 255, 255)',
            transformOrigin: '268.4200134277344px 357.5349884033203px',
          }}
          id="el6wi2yfyrr7g"
          className="animable"
        />
      </g>
      <g
        id="freepik--Character--inject-141"
        style={{ transformOrigin: '358.70326232910156px 382.8468322753906px' }}
        className="animable animator-hidden"
      >
        <path
          d="M405.18,458.75,417,462.9l-.13,11.36c-5.66,1.6-6.45,4.46-6.45,4.46l11.83-.27,10.29-20.93-8.14-5.32-15.22-6.66"
          style={{
            fill: 'rgb(149, 189, 81)',
            transformOrigin: '418.8599853515625px 462.1299743652344px',
          }}
          id="elhsjytnwj3a"
          className="animable"
        />
        <g id="elqdmu09mtzbp">
          <g
            style={{
              opacity: '0.6000000000000001',
              transformOrigin: '421.4800109863281px 467.5550079345703px',
            }}
            className="animable"
          >
            <polygon
              points="432.54 457.52 422.25 478.45 410.42 478.72 411.71 476.91 421.32 476.16 430.81 456.39 432.54 457.52"
              style={{
                fill: 'rgb(255, 255, 255)',
                transformOrigin: '421.4800109863281px 467.5550079345703px',
              }}
              id="elra1tj9hu5s"
              className="animable"
            />
          </g>
        </g>
        <path
          d="M416.83,469.06c.09.12.76-.29,1.68-.28s1.59.37,1.68.25-.57-.79-1.68-.77S416.72,469,416.83,469.06Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '418.5082697868347px 468.67080613970757px',
          }}
          id="elks1hrjaphc"
          className="animable"
        />
        <path
          d="M417,466.24c.12.08.63-.63,1.57-.84s1.7.21,1.77.09-.1-.25-.43-.43a2.24,2.24,0,0,0-2.7.61C416.94,466,416.9,466.2,417,466.24Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '418.65275633335114px 465.5445430278778px',
          }}
          id="eljh6q8dn2e68"
          className="animable"
        />
        <path
          d="M416.8,462.62c.13.06.52-.63,1.34-.93s1.56-.05,1.62-.19-.13-.22-.46-.33a2,2,0,0,0-2.38.9C416.75,462.37,416.74,462.6,416.8,462.62Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '418.2687715291977px 461.8434149026871px',
          }}
          id="elpy9l7xktxqh"
          className="animable"
        />
        <path
          d="M415.93,462.61c.15,0,.06-.78.41-1.58s.93-1.26.86-1.39-.92.2-1.34,1.19S415.8,462.68,415.93,462.61Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '416.4340486526489px 461.11348247528076px',
          }}
          id="el69dwk7r99w4"
          className="animable"
        />
        <path
          d="M414.53,461.28a5.24,5.24,0,0,0,.28-1.59c.05-.57.1-1.14.16-1.73a4,4,0,0,0,0-1.1,1,1,0,0,0-.42-.59.74.74,0,0,0-.81,0,2.2,2.2,0,0,0-.67,1.05,3.82,3.82,0,0,0-.21,1.07,4,4,0,0,0,.31,1.79c.42,1,1,1.31,1,1.27a13.35,13.35,0,0,1-.7-1.4,4.12,4.12,0,0,1-.18-1.62,3.92,3.92,0,0,1,.22-.93,1.84,1.84,0,0,1,.48-.8.21.21,0,0,1,.25,0,.47.47,0,0,1,.2.27,4.16,4.16,0,0,1,0,.94c0,.59,0,1.17-.05,1.74A5.11,5.11,0,0,0,414.53,461.28Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '413.9306732416153px 458.8013422489166px',
          }}
          id="el33jy0r0ok4d"
          className="animable"
        />
        <path
          d="M414.34,461.8c.05,0-.3-.55-1.21-.85a3,3,0,0,0-3.36,1.2.54.54,0,0,0,.05.46.64.64,0,0,0,.31.25,1.89,1.89,0,0,0,.56.11,5.13,5.13,0,0,0,1,0,5,5,0,0,0,1.56-.4c.85-.37,1.24-.8,1.2-.84s-.5.24-1.32.5a5.53,5.53,0,0,1-1.48.26,5.1,5.1,0,0,1-.87,0,1.65,1.65,0,0,1-.4-.08c-.1-.06-.07-.05-.07-.08a.82.82,0,0,1,.23-.28,2.26,2.26,0,0,1,.35-.28,2.67,2.67,0,0,1,.76-.37,2.94,2.94,0,0,1,1.43-.07A9.29,9.29,0,0,1,414.34,461.8Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '412.0964353084564px 461.90782058238983px',
          }}
          id="elocpkw2ygpfl"
          className="animable"
        />
        <g id="elina0rm4dou">
          <g
            style={{
              opacity: '0.6000000000000001',
              transformOrigin: '424.5000545978546px 457.35002958774567px',
            }}
            className="animable"
          >
            <path
              d="M423.1,457.38a1.4,1.4,0,1,0,2.8-.06,1.4,1.4,0,1,0-2.8.06Z"
              style={{
                fill: 'rgb(255, 255, 255)',
                transformOrigin: '424.5000545978546px 457.35002958774567px',
              }}
              id="elxtr5p71ntzi"
              className="animable"
            />
          </g>
        </g>
        <path
          d="M344.54,454l.16,15.48s-15.69,5.37-15.94,8.23l30,.77.74-24.14Z"
          style={{
            fill: 'rgb(149, 189, 81)',
            transformOrigin: '344.1300048828125px 466.24000549316406px',
          }}
          id="elqyyrrdvflbh"
          className="animable"
        />
        <g id="eloro96j8j3qi">
          <g
            style={{
              opacity: '0.6000000000000001',
              transformOrigin: '353.6792880296707px 469.5371471643448px',
            }}
            className="animable"
          >
            <path
              d="M354,468.42a1.23,1.23,0,0,1,.81,1.42,1.19,1.19,0,0,1-1.4.82,1.31,1.31,0,0,1-.86-1.51,1.23,1.23,0,0,1,1.56-.69"
              style={{
                fill: 'rgb(255, 255, 255)',
                transformOrigin: '353.6792880296707px 469.5371471643448px',
              }}
              id="elt2za53nzxp"
              className="animable"
            />
          </g>
        </g>
        <g id="eln9gs8fmm3vo">
          <g
            style={{
              opacity: '0.6000000000000001',
              transformOrigin: '343.7855978012085px 477.260009765625px',
            }}
            className="animable"
          >
            <path
              d="M358.8,478.48l0-2.44-28.79.18s-1.36.54-1.23,1.49Z"
              style={{
                fill: 'rgb(255, 255, 255)',
                transformOrigin: '343.7855978012085px 477.260009765625px',
              }}
              id="elyw2wtlb08n8"
              className="animable"
            />
          </g>
        </g>
        <path
          d="M344.21,469.32c0,.14.74.23,1.45.72s1.07,1.12,1.21,1.06-.06-.91-.93-1.48S344.19,469.18,344.21,469.32Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '345.56018030643463px 470.1518803834915px',
          }}
          id="elo23xha87ju"
          className="animable"
        />
        <path
          d="M341,470.54c0,.14.59.41,1.07,1s.61,1.29.76,1.29.26-.83-.35-1.61S341,470.4,341,470.54Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '341.9737556576729px 471.6535050868988px',
          }}
          id="elcsl3vnpyh0p"
          className="animable"
        />
        <path
          d="M339.14,474.26c.14,0,.36-.69,0-1.5s-1-1.15-1.1-1,.35.56.64,1.23S339,474.25,339.14,474.26Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '338.680824637413px 472.9927487373352px',
          }}
          id="eliakfmhkxc9"
          className="animable"
        />
        <path
          d="M344.57,465.78c.06.14.73,0,1.56,0s1.47.36,1.55.23-.53-.66-1.51-.74S344.5,465.67,344.57,465.78Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '346.1260679960251px 465.6496910750866px',
          }}
          id="ely4peehuzeo"
          className="animable"
        />
        <g id="eltfmkiqpfi3">
          <g
            style={{
              opacity: '0.30000000000000004',
              transformOrigin: '352.0050048828125px 458.56500244140625px',
            }}
            className="animable"
          >
            <polygon
              points="344.57 457.06 344.6 459.82 359.36 460.07 359.44 457.58 344.57 457.06"
              id="eliz09vazfxyt"
              style={{
                transformOrigin: '352.0050048828125px 458.56500244140625px',
              }}
              className="animable"
            />
          </g>
        </g>
        <path
          d="M344.59,462.66a2.81,2.81,0,0,0,1.51,0,6.67,6.67,0,0,0,1.58-.44,7.22,7.22,0,0,0,.88-.44,1.64,1.64,0,0,0,.46-.34.64.64,0,0,0,.09-.78.82.82,0,0,0-.63-.35,2.32,2.32,0,0,0-.57.06,5.17,5.17,0,0,0-.94.3,5.33,5.33,0,0,0-1.41.87c-.72.63-1,1.17-.92,1.2s.42-.4,1.14-.91a5.75,5.75,0,0,1,1.36-.72,5.53,5.53,0,0,1,.87-.25c.32-.07.59-.07.67.08s0,.07,0,.16a1.2,1.2,0,0,1-.34.25c-.27.17-.54.31-.8.44a9.41,9.41,0,0,1-1.47.52A9.41,9.41,0,0,0,344.59,462.66Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '346.89644145965576px 461.5364581346512px',
          }}
          id="elkbo5i60vq3"
          className="animable"
        />
        <path
          d="M344.78,462.88a2.49,2.49,0,0,0,.43-1.45,4.7,4.7,0,0,0-.16-1.65,4.38,4.38,0,0,0-.36-.92,1.25,1.25,0,0,0-.94-.79.7.7,0,0,0-.64.41,2.22,2.22,0,0,0-.17.54,3.51,3.51,0,0,0,0,1,3.34,3.34,0,0,0,.6,1.57c.57.79,1.17,1,1.19.93s-.46-.37-.89-1.13a3.55,3.55,0,0,1-.44-1.42,3.23,3.23,0,0,1,.05-.87c.06-.32.18-.57.3-.54s.4.25.52.51a5.77,5.77,0,0,1,.35.82,5.58,5.58,0,0,1,.25,1.51C344.87,462.31,344.7,462.85,344.78,462.88Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '344.0647243261337px 460.47497844696045px',
          }}
          id="eliumh8p9jau"
          className="animable"
        />
        <polygon
          points="414.36 446.11 379.77 433.25 416.13 428.14 417.96 410.43 349.97 416.36 340.37 430.19 342.89 447.53 409.14 464.37 414.36 446.11"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '379.1649932861328px 437.3999938964844px',
          }}
          id="ely3f3tj76jn9"
          className="animable"
        />
        <path
          d="M341,458.5l-3.31-63.3a17.8,17.8,0,0,1,8.88-14.88h0a17.8,17.8,0,0,1,14.55-1.49l44.11,14.71,24.21,6.23s4.13,10.17,1.7,18.07c-2.37,7.74-10.85,12.89-18.31,10.68-9.27-2.75-51.81-21-51.81-21V459Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '384.7978820800781px 418.4594421386719px',
          }}
          id="el3e41ixzo7ut"
          className="animable"
        />
        <path
          d="M412.21,428.68l-.26-.08-.76-.29-2.89-1.16-10.61-4.33L362.74,408.4l-1.78-.74.29-.19c0,14.33-.05,27.2-.07,36.53,0,4.63,0,8.38-.06,11,0,1.27,0,2.27,0,3,0,.32,0,.58,0,.77a.8.8,0,0,1,0,.27,1.39,1.39,0,0,1,0-.27l0-.77c0-.71,0-1.71,0-3,0-2.62,0-6.37-.06-11,0-9.33,0-22.2-.06-36.53v-.3l.28.11,1.78.74,34.9,14.53L408.36,427l2.86,1.23.74.33Z"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '386.5849914550781px 433.1050109863281px',
          }}
          id="elhph9pqm0lqn"
          className="animable"
        />
        <path
          d="M371.47,428.76a14.52,14.52,0,0,1,8.29,4.59c-.09.09-1.66-1.37-4-2.64S371.43,428.88,371.47,428.76Z"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '375.6147222518921px 431.05698919296265px',
          }}
          id="elhvsgdx86o8n"
          className="animable"
        />
        <path
          d="M357.2,398.91a20.24,20.24,0,0,1,2.34,4.28,21.08,21.08,0,0,1,1.64,4.59c-.11,0-.92-2-2-4.42S357.1,399,357.2,398.91Z"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '359.18654906749725px 403.3450012207031px',
          }}
          id="elgz69rqm1ftv"
          className="animable"
        />
        <polygon
          points="357.3 371.02 349.5 369.56 341.31 377.92 342.82 383.25 352.37 379.73 357.3 371.02"
          style={{
            fill: 'rgb(255, 190, 157)',
            transformOrigin: '349.30499267578125px 376.4049987792969px',
          }}
          id="elstrqwa1ytr"
          className="animable"
        />
        <path
          d="M285.23,351.94l8.87-2,14.28,2.23-.55,9.11s-4.43.64-6,.12a5,5,0,0,0-2.92-.06s.87,1.79-.63,2.08-3.95.92-4.41-1.2a1.62,1.62,0,0,1-1.67-1.22s-1.16.42-1.68-1.61-.22-5.08,3.79-6.05c.21-.05.31-.53-.09-.51l-6.34,1.19S283.62,354.21,285.23,351.94Z"
          style={{
            fill: 'rgb(255, 190, 157)',
            transformOrigin: '296.6232614517212px 356.8385467529297px',
          }}
          id="elbuvccw3mefo"
          className="animable"
        />
        <path
          d="M292.78,358.29a6.29,6.29,0,0,1,1.51-.27,13.94,13.94,0,0,1,1.68-.15,1.35,1.35,0,0,1,1,.47,1.15,1.15,0,0,1,.06,1.22,2.65,2.65,0,0,1-1.81,1.11,13.83,13.83,0,0,1-1.64.36,4,4,0,0,1-1.54,0,12.72,12.72,0,0,1,1.49-.31c.46-.1,1-.24,1.59-.43A6.25,6.25,0,0,0,296,360a1.62,1.62,0,0,0,.69-.63.78.78,0,0,0,0-.81,1.06,1.06,0,0,0-.73-.35c-.57,0-1.16.06-1.63.07A6.18,6.18,0,0,1,292.78,358.29Z"
          style={{
            fill: 'rgb(235, 153, 110)',
            transformOrigin: '294.6083550453186px 359.48741149902344px',
          }}
          id="elth4cmpxscv"
          className="animable"
        />
        <path
          d="M294.3,356.33c0,.11-.44.34-.78.87s-.39,1.05-.5,1.06-.25-.64.18-1.28S294.3,356.23,294.3,356.33Z"
          style={{
            fill: 'rgb(235, 153, 110)',
            transformOrigin: '293.6036143898964px 357.2789116501808px',
          }}
          id="eleuzeo2xuhj6"
          className="animable"
        />
        <path
          d="M296.74,359.58s.09,0,.23,0,.3.29.42.48a1.23,1.23,0,0,1,.15,1,1.2,1.2,0,0,1-.92.78,12.53,12.53,0,0,1-1.95.44c-.51.05-.83,0-.83-.05s.31-.1.79-.22,1.14-.33,1.88-.53a.93.93,0,0,0,.7-.53,1,1,0,0,0,0-.75c-.12-.25-.16-.41-.26-.49S296.72,359.6,296.74,359.58Z"
          style={{
            fill: 'rgb(235, 153, 110)',
            transformOrigin: '295.71306896209717px 360.9403455257416px',
          }}
          id="elbi82f3a1q1m"
          className="animable"
        />
        <path
          d="M297.33,361.13s.1-.18.38-.22a1.64,1.64,0,0,1,1,.26,1.2,1.2,0,0,1,.51.94c0,.26,0,.42-.09.42s-.1-.15-.18-.37a1.23,1.23,0,0,0-.44-.66,2.21,2.21,0,0,0-.79-.32C297.5,361.14,297.36,361.18,297.33,361.13Z"
          style={{
            fill: 'rgb(235, 153, 110)',
            transformOrigin: '298.2749791741371px 361.71823769807816px',
          }}
          id="el52mbk253lq4"
          className="animable"
        />
        <path
          d="M301.75,354.05l-4,1.59L294,358.3s0,.92,2.13.58a9.94,9.94,0,0,0,3.76-1.46s2.79,1.76,5.26,1.35"
          style={{
            fill: 'rgb(255, 190, 157)',
            transformOrigin: '299.57501220703125px 356.5026891231537px',
          }}
          id="elzrluntr2dz"
          className="animable"
        />
        <path
          d="M305.11,358.77s-.13.05-.39.1a5.18,5.18,0,0,1-1.14,0,9.69,9.69,0,0,1-3.82-1.32h.18a9.3,9.3,0,0,1-4.71,1.58,2.44,2.44,0,0,1-.84-.12.88.88,0,0,1-.62-.72v-.09l.08-.06,3.8-2.64h0c1.23-.47,2.24-.85,2.95-1.1a5.81,5.81,0,0,1,1.12-.35,6.32,6.32,0,0,1-1.06.52c-.69.3-1.68.72-2.9,1.22l0,0-3.76,2.69.08-.16c0,.12.17.29.37.36a2,2,0,0,0,.71.1,9.25,9.25,0,0,0,4.52-1.48l.1-.06.09,0a10.36,10.36,0,0,0,3.66,1.41A11.12,11.12,0,0,0,305.11,358.77Z"
          style={{
            fill: 'rgb(235, 153, 110)',
            transformOrigin: '299.4399871826172px 356.5906140804291px',
          }}
          id="eliw2ja6900sa"
          className="animable"
        />
        <path
          d="M295.93,353.85c0,.11-.47.1-.93-.18s-.69-.69-.61-.75.4.18.8.42S296,353.75,295.93,353.85Z"
          style={{
            fill: 'rgb(235, 153, 110)',
            transformOrigin: '295.1561105251312px 353.41294145584106px',
          }}
          id="eltb2gojjr94d"
          className="animable"
        />
        <path
          d="M307.61,363.69l33.86,4.92a13.41,13.41,0,0,0,10.15-.85l30.16-15.47-9-19.91h0a27,27,0,0,0-3.4,1.43l-24.81,17.88-36-1.26Z"
          style={{
            fill: 'rgb(149, 189, 81)',
            transformOrigin: '344.6949768066406px 350.80649757385254px',
          }}
          id="ele4rfd4on227"
          className="animable"
        />
        <polygon
          points="428.79 399.62 426.63 395.83 403.55 390.05 406.27 394.75 428.79 399.62"
          style={{
            fill: 'rgb(255, 190, 157)',
            transformOrigin: '416.1699981689453px 394.8349914550781px',
          }}
          id="elak5vy1vr2ca"
          className="animable"
        />
        <path
          d="M372.75,332.38c0,.8-3.39,25.86-3.39,31s9.8,10.67,9.8,10.67l10.73,22.79h38.23l-13.07-24.54-5.91-19.66c-2.24-7.44-2-11.29-7.55-16.74l-10.69-7.2Z"
          style={{
            fill: 'rgb(149, 189, 81)',
            transformOrigin: '398.739990234375px 362.77001953125px',
          }}
          id="elv9bbnr2jm4e"
          className="animable"
        />
        <path
          d="M383.85,352.16l6.42,26.94L357.89,370l-5.52,9.76,42.13,19.18a11.84,11.84,0,0,0,14.61-4h0a11.86,11.86,0,0,0,1.77-9.74l-9.43-36.85a9,9,0,0,0-11.6-6.31h0A9,9,0,0,0,383.85,352.16Z"
          style={{
            fill: 'rgb(149, 189, 81)',
            transformOrigin: '381.8105239868164px 370.7855339050293px',
          }}
          id="elbulf39vfwss"
          className="animable"
        />
        <path
          d="M379.76,375.3a7.86,7.86,0,0,0-1-1.43c-.24-.12-.58-.3-1-.55a29.71,29.71,0,0,1-3.55-2.35,14.53,14.53,0,0,1-4.18-4.75c-1-2.24-1-5-.59-7.7s1-5.26,1.28-7.61.61-4.46.74-6.24.16-3.23.16-4.23,0-1.56,0-1.56a1.55,1.55,0,0,1,.07.4c0,.26.06.65.09,1.15a41.1,41.1,0,0,1,0,4.26c-.09,1.79-.31,3.93-.65,6.28s-.86,4.91-1.25,7.61-.44,5.35.52,7.48a14.61,14.61,0,0,0,4,4.68,37.93,37.93,0,0,0,3.45,2.45A8.24,8.24,0,0,1,379.76,375.3Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '374.47121715545654px 357.0899963378906px',
          }}
          id="eljyz89o698m9"
          className="animable"
        />
        <path
          d="M392.64,330.48a3.39,3.39,0,0,1-.59.66c-.39.4-1,1-1.71,1.65-1.47,1.37-3.56,3.21-6,5.13s-4.64,3.56-6.3,4.7c-.83.57-1.51,1-2,1.31a3.66,3.66,0,0,1-.77.43,4.42,4.42,0,0,1,.68-.56l1.91-1.42c1.61-1.19,3.83-2.86,6.22-4.78s4.5-3.71,6-5l1.8-1.56A4,4,0,0,1,392.64,330.48Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '383.95501708984375px 337.4199981689453px',
          }}
          id="el3i86k0u4nfh"
          className="animable"
        />
        <path
          d="M383.85,352.16a2.83,2.83,0,0,1,.16.52q.15.56.39,1.5c.34,1.35.81,3.25,1.4,5.62,1.17,4.82,2.8,11.54,4.66,19.25l.08.33-.32-.09L382,377l-24.11-6.85.17-.07-4.09,7.1-1.14,1.92-.3.49c-.07.12-.11.17-.12.17a.9.9,0,0,1,.08-.19l.27-.51,1.06-2,4-7.17.06-.1.11,0,24.14,6.74,8.26,2.34-.25.23c-1.81-7.72-3.39-14.45-4.53-19.28-.54-2.38-1-4.3-1.28-5.65-.14-.64-.25-1.14-.33-1.52A3.36,3.36,0,0,1,383.85,352.16Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '371.4750213623047px 365.9600067138672px',
          }}
          id="elyhn59yphdoa"
          className="animable"
        />
        <path
          d="M401.59,350.48a1.9,1.9,0,0,1,.16.47c.1.35.22.79.38,1.34l1.3,5c1.07,4.24,2.54,10,4.15,16.41.79,3.21,1.57,6.26,2.23,9.06a21.41,21.41,0,0,1,.61,7.6,10.79,10.79,0,0,1-1.8,4.81,5.53,5.53,0,0,1-1.3,1.37,14.17,14.17,0,0,0,1.16-1.46,10.91,10.91,0,0,0,1.66-4.76,21.77,21.77,0,0,0-.7-7.48c-.7-2.78-1.46-5.83-2.26-9l-4-16.44-1.18-5-.3-1.36A3.31,3.31,0,0,1,401.59,350.48Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '406.057324886322px 373.50999450683594px',
          }}
          id="el46n8a6bhwod"
          className="animable"
        />
        <g id="elcuqiz4fm5ww">
          <g
            style={{
              opacity: '0.30000000000000004',
              transformOrigin: '409.7199010848999px 378.4175090789795px',
            }}
            className="animable"
          >
            <path
              d="M407.47,396.82c4.74.43,7.49-2.27,8-6a13.41,13.41,0,0,0-1.06-6c-2.91-8.52-5.85-17.11-10.46-24.85,1.74,7.74,4,15.17,5.71,22.91a23.6,23.6,0,0,1,.73,6.59,11.89,11.89,0,0,1-2.92,7.34"
              id="el6ftpmskzagp"
              style={{
                transformOrigin: '409.7199010848999px 378.4175090789795px',
              }}
              className="animable"
            />
          </g>
        </g>
        <path
          d="M368.6,358a11.1,11.1,0,0,1-1.92-2.11,31.23,31.23,0,0,1-3.55-5.88,30.72,30.72,0,0,1-2.17-6.53,11.3,11.3,0,0,1-.36-2.83,26.05,26.05,0,0,1,.72,2.74,39.18,39.18,0,0,0,2.28,6.4,38.21,38.21,0,0,0,3.38,5.88C368,357.14,368.66,358,368.6,358Z"
          style={{
            fill: 'rgb(250, 250, 250)',
            transformOrigin: '364.6019096374512px 349.32501220703125px',
          }}
          id="elfudz45g72ae"
          className="animable"
        />
        <path
          d="M359.32,363.53a12.74,12.74,0,0,1-1.52-2.17c-.88-1.38-2-3.32-3.22-5.51s-2.2-4.21-2.88-5.69a12.6,12.6,0,0,1-1-2.45A13.91,13.91,0,0,1,352,350l3,5.6c1.15,2.1,2.2,4,3.07,5.57A15.17,15.17,0,0,1,359.32,363.53Z"
          style={{
            fill: 'rgb(250, 250, 250)',
            transformOrigin: '355.00999450683594px 355.61997985839844px',
          }}
          id="elakzqqi62m6b"
          className="animable"
        />
        <path
          d="M346.55,368.51a64.67,64.67,0,0,1-1.56-8,64.63,64.63,0,0,1-1.05-8.09,64.6,64.6,0,0,1,1.56,8A62.64,62.64,0,0,1,346.55,368.51Z"
          style={{
            fill: 'rgb(250, 250, 250)',
            transformOrigin: '345.2449951171875px 360.4650115966797px',
          }}
          id="el5zmi20xcj89"
          className="animable"
        />
        <path
          d="M335.19,367.74a9.2,9.2,0,0,1-.26-2.28c-.09-1.42-.14-3.38-.09-5.55s.2-4.12.35-5.53a9.33,9.33,0,0,1,.37-2.27,10.62,10.62,0,0,1,0,2.29c-.06,1.42-.14,3.37-.2,5.52s-.06,4.11-.06,5.53A10.08,10.08,0,0,1,335.19,367.74Z"
          style={{
            fill: 'rgb(250, 250, 250)',
            transformOrigin: '335.2210577428341px 359.9250030517578px',
          }}
          id="el1h1p4ax90do"
          className="animable"
        />
        <path
          d="M323.82,351.2a8.93,8.93,0,0,1,.28,2.12c.1,1.32.16,3.15.13,5.17s-.17,3.85-.32,5.16a8.06,8.06,0,0,1-.36,2.12c-.15,0,.09-3.27.15-7.29S323.66,351.21,323.82,351.2Z"
          style={{
            fill: 'rgb(250, 250, 250)',
            transformOrigin: '323.8696065545082px 358.4850158691406px',
          }}
          id="el0lsqnul0wbxl"
          className="animable"
        />
        <path
          d="M314.48,352.4a36.82,36.82,0,0,1-.29,6.1,34.79,34.79,0,0,1-.81,6.05,71.36,71.36,0,0,1,1.1-12.15Z"
          style={{
            fill: 'rgb(250, 250, 250)',
            transformOrigin: '313.94162970781326px 358.47499084472656px',
          }}
          id="el2vx9day3hlw"
          className="animable"
        />
        <path
          d="M370.77,343.15c-.15,0-.75-1.62-1.77-3.52s-2-3.34-1.92-3.43a13.07,13.07,0,0,1,3.69,6.95Z"
          style={{
            fill: 'rgb(250, 250, 250)',
            transformOrigin: '368.92270171642303px 339.6750183105469px',
          }}
          id="el2manq6aqxlm"
          className="animable"
        />
        <path
          d="M388.37,370.36a2.09,2.09,0,0,1-.78.09,16.66,16.66,0,0,1-2.15-.05,24.9,24.9,0,0,1-6.91-1.54,25.3,25.3,0,0,1-6.23-3.37,17.77,17.77,0,0,1-1.65-1.38c-.37-.35-.56-.55-.53-.58s.91.66,2.39,1.66a29.77,29.77,0,0,0,13,4.85C387.25,370.26,388.37,370.27,388.37,370.36Z"
          style={{
            fill: 'rgb(250, 250, 250)',
            transformOrigin: '379.24345302581787px 366.9966323375702px',
          }}
          id="elkkkmamzlusm"
          className="animable"
        />
        <path
          d="M384.72,357.59a6.82,6.82,0,0,1-2.08-.23,34.58,34.58,0,0,1-4.89-1.3,32.56,32.56,0,0,1-4.65-2,7.4,7.4,0,0,1-1.79-1.1c.08-.14,2.9,1.33,6.61,2.6S384.75,357.44,384.72,357.59Z"
          style={{
            fill: 'rgb(250, 250, 250)',
            transformOrigin: '378.01510667800903px 355.2736277580261px',
          }}
          id="el9cglvqszijl"
          className="animable"
        />
        <path
          d="M382.6,348.41a12.2,12.2,0,0,1-9.72-3.89,21.09,21.09,0,0,0,9.72,3.89Z"
          style={{
            fill: 'rgb(250, 250, 250)',
            transformOrigin: '377.7400207519531px 346.47703444957733px',
          }}
          id="elig9hennqg"
          className="animable"
        />
        <path
          d="M397.57,341.87c-.05.08-.83-.45-2.25-.85a9.27,9.27,0,0,0-5.62.21,9.13,9.13,0,0,0-4.38,3.53c-.82,1.23-1.06,2.14-1.15,2.11a5.16,5.16,0,0,1,.83-2.31,8.75,8.75,0,0,1,10.43-3.89A5.23,5.23,0,0,1,397.57,341.87Z"
          style={{
            fill: 'rgb(250, 250, 250)',
            transformOrigin: '390.8700408935547px 343.5302429199219px',
          }}
          id="elx1uan0pvgei"
          className="animable"
        />
        <path
          d="M399.34,354.15a6.09,6.09,0,0,1-1.7,1,22.05,22.05,0,0,1-9.16,1.82,5.64,5.64,0,0,1-1.93-.22c0-.16,3,0,6.5-.71S399.27,354,399.34,354.15Z"
          style={{
            fill: 'rgb(250, 250, 250)',
            transformOrigin: '392.9449920654297px 355.5591447353363px',
          }}
          id="elkqknjglx8wi"
          className="animable"
        />
        <path
          d="M404.46,369.56a48.47,48.47,0,0,1-7,.95,49.14,49.14,0,0,1-7.08.43,47.14,47.14,0,0,1,7-.95A49,49,0,0,1,404.46,369.56Z"
          style={{
            fill: 'rgb(250, 250, 250)',
            transformOrigin: '397.4199981689453px 370.2499595284462px',
          }}
          id="elphl7yj558"
          className="animable"
        />
        <path
          d="M409,390.91a2.5,2.5,0,0,1-.8-.16,19.2,19.2,0,0,1-2.09-.71,35.14,35.14,0,0,1-6.45-3.41,69.24,69.24,0,0,1-5.82-4.37,16.16,16.16,0,0,1-2.24-2A17.7,17.7,0,0,1,394,382c1.49,1.11,3.54,2.66,5.89,4.22a46.1,46.1,0,0,0,6.3,3.51C407.9,390.46,409,390.83,409,390.91Z"
          style={{
            fill: 'rgb(250, 250, 250)',
            transformOrigin: '400.29998779296875px 385.58502197265625px',
          }}
          id="elfhrmrsh2666"
          className="animable"
        />
        <path
          d="M384.73,394.16a7.58,7.58,0,0,1-.16-2.27,25.33,25.33,0,0,1,2.58-10.61,7.43,7.43,0,0,1,1.18-1.94,58.23,58.23,0,0,0-2.54,7.23A59.9,59.9,0,0,0,384.73,394.16Z"
          style={{
            fill: 'rgb(250, 250, 250)',
            transformOrigin: '386.437756896019px 386.75px',
          }}
          id="elf95bewch3tg"
          className="animable"
        />
        <path
          d="M372.54,387.63A16,16,0,0,1,376.11,376a45.72,45.72,0,0,0-2.44,5.63A43.84,43.84,0,0,0,372.54,387.63Z"
          style={{
            fill: 'rgb(250, 250, 250)',
            transformOrigin: '374.2910418510437px 381.8150329589844px',
          }}
          id="el52os2w3gd0m"
          className="animable"
        />
        <path
          d="M365.74,371.67a34.73,34.73,0,0,0-3.72,5.38,34.18,34.18,0,0,0-2.15,6.18,5.68,5.68,0,0,1,.15-2,17,17,0,0,1,1.53-4.47,16.76,16.76,0,0,1,2.71-3.87A5.74,5.74,0,0,1,365.74,371.67Z"
          style={{
            fill: 'rgb(250, 250, 250)',
            transformOrigin: '362.79005885124207px 377.45001220703125px',
          }}
          id="elant3mq0mzxq"
          className="animable"
        />
        <path
          d="M410.66,357.7a6.9,6.9,0,0,1-6.35,2.52c0-.16,1.56-.2,3.31-.91S410.55,357.59,410.66,357.7Z"
          style={{
            fill: 'rgb(250, 250, 250)',
            transformOrigin: '407.48500061035156px 358.9897291660309px',
          }}
          id="elunp029y9fig"
          className="animable"
        />
        <path
          d="M406.08,343.67a5,5,0,0,1-1.87,2.48,4.91,4.91,0,0,1-2.81,1.34c0-.14,1.2-.69,2.48-1.75S406,343.58,406.08,343.67Z"
          style={{
            fill: 'rgb(250, 250, 250)',
            transformOrigin: '403.74000549316406px 345.57744777202606px',
          }}
          id="elp1j491otepm"
          className="animable"
        />
        <path
          d="M389.75,334.33a4.92,4.92,0,0,1,2,2.26,5.12,5.12,0,0,1,.83,2.92c-.15,0-.51-1.25-1.29-2.67S389.65,334.43,389.75,334.33Z"
          style={{
            fill: 'rgb(250, 250, 250)',
            transformOrigin: '391.16175282001495px 336.91998291015625px',
          }}
          id="elimosd93201"
          className="animable"
        />
        <path
          d="M414,370.91a6.38,6.38,0,0,1-3.09,1.52,6.31,6.31,0,0,1-3.43.17c0-.15,1.52-.21,3.3-.68S413.94,370.78,414,370.91Z"
          style={{
            fill: 'rgb(250, 250, 250)',
            transformOrigin: '410.74000549316406px 371.8270890712738px',
          }}
          id="elrgk2tw39838"
          className="animable"
        />
        <path
          d="M421,383.22a57.71,57.71,0,0,1-10.93.92,29,29,0,0,1,5.44-.72A27.89,27.89,0,0,1,421,383.22Z"
          style={{
            fill: 'rgb(250, 250, 250)',
            transformOrigin: '415.53501892089844px 383.6547977030277px',
          }}
          id="el9a2kx602hnw"
          className="animable"
        />
        <path
          d="M425.86,393.12a124.65,124.65,0,0,1-16.09-.4,124.65,124.65,0,0,1,16.09.4Z"
          style={{
            fill: 'rgb(250, 250, 250)',
            transformOrigin: '417.8149871826172px 392.92001152038574px',
          }}
          id="elvktxscyti2d"
          className="animable"
        />
        <path
          d="M390.47,293.88c.67,3.39,5.54,4.74,6,8.16.2,1.46-.5,2.92-.44,4.4.06,2,1.45,3.67,2.15,5.53,1.46,3.84-.18,8.39-3.27,11.1s-7.31,3.81-11.41,3.89"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '391.1097846031189px 310.4200134277344px',
          }}
          id="el9uarqb170rq"
          className="animable"
        />
        <path
          d="M362.89,297.38c.43,1.81-.52,3.63-1.52,5.2s-2.13,3.21-2.11,5.06c0,3.3,3.66,5.79,3.52,9.08-.08,1.76-1.26,3.35-1.24,5.12a6,6,0,0,0,2.49,4.29c1.3,1.1,2.56.71,3.76,1.93a14,14,0,0,0,4.85,3.11c3.83,1.37,7.63-2.1,9.26-2.87"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '370.5799036026001px 314.4334087371826px',
          }}
          id="elhiyc1i2cibp"
          className="animable"
        />
        <path
          d="M391.47,295.73c-.22-1.67-2-2.71-3.6-3.07s-3.39-.32-4.92-1a22.75,22.75,0,0,1-3.56-2.66,10.35,10.35,0,0,0-16.46,7.4c-.22,2.46.63,5.19,2.74,6.47,2.45,1.48,5.6.57,8.28-.44s5.73-2.06,8.3-.8a12.21,12.21,0,0,1,3.69,3.58c1.08,1.34,2.39,2.68,4.09,3s3.74-1,3.54-2.72"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '378.23995780944824px 297.61585330963135px',
          }}
          id="elcgzoewdzao"
          className="animable"
        />
        <path
          d="M392.05,331.14l-4-32.39a2.82,2.82,0,0,0-2.93-2.43l-15.32-1.43c-4.8.24-7,6.54-6.6,11.33.49,5.31,1.29,11.79,2.53,15.62,2.48,7.7,8.69,7.76,8.69,7.76S375.54,341,376,343.8"
          style={{
            fill: 'rgb(255, 190, 157)',
            transformOrigin: '377.60191822052px 319.3450012207031px',
          }}
          id="elbs7vdw0lxg5"
          className="animable"
        />
        <path
          d="M365.26,312.13a1,1,0,0,0,1.14.87,1,1,0,0,0,.9-1.08,1,1,0,0,0-1.14-.87A1,1,0,0,0,365.26,312.13Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '366.2800419330597px 312.0250002145767px',
          }}
          id="elqowebuwckxq"
          className="animable"
        />
        <path
          d="M365,310.13c.15.12.83-.57,1.92-.74s2,.26,2.07.1-.12-.28-.5-.48a2.82,2.82,0,0,0-1.69-.25,2.69,2.69,0,0,0-1.5.76C365,309.83,364.9,310.08,365,310.13Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '366.98341178894043px 309.4337708353996px',
          }}
          id="elb5vhhf7i8ra"
          className="animable"
        />
        <path
          d="M376.25,310.52a1,1,0,0,0,1.13.87,1,1,0,0,0,.9-1.07,1,1,0,0,0-1.13-.87A1,1,0,0,0,376.25,310.52Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '377.2650170326233px 310.41999304294586px',
          }}
          id="el4rhq2fx200c"
          className="animable"
        />
        <path
          d="M375.72,308.67c.15.11.83-.58,1.92-.74s2,.25,2.07.1-.12-.29-.51-.49a2.78,2.78,0,0,0-1.68-.24,2.75,2.75,0,0,0-1.5.75C375.73,308.36,375.65,308.62,375.72,308.67Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '377.7102761268616px 307.9713023304939px',
          }}
          id="elrrkg117so3a"
          className="animable"
        />
        <path
          d="M373,317.37a7.63,7.63,0,0,0-1.82,0c-.28,0-.55,0-.63-.19a1.36,1.36,0,0,1,.06-.85c.15-.71.32-1.46.49-2.25a37.33,37.33,0,0,0,1-5.83,36.9,36.9,0,0,0-1.46,5.74q-.24,1.19-.45,2.25a1.66,1.66,0,0,0,0,1.12.71.71,0,0,0,.52.34,2.57,2.57,0,0,0,.49,0A7,7,0,0,0,373,317.37Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '371.5463352203369px 312.98085021972656px',
          }}
          id="elzda1yb48gxr"
          className="animable"
        />
        <path
          d="M374.38,329.6a19.26,19.26,0,0,0,10.06-4.34,10.8,10.8,0,0,1-9.84,6.46Z"
          style={{
            fill: 'rgb(235, 153, 110)',
            transformOrigin: '379.41001892089844px 328.4900207519531px',
          }}
          id="elvk2ztgddek"
          className="animable"
        />
        <path
          d="M373.89,319.93a2,2,0,0,1,1.65-1,1.83,1.83,0,0,1,1.34.47,1.16,1.16,0,0,1,.29,1.29,1.32,1.32,0,0,1-1.37.58,4.13,4.13,0,0,1-1.53-.61,1.61,1.61,0,0,1-.36-.27.39.39,0,0,1-.06-.41"
          style={{
            fill: 'rgb(235, 153, 110)',
            transformOrigin: '375.5360174179077px 320.11123394966125px',
          }}
          id="el81chjt0uoh5"
          className="animable"
        />
        <path
          d="M376.35,317.63c-.18,0,0,1.2-.88,2.19s-2.16,1.07-2.15,1.23.32.19.86.12a3,3,0,0,0,1.8-1,2.65,2.65,0,0,0,.66-1.83C376.61,317.88,376.43,317.61,376.35,317.63Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '374.98053562641144px 319.41727221012115px',
          }}
          id="elgmc9z8u2nwu"
          className="animable"
        />
        <path
          d="M374.74,305.7c.16.28,1.23,0,2.53-.08s2.39.14,2.52-.15-.17-.39-.63-.61a4.37,4.37,0,0,0-3.84.17C374.88,305.29,374.68,305.56,374.74,305.7Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '377.27502155303955px 305.15879398584366px',
          }}
          id="el97tl7f4ha1d"
          className="animable"
        />
        <path
          d="M364.88,306.92c.23.21.94-.16,1.84-.31s1.7-.1,1.83-.38-.1-.37-.47-.55a2.55,2.55,0,0,0-1.56-.17,2.58,2.58,0,0,0-1.4.72C364.84,306.54,364.78,306.81,364.88,306.92Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '366.71070313453674px 306.219988822937px',
          }}
          id="elgd5wpwhikbh"
          className="animable"
        />
        <path
          d="M392.49,323c-2.1.78-2.47-.29-4.33-1.55a7.22,7.22,0,0,1-3.13-5.67c-.06-2,.67-4.12-.29-5.85-1.28-2.28-4.8-2.49-6.08-4.77-.75-1.33-.54-3.05-1.35-4.33a5.32,5.32,0,0,0-3.65-2,13.8,13.8,0,0,1-4.14-1.07c-1.25-.69-2.2-2.22-1.67-3.55a75.93,75.93,0,0,1,17.92,1.47,6.72,6.72,0,0,1,3.75,1.67,7.07,7.07,0,0,1,1.37,4.12q1.19,10.71,2.36,21.43"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '380.4749937057495px 308.7117214202881px',
          }}
          id="eld2r4yp3ksar"
          className="animable"
        />
        <path
          d="M394.91,306.1a6.14,6.14,0,0,1,11.14-3.91,8.69,8.69,0,0,1,.83,7.26c-.79,2.89-2.89,5.74-5.87,6.07-2.79.31-5.41-1.77-6.63-4.31s-1.35-5.44-1.46-8.25"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '400.12073135375977px 307.57446336746216px',
          }}
          id="elu6a97ek0hak"
          className="animable"
        />
        <path
          d="M398.17,316.68a1.59,1.59,0,0,0,.34-.53,4,4,0,0,0,.34-1.67,7.58,7.58,0,0,0-.47-2.49c-.3-.9-.77-1.82-1.16-2.82a7.54,7.54,0,0,1-.64-2.9c0-.9.32-1.71.4-2.44a3.08,3.08,0,0,0-.22-1.69c-.19-.37-.39-.51-.4-.49s.13.18.27.54a3.21,3.21,0,0,1,.1,1.61,20.08,20.08,0,0,0-.47,2.46,7.5,7.5,0,0,0,.63,3c.4,1,.88,1.93,1.19,2.8a8.08,8.08,0,0,1,.52,2.38A5.1,5.1,0,0,1,398.17,316.68Z"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '397.5551847219467px 309.1640625px',
          }}
          id="el83tn6ekiicg"
          className="animable"
        />
        <path
          d="M396.79,307.17a3.65,3.65,0,0,0,1.39,1,7,7,0,0,0,7.83-1.1,3.83,3.83,0,0,0,1.07-1.34c-.05,0-.42.5-1.23,1.15a7.55,7.55,0,0,1-3.63,1.6,7.46,7.46,0,0,1-3.93-.54C397.33,307.54,396.82,307.12,396.79,307.17Z"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '401.93499755859375px 307.31479501724243px',
          }}
          id="elg1kdqmwhacl"
          className="animable"
        />
        <path
          d="M397.37,308.35a3.77,3.77,0,0,0,.87,1.13,8.41,8.41,0,0,0,2.88,1.9,8.57,8.57,0,0,0,3.38.64,3.52,3.52,0,0,0,1.41-.21,11.58,11.58,0,0,0-1.4,0,9.86,9.86,0,0,1-6.1-2.47A11.48,11.48,0,0,0,397.37,308.35Z"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '401.6399841308594px 310.18777906894684px',
          }}
          id="el5n2m7vi6t86"
          className="animable"
        />
        <path
          d="M397.1,319.55s0,0,.08-.11a3.2,3.2,0,0,0,.16-.35,4.1,4.1,0,0,0,.31-1.46,4.93,4.93,0,0,0-.5-2.31,4.52,4.52,0,0,0-2.23-2.19,11.12,11.12,0,0,1-3.27-1.7,2.4,2.4,0,0,1-.72-1.79,10,10,0,0,1,.36-2.16,5.94,5.94,0,0,0-.85-4.7,5.34,5.34,0,0,0-1.91-1.74,6.44,6.44,0,0,0-2.59-.62c-.88-.06-1.75,0-2.58-.15a5,5,0,0,1-2.28-.81,3.46,3.46,0,0,1-1.23-1.93,17.28,17.28,0,0,0-.74-2.24,8,8,0,0,0-2.72-3.4,6.32,6.32,0,0,0-3.62-1.13,5,5,0,0,0-3,.94,4.77,4.77,0,0,0-1.52,1.81,4.27,4.27,0,0,0-.41,1.44,1.89,1.89,0,0,0,0,.52,4.92,4.92,0,0,1,.52-1.9,4.84,4.84,0,0,1,4.38-2.57,6.08,6.08,0,0,1,3.45,1.13,7.76,7.76,0,0,1,2.6,3.29,20.48,20.48,0,0,1,.71,2.21,3.79,3.79,0,0,0,1.35,2.1,5.26,5.26,0,0,0,2.43.89c.87.12,1.74.09,2.61.15a6,6,0,0,1,2.44.59,4.9,4.9,0,0,1,1.8,1.61,5.66,5.66,0,0,1,.81,4.45,10.38,10.38,0,0,0-.35,2.22,2.66,2.66,0,0,0,.82,2,11,11,0,0,0,3.38,1.73,4.29,4.29,0,0,1,2.15,2,4.93,4.93,0,0,1,.54,2.23A5.08,5.08,0,0,1,397.1,319.55Z"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '382.7370185852051px 305.1546850204468px',
          }}
          id="el6a5f42verfd"
          className="animable"
        />
        <path
          d="M393.09,297.59a.82.82,0,0,0,0-.38,1.73,1.73,0,0,0-.42-1,2.72,2.72,0,0,0-1.5-.88,4.14,4.14,0,0,0-2.28.28c-.8.29-1.61.75-2.52,1.08a3.5,3.5,0,0,1-2.92,0,6.43,6.43,0,0,1-2.15-2.58,11.66,11.66,0,0,0-2.33-3,11.15,11.15,0,0,0-6.59-2.93,6.38,6.38,0,0,0-3.14.59,5,5,0,0,0-2.08,1.85,3.75,3.75,0,0,0-.54,2.24,3.57,3.57,0,0,0,.53,1.65,2.85,2.85,0,0,0,.73.81,2.21,2.21,0,0,0,.33.19,4.32,4.32,0,0,1-.95-1.07,3.7,3.7,0,0,1-.45-1.6,3.61,3.61,0,0,1,.56-2.09,4.76,4.76,0,0,1,2-1.72,6,6,0,0,1,3-.52,10.85,10.85,0,0,1,6.37,2.86,11.58,11.58,0,0,1,2.28,2.88,6.56,6.56,0,0,0,2.28,2.68,3,3,0,0,0,1.65.34,5.35,5.35,0,0,0,1.53-.35c.95-.36,1.74-.83,2.51-1.13a4,4,0,0,1,2.15-.32,2.59,2.59,0,0,1,1.43.78,1.67,1.67,0,0,1,.46.93C393.1,297.45,393.07,297.59,393.09,297.59Z"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '379.86078453063965px 292.87775897979736px',
          }}
          id="elva7i95288p"
          className="animable"
        />
      </g>
      <defs>
        {' '}
        <filter id="active" height="200%">
          {' '}
          <feMorphology
            in="SourceAlpha"
            result="DILATED"
            operator="dilate"
            radius={2}
          />{' '}
          <feFlood floodColor="#32DFEC" floodOpacity={1} result="PINK" />{' '}
          <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />{' '}
          <feMerge>
            {' '}
            <feMergeNode in="OUTLINE" /> <feMergeNode in="SourceGraphic" />{' '}
          </feMerge>{' '}
        </filter>{' '}
        <filter id="hover" height="200%">
          {' '}
          <feMorphology
            in="SourceAlpha"
            result="DILATED"
            operator="dilate"
            radius={2}
          />{' '}
          <feFlood floodColor="#ff0000" floodOpacity="0.5" result="PINK" />{' '}
          <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />{' '}
          <feMerge>
            {' '}
            <feMergeNode in="OUTLINE" /> <feMergeNode in="SourceGraphic" />{' '}
          </feMerge>{' '}
          <feColorMatrix
            type="matrix"
            values="0   0   0   0   0                0   1   0   0   0                0   0   0   0   0                0   0   0   1   0 "
          />{' '}
        </filter>
      </defs>
    </svg>
  );
};

export default IdeaIcon;

IdeaIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};
