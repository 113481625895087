import styled from '@emotion/styled';
import { Colors } from 'utils';
import PropTypes from 'prop-types';
import device from './device';
const PrimaryButton = styled.button`
  border-bottom: none;
  font-weight: bold;
  background: ${(props) =>
    !props.primary ? Colors.secondaryColor : Colors.white};
  border: 1px solid ${Colors.secondaryColor};
  color: ${(props) => (!props.primary ? Colors.white : Colors.secondaryColor)};
  text-align: center;
  border-radius: 12px;
  cursor: pointer;
  margin-bottom: 20px;
  padding: 15px 20px;
  min-width: ${(props) => (props.minWidth ? props.minWidth : '200px')};
  font-size: 22px;
  @media ${device.laptop} {
    font-size: 16px;
    min-width: ${(props) => (props.minWidth ? props.minWidth : '100px')};
  }
  @media ${device.allMobile} {
    padding: 10px 20px;
    font-size: 16px;
    background: ${(props) =>
    !props.primary ? Colors.secondaryColor : Colors.white};
    border: 1px solid ${Colors.secondaryColor};
    color: ${(props) => (!props.primary ? Colors.white : Colors.secondaryColor)};
  }
  &:hover {
    background: ${(props) =>
    props.primary ? Colors.secondaryColor : Colors.white};
    color: ${(props) => (props.primary ? Colors.white : Colors.secondaryColor)};
  }
  &:disabled {
    background: ${Colors.steel};
    border: ${Colors.steel};
    color: ${Colors.grey};
    cursor: not-allowed;
  }
`;

export default PrimaryButton;

PrimaryButton.propTypes = {
  primary: PropTypes.bool,
  minWidth: PropTypes.string,
};
