/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { Colors } from 'utils';

const ViewIcon = ({ color, width, height }) => {
  return (
    <svg
      height={`${height || '100%'}`}
      width={`${width || '100%'}`}
      viewBox="0 0 512 512"
      css={css`
        fill: ${color || Colors.black};
        max-width: ${width || '100%'};
        max-height: ${height || '100%'};
        height: ${height || '100%'};
        width: ${width || '100%'};
        display: inline-block;
      `}
    >
      <g>
        <g>
          <path
            d="M509.188,247.27c-4.57-6.39-113.521-156.272-252.182-156.272C118.334,90.998,7.383,240.88,2.813,247.27
			c-3.75,5.22-3.75,12.25,0,17.46c4.57,6.39,115.521,156.271,254.193,156.271c138.661,0,247.612-149.881,252.182-156.272
			C512.938,259.52,512.938,252.489,509.188,247.27z M257.006,361c-57.891,0-107.001-47.11-107.001-105.001
			s49.11-105.001,107.001-105.001s105.001,47.11,105.001,105.001S314.897,361,257.006,361z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M257.006,210.999c-24.82,0-47,20.19-47,45c0,24.82,22.18,45,47,45c24.81,0,45-20.18,45-45
			C302.006,231.189,281.816,210.999,257.006,210.999z"
          />
        </g>
      </g>
    </svg>
  );
};

export default ViewIcon;

ViewIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};
