import React, { Component } from 'react';
import styled from '@emotion/styled';
import { Colors, device } from 'utils';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { authActions } from 'redux/actions';

const RightSideBarWrapper = styled.aside`
  width: 290px;
  padding: 20px;
  min-height: calc(100vh - 96px);
  background: ${Colors.grey};
  text-align: center;
  @media ${device.minLaptop} {
    width: 200px;
  }
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: sticky;
    top: 20px;
    min-height: calc(100vh - 116px);
  }

  button {
    display: block;
    border: 1px solid ${Colors.GreenColor};
    background: ${Colors.GreenColor};
    color: ${Colors.white};
    text-align: center;
    border-radius: 12px;
    cursor: pointer;
    margin-bottom: 20px;
    padding: 15px 20px;
    min-width: 250px;
    font-size: 18px;
    @media ${device.minLaptop} {
      min-width: 160px;
    }
    &:hover {
      color: ${Colors.GreenColor};
      background: ${Colors.transparent};
    }
  }
  ul {
    padding: 0;
    list-style: none;
    li {
      button {
        display: block;
        background: ${Colors.transparent};
        border: 1px solid ${Colors.GreenColor};
        color: ${Colors.GreenColor};
        text-align: center;
        border-radius: 12px;
        cursor: pointer;
        margin-bottom: 20px;
        padding: 15px 20px;
        min-width: 250px;
        font-size: 18px;
        @media ${device.minLaptop} {
          min-width: 160px;
        }
        &.active,
        &:hover {
          background: ${Colors.GreenColor};
          color: ${Colors.white};
        }
      }
    }
  }
  .setting-list {
    padding-left: 50px;
    @media ${device.minLaptop} {
      padding-left: 0;
    }
    button {
      min-width: 200px;
      @media ${device.minLaptop} {
        min-width: 160px;
      }
    }
  }
`;

class RightSideBar extends Component {
  static propTypes = {
    setCurrentView: PropTypes.func.isRequired,
    currentView: PropTypes.string.isRequired,
    logout: PropTypes.func.isRequired,
  };
  state = {
    showSettingList: false,
  };
  render() {
    const { showSettingList } = this.state;
    const { setCurrentView, currentView, logout } = this.props;
    return (
      <RightSideBarWrapper>
        <div>
          <div>
            <nav>
              <ul>
                <li>
                  <button
                    onClick={() => setCurrentView('profile')}
                    htmltype="button"
                    className={currentView === 'profile' ? 'active' : ''}
                  >
                    Profil
                  </button>
                </li>
                {/* <li>
                  <button
                    onClick={() => setCurrentView('projects')}
                    htmltype="button"
                    className={currentView === 'projects' ? 'active' : ''}
                  >
                    Eigene Projekte
                  </button>
                </li> */}
                <li>
                  <button
                    htmltype="button"
                    onClick={() =>
                      this.setState({ showSettingList: !showSettingList })
                    }
                  >
                    Einstellungen
                  </button>
                  {(showSettingList ||
                    [
                      'userInfo',
                      'resetPassword',
                      'resetEmail',
                      'suppers',
                      'payment',
                    ].includes(currentView)) && (
                    <ul className="setting-list">
                      <li>
                        <button
                          onClick={() => setCurrentView('userInfo')}
                          htmltype="button"
                          className={currentView === 'userInfo' ? 'active' : ''}
                        >
                          Persönliche Daten
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={() => setCurrentView('resetPassword')}
                          htmltype="button"
                          className={
                            currentView === 'resetPassword' ? 'active' : ''
                          }
                        >
                          Passwort ändern
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={() => setCurrentView('resetEmail')}
                          htmltype="button"
                          className={
                            currentView === 'resetEmail' ? 'active' : ''
                          }
                        >
                          E-Mail ändern
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={() => setCurrentView('suppers')}
                          htmltype="button"
                          className={currentView === 'suppers' ? 'active' : ''}
                        >
                          Unterstützungen
                        </button>
                      </li>
                      {/* <li>
                        <button
                          onClick={() => setCurrentView('payment')}
                          htmltype="button"
                          className={currentView === 'payment' ? 'active' : ''}
                        >
                          Zahlungsmethoden
                        </button>
                      </li> */}
                    </ul>
                  )}
                </li>
              </ul>
            </nav>
          </div>
          <div>
            <button htmltype="button" onClick={() => logout()}>
              Abmelden
            </button>
          </div>
        </div>
      </RightSideBarWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  const { user } = state.authentication;
  return {
    user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(authActions.logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RightSideBar);
