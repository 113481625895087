import React, { Component } from 'react';
import { Form, Input } from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ProjectsActions } from 'redux/actions';
import {
  PrimaryButton,
  formLayout,
  formValidateMessages,
  H1,
  FormWrapper,
  Colors,
} from 'utils';
import { UploadImage } from 'components';
import styled from '@emotion/styled';
const FormItem = Form.Item;
const UpdatesFormWrapper = styled.div`
  h1 {
    margin: 0;
  }
  .form-wrapper {
    margin: 0;
    padding: 0;
    .ant-form-item {
      display: block;
      .ant-form-item-label {
        color: ${Colors.GreenColor};
        margin-bottom: 10px;
        label {
          font-weight: bold;
          font-size: 22px;
        }
      }
    }
    .ant-input {
      width: 100%;
      padding: 10px;
      border: 1px solid ${Colors.GreenColor};
      border-radius: 10px;
    }
  }
  button {
    margin: 0;
  }
`;
export class UpdatesForm extends Component {
  static propTypes = {
    createProjectUpdates: PropTypes.func.isRequired,
    projectId: PropTypes.string.isRequired,
    handelCloseModal: PropTypes.func.isRequired,
  };

  state = {
    image: null,
  };

  onFinish = (values) => {
    const { createProjectUpdates, projectId, handelCloseModal } = this.props;

    // Create the form data
    const formData = new FormData();

    formData.append('update[title]', values.title);
    formData.append('update[body]', values.body);
    formData.append('update[project_id]', Number(projectId));
    if (
      this.state.image &&
      this.state.image[0] &&
      this.state.image[0].originFileObj
    ) {
      formData.append(
        'update[image]',
        this.state.image[0].originFileObj,
        this.state.image[0].name
      );
    }
    // Send the Create Request
    createProjectUpdates(formData);

    // Reset the Image
    this.setState({ image: null });
    handelCloseModal();
  };
  render() {
    return (
      <UpdatesFormWrapper>
        <H1>Updates</H1>
        <Form
          {...formLayout}
          name="project-details"
          onFinish={this.onFinish}
          validateMessages={formValidateMessages}
        >
          <FormWrapper className="form-wrapper">
            <>
              <p>
                Updates über dein Projekt: Hier kannst du deine Community auf
                dem Laufenden halten.
              </p>
              <p>
                Nutze die Funktion für Neuigkeiten zum Projekt, Medienberichte,
                Vorstellung des Teams, erreichte Milestones und
                Projektverzögerungen.
              </p>
              <p>Der Updateeintrag erscheint auf deiner Projektseite.</p>

              <FormItem
                name="title"
                label="Titel"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </FormItem>
              <FormItem
                name="body"
                label="Update-Text"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input.TextArea />
              </FormItem>

              <UploadImage
                required={false}
                multiple={false}
                label="Bild"
                name="image"
                setImage={(image) => {
                  this.setState({ image });
                }}
              />
              <p className="remark">Empfohlene Größe: 250 x 200 pixels</p>
            </>
          </FormWrapper>
          <FormItem>
            <PrimaryButton type="primary" htmltype="submit" minWidth="150px">
              Hinzufügen
            </PrimaryButton>
          </FormItem>
        </Form>
      </UpdatesFormWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  const { updates, loading, errors } = state.projects;
  return {
    updates,
    loading,
    errors,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createProjectUpdates: (update) =>
    dispatch(ProjectsActions.createProjectUpdates(update)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdatesForm);
