import React, { Component } from 'react';
import { Form, Input, Spin } from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import pickBy from 'loadsh/pickBy';
import identity from 'loadsh/identity';
import isEmpty from 'loadsh/isEmpty';
import {
  Container,
  PrimaryButton,
  formLayout,
  formValidateMessages,
  H1,
  FormWrapper,
} from 'utils';
import { ProjectsActions } from 'redux/actions';
const FormItem = Form.Item;

class SocialNetworks extends Component {
  static propTypes = {
    updateDraft: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    projectId: PropTypes.string.isRequired,
    errors: PropTypes.object.isRequired,
    currentStep: PropTypes.number.isRequired,
    setCanMove: PropTypes.func.isRequired,
    showModal: PropTypes.bool.isRequired,
    selectedStep: PropTypes.number.isRequired,
    setSubmitFromModal: PropTypes.func.isRequired,
  };
  onFinish = (values) => {
    const {
      updateDraft,
      projectId,
      currentStep,
      setCanMove,
      showModal,
      selectedStep,
      setSubmitFromModal,
    } = this.props;

    if (showModal) {
      updateDraft(projectId, values, selectedStep - 1);
      setSubmitFromModal(false);
    } else {
      updateDraft(projectId, values, currentStep);
    }
    setCanMove(true);
    this.props.handleSteps(8);
  };
  render() {
    const { project, loading, setCanMove } = this.props;
    return (
      <Container className="inside-form-container">
        <Spin spinning={loading}>
          <H1>Soziale Medien</H1>
          <p>Soziale Medien des Projekts.</p>
          { !loading && (
            <Form
              {...formLayout}
              name="project-details"
              onFinish={this.onFinish}
              onChange={() => setCanMove(false)}
              validateMessages={formValidateMessages}
              initialValues={project ? pickBy(project.netWork, identity) : {}}
            >
              <div>
                <h2>Links zu deinen Seiten</h2>
                <FormWrapper>
                  <>
                    <FormItem
                      name="facebook_url"
                      label="Facebook-Page"
                      extra="https://www.facebook.com/deinepage"
                    >
                      <Input />
                    </FormItem>
                    <FormItem
                      name="instagram_url"
                      label="Instagram-Profil"
                      extra="https://www.instagram.com/deinprofil"
                    >
                      <Input />
                    </FormItem>
                    <FormItem
                      name="website_url"
                      label="Webseite"
                      extra="https://www.webseite.de"
                    >
                      <Input />
                    </FormItem>
                    <FormItem>
                      <PrimaryButton
                        type="primary"
                        htmltype="submit"
                        minWidth="150px"
                        id="submit-button"
                      >
                        Speichern
                      </PrimaryButton>
                    </FormItem>
                  </>
                </FormWrapper>
              </div>
            </Form>
          )}
        </Spin>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const { project, loading, errors, currentStep } = state.projects;
  return {
    project,
    loading,
    errors,
    currentStep,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleSteps: (step) => dispatch(ProjectsActions.handleSteps(step)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SocialNetworks);
