import React, { Component } from 'react';
import { Form, Input, DatePicker, Upload, Select } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { AddressForm } from 'components';
import { dateFormat, eighteenYearsFromNow } from 'utils';
const FormItem = Form.Item;
const { Option } = Select;
export default class Privatperson extends Component {
  state = {
    showPersonalInfo: false,
  };
  onChange = (value) => {
    this.setState({ showPersonalInfo: value === 'Ja' ? false : true });
  };
  render() {
    const { showPersonalInfo } = this.state;
    return (
      <>
        <FormItem
          name={['Privatperson', 'aboutYou']}
          label="bist du der gesetzlich Vertreter?"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select style={{ width: '100%' }} onChange={this.onChange}>
            <Option key={'Ja'} value={'Ja'}>
              Ja
            </Option>
            <Option key={'Nein'} value={'Nein'}>
              Nein
            </Option>
          </Select>
        </FormItem>
        {showPersonalInfo && (
          <>
            <FormItem
              name={['Privatperson', 'firstName']}
              label="Vorname"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </FormItem>
            <FormItem
              name={['Privatperson', 'lastName']}
              label="Nachname"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </FormItem>
            <FormItem
              name={['Privatperson', 'email']}
              label="E-Mail-Adresse"
              rules={[
                {
                  required: true,
                  message: 'Bitte gib deine E-Mail-Adresse ein.',
                },
              ]}
            >
              <Input type="email" />
            </FormItem>
            <AddressForm />
            <FormItem
              name={['Privatperson', 'DateOfBirth']}
              label="Geburtsdatum"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <DatePicker
                format={dateFormat}
                disabledDate={(d) => !d || d.isAfter(eighteenYearsFromNow)}
              />
            </FormItem>
            <FormItem
              name={['Privatperson', 'nationality']}
              label="Staatsangehörigkeit"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </FormItem>
          </>
        )}

        <Form.Item
          name="dragger"
          valuePropName="fileList"
          getValueFromEvent={this.normFile}
          label="Ausweis/Reisepass/Führerschein"
        >
          <Upload.Dragger name="files">
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload.
            </p>
          </Upload.Dragger>
        </Form.Item>
      </>
    );
  }
}
