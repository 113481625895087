import React, { Component } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Colors, Modal, PrimaryButton, device } from 'utils';
import { Layout, PaymentCard } from 'components';
import { Donation, Danke, InfoForm } from './components';
import { Redirect } from 'react-router-dom';
const DonationWrapper = styled.div``;
const DonationFormWrapper = styled.div`
  display: flex;
  margin-top: 100px;
  @media ${device.allMobile} {
    flex-wrap: wrap;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .paymentCard {
    label {
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .ant-row {
      display: block;
    }
    .payment-card {
      max-width: 100%;

      .ant-form-item-control-input-content {
        @media ${device.allMobile} {
          max-width: 200px;
        }
        div {
          max-width: 100%;
        }
        input {
          font-size: 18px;
        }
        #cardNumber {
          width: 300px;
        }
      }
    }
  }

  h1 {
    font-weight: bold;
  }
  .zahlungsmethode {
    h2 {
      font-size: 22px;
      font-weight: bold;
      @media ${device.allMobile} {
        font-size: 16px;
      }
    }
    & > div {
      display: flex;
      justify-content: space-around;
      font-size: 35px;
      margin-bottom: 20px;
      @media ${device.allMobile} {
        font-size: 22px;
      }
    }
  }
  .deine-label {
    input {
      border-radius: 15px;
      padding: 15px;
    }
  }
  .gesamtsumme-button {
    @media ${device.allMobile} {
      min-width: 100%;
    }
  }
  .gesamtsumme {
    display: flex;
    justify-content: space-between;
    h2 {
      font-size: 30px;
      font-weight: bold;
      margin: 0;
      @media ${device.allMobile} {
        font-size: 20px;
      }
    }
    p {
      font-size: 30px;
      font-weight: bold;
      margin: 0;
      @media ${device.allMobile} {
        font-size: 20px;
      }
    }
    & + p {
      font-size: 22px;
      margin-bottom: 30px;
      @media ${device.allMobile} {
        font-size: 16px;
      }
    }
  }
  .danke {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: 500px;
    p {
      text-align: left;
      font-size: 22px;
      @media ${device.laptop} {
        font-size: 18px;
      }
      @media ${device.allMobile} {
        font-size: 16px;
      }
    }
  }
  & > div {
    width: 50%;
    padding: 20px 30px;
    &:first-of-type {
      padding-left: 0;
    }
    &:nth-of-type(2) {
      padding-right: 0;
    }
    @media ${device.allMobile} {
      width: 100%;
    }
    @media ${device.allMobile} {
      padding: 0;
    }
    @media ${device.tablet} {
      margin-top: 0;
    }
    .ant-checkbox-wrapper {
      a {
        color: ${Colors.secondaryColor};
        margin: 0 3px;
        display: inline-block;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .freiwilliges-input {
      position: relative;
      .anticon {
        position: absolute;
        right: 150px;
        font-size: 22px;
        top: 50%;
        transform: translateY(-50%);
        @media ${device.allMobile} {
          font-size: 16px;
          right: 10px;
        }
      }
    }
    .donation-input {
      display: flex;
      padding: 20px 20px 20px 20px;
      border: 1px solid ${Colors.steel};
      border-radius: 10px;
      justify-content: space-between;
      align-items: center;
      @media ${device.allMobile} {
        padding: 10px;
      }
      input,
      .ant-input-group-addon {
        border: 0;
        background: transparent;
        font-weight: bold;
        font-size: 22px;
        @media ${device.allMobile} {
          font-size: 16px;
        }
      }

      .ant-form-item-control {
        text-align: right;
        @media ${device.tablet} {
          max-width: 97px;
        }
        @media ${device.allMobile} {
          max-width: 55%;
        }
        input {
          font-weight: bold;
          font-size: 22px;
          padding-right: 10px;
          text-align: right;
          max-width: 100%;
          @media ${device.allMobile} {
            font-size: 16px;
            max-width: 150px;
          }
          @media ${device.tablet} {
            font-size: 16px;
            max-width: 104px;
          }
        }
        .ant-input-group-addon {
          padding: 0;
          text-align: left;
        }
      }
      .ant-form-item-label {
        width: 40%;
        display: inline-block;
        text-align: left;
        padding: 0;
        @media ${device.tablet} {
          width: 60%;
        }
        @media ${device.allMobile} {
          width: 45%;
          max-width: 45%;
        }
        label {
          font-size: 22px;
          font-weight: bold;
          @media ${device.allMobile} {
            font-size: 16px;
            line-height: 1;
          }
          &:before,
          &:after {
            content: none;
          }
        }
      }
    }
  }

  .show-options {
    .donation-input {
      .ant-form-item-label {
        width: 20%;
        @media ${device.laptop} {
          width: 31%;
          max-width: 31%;
        }
        @media ${device.allMobile} {
          width: 80px;
          max-width: 80px;
        }
        @media ${device.tablet} {
          width: 35%;
          max-width: 35%;
        }
      }
      .ant-form-item-control {
        max-width: 80%;
        @media ${device.laptop} {
          width: 69%;
          max-width: 69%;
        }
        @media ${device.tablet} {
          width: 65%;
          max-width: 65%;
        }
        @media ${device.allMobile} {
          width: calc(100% - 80px);
          max-width: calc(100% - 80px);
        }
      }
    }
    .freiwilliges-option {
      @media ${device.allMobile} {
        display: flex;
        max-width: 223px;
        margin-left: auto;

        overflow: scroll;
      }

      div {
        display: inline-block;
        button {
          margin: 0;
          @media ${device.allMobile} {
            padding: 5px 10px;
            font-size: 15px;
            border-radius: 5px;
            min-width: 56px;
          }
        }
        & + div {
          margin-left: 15px;
          @media ${device.tablet} {
            margin-left: 10px;
          }
          @media ${device.allMobile} {
            margin-left: 10px;
          }
        }
      }
    }
  }
`;
const DankeModal = styled.div`
  text-align: center;
  padding: 20px 25px;
  @media ${device.tablet} {
    min-width: 500px;
  }
  button {
    margin-top: 25px;
  }
`;

export class DonationPage extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    donationSuccess: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
  };
  state = {
    showModal: false,
    showPaymentForm: false,
    currentView: null,
    showOption: false,
    otherOption: false,
    donation: parseFloat(
      new URLSearchParams(this.props.location.search).get('donation') || 0
    ).toFixed(2),
    freiwilliges: 0,
    allRewards: [],
    selectedRewards: [],
    addressRequired: false,
    clientSecret: null,
  };
  donationFormRef = React.createRef();

  handleCLientSecret = (value) => {
    this.setState({ clientSecret: value });
  };
  handelCloseModal = () => {
    this.setState({ showModal: false });
    document.body.style.overflowY = 'unset';
  };

  handelShowModal = () => {
    this.setState({ showModal: true });
    document.body.style.overflowY = 'hidden';
  };
  setDonationState = (donation) => {
    this.setState({ donation });
  };
  setCurrentViewState = (currentView) => {
    this.setState({ currentView });
  };
  setShowOptionState = (showOption) => {
    this.setState({ showOption });
  };
  setOtherOptionState = (otherOption) => {
    this.setState({ otherOption });
  };
  setFreiwilligesState = (freiwilliges) => {
    this.setState({ freiwilliges });
  };
  setShowPaymentFormState = (showPaymentForm) => {
    this.setState({ showPaymentForm });
  };
  setAllRewards = (allRewards) => {
    this.setState({ allRewards });
  };
  setSelectedRewards = (selectedRewards) => {
    this.setState({ selectedRewards });
  };
  setAddressRequired = (selectedRewards) => {
    let isAddressRequired = false;
    selectedRewards.forEach((reward) => {
      isAddressRequired = isAddressRequired || reward.address_required;
    });
    this.setState({
      addressRequired: isAddressRequired,
    });
  };

  render() {
    const {
      showPaymentForm,
      donation,
      freiwilliges,
      showOption,
      otherOption,
      currentView,
      showModal,
    } = this.state;
    const {
      location: { search },
      donationSuccess,
      match: {
        params: { id },
      },
    } = this.props;

    const originalDonation = Number(
      new URLSearchParams(search).get('donation') || 0
    );
    const originalRewardId = Number(
      new URLSearchParams(search).get('rewardId') || 0
    );

    return (
      <>
        <DonationWrapper>
          {donationSuccess && <Redirect to={{ pathname: '/thank-you' }} />}
          <Layout container={true}>
            <>
              <DonationFormWrapper>
                <Donation
                  showPaymentForm={showPaymentForm}
                  showOption={showOption}
                  otherOption={otherOption}
                  originalDonation={originalDonation}
                  donation={donation}
                  freiwilliges={freiwilliges}
                  setDonationState={this.setDonationState}
                  setCurrentViewState={this.setCurrentViewState}
                  setShowOptionState={this.setShowOptionState}
                  setOtherOptionState={this.setOtherOptionState}
                  setFreiwilligesState={this.setFreiwilligesState}
                  setShowPaymentFormState={this.setShowPaymentFormState}
                  projectId={id}
                  originalRewardId={originalRewardId}
                  setAllRewards={this.setAllRewards}
                  setSelectedRewards={this.setSelectedRewards}
                  allRewards={this.state.allRewards}
                  selectedRewards={this.state.selectedRewards}
                  formRef={this.donationFormRef}
                  setAddressRequired={this.setAddressRequired}
                  handleCLientSecret={this.handleCLientSecret}
                />
                {!showPaymentForm && (
                  <Danke handelShowModal={this.handelShowModal} />
                )}

                {showPaymentForm && currentView === 'info' && (
                  <InfoForm
                    setCurrentViewState={this.setCurrentViewState}
                    amount={Number(donation)}
                    tips={Number(freiwilliges)}
                    projectId={id}
                    donationFormRef={this.donationFormRef}
                    selectedRewards={this.state.selectedRewards}
                    addressRequired={this.state.addressRequired}
                    clientSecret={this.state.clientSecret}
                    handleCLientSecret={this.handleCLientSecret}
                    // handleCLientSecret={handleCLientSecret}
                  />
                )}

                {showPaymentForm && currentView === 'payment' && (
                  <div className="paymentCard zahlungsmethode">
                    <PaymentCard />
                  </div>
                )}
              </DonationFormWrapper>
            </>
          </Layout>
        </DonationWrapper>
        <Modal showModal={showModal} handelCloseModal={this.handelCloseModal}>
          <DankeModal>
            <p>
              Unterstütze uns, um deine Community zu unterstützen! Wir sind ein
              junges Team, das den Traum hat, etwas Positives für die
              muslimische Gemeinschaft in Deutschland zu bewirken. Wir sorgen
              mit dem Geist der Gemeinschaft dafür, dass Muslime in Deutschland
              mit großartigen Ideen zum Wohl der Gesamtgesellschaft beitragen.
              Auch commonsplace finanziert sich durch die Gemeinschaft -
              Crowdfinanzierung: Wenn Du ein Projekt auf commonsplace
              unterstützt, kannst du auch uns mit einem freiwilligen Beitrag
              behilflich sein. Dank Deines Beitrags können wir die Plattform
              bereitstellen, Projekte mit aufbauen und so die Community
              unterstützen, Großes zu erreichen. Ohne Dich gibt es uns nicht!
              Vielen Dank, dass Du Teil unserer Vision bist.
            </p>
            <PrimaryButton onClick={() => this.handelCloseModal()}>
              Teil der Vision werden
            </PrimaryButton>
          </DankeModal>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { donationSuccess } = state.payment;
  return {
    donationSuccess,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DonationPage);
