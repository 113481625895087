import styled from '@emotion/styled';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Colors, device, PrimaryButton } from 'utils';
import CurrencyInput from 'react-currency-input-field';
import { Modal } from 'antd';

const SupportWrapper = styled.div`
& {
  width:100%;
  margin-bottom:20px
}
  h1 {
    font-size: 25px;
    font-weight: bold;
    text-align: center;
  }
  & > div {
    // border: 2px solid ${Colors.grey};
    border-radius: 8px;
    // padding: 20px;
    // margin-top: 42px;

    p {
      margin: 0;
      &.price {
        color: ${Colors.primaryColor};

        font-weight: 600;
        & + p {
          font-family: 'Roboto';
          margin-bottom: 10px;
          color: ${Colors.steel};
          font-weight: 100;
        }
      }
    }
    form {
      position: relative;
      // margin: 0 0 25px;
      input {
        border: ${(props) =>
    props.canSubmit
      ? `1px solid ${Colors.steel}`
      : `1px solid ${Colors.red}`};
        border-radius: 10px;
        width: 100%;
        height: 57px;
        padding: 10px;
        text-align: center;
        &:focus {
          outline: none;
        }
      }
      span {
        position: absolute;
        right: 10px;
        font-weight: 600;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    a {
      width: 100%;
      // margin-top: 20px;
      border-bottom: none;
      background: ${Colors.secondaryColor};
      border: 1px solid ${Colors.secondaryColor};
      color: ${Colors.white};
      text-align: center;
      border-radius: 12px;
      cursor: pointer;
      padding: 10px 30px;
      font-size: 22px;
      display: block;
      margin-bottom: 0px;
      font-weight: bold;
      @media ${device.laptop} {
        font-size: 18px;
        padding: 15px 30px;
      }
      @media ${device.allMobile} {
        font-size: 18px !important;
        padding: 15px 30px;
      }
      &:hover {
        box-shadow: 0 8px 12px 2px rgba(0, 0, 0, 0.14),
          0 3px 15px 5px rgba(0, 0, 0, 0.12), 0 4px 15px -3px rgba(0, 0, 0, 0.2);
      }
    }
    p {
      margin: 0;
      margin-top: 15px;
      font-size: 16px;
      font-weight: blod;
      color: ${Colors.GreenColor};
    }
  }
  .error {
    margin: 0;
    color: ${Colors.red};
  }
  .tip-btn{
    &:hover {
      background: #95bd51;
      color: #fff;
    }
  }
`;

export default class Support extends Component {
  state = {
    donationValue: 0,
    canSubmit: true,
  };

  handleButtonClick() {
    const targetElement = document.getElementById('rewards-section');

    if (targetElement) {
      const offsetTop = targetElement.offsetTop;

      window.scrollTo({
        top: offsetTop - 100,
        behavior: 'smooth',
      });
    };
  };

  render() {
    const { donationValue, canSubmit, } = this.state;
    const { projectId, projectStatus, donationsAverage, project, handleShowContactModal } = this.props;
    return (
      <>
        <SupportWrapper canSubmit={canSubmit}>
          {projectStatus === 'successful' || projectStatus === 'finished' ? (
            <div>
              {project?.is_ticketable
              ?<><p style={{marginBottom:'10px'}}>
              Der Zeitraum für den Ticketkauf ist leider abgelaufen.
              </p>
              <PrimaryButton className="tip-btn" minWidth="100%" onClick={handleShowContactModal}>
              Kontaktiere den Veranstalter
            </PrimaryButton></>
             :<p>Der Finanzierungszeitraum ist abgelaufen, du kannst dieses Projekt nicht mehr unterstützen.</p>
              }
            </div>
          ) : (
            <>
              {project?.is_ticketable ? (
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                  <PrimaryButton className="tip-btn" minWidth="100%" onClick={this.handleButtonClick}>
                    Sichere dir jetzt dein Ticket!
                  </PrimaryButton>
                  <PrimaryButton className="tip-btn" minWidth="100%" onClick={handleShowContactModal}>
                    Kontaktiere den Veranstalter
                  </PrimaryButton>
                </div>
              ) : (
                <>
                  <div style={{ width: "100%" }}>
                    <div style={{ width: "100%", marginBottom: "20px" }}>
                      <form>
                        <CurrencyInput
                          decimalSeparator=","
                          groupSeparator="."
                          placeholder={`ø${donationsAverage ? donationsAverage : 0} €`}
                          allowNegativeValue={false}
                          onChange={(e) =>
                            this.setState({
                              donationValue: Number(
                                e.target.value
                                  .replaceAll('.', '')
                                  .replaceAll(',', '.')
                              ),
                            })
                          }
                        />
                        {!canSubmit && (
                          <p className="error">Der Mindestbeitrag liegt bei 5 €</p>
                        )}
                      </form>
                    </div>
                    <Link
                      onClick={() =>
                        donationValue < 5
                          ? this.setState({ canSubmit: false })
                          : this.setState({ canSubmit: true })
                      }
                      to={
                        donationValue < 5
                          ? '#!'
                          : `/project/${projectId}/donation?donation=${donationValue}`
                      }
                    >
                      Jetzt unterstützen!
                    </Link>
                  </div>
                </>
              )}
            </>
          )}
        </SupportWrapper>
      </>
    );
  }
}
Support.propTypes = {
  projectId: PropTypes.number,
  projectStatus: PropTypes.string,
  donationsAverage: PropTypes.string,
  project: PropTypes.object.isRequired
};
