/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import PropTypes from 'prop-types';

const ShareIcon = ({ width, height }) => {
  return (
    <svg
      className="animated"
      id="freepik_stories-mobile-marketing"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 500"
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      css={css`
        max-width: ${width || '100%'};
        max-height: ${height || '100%'};
        height: ${height || '100%'};
        width: ${width || '100%'};
        display: inline-block;
      `}
    >
      <style
        dangerouslySetInnerHTML={{
          __html:
            'svg#freepik_stories-mobile-marketing:not(.animated) .animable {opacity: 0;}svg#freepik_stories-mobile-marketing.animated #freepik--background-complete--inject-3 {animation: 1s 1 forwards cubic-bezier(.36,-0.01,.5,1.38) fadeIn;animation-delay: 0s;}svg#freepik_stories-mobile-marketing.animated #freepik--Floor--inject-3 {animation: 1s 1 forwards cubic-bezier(.36,-0.01,.5,1.38) zoomIn;animation-delay: 0s;}svg#freepik_stories-mobile-marketing.animated #freepik--Icons--inject-3 {animation: 1s 1 forwards cubic-bezier(.36,-0.01,.5,1.38) zoomIn;animation-delay: 0s;}svg#freepik_stories-mobile-marketing.animated #freepik--Device--inject-3 {animation: 1s 1 forwards cubic-bezier(.36,-0.01,.5,1.38) slideRight;animation-delay: 0s;}svg#freepik_stories-mobile-marketing.animated #freepik--Megaphone--inject-3 {animation: 1s 1 forwards cubic-bezier(.36,-0.01,.5,1.38) zoomIn;animation-delay: 0s;}svg#freepik_stories-mobile-marketing.animated #freepik--Stars--inject-3 {animation: 1s 1 forwards cubic-bezier(.36,-0.01,.5,1.38) slideRight;animation-delay: 0s;}            @keyframes fadeIn {                0% {                    opacity: 0;                }                100% {                    opacity: 1;                }            }                    @keyframes zoomIn {                0% {                    opacity: 0;                    transform: scale(0.5);                }                100% {                    opacity: 1;                    transform: scale(1);                }            }                    @keyframes slideRight {                0% {                    opacity: 0;                    transform: translateX(30px);                }                100% {                    opacity: 1;                    transform: translateX(0);                }            }        ',
        }}
      />
      <g
        id="freepik--background-complete--inject-3"
        style={{ transformOrigin: '257.82279205322266px 221.27000427246094px' }}
        className="animable"
      >
        <path
          d="M136.31,126.37h0a34.26,34.26,0,0,1-34.26-34.26h0a34.26,34.26,0,0,1,34.26-34.26h0a34.26,34.26,0,0,1,34.26,34.26h0A34.26,34.26,0,0,1,136.31,126.37Z"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '136.30999755859375px 92.11000442504883px',
          }}
          id="eltmw0litpyoa"
          className="animable"
        />
        <rect
          x="120.77"
          y="84.48"
          width="7.09"
          height="22.91"
          style={{
            fill: 'rgb(255, 255, 255)',
            transformOrigin: '124.315px 95.935px',
          }}
          id="el3t973zq0k3"
          className="animable"
        />
        <path
          d="M124.28,81.48a4.23,4.23,0,1,0-4.19-4.23A4.22,4.22,0,0,0,124.28,81.48Z"
          style={{
            fill: 'rgb(255, 255, 255)',
            transformOrigin: '124.32003784179688px 77.25013303756714px',
          }}
          id="ele1w74wiokyb"
          className="animable"
        />
        <path
          d="M139.2,95.36c0-3.22,1.48-5.14,4.32-5.14,2.6,0,3.86,1.84,3.86,5.14v12h7.05V92.88c0-6.14-3.47-9.1-8.33-9.1a8,8,0,0,0-6.9,3.78V84.48h-6.81v22.9h6.81Z"
          style={{
            fill: 'rgb(255, 255, 255)',
            transformOrigin: '143.4100112915039px 95.57967185974121px',
          }}
          id="ely9nu7umkc7q"
          className="animable"
        />
        <polygon
          points="182.22 23.89 186.91 32.46 195.49 37.16 186.91 41.85 182.22 50.42 177.53 41.85 168.95 37.16 177.53 32.46 182.22 23.89"
          style={{
            fill: 'rgb(245, 245, 245)',
            transformOrigin: '182.22000122070312px 37.154998779296875px',
          }}
          id="elh9zsrj9zv8j"
          className="animable"
        />
        <polygon
          points="377.87 256.27 381.39 262.71 387.82 266.22 381.39 269.74 377.87 276.18 374.35 269.74 367.92 266.22 374.35 262.71 377.87 256.27"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '377.87001037597656px 266.22499084472656px',
          }}
          id="eluvcm8mvhgie"
          className="animable"
        />
        <polygon
          points="387.82 224.51 389.41 227.41 392.31 229 389.41 230.59 387.82 233.49 386.24 230.59 383.33 229 386.24 227.41 387.82 224.51"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '387.8199920654297px 229px',
          }}
          id="elj1dvb5j9rgr"
          className="animable"
        />
        <polygon
          points="148.71 27.11 150.3 30.02 153.2 31.6 150.3 33.19 148.71 36.09 147.13 33.19 144.22 31.6 147.13 30.02 148.71 27.11"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '148.70999908447266px 31.600000381469727px',
          }}
          id="eldhvbsvgx44d"
          className="animable"
        />
        <path
          d="M69.13,296.76a10.39,10.39,0,0,0,6.06-9.68c0-5.73-4.45-10.33-9.82-10.29s-9.68,4.73-9.63,10.46a10.34,10.34,0,0,0,6.32,9.6C47.12,299.3,48,315.56,48,315.56l35.39-.3S84,298.81,69.13,296.76Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '65.69186782836914px 296.17488861083984px',
          }}
          id="el609hjsnshnu"
          className="animable"
        />
        <path
          d="M408.64,403.94a5.51,5.51,0,0,0-10.87.32A5.51,5.51,0,0,0,386.9,404a7.43,7.43,0,0,0,1.34,5.37,21.83,21.83,0,0,0,3.88,4.1c2,1.77,3.81,3.42,5.79,5.18,2-1.78,3.63-3.45,5.59-5.23a21.59,21.59,0,0,0,3.85-4.14A7.45,7.45,0,0,0,408.64,403.94Z"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '397.77186393737793px 409.06575298309326px',
          }}
          id="elrbvaq0fgq6h"
          className="animable"
        />
        <path
          d="M431.69,193.15l3.79-.75,6-13.18-1.5-7.57s5.79-1.52,7.07,8.53,1.52,7.38,1.52,7.38,12.45-2.42,13.7-1.55c.91.64,3.7,12.94,5.16,19.62a7.39,7.39,0,0,1-3.69,8.33,5.19,5.19,0,0,1-1.31.44c-3.1.62-19.46,1.24-19.46,1.24l-6.55,1.3Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '449.6726837158203px 194.25400352478027px',
          }}
          id="elfhp21zxx27j"
          className="animable"
        />
        <polygon
          points="420.42 193.22 427.89 218.7 434.39 217.41 429.35 192 420.42 193.22"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '427.40501403808594px 205.3499984741211px',
          }}
          id="els17k8xfc6b"
          className="animable"
        />
      </g>
      <g
        id="freepik--Floor--inject-3"
        style={{ transformOrigin: '248.98500061035156px 469.32000732421875px' }}
        className="animable"
      >
        <path
          d="M470.47,469.32c0,.15-99.17.26-221.47.26s-221.5-.11-221.5-.26,99.15-.26,221.5-.26S470.47,469.18,470.47,469.32Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '248.98500061035156px 469.32000732421875px',
          }}
          id="el2d7bhz94ph5"
          className="animable"
        />
      </g>
      <g
        id="freepik--Icons--inject-3"
        style={{ transformOrigin: '260.1416778564453px 257.29126739501953px' }}
        className="animable"
      >
        <path
          d="M393.43,183.15h0a40.64,40.64,0,0,1-54.31-18.77h0a40.64,40.64,0,0,1,18.77-54.31h0a40.63,40.63,0,0,1,54.31,18.77h0A40.63,40.63,0,0,1,393.43,183.15Z"
          style={{
            fill: 'rgb(149, 189, 81)',
            transformOrigin: '375.6616554260254px 146.60829162597656px',
          }}
          id="el2dhtof5kjwb"
          className="animable"
        />
        <path
          d="M365.62,155.69a1,1,0,0,0,.95-1.23c-.1-.49-.37-1.73-.47-2.24-.14-.71-.31-.9-1-1.22a8,8,0,0,1-4-4.09c-3.06-6.3-1.09-14.23,6.47-17.9,6.69-3.26,12.36-1,15,4.5,3.49,7.19,3.26,14.8-1.46,17.1a4.29,4.29,0,0,1-6.27-2.89c-.78-3.52-1-7.63-2.1-9.91-1-2-2.92-3.21-5.18-2.11-2.67,1.3-3.47,5.1-1.67,8.79a10.68,10.68,0,0,0,2.71,3.56s2.9,12.88,3.4,15.14c1,4.49,4.22,9,4.53,9.51a.38.38,0,0,0,.67-.15c.09-.44,1.36-5.9.53-10.34-.23-1.26-1.58-7.71-1.58-7.71,1.67,1.23,4.91,1.46,7.6.15,8-3.9,9.9-13.85,5.14-23.63-3.59-7.39-13.21-11.23-22.72-6.6-11.85,5.76-13.69,17.15-10.25,24.23C358,152.94,361.45,156,365.62,155.69Z"
          style={{
            fill: 'rgb(255, 255, 255)',
            transformOrigin: '372.9212017059326px 147.62652206420898px',
          }}
          id="el9xnedq3kt99"
          className="animable"
        />
        <path
          d="M434,297.76h0a28,28,0,0,1-16.17-36.18h0A28,28,0,0,1,454,245.4h0a28,28,0,0,1,16.17,36.18h0A28,28,0,0,1,434,297.76Z"
          style={{
            fill: 'rgb(149, 189, 81)',
            transformOrigin: '444px 271.5800151824951px',
          }}
          id="eloacgl9xtumf"
          className="animable"
        />
        <path
          d="M458.64,276.34a15.09,15.09,0,0,0-28.07-11c-.09.2-.17.41-.25.61a14.82,14.82,0,0,0-.74,8l-5.41,6.53,8.76.5a15.09,15.09,0,0,0,25.58-4.26A3.62,3.62,0,0,0,458.64,276.34Zm-18.72,6.73a12.6,12.6,0,0,1-5.77-4.44l-5.11-.29,3.15-3.8a12.47,12.47,0,0,1,.37-7.76c.15-.38.32-.76.5-1.12a12.69,12.69,0,0,1,23.52,9.24c-.09.32-.19.63-.31.94A12.65,12.65,0,0,1,439.92,283.07Z"
          style={{
            fill: 'rgb(255, 255, 255)',
            fillRule: 'evenodd',
            transformOrigin: '441.8082637786865px 271.3577251434326px',
          }}
          id="elzsgcg2ok9p"
          className="animable"
        />
        <path
          d="M449.8,276.6c-.29-.3-1.66-1.78-1.94-2s-.48-.38-.84-.12-1.33.77-1.61.92-.5.1-.78-.2a10.09,10.09,0,0,1-2.13-2.77,11,11,0,0,1-1-3.1c-.06-.42.18-.54.42-.64s.5-.27.75-.4l.19-.12a3.53,3.53,0,0,0,.37-.33.65.65,0,0,0,.21-.61c0-.2-.07-2.16-.1-2.95s-.36-.79-.56-.87-.42-.2-.65-.28a1.37,1.37,0,0,0-1.09.07,4.16,4.16,0,0,0-2.3,2.41,4.66,4.66,0,0,0-.27,1.23,8.26,8.26,0,0,0,.33,2.85c.09.29.94,4.71,3.92,7.43s3.17,2.26,3.83,2.44a3.67,3.67,0,0,0,3-.74,3,3,0,0,0,.83-1.55C450.27,277.08,450.08,276.9,449.8,276.6Z"
          style={{
            fill: 'rgb(255, 255, 255)',
            fillRule: 'evenodd',
            transformOrigin: '444.41776943206787px 271.3384475708008px',
          }}
          id="elzpizxwjifeq"
          className="animable"
        />
        <path
          d="M69.63,168.24h0a21.37,21.37,0,0,1-21.37-21.37h0A21.37,21.37,0,0,1,69.63,125.5h0A21.37,21.37,0,0,1,91,146.87h0A21.37,21.37,0,0,1,69.63,168.24Z"
          style={{
            fill: 'rgb(149, 189, 81)',
            transformOrigin: '69.62999725341797px 146.87000274658203px',
          }}
          id="el0hjuvt8kpn7b"
          className="animable"
        />
        <path
          d="M58.25,154a13.3,13.3,0,0,0,20.44-11.8A9.58,9.58,0,0,0,81,139.81a9.65,9.65,0,0,1-2.69.74A4.7,4.7,0,0,0,80.39,138a9.14,9.14,0,0,1-3,1.13,4.68,4.68,0,0,0-8,4.27,13.29,13.29,0,0,1-9.63-4.89,4.69,4.69,0,0,0,1.45,6.24,4.6,4.6,0,0,1-2.12-.58,4.68,4.68,0,0,0,3.75,4.64,4.67,4.67,0,0,1-2.11.08,4.68,4.68,0,0,0,4.36,3.24A9.39,9.39,0,0,1,58.25,154Z"
          style={{
            fill: 'rgb(255, 255, 255)',
            transformOrigin: '69.62499618530273px 146.84745121002197px',
          }}
          id="elqyaqgq2hqrr"
          className="animable"
        />
        <path
          d="M108,407.61h0A36.8,36.8,0,0,1,63.7,380.34h0A36.8,36.8,0,0,1,91,336h0a36.8,36.8,0,0,1,44.33,27.27h0A36.8,36.8,0,0,1,108,407.61Z"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '99.51419830322266px 371.8016166687012px',
          }}
          id="el3authz5vza8"
          className="animable"
        />
        <path
          d="M95.67,355.72c5.24-1.25,5.87-1.38,8-1.78a11.18,11.18,0,0,1,3.8-.19,6.29,6.29,0,0,1,2.61.93,6.43,6.43,0,0,1,2,1.91,11.22,11.22,0,0,1,1.54,3.48c.59,2.05.76,2.67,2,7.91s1.37,5.87,1.77,8a10.92,10.92,0,0,1,.19,3.8,6,6,0,0,1-.93,2.61,6.29,6.29,0,0,1-1.9,2,11.31,11.31,0,0,1-3.49,1.54c-2,.59-2.66.76-7.9,2s-5.87,1.38-8,1.78a11,11,0,0,1-3.81.19,6.2,6.2,0,0,1-2.61-.93A6.08,6.08,0,0,1,87,387a11.06,11.06,0,0,1-1.55-3.48c-.58-2.05-.75-2.66-2-7.9s-1.38-5.87-1.78-8a11.52,11.52,0,0,1-.19-3.81,6.64,6.64,0,0,1,2.84-4.61,11.17,11.17,0,0,1,3.48-1.55c2-.58,2.67-.75,7.91-2m-.84-3.54c-5.33,1.27-6,1.45-8.07,2.05a14.62,14.62,0,0,0-4.55,2,10.36,10.36,0,0,0-4.37,7.1,14.85,14.85,0,0,0,.22,5c.41,2.11.54,2.79,1.81,8.12s1.45,6,2,8.06a14.51,14.51,0,0,0,2,4.55A9.91,9.91,0,0,0,87.06,392a9.8,9.8,0,0,0,4,1.43,14.79,14.79,0,0,0,5-.22c2.11-.4,2.79-.54,8.12-1.81s6-1.45,8.06-2a15,15,0,0,0,4.55-2,9.91,9.91,0,0,0,2.94-3.09,10.07,10.07,0,0,0,1.44-4,14.84,14.84,0,0,0-.23-5c-.4-2.12-.54-2.79-1.81-8.12s-1.45-6-2-8.07a15.1,15.1,0,0,0-2-4.55,9.88,9.88,0,0,0-3.09-2.93,9.73,9.73,0,0,0-4-1.44,14.79,14.79,0,0,0-5,.22c-2.12.41-2.79.54-8.12,1.81"
          style={{
            fill: 'rgb(255, 255, 255)',
            transformOrigin: '99.52323532104492px 371.8150215148926px',
          }}
          id="elr95vq91074"
          className="animable"
        />
        <path
          d="M97.1,361.73a10.36,10.36,0,1,0,12.48,7.68,10.37,10.37,0,0,0-12.48-7.68m4,16.63a6.73,6.73,0,1,1,5-8.11,6.73,6.73,0,0,1-5,8.11"
          style={{
            fill: 'rgb(255, 255, 255)',
            transformOrigin: '99.50132465362549px 371.8089323043823px',
          }}
          id="el7q5gwsrbpmn"
          className="animable"
        />
        <path
          d="M109.84,358.28a2.42,2.42,0,1,1-2.92-1.8,2.41,2.41,0,0,1,2.92,1.8"
          style={{
            fill: 'rgb(255, 255, 255)',
            transformOrigin: '107.48409938812256px 358.83255672454834px',
          }}
          id="el4b1eoa4df1h"
          className="animable"
        />
        <path
          d="M386.81,375.22h0a17.16,17.16,0,0,1-17.17-17.16h0a17.16,17.16,0,0,1,17.17-17.16h0A17.16,17.16,0,0,1,404,358.06h0A17.16,17.16,0,0,1,386.81,375.22Z"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '386.8199920654297px 358.0599937438965px',
          }}
          id="elh2scavnwejs"
          className="animable"
        />
        <path
          d="M384.19,368.43h4.18V358h2.91l.32-3.5h-3.23v-2c0-.82.17-1.15,1-1.15h2.27v-3.64h-2.9c-3.11,0-4.51,1.37-4.51,4v2.8H382V358h2.17Z"
          style={{
            fill: 'rgb(255, 255, 255)',
            transformOrigin: '386.8199920654297px 358.0699920654297px',
          }}
          id="elbr2k32mye8"
          className="animable"
        />
      </g>
      <g
        id="freepik--Device--inject-3"
        style={{ transformOrigin: '259.3800277709961px 267.1850051879883px' }}
        className="animable"
      >
        <path
          d="M328.27,470.05,190.38,470a30,30,0,0,1-30-30l.06-345.68a30,30,0,0,1,30.06-30l137.88.08a30,30,0,0,1,30,30L358.32,440A30,30,0,0,1,328.27,470.05Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '259.3800277709961px 267.1850051879883px',
          }}
          id="el3gbkf7co9h2"
          className="animable"
        />
        <path
          d="M328.23,76.52l-26.74,0A6.65,6.65,0,0,0,295,83.28v4.85a6.63,6.63,0,0,1-6.49,6.78l-52.11,0a6.64,6.64,0,0,1-6.49-6.79V83.24a6.66,6.66,0,0,0-6.49-6.79H214l-23.39,0a21,21,0,0,0-21.05,21l-.07,337.41a21,21,0,0,0,21,21l137.62.08a21,21,0,0,0,21-21l.07-337.42A21,21,0,0,0,328.23,76.52Z"
          style={{
            fill: 'rgb(255, 255, 255)',
            transformOrigin: '259.3349914550781px 266.1949462890625px',
          }}
          id="elw3z4komwx1"
          className="animable"
        />
        <path
          d="M330.24,103.93c0,.09-3.48.19-7.76.22s-7.77,0-7.77-.09,3.47-.2,7.76-.23S330.24,103.84,330.24,103.93Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '322.47499084472656px 103.99070315063px',
          }}
          id="elzzf4mf9mmv"
          className="animable"
        />
        <path
          d="M330.27,107.9c0,.09-3.47.19-7.76.23s-7.77,0-7.77-.1,3.48-.19,7.77-.23S330.27,107.81,330.27,107.9Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '322.50498962402344px 107.96569998562336px',
          }}
          id="elub6v8fm9rc"
          className="animable"
        />
        <path
          d="M330.31,111.87c0,.09-3.48.2-7.77.23s-7.76,0-7.77-.1,3.48-.19,7.77-.23S330.31,111.78,330.31,111.87Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '322.53998851776123px 111.93358750641346px',
          }}
          id="eljpl6ri6iiq"
          className="animable"
        />
        <path
          d="M184,115.75l.41,0,1.13,0,4.18-.1-.12.11c0-.92,0-1.93-.05-3,0-.56,0-1.09,0-1.74a1.61,1.61,0,0,1,1.4-1.29c.68,0,1.27,0,2,0a1.63,1.63,0,0,1,1.37,1.61c0,1.42,0,2.89.05,4.42l-.19-.19,5.27,0-.2.2c0-2.12,0-4.32-.06-6.57,0-.56,0-1.11,0-1.67a1.67,1.67,0,0,0-.74-1.24l-2.3-2.28-2.23-2.19-1.07-1.06c-.37-.36-.66-.73-1.09-.82a1.43,1.43,0,0,0-1.21.23c-.32.27-.66.65-1,1l-1.9,1.91c-1.19,1.23-2.43,2.36-3.33,3.43a3.76,3.76,0,0,0-.26,2c0,.65,0,1.27,0,1.85,0,1.14,0,2.14,0,3s0,1.39,0,1.91a5.54,5.54,0,0,1,0,.69,5.69,5.69,0,0,1,0-.63c0-.51,0-1.14,0-1.89s0-1.87-.07-3c0-.6,0-1.23,0-1.9,0-.34,0-.68,0-1a2.18,2.18,0,0,1,.29-1.1c1-1.19,2.14-2.25,3.34-3.52l1.88-1.93c.34-.33.61-.66,1-1a1.79,1.79,0,0,1,1.53-.31,2.93,2.93,0,0,1,1.27.91l1.08,1.06,2.23,2.19,2.31,2.26a4.61,4.61,0,0,1,.59.64,1.73,1.73,0,0,1,.28.89c0,.56,0,1.12,0,1.68,0,2.25,0,4.45.05,6.57v.2h-.2l-5.27,0H194v-.19c0-1.53,0-3,0-4.42a1.27,1.27,0,0,0-1.06-1.28c-.61,0-1.33,0-1.92,0a1.29,1.29,0,0,0-1.13,1c0,.53,0,1.14,0,1.68,0,1.09,0,2.11,0,3v.12h-.12l-4.25,0-1.11,0A2.29,2.29,0,0,1,184,115.75Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '191.91496086120605px 107.85190105438232px',
          }}
          id="elsa3ev2an4cr"
          className="animable"
        />
        <path
          d="M275,379.41a8.36,8.36,0,0,1-1.45.1l-3.94.08c-3.32.05-7.92.08-13,.08s-9.68,0-13-.08l-3.94-.08a8.5,8.5,0,0,1-1.45-.1,7.36,7.36,0,0,1,1.45-.1l3.94-.09c3.32,0,7.92-.07,13-.07s9.67,0,13,.07l3.94.09A7.25,7.25,0,0,1,275,379.41Z"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '256.60999298095703px 379.4099884033203px',
          }}
          id="elxjhxsp1qpac"
          className="animable"
        />
        <path
          d="M332.77,388.45c0,.15-21.17.26-47.28.26s-47.28-.11-47.28-.26,21.17-.26,47.28-.26S332.77,388.31,332.77,388.45Z"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '285.489990234375px 388.45001220703125px',
          }}
          id="elpx586n5t8rk"
          className="animable"
        />
        <path
          d="M303.22,397c0,.15-14.55.26-32.5.26s-32.51-.11-32.51-.26,14.55-.26,32.51-.26S303.22,396.82,303.22,397Z"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '270.71500396728516px 397px',
          }}
          id="elx58fk6i179k"
          className="animable"
        />
        <path
          d="M334.64,413.53l-2.94-2.95-2.8,2.79,0-8.44h5.73Zm-2.94-3.32.09.09,2.59,2.6v-7.7h-5.21v7.55Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '331.77001953125px 409.22999572753906px',
          }}
          id="elhbmmbdsietk"
          className="animable"
        />
        <path
          d="M256.41,412.43l-1-.46a2.65,2.65,0,1,1,.8-.58ZM254,406.81a2.69,2.69,0,0,0,.05,5.37,2.86,2.86,0,0,0,1.29-.41l0,0,.77.34-.18-.75,0,0a2.68,2.68,0,0,0-2-4.49Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '254.2142734527588px 409.6199951171875px',
          }}
          id="elpay3vavgk2s"
          className="animable"
        />
        <path
          d="M263.07,412l-1.18-2.9-2.23-2,5.4,0Zm-2.91-4.73,1.9,1.74,1,2.47,1.7-4.25Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '262.36000061035156px 409.5500030517578px',
          }}
          id="el7ingetwc7r6"
          className="animable"
        />
        <g id="el3eo413weajs">
          <rect
            x="261.78"
            y="407.95"
            width="3.52"
            height="0.2"
            style={{
              fill: 'rgb(38, 50, 56)',
              transformOrigin: '263.53999999999996px 408.05px',
              transform: 'rotate(-33.05deg)',
            }}
            className="animable"
          />
        </g>
        <path
          d="M247.9,407.66a1.67,1.67,0,0,0-3.29.11,1.67,1.67,0,0,0-3.3-.06,2.27,2.27,0,0,0,.41,1.63,6.6,6.6,0,0,0,1.18,1.24c.61.53,1.16,1,1.76,1.56.6-.54,1.1-1.05,1.7-1.59a6.6,6.6,0,0,0,1.16-1.26A2.22,2.22,0,0,0,247.9,407.66Z"
          style={{
            fill: 'rgb(149, 189, 81)',
            transformOrigin: '244.60873818397522px 409.2329435348511px',
          }}
          id="el6bz0kdn82o"
          className="animable"
        />
        <path
          d="M340.66,421s0-.33,0-.94,0-1.54,0-2.74c0-2.41,0-6-.05-10.56,0-9.19-.05-22.56-.09-39.33l.17.17-159.91.09h0l.26-.26c0,18.62,0,36.67,0,53.57l-.25-.25,114.43.11,33.4.07,9,0h2.32l.8,0h-.77l-2.29,0-8.91,0-33.35.07-114.6.12h-.26V421c0-16.9,0-34.95,0-53.57v-.26h.28l159.91.09h.17v.17c0,16.81-.07,30.22-.09,39.44,0,4.58,0,8.11,0,10.52,0,1.18,0,2.08,0,2.7S340.66,421,340.66,421Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '260.7400131225586px 394.1449890136719px',
          }}
          id="el7lfs8daehiq"
          className="animable"
        />
        <g id="el4rkkrqqcsir">
          <rect
            x="191.73"
            y="372.05"
            width="35.42"
            height="44.3"
            style={{
              fill: 'rgb(235, 235, 235)',
              transformOrigin: '209.44px 394.2px',
              transform: 'rotate(-89.67deg)',
            }}
            className="animable"
          />
        </g>
        <path
          d="M231.69,376.62h-.85l-2.42,0h-9.09l-32-.07.24-.23c-.05,10.55-.11,22.63-.17,35.41.05.07-.49-.49-.26-.26h3.05l3,0,6,0,11.5.07,20.74.15-.21.22c.1-10.64.19-19.46.26-25.66,0-3.08.07-5.5.09-7.19,0-.81,0-1.44,0-1.9s0-.67,0-.67,0,.2,0,.62,0,1.07,0,1.87c0,1.67,0,4.08,0,7.14,0,6.23,0,15.1,0,25.79v.21h-.22l-20.74-.08-11.5-.06-6,0-3,0h-3.05l-.27-.27c.09-12.79.16-24.87.23-35.41v-.24h.24l32.1.29,9,.12,2.37,0,.6,0Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '209.23999786376953px 394.08998107910156px',
          }}
          id="el274iribvhdo"
          className="animable"
        />
        <g id="el1ib8whdn6jr">
          <rect
            x="193.93"
            y="373.61"
            width="30.96"
            height="41.19"
            style={{
              fill: 'rgb(224, 224, 224)',
              transformOrigin: '209.41px 394.20500000000004px',
              transform: 'rotate(-89.67deg)',
            }}
            className="animable"
          />
        </g>
        <polygon
          points="188.72 409.56 203.22 390.4 212.06 402.49 218.14 396.09 229.91 409.8 188.72 409.56"
          style={{
            fill: 'rgb(245, 245, 245)',
            transformOrigin: '209.31500244140625px 400.09999084472656px',
          }}
          id="elh0naqf3139h"
          className="animable"
        />
        <path
          d="M215.93,388.12a3.25,3.25,0,1,1-3.23-3.26A3.24,3.24,0,0,1,215.93,388.12Z"
          style={{
            fill: 'rgb(245, 245, 245)',
            transformOrigin: '212.68000483512878px 388.1099760532379px',
          }}
          id="elv341q8eeio"
          className="animable"
        />
        <path
          d="M262.09,146.87a1.44,1.44,0,0,1-.48,0l-1.35.05-5,.09c-4.21,0-10,.07-16.46.07s-12.25,0-16.46-.07l-5-.09-1.36-.05a1.34,1.34,0,0,1-.47,0,1.74,1.74,0,0,1,.47-.05l1.36,0,5-.08c4.21-.05,10-.08,16.46-.08s12.25,0,16.46.08l5,.08,1.35,0A1.87,1.87,0,0,1,262.09,146.87Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '238.79999542236328px 146.8699951171875px',
          }}
          id="elcaliz6532"
          className="animable"
        />
        <path
          d="M327.53,342.65l-4.36-4.38L319,342.42l0-12.54,8.52,0Zm-4.36-4.92.13.13,3.85,3.86,0-11.44h-7.76v11.22Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '323.26499938964844px 336.26499938964844px',
          }}
          id="els5myh02wtqg"
          className="animable"
        />
        <path
          d="M216.67,341.33l-2-.91a5.16,5.16,0,1,1,1.57-1.13Zm-4.77-11.05a5.28,5.28,0,0,0,.09,10.56,5.49,5.49,0,0,0,2.54-.8l.09-.05,1.5.67-.34-1.48.07-.07a5.27,5.27,0,0,0-3.95-8.83Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '212.15948486328125px 335.80481004714966px',
          }}
          id="el97si7x7j805"
          className="animable"
        />
        <path
          d="M229.79,340.46l-2.34-5.71-4.38-4,10.64-.08Zm-5.73-9.33,3.74,3.43,2,4.86,3.34-8.36Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '228.3899917602539px 335.56500244140625px',
          }}
          id="elhs03k54xj6"
          className="animable"
        />
        <g id="eleav4kx3l8hq">
          <rect
            x="227.24"
            y="332.52"
            width="6.92"
            height="0.39"
            style={{
              fill: 'rgb(38, 50, 56)',
              transformOrigin: '230.70000000000002px 332.715px',
              transform: 'rotate(-33.05deg)',
            }}
            className="animable"
          />
        </g>
        <path
          d="M199.93,332a3.29,3.29,0,0,0-6.49.21,3.29,3.29,0,0,0-6.49-.11,4.45,4.45,0,0,0,.81,3.2,13.51,13.51,0,0,0,2.33,2.45c1.19,1,2.28,2,3.47,3.07,1.16-1.06,2.16-2.06,3.33-3.13a13.47,13.47,0,0,0,2.29-2.48A4.46,4.46,0,0,0,199.93,332Z"
          style={{
            fill: 'rgb(149, 189, 81)',
            transformOrigin: '193.4453248977661px 335.08339071273804px',
          }}
          id="el5d0fcygtpr7"
          className="animable"
        />
        <circle
          cx="198.96"
          cy="146.87"
          r="7.22"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '198.96px 146.87px',
          }}
          id="elpcard61afbs"
          className="animable"
        />
        <path
          d="M324.81,301.25c0,.15-30.26.27-67.58.27s-67.59-.12-67.59-.27,30.26-.26,67.59-.26S324.81,301.11,324.81,301.25Z"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '257.22499084472656px 301.25498962402344px',
          }}
          id="elb06raxny51f"
          className="animable"
        />
        <path
          d="M324.81,290c0,.14-30.26.26-67.58.26s-67.59-.12-67.59-.26,30.26-.26,67.59-.26S324.81,289.86,324.81,290Z"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '257.22499084472656px 290px',
          }}
          id="elmys1z7qzrfq"
          className="animable"
        />
        <path
          d="M263.21,312.66c0,.14-16.47.26-36.78.26s-36.79-.12-36.79-.26,16.47-.26,36.79-.26S263.21,312.52,263.21,312.66Z"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '226.42498779296875px 312.6600036621094px',
          }}
          id="elyzxn6qx7p1k"
          className="animable"
        />
        <path
          d="M254.14,435.29a2.88,2.88,0,1,1-2.86-2.93A2.89,2.89,0,0,1,254.14,435.29Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '251.26034951210022px 435.2399523258209px',
          }}
          id="el1xfmqmdoau2"
          className="animable"
        />
        <path
          d="M263,435.33a2.87,2.87,0,1,1-2.86-2.93A2.88,2.88,0,0,1,263,435.33Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '260.130512714386px 435.2699637413025px',
          }}
          id="elwwbgb09x2mm"
          className="animable"
        />
        <path
          d="M271.93,435.37a2.87,2.87,0,1,1-2.86-2.93A2.89,2.89,0,0,1,271.93,435.37Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '269.0604121685028px 435.3099880218506px',
          }}
          id="elrf4yrl3iyr"
          className="animable"
        />
        <rect
          x="181.69"
          y="161.15"
          width="159.93"
          height="97.8"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '261.655px 210.05px',
          }}
          id="el2xdxujogp9b"
          className="animable"
        />
        <polygon
          points="196.46 258.98 242.28 197.64 270.7 236 289.95 215.48 327.72 258.94 196.46 258.98"
          style={{
            fill: 'rgb(245, 245, 245)',
            transformOrigin: '262.09000396728516px 228.31000518798828px',
          }}
          id="elyx7mcz83dn7"
          className="animable"
        />
        <path
          d="M282.74,190.11a10.34,10.34,0,1,1-10.34-10.33A10.34,10.34,0,0,1,282.74,190.11Z"
          style={{
            fill: 'rgb(245, 245, 245)',
            transformOrigin: '272.3999967575073px 190.1200189590454px',
          }}
          id="el1i717a6cc6a"
          className="animable"
        />
        <path
          d="M341.19,349.85c0-2.09-.1-90.44-.24-221.24l.24.24-159.91,0h0l.26-.26c0,82.74,0,159.31,0,221.24l-.22-.21,159.93.21-159.93.22h-.21v-.22c0-61.93,0-138.5-.05-221.24v-.26h.28l159.91,0h.24v.23c-.05,65.47-.09,120.8-.12,159.78,0,19.46-.05,34.83-.06,45.35,0,5.24,0,9.27,0,12,0,1.35,0,2.38,0,3.08S341.19,349.85,341.19,349.85Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '261.2749938964844px 239.1900177001953px',
          }}
          id="elxwa8gxxxxl"
          className="animable"
        />
      </g>
      <g
        id="freepik--Megaphone--inject-3"
        style={{ transformOrigin: '220.55912017822266px 289.4814682006836px' }}
        className="animable"
      >
        <path
          d="M266,377.3l3.54-11.89c.62-2.58,1.44-5.62,2.54-9.09,1-3.12,3.54-6.4,5.86-8.76,3.61-3.67,5.33-3.69,7.75-5.64.22-.14.47-.35.68-.46a3.81,3.81,0,0,0,1.18-1c2.09-2.26.08-5.77-4.17-5.43-3.19.25-8.41,1.16-16.89,9.91-.11.14-.22.25-.32.38-4.45,5.79-8.63-10.73-9.39-16.88a6.43,6.43,0,0,0-8.71-5l-20.66,9.66a9.67,9.67,0,0,0-6,.2,6.16,6.16,0,0,0-3.94,7.29L225.86,374l-24.61,81.9H254.3Z"
          style={{
            fill: 'rgb(255, 190, 157)',
            transformOrigin: '244.81642532348633px 389.44647216796875px',
          }}
          id="elcd2in3ytc8"
          className="animable"
        />
        <path
          d="M306.8,302.08l-16.4,32.14-8.2-2.28L241,365.47a8.34,8.34,0,0,1-11.59-1l-15.7-18.23s31.65-13.63,37.5-24.91-.91-13.85-.91-13.85l14.89-16.92Z"
          style={{
            fill: 'rgb(149, 189, 81)',
            transformOrigin: '260.25499725341797px 328.9576759338379px',
          }}
          id="el3l74wj7iu9k"
          className="animable"
        />
        <path
          d="M285.53,236.44s-24-5.31-57.79-37.79S178.09,123,174,123.24s-22.19-8.17-56.53,66.52-20.81,92-14,91.9,53.22-13.07,82.2-9.78,66.23,18.4,66.23,18.4Z"
          style={{
            fill: 'rgb(149, 189, 81)',
            transformOrigin: '190.28411865234375px 206.67147064208984px',
          }}
          id="el6fms6pmfmtv"
          className="animable"
        />
        <path
          d="M336.52,277.15l-8.19,15.57-3.67,7a20.32,20.32,0,0,1-26.49,9l-51.45-17.93,30-68.4,51.4,27.39A20.32,20.32,0,0,1,336.52,277.15Z"
          style={{
            fill: 'rgb(149, 189, 81)',
            transformOrigin: '292.7884826660156px 266.48940658569336px',
          }}
          id="elb0hcxb2yga"
          className="animable"
        />
        <g id="eleoby0fd2ebc">
          <g
            style={{
              opacity: '0.4',
              transformOrigin: '292.7884826660156px 266.48940658569336px',
            }}
            className="animable"
          >
            <path
              d="M336.52,277.15l-8.19,15.57-3.67,7a20.32,20.32,0,0,1-26.49,9l-51.45-17.93,30-68.4,51.4,27.39A20.32,20.32,0,0,1,336.52,277.15Z"
              id="elbw5mwa5fy5w"
              style={{
                transformOrigin: '292.7884826660156px 266.48940658569336px',
              }}
              className="animable"
            />
          </g>
        </g>
        <path
          d="M120.21,191.12c-18.27,40.46-25.88,76.52-17,80.53s30.91-25.53,49.18-66,25.88-76.51,17-80.53S138.48,150.66,120.21,191.12Z"
          style={{
            fill: 'rgb(149, 189, 81)',
            transformOrigin: '136.30000686645508px 198.38424682617188px',
          }}
          id="elrfd03e6c8cb"
          className="animable"
        />
        <g id="el6scrbtllxva">
          <g
            style={{
              opacity: '0.5',
              transformOrigin: '136.30000686645508px 198.38424682617188px',
            }}
            className="animable"
          >
            <path
              d="M120.21,191.12c-18.27,40.46-25.88,76.52-17,80.53s30.91-25.53,49.18-66,25.88-76.51,17-80.53S138.48,150.66,120.21,191.12Z"
              id="ely5guwxay5wn"
              style={{
                transformOrigin: '136.30000686645508px 198.38424682617188px',
              }}
              className="animable"
            />
          </g>
        </g>
        <polygon
          points="340.94 299.65 326.99 295.27 335.62 278.87 346.08 285.37 340.94 299.65"
          style={{
            fill: 'rgb(149, 189, 81)',
            transformOrigin: '336.5349884033203px 289.25999450683594px',
          }}
          id="elzovbpgxjqmf"
          className="animable"
        />
        <g id="el7l87epffbkk">
          <g
            style={{
              opacity: '0.6000000000000001',
              transformOrigin: '331.92999267578125px 287.52000427246094px',
            }}
            className="animable"
          >
            <polygon
              points="335.62 278.87 326.99 295.27 329.86 296.17 336.87 279.64 335.62 278.87"
              id="elti8nc8rgpu"
              style={{
                transformOrigin: '331.92999267578125px 287.52000427246094px',
              }}
              className="animable"
            />
          </g>
        </g>
        <path
          d="M326,282.65c-.2.45-7.16-2.25-15.54-6s-15-7.23-14.81-7.68,7.16,2.26,15.55,6.05S326.21,282.2,326,282.65Z"
          style={{
            fill: 'rgb(149, 189, 81)',
            transformOrigin: '310.82542419433594px 275.8100929260254px',
          }}
          id="elq59znwy8vq"
          className="animable"
        />
        <path
          d="M330.3,272.88c-.22.44-6.52-2.21-14.07-5.93s-13.49-7.09-13.27-7.54,6.51,2.21,14.06,5.93S330.52,272.43,330.3,272.88Z"
          style={{
            fill: 'rgb(149, 189, 81)',
            transformOrigin: '316.62998390197754px 266.14404678344727px',
          }}
          id="el7bkrc8z66ri"
          className="animable"
        />
        <path
          d="M320.6,292.9c-.07.24-1.73-.14-4.37-1-1.32-.41-2.89-.93-4.62-1.52s-3.5-1.53-5.41-2.4c-7.63-3.54-13.46-7-13.27-7.42s6.46,2.29,14,5.8c1.9.86,3.66,1.8,5.32,2.49s3.15,1.3,4.4,1.86C319.15,291.86,320.67,292.66,320.6,292.9Z"
          style={{
            fill: 'rgb(149, 189, 81)',
            transformOrigin: '306.76391410827637px 286.7438588142395px',
          }}
          id="el4vzi69ihymx"
          className="animable"
        />
        <path
          d="M157.46,182.13s-20.86-12.94-32.27,15.25,10.53,32.82,10.53,32.82A187.63,187.63,0,0,0,157.46,182.13Z"
          style={{
            fill: 'rgb(149, 189, 81)',
            transformOrigin: '139.68050575256348px 204.6827621459961px',
          }}
          id="elk93kn677k4"
          className="animable"
        />
        <g id="el5mn8wfvoi0a">
          <g
            style={{
              opacity: '0.5',
              transformOrigin: '149.75486946105957px 210.06500244140625px',
            }}
            className="animable"
          >
            <path
              d="M179.65,148.53c.13,0-.17,1.94-.81,5.39s-1.6,8.44-2.94,14.58L173,178.37q-.79,2.65-1.63,5.49l-.85,2.87c-.31,1-.72,1.91-1.08,2.88l-4.71,12.16c-.41,1.06-.83,2.12-1.24,3.19s-1,2.09-1.47,3.15l-3.1,6.4-3.11,6.39c-.53,1-1,2.12-1.57,3.1s-1.17,2-1.74,2.95l-6.65,11.23c-.54.88-1,1.79-1.6,2.63l-1.73,2.45-3.32,4.68-6,8.37c-4,4.83-7.34,8.64-9.64,11.29a32.77,32.77,0,0,1-3.73,4,32.31,32.31,0,0,1,3.2-4.42c2.22-2.83,5.35-6.79,9.19-11.66l5.75-8.43,3.22-4.69c.56-.8,1.12-1.62,1.69-2.45s1-1.74,1.55-2.63L151,226.1l1.71-2.94c.59-1,1-2,1.55-3.08l3.08-6.35c1-2.15,2.07-4.26,3.09-6.35.48-1,1.06-2.06,1.46-3.12l1.25-3.16,4.78-12c.37-1,.79-1.89,1.11-2.85s.59-1.9.88-2.84l1.7-5.43,3.07-9.75,3.47-14.4A32.28,32.28,0,0,1,179.65,148.53Z"
              id="el3o9k5qraf9b"
              style={{
                transformOrigin: '149.75486946105957px 210.06500244140625px',
              }}
              className="animable"
            />
          </g>
        </g>
        <g id="eldosnmdiw496">
          <g
            style={{
              opacity: '0.4',
              transformOrigin: '241.91383171081543px 336.7127323150635px',
            }}
            className="animable"
          >
            <path
              d="M218.69,344c1.91,3.46,3.85,7,6.66,9.73S232,358.4,236,358s7.65-3.66,7.69-7.6c1.88,1.41,4.77.65,6.33-1.12s2.07-4.22,2.25-6.57c2.56,1.51,6.12.32,7.83-2.11a10.14,10.14,0,0,0,1-8.56,30.17,30.17,0,0,0-4.11-7.87c2,.16,4.06.3,5.82-.59s3-3.23,1.93-4.91a4.83,4.83,0,0,0-2.87-1.83c-3.09-1-5.25-1.74-8.47-1.37-.74,0,1.29,8.48-18.88,20.06C222.55,342.38,218.69,344,218.69,344Z"
              id="el4cy4aa7giz"
              style={{
                transformOrigin: '241.91383171081543px 336.7127323150635px',
              }}
              className="animable"
            />
          </g>
        </g>
        <path
          d="M264.11,356.2c0,.09-1.35-.17-3.53-.11a20,20,0,0,0-14.82,7.11c-1.41,1.66-2,2.87-2.13,2.82a3.08,3.08,0,0,1,.39-.88,17.51,17.51,0,0,1,1.45-2.18,19,19,0,0,1,15.11-7.24,15.23,15.23,0,0,1,2.6.24A2.73,2.73,0,0,1,264.11,356.2Z"
          style={{
            fill: 'rgb(235, 153, 110)',
            transformOrigin: '253.86997985839844px 360.8702983856201px',
          }}
          id="elfgah4yro65o"
          className="animable"
        />
        <path
          d="M220,338.59c3.25,3.12,5.47,6.57,8.88,9.71,1.55,1.43,4,4.13,6,4.49s3.4.09,4.22-1.85c.67-1.57-.53-3.72-1.35-5-2.17-3.41-4.25-6.07-5.87-8.72-.77-1.24.22-1.5.58-.92,1.56,1.62,3.9,3,5.3,4.79,1.92,2.41,2.26,5.62,7.28,4.6a4.38,4.38,0,0,0,1.86-.77c1.89-1.45,2-4,1.18-6a16.32,16.32,0,0,0-2.8-4.22c2.14,2.41,4.47,5,7.87,5.22a4.67,4.67,0,0,0,4.75-2.57c1-2.28-.43-4.49-1.88-6.27a65.68,65.68,0,0,0-8.53-8.72,6.53,6.53,0,0,0-4.14-1.53c-1.76,0-4,.32-5,1.84a6.85,6.85,0,0,0-1.37,5.23s-4.4-2.23-7.94.1a11.26,11.26,0,0,0-4.42,6.36Z"
          style={{
            fill: 'rgb(255, 190, 157)',
            transformOrigin: '239.11685752868652px 336.8779754638672px',
          }}
          id="elqq7ow3hjsh"
          className="animable"
        />
        <path
          d="M243.45,319.87a5.28,5.28,0,0,1-.62-6c.75-1.47,1.69-2.9,2.65-3.22,4.19-1.41,22.51,1.76,22.34,5.82s-1.51,6-11.44,4.57c-1.75-.25-4.49-.59-4.49-.59l-2.4,3.6Z"
          style={{
            fill: 'rgb(255, 190, 157)',
            transformOrigin: '254.9960069656372px 317.17977952957153px',
          }}
          id="elzcfgw1fvdh"
          className="animable"
        />
        <path
          d="M246.73,336.89a23.89,23.89,0,0,0-10.09-9.15,5.82,5.82,0,0,1,1.94.68,17.41,17.41,0,0,1,7.28,6.6A5.59,5.59,0,0,1,246.73,336.89Z"
          style={{
            fill: 'rgb(235, 153, 110)',
            transformOrigin: '241.68499755859375px 332.3150329589844px',
          }}
          id="eldyp84gj2lrk"
          className="animable"
        />
        <path
          d="M249.81,324.4c-.09.08-1.3-.9-2.95-1.91a21.46,21.46,0,0,0-2.14-1.19c-.57-.26-.95-.33-.94-.41s.43-.12,1.06.07a9.7,9.7,0,0,1,2.3,1.09C248.83,323.08,249.94,324.29,249.81,324.4Z"
          style={{
            fill: 'rgb(235, 153, 110)',
            transformOrigin: '246.80005598068237px 322.61400759220123px',
          }}
          id="elmf1xi64vojk"
          className="animable"
        />
        <path
          d="M252.89,320.62a7.83,7.83,0,0,1-3.53-.4A6.52,6.52,0,0,1,247.1,319c-.5-.43-.71-.78-.66-.83a15,15,0,0,0,3.07,1.52A26.31,26.31,0,0,1,252.89,320.62Z"
          style={{
            fill: 'rgb(235, 153, 110)',
            transformOrigin: '249.6614067554474px 319.4213296175003px',
          }}
          id="el6zaj1o3nup4"
          className="animable"
        />
        <path
          d="M235.09,341.92a13,13,0,0,1-1.67-2.17,19.43,19.43,0,0,0-4.27-4.86,8.59,8.59,0,0,0-6.06-1.6,10.28,10.28,0,0,0-2.55.81s.18-.21.59-.46a5.58,5.58,0,0,1,1.9-.72,8.54,8.54,0,0,1,6.42,1.55,17.32,17.32,0,0,1,4.28,5.07A16.28,16.28,0,0,1,235.09,341.92Z"
          style={{
            fill: 'rgb(235, 153, 110)',
            transformOrigin: '227.81500244140625px 337.3653492927551px',
          }}
          id="el1c0icic8mgs"
          className="animable"
        />
        <path
          d="M249.1,382.42a56,56,0,0,1-7.48,1.07,54.22,54.22,0,0,1-7.55.56,56.34,56.34,0,0,1,7.49-1.07A56,56,0,0,1,249.1,382.42Z"
          style={{
            fill: 'rgb(235, 153, 110)',
            transformOrigin: '241.58499908447266px 383.23500061035156px',
          }}
          id="elyvyyc40fzkt"
          className="animable"
        />
      </g>
      <g
        id="freepik--Stars--inject-3"
        style={{ transformOrigin: '246.3600082397461px 191.56000137329102px' }}
        className="animable"
      >
        <polygon
          points="406.63 49.84 407.76 51.91 409.82 53.04 407.76 54.17 406.63 56.23 405.5 54.17 403.44 53.04 405.5 51.91 406.63 49.84"
          style={{
            fill: 'rgb(149, 189, 81)',
            transformOrigin: '406.6300048828125px 53.03499984741211px',
          }}
          id="el0sz6gjaypl"
          className="animable"
        />
        <polygon
          points="430.24 101.01 431.37 103.08 433.43 104.21 431.37 105.34 430.24 107.4 429.11 105.34 427.04 104.21 429.11 103.08 430.24 101.01"
          style={{
            fill: 'rgb(149, 189, 81)',
            transformOrigin: '430.23500061035156px 104.20500183105469px',
          }}
          id="elb7hpy31cawc"
          className="animable"
        />
        <polygon
          points="51.9 237.53 53.03 239.6 55.1 240.73 53.03 241.85 51.9 243.92 50.77 241.85 48.71 240.73 50.77 239.6 51.9 237.53"
          style={{
            fill: 'rgb(149, 189, 81)',
            transformOrigin: '51.904998779296875px 240.7249984741211px',
          }}
          id="elfjuzv49af1"
          className="animable"
        />
        <polygon
          points="375.66 62.56 378.27 67.33 383.04 69.94 378.27 72.54 375.66 77.31 373.06 72.54 368.29 69.94 373.06 67.33 375.66 62.56"
          style={{
            fill: 'rgb(149, 189, 81)',
            transformOrigin: '375.6650085449219px 69.93499946594238px',
          }}
          id="ela9auu4w6jgn"
          className="animable"
        />
        <polygon
          points="73.06 205.1 75.66 209.85 80.42 212.45 75.66 215.06 73.06 219.81 70.46 215.06 65.7 212.45 70.46 209.85 73.06 205.1"
          style={{
            fill: 'rgb(149, 189, 81)',
            transformOrigin: '73.05999755859375px 212.4550018310547px',
          }}
          id="el1yk1tgd9c98"
          className="animable"
        />
        <polygon
          points="436.66 318.56 439.26 323.32 444.01 325.92 439.26 328.52 436.66 333.28 434.06 328.52 429.3 325.92 434.06 323.32 436.66 318.56"
          style={{
            fill: 'rgb(149, 189, 81)',
            transformOrigin: '436.6549987792969px 325.9199981689453px',
          }}
          id="eltt3dc8vfh4m"
          className="animable"
        />
      </g>
      <defs>
        {' '}
        <filter id="active" height="200%">
          {' '}
          <feMorphology
            in="SourceAlpha"
            result="DILATED"
            operator="dilate"
            radius={2}
          />{' '}
          <feFlood floodColor="#32DFEC" floodOpacity={1} result="PINK" />{' '}
          <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />{' '}
          <feMerge>
            {' '}
            <feMergeNode in="OUTLINE" /> <feMergeNode in="SourceGraphic" />{' '}
          </feMerge>{' '}
        </filter>{' '}
        <filter id="hover" height="200%">
          {' '}
          <feMorphology
            in="SourceAlpha"
            result="DILATED"
            operator="dilate"
            radius={2}
          />{' '}
          <feFlood floodColor="#ff0000" floodOpacity="0.5" result="PINK" />{' '}
          <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />{' '}
          <feMerge>
            {' '}
            <feMergeNode in="OUTLINE" /> <feMergeNode in="SourceGraphic" />{' '}
          </feMerge>{' '}
          <feColorMatrix
            type="matrix"
            values="0   0   0   0   0                0   1   0   0   0                0   0   0   0   0                0   0   0   1   0 "
          />{' '}
        </filter>
      </defs>
    </svg>
  );
};

export default ShareIcon;

ShareIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};
