import styled from '@emotion/styled';
import React, { Component } from 'react';
import { Colors, PrimaryButton, Modal } from 'utils';
import { SingleTime } from './';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ProjectsActions } from 'redux/actions';
const TimeLineTabWrapper = styled.div`
  margin-bottom: 50px;
  h1 {
    font-size: 30px;
    font-weight: bold;
    color: ${Colors.GreenColor};
    margin: 0;
    margin-bottom: 20px;
    margin-left: 17px;
  }
  .show-more {
    text-align: center;
    margin: 25px 0;
    button {
      display: inline-block;
      text-decoration: none;
      background: none;
      border: none;
      border-bottom: 2px solid ${Colors.transparent};
      margin: 20px auto 0;
      color: ${Colors.secondaryColor};
      &:hover {
        border-bottom: 2px solid ${Colors.secondaryColor};
      }
    }
  }
`;

class TimeLineTab extends Component {
  static propTypes = {
    updates: PropTypes.array.isRequired,
    deleteProjectUpdates: PropTypes.func.isRequired,
    projectId: PropTypes.any.isRequired,
  };
  state = {
    currentLimit: 3,
    showModal: false,
    selectedUpdateId: null,
  };

  handelCloseModal = () => {
    this.setState({ showModal: false, selectedUpdateId: null });
  };

  selectUpdateToDelete = (id) => {
    this.setState({ showModal: true, selectedUpdateId: id });
  };

  deleteUpdate = () => {
    this.props.deleteProjectUpdates(
      this.props.projectId,
      this.state.selectedUpdateId
    );
    this.handelCloseModal();
  };

  render() {
    const { updates } = this.props;

    return (
      <TimeLineTabWrapper>
        <h1>Update</h1>
        {updates &&
          updates.map((time, inx) => {
            return (
              inx < this.state.currentLimit && (
                <SingleTime
                  key={`${JSON.stringify(time)}-${inx}`}
                  time={time.date}
                  by={time.title}
                  id={time.id}
                  image={time.image}
                  selectUpdateToDelete={this.selectUpdateToDelete}
                  content={time.body || 'There Is No Body'}
                />
              )
            );
          })}
        {this.state.currentLimit < updates.length && (
          <div className="show-more">
            <button
              onClick={() =>
                this.setState({ currentLimit: this.state.currentLimit + 3 })
              }
            >
              Mehr anzeigen
            </button>
          </div>
        )}

        {/* Modal for deleting */}
        <Modal
          showModal={this.state.showModal}
          handelCloseModal={this.handelCloseModal}
        >
          <div style={{ padding: '10px 30px' }}>
            <div style={{ fontSize: '24px' }}>
              Bist du sicher, dass du fortfahren möchtest?
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                marginTop: '20px',
              }}
            >
              <PrimaryButton onClick={() => this.deleteUpdate()}>
                Bestätigen
              </PrimaryButton>
              <PrimaryButton
                onClick={() => this.handelCloseModal()}
                style={{ backgroundColor: '#F44336', color: '#FFF' }}
              >
                Abbrechen
              </PrimaryButton>
            </div>
          </div>
        </Modal>
      </TimeLineTabWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  deleteProjectUpdates: (projectId, updateId) =>
    dispatch(ProjectsActions.deleteProjectUpdates(projectId, updateId)),
});

export default connect(null, mapDispatchToProps)(TimeLineTab);
