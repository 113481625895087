import { HandlePaymentErrorMassage } from 'utils';
const getMyCards = () => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  return fetch(`cards`, requestOptions)
    .then((cards) => {
      return { cards };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const prepareCard = (user) => {
  const body = JSON.stringify({ user_info: { ...user } });
  const requestOptions = {
    method: 'POST',
    redirect: 'follow',
    body,
  };

  return fetch(`cards/prepare_card`, requestOptions)
    .then((card) => {
      return { card };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const getToken = (card) => {
  const body = new URLSearchParams();
  body.append('accessKeyRef', card.AccessKey);
  body.append('data', card.PreregistrationData);
  body.append('cardNumber', card.number);
  body.append('cardExpirationDate', card.expiry);
  body.append('cardCvx', card.cvc);
  const requestOptions = {
    method: 'POST',
    redirect: 'follow',
    body,
  };

  return fetch(
    `https://homologation-webpayment.payline.com/webpayment/getToken`,
    requestOptions
  )
    .then((card) => {
      return { card };
    })
    .catch((error) => {
      return Promise.reject({ errors: HandlePaymentErrorMassage(error) });
    });
};

const saveCard = (cardToken, cardId) => {
  const body = JSON.stringify({
    data: { RegistrationData: cardToken, card_id: cardId },
  });
  const requestOptions = {
    method: 'POST',
    redirect: 'follow',
    body,
  };

  return fetch(`cards/update_card`, requestOptions)
    .then((card) => {
      return { card };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const makeDonation = (donation) => {
  const raw = JSON.stringify({
    donation: donation,
  });
  const requestOptions = {
    method: 'POST',
    redirect: 'follow',
    body: raw,
  };
  return fetch(`payments/payment_session`, requestOptions)
    .then((donation) => {
      return { donation };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const setUpStripeAccountURL = (projectId) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  return fetch(`/payments/${projectId}/stripe_link`, requestOptions)
    .then((url) => {
      return { url };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const getStripeStatus = (projectId) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  return fetch(`/payments/${projectId}/stripe_status`, requestOptions)
    .then((status) => {
      return { status };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const deleteCard = (cardId) => {
  const requestOptions = {
    method: 'DELETE',
    redirect: 'follow',
  };

  return fetch(`cards/${cardId}`, requestOptions)
    .then((card) => {
      return { card };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const paymentServices = {
  getMyCards,
  prepareCard,
  getToken,
  saveCard,
  deleteCard,
  makeDonation,
  setUpStripeAccountURL,
  getStripeStatus,
};

export default paymentServices;
