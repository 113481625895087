import React, { Component } from 'react';
import { Layout, LoginForm } from 'components';
import { Container, device } from 'utils';
import styled from '@emotion/styled';

const LoginWrapper = styled.div`
  max-width: 550px;
  margin: 100px auto;
  @media ${device.allMobile} {
    max-width: 100%;
  }
`;
export default class LoginPage extends Component {
  render() {
    return (
      <Layout>
        <Container>
          <LoginWrapper>
            <LoginForm />
          </LoginWrapper>
        </Container>
      </Layout>
    );
  }
}
