import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { authActions } from 'redux/actions';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import {
  Colors,
  SvgWrapper,
  H1,
  Container,
  PrimaryButton,
  formValidateMessages,
  device,
} from 'utils';
import { BaseLayout } from 'components';
import { ShortBackground, ContactUs } from 'images';
import { Input, Form } from 'antd';
import ReCAPTCHA from 'react-google-recaptcha';
import { store } from 'react-notifications-component';
import { Helmet } from 'react-helmet';
const FormItem = Form.Item;

const Intro = styled.div`
  text-align: center;
  h1 {
    font-size: 55px;
    @media ${device.allMobile} {
      font-size: 30px;
    }
  }
`;
const ContactUsWrapper = styled.div`
  display: flex;
  margin: 50px auto;
  margin-top: 150px;
  @media ${device.allMobile} {
    margin-top: 25px;
    flex-wrap: wrap;
  }

  @media ${device.laptop} {
    align-items: center;
    margin-top: 100px;
  }
  @media ${device.tablet} {
    margin-top: 75px;
  }
  & > div {
    width: 50%;
    @media ${device.allMobile} {
      width: 100%;
      order: 2;
    }
    & + div {
      margin-top: -108px;
      @media ${device.allMobile} {
        margin-top: 25px;
        order: 1;
      }
      @media ${device.laptop} {
        margin-top: -165px;
      }
      @media ${device.tablet} {
        margin-top: -108px;
      }
    }
    .ant-select-selector,
    input,
    textarea {
      border: 1px solid ${Colors.secondaryColor} !important;
      border-radius: 15px !important;
    }
    textarea {
      min-height: 200px;
    }
  }
`;

const FormWrapper = styled.div`
  display: flex;
  @media ${device.allMobile} {
    flex-wrap: wrap;
  }
  & > div {
    width: 50%;
    @media ${device.allMobile} {
      width: 100%;
    }
  }

  .ant-form-item {
    .ant-form-item-control-input-content {
      padding-left: 0;
      padding-right: 10px;
      @media ${device.allMobile} {
        padding-right: 0;
      }
    }
    & + .ant-form-item {
      .ant-form-item-control-input-content {
        padding-right: 0;
        padding-left: 10px;
        @media ${device.allMobile} {
          padding-left: 0;
        }
      }
    }
  }
`;
const ContactUsHeader = styled.div`
  margin-top: 100px;
  h1 {
    @media ${device.allMobile} {
      font-size: 25px;
    }
  }
`;

export class ContactUsPage extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    sendContact: PropTypes.func.isRequired,
  };
  state = {
    captcha: null,
  };
  onChange = (value) => {
    this.setState({ captcha: value });
  };
  onFinish = (values) => {
    const { captcha } = this.state;
    const { sendContact } = this.props;

    const notification = {
      insert: 'top',
      container: 'top-right',
      animationIn: ['animate__animated animate__fadeIn'], // `animate.css v4` classes
      animationOut: ['animate__animated animate__fadeOut'], // `animate.css v4` classes
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
    };
    if (captcha) {
      sendContact(values);
    } else {
      store.addNotification({
        ...notification,
        title: 'Fehler!',
        type: 'danger',
        message: 'Bitte alle Felder ausfüllen.',
      });
    }
  };
  render() {
    return (
      <div>
        <Helmet>
          <meta
            name="description"
            content="Kontakt zu commonsplace aufnehmen. Support-Team steht zur Verfügung, um Fragen zu beantworten und Hilfe zu leisten."
          />
        </Helmet>
        <BaseLayout
          maxHeightProps="500px"
          maxHeightMobileProps="250px"
          container={false}
          img={ShortBackground}
          bannerComponent={
            <Intro>
              <H1>Kontaktiere uns</H1>
            </Intro>
          }
        >
          <Container>
            <ContactUsHeader>
              <h2>Du hast eine Frage oder ein spezielles Anliegen?</h2>
              <p>
                commonsplace sollte einfach und unkompliziert sein, aber wir
                verstehen, dass manchmal Fragen und Probleme auftreten. Bitte
                schaue zunächst bei den <Link to="faqs">FAQs </Link> nach, ob
                deine Frage nicht dort schon beantwortet wurde.
              </p>
              <p>
                Wenn du uns kontaktieren möchtest, nutze bitte das untenstehende
                Formular. Wir antworten dir so schnell wie möglich.
              </p>
              <p>Wir freuen uns über deine Nachricht.</p>
            </ContactUsHeader>
            <ContactUsWrapper>
              <div>
                <Form
                  validateMessages={formValidateMessages}
                  onFinish={this.onFinish}
                >
                  <FormWrapper>
                    <FormItem
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: 'Name ist erforderlich.',
                        },
                      ]}
                    >
                      <Input placeholder="Name" />
                    </FormItem>
                    <FormItem
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: 'Email ist erforderlich.',
                        },
                      ]}
                    >
                      <Input type="email" placeholder="E-Mail-Adresse" />
                    </FormItem>
                  </FormWrapper>
                  <FormWrapper>
                    <FormItem name="telephone">
                      <Input placeholder="Telefon" />
                    </FormItem>
                    <FormItem
                      name="subject"
                      rules={[
                        {
                          required: true,
                          message: 'Betreff ist erforderlich.',
                        },
                      ]}
                    >
                      <Input placeholder="Betreff" />
                    </FormItem>
                  </FormWrapper>

                  <FormItem
                    name="body"
                    rules={[
                      {
                        required: true,
                        message: 'Eine Nachricht ist erforderlich.',
                      },
                    ]}
                  >
                    <Input.TextArea placeholder="Deine Nachricht" />
                  </FormItem>
                  <FormItem name="recaptcha">
                    <ReCAPTCHA
                      sitekey="6Le3SXcaAAAAALMCZbmfzc6AiJ1GpIHzs2XP61at"
                      onChange={this.onChange}
                    />
                  </FormItem>
                  <FormItem>
                    <PrimaryButton
                      type="primary"
                      htmltype="submit"
                      minWidth="150px"
                    >
                      Senden
                    </PrimaryButton>
                  </FormItem>
                </Form>
              </div>
              <div>
                <SvgWrapper width={'100%'} height={'auto'}>
                  <ContactUs />
                </SvgWrapper>
              </div>
            </ContactUsWrapper>
          </Container>
        </BaseLayout>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  sendContact: (form) => dispatch(authActions.sendContact(form)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ContactUsPage);
