import React, { Component } from 'react';
import styled from '@emotion/styled';
import { TargetIcon, TimeIcon, SupporterIcon, MapIcon } from 'images';
import { ProgressBar } from 'components';
import {
  Colors,
  SvgWrapper,
  PrimaryButton,
  Modal,
  device,
  numberWithCommas,
  H1,
} from 'utils';
import { ShareProject } from '.';
import RecentSupport from './RecentSupport';
import PropTypes from 'prop-types';
import {
  GiftFilled,
  InfoCircleOutlined,
  InstagramOutlined,
} from '@ant-design/icons';
import { Tooltip, Button } from 'antd';
import ProjectImages from './ProjectImages';
import { Col, Row } from 'antd';
import Support from './Support';
import { DonationIcon } from 'images';
import { Input } from 'antd';
import { Link } from 'react-router-dom';

const ProjectDetailsWrapper = styled.div`
  background: ${Colors.white};
  // box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
  //   0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  // margin-top: 30px;
  // margin-bottom: 30px;
  padding: 0px -8px;
  @media ${device.allMobile} {
    width: 100%;
    margin: auto;
    // padding: 0 0 15px;
    margin-top: 20px;
  }
  @media ${device.tablet} {
    margin-right: 20px;
    margin-left: 20px;
  }
`;

const ProjectInfo = styled.div`
  display: flex;
  @media ${device.allMobile} {
    flex-wrap: wrap;
    width: 100%;
  }
  @media ${device.tablet} {
    flex-wrap: wrap;
    width: 100%;
  }
  & > div {
    padding: 10px;
  }
  .project-info {
    &__description {
      .description-line {
        display: block;
        cursor: default;
      }
      span {
        background: none;
        padding: none;

        border: none;
        cursor: pointer;
        span {
          color: ${Colors.secondaryColor};
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    &__details {
      min-width: 100%;
      text-align: center;
      padding: 0;

      @media ${device.allMobile} {
        padding: 0;
        width: 100%;
      }
      @media ${device.tablet} {
        width: 100%;
      }
    }
    &__funding {
      min-width: 60%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media ${device.allMobile} {
        width: 100%;
      }
      @media ${device.tablet} {
        width: 100%;
      }
      & > p {
        font-size: 18px;
        line-height: 1.6;
        margin-bottom: 30px;
        @media ${device.allMobile} {
          text-align: center;
        }
      }
      &--actions {
        display: flex;
        flex-direction:columnl
        justify-content: space-between;
        button {
          margin-bottom: 30px;
          width:100%;
        }
        @media ${device.allMobile} {
          display: block;

          button {
            margin-bottom: 20px;
            min-width: 100%;
          }
        }
        .donation {
          padding: 0;
          a {
            padding: 15px 20px;
            display: block;
            &:hover {
              color: ${Colors.secondaryColor};
            }
          }
        }
      }
    }
  }

  .project-rewards {
    margin: 30px 0;
    font-size: 26px;
    font-weight: 780;
    @media ${device.laptop} {
      font-size: 18px;
    }
    @media ${device.tablet} {
      font-size: 20px;
      text-align: start !important;
    }
    @media ${device.allMobile} {
      font-size: 16px;
      text-align: start !important;
    }
  }
  .project-rewards-gift {
    font-size: 60px;
    @media ${device.laptop} {
      font-size: 40px;
    }
    @media ${device.tablet} {
      font-size: 40px;
    }
    @media ${device.allMobile} {
      font-size: 24px;
    }
  }
  .project-rewards-gift:hover {
    color: #95bd51;
    cursor: pointer;
  }
  .project-rewards-inner:hover {
    color: #95bd51;
    cursor: pointer;
  }
`;

const ProjectFooter = styled.div`
  @media ${device.allMobile} {
    padding: 5px 0px;
    text-align: center;
  }
  .project-info {
    &__numbers {
      display: flex;
      // justify-content: space-between;
      flex-direction: column;
      margin-top: 10px;
      @media ${device.allMobile} {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
      }
      img {
        width: 55px;
        height: 55px;
        margin-right: 20px;
      }
      & > div {
        display: flex;
        align-items: center;
        @media ${device.allMobile} {
          margin-bottom: 5px;
          width: 100%;
          justify-content: flex-start;
        }
        & > span {
          margin: 0;
          margin-right: 20px;
        }
        p {
          margin: 0;
          line-height: 1;
          // text-align: center;
          font-weight: 300;
          opacity: 0.7;
          margin-top: 5px;
          &:first-of-type {
            font-size: 18px;
            margin-top: 10px;
            font-weight: bold;
            opacity: 1;
            // white-space: nowrap;
            @media ${device.allMobile} {
              font-size: 14px;
              margin-top: 10px;
            }
          }
        }
      }
    }
    .progress-bar {
      div {
        height: 10px;
        border-radius: 5px 0 10px 5px;
      }
      &.complete {
        div {
          border-radius: 5px 5px 5px 5px;
        }
      }
    }
  }
`;

const DescriptionModal = styled.div`
  @media ${device.allMobile} {
    text-align: center;
  }
  .description-line {
    display: block;
  }
  .button {
    text-align: center;
  }
`;

const DescriptionText = styled.p`ProjectTab
  white-space: pre-wrap;
  /* Additional styles for the description text */
`;

const ResponsiveH1 = styled.h1`
  @media (max-width: 576px) {
    font-size: 15px;
    text-align: center;
  }
`;

const ResponsiveText = styled.p`
  @media (max-width: 768px) {
    font-size: 15px;
    text-align: center;
  }
`;

const { TextArea } = Input;

export default class ProjectDetails extends Component {
  scrollToTop = () => {
    let bodyRect = document.body.getBoundingClientRect().top;
    let element = document.getElementById('project-detail');
    let headerOffset = 160;
    let elementPosition = element.getBoundingClientRect().top;
    let offsetPosition = elementPosition - bodyRect - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  };

  state = {
    showModal: false,
    showProjectDescriptionModal: false,
    showContactModal: false,
  };
  static propTypes = {
    projectUrl: PropTypes.string,
    projectDescription: PropTypes.string,
    projectDonations: PropTypes.number,
    projectSupporters: PropTypes.number,
    projectDays: PropTypes.number,
    projectImages: PropTypes.array,
    projectGoal: PropTypes.number,
    projectFinalGoal: PropTypes.number,
    isViewPage: PropTypes.bool.isRequired,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    banner: PropTypes.string,
    projectId: PropTypes.number,
    projectStatus: PropTypes.string,
    rewardsExist: PropTypes.bool,
    project: PropTypes.object.isRequired,
    loadingDonations: PropTypes.bool.isRequired,
    getProjectDonations: PropTypes.func.isRequired,
    donations: PropTypes.object.isRequired,
  };

  handelCloseModal = () => {
    this.setState({ showModal: false });
    document.body.style.overflowY = 'unset';
  };

  handelShowModal = () => {
    this.setState({ showModal: true });
    document.body.style.overflowY = 'hidden';
  };

  handelCloseDescriptionModal = () => {
    this.setState({ showProjectDescriptionModal: false });
    document.body.style.overflowY = 'unset';
  };

  handelShowDescriptionModal = () => {
    this.setState({ showProjectDescriptionModal: true });
    document.body.style.overflowY = 'hidden';
  };

  scrollToRewards = () => {
    let bodyRect = document.body.getBoundingClientRect().top;
    let element = document.getElementById('rewards-section');
    let headerOffset = 110;
    let elementPosition = element.getBoundingClientRect().top;
    let offsetPosition = elementPosition - bodyRect - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  };

  render() {
    const { showModal, showProjectDescriptionModal, showContactModal } =
      this.state;
    const {
      projectDescription,
      projectDonations,
      projectSupporters,
      projectDays,
      projectImages,
      projectGoal,
      isViewPage,
      subtitle,
      title,
      banner,
      projectId,
      projectStatus,
      project,
      loadingDonations,
      getProjectDonations,
      donations,
      projectUrl,
    } = this.props;
    localStorage.setItem('project_url', projectUrl);
    let projectDraft = localStorage.getItem('projectDraft');
    projectDraft = JSON.parse(projectDraft);
    const percentage = Math.floor(
      ((projectDonations || 0) / projectGoal) * 100
    );
    const percentageForTotalGoal = Math.floor(
      ((projectDonations || 0) / project?.final_goal) * 100
    );

    const description =
      projectDescription && projectDescription.length > 914
        ? projectDescription.substring(0, 915)
        : projectDescription;

    const newDesc = description;

    const descriptions = projectDescription && description?.split(/\n/g);
    const fullDescriptions = projectDescription && projectDescription;
    // const instaHandle = project?.instagram_url?.split(
    //   'http://www.instagram.com/'
    // );
    const instaHandle = project?.instagram_url?.split('instagram.com/')[1];
    return (
      <div id="project-detail">
        <div className="title_subtitile">
          <H1
            style={{
              marginTop: '40px',
              // textTransform: 'capitalize',
              paddingBottom: '0px',
              lineHeight: '38px',
              textAlign: 'left',
              fontSize: '32px',
            }}
            className="project_detail_heading_lg"
          >
            {title}
          </H1>
          <h2 className="project_detail_heading_lg ">{subtitle}</h2>
        </div>
        <Row gutter={{ lg: 16 }}>
          <Col lg={{ span: 15 }} className="w-full">
            <ProjectDetailsWrapper>
              <ProjectInfo>
                <div className="project-info__details">
                  {projectImages.length > 0 && (
                    <ProjectImages
                      projectImages={projectImages}
                      projectMobileImages={project.mobile_array_images}
                    />
                  )}
                </div>
              </ProjectInfo>
              <div className="project-info__funding">
                <div className="title_subtitile">
                  <h1
                    style={{
                      marginTop: '20px',
                      // textTransform: 'capitalize',
                      lineHeight: '28px',
                      fontSize: '24px',
                    }}
                    className="project_detail_heading_sm"
                  >
                    {title}
                  </h1>
                  <h2
                    className="project_detail_heading_sm"
                    style={{ fontSize: '18px' }}
                  >
                    {subtitle}
                  </h2>
                </div>
                <div className="project-info__description project_info_desc_lg">
                  {/* {descriptions && descriptions.length > 0
                    ? descriptions.map((des, index) => (
                      <span
                        key={`${des}-${index}`}
                        className="description-line"
                      >
                        {des}
                      </span>
                    ))
                    : 'Your Description...'} */}
                  <TextArea
                    autoSize
                    readOnly
                    className="text_area description-line"
                    value={description}
                  />
                  {projectDescription && projectDescription.length > 914 && (
                    <span>
                      ...
                      <span
                        style={{
                          color: '#95BD51',
                          fontWeight: '400',
                          cursor: 'pointer',
                        }}
                        onClick={() => this.handelShowDescriptionModal()}
                      >
                        Mehr Anzeigen
                      </span>
                    </span>
                  )}
                </div>
                <div className="sepratTargetIconor-lg" />
              </div>
            </ProjectDetailsWrapper>
          </Col>
          <Col span={24} lg={{ span: 9 }}>
            <div className="project_footer_wrapper">
              <div className="parent_funds funds_lg">
                {/* <span className="collected_funds">
                  €{' '}
                  {numberWithCommas(
                    projectDonations
                      ? parseFloat(projectDonations).toFixed(2)
                      : 0
                  )}
                </span> */}
                {/* <span className="raised_amount"> raised of {project?.final_goal && (isViewPage
                  ? (project?.fundingTarget &&
                    project?.fundingTarget?.final_goal &&
                    numberWithCommas(project?.fundingTarget?.final_goal)) ||
                  0
                  : project?.final_goal && numberWithCommas(project?.final_goal))}{" "} € goal</span>  */}
                {/* <span className='circle'></span><span className='total_donors'>{projectSupporters || 0}
                </span><span className="raised_amount total_donors">donors</span> */}
              </div>
              <ProjectFooter>
                <div>
                  <ProgressBar
                    percentage={
                      (percentage >= 100
                        ? percentageForTotalGoal
                        : percentage) || 0
                    }
                    className="progress_bar_lg"
                  />
                  {/* <div className="parent_funds funds_sm">
                    <span className="collected_funds">
                      €{' '}
                      {numberWithCommas(
                        projectDonations
                          ? parseFloat(projectDonations).toFixed(2)
                          : 0
                      )}
                    </span>
                    <span className="raised_amount"> raised of {project?.final_goal && (isViewPage
                      ? (project?.fundingTarget &&
                        project?.fundingTarget?.final_goal &&
                        numberWithCommas(project?.fundingTarget?.final_goal)) ||
                      0
                      : project?.final_goal && numberWithCommas(project?.final_goal))}{" "} € goal</span> <span className='circle'></span><span className='total_donors'>{projectSupporters || 0}
                    </span><span className="raised_amount total_donors">donors</span>
                  </div> */}
                  <span className="donars_count">
                    <div className="icons_detail_section">
                      {project.is_ticketable ? (
                        <div className="icon_main_div">
                          <SvgWrapper width={'20px'} height={'20px'}>
                            <img
                              src={MapIcon}
                              style={{
                                marginTop: '-9px',
                              }}
                            />
                          </SvgWrapper>
                          <div className="detail_count">
                            <p className="city_p_text">{project?.city}</p>
                          </div>
                        </div>
                      ) : project?.is_patreon ? (
                        <div className="icon_main_div insta_patreon">
                          <InstagramOutlined
                            style={{
                              color: 'black',
                              marginRight: '4px',
                              fontSize: '25px',
                              marginBottom: '2px',
                            }}
                          />
                          {project?.instagram_url ? (
                            <a
                              href={project?.instagram_url}
                              target="_blank"
                              className="detail_p_text_patreon insta-handler"
                              rel="noreferrer"
                            >
                              {project?.instagram_url &&
                                `@${instaHandle?.replace('/', '')}`}
                            </a>
                          ) : (
                            <p className="detail_p_text_patreon">
                              Instagram handle
                            </p>
                          )}
                        </div>
                      ) : (
                        <div className="icon_main_div">
                          <SvgWrapper width={'25px'} height={'25px'}>
                            <TargetIcon />
                          </SvgWrapper>
                          <div className="detail_count">
                            <p className="detail_p">
                              {numberWithCommas(
                                projectDonations
                                  ? parseFloat(projectDonations).toFixed(2)
                                  : 0
                              )}{' '}
                              €
                            </p>
                            <p className="detail_p_text">gesammelt</p>
                          </div>
                        </div>
                      )}
                      <div className="icon_main_div">
                        <img
                          src={SupporterIcon}
                          style={{
                            width: '35px',
                            minWidth: '35px',
                            height: '35px',
                            marginTop: '-3px',
                          }}
                          alt="supporter-icon"
                        />
                        <div className="detail_count">
                          <p className="detail_p">{projectSupporters || 0}</p>
                          <p className="detail_p_text">
                            {project.is_ticketable ? 'Besucher' : 'Supporter'}
                          </p>
                        </div>
                      </div>
                      {!project?.is_patreon && (
                        <div className="icon_main_div">
                          <SvgWrapper width={'25px'} height={'25px'}>
                            <TimeIcon />
                          </SvgWrapper>
                          <div className="detail_count">
                            <p className="detail_p">{projectDays || 0}</p>
                            <p className="detail_p_text">Tag(e)</p>
                          </div>
                        </div>
                      )}
                    </div>
                  </span>
                  <div className="project-info__numbers">
                    <Support
                      // projectUrl={url}
                      projectId={project?.id}
                      projectStatus={project?.status}
                      donationsAverage={project?.donations_average}
                      project={project}
                      handleShowContactModal={() =>
                        this.setState({ showContactModal: true })
                      }
                    />
                    <div>
                      {project && project.id && (
                        <RecentSupport
                          loadingDonations={loadingDonations}
                          projectId={project.id}
                          getProjectDonations={getProjectDonations}
                          donations={donations}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </ProjectFooter>
            </div>
          </Col>
        </Row>
        <div>
          <p as="div" className="project_info_desc_mobile">
            {/* {descriptions && descriptions.length > 0
              ? descriptions.map((des, index) => (
                <span key={`${des}-${index}`} className="description-line">
                  {des}
                </span>
              ))
              : 'Your Description...'} */}
            <TextArea
              autoSize
              readOnly
              className="text_area description-line"
              value={newDesc}
            />
            {projectDescription && projectDescription.length > 914 && (
              <span>
                ...
                <span
                  style={{
                    color: '#95BD51',
                    cursor: 'pointer',
                    fontWeight: '400',
                  }}
                  onClick={() => this.handelShowDescriptionModal()}
                >
                  Mehr Anzeigen
                </span>
              </span>
            )}
            <div className="seprator-sm" />
          </p>
          <div className="goodies_initiator">
            {this.props.rewardsExist && !project.is_ticketable && (
              <p className="project-rewards project-rewards-main">
                <span
                  className="project-rewards-inner rewards_text"
                  onClick={() => this.scrollToRewards()}
                >
                  <GiftFilled className="project-rewards-gift" />{' '}
                  <span>Dieser Projektinitiator bietet Goodies an.</span>
                </span>
                <Tooltip
                  title="Goodies sind Gegenleistungen vom Projektinitiator, die du für deine Unterstütztung erhältst."
                  color="#95bd51"
                >
                  <Button type="link" style={{ padding: '0px 5px' }}>
                    <InfoCircleOutlined
                      style={{ color: 'black', fontSize: '16px' }}
                    />
                  </Button>
                </Tooltip>
              </p>
            )}

            {project?.donation_receipts_possible && (
              <p className="donation-note">
                <img
                  src={DonationIcon}
                  alt="project-donation-icon"
                  // onClick={() => this.handelOpenImagesModal()}
                  style={{
                    height: '15px',
                    width: '15px',
                    marginRight: '5px',
                    marginTop: '-3px',
                  }}
                />
                Der Projektinitiator kann Spendenquittungen ausstellen.
              </p>
            )}
          </div>
        </div>
        <Modal showModal={showModal} handelCloseModal={this.handelCloseModal}>
          <ShareProject subtitle={subtitle} title={title} banner={banner} />
        </Modal>
        <Modal
          showModal={showProjectDescriptionModal}
          handelCloseModal={this.handelCloseDescriptionModal}
        >
          <DescriptionModal>
            {/* <p>
              {fullDescriptions &&
                fullDescriptions.length > 0 &&
                fullDescriptions.map((des, index) => (
                  <>
                    {des ? (<> <span key={`${des}-${index}`} className="description-line">
                      {des}
                    </span>
                      <span class="description-line">-----</span></>) : null}
                  </>
                ))}
            </p> */}
            <TextArea
              rows={14}
              readOnly
              className="text_area description-line"
              value={fullDescriptions}
            />
            <div className="button">
              <PrimaryButton onClick={() => this.handelCloseDescriptionModal()}>
                Schließen
              </PrimaryButton>
            </div>
          </DescriptionModal>
        </Modal>
        <Modal
          showModal={showContactModal}
          handelCloseModal={() => this.setState({ showContactModal: false })}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            id="test1"
          >
            <ResponsiveH1 style={{ color: '#95bd51' }}>
              Kontaktiere den Veranstalter
            </ResponsiveH1>
            <ResponsiveText>
              Email: {project?.initiator_email || ''}
            </ResponsiveText>
          </div>
        </Modal>
      </div>
    );
  }
}
