const authConstants = {
  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
  SIGN_UP_REQUEST: 'USERS_SIGN_UP_REQUEST',
  SIGN_UP_SUCCESS: 'USERS_SIGN_UP_SUCCESS',
  SIGN_UP_FAILURE: 'USERS_SIGN_UP_FAILURE',
  RESET_PASSWORD_REQUEST: 'USERS_RESET_PASSWORD_USER_REQUEST',
  RESET_PASSWORD_SUCCESS: 'USERS_RESET_PASSWORD_USER_SUCCESS',
  RESET_PASSWORD_FAILURE: 'USERS_PASSWORD_RESET_USER_FAILURE',
  CHANGE_PASSWORD_REQUEST: 'USERS_PASSWORD_CHANGE_USER_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'USERS_PASSWORD_CHANGE_USER_SUCCESS',
  CHANGE_PASSWORD_FAILURE: 'USERS_PASSWORD_CHANGE_USER_FAILURE',
  UPDATE_PROFILE_REQUEST: 'UPDATE_PROFILE_REQUEST',
  UPDATE_PROFILE_SUCCESS: 'UPDATE_PROFILE_SUCCESS',
  UPDATE_PROFILE_FAILURE: 'UPDATE_PROFILE_FAILURE',
  LOGOUT: 'USER_LOGOUT',
  REST_WELCOME: 'REST_WELCOME',
};

export default authConstants;
