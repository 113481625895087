import React from 'react';
import styled from '@emotion/styled';
import { Colors, Section, device } from 'utils';
import NewsLetter from 'scenes/Home/components/NewsLetter';
import MuhammedSAW from 'components/MuhammadSaw';
const MuhammedQuot = styled.section`
  text-align: center;
  font-family: 'Shadows Into Light', cursive;
  p {
    font-size: 25px;
    max-width: 50%;
    margin: auto;
    margin-bottom: 70px;
    @media ${device.allMobile} {
      margin-bottom: 20px;
      font-size: 22px;
      max-width: 80%;
    }
  }
  q {
    display: block;
    position: relative;
    max-width: 70%;
    margin: 150px auto 50px;
    font-size: 30px;
    @media ${device.allMobile} {
      margin: 50px auto;
      font-size: 22px;
      max-width: 80%;
    }
    &::before {
      content: '“';
      font-size: 500px;
      line-height: 1;
      color: ${Colors.secondaryColor};
      position: absolute;
      top: -200px;
      left: -150px;
      @media ${device.allMobile} {
        font-size: 50px;
        top: -20px;
        left: -10px;
      }
      @media ${device.tablet} {
        font-size: 300px;
        top: -150px;
        left: -100px;
      }
    }
    &::after {
      content: '„';
      font-size: 500px;
      line-height: 1;
      position: absolute;
      color: ${Colors.secondaryColor};
      bottom: -150px;
      right: -150px;
      @media ${device.allMobile} {
        font-size: 50px;
        bottom: -20px;
        right: -10px;
      }
      @media ${device.tablet} {
        font-size: 300px;
        bottom: -150px;
        right: -100px;
      }
    }
    & + p {
      position: relative;
      &:after {
        content: '';
        width: 300px;
        height: 4px;
        display: inline-block;
        background: ${Colors.secondaryColor};
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -25px;
        @media ${device.allMobile} {
          width: 100px;
          top: -25px;
        }
      }
    }
  }
  span {
    font-size: 150px;
    color: ${Colors.secondaryColor};
    font-weight: bold;
    @media ${device.allMobile} {
      font-size: 70px;
    }
  }
`;
export default function Hadeth() {
  return (
    <Section>
      <MuhammedQuot>
        <q className='txt'>
          Allah Der Mächtige und Erhabene sprach: „Meine Liebe ist verpflichtend
          für diejenigen, die sich um Meinetwillen gegenseitig lieben, die sich
          um Meinetwillen zusammensetzen, die sich um Meinetwillen gegenseitig
          besuchen und die sich um Meinetwillen gegenseitig unterstützen.
        </q>
      </MuhammedQuot>
      <p className="qute"><MuhammedSAW /></p>
    </Section>
  );
}
