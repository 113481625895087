import { generateProjectObject } from 'utils';
import { projectsServices } from 'services';
import projectsConstants from './ProjectsTypes';
import { DonationsActions, AlertActions } from 'redux/actions';
const getMyProjects = () => {
  return (dispatch) => {
    dispatch(getMyProjectsRequest());
    projectsServices
      .getMyProjects()
      .then((res) => {
        return dispatch(getMyProjectsSuccess(res.projects));
      })
      .catch((error) => {
        error.errors.map((err) => dispatch(AlertActions.error(err)));
        return dispatch(getMyProjectsFailure(error.errors));
      });
  };
};

const getMyProjectsRequest = () => ({
  type: projectsConstants.GET_MY_PROJECTS_REQUEST,
});
const getMyProjectsSuccess = (projects) => ({
  type: projectsConstants.GET_MY_PROJECTS_SUCCESS,
  projects,
});
const getMyProjectsFailure = (error) => ({
  type: projectsConstants.GET_MY_PROJECTS_FAILURE,
  error,
});

const getProjects = (perPage, page, ids) => {
  return (dispatch) => {
    dispatch(getProjectsRequest());
    projectsServices
      .getProjects(perPage, page, ids)
      .then((res) => {
        return dispatch(getProjectsSuccess(res.projects));
      })
      .catch((error) => {
        if (error.errors) {
          error.errors.map((err) => dispatch(AlertActions.error(err)));
          return dispatch(getProjectsFailure(error.errors));
        }
      });
  };
};

const getSuccessfullEventProjects = (perPage, page, ids) => {
  return (dispatch) => {
    dispatch(getSuccessfullEventProjectsRequest());
    projectsServices
      .getSuccessfullEventProjects(perPage, page, ids)
      .then((res) => {
        return dispatch(getSuccessfullEventProjectsSuccess(res.projects));
      })
      .catch((error) => {
        error.errors.map((err) => dispatch(AlertActions.error(err)));
        return dispatch(getSuccessfullEventProjectsFailure(error.errors));
      });
  };
};

const getAllSuccessfullEvent = (cat) => {
  return (dispatch) => {
    dispatch(getAllSuccessfullEventsRequest());
    projectsServices
      .getAllSuccessfullEvent(cat)
      .then((res) => {
        return dispatch(getAllSuccessfullEventsSuccess(res.projects));
      })
      .catch((error) => {
        error.errors.map((err) => dispatch(AlertActions.error(err)));
        return dispatch(getAllSuccessfullEventsFailure(error.errors));
      });
  };
};

const getAllSuccessfullEventsRequest = () => ({
  type: projectsConstants.ALL_SUCCESSFULL_EVENT_REQUEST,
});
const getAllSuccessfullEventsSuccess = (projects) => ({
  type: projectsConstants.ALL_SUCCESSFULL_EVENT_SUCCESS,
  projects,
});

const getAllSuccessfullEventsFailure = (error) => ({
  type: projectsConstants.GET_SUCCESSFULL_EVENT_PROJECTS_FAILURE,
  error,
});

const getSuccessfullEventProjectsRequest = () => ({
  type: projectsConstants.GET_SUCCESSFULL_EVENT_PROJECTS_REQUEST,
});
const getSuccessfullEventProjectsSuccess = (projects) => ({
  type: projectsConstants.GET_SUCCESSFULL_EVENT_PROJECTS_SUCCESS,
  projects,
});
const getSuccessfullEventProjectsFailure = (error) => ({
  type: projectsConstants.GET_SUCCESSFULL_EVENT_PROJECTS_FAILURE,
  error,
});

const getEventProjects = (perPage, page, ids) => {
  return (dispatch) => {
    dispatch(getEventProjectsRequest());
    projectsServices
      .getEventProjects(perPage, page, ids)
      .then((res) => {
        return dispatch(getEventProjectsSuccess(res.projects));
      })
      .catch((error) => {
        error.errors.map((err) => dispatch(AlertActions.error(err)));
        return dispatch(getEventProjectsFailure(error.errors));
      });
  };
};

const getEventProjectsRequest = () => ({
  type: projectsConstants.GET_EVENT_PROJECTS_REQUEST,
});
const getEventProjectsSuccess = (projects) => ({
  type: projectsConstants.GET_EVENT_PROJECTS_SUCCESS,
  projects,
});
const getEventProjectsFailure = (error) => ({
  type: projectsConstants.GET_EVENT_PROJECTS_FAILURE,
  error,
});

const getProjectsRequest = () => ({
  type: projectsConstants.GET_PROJECTS_REQUEST,
});
const getProjectsSuccess = (projects) => ({
  type: projectsConstants.GET_PROJECTS_SUCCESS,
  projects,
});
const getProjectsFailure = (error) => ({
  type: projectsConstants.GET_PROJECTS_FAILURE,
  error,
});

const getRandomProjects = (projectId) => {
  return (dispatch) => {
    dispatch(getRandomProjectsRequest());
    projectsServices
      .getRandomProjects(projectId)
      .then((res) => {
        return dispatch(getRandomProjectsSuccess(res.projects.projects));
      })
      .catch((error) => {
        error.errors.map((err) => dispatch(AlertActions.error(err)));
        return dispatch(getRandomProjectsFailure(error.errors));
      });
  };
};

const getRandomProjectsRequest = () => ({
  type: projectsConstants.GET_RANDOM_PROJECTS_REQUEST,
});
const getRandomProjectsSuccess = (projects) => ({
  type: projectsConstants.GET_RANDOM_PROJECTS_SUCCESS,
  projects,
});
const getRandomProjectsFailure = (error) => ({
  type: projectsConstants.GET_RANDOM_PROJECTS_FAILURE,
  error,
});

const getSuccessfulProjects = (perPage, page, ids) => {
  return (dispatch) => {
    dispatch(getSuccessfulProjectsRequest());
    projectsServices
      .getSuccessfulProjects(perPage, page, ids)
      .then((res) => {
        return dispatch(getSuccessfulProjectsSuccess(res.projects));
      })
      .catch((error) => {
        error.errors.map((err) => dispatch(AlertActions.error(err)));
        return dispatch(getSuccessfulProjectsFailure(error.errors));
      });
  };
};

const getSuccessfulProjectsRequest = () => ({
  type: projectsConstants.GET_SUCCESSFUL_PROJECTS_REQUEST,
});
const getSuccessfulProjectsSuccess = (projects) => ({
  type: projectsConstants.GET_SUCCESSFUL_PROJECTS_SUCCESS,
  projects,
});
const getSuccessfulProjectsFailure = (error) => ({
  type: projectsConstants.GET_SUCCESSFUL_PROJECTS_FAILURE,
  error,
});

const getHomeProjects = () => {
  return (dispatch) => {
    dispatch(getHomeProjectsRequest());
    projectsServices
      .getHomeProjects()
      .then((res) => {
        return dispatch(getHomeProjectsSuccess(res.projects));
      })
      .catch((error) => {
        error.errors.map((err) => dispatch(AlertActions.error(err)));
        return dispatch(getHomeProjectsFailure(error.errors));
      });
  };
};

const getHomeProjectsRequest = () => ({
  type: projectsConstants.GET_HOME_PROJECTS_REQUEST,
});
const getHomeProjectsSuccess = (projects) => ({
  type: projectsConstants.GET_HOME_PROJECTS_SUCCESS,
  projects,
});
const getHomeProjectsFailure = (error) => ({
  type: projectsConstants.GET_HOME_PROJECTS_FAILURE,
  error,
});

const setCategoryTemp = (category) => ({
  type: projectsConstants.SET_TEMP_CATEGORY,
  payload: category,
});

const getMySupportedProjects = () => {
  return (dispatch) => {
    dispatch(getMySupportedProjectsRequest());
    projectsServices
      .getMySupportedProjects()
      .then((res) => {
        return dispatch(getMySupportedProjectsSuccess(res.projects));
      })
      .catch((error) => {
        error.errors.map((err) => dispatch(AlertActions.error(err)));
        return dispatch(getMySupportedProjectsFailure(error.errors));
      });
  };
};

const getMySupportedProjectsRequest = () => ({
  type: projectsConstants.GET_MY_SUPPORTED_PROJECTS_REQUEST,
});
const getMySupportedProjectsSuccess = (projects) => ({
  type: projectsConstants.GET_MY_SUPPORTED_PROJECTS_SUCCESS,
  projects,
});
const getMySupportedProjectsFailure = (error) => ({
  type: projectsConstants.GET_MY_SUPPORTED_PROJECTS_FAILURE,
  error,
});
const showProject = (
  url,
  noDonations = false,
  noUpdates = false,
  noRandomProjects = false
) => {
  return (dispatch) => {
    dispatch(showProjectRequest());
    projectsServices
      .showProject(url)
      .then((res) => {
        if (!noUpdates) {
          dispatch(getProjectUpdates(res.project.id));
        }
        if (!noRandomProjects) {
          dispatch(getRandomProjects(res.project.id));
        }
        if (!noDonations) {
          dispatch(DonationsActions.getProjectDonations(res.project.id, 3));
        }
        return dispatch(showProjectSuccess({ ...res.project, url }));
      })
      .catch((error) => {
        error?.errors?.map((err) => dispatch(AlertActions?.error(err)));
        return dispatch(showProjectFailure(error?.errors));
      });
  };
};

const showProjectRequest = () => ({
  type: projectsConstants.SHOW_PROJECT_REQUEST,
});
const showProjectSuccess = (project) => ({
  type: projectsConstants.SHOW_PROJECT_SUCCESS,
  project,
});
const showProjectFailure = (error) => ({
  type: projectsConstants.SHOW_PROJECT_FAILURE,
  error,
});

const createDraft = (project) => {
  return (dispatch) => {
    dispatch(createDraftRequest());
    projectsServices
      .createDraft(project)
      .then((res) => {
        localStorage.setItem(
          'projectDraft',
          JSON.stringify(generateProjectObject(res.project))
        );
        return dispatch(createDraftSuccess(res.project));
      })
      .catch((error) => {
        error.errors.map((err) => dispatch(AlertActions.error(err)));
        return dispatch(createDraftFailure(error.errors));
      });
  };
};

const createDraftRequest = () => ({
  type: projectsConstants.CREATE_DRAFT_REQUEST,
});
const createDraftSuccess = (project) => ({
  type: projectsConstants.CREATE_DRAFT_SUCCESS,
  project,
});
const createDraftFailure = (error) => ({
  type: projectsConstants.CREATE_DRAFT_FAILURE,
  error,
});

const updateDraft = (projectId, project, currentStep) => {
  // TODO:
  const keyLength = Object.keys(project)?.length;
  if (keyLength > 0) {
    if (project?.category_ids) {
      const categoryIds = [String(project?.category_ids)];
      project.category_ids = categoryIds;
    }
  }
  return (dispatch) => {
    dispatch(updateDraftRequest());
    projectsServices
      .updateDraft(projectId, project)
      .then((res) => {
        localStorage.setItem(
          'projectDraft',
          JSON.stringify(generateProjectObject(res.project))
        );
        if (currentStep) {
          dispatch(AlertActions.success('Dein Projekt wurde aktualisiert.'));
        }
        return dispatch(updateDraftSuccess(res.project, currentStep));
      })
      .catch((error) => {
        error.errors.map((err) => dispatch(AlertActions.error(err)));
        return dispatch(updateDraftFailure(error.errors));
      });
  };
};

const updateDraftRequest = () => ({
  type: projectsConstants.UPDATE_DRAFT_REQUEST,
});
const updateDraftSuccess = (project, currentStep) => ({
  type: projectsConstants.UPDATE_DRAFT_SUCCESS,
  project,
  currentStep: currentStep === 10 ? currentStep : currentStep + 1,
});
const updateDraftFailure = (error) => ({
  type: projectsConstants.UPDATE_DRAFT_FAILURE,
  error,
});

const reEditDraft = (projectId, project, currentStep) => {
  return (dispatch) => {
    dispatch(reEditDraftRequest());
    projectsServices
      .reEditDraft(projectId, project)
      .then((res) => {
        localStorage.setItem(
          'projectDraft',
          JSON.stringify(generateProjectObject(res.project))
        );
        if (currentStep) {
          dispatch(AlertActions.success('Your Project Change to Draft'));
        }

        return dispatch(reEditDraftSuccess(res.project));
      })
      .catch((error) => {
        error.errors.map((err) => dispatch(AlertActions.error(err)));
        return dispatch(reEditDraftFailure(error.errors));
      });
  };
};

const reEditDraftRequest = () => ({
  type: projectsConstants.REEDIT_DRAFT_REQUEST,
});
const reEditDraftSuccess = (project) => ({
  type: projectsConstants.REEDIT_DRAFT_SUCCESS,
  project,
  currentStep: 1,
});
const reEditDraftFailure = (error) => ({
  type: projectsConstants.REEDIT_DRAFT_FAILURE,
  error,
});

const uploadImages = (projectId, project, currentStep) => {
  return (dispatch) => {
    dispatch(uploadImagesRequest());
    projectsServices
      .uploadImages(projectId, project)
      .then((res) => {
        localStorage.setItem(
          'projectDraft',
          JSON.stringify(generateProjectObject(res.project))
        );
        return dispatch(uploadImagesSuccess(res.project, currentStep));
      })
      .catch((error) => {
        error.errors.map((err) => dispatch(AlertActions.error(err)));
        return dispatch(uploadImagesFailure(error.errors));
      });
  };
};

const uploadImagesRequest = () => ({
  type: projectsConstants.UPDATE_DRAFT_REQUEST,
});
const uploadImagesSuccess = (project, currentStep) => ({
  type: projectsConstants.UPDATE_DRAFT_SUCCESS,
  project,
  currentStep: currentStep === 10 ? currentStep : currentStep + 1,
});
const uploadImagesFailure = (error) => ({
  type: projectsConstants.UPDATE_DRAFT_FAILURE,
  error,
});

const updateDraftAndImages = (
  projectId,
  projectJSON,
  projectImages,
  currentStep
) => {
  return (dispatch) => {
    dispatch(updateDraftRequest());
    // Send the Images
    projectsServices
      .uploadImages(projectId, projectImages)
      .then((res) => {
        // Send the JSON
        projectsServices
          .updateDraft(projectId, projectJSON)
          .then((res) => {
            localStorage.setItem(
              'projectDraft',
              JSON.stringify(generateProjectObject(res.project))
            );
            if (currentStep) {
              dispatch(
                AlertActions.success('Dein Projekt wurde aktualisiert.')
              );
            }

            return dispatch(updateDraftSuccess(res.project, currentStep));
          })
          .catch((error) => {
            error.errors.map((err) => dispatch(AlertActions.error(err)));
            return dispatch(updateDraftFailure(error.errors));
          });
      })
      .catch((error) => {
        error.errors.map((err) => dispatch(AlertActions.error(err)));
        return dispatch(uploadImagesFailure(error.errors));
      });
  };
};

const updateDraftReward = (projectId, rewardJSON, rewardImage, currentStep) => {
  return async (dispatch) => {
    dispatch(updateDraftRequest());

    try {
      // Send the JSON
      const rewardsCreateReq = await projectsServices.createEditReward(
        projectId,
        rewardJSON
      );

      // Get the ID from the request
      const rewardId = rewardsCreateReq.project.id;

      // Send the Image with the ID
      await projectsServices.uploadRewardImage(rewardId, rewardImage);

      // Send the JSON empty again to get correct data
      const finalUpdateReq = await projectsServices.updateDraft(projectId, {});

      localStorage.setItem(
        'projectDraft',
        JSON.stringify(generateProjectObject(finalUpdateReq.project))
      );
      if (currentStep) {
        dispatch(AlertActions.success('Dein Projekt wurde aktualisiert.'));
      }

      return dispatch(updateDraftSuccess(finalUpdateReq.project, currentStep));
    } catch (error) {
      console.log(error);
    }
  };
};

const deleteDraftReward = (projectId, rewardId, currentStep) => {
  return async (dispatch) => {
    dispatch(updateDraftRequest());

    try {
      // Send Delete
      await projectsServices.deleteReward(rewardId);

      // Send the JSON empty again to get correct data
      const finalUpdateReq = await projectsServices.updateDraft(projectId, {});

      localStorage.setItem(
        'projectDraft',
        JSON.stringify(generateProjectObject(finalUpdateReq.project))
      );
      if (currentStep) {
        dispatch(AlertActions.success('Dein Projekt wurde aktualisiert.'));
      }

      return dispatch(updateDraftSuccess(finalUpdateReq.project, currentStep));
    } catch (error) {
      console.log(error);
    }
  };
};

const submitDraft = (project) => {
  return (dispatch) => {
    dispatch(submitDraftRequest());
    projectsServices
      .submitDraft(project)
      .then((res) => {
        localStorage.setItem(
          'projectDraft',
          JSON.stringify(generateProjectObject(res.project))
        );
        dispatch(AlertActions.success('Dein Projekt wurde eingereicht.'));
        return dispatch(submitDraftSuccess(res.project));
      })
      .catch((error) => {
        error.errors.map((err) => dispatch(AlertActions.error(err)));
        return dispatch(submitDraftFailure(error.errors));
      });
  };
};

const submitDraftRequest = () => ({
  type: projectsConstants.SUBMIT_DRAFT_REQUEST,
});
const submitDraftSuccess = (project) => ({
  type: projectsConstants.SUBMIT_DRAFT_SUCCESS,
  project,
});
const submitDraftFailure = (error) => ({
  type: projectsConstants.SUBMIT_DRAFT_FAILURE,
  error,
});

const checkUrl = (url) => {
  return (dispatch) => {
    dispatch(checkUrlRequest());
    projectsServices
      .checkUrl(url)
      .then((res) => {
        return dispatch(checkUrlSuccess(res.isUniq));
      })
      .catch((error) => {
        error.errors.map((err) => dispatch(AlertActions.error(err)));
        return dispatch(checkUrlFailure(error.errors));
      });
  };
};

const checkUrlRequest = () => ({
  type: projectsConstants.CHECK_URL_REQUEST,
});
const checkUrlSuccess = (isUniq) => ({
  type: projectsConstants.CHECK_URL_SUCCESS,
  isUniq,
});
const checkUrlFailure = (error) => ({
  type: projectsConstants.CHECK_URL_FAILURE,
  error,
});

const getProjectCategories = () => {
  return (dispatch) => {
    dispatch(getProjectCategoriesRequest());
    projectsServices
      .getProjectCategories()
      .then((res) => {
        return dispatch(getProjectCategoriesSuccess(res.categories));
      })
      .catch((error) => {
        error.errors.map((err) => dispatch(AlertActions.error(err)));
        return dispatch(getProjectCategoriesFailure(error.errors));
      });
  };
};

const getProjectAdminCategories = () => {
  return (dispatch) => {
    dispatch(getProjectAdminCategoriesRequest());
    projectsServices
      .getProjectAdminCategories()
      .then((res) => {
        return dispatch(getProjectAdminCategoriesSuccess(res.adminCategories));
      })
      .catch((error) => {
        error?.errors?.map((err) => dispatch(AlertActions.error(err)));
        return dispatch(getProjectAdminCategoriesFailure(error.errors));
      });
  };
};

const getProjectCategoriesRequest = () => ({
  type: projectsConstants.GET_CATEGORIES_REQUEST,
});
const getProjectAdminCategoriesRequest = () => ({
  type: projectsConstants.GET_ADMIN_CATEGORIES_REQUEST,
});
const getProjectAdminCategoriesSuccess = (adminCategories) => ({
  type: projectsConstants.GET_ADMIN_CATEGORIES_SUCCESS,
  adminCategories,
});
const getProjectAdminCategoriesFailure = (error) => ({
  type: projectsConstants.GET_ADMIN_CATEGORIES_FAILURE,
  error,
});

const getProjectCreationCategories = () => {
  return (dispatch) => {
    dispatch(getProjectCreationCategoriesRequest());
    projectsServices
      .getProjectCreationCategories()
      .then((res) => {
        return dispatch(
          getProjectCreationCategoriesSuccess(res.categoriesCreation)
        );
      })
      .catch((error) => {
        error.errors.map((err) => dispatch(AlertActions.error(err)));
        return dispatch(getProjectCreationCategoriesFailure(error.errors));
      });
  };
};

const getProjectCreationCategoriesRequest = () => ({
  type: projectsConstants.GET_CREATION_CATEGORIES_REQUEST,
});
const getProjectCreationCategoriesSuccess = (categoriesCreation) => ({
  type: projectsConstants.GET_CREATION_CATEGORIES_SUCCESS,
  categoriesCreation,
});
const getProjectCreationCategoriesFailure = (error) => ({
  type: projectsConstants.GET_CREATION_CATEGORIES_FAILURE,
  error,
});

const getProjectCategoriesSuccess = (categories) => ({
  type: projectsConstants.GET_CATEGORIES_SUCCESS,
  categories,
});
const getProjectCategoriesFailure = (error) => ({
  type: projectsConstants.GET_CATEGORIES_FAILURE,
  error,
});

const getProjectUpdates = (projectId) => {
  return (dispatch) => {
    dispatch(getProjectUpdatesRequest());
    projectsServices
      .getProjectUpdates(projectId)
      .then((res) => {
        return dispatch(getProjectUpdatesSuccess(res.updates));
      })
      .catch((error) => {
        error.errors.map((err) => dispatch(AlertActions.error(err)));
        return dispatch(getProjectUpdatesFailure(error.errors));
      });
  };
};

const getProjectUpdatesRequest = () => ({
  type: projectsConstants.GET_UPDATES_REQUEST,
});
const getProjectUpdatesSuccess = (updates) => {
  return {
    type: projectsConstants.GET_UPDATES_SUCCESS,
    updates,
  };
};
const getProjectUpdatesFailure = (error) => ({
  type: projectsConstants.GET_UPDATES_FAILURE,
  error,
});

const createProjectUpdates = (update) => {
  return (dispatch) => {
    dispatch(createProjectUpdatesRequest());
    projectsServices
      .createProjectUpdates(update)
      .then((res) => {
        projectsServices
          .getProjectUpdates(res.update.project_id)
          .then((res) => {
            dispatch(
              AlertActions.success(
                'Dein Update wurde auf deiner Projektseite veröffentlicht.'
              )
            );
            return dispatch(createProjectUpdatesSuccess(res.updates));
          });
      })
      .catch((error) => {
        error.errors.map((err) => dispatch(AlertActions.error(err)));
        return dispatch(createProjectUpdatesFailure(error.errors));
      });
  };
};

const createProjectUpdatesRequest = () => ({
  type: projectsConstants.CREATE_UPDATE_REQUEST,
});
const createProjectUpdatesSuccess = (updates) => ({
  type: projectsConstants.CREATE_UPDATE_SUCCESS,
  updates,
});
const createProjectUpdatesFailure = (error) => ({
  type: projectsConstants.CREATE_UPDATE_FAILURE,
  error,
});

const deleteProjectUpdates = (projectId, updateId) => {
  return (dispatch) => {
    dispatch(deleteProjectUpdatesRequest());
    projectsServices
      .deleteProjectUpdates(updateId)
      .then(() => {
        projectsServices.getProjectUpdates(projectId).then((res) => {
          dispatch(AlertActions.success('Your Update has been Deleted'));
          return dispatch(deleteProjectUpdatesSuccess(res.updates));
        });
      })
      .catch((error) => {
        error.errors.map((err) => dispatch(AlertActions.error(err)));
        return dispatch(deleteProjectUpdatesFailure(error.errors));
      });
  };
};

const deleteProjectUpdatesRequest = () => ({
  type: projectsConstants.DELETE_UPDATE_REQUEST,
});
const deleteProjectUpdatesSuccess = (updates) => ({
  type: projectsConstants.DELETE_UPDATE_SUCCESS,
  updates,
});
const deleteProjectUpdatesFailure = (error) => ({
  type: projectsConstants.DELETE_UPDATE_FAILURE,
  error,
});

const getProjectStatus = (id) => {
  return (dispatch) => {
    dispatch(getProjectStatusRequest());
    projectsServices
      .getProjectStatus(id)
      .then((res) => {
        return dispatch(getProjectStatusSuccess(res.projectStatus));
      })
      .catch((error) => {
        error?.errors?.map((err) => dispatch(AlertActions.error(err)));
        return dispatch(getProjectStatusFailure(error.errors));
      });
  };
};

const getProjectStatusRequest = () => ({
  type: projectsConstants.GET_PROJECT_STATUS_REQUEST,
});
const getProjectStatusSuccess = (projectStatus) => ({
  type: projectsConstants.GET_PROJECT_STATUS_SUCCESS,
  projectStatus,
});
const getProjectStatusFailure = (error) => ({
  type: projectsConstants.GET_PROJECT_STATUS_FAILURE,
  error,
});

const removeEdit = (id) => {
  return (dispatch) => {
    dispatch(removeEditRequest());
    projectsServices
      .removeEdit(id)
      .then((res) => {
        return dispatch(removeEditSuccess(res.projectStatus));
      })
      .catch((error) => {
        error.errors.map((err) => dispatch(AlertActions.error(err)));
        return dispatch(removeEditFailure(error.errors));
      });
  };
};

const removeEditRequest = () => ({
  type: projectsConstants.REMOVE_EDIT_REQUEST,
});
const removeEditSuccess = (projectStatus) => ({
  type: projectsConstants.REMOVE_EDIT_SUCCESS,
  projectStatus,
});
const removeEditFailure = (error) => ({
  type: projectsConstants.REMOVE_EDIT_FAILURE,
  error,
});

const clearProject = () => {
  return (dispatch) => {
    dispatch(clearProjectSuccess());
  };
};
const clearProjectSuccess = () => ({
  type: projectsConstants.CLEAR_PROJECT_SUCCESS,
});

const handleSteps = (step) => {
  return (dispatch) => {
    dispatch({
      type: projectsConstants.HANDLE_STEPS,
      step,
    });
  };
};

const ProjectActions = {
  getMyProjects,
  getMySupportedProjects,
  getSuccessfulProjects,
  createDraft,
  updateDraft,
  uploadImages,
  updateDraftAndImages,
  updateDraftReward,
  deleteDraftReward,
  submitDraft,
  checkUrl,
  getProjectCategories,
  getProjectAdminCategories,
  getProjectCreationCategories,
  clearProject,
  showProject,
  getProjects,
  getProjectUpdates,
  createProjectUpdates,
  deleteProjectUpdates,
  handleSteps,
  reEditDraft,
  getProjectStatus,
  getRandomProjects,
  getHomeProjects,
  removeEdit,
  getEventProjects,
  getSuccessfullEventProjects,
  setCategoryTemp,
  getAllSuccessfullEvent,
};

export default ProjectActions;
