import React, { Component } from 'react';
import 'moment/locale/de';
import locale from 'antd/es/date-picker/locale/de_DE';
import { Form, Input, DatePicker, Spin, Select } from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import pickBy from 'loadsh/pickBy';
import identity from 'loadsh/identity';
import isEmpty from 'loadsh/isEmpty';
import {
  Container,
  PrimaryButton,
  formLayout,
  formValidateMessages,
  H2,
  H1,
  FormWrapper,
  dateFormat,
  eighteenYearsFromNow,
} from 'utils';
import { AddressForm } from 'components';
import { ProjectsActions } from 'redux/actions';
import styled from '@emotion/styled';

const FormItem = Form.Item;
class AboutYou extends Component {
  static propTypes = {
    updateDraft: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    projectId: PropTypes.string,
    currentStep: PropTypes.number.isRequired,
    setCanMove: PropTypes.func.isRequired,
    showModal: PropTypes.bool.isRequired,
    selectedStep: PropTypes.number.isRequired,
    setSubmitFromModal: PropTypes.func.isRequired,
    handleOnChange: PropTypes.func,
    handleInputChange: PropTypes.func,
    andere: PropTypes.bool,
  };
  constructor(props) {
    super(props);

    this.state = {
      andere: false,
      inputValue: '',
      selectedValue: '', // New state for the input field value
    };

    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  onFinish = (values) => {
    const {
      updateDraft,
      projectId,
      currentStep,
      setCanMove,
      showModal,
      selectedStep,
      setSubmitFromModal,
    } = this.props;
    values.birthday = Number(values.initiator_birthday.format('X'));

    const aboutYouObj = {
      ...values,
      initiator_city: values.city,
      initiator_country: values.country,
      initiator_street: values.street,
      initiator_zip_code: values.postcode,
      initiator_building_number: values.building_number,
      learned_about: this.state.andere
        ? this.state.inputValue
        : this.state.selectedValue,
    };
    if (showModal) {
      updateDraft(projectId, aboutYouObj, selectedStep - 1);
      setSubmitFromModal(false);
    } else {
      updateDraft(projectId, aboutYouObj, currentStep);
    }

    setCanMove(true);
    this.props.handleSteps(7);
  };

  handleOnChange = (value) => {
    if (value === 'Andere') {
      this.setState({ andere: true, selectedValue: "", inputValue: '' });
    } else {
      this.setState({ andere: false, selectedValue: value, inputValue: '' });
    }
  };

  handleInputChange = (e) => {
    this.setState({ inputValue: e.target.value });
  };

  render() {
    const { project, loading, setCanMove, projectId } = this.props;
    const { andere, inputValue, selectedValue } = this.state;

    return (
      <Container className="inside-form-container">
        <Spin spinning={loading}>
          <H1>Persönliche Daten</H1>
          <p>
          Stelle dich vor. Diese Daten werden gemäß den Datenschutzbestimmungen bei uns gespeichert - niemand wird deine Daten einsehen können.
          </p>
          { !loading && (
            <Form
              {...formLayout}
              name="project-details"
              onFinish={this.onFinish}
              onChange={() => setCanMove(false)}
              validateMessages={formValidateMessages}
              initialValues={
                projectId
                  ? project
                    ? pickBy(project.aboutYou, identity)
                    : {}
                  : ''
              }
            >
              <div>
                <H2>Persönliche Informationen</H2>
                <FormWrapper>
                  <>
                    <FormItem
                      name="initiator_first_name"
                      label="Vorname"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input />
                    </FormItem>
                    <FormItem
                      name="initiator_last_name"
                      label="Nachname"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input />
                    </FormItem>
                    <FormItem
                      name="initiator_birthday"
                      label="Geburtstag"
                      extra="mind. 18 Jahre alt"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <DatePicker
                        locale={locale}
                        format={dateFormat}
                        disabledDate={(d) =>
                          !d || d.isAfter(eighteenYearsFromNow)
                        }
                      />
                    </FormItem>
                    <FormItem
                      name="initiator_phone_number"
                      label="Mobilenummer"
                      extra="Damit commonsplace dich erreichen kann. Diese Information ist nicht öffentlich."
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input />
                    </FormItem>
                    <AddressForm required={true} />
                    <FormItem
                      name="initiator_email"
                      label="E-Mail Adresse"
                      rules={[
                        {
                          required: true,
                          message: 'Bitte gib deine E-Mail Adresse ein.',
                        },
                      ]}
                    >
                      <Input type="email" />
                    </FormItem>
                    <FormItem
                      name="initiator_nationality"
                      label="Staatsangehörigkeit"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input />
                    </FormItem>
                    <div>
                        <FormItem
                          name="learned_about"
                          label="Über commonsplace erfahren durch:"
                          rules={[
                            {
                              required: true,
                              message: 'erforderlich',
                            },
                          ]}
                        >
                          <Select
                            defaultValue="Please Select"
                            options={[
                              {
                                value: 'Please Select',
                                label: 'Please Select',
                                disabled: true,
                              },
                              { value: 'Social Media', label: 'Social Media' },
                              {
                                value: 'Freunde and Famlie',
                                label: 'Freunde and Famlie',
                              },
                              { value: 'WhatsApp', label: 'WhatsApp' },
                              { value: 'Newsletter', label: 'Newsletter' },
                              { value: 'Google Suche', label: 'Google Suche' },
                              {
                                value:
                                'Printmedien wie Plakat oder Flyer von commonsplace',
                                label: 'Printmedien wie Plakat oder Flyer',
                              },
                              { value: 'Andere', label: 'Andere' },
                            ]}
                            style={{ width: '100%' }}
                            onChange={this.handleOnChange}
                            value={selectedValue}
                            />
                        </FormItem>
                          {andere && (
                            <FormItem
                              name="learn_about"
                              label="Über commonsplace erfahren durch:"
                              rules={[
                                {
                                  required: true,
                                  message: 'erforderlich',
                                },
                              ]}
                            >
                              <Input
                                value={inputValue}
                                onChange={this.handleInputChange}
                              />
                            </FormItem>
                          )} 
                    </div>
                  </>
                </FormWrapper>
              </div>
              <FormItem>
                <PrimaryButton
                  type="primary"
                  htmltype="submit"
                  minWidth="150px"
                  id="submit-button"
                >
                  Speichern
                </PrimaryButton>
              </FormItem>
            </Form>
          )}
        </Spin>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const { project, loading, errors, currentStep } = state.projects;
  return {
    project,
    loading,
    errors,
    currentStep,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleSteps: (step) => dispatch(ProjectsActions.handleSteps(step)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AboutYou);
