import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Colors } from 'utils';
import device from './device';

const Title = styled.h1`
  display: inline-block;
  position: relative;
  font-size: 65px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 0;
  line-height: 1;
  color: ${(props) => (props.color ? `${props.color}` : Colors.white)};
  @media ${device.allMobile} {
    font-size: 30px;
  }
  @media ${device.laptop} {
    font-size: 30px;
  }
`;
export default function TitleBanner(props) {
  return <Title {...props}>{props.children}</Title>;
}

TitleBanner.propTypes = {
  children: PropTypes.string,
  color: PropTypes.string,
};
