import React from 'react';
import { Colors, device } from 'utils';
import styled from '@emotion/styled';

const RecommendCoFundingsWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background: ${Colors.white};
  margin: auto;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  margin-bottom: 30px;
  padding: 30px 40px;
  @media ${device.allMobile} {
    flex-wrap: wrap;
    width: 90%;
    padding: 15px 0;
  }
  .start-project {
    &__image {
      width: 50%;
      display: inline-block;
      position: relative;
      @media ${device.allMobile} {
        width: 100%;
      }
      & > span {
        display: inline-block;
        height: 100%;
        width: 80%;
        height: 300px;
        background: ${Colors.grey};
        position: relative;
        border-radius: 10px;
        @media ${device.allMobile} {
          width: 100%;
          margin-bottom: 15px;
        }
        span {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateY(-50%) translateX(-50%);
        }
      }
    }
    &__content {
      width: 50%;
      display: inline-block;
      padding: 0 0 0 35px;
      text-align: center;
      max-width: 400px;
      margin-left: auto;
      @media ${device.allMobile} {
        width: 100%;
        padding: 0;
      }
      p {
        text-align: justify;
        @media ${device.allMobile} {
          font-size: 16px;
        }
      }
      button {
        border-bottom: none;
        padding: 10px;
        background: ${Colors.secondaryColor};
        border: 1px solid ${Colors.secondaryColor};
        color: ${Colors.white};
        text-align: center;
        border-radius: 12px;
        cursor: pointer;
        margin-bottom: 20px;
        padding: 15px 20px;
        min-width: 200px;
        margin-top: 20px;
        font-size: 18px;
        &:hover {
          background: ${Colors.primaryColor};
          border: 1px solid ${Colors.primaryColor};
        }
      }
    }
    &__title {
      position: absolute;
      right: -30px;
      top: 50%;
      transform: translateY(-55%);
      @media ${device.allMobile} {
        position: static;
        transform: translateY(0);
        text-align: center;
      }
      p,
      h1 {
        margin: 0;
        line-height: 1;
        max-width: 200px;
        @media ${device.allMobile} {
          max-width: none;
        }
      }
      h1 {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 39px;
        word-break: break-word;
        @media ${device.allMobile} {
          font-size: 24px;
        }
      }
      p {
        margin-bottom: 0;
        text-transform: uppercase;
        font-size: 22px;
        @media ${device.allMobile} {
          font-size: 18px;
          margin-bottom: 10px;
        }
      }
    }
  }
`;
export default function RecommendCoFundings() {
  return (
    <RecommendCoFundingsWrapper>
      <div className="start-project__image">
        <span>
          <span>Photo 1*1</span>
        </span>
        <div className="start-project__title">
          <p>WE RECOMMEND</p>
          <h1>COFUNDINGSUPPORT FOR YOUR PROJECT</h1>
        </div>
      </div>
      <div className="start-project__content">
        <p>
          Money is not everything, but enough of it is essential. That's why we
          and our co-operations around the clock make sure that we provide extra
          funding for your project in addition to your crowd. This is what we
          call cofunding. Have a look if there is something suitable for you.
        </p>
        <button>Take a Look</button>
      </div>
    </RecommendCoFundingsWrapper>
  );
}
