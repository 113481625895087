import React from 'react';
import { BaseLayout } from 'components';

import { ShortBackground } from 'images';
import { Container, H1, SvgWrapper, isMobile, device } from 'utils';
import styled from '@emotion/styled';
import { FeesIcon } from 'images';
import { Helmet } from 'react-helmet';

const FeesPageWrapper = styled.div`
  .intro {
    text-align: center;
    margin-bottom: 100px;
    margin-top: 100px;
    h2 {
      margin-top: 50px;
      margin-bottom: 20px;
    }
    .icon {
      position: relative;
      left: -55px;
      margin-bottom: 50px;
      @media ${device.allMobile} {
        left: -24px;
        margin-bottom: 25px;
      }
    }
    p {
      font-size: 22px;
      max-width: 80%;
      margin: auto;
      text-align: left;
      @media ${device.allMobile} {
        max-width: 100%;
      }
      span {
        font-weight: bold;
      }
    }
  }
`;
export default function FeesPage() {
  return (
    <div>
      <Helmet>
        <meta
          name="description"
          content="Transparente Gebühren bei commonsplace. Alles über faire und klare Gebührenstrukturen für erfolgreiche Projektfinanzierungen erfahren."
        />
      </Helmet>
    <BaseLayout
      maxHeightProps="500px"
      maxHeightMobileProps="250px"
      container={false}
      img={ShortBackground}
      bannerComponent={
        <div style={{ textAlign: 'center' }}>
          <H1>Gebühren</H1>
        </div>
      }
    >
      <Container>
        <FeesPageWrapper>
          <div className="intro">
            <h1>
              commonsplace ist ein Ort, an dem die Community sich gegenseitig
              unterstützt und das Gemeinwohl mit großartigen Ideen bereichert
            </h1>
            <SvgWrapper
              width={isMobile() ? '100%' : '600px'}
              height="auto"
              className="icon"
            >
              <FeesIcon />
            </SvgWrapper>
            <p>
              commonsplace arbeitet wirkungsorientiert und ist auf das
              Gemeinwohl aller ausgerichtet. Als junges Unternehmen liegt uns
              Qualität, eine kontinuierliche Verbesserung und eine Steigerung
              unserer Plattform sehr am Herzen. Damit wir eine qualitative
              Plattform zur Erfüllung der Bedürfnisse der muslimischen Community
              in Deutschland anbieten können, entstehen für den Projektersteller
              Gebühren. Damit betreiben wir die Plattform. Nur so können wir sie
              weiterentwickeln, bekannter machen und die Sicherheit
              gewährleisten.
            </p>
            <p>
              commonsplace ist unabhängig - weder die Plattform noch die
              Initiatoren dieser Plattform werden von Dritten finanziert. Und
              dabei soll es auch bleiben! Genau wie jedes von euch initiierte
              Projekt innerhalb von commonsplace, trägt die Community auch
              commonsplace.
            </p>
            <br/>
            <p>
            Die Gebührenstruktur setzt sich wie folgt zusammen: Circa 3-4% der Finanzierungssumme entfallen als Gebühren, die von den Zahlungsdienstleistern Stripe und PayPal erhoben werden. Zusätzlich erhebt commonsplace eine Nutzungsgebühr in Höhe von 5%. Diese Kosten werden nicht von den Unterstützern, sondern vom Projektinitiator getragen und automatisch von der Finanzierungssumme abgezogen.*
            </p>
            <br/><p>Die Nutzungsgebühr** ermöglicht den Betrieb unserer Plattform und deckt zusätzliche Kosten ab, die potenziell entstehen, wenn beispielsweise Unterstützer ihre Zahlungen widerrufen oder Banken die Gelder anfechten. Diese Kosten werden direkt commonsplace in Rechnung gestellt. Wir möchten den Nutzern transparent machen, dass bei jedem Projekt signifikante Kosten entstehen können, die durch solche Vorfälle verursacht werden.</p>
            <br/><p>Unser langfristiges Ziel ist es, die Nutzungsgebühren auf 0% zu reduzieren, sobald commonsplace eine solide und breite Nutzerbasis etabliert hat und wir eine nachhaltige Finanzierung der Plattform gewährleisten können.</p>
            <br/><p>*Bitte berücksichtige diese Gebühren bei der Berechnung deiner gewünschten Finanzierungssumme, um das benötigte Ziel zu erreichen.</p>
            <br/><p>**Falls du Bedenken bezüglich der Nutzungsgebühren hast, kontaktiere uns, bevor das Projekt startet. In individuellen Fällen bieten wir Sonderkonditionen an.</p>
            <p>
              Berechne diese Gebühren in deine Fundingsumme mit ein, um deine vollständige Fundingsumme zu erhalten.
            </p>
            <h2>Widerrufsrecht</h2>
            <p>
              Bitte bedenke, dass der Spender ein zweiwöchiges Widerrufsrecht ab
              der Zielerreichung deines Fundingziels besitzt. Sollte der Spender
              sein Widerrufsrecht in Anspruch nehmen, wird der Spendenbetrag des
              Widerrufenden von der gesammelten Spendensumme abgezogen. Keine
              Sorge, dabei entstehen keine Kosten für Dich!
            </p>
          </div>
        </FeesPageWrapper>
      </Container>
    </BaseLayout></div>
  );
}
