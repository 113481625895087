import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Select, Modal, Spin, Button, Input } from 'antd';
import { PrimaryButton } from 'utils';
import { CheckOutlined, MinusCircleOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { projectsServices } from 'services';
import CurrencyInput from 'react-currency-input-field';
import ChooseRewardCard from './ChooseRewardCard';
const { Option } = Select;

const FormItem = Form.Item;

const ChooseRewardsWrapper = styled.div`
  .ant-select-selector {
    border-radius: 10px !important;
  }
  .ant-form-item-label {
    white-space: normal;
    label {
      height: fit-content;
    }
  }
`;

export class ChooseRewards extends Component {
  static propTypes = {
    projectId: PropTypes.string.isRequired,
    originalRewardId: PropTypes.number.isRequired,
    donationLessThenFive: PropTypes.bool.isRequired,
    setAllRewards: PropTypes.func.isRequired,
    setSelectedRewards: PropTypes.func.isRequired,
    setAddressRequired: PropTypes.func.isRequired,
    allRewards: PropTypes.any.isRequired,
    selectedRewards: PropTypes.any.isRequired,
    handleCLientSecret: PropTypes.func.isRequired,
    formRef: PropTypes.object.isRequired,
    isTicketable: PropTypes.bool.isRequired,
  };

  state = {
    showModal: false,
    loading: false,
    rewardIdsArray: [],
    remainingTicketslimit: 0,
    limitReach: false,
  };
  componentDidMount = async () => {
    const { setAllRewards } = this.props;
    let rewards = [];
    // Send Request to get the rewards
    try {
      rewards = await projectsServices.getProjectRewards(this.props.projectId); // Change to 1 for test
      // Remove Rewards that have finished
      rewards = rewards.filter((reward) => {
        return !(
          reward.max_order_count &&
          reward.order_count &&
          reward.order_count >= reward.max_order_count
        );
      });
      this.setState({ loading: false });
      setAllRewards(rewards);

      // Look for id
      if (this.props.originalRewardId !== 0) {
        const elementIndex = rewards
          .map(function (x) {
            return x.id;
          })
          .indexOf(this.props.originalRewardId);

        // Add to selected and remove from allRewards
        if (elementIndex !== -1) {
          await this.handleChooseReward(elementIndex);
        }
      }
    } catch (error) { }
  };

  //   handleChooseReward = async (index) => {
  //     const selectedReward = { ...this.props.allRewards[index] };
  //       // Remove it from the All rewards
  //     if (selectedReward.no_multi_order) {
  //       let removedAllRewards = [...this.props.allRewards];
  //       removedAllRewards.splice(index, 1);
  //       this.props.setAllRewards([...removedAllRewards]);
  //     }
  //     // Set the State to loading
  //     this.setState({
  //       loading: true,
  //     });

  //     try {
  //       // Get the Questions of the Reward
  //       const questionsRequest = await projectsServices.getRewardQuestions(
  //         selectedReward.id
  //       );
  //       // Set the Questions and Address of the Object
  //       // Create the Answers in the object
  //       selectedReward.questions = questionsRequest[selectedReward.id];
  //       selectedReward.answers = new Array(selectedReward.questions.length).fill(
  //         ''
  //       );
  //       selectedReward.address_required = questionsRequest.address_required;
  //       // Set Address Required
  //       this.props.setAddressRequired([
  //         ...this.props.selectedRewards,
  //         selectedReward,
  //       ]);
  //       // Add it to the Selected Rewards
  //       this.props.setSelectedRewards([
  //         ...this.props.selectedRewards,
  //         selectedReward,
  //       ]);
  //       // Set loading to false and close modal
  //       this.setState({
  //         loading: false,
  //         showModal: false,
  //       });

  //       if (selectedReward.id) {

  //         this.setState((prevState) => ({
  //           rewardIdsArray: [...prevState.rewardIdsArray, selectedReward.id],
  //         }));
  //         const url = new URL(window.location.href);
  //         const parameters = url.searchParams;
  //         const rewardIdsArray = this.state.rewardIdsArray;
  //         parameters.set('rewardId', rewardIdsArray.join(','));
  //         window.history.replaceState({}, '', url.toString());
  //       }
  //     } catch (error) { }
  // };

  handleChooseReward = async (index) => {
    const selectedReward = { ...this.props.allRewards[index] };
    let max_count = selectedReward?.max_order_count;
    let booked = selectedReward?.ordered_reward_count;
    let limit = max_count - booked;

    // Check if max_count and booked are not null
    if (limit != null && max_count != null && booked != null) {

      // Check if limit is greater than remainingTicketslimit
      if (limit <= this.state.remainingTicketslimit) {
        this.setState({ limitReach: true });
        return;
      }
      // Increment remainingTicketslimit
      this.setState((prevState) => ({
        remainingTicketslimit: prevState.remainingTicketslimit + 1,
        limitReach: false,
      }));

      this.setState({ limitReach: false });
    }

    if (selectedReward.no_multi_order) {
      let removedAllRewards = [...this.props.allRewards];
      removedAllRewards.splice(index, 1);
      this.props.setAllRewards([...removedAllRewards]);
    }

    // Set the State to loading
    this.setState({
      loading: true,
    });

    try {
      // Get the Questions of the Reward
      const questionsRequest = await projectsServices.getRewardQuestions(
        selectedReward.id
      );

      // Set the Questions and Address of the Object
      // Create the Answers in the object
      selectedReward.questions = questionsRequest[selectedReward.id];
      selectedReward.answers = new Array(selectedReward.questions.length).fill(
        ''
      );

      selectedReward.address_required = questionsRequest.address_required;

      // Set Address Required
      this.props.setAddressRequired([
        ...this.props.selectedRewards,
        selectedReward,
      ]);

      // Add it to the Selected Rewards
      this.props.setSelectedRewards([
        ...this.props.selectedRewards,
        selectedReward,
      ]);

      // Set loading to false and close modal
      this.setState({
        loading: false,
        showModal: false,
      });

      if (selectedReward.id) {
        this.setState((prevState) => ({
          rewardIdsArray: [...prevState.rewardIdsArray, selectedReward.id],
        }));

        const url = new URL(window.location.href);
        const parameters = url.searchParams;
        const rewardIdsArray = this.state.rewardIdsArray;
        parameters.set('rewardId', rewardIdsArray.join(','));
        window.history.replaceState({}, '', url.toString());
      }
    } catch (error) {
      // Handle error if needed
    }
  };

  handleRemoveReward = (index) => {
    const { isTicketable } = this.props;
    if (isTicketable && this.props.selectedRewards.length === 1) return;
    // Loading for a good effect
    this.setState({
      loading: true,
    });
    const selectedReward = { ...this.props.selectedRewards[index] };
    let removedSelectedRewards = [...this.props.selectedRewards];
    removedSelectedRewards.splice(index, 1);
    this.props.setSelectedRewards([...removedSelectedRewards]);
    let max_count = selectedReward?.max_order_count;
    let booked = selectedReward?.ordered_reward_count;
    let limit = max_count - booked;
    // Check if max_count and booked are not null
    if (limit != null && max_count != null && booked != null) {
      // Increment remainingTicketslimit
      this.setState((prevState) => ({
        remainingTicketslimit: prevState.remainingTicketslimit - 1,
        limitReach: false,
      }));

      this.setState({ limitReach: false });
    }
    // Add it to AllRewards
    if (selectedReward.no_multi_order) {
      this.props.setAllRewards([...this.props.allRewards, selectedReward]);
    }
    // Check if Address is Required
    this.props.setAddressRequired([...removedSelectedRewards]);

    // End Load
    this.setState({
      loading: false,
    });
    this.props.formRef.current.resetFields();

    const url = new URL(window.location.href);
    const parameters = url.searchParams;
    const rewardIdsArray = this.state.rewardIdsArray;
    // Check if the selectedReward.id already exists in the rewardIdsArray
    const existingIndex = rewardIdsArray.indexOf(selectedReward.id);
    if (existingIndex !== -1) {
      // If the ID exists, remove it from the array
      rewardIdsArray.splice(existingIndex, 1);
    }
    // Update the URL with the updated rewardIdsArray
    parameters.set('rewardId', rewardIdsArray.join(','));
    window.history.replaceState({}, '', url.toString());
  };

  handleInputChange = (value, questionIndex, rewardIndex) => {
    // Get the Selected Rewards
    const editedSelectedRewards = [...this.props.selectedRewards];
    // Get the Value and set the reward with the new value
    editedSelectedRewards[rewardIndex].answers[questionIndex] = value;
    // Replace the Reward with the Edited Version of It
    this.props.setSelectedRewards([...editedSelectedRewards]);
    // Store for paypal after redirect final transfer_amount apis
    localStorage.setItem('answers', JSON.stringify([...editedSelectedRewards]));
  };

  render() {
    const { isTicketable } = this.props;
    const searchParams = new URLSearchParams(document.location.search);
    const payment_id = searchParams.get('paymentId');
    const payment_intent = searchParams.get('payment_intent');
    return (
      <Spin spinning={this.state.loading}>
        <ChooseRewardsWrapper>
          {/* Rewards Questions Form  */}

          {payment_id != undefined || payment_intent != undefined
            ? JSON.parse(localStorage.getItem('answers'))?.map((reward, i) => {
              return (
                <div key={i} style={{ margin: '30px 0px' }}>
                  <FormItem
                    name={`reward--${i}`}
                    className="donation-input"
                    labelCol={{ span: 18 }}
                    wrapperCol={{ span: 6 }}
                    label={
                      <div>
                        {reward.title}{' '}
                        <span>
                          <Button
                            type="link"
                            danger
                            size="large"
                            onClick={() => this.handleRemoveReward(i)}
                          >
                            <MinusCircleOutlined
                              style={{ fontSize: '18px' }}
                            />
                          </Button>
                        </span>
                      </div>
                    }
                    style={{
                      border: '2px solid black',
                      borderRadius: '20px',
                    }}
                  >
                    <div className="currency-input">
                      <CurrencyInput
                        style={{ width: '100%' }}
                        disabled={true}
                        allowNegativeValue={false}
                        decimalSeparator=","
                        groupSeparator="."
                        placeholder="0,00"
                        value={parseFloat(reward.price).toFixed(2)}
                      />
                    </div>
                  </FormItem>
                  {/* Questions */}
                  {/* {reward.questions.length !== 0 && 'Frage zu diesem Goody:'}
                {reward.questions.map((question, j) => {
                  return (
                    <div key={j}>
                      <FormItem
                        name={`reward--${i}-question--${j}`}
                        style={{ margin: '10px 0px' }}
                        rules={[
                          {
                            required: true,
                            message: 'Deine Antwort ist erforderlich',
                          },
                        ]}
                      >

                        {question.length === 1 || question[1] === '' ? (
                          <Input
                            placeholder={question[0]}
                            size="large"
                            style={{ borderRadius: '10px' }}
                            onChange={(e) => {
                              this.handleInputChange(e.target.value, j, i);
                            }}
                          />
                        ) : (
                          <Select
                            placeholder={question[0]}
                            onChange={(value) => {
                              this.handleInputChange(value, j, i);
                            }}
                            size="large"
                          >
                            {question.map((answer, k) => {
                              return k !== 0 ? (
                                <Option key={k} value={answer}>
                                  {answer}
                                </Option>
                              ) : null;
                            })}
                          </Select>
                        )}
                      </FormItem>
                    </div>
                  );
                })} */}
                </div>
              );
            })
            : this.props.selectedRewards?.map((reward, i) => {
              return (
                <div key={i} style={{ margin: '30px 0px' }}>
                  <FormItem
                    name={`reward--${i}`}
                    className="donation-input"
                    labelCol={{ span: 18 }}
                    wrapperCol={{ span: 6 }}
                    label={
                      <div>
                        {reward.title}{' '}
                        {isTicketable ? (
                          this.props.selectedRewards.length > 1 && (
                            <span>
                              <Button
                                type="link"
                                danger
                                size="large"
                                onClick={() => this.handleRemoveReward(i)}
                              >
                                <MinusCircleOutlined
                                  style={{ fontSize: '18px' }}
                                />
                              </Button>
                            </span>
                          )
                        ) : (
                          <span>
                            <Button
                              type="link"
                              danger
                              size="large"
                              onClick={() => this.handleRemoveReward(i)}
                            >
                              <MinusCircleOutlined
                                style={{ fontSize: '18px' }}
                              />
                            </Button>
                          </span>
                        )}
                      </div>
                    }
                    style={{
                      border: '2px solid black',
                      borderRadius: '20px',
                    }}
                  >
                    <div className="currency-input">
                      <CurrencyInput
                        style={{ width: '100%' }}
                        disabled={true}
                        allowNegativeValue={false}
                        decimalSeparator=","
                        groupSeparator="."
                        placeholder="0,00"
                        value={parseFloat(reward.price).toFixed(2)}
                      />
                    </div>
                  </FormItem>
                  {/* Questions */}
                  {reward.questions.length !== 0 &&
                    `Frage zu diesem ${isTicketable ? 'Ticket' : 'Goody'}:`}
                  {reward.questions.map((question, j) => {
                    return (
                      <div key={j}>
                        <FormItem
                          name={`reward--${i}-question--${j}`}
                          style={{ margin: '10px 0px' }}
                          rules={[
                            {
                              required: true,
                              message: 'Deine Antwort ist erforderlich',
                            },
                          ]}
                        >
                          {/* A Select or An Input depending on the Question */}
                          {question.length === 1 || question[1] === '' ? (
                            <Input
                              placeholder={question[0]}
                              size="large"
                              style={{ borderRadius: '10px' }}
                              onChange={(e) => {
                                this.handleInputChange(e.target.value, j, i);
                              }}
                            />
                          ) : (
                            <Select
                              placeholder={question[0]}
                              onChange={(value) => {
                                this.handleInputChange(value, j, i);
                              }}
                              size="large"
                            >
                              {question.map((answer, k) => {
                                return k !== 0 ? (
                                  <Option key={k} value={answer}>
                                    {answer}
                                  </Option>
                                ) : null;
                              })}
                            </Select>
                          )}
                        </FormItem>
                      </div>
                    );
                  })}
                </div>
              );
            })}

          {/* Choose More Rewards */}
          {this.props.allRewards.length > 0 ? (
            <div>
              <PrimaryButton
                style={{ marginBottom: '25px' }}
                className="Gegenleistung-button"
                type="primary"
                // minWidth={'250px'}
                onClick={() => {
                  this.setState({ showModal: true });
                  // this.props.handleCLientSecret(null);
                }}
              >
                {this.props.selectedRewards.length > 0 && 'Weitere'}{' '}
                {isTicketable ? 'Tickets ' : 'Goodies '}
                hinzufügen
              </PrimaryButton>
              <Modal
                title={
                  <div
                    style={{
                      fontSize: '24px',
                      textAlign: 'center',
                      margin: '10px',
                    }}
                  >
                    Goodies
                  </div>
                }
                visible={this.state.showModal}
                onOk={() => this.setState({ showModal: false })}
                onCancel={() => this.setState({ showModal: false })}
                okButtonProps={{ shape: 'round' }}
                footer={[
                  <Button
                    key="submit"
                    size="large"
                    onClick={() => this.setState({ showModal: false })}
                  >
                    <CheckOutlined />
                  </Button>,
                ]}
              >
                {this.props.allRewards.map((reward, i) => {
                  return (
                    <ChooseRewardCard
                      reward={reward}
                      index={i}
                      key={i}
                      handleChooseReward={this.handleChooseReward}
                    />
                  );
                })}
                {this.state.limitReach && (
                  <p
                    style={{
                      color: 'red',
                      display: 'flex',
                      justifyContent: 'center',
                      padding: '20px 0',
                    }}
                  >
                    {isTicketable
                      ? 'Ticket-Limit erreicht'
                      : 'Goodie-Limit erreicht'}
                  </p>
                )}
              </Modal>
            </div>
          ) : null}
        </ChooseRewardsWrapper>
      </Spin>
    );
  }
}

export default ChooseRewards;
