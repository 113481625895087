/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { Colors } from 'utils';
const FacebookIcon = ({ color, width, height }) => {
  return (
    <svg
      height={`${height || '24'}px`}
      width={`${width || '24'}px`}
      viewBox="0 0 24 24"
      css={css`
        fill: ${color || Colors.secondaryColor};
        max-width: ${width || '24'}px;
        max-height: ${height || '24'}px;
        height: ${height || '24'}px;
        width: ${width || '24'}px;
      `}
    >
      <g id="surface1">
        <path
          style={{
            stroke: 'none',
            fillRull: 'nonzero',
            fill: color || Colors.secondaryColor,
            fillOpacity: '1',
          }}
          d="M 3.664062 25 L 11.816406 25 L 11.816406 16.113281 L 8.886719 16.113281 L 8.886719 13.183594 L 11.816406 13.183594 L 11.816406 9.523438 C 11.816406 7.503906 13.460938 5.859375 15.476562 5.859375 L 19.140625 5.859375 L 19.140625 8.789062 L 16.210938 8.789062 C 15.402344 8.789062 14.746094 9.445312 14.746094 10.253906 L 14.746094 13.183594 L 19.007812 13.183594 L 18.519531 16.113281 L 14.746094 16.113281 L 14.746094 25 L 21.335938 25 C 23.355469 25 25 23.355469 25 21.335938 L 25 3.664062 C 25 1.644531 23.355469 0 21.335938 0 L 3.664062 0 C 1.644531 0 0 1.644531 0 3.664062 L 0 21.335938 C 0 23.355469 1.644531 25 3.664062 25 Z M 1.464844 3.664062 C 1.464844 2.449219 2.449219 1.464844 3.664062 1.464844 L 21.335938 1.464844 C 22.550781 1.464844 23.535156 2.449219 23.535156 3.664062 L 23.535156 21.335938 C 23.535156 22.550781 22.550781 23.535156 21.335938 23.535156 L 16.210938 23.535156 L 16.210938 17.578125 L 19.761719 17.578125 L 20.738281 11.71875 L 16.210938 11.71875 L 16.210938 10.253906 L 20.605469 10.253906 L 20.605469 4.394531 L 15.476562 4.394531 C 12.652344 4.394531 10.351562 6.695312 10.351562 9.523438 L 10.351562 11.71875 L 7.421875 11.71875 L 7.421875 17.578125 L 10.351562 17.578125 L 10.351562 23.535156 L 3.664062 23.535156 C 2.449219 23.535156 1.464844 22.550781 1.464844 21.335938 Z M 1.464844 3.664062 "
        />
      </g>
    </svg>
  );
};

export default FacebookIcon;

FacebookIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};
