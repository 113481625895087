import styled from '@emotion/styled';
import React from 'react';
import { PrimaryButton, Section, device, Container } from 'utils';
import { Link } from 'react-router-dom';
const DiscoverProjectsWrapper = styled.section`
  text-align: center;
  max-width: 1220px;
  margin: auto;
  h1 {
    font-size: 65px;
    @media ${device.laptop} {
      font-size: 50px;
    }
    @media ${device.allMobile} {
      font-size: 30px;
    }
    @media ${device.tablet} {
      font-size: 40px;
    }
  }
`;
export default function DiscoverProjects() {
  return (
    <Section>
      <Container>
        <DiscoverProjectsWrapper>
          <h3>Unterstütze deine Community!</h3>
          <Link to="/projects">
            <PrimaryButton> Jetzt Projekte entdecken </PrimaryButton>{' '}
          </Link>{' '}
        </DiscoverProjectsWrapper>
      </Container>
    </Section>
  );
}
