import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'antd';
import { numberWithCommas, PrimaryButton } from 'utils';
import styled from '@emotion/styled';
import moment from 'moment';

const SingleRewardWrapper = styled.div`
  text-align: center;
  .title-open {
    font-size: 24px;
    font-weight: 700;
    word-break: break-word;
  }
  .title-closed {
    font-size: 24px;
    font-weight: 700;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .description-open {
    font-size: 16px;
    font-weight: 500;
    color: #999;
    word-break: break-word;
  }
  .description-closed {
    font-size: 16px;
    font-weight: 500;
    color: #999;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .seperate-container {
    display: flex;
    justify-content: space-between;
  }
  .price {
    text-align: right;
    font-size: 16px;
    font-weight: 750;
  }
  .numbers {
    text-align: left;
    font-size: 12px;
    font-weight: 750;
    margin-top: 5px;
    color: #444;
  }
`;

export default class RewardCard extends Component {
  static propTypes = {
    reward: PropTypes.object,
    handleChooseReward: PropTypes.func,
  };

  state = {
    open: false,
  };

  render() {
    const { reward, handleChooseReward } = this.props;
    return (
      <Card
        hoverable
        style={{
          borderRadius: '40px',
          margin: '20px',
          maxWidth: '300px',
          overflow: 'hidden',
        }}
        onClick={() => {
          this.setState({ open: !this.state.open });
        }}
        cover={
          <img
            style={{
              borderRadius: '40px',
              border: '1px solid #f0f0f0',
            }}
            height="250px"
            alt="reward-card"
            src={reward.image}
          />
        }
      >
        <SingleRewardWrapper>
          <div className={this.state.open ? 'title-open' : 'title-closed'}>
            {' '}
            {reward.title}
          </div>
          <div
            className={
              this.state.open ? 'description-open' : 'description-closed'
            }
          >
            {' '}
            {reward.description}
          </div>

          {this.state.open && (
            <PrimaryButton
              style={{ margin: '20px auto 0px' }}
              onClick={() => handleChooseReward(reward.id)}
            >
              Mehr anzeigen
            </PrimaryButton>
          )}

          <div style={{ marginTop: '30px' }} className="seperate-container">
            <span className="numbers">
              {reward.ordered_reward_count ? reward.ordered_reward_count : '0'}{' '}
              {reward.max_order_count && (
                <span>von {reward.max_order_count}</span>
              )}{' '}
              gebucht
            </span>
            <span className="price">
              {numberWithCommas(Number(reward.price).toFixed(2))} €
            </span>
          </div>

          {/* <div
            style={{
              marginTop: '10px',
              textAlign: 'center',
              fontWeight: '700',
            }}
          >
            {moment.unix(reward.shipping_date).format('MM.YYYY')}
          </div> */}
        </SingleRewardWrapper>
      </Card>
    );
  }
}
