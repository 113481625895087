import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PaymentActions, ProjectsActions, authActions } from 'redux/actions';
import {
  SvgWrapper,
  Container,
  H1,
  IsLoggedIn,
  Colors,
  PrimaryButton,
  isMobile,
  Modal,
  device,
  Section,
} from 'utils';
import { default as ShareProject } from './ShareProjct';
import { Header, Footer, Card } from 'components';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import config from 'config';
import { Spin } from 'antd';
import { ThankYouImg } from 'images';
import MuhammedSAW from 'components/MuhammadSaw';

const APP_URL = config.REACT_APP_URL;
const ThankYouWrapper = styled.div`
  text-align: center;
  margin-bottom: 100px;
  h1 {
    font-weight: bold;
    color: ${Colors.GreenColor};
    & + p {
      font-size: 22px;
      color: ${Colors.GreenColor};
    }
  }
  .actions {
    display: flex;
    justify-content: center;
    @media ${device.allMobile} {
      display: block;
    }
    a + button {
      margin-left: 20px;
      @media ${device.allMobile} {
        margin-left: 0;
      }
    }
  }
`;

const CardsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  @media ${device.allMobile} {
    display: block;
  }
  .card {
    width: 32%;
    display: inline-block;
    text-align: center;
    margin-bottom: 65px;
    @media ${device.allMobile} {
      width: 90%;
      margin: auto;
      margin-bottom: 20px;
    }
  }
`;

const RecommendedProjectsWrapper = styled.div`
  padding: 0 0;
  text-align: center;
  position: relative;
  margin-top: -5vh;

  h1 {
    position: relative;
    margin-bottom: 80px;
    font-weight: bold;
    @media ${device.laptop} {
      font-size: 32px;
    }
    &:after {
      content: '';
      width: 300px;
      height: 4px;
      background: ${Colors.secondaryColor};
      display: inline-block;
      position: absolute;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  & > a {
    display: inline-block;
    text-decoration: none;
    margin: 20px auto 0;
    color: ${Colors.secondaryColor};
  }
  .show-more {
    font-weight: bold;
    font-size: 22px;
  }

  .show-more-wrapper {
    position: relative;
    .background {
      position: absolute;
      z-index: -1;
      width: 100%;
      top: -50%;
    }
  }
  .show-more-component {
    display: flex;
    align-items: center;
    @media ${device.allMobile} {
      display: block;
    }
    &__content {
      margin-left: 45px;
      text-align: left;
      @media ${device.allMobile} {
        text-align: center;
        margin-left: 0;
      }
      h2 {
        font-weight: bold;
        font-size: 45px;
        margin: 0;
        @media ${device.allMobile} {
          font-size: 25px;
        }
        @media ${device.laptop} {
          font-size: 32px;
        }
        @media ${device.tablet} {
          font-size: 28px;
        }
      }
      p {
        font-size: 30px;
        @media ${device.allMobile} {
          font-size: 16px;
        }
        @media ${device.laptop} {
          font-size: 20px;
        }
        span {
          display: block;
          a {
            text-decoration: underline;
            &:hover {
              font-weight: bold;
            }
          }
        }
      }
    }
  }
`;
class ThankYouPage extends Component {
  componentDidMount() {
    const {
      resetDonation,
      project,
      showProject,
      sendSuccessURL,
      match: {
        params: { url },
      },
    } = this.props;

    resetDonation();
    if (project && project.url !== url) {
      showProject(url, true, true, true);
    }
    const session_string = this?.props?.location?.search;
    const session_id = session_string.split('=')[1];
    if (session_id) {
      sendSuccessURL(session_id);
    }
    this.handleSetProject();
    this.handleShowProjects();
  }

  static propTypes = {
    resetDonation: PropTypes.func.isRequired,
    showProject: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    sendSuccessURL: PropTypes.func,
    projects: PropTypes.object.isRequired,
    getProjects: PropTypes.func.isRequired,
  };
  state = {
    showModal: false,
    projectObj: {},
    perPage: 0,
    allProjectsIds: [],
    allProjects: [],
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.projects !== this.props.projects) {
      const cominedArr = this.state.allProjects.concat(
        this.props.projects.projects
      );
      const previousIds = cominedArr?.map((project) => project.id);
      this.setState({ allProjects: cominedArr });
      this.setState({ allProjectsIds: previousIds });
    }
  };

  handleSetMetaTags = () => {
    const { projectObj } = this.state;

    if (!projectObj) {
      return;
    }
    const { title, description, images } = projectObj;
    const ogTitleTag = document.querySelector('meta[property="og:title"]');
    const ogDescriptionTag = document.querySelector('meta[property="og:description"]');
    // const ogImageTag = document.querySelector('meta[property="og:image"]');
    // const ogImageTagSecure = document.querySelector('meta[property="og:image:secure_url"]');
    const projectUrl = document.querySelector('meta[property="og:url"]');
    if (ogTitleTag && title) {
      ogTitleTag.setAttribute('content', title);
    }
    if (ogDescriptionTag && description) {
      ogDescriptionTag.setAttribute('content', description);
    }
    if (projectUrl) {
      const project_url = `${APP_URL}project/${projectObj?.url ? projectObj?.url : ''}`;
      projectUrl.setAttribute('content', project_url);
    }
    // if (ogImageTag && ogImageTagSecure && images && images.length > 0) {
    //   const projectImageURL = `${images[0]}`;
    //   if (ogImageTag) {
    //     ogImageTag.setAttribute('content', projectImageURL);
    //   }
    //   if (ogImageTagSecure) {
    //     ogImageTagSecure.setAttribute('content', projectImageURL);
    //   }
    // }
  }

  handleShowProjects = () => {
    const { getProjects } = this.props;
    const { perPage, allProjectsIds } = this.state;
    getProjects(perPage + 6, 1, allProjectsIds);
    this.setState({ perPage: perPage + 6 });
  };

  handelCloseModal = () => {
    this.setState({ showModal: false });
    document.body.style.overflowY = 'unset';
  };
  handelShowModal = () => {
    this.setState({ showModal: true });
    document.body.style.overflowY = 'hidden';
  };
  handleSetProject = () => {
    const { project } = this.props;
    this.setState({ projectObj: project && project });
  };
  render() {
    const { showModal, projectObj, allProjects } = this.state;
    const { loading } = this.props;
    const projects = isMobile() ? allProjects.slice(0, 3) : allProjects;
    return (
      <>
        <div>
          <Header relativeHeader={true} />
          <Container>
            <ThankYouWrapper>
              <div>
                <SvgWrapper
                  width={isMobile() ? '80%' : '500px'}
                  height={'auto'}
                >
                  <picture>
                    <img src={ThankYouImg} alt="thank-you-img" />
                  </picture>
                </SvgWrapper>
              </div>

              <H1>DANKE</H1>
              <p>
                Die Community sagt Danke!
                <br /> Mit deiner Unterstützung sorgst du dafür, dass sich eine
                großartige Idee realisieren lässt. Teile dieses Projekt jetzt
                mit deinem Netzwerk, damit das Projekt nicht nur eine Idee
                bleibt, sondern Wirklichkeit wird!
              </p>
              <p className='txt'>
                "Es gibt keinen Muslim, welcher ein Bäumchen oder eine
                Nutzpflanze einpflanzt, wovon ein Vogel oder ein Mensch isst,
                ohne dass ihm dies als Wohltätigkeit angerechnet wird."
                <br />
              </p>
              <MuhammedSAW ThankYouPage={true} />
              <div className="actions">
                {!IsLoggedIn() && (
                  <Link className="become-a-member" to="/register">
                    <PrimaryButton minWidth={isMobile() ? '100%' : '250px'}>
                      Werde Teil der Community
                    </PrimaryButton>
                  </Link>
                )}

                <PrimaryButton
                  minWidth={isMobile() ? '100%' : '250px'}
                  onClick={() => { this.handelShowModal(); this.handleSetMetaTags(); }}
                  primary={true}
                >
                  Teilen
                </PrimaryButton>
              </div>
            </ThankYouWrapper>
          </Container>
          <RecommendedProjectsWrapper>
            <div className="container">
              <Container>
                <Section>
                  <div>
                    <H1>Weitere Projekte aus Deiner Community</H1>
                  </div>
                  <Spin spinning={loading}>
                    <CardsWrapper>
                      {projects?.length > 0 &&
                        projects?.map((project) => (
                          <Card
                            project={project}
                            key={JSON.stringify(project)}
                          />
                        ))}
                    </CardsWrapper>
                  </Spin>
                  <Link to="/projects">
                    <PrimaryButton className="show-more">
                      Mehr anzeigen
                    </PrimaryButton>
                  </Link>
                </Section>
              </Container>
            </div>
          </RecommendedProjectsWrapper>
          <Footer />
        </div>
        <Modal showModal={showModal} handelCloseModal={this.handelCloseModal}>
          <ShareProject
            subtitle={projectObj?.subtitle ? projectObj?.subtitle : ''}
            title={projectObj?.title ? projectObj?.title : ''}
            banner={projectObj?.banner ? projectObj?.banner : ''}
            url={`${APP_URL}project/${projectObj?.url ? projectObj?.url : ''}`}
          />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { project, loading, homeProjects, projects } = state.projects;
  return {
    project,
    loading,
    homeProjects,
    projects,
  };
};

const mapDispatchToProps = (dispatch) => ({
  showProject: (url, noDonations, noUpdates, noRandomProjects) =>
    dispatch(
      ProjectsActions.showProject(url, noDonations, noUpdates, noRandomProjects)
    ),
  getProjects: (perPage, page, ids) =>
    dispatch(ProjectsActions.getProjects(perPage, page, ids)),
  resetDonation: () => dispatch(PaymentActions.resetDonation()),
  sendSuccessURL: (id) => dispatch(authActions.sendSuccessURL(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ThankYouPage);