import styled from '@emotion/styled';
import React from 'react';
import { Colors, Container, Section, device, SvgWrapper } from 'utils';
import { ArrowButton, BeforeBackground, AfterBackground } from 'images';
import SectionTitleH2 from 'utils/SectionTitleH2';

const WhyWrapper = styled.div`
  position: relative;
  .container {
    background: #f9f9f9;
    margin-top: -8px;
  }

  .after-background {
    position: relative;
    margin-top: 0;
  }
  h1 {
    font-size: 65px;
    margin-bottom: 65px;
    margin-top: 0;
    line-height: 1;
    @media ${device.laptop} {
      font-size: 30px;
    }
    @media ${device.allMobile} {
      font-size: 30px;
      margin-bottom: 20px;
    }
  }
  ul {
    padding: 0;
    list-style: none;
    li {
      font-size: 26px;
      margin-bottom: 10px;
      @media ${device.laptop} {
        font-size: 20px;
      }
      @media ${device.allMobile} {
        font-size: 16px;
      }
      .svg-wrapper {
        position: relative;
        transform: rotate(-90deg);
        margin-right: 10px;
      }
      span {
        mark {
          background-color: ${Colors.secondaryColor};
          color: ${Colors.white};
        }
      }
    }
  }
`;
const ListWrapper = styled.div`
  position: relative;
  padding: 75px;
  @media ${device.allMobile} {
    padding: 50px 20px;
  }
  ul {
    list-style-image: url(${ArrowButton});
    @media ${device.allMobile} {
      padding-left: 18px;
      li span {
        position: relative;
        top: -8px;
      }
    }
  }
  &:before {
    content: '';
    display: inline-block;
    width: 100px;
    height: 100px;
    border-left: 8px solid ${Colors.secondaryColor};
    border-top: 8px solid ${Colors.secondaryColor};
    position: absolute;
    top: 0;
    left: 0;
    @media ${device.allMobile} {
      width: 75px;
      height: 75px;
    }
  }
  &:after {
    content: '';
    display: inline-block;
    width: 100px;
    height: 100px;
    border-right: 8px solid ${Colors.secondaryColor};
    border-bottom: 8px solid ${Colors.secondaryColor};
    position: absolute;
    bottom: 0;
    right: 0;
    @media ${device.allMobile} {
      width: 75px;
      height: 75px;
    }
  }
`;

export default function Why() {
  return (
    <Section>
      <WhyWrapper>
        <div className="background">
          <SvgWrapper width="100%" height="100%">
            <BeforeBackground />
          </SvgWrapper>
        </div>
        <div className="container">
          <Container>
            <ListWrapper>
              <SectionTitleH2>Warum gerade commonsplace?</SectionTitleH2>
              <ul>
                <li>
                  <span>
                    commonsplace gewährleistet dir absolute{' '}
                    <mark>Sicherheit</mark> und <mark>Transparenz</mark> bzgl.
                    der Finanzen
                  </span>
                </li>
                <li>
                  <span>
                    Auf commonsplace findest du großartige Ideen und Projekte{' '}
                    <mark>deiner Community</mark> übersichtlich auf einer
                    Plattform
                  </span>
                </li>
                <li>
                  <span>
                    Dein Projekt gewinnt durch commonsplace an{' '}
                    <mark>Bekanntheit</mark> in der muslimischen Community in
                    Deutschland und du kannst dein Fundingziel{' '}
                    <mark>schnell</mark> erreichen
                  </span>
                </li>
              </ul>
            </ListWrapper>
          </Container>
        </div>
        <div className="after-background">
          <SvgWrapper width="100%" height="100%">
            <AfterBackground />
          </SvgWrapper>
        </div>
      </WhyWrapper>
    </Section>
  );
}
