import React, { Component } from 'react';
import styled from '@emotion/styled';
import { Colors } from 'utils';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { authActions } from 'redux/actions';
import { Link } from 'react-router-dom';

const RightSideBarWrapper = styled.aside`
  position: absolute;
  right: 0;
  width: 80%;
  padding: 20px;
  min-height: 100vh;
  background: ${Colors.grey};
  text-align: center;
  z-index: 1001;
  margin-top: -179px;
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: sticky;
    top: 20px;
    min-height: calc(100vh - 116px);
  }

  button {
    display: block;
    border: 1px solid ${Colors.GreenColor};
    background: ${Colors.GreenColor};
    color: ${Colors.white};
    text-align: center;
    border-radius: 12px;
    cursor: pointer;
    margin-bottom: 20px;
    padding: 15px 20px;
    min-width: 250px;
    font-size: 18px;
    &:hover {
      color: ${Colors.GreenColor};
      background: ${Colors.transparent};
    }
  }
  ul {
    padding: 0;
    list-style: none;
    li {
      button {
        display: block;
        background: ${Colors.transparent};
        border: 1px solid ${Colors.GreenColor};
        color: ${Colors.GreenColor};
        text-align: center;
        border-radius: 12px;
        cursor: pointer;
        margin-bottom: 20px;
        padding: 15px 20px;
        min-width: 250px;
        font-size: 18px;
        &.active,
        &:hover {
          background: ${Colors.GreenColor};
          color: ${Colors.white};
        }
      }
    }
  }
  .setting-list {
    padding-left: 50px;
    button {
      min-width: 200px;
    }
  }
`;
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #000;
  opacity: 0.5;
  z-index: 1000;
  overflow-y: hidden;
`;
class RightSideBar extends Component {
  static propTypes = {
    setCurrentView: PropTypes.func.isRequired,
    currentView: PropTypes.string.isRequired,
    logout: PropTypes.func.isRequired,
    handleCloseMenu: PropTypes.func.isRequired,
  };
  state = {
    showSettingList: false,
  };
  render() {
    const { logout, handleCloseMenu } = this.props;
    return (
      <>
        <Overlay onClick={handleCloseMenu} />
        <RightSideBarWrapper>
          <div>
            <div>
              <ul>
                <li>
                  <Link to="/profile">Profil</Link>
                </li>
                <li>
                  <Link to="/profile">Eigene Projekte</Link>
                </li>
              </ul>
            </div>
            <div>
              <Link to="#!" onClick={() => logout()}>
                Abmelden
              </Link>
            </div>
          </div>
        </RightSideBarWrapper>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { user } = state.authentication;
  return {
    user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(authActions.logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RightSideBar);
