import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AdminActions } from 'redux/actions';
import { Layout } from 'components';
import { Button, Popconfirm, Table } from 'antd';
import { Container, PrimaryButton } from 'utils';
import styled from '@emotion/styled';
import { adminServices } from 'services';
import { Input, Form, Radio } from 'antd';
import { H2, Modal } from 'utils';
import '../ProjectForm/components/RichTextCss.css';
import '../Project/components/project_details.css';
import Papa from 'papaparse';

const FormItem = Form.Item;

const TableWrapper = styled.div`
  padding: 50px 0;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

const Filter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  @media (max-width: 320px) {
    gap: 10px;
    overflow: hidden;
  }
`;

const perPage = 20;
export class ViewDonationDetails extends Component {
  static propTypes = {
    donations: PropTypes.any.isRequired,
    loading: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
  };
  componentDidMount() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.getDonationDetail(id, 1);
  }
  constructor(props) {
    super(props);
    this.state = {
      status: null,
      loading: false,
      page: 1,
      donationList: { donations: [] },
      showEditModal: false,
      selectedData: null,
      anonymousTrue: false,
    };
  }

  async getDonationDetail(id, page) {
    try {
      this.setState({
        loading: true,
        error: null,
        // donationList: { donations: [], count: 1, page: this.state.page },
      });
      const res = await adminServices.getDonationDetailService(id, page);
      let data = res?.projects;
      const updatedDonations = data?.donations.map((item) => {
        item.full_name = `${item.first_name} ${item.last_name}`;
        return item;
      });
      this.setState({ loading: false, error: null });
      this.setState({ showEditModal: false });
      this.setState({
        donationList: {
          donations: updatedDonations,
          pages: data.pages,
          count: data.count,
        },
      });
      this.setState({ loading: false, error: null });
    } catch (error) {}
  }
  handelCloseEditModal = () => {
    this.setState({ showEditModal: false, selectedData: null });
    // document.body.style.overflowY = 'unset';
  };

  handelEditModal = (data) => {
    this.setState({ selectedData: data, showEditModal: true });
  };

  onEdit = async (values) => {
    const { selectedData, page } = this.state;
    const {
      match: {
        params: { id },
      },
    } = this.props;

    const data = {
      donation: {
        first_name: values.firstName,
        last_name: values.lastName,
        tips: values.tip,
        amount: values.amount,
        anonymous: values.anonymous ?? false,
        message: values.message,
      },
    };
    if (selectedData) data.donation.donation_id = selectedData.id;
    else data.id = id;
    const raw = JSON.stringify(data);

    try {
      selectedData
        ? await adminServices.updateDonationDataService(raw)
        : await adminServices.createDonationDataService(raw);
      await this.getDonationDetail(id, page);
    } catch (error) {
      console.error('Error updating or fetching donation details:', error);
    }
  };

  handleOnclick = (e) => {
    const value = e.target.value === 'true' ? true : false;
    this.setState({ anonymousTrue: value });
  };

  deleteHandler = async ({ id }) => {
    const {
      match: {
        params: { id: projectId },
      },
    } = this.props;
    try {
      this.setState({ loading: true, error: null });
      await adminServices.deleteDonation(id);

      this.setState({ loading: false, error: null });
      await this.getDonationDetail(projectId, this.state.page);
    } catch (error) {
      this.setState({ loading: false });
    }
  };

  handleDownloadCSV = (data) => {
    const csvData = Papa.unparse(data);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'project_donations_stats.csv';
    link.click();
  };

  downloadHandler = async () => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const data = await adminServices.getAllDonations(id);
    this.handleDownloadCSV(data?.donations);
  };

  render() {
    const { loading } = this.state;
    const { donationList, showEditModal, selectedData } = this.state;
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const columns = [
      {
        title: 'User name',
        dataIndex: 'full_name',
        key: 'full_name',
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Tips',
        dataIndex: 'tips',
        key: 'tips',
      },
      { title: 'Amount', dataIndex: 'amount', key: 'amount' },
      {
        title: 'Anonymous',
        dataIndex: 'anonymous',
        key: 'anonymous',
        render: (text, record) => (
          <span>{record.anonymous ? 'True' : 'False'}</span>
        ),
      },

      { title: 'Message', dataIndex: 'message', key: 'message' },
      {
        title: <div>View</div>,
        dataIndex: 'view',
        key: 'view',
        align: 'center',
        render: (text, record) => (
          <div>
            <Button onClick={() => this.handelEditModal(record)} type="text">
              Edit
            </Button>
            <Popconfirm
              title="Are you sure to delete this donation?"
              onConfirm={() => this.deleteHandler(record)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="text" danger>
                Delete
              </Button>
            </Popconfirm>
            {/* <span >Edit</span> |{' '}
            <span onClick={() => this.handelDeleteModal(record)}>Delete</span> */}
          </div>
        ),
      },
    ];
    return (
      <Layout>
        <Container>
          {donationList?.donations?.length > 0 ? (
            <TableWrapper>
              <Buttons>
                <PrimaryButton onClick={this.downloadHandler}>
                  Download Donations
                </PrimaryButton>
                <PrimaryButton
                  className="rit mr-5"
                  onClick={() => this.setState({ showEditModal: true })}
                >
                  Add Donation
                </PrimaryButton>
              </Buttons>
              <Table
                key={(Math.random() + 1).toString(36).substring(7)}
                columns={columns}
                dataSource={donationList.donations}
                loading={loading}
                pagination={{
                  total: donationList.count,
                  pageSize: perPage,
                  hideOnSinglePage: false,
                  showSizeChanger: false,
                  current: this.state.page,
                  onChange: (page) => {
                    this.setState({ page });
                    this.getDonationDetail(id, page);
                  },
                }}
              />
            </TableWrapper>
          ) : (
            <h3 style={{ margin: '50px 0', textAlign: 'center' }}>
              No Donations found for this project
            </h3>
          )}

          <Modal
            showModal={showEditModal}
            handelCloseModal={this.handelCloseEditModal}
          >
            <H2>{selectedData ? 'Edit' : 'Add'} Donation</H2>
            <Form layout="vertical" onFinish={this.onEdit}>
              <FormItem
                label="Vorname"
                name="firstName"
                initialValue={selectedData && selectedData.first_name}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="Vorname" />
              </FormItem>
              <FormItem
                label="Nachname"
                name="lastName"
                initialValue={selectedData && selectedData.last_name}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="Nachname" />
              </FormItem>
              <FormItem
                label="Tipp"
                name="tip"
                initialValue={selectedData && selectedData.tips}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="Tip" />
              </FormItem>
              <FormItem
                label="Menge "
                name="amount"
                initialValue={selectedData && selectedData.amount}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="Menge" />
              </FormItem>

              <FormItem
                name={'message'}
                label="Nachricht"
                initialValue={selectedData && selectedData.message}
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input.TextArea />
              </FormItem>
              <FormItem
                label="Anonymous"
                name="anonymous"
                initialValue={selectedData && selectedData?.anonymous}
              >
                <Radio.Group>
                  <div>
                    <Radio className="radio_buttons large" value={true}>
                      WAHR
                    </Radio>
                    <Radio className="radio_buttons large" value={false}>
                      FALSCH
                    </Radio>
                  </div>
                </Radio.Group>
              </FormItem>
              <FormItem>
                <PrimaryButton
                  type="primary"
                  htmltype="submit"
                  minWidth="150px"
                >
                  {selectedData ? 'Update' : 'Add'}
                </PrimaryButton>
              </FormItem>
            </Form>
          </Modal>
        </Container>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  const { projects, loading } = state.admin;
  return {
    projects,
    loading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllProjects: (perPage, page, status = null) =>
    dispatch(AdminActions.getAllProjects(perPage, page, status)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewDonationDetails);
