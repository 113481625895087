import styled from '@emotion/styled';
import React, { Component } from 'react';
import { Colors } from 'utils';
import { SingleTime } from './';
import PropTypes from 'prop-types';

const TimeLineTabWrapper = styled.div`
  h1 {
    font-size: 30px;
    font-weight: bold;
    color: ${Colors.GreenColor};
    margin: 0;
    margin-bottom: 20px;
    margin-left: 17px;
  }
  .show-more {
    text-align: center;
    margin: 25px 0;
    button {
      display: inline-block;
      text-decoration: none;
      background: none;
      border: none;
      border-bottom: 2px solid ${Colors.transparent};
      margin: 20px auto 0;
      color: ${Colors.secondaryColor};
      &:hover {
        border-bottom: 2px solid ${Colors.secondaryColor};
      }
    }
  }
`;

export default class TimeLineTab extends Component {
  static propTypes = {
    updates: PropTypes.array.isRequired,
  };
  state = {
    currentLimit: 3,
  };

  render() {
    const { updates } = this.props;

    return (
      <TimeLineTabWrapper>
        <h1>Update</h1>
        {updates &&
          updates.map((time, inx) => {
            return (
              inx < this.state.currentLimit && (
                <SingleTime
                  key={`${JSON.stringify(time)}-${inx}`}
                  time={time.date}
                  by={time.title}
                  image={time.image}
                  content={time.body || 'There Is No Body'}
                />
              )
            );
          })}
        {this.state.currentLimit < updates.length && (
          <div className="show-more">
            <button
              onClick={() =>
                this.setState({ currentLimit: this.state.currentLimit + 3 })
              }
            >
              Mehr anzeigen
            </button>
          </div>
        )}
      </TimeLineTabWrapper>
    );
  }
}
