import React, { Component } from 'react';
import { Header, Banner, Footer, LoginForm } from 'components';
import { Container, Modal } from 'utils';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
const BaseLayoutWrapper = styled.div`
  & > .container {
    position: relative;
    z-index: 2;
  }
`;

class BaseLayout extends Component {
  static propTypes = {
    img: PropTypes.string,
    children: PropTypes.object,
    bannerComponent: PropTypes.element,
    container: PropTypes.bool,
    user: PropTypes.object,
    maxHeightProps: PropTypes.string,
    whiteHeader: PropTypes.bool,
    bannerClass: PropTypes.string,
    maxHeightMobileProps: PropTypes.string,
  };
  state = {
    showModal: false,
  };
  handelCloseModal = () => {
    this.setState({ showModal: false });
    document.body.style.overflow = 'unset';
  };
  handelShowModal = () => {
    this.setState({ showModal: true });
    document.body.style.overflow = 'hidden';
  };
  render() {
    const { showModal } = this.state;
    const {
      img,
      bannerComponent,
      container,
      children,
      user,
      maxHeightProps,
      whiteHeader,
      bannerClass,
      maxHeightMobileProps,
      style
    } = this.props;
    const isLoggedIn = Boolean(
      localStorage.getItem('user') || (user && user.uid)
    );

    return (
      <>
        <BaseLayoutWrapper>
          <Header showLogin={this.handelShowModal} whiteHeader={whiteHeader} />
          <Banner
            maxHeightProps={maxHeightProps}
            maxHeightMobileProps={maxHeightMobileProps}
            img={img}
            {...this.props}
            className={bannerClass ? bannerClass : ''}
            style={style}
          >
            {bannerComponent}
          </Banner>

          {container ? (
            <Container className="container">
              <div className="content-Wrapper">{children}</div>
            </Container>
          ) : (
            <div className="content-Wrapper">{children}</div>
          )}
          <Footer />
        </BaseLayoutWrapper>
        <Modal
          showModal={isLoggedIn ? false : showModal}
          handelCloseModal={this.handelCloseModal}
        >
          <LoginForm />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { user } = state.authentication;
  return {
    user,
  };
};
const mapDispatchToProps = () => ({});
export default connect(mapStateToProps, mapDispatchToProps)(BaseLayout);
