import styled from '@emotion/styled';
import React from 'react';
import { Container, Colors, device } from 'utils';

import Background from 'images/blob-21.svg';
import { Link } from 'react-router-dom';
const SupportWrapper = styled.div`
  text-align: center;
  position: relative;
  padding: 0;

  .container {
    max-width: 1200px;
    @media ${device.laptop} {
      max-width: 950px;
    }
    @media ${device.allMobile} {
      padding: 0;
    }
    @media ${device.tablet} {
      padding: 0;
    }
  }
`;

const ContentWrapper = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 25px;
  text-align: center;
  position: relative;

  @media ${device.allMobile} {
    padding: 10px 0;
  }
  h1 {
    font-weight: bold;
    font-size: 40px;
    margin-bottom: 30px;
    @media ${device.laptop} {
      padding-top: 126px;
      font-size: 30px;
    }
    @media ${device.tablet} {
      padding-top: 0;
    }

    @media ${device.allMobile} {
      font-size: 25px;
    }
  }
  p {
    font-size: 27px;
    @media ${device.allMobile} {
      font-size: 16px;
    }
  }
  a {
    background: ${Colors.secondaryColor};
    border: 1px solid ${Colors.secondaryColor};
    color: ${Colors.white};
    text-align: center;
    border-radius: 12px;
    cursor: pointer;
    margin-bottom: 0;
    margin-top: 30px;
    display: inline-block;
    padding: 15px 20px;
    min-width: 200px;
    font-size: 18px;
    &:hover {
      background: ${Colors.transparent};
      color: ${Colors.secondaryColor};
    }
  }
`;
const BackgroundWrapper = styled.div`
  background-image: url(${Background});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  overflow-x: hidden;
  padding: 160px 74px 139px;
  @media ${device.allMobile} {
    padding: 100px 15px;
  }
  @media ${device.laptop} {
    padding: 6px 50px 81px;
  }
  @media ${device.tablet} {
    padding: 100px 30px 82px;
  }
`;
export default function Support() {
  return (
    <SupportWrapper>
      <Container className="container">
        <BackgroundWrapper>
          <ContentWrapper>
            <h2>Wer ist commonsplace?</h2>
            <p>
              Mit der ersten deutsch-muslimischen crowdfunding-Plattform geben
              wir kreativen und engagierten Muslimen einen digitalen und
              kooperativen Raum, um großartige Ideen in der Community
              miteinander zu teilen und zu finanzieren.
            </p>
            <Link to="/about-us">Mehr über uns erfahren</Link>
          </ContentWrapper>
        </BackgroundWrapper>
      </Container>
    </SupportWrapper>
  );
}
